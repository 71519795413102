/**
 * Miscellaneous project specific Handlebars helper functions.
 */
define('library/utilities/days-hours-minutes-label',['require','underscore','library/vlp/app'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//class dependencies
	var App = require("library/vlp/app");

	return function(time, options){

		options = _.defaults(options || {}, {
			roundToDays  : false,
			seconds      : false,
			milliseconds : false
		});

		if(options.milliseconds){
			time /= 60000;
		} else if(options.seconds){
			time /= 60;
		}

		if(time === 0 || time < 1){
			return App.i18n("time.minutes", 0);
		}
		var days = Math.floor(time / (60 * 24));
		time -= days * (60 * 24);
		var hours = Math.floor(time / 60);
		time -= hours * 60;
		var minutes = Math.floor(time);

		var label = "";

		if(days > 0){
			if(days === 1){
				if(options.roundToDays){
					return App.i18n("time.day", days);
				} else {
					label += App.i18n("time.day", days) + " ";
				}
			} else{
				if(options.roundToDays){
					return App.i18n("time.days", days);
				} else {
					label += App.i18n("time.days", days) + " ";
				}
			}
		}
		if(hours > 0){
			if(hours === 1){
				label += App.i18n("time.hour", hours) + " ";
			} else{
				label += App.i18n("time.hours", hours) + " ";
			}
		}
		if(minutes > 0){
			if(minutes === 1){
				label += App.i18n("time.minute", minutes) + " ";
			} else{
				label += App.i18n("time.minutes", minutes) + " ";
			}
		}
		return _.trim(label);
	};

});
