define('models/manual',['require','exports','module','backbone','underscore','library/vlp/app','library/vlp/model','library/vlp/collection','models/step'],function (require, exports) {
	"use strict";

	//library dependencies
	var Backbone = require("backbone"),
	    _        = require("underscore");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection"),
	    Step           = require("models/step");


	//Use exports for requirejs circular dependency resolving
	var Manual = exports;

	//Add Manual to the App.Models namespace
	App.Models.Manual = Manual;


	Manual.Model = BaseModel.extend({
		urlRoot : "/manuals",
		/**
		 * Default values for empty Models (new Manual.Model())
		 */
		defaults: {
			name             : "Test Manual 1",
			language         : "english",
			locale           : "en-us",
			activeStepNumber : 0,
			startLabelingStep: 0,
			toc              : [],
			tags             : {},
			alternativeLocales: {},
			manualFilePath    : null
		},
		relations : [
			{
				key             : "steps",
				type            : Backbone.HasMany,
				relatedModel    : "Step.Model",
				collectionType  : "Step.Collection"
			}
		],
		setters : {
			activeStepNumber : function(value){
				if(value < 0){
					value = 0;
				} else if(this.get("steps") && value >= this.get("steps").length){
					value = this.get("steps").length - 1;
				}
				return value;
			}
		},
		getters : {
			tableOfContentsPosition : function(){
				if(this.get("remoteControlMode")) { return "hidden"; }

				if(App.config.tableOfContentsButtonLocation === "top"){
					return "top";
				}
				if(this.get("networkTopology")){ return "nested"; }
				if(this.get("showRemoteControl")){ return "nested"; }

				return "top";
			},
			showMoreOptions : function(){
				if(this.get("networkTopology")){ return true; }
				if(this.get("showRemoteControl")){ return true; }

				return false;
			}
		},
		/**
		 * Constructor
		 */
		initialize : function(){

		},

		parse : function(){
			var result = BaseModel.prototype.parse.apply(this, arguments);


			if(!result.steps && result.toc){
				result.steps = [];
				result.tags  = {};
				var alternateTags = {};
				var parseTOC = function(toc){
					for(var i = 0; i < toc.length; i++){
						var item = toc[i];
						result.steps[parseInt(item.stepno, 10)] = {
							id       : item.id,
							stepNo   : item.stepno,
							manualId : result.id,
							tags     : item.tags
						};
						if(item.tags){
							_.each(item.tags, function(tag){ //jshint ignore:line
								result.tags[tag] = item.id;
								var baseTag = tag.replace(/^l/, "s");
								if(baseTag && baseTag !== tag && !alternateTags.hasOwnProperty(baseTag)){
									alternateTags[baseTag] = item.id;
								}
							});
						}
						if(item.children){
							parseTOC(item.children);
						}
					}

				};
				parseTOC(result.toc);
				result.tags = _.defaults(result.tags, alternateTags);
			}

			return result;
		},
		fetch : function(options){
			options = options || {};
			options.data = options.data || {};
			options.data.languagecode = options.data.languagecode || this.get("locale");
			return BaseModel.prototype.fetch.call(this, options);
		},
		fetchStep : function(options){
			options = options || {};
			options.data = options.data || {};
			options.method = "read";

			options.data.languagecode = options.data.languagecode || this.get("locale");
			options.data.nodeId = options.data.nodeId || this.get("step").id;

			options.properties = [];

			var self = this;

			var success = options.success;

			options.success = function(resp){
				var step = new Step.Model(resp);
				self.set("step", step);
				if(success) {
					success(self, resp);
				}
			};

			options.action = options.action || "node";

			return this.action(options.action, options);
		},
		nextStep : function(options){
			options = options || {};

			options.action = "nextNode";
			return this.fetchStep(options);
		},
		prevStep : function(options){
			options = options || {};

			options.action = "previousNode";
			return this.fetchStep(options);
		}
	});


	Manual.Collection = BaseCollection.extend({
		model : Manual.Model
	});

});

