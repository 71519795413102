define('models/class-entitlement',['require','exports','module','library/vlp/app','models/entitlement'],function (require, exports) {
	"use strict";

	//class dependencies
	var App            = require("library/vlp/app"),
	    Entitlement    = require("models/entitlement");

	//Use exports for requirejs circular dependency resolving
	var ClassEntitlement = exports;

	//Add ClassEntitlement to the App.Models namespace
	App.Models.ClassEntitlement = ClassEntitlement;

	ClassEntitlement.Model = Entitlement.Model.extend({
		urlRoot: "/classes",
		pushService : "class",

		url : function(){
			var urlRoot = this.basePath();
			urlRoot += this.get("classId") + "/entitlements/" + this.id;
			return this.serverBase + urlRoot;
		}
	});

	ClassEntitlement.Collection = Entitlement.Collection.extend({
		model: ClassEntitlement.Model,
		perPage : 15,
		urlRoot: "/classes",
		pushService : "class",
		classId : 0,
		initialize : function(models, options){
			options = options || {};
			if(options.classId){
				this.classId = options.classId;
			}
		},
		url : function(){
			var urlRoot = this.basePath();
			urlRoot += this.classId + "/entitlements";
			return this.serverBase + urlRoot;
		}
	});
});

