/**
 * Public Profile Backbone View
 */
define('views/common/public-profile',['require','jquery','underscore','library/vlp/app','library/vlp/view','hbs!tpls/common/public-profile.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App      = require("library/vlp/app"),
	    BaseView = require("library/vlp/view"),
	    tpl      = require("hbs!tpls/common/public-profile.handlebars");

	return BaseView.extend({

		template : tpl,
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .authored-lab" : "hide"
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 */
		initialize : function(options){
			_.bindAll(this);

			options = options || {};

			this.listenTo(this.model, "request sync error", this.render);
			$("#modal_public_profile").on("hidden", this.onHidden);
		},
		serialize : function(){
			var data = this.model.toHandlebars();


			if(this.model.get("id") === "me"){
				_.each(_.keys(data), function(field){
					if(field.match(/_public$/) && !data[field]) {
						delete data[field.replace(/_public$/, "")];
					}
				});
			}
			data.loggedIn = App.user.get("loggedIn");
			return data;
		},
		afterRender : function(){
			$("#modal_public_profile").modal("reflow");
		},

		show : function(){
			if(!this.model.fetched && !this.model.loading){
				this.model.fetch({
					loginCancelable : true,
					error           : this.fetchError
				});
			} else{
				this.render();
			}
			$("#modal_public_profile").append(this.el);
			$("#modal_public_profile").modal("show");
			App.analytics.trackEvent("Account", "Show: Public Profile", this.model.get("username"));
		},
		hide : function(){
			$("#modal_public_profile").modal("hide");
		},
		onHidden : function(){
			this.trigger("closed");
			this.remove();
			$("#modal_public_profile").off("hidden", this.onHidden);
		},

		fetchError : function(model, jqXHR){
			if(jqXHR.statusText === "loginCanceled"){
				this.render();
			} else {
				return false;
			}
		}
	});

});
