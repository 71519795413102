/**
 * Event Backbone View
 */
define('views/event/main',['require','jquery','underscore','library/vlp/app','views/vlp-base','hbs!tpls/event/main.handlebars','views/common/layout-tpls','hbs!tpls/event/registered.handlebars','hbs!tpls/event/register.handlebars','i18n!nls/event'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App                 = require("library/vlp/app"),
	    VlpBaseView         = require("views/vlp-base"),
	    mainTPL             = require("hbs!tpls/event/main.handlebars"),
	    LayoutTPLs          = require("views/common/layout-tpls"),
	    registeredTPL       = require("hbs!tpls/event/registered.handlebars"),
	    registerTPL         = require("hbs!tpls/event/register.handlebars"),
	    pageTexts           = require("i18n!nls/event");

	return VlpBaseView.extend({

		className : "general-page",

		checkLoggedIn         : false,
		checkMaintenance      : true,
		checkBrowserSupported : false,
		checkTenantPath       : false,
		stationPush           : true,

		//Required
		mainTemplate   : mainTPL,
		layoutTemplate : LayoutTPLs.layoutNoLogin,

		autoValidate : false,

		events : {
			"submit #register_form" : "onRegister",
			"click .unregister .btn": "onUnregister"
		},

		modelBindings : {
			"#register_username"  : "username",
			"#register_password"  : "password",
			"#register_stationId" : "stationId"
		},

		validation : {
			username : {
				required  : true
			},
			password : {
				required  : true
			},
			stationId : {
				required  : true,
				//32bit signed int range
				pattern : "number",
				range   : [-2147483648, 2147483647]
			}
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){

			_.bindAll(this);

			App.locale.mergeI18n(pageTexts);
			//Checks logged in
			this.setup(options);
			this.once(VlpBaseView.STARTUP_COMPLETE, this.start);

		},
		/**
		 * Display the content.
		 */
		render : function(){
			var tpl = registerTPL;
			var data = {};
			data.copyrightYear = new Date().getFullYear();

			if(this.model.isRegistered()){
				data.stationId = this.model.get("stationId");
				tpl = registeredTPL;
				$(".station-id").text(this.model.get("stationId"));
				$("#registered_station").show();
				$("body").addClass("event-station");
			} else{
				$("body").removeClass("event-station");
				$("#registered_station").hide();
			}
			this.$(".inner").html(tpl(data));
			this.$("#register_username").focus();
			//this.$(".spinner").hide();
			//Always return this for chaining
			return this;
		},
		start : function(){
			var self = this;
			this.model = App.station;
			this.listenTo(this.model, "unregistered", this.render);
			this.updateBindings();
			App.user.checkLoggedIn({
				success : function(){
					App.user.logout({success : self.render});
				},
				error   : this.render
			});
		},
		onRegister : function(event){

			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}
			var self = this;

			//Validate and reject if invalid
			if(this.validate(["username", "password", "stationId"], {displayErrors: true})){ return; }


			this.model.register({
				force : this.$("#register_force").prop("checked"),

				success : function(){
					self.removeGeneralMessage("station-invalid");
					self._setupPush();
					self.render();
					self.model.unset("username");
					self.model.unset("password");
					App.analytics.trackEvent("Station", "Register", self.model.get("stationId"));
				},
				error : function(response){
					var clearFields = true;

					response = response.response || response;

					if(response && response.message){
						self.$(".save-error-general")
							.text(_.escapeHTML(response.message))
							.show();

						if(response.errorCode === 4102){
							self.$(".force-register").show();
							self.$("#register_force").focus();
							clearFields = false;
						}
					} else{
						self.$("#register_invalid").show();
					}


					if(clearFields){
						self.$("#register_form").find("input[type=text],input[type=password]").val("").change();
						self.$("#register_username").focus();
					}
				},
				beforeSend : function(){
					self.$(".register-spinner").css("visibility", "visible");
					self.$("#register").button("loading");
					self.$(".save-error-general").hide();
				},
				complete : function(){
					self.$(".register-spinner").css("visibility", "hidden");
					self.$("#register").button("reset");
				}
			});

		},
		onUnregister : function(event){

			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			var self = this;

			this.model.unregister({
				success : this.render,
				error   : this.render,
				beforeSend : function(){
					self.$(".unregister .spinner").css("visibility", "visible");
					self.$(".unregister .btn").button("loading");
					App.push.off("station:invalid", self._stationInvalid);
					App.analytics.trackEvent("Station", "Unregister", self.model.get("stationId"));
				},
				complete : function(){
					self.$(".unregister .spinner").css("visibility", "hidden");
					self.$(".unregister .btn").button("reset");
				}

			});
		}


	});

});

