/**
 * Vm Backbone View
 */
define('views/console/broadcast-vm',['require','jquery','underscore','library/vlp/app','library/vlp/view','hbs!tpls/console/broadcast-vm.handlebars','utilities/browser','library/utilities/screenfull','library/wmks'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App                = require("library/vlp/app"),
	    BaseView           = require("library/vlp/view"),
	    vmTPL              = require("hbs!tpls/console/broadcast-vm.handlebars"),
	    Browser            = require("utilities/browser"),
	    Screenfull         = require("library/utilities/screenfull");

	    require("library/wmks");


	return BaseView.extend({
		template : vmTPL,
		removeKeepEl : true,

		POWERED_ON     : "powered-on",
		POWERED_DOWN   : "powered-down",
		LOADING        : "loading",
		CANNOT_CONNECT : "cannotConnect",
		UNAVAILABLE    : "unavailable",

		screenStatus : "normal",
		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		//el : "#vm",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .full-screen" : "toggleFullscreen",
			"click .screen-retry": "refreshConsole",
			"click .page-refresh": "refreshPage"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			options = options || {};

			this._throttledRefresh = _.throttle(this.refreshConsole, App.config.screenTicketDelay, {trailing : false});
		},
		serialize : function(){
			var data = this.model.toHandlebars();
			data.allowFullscreen = Browser.support.fullscreen;
			return data;
		},
		/**
		 * Display the content.
		 */
		beforeRender : function(){
			$(".modal-backdrop").remove();
		},
		afterRender : function(){
			this.$vmConsole = this.$("#vm_console");
			this.$vmGroup   = this.$("#vm_group");

			this.displayState(this.LOADING, this.$vmConsole);

			this.$vmConsole.find("canvas").focus();

			this.$vmGroup.removeAttr("style");
			this.$vmConsole.removeAttr("style");
			this.setWmksOption("fitToParent", true);

			this.startVM();
			this.$("[rel=popover]").popover("hide");
			this.$("[rel=tooltip]").tooltip();
		},
		refresh : function(){
			this.startVM();
		},
		show : function(){
			this._hadMaximize = $("body").hasClass("maximize");
			$("body").addClass("maximize broadcasting-vm");
			this.render();
			this.$el.show();
		},
		hide : function(){
			this.$el.hide();
			if(this._hadMaximize){
				$("body").removeClass("maximize");
			}
			$("body").removeClass("broadcasting-vm");
		},
		remove : function(){
			this.hide();
			this.unload();
			BaseView.prototype.remove.call(this);
		},
		unload : function(){
			clearTimeout(this.wmksTimeout);
			this.stopListening();
			this.stopVM();
		},
		refreshConsole : function(event) {
			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}
			this.startVM();
		},
		/**
		 * "Start" the VM: connect with WebMKS
		 */
		startVM : function(){
			var self = this;

			this.displayState(this.LOADING);
			this.stopVM();

			if(!this._wmksConnectAttempt){
				this._wmksConnectAttempt = 1;
			}

			if(this.ticketTimeout){
				clearTimeout(this.ticketTimeout);
				this.ticketTimeout = null;
			}
			if(this.wmksTimeout){
				clearTimeout(this.wmksTimeout);
				this.wmksTimeout = null;
			}

			this.wmksTimeout = setTimeout(function(){
				if(self._wmksConnectAttempt > App.config.screenTicketMaxTries){
					self.displayState(self.CANNOT_CONNECT);
					clearTimeout(self.wmksTimeout);
					self.wmksTimeout = null;
				} else{
					self._wmksConnectAttempt++;
					self.startVM();
				}
			}, App.config.vmConnectionWait);


			var url = self.model.get("classActiveScreenTicket");
			var vmx = self.model.get("classActiveVmx");

			if(!url){ return; }

			this.$vmConsole.wmks({
				VCDProxyHandshakeVmxPath : vmx,
				useUnicodeKeyboardInput  : true,
				enableNextGenerationMouse: true,
				enableUint8Utf8          : false,
				locale                   : App.locale.get().toLowerCase(),
				sessionName              : this.model.get("name"),
				enableVVC                : false
			});

			try{
				this.$vmConsole.wmks("connect", url);

				this.$vmConsole.on("wmksconnected.vm", function(){
					clearTimeout(self.wmksTimeout);
					self.wmksTimeout = null;
					self._wmksConnectAttempt = null;

					//Listen for disconnects and refresh screenTicket/webmks connection
					self.$vmConsole.on("wmksdisconnected.vm", self._throttledRefresh);
					self.$vmConsole.wmks("disconnectEvents");
					self.setWmksOption("fitToParent", true);
					self.displayState(self.POWERED_ON);
					$(window).off("resize.broadcastvm").on("resize.broadcastvm", self.windowResizing);
				});
			} catch(e){
				//Some error connecting to VM. Let the reconnect wmksTimeout call (above) try it again.
				console.warn("Cannot connect with wmks:", e);
				this.stopVM();
			}
		},

		/**
		 * Stop the VM connection (disconnect WebMKS).
		 */
		stopVM : function(){
			this._canvas = null;
			$(window).off("resize.broadcastvm");

			if(this.$vmConsole){
				try{
					this.$vmConsole.off("wmksconnected.vm");
					this.$vmConsole.off("wmksdisconnected.vm", this._throttledRefresh);

					this.$vmConsole.wmks("destroy");
				} catch (e){}
			}
		},
		/**
		 * Safely set an WebMKS option
		 *
		 * If the console is not connected, it will wait until is connected to set the option.
		 * Useful if the user sets an option before the WMKS console finishes connecting.
		 */
		setWmksOption : function(option, value){
			if(this.$vmConsole && this.$vmConsole.data("wmks-wmks")){
				if(value !== undefined && option !== "updateFitGuestSize"){
					this.$vmConsole.wmks("option", option, value);
				} else{
					this.$vmConsole.wmks(option, value);
				}
			} else if(this.$vmConsole){
				var self = this;

				this.$vmConsole.one("wmksconnected", function(){

					if(value !== undefined && option !== "updateFitGuestSize"){
						self.$vmConsole.wmks("option", option, value);
					} else{
						self.$vmConsole.wmks(option, value);
					}
				});
			}
		},

		displayState : function(state){

			this.$vmGroup.removeClass("state-loading state-powered-on state-powered-down state-unavailable state-cannot-connect");

			switch(state){
				case this.POWERED_ON:
					this.$vmGroup.addClass("state-powered-on");

					var $canvas = this.$vmConsole.find("canvas");
					this._canvas = $canvas.length && $canvas[0];
					break;
				case this.POWERED_DOWN:
					this.$vmGroup.addClass("state-powered-down");
					break;
				case this.LOADING:
					this.$vmGroup.addClass("state-loading");
					break;

				case this.UNAVAILABLE:
					this.$vmGroup.addClass("state-unavailable");
					break;
				case this.CANNOT_CONNECT:
					this.$vmGroup.addClass("state-cannot-connect");
					break;
			}
			this.$("[rel=popover]").popover("hide");
		},

		windowResizing : function(event){
			if(event && event.target !== window){ return; }
			this.setWmksOption("fitToParent", true);
		},

		toggleFullscreen : function(){
			Screenfull.toggle();
			if(Screenfull.isFullscreen){
				this.$(".full-screen").addClass("animated slow flash");
			} else{
				this.$(".full-screen").removeClass("animated slow flash");
			}
		},

		refreshPage : function() {
			App.reload();
		}
	});

});

