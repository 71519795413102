/*globals App */
define('utilities/analytics/base',['require','underscore','library/vlp/base-class'],function (require) {
	"use strict";

	var _ = require("underscore");

	var BaseClass = require("library/vlp/base-class");

	var masterEventCategories = ["push", "rest", "exception"];

	return BaseClass.extend({
		enable : true,
		name   : "",
		master : false,
		debug  : false,
		initialize : function(info){
			_.bindAll(this);
			if(!info){
				info = {};
			}
			this.master  = info.hasOwnProperty("master")  ? info.master : false;
			this.enabled = info.hasOwnProperty("enabled") ? info.enabled : true;

			this.name = (this.master ? "master" : "client") + App.makeId();

		},
		set : function(name, value){
			this[name] = value;
		},
		trackEvent : function(category, action, label, value, nonInteraction){
			if(!this.enable) { return; }
			if(_.contains(masterEventCategories, category) && !this.master){
				return;
			}

			this._doTrackEvent(category, action, label, value, nonInteraction);
		},
		timing : function(category, variable, value, label){
			if(!this.enable) { return; }

			this._doTiming(category, variable, value, label);
		},
		_doTrackEvent : function(category, action, label, value, nonInteraction){
			console.warn("_doTrackEvent must be overloaded in child analytics class");
			return;
		},
		_doTiming : function(category, variable, value, label){
			console.warn("_doTiming must be overloaded in child analytics class");
			return;
		},
		advertisingCookiesAllowedCallback : function(){
			//override if needed in child class
		},
		functionalCookiesAllowedCallback : function(){
			//override if needed in child class
		},
		performanceCookiesAllowedCallback : function(){
			//override if needed in child class
		},
		socialMediaCookiesAllowedCallback : function(){
			//override if needed in child class
		}

	});

});

