/*globals App */
/**
 * Base Backbone Model
 *
 * This is a base model for all relational Models with the standard data format returned by the server.
 */
define('library/vlp/model',['require','jquery','underscore','backbone','utilities/browser','library/backbone/backbone-validation','library/vlp/types'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var Browser  = require("utilities/browser");

	require("library/backbone/backbone-validation");
	require("library/vlp/types");

	Backbone.Relational.showWarnings = false;

	Backbone._extraSerialize = ["loading","fetched","page","perPage","pageCount","totalCount","prevPage","nextPage", "orderBy", "orderDir"];
	Backbone.toHandlebars = function (value) {
		var processed = {};
		var process = function (model) {

			//Prevents too many recursive calls and creates appropriate circular dependencies.
			var data = {};
			if (model.hasOwnProperty("cid")) {
				if (processed[model.cid]) {
					return processed[model.cid];
				}
				processed[model.cid] = data; //Stash data for future calls on this same object.
			}

			/* jshint ignore:start */
			var attributes = model.attributes || model;

			for (var attr in attributes) {
				if (attributes.hasOwnProperty(attr)) {

					var value = attributes[attr];
					if (value instanceof Backbone.Collection) {
						data[attr] = [];

						value.each(function(item) {
							data[attr].push(process(item));
						});

						_.each(Backbone._extraSerialize, function(extra) {
							if (value[extra] !== undefined) {
								data[attr][extra] = value[extra];
							}
						});
					} else if (value instanceof Backbone.Model) {
						data[attr] = process(value);
					} else if (_.isArray(value)) {
						data[attr] = [];
						for (var arrayItem in value) {
							if (value.hasOwnProperty(arrayItem)) {
								var arrayValue = value[arrayItem];
								if (_.isArray(arrayValue) || arrayValue === Object(arrayValue)) {
									data[attr].push(process(arrayValue));
								} else {
									data[attr].push(arrayValue);
								}
							}
						}
					} else if (_.isDate(value)) {
						data[attr] = value;
					} else if (value === Object(value)) {
						data[attr] = {};
						for (var objectItem in value) {
							if (value.hasOwnProperty(objectItem)) {
								var objectValue = value[objectItem];
								if (_.isArray(objectValue) || objectValue === Object(objectValue)) {
									data[attr][objectItem] = process(objectValue);
								} else {
									data[attr][objectItem] = objectValue;
								}
							}
						}
					} else {
						data[attr] = value;
					}
				}
			}
			/* jshint ignore:end */

			if (model.getters) {
				for (var getterAttr in model.getters) {
					if (model.getters.hasOwnProperty(getterAttr)) {
						data[getterAttr] = _.bind(model.getters[getterAttr], model);
					}
				}
			}

			_.each(Backbone._extraSerialize, function(extra) {
				if (model[extra] !== undefined) {
					data[extra] = model[extra];
				}
			});
			return data;
		};

		if (value instanceof Backbone.Collection) {
			var result = value.map(function (model) {
				return process(model);
			});

			_.each(Backbone._extraSerialize, function(extra) {
				if (value[extra] !== undefined) {
					result[extra] = value[extra];
				}
			});

			return result;
		} else {
			return process(value);
		}
	};

	var BaseModel = Backbone.RelationalModel.extend({
		fetched :  false,
		loading :  false,
		pushSync : false,

		serverBase :   "",
		eventUrl : "",
		createModels : true,

		types :   {},
		getters : {},

		setters : {},

		methodMap :   {
			"create": "POST",
			"update": "PUT",
			"delete": "DELETE",
			"read":   "GET"
		},
		constructor : function () {

			Backbone.RelationalModel.prototype.constructor.apply(this, arguments);

			if (_.isEmpty(this.pushService) && this.model && !_.isEmpty(this.model.prototype.pushService)) {
				this.pushService = this.model.prototype.pushService;
			}

			this.listenTo(this, "request", this._startFetch);
			this.listenTo(this, "sync",    this._endFetch);
			this.listenTo(this, "error",   this._errorFetch);
			this._setupPush();
		},
		get : function (attr) {
			// Call the getter if available
			if (_.isFunction(this.getters[attr])) {
				return this.getters[attr].call(this);
			}

			return Backbone.RelationalModel.prototype.get.call(this, attr);
		},

		set : function (key, value, options) {
			var attrs, attr;

			// Normalize the key-value into an object
			if (_.isObject(key) || key === null) {
				attrs = key;
				options = value;
			} else {
				attrs = {};
				attrs[key] = value;
			}

			// Go over all the set attributes and call the setter if available
			for (attr in attrs) {
				if(attrs.hasOwnProperty(attr)){
				//Fix for string values of "null" coming back from the API
					if(_.isString(attrs[attr]) && attrs[attr] === "null"){
						attrs[attr] = null;
					}

					if (_.isFunction(this.setters[attr])) {
						attrs[attr] = this.setters[attr].call(this, attrs[attr], options);
					}
					if (_.isFunction(this.types[attr])) {
						attrs[attr] = this.types[attr].call(this, attrs[attr], options);
					}
				}
			}

			return Backbone.RelationalModel.prototype.set.call(this, attrs, options);
		},
		triggerChange : function(attrs, options){
			options = {};
			var args = _.toArray(arguments);
			if(args.length > 1){
				if(_.isObject(args[args.length - 1])){
					options = args.pop();
				}
			}
			attrs = [];
			for (var i = 0; i < args.length; i++) {
				var arg = args[i];
				if (_.isArray(arg)) {
					attrs = attrs.concat(arg);
				} else {
					attrs.push(arg);
				}
			}
			for (var j = 0; j < attrs.length; j++) {
				var attr = attrs[j];
				var value = this.get(attr);
				this.changed[attr] = value;
				this.trigger("change:" + attr, this, value, options);
			}
			this.trigger("change", this, options);
		},

		toHandlebars : function () {
			return Backbone.toHandlebars(this);
		},
		_makeURL : function (options, model) {
			var url = options.url;
			if (!url) {
				if (model) {
					url = model.url();
				} else {
					url = this.url();
				}
			}
			if (this.syncParams) {
				options.params = options.params || {};
				_.defaults(options.params, this.syncParams, BaseModel.prototype.syncParams);
			}
			if (options.params) {
				if (url.indexOf("?") === -1) {
					url += "?";
				} else {
					url += "&";
				}
				url += $.param(options.params);
			}
			return url;
		},
		url : function () {

			var urlRoot = _.result(this, "basePath");
			if (!this.isNew()) {
				urlRoot += this.id;
			}
			if (urlRoot.match(/^https?:/)) {

				return urlRoot;
			}
			return this.serverBase + urlRoot;
		},

		pushReturnEvent : function(){
			var pushEvent = "received:" + _.result(this, "pushService");

			if(!this.isNew()){
				pushEvent += ":" + this.id;
			}
			return pushEvent;
		},
		parse: function (response, options) {
			var name = _(_.result(this, "basePath")).trim("/");

			var result = response;
			//Top level response call from the server
			if (options && !options._parsed) {
				result = result.data;
				options._parsed = true;
				if (!result) { return {}; }
			}

			//Check if data resides under an object that is named like its path.
			//IE { data : { catalogs : [] } }
			if (result[name] && (_.isObject(result[name]) || _.isArray(result[name]))) {
				result = result[name];
			}
			name = name.replace(/(es|s)$/, "");
			if (result[name] && (_.isObject(result[name]) || _.isArray(result[name]))) {
				result = result[name];
			}

			if (_.isArray(result)) {
				result = result.shift();
			}
			return result;
		},
		sync: function (method, model, options) {
			options = options || {};

			BaseModel.prototype._setupDebug.call(this, options);


			options.pushSync = (options.hasOwnProperty("pushSync") ? options.pushSync : model.pushSync || false);
			options.service  = options.service || _.result(model, "pushService");

			if (options.returnPath === "none") {
				if (options.data) {
					options.data.returnPath = "none";
				} else if (options.params) {
					options.params.returnPath = "none";
				}
			} else if (options.returnPath === "push") {
				options.pushReturn = true;
			}

			var pushReturn = (!_.isEmpty(options.service) && App.push && (options.pushSync || options.pushReturn || options.pushFetch) && options.returnPath !== "none");

			if (pushReturn) {
				return BaseModel.prototype._pushSync.call(this, method, model, options);
			} else {
				//normal non-push call
				return BaseModel.prototype._restSync.call(this, method, model, options);
			}
		},
		_restSync : function(method, model, options){

			options.url = this._makeURL(options, model);

			var modelData;
			if (!options.hasOwnProperty("data") || !_.isObject(options.data)) {
				//Don"t send relations on POST/PUT unless specified
				if (method === "read" && !options.properties) {
					options.properties = [];
				}
				modelData = model.toJSON();
				var withRelations = options["with"] || {};
				var properties = options.properties || null;

				delete options["with"];
				delete options.properties;

				if(model instanceof Backbone.RelationalModel){
					_.each(model.getRelations(), function (relation) {
						if (_.indexOf(withRelations, relation.key) === -1) {
							delete modelData[relation.key];
						}
					});
				}

				if (properties) {
					var tModelData = {};
					_.each(properties, function (property) {
						if (modelData.hasOwnProperty(property)) {
							tModelData[property] = modelData[property];
						}
					});
					modelData = tModelData;
				} else{
					for(var i in modelData){
						if(modelData.hasOwnProperty(i)){
							if(modelData[i] instanceof Backbone.Collection && _.indexOf(withRelations, i) === -1){
								delete modelData[i];
							}
						}
					}

				}
				options.data = modelData;
			}

			if (method !== "read" && options.hasOwnProperty("data") && _.isObject(options.data)) {
				options.contentType = options.contentType || "application/json";
				options.data = JSON.stringify(options.data);
			}
			if (options.error) {
				if (model.parseError) {
					var error = options.error;
					options.error = function (resp) {
						resp = model.parseError(resp);
						error(resp);
					};
				}
				options.error._hasUserHandler = !!options._hasOriginalError;
			}

			if (Browser.firefox && !options.async) {
				delete options.async;
			}
			if (App.ajaxDataType === "jsonp") {
				options.dataType = "jsonp";
				if(options.data === "{}"){ options.data = ""; }
			}
			return Backbone.sync(method, model, options);
		},
		_pushSync : function(method, model, options){
			var self = this;

			var operation = options.operation || options.action || this.methodMap[method];

			var successCallback = null;
			var timeout = 0;
			var timeoutId = null;
			var timeoutCallback = null;
			var call = null;
			var recall = null;
			var attempts = 0;


			if (options.hasOwnProperty("pushTimeout")) {
				timeout = options.pushTimeout;
			} else {
				timeout = App.config.pushTimeout;
			}

			var success = options.success;
			delete options.success;

			var error = options.error;
			delete options.error;

			if (options.pushFetch || (success && (!success.hasOwnProperty("original") || success.original)) || (error && options._hasOriginalError)) {
				var pushReturnEvent;
				if (options.pushReturnEvent) {
					pushReturnEvent = options.pushReturnEvent;
				} else if (options.service) {
					pushReturnEvent = "received:" + options.service;
					if (!this.isNew()) {
						pushReturnEvent += ":" + this.id;
					}
				} else {
					pushReturnEvent = _.result(model, "pushReturnEvent");
				}

				if (operation) {
					pushReturnEvent += ":" + operation;
				}
				successCallback = function (info) {
					call = null;
					var successStatus;
					if (timeoutId) {
						clearTimeout(timeoutId);
					}
					if (info.data) {
						model.set(model.parse(info.data));
					}
					if (info.data && info.data.hasOwnProperty("success")) {
						successStatus = _.toBoolean(info.data.success);
						if (successStatus && success) {
							success(info, info.message, {});
						} else if (error) {
							error(info, info.message, {});
						}
					} else if (info.hasOwnProperty("success")) {
						successStatus = _.toBoolean(info.success);
						if (successStatus && success) {
							success(info, info.message, {});
						} else if (error) {
							error(info, info.message, {});
						}
					} else {
						if (success) {
							success(info, info.message, {});
						}
					}
					if(options.complete){ options.complete(info, info.message); }
				};
				App.push.once(pushReturnEvent, successCallback);
			}

			//return result on push channel only
			options.params = _.defaults(options.params || {}, {
				returnPath: "push"
			});


			if (timeout && successCallback) {

				recall = function () {
					if (timeoutId) { clearTimeout(timeoutId); }

					if (call) {
						console.warn("Recalling " + pushReturnEvent + " after failure. Call attempts:", attempts);
						call();
					}
				};

				timeoutCallback = function () {

					if (timeoutId) { clearTimeout(timeoutId); }

					if (method === "read" && App.config.pushTimeoutRetries >= attempts) {
						timeoutId = setTimeout(recall, timeout);
						App.push.once("opened", recall);
						App.push.throttledReopen();
					} else {
						call = null;
						if (successCallback) {
							App.push.off(pushReturnEvent, successCallback);
						}
						var errorMessage = "Push call timed out " + pushReturnEvent +  (options.pushReturn && !options.pushSync ? ":pushReturn" : ":pushSync");
						if (error) {
							error(model, { response : { message : errorMessage, errorCode : -1}}, options);
						} else{
							throw new Error(errorMessage);
						}
						model.trigger("push:timeout");
						if(options.complete){ options.complete(); }
					}
				};
			}

			if (options.pushReturn && !options.pushSync) {
				//REST call with with push return
				call = function () {
					if (timeoutId) { clearTimeout(timeoutId); }
					if (timeout && timeoutCallback) {
						if (recall) { App.push.off("opened", recall); }
						timeoutId = setTimeout(timeoutCallback, timeout);
					}

					attempts++;
					return BaseModel.prototype._restSync.call(self, method, model, _.clone(options));
				};
			} else {

				//Push call
				var requestParams = options.params || {};
				if (!model.isNew() && !requestParams.id) {
					requestParams.id = model.id;
				}

				//Don"t send relations on POST/PUT unless specified
				if (!options.data) {
					options.data = model.toJSON();

					var withRelations = options["with"] || {};
					var properties = options.properties || null;

					_.each(model.getRelations(), function (relation) {
						if (_.indexOf(withRelations, relation.key) === -1) {
							delete options.data[relation.key];
						}
					});

					if (properties) {
						var tData = {};
						_.each(properties, function (property) {
							if (options.data.hasOwnProperty(property)) {
								tData[property] = options.data[property];
							}
						});
						options.data = tData;
					}

				}

				delete options["with"];
				delete options.properties;


				var broadcast = (options.hasOwnProperty("pushBroadcast") ? options.pushBroadcast : model.pushBroadcast || false);

				var data = {};
				data.type    = options.service;
				data.service = options.service;
				if (!options.local) {
					data.component = (options.hasOwnProperty("component") ? options.component : model.component || "core");
				}
				data.data = options.data;
				data.requestParams = requestParams;
				data.broadcast = broadcast;

				data.operation = operation;
				call = function () {
					if (timeoutId) { clearTimeout(timeoutId); }
					if (timeout && timeoutCallback) {
						if (recall) { App.push.off("opened", recall); }
						timeoutId = setTimeout(timeoutCallback, timeout);
					}

					attempts++;
					if(options.beforeSend){
						options.beforeSend();
					}
					App.push.send(data);
				};
			}


			if (call) {
				return call();
			}

		},
		fetch:        function (options) {

			var tOptions = _.clone(options || {});

			tOptions.params = tOptions.params || {};

			for (var i in tOptions.params) {
				if (tOptions.params.hasOwnProperty(i) && _.isArray(tOptions.params[i])) {
					tOptions.params[i] = tOptions.params[i].join(",");
				}
			}
			if (tOptions.error) {
				tOptions._hasOriginalError = true;
			}
			//call parent setup
			return Backbone.RelationalModel.prototype.fetch.call(this, tOptions);

		},
		save:         function (key, val, options) {
			var attrs;

			// Handle both `"key", value` and `{key: value}` -style arguments.
			if (key === null || key === undefined || typeof key === "object") {
				attrs = key;
				options = val;
			} else {
				(attrs = {})[key] = val;
			}

			if (options.properties){
				options.validateAll = false;
			}
			if (options.error) {
				options._hasOriginalError = true;
			}
			return Backbone.RelationalModel.prototype.save.call(this, attrs, options);
		},
		destroy:      function (options) {
			if (options.error) {
				options._hasOriginalError = true;
			}
			return Backbone.RelationalModel.prototype.destroy.call(this, options);
		},
		basePath:     function () {
			var basePath = _.result(this, "urlRoot");
			if (!basePath && this.collection) {
				basePath = this.collection.prototype.urlRoot;
			}

			if (this.serverBase.charAt(this.serverBase.length - 1) === "/" && basePath.charAt(0) === "/") {
				basePath = basePath.substring(1);
			}
			if (basePath.charAt(basePath.length - 1) !== "/") {
				basePath += "/";
			}

			return basePath;
		},
		action:       function (action, options) {

			options = options || {};
			var method = options.method || "create";
			delete options.method;
			options.action = action;
			if (!options.url) {
				options.url = _.rtrim(this.url(), "/") + "/" + action;
			}

			var model = this;
			var success = options.success;
			options.success = function (resp) {
				if (!model.set(model.parse(resp, options), options)) { return false; }
				if (success) { success(model, resp); }
			};
			options.success.original = success;
			if (options.error) {
				options._hasOriginalError = true;
			}
			var error = options.error;
			options.error = function (resp) {
				if (error) { error(model, resp, options); }
				model.trigger("error", model, resp, options);
			};
			return this.sync(method, this, options);
		},
		parseError:   function (resp) {

			var response;
			if (_.isObject(resp.response)) {
				return resp;
			}
			if (resp.responseText) {
				try {
					response = JSON.parse(resp.responseText);
				} catch (error) {
					response = {};
					response.message = resp.statusText;
				}
			}
			resp.response = response;


			if(!resp.response) { return resp; }

			if(resp.response.errorCode && !_.isNumber(resp.response.errorCode)){
				var number = _.toNumber(resp.response.errorCode);
				if(number.toString() === resp.response.errorCode){
					resp.response.errorCode = number;
				}
			}
			if(resp.response.errors){
				//Reformat errors
				var errors = {};
				if(_.isArray(resp.response.errors)){
					_.each(resp.response.errors, function(error){
						if(_.isObject(error)){
							_.each(error, function(item, key){
								if(!errors.hasOwnProperty(key)){
									errors[key] = [];
								}
								errors[key].push(item);
							});
						}
					});
				}
				resp.response.errors = errors;
			}
			return resp;
		},

		_setupDebug : function(options){
			if(!options || !options.debug) { return; }
			if(!App.config.debug){
				delete options.debug;
				return;
			}
			if(_.isArray(options.debug)){
				options.debug = options.debug.join(",");
			}
			var info = "";
			info+= Browser.name + "_" + Browser.version.full + "_" + Browser.osType;
			options.debug = info + "-" + options.debug;
			options.debug+= "-" + App.makeId();
			if(options.params){
				options.params.debug = options.debug;
			} else if (options.data){
				options.data.debug = options.debug;
			} else{
				options.params = {};
				options.params.debug = options.debug;
			}
		},

		_setupPush : function(pushService){
			pushService = pushService || _.result(this, "pushService");
			if(_.isEmpty(pushService)) { return; }

			this.on("change:" + this.idAttribute, this._registerPushFetch, this);
			this._registerPushFetch();

		},
		_registerPushFetch : function(){
			if(!App.push) { return; }
			var pushService = _.result(this, "pushService");
			var event = pushService + ":" + this.id;
			App.push.off(event, this._pushUpdated, this);
			App.push.on(event, this._pushUpdated, this);
		},
		_pushUpdated : function(info){
			var data = info.data;
			if(data === null || data === undefined){
				return;
			}

			data = this.parse(data);

			this.set(data);
			if(info.operation){
				this.trigger(info.operation, this);
			}

		},
		_startFetch : function(model){
			if(model === this) {
				this.loading = true;
			}
		},
		_endFetch : function(model){
			if(model === this){
				this.loading  = false;
				this.fetched = true;
			}
		},
		_errorFetch : function(model){
			if(model === this) {
				this.loading = false;
			}
		},
		_validate : function(attrs, options){
			if (!options.validate || !this.validate) { return true; }

			if (options.validateAll !== false) {
				attrs = _.extend({}, this.attributes, attrs);
			} else if(options.properties && _.isArray(options.properties)){
				attrs = _.extend({}, _.pick(this.attributes, options.properties), attrs);
			}
			var error = this.validationError = this.validate(attrs, options) || null;
			if (!error) { return true; }
			this.trigger("invalid", this, error, _.extend(options, {validationError: error}));
			return false;
		},
		// This is called by Backbone when it needs to perform validation.
		// You can call it manually without any parameters to validate the
		// entire model.
		validate: function(attrs, setOptions){
			var self = this;
			var opt = _.extend({}, setOptions);

			var validations = _.keys(_.result(this, "validation") || {});
			if(!attrs){
				attrs = this.attributes;
			}
			var validatedAttrs = _.pick(attrs, validations);


			var result = this.preValidate(validatedAttrs);

			this._isValid = !result;
			var invalidAttrs = result || {};


			// Trigger validated events.
			// Need to defer this so the model is actually updated before
			// the event is triggered.
			_.defer(function() {
				self.trigger("validated", self._isValid, self, invalidAttrs, attrs);
				self.trigger("validated:" + (self._isValid ? "valid" : "invalid"), self, invalidAttrs, attrs);
			});

			// Return any error messages to Backbone, unless the forceUpdate flag is set.
			// Then we do not return anything and fools Backbone to believe the validation was
			// a success. That way Backbone will update the model regardless.
			if (!opt.forceUpdate && !this._isValid) {
				return invalidAttrs;
			} else{
				return false;
			}
		}
	});

	Backbone.Validation.configure({
		labelFormatter: "none"
	});
	_.defaults(BaseModel.prototype, Backbone.Validation.mixin);
	return BaseModel;

});

