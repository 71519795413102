
define('hbs!tpls/catalogs/enrollment-lab.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return " new-lab";
},"3":function(depth0,helpers,partials,data) {
    return "with-systems";
},"5":function(depth0,helpers,partials,data) {
    return "with-badges";
},"7":function(depth0,helpers,partials,data) {
    return "with-reviews";
},"9":function(depth0,helpers,partials,data) {
    return "<span class=\"new-lab-label\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.new",{"name":"$","hash":{},"data":data}))
    + "</span>";
},"11":function(depth0,helpers,partials,data) {
    var stack1;

  return this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0['class'] : depth0)) != null ? stack1.name : stack1), depth0))
    + ": ";
},"13":function(depth0,helpers,partials,data) {
    return "				<div class=\"clearfix\">\n					<div class=\"alert alert-info\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.submittedForGrade",{"name":"$","hash":{},"data":data}))
    + "\n					</div>\n				</div>\n";
},"15":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "				<div class=\"clearfix\">\n					<span class=\"lab-progress-title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.progress",{"name":"$","hash":{},"data":data}))
    + "</span>\n					<div class=\"lab-progress-container\">\n						<div class=\"progress\">\n							<div class=\"bar\" style=\"width: "
    + this.escapeExpression(((helper = (helper = helpers.progress || (depth0 != null ? depth0.progress : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"progress","hash":{},"data":data}) : helper)))
    + "%;\"></div>\n						</div>\n						<span class=\"fa fa-2x fa-va fa-fw fa-clock-o "
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.timeRemaining : depth0),0,{"name":"ne","hash":{},"fn":this.program(16, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " progress-warning-icon\"></span>\n					</div>\n				</div>\n\n				<div class=\"clearfix\">\n					<span class=\"lab-time-remaining-title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.timeRemaining",{"name":"$","hash":{},"data":data}))
    + "</span>\n					<span class=\"time-remaining-label "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.timeRemaining : depth0),0,{"name":"eq","hash":{},"fn":this.program(18, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + this.escapeExpression(((helper = (helper = helpers.timeRemainingLabel || (depth0 != null ? depth0.timeRemainingLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"timeRemainingLabel","hash":{},"data":data}) : helper)))
    + "</span>\n				</div>\n";
},"16":function(depth0,helpers,partials,data) {
    return "hide";
},"18":function(depth0,helpers,partials,data) {
    return "warning";
},"20":function(depth0,helpers,partials,data) {
    return "				<button type=\"button\" class=\"btn invite-friend\" data-toggle=\"popover\" data-html=\"true\" data-trigger=\"click\" data-placement=\"top\" data-class=\"popover-invite\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"share.invite.buttonInvite",{"name":"$","hash":{},"data":data}))
    + "</button>\n";
},"22":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = this.invokePartial(partials.catalogs_enrollment_unenroll,depth0,{"name":"catalogs_enrollment_unenroll","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + ((stack1 = this.invokePartial(partials.catalogs_enrollment_start_button,depth0,{"name":"catalogs_enrollment_start_button","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "");
},"24":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = this.invokePartial(partials.catalogs_enrollment_unenroll,depth0,{"name":"catalogs_enrollment_unenroll","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "				<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"page":"console/{{id}}"},"data":data}))
    + "\" class=\"btn btn-large btn-primary\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.resumeLab",{"name":"$","hash":{},"data":data}))
    + "</a>\n";
},"26":function(depth0,helpers,partials,data) {
    return "				<button type=\"button\" class=\"btn btn-large btn-default disabled\" disabled>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.expired",{"name":"$","hash":{},"data":data}))
    + "</button>\n";
},"28":function(depth0,helpers,partials,data) {
    return "				<button type=\"button\" class=\"btn btn-large btn-default disabled\" disabled>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.awaitingGrade",{"name":"$","hash":{},"data":data}))
    + "</button>\n";
},"30":function(depth0,helpers,partials,data) {
    return "				<button type=\"button\" class=\"btn btn-large btn-default disabled\" disabled>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.suspended",{"name":"$","hash":{},"data":data}))
    + "</button>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"lab-box enrollment-lab"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.newItem : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.systems : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.badges : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"ratingEnabled",{"name":"config","hash":{},"data":data}),(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"reviewsEnabled",{"name":"config","hash":{},"data":data}),{"name":"or","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-lab-id=\""
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n	"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.newItem : depth0),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n\n	<div class=\"lab-top\">\n"
    + ((stack1 = this.invokePartial(partials.catalogs_lab_systems,(depth0 != null ? depth0.systems : depth0),{"name":"catalogs_lab_systems","data":data,"indent":"\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + ((stack1 = this.invokePartial(partials.catalogs_lab_reviews_and_ratings,depth0,{"name":"catalogs_lab_reviews_and_ratings","data":data,"indent":"\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + ((stack1 = this.invokePartial(partials.catalogs_lab_badges,(depth0 != null ? depth0.badges : depth0),{"name":"catalogs_lab_badges","data":data,"indent":"\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "	</div>\n\n	<div class=\"lab-middle\">\n		<div class=\"lab-image\">\n			<img data-property=\"thumbnail\" src=\""
    + this.escapeExpression(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"alt","catalogs.labs.thumbnailAlt",(depth0 != null ? depth0.name : depth0),{"name":"$$","hash":{},"data":data}))
    + "/>\n		</div>\n		<div class=\"lab-text\">\n			<h3 class=\"lab-name skin-text-popout\">"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0['class'] : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n\n\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"awaitingGrade",{"name":"eq","hash":{},"fn":this.program(13, data, 0),"inverse":this.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "		</div>\n		<div class=\"lab-buttons\">\n"
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.display : depth0)) != null ? stack1.showInvite : stack1),{"name":"if","hash":{},"fn":this.program(20, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n			<div class=\"spinner pull-right start-spinner hide\"></div>\n"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"incomplete",{"name":"eq","hash":{},"data":data}),(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"ready",{"name":"eq","hash":{},"data":data}),(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"gone",{"name":"eq","hash":{},"data":data}),{"name":"or","hash":{},"fn":this.program(22, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"active",{"name":"eq","hash":{},"fn":this.program(24, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"expired",{"name":"eq","hash":{},"fn":this.program(26, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"awaitingGrade",{"name":"eq","hash":{},"fn":this.program(28, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"suspended",{"name":"eq","hash":{},"fn":this.program(30, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n\n	<div class=\"info-box-header lab-detail-header closed\">\n		<h3>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.showDetails",{"name":"$","hash":{},"data":data}))
    + "</h3>\n	</div>\n	<div class=\"info-box lab-detail\">\n\n"
    + ((stack1 = this.invokePartial(partials.catalogs_lab_common_metadata,depth0,{"name":"catalogs_lab_common_metadata","data":data,"indent":"\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + ((stack1 = this.invokePartial(partials.catalogs_lab_common_metadata_links,depth0,{"name":"catalogs_lab_common_metadata_links","data":data,"indent":"\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "	</div>\n</div>\n";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('catalogs_enrollment_lab', t);
return t;
});

