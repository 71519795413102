
define('hbs!tpls/console/video-conference.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.joined : depth0),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.inviteUrl : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.joined : depth0),{"name":"unless","hash":{},"fn":this.program(6, data, 0),"inverse":this.program(16, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    return "		<button class=\"btn-no-style reload fa fa-refresh\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.videoConference.zoom.reload",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button>\n";
},"4":function(depth0,helpers,partials,data) {
    var helper;

  return "		<div class=\"invitation-url\">\n			<a href=\""
    + this.escapeExpression(((helper = (helper = helpers.inviteUrl || (depth0 != null ? depth0.inviteUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"inviteUrl","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\"open-external\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.videoConference.zoom.openInApp",{"name":"$","hash":{},"data":data}))
    + " <i class=\"fa fa-external-link\"></i></a>\n		</div>\n";
},"6":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.openedExternally : depth0),{"name":"unless","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		<div class=\"join-button\">\n			<button type=\"button\" class=\"btn btn-large join-conference\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.videoConference.zoom.join",{"name":"$","hash":{},"data":data}))
    + "</button>\n		</div>\n"
    + ((stack1 = (helpers.and || (depth0 && depth0.and) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.showRemoteControl : depth0),(depth0 != null ? depth0.remoteUrl : depth0),{"name":"and","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.openedExternally : depth0),{"name":"if","hash":{},"fn":this.program(14, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"7":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.mode : depth0),"student",{"name":"eq","hash":{},"fn":this.program(8, data, 0),"inverse":this.program(10, data, 0),"data":data})) != null ? stack1 : "");
},"8":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<div class=\"well\">"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.videoConference.zoom.helpMessageStudent",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"10":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<div class=\"well\">"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.videoConference.zoom.helpMessageInstructor",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"12":function(depth0,helpers,partials,data) {
    var helper;

  return "			<div class=\"join-button open-remote-control\">\n				<a class=\"btn btn-large\" target=\"_blank\" href=\""
    + this.escapeExpression(((helper = (helper = helpers.remoteUrl || (depth0 != null ? depth0.remoteUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"remoteUrl","hash":{},"data":data}) : helper)))
    + "\"  >"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.videoConference.consoleRemote",{"name":"$","hash":{},"data":data}))
    + "</a>\n			</div>\n";
},"14":function(depth0,helpers,partials,data) {
    return "			<div class=\"alert alert-warning\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.videoConference.zoom.openedExternally",{"name":"$","hash":{},"data":data}))
    + "</div>\n";
},"16":function(depth0,helpers,partials,data) {
    return "		<iframe src=\"/zoom-iframe.html\"\n			sandbox=\"allow-forms allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox\"\n			allow=\"microphone; camera; fullscreen;\"\n			id=\"video_conference_frame\"></iframe>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,((stack1 = (depth0 != null ? depth0.videoConference : depth0)) != null ? stack1.type : stack1),"zoom",{"name":"eq","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
Handlebars.registerPartial('console_video_conference', t);
return t;
});

