define('views/console/panel',['require','jquery','underscore','backbone','library/vlp/app','utilities/browser'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App      = require("library/vlp/app"),
	    Browser  = require("utilities/browser");

	return Backbone.View.extend({


		previouslyExpanded : false,

		events : {
			"click [data-function=\"float\"]"          : "float",
			"click [data-function=\"close\"]"          : "close",
			"click [data-function=\"dock-left\"]"      : "dockLeft",
			"click [data-function=\"dock-right\"]"     : "dockRight",
			"click [data-function=\"slide-or-focus\"]" : "focusOrToggleExpand",
			"click"                                    : "setFocus"
		},
		initialize : function(options){
			_.bindAll(this);

			this.state = {};

			var data = this.$el.data();

			this.state.top       = this.$el.offset().top;
			this.state.left      = this.$el.offset().left;
			this.state.width     = this.$el.width();
			this.state.height    = this.$el.height();
			this.state.position  = data.position  || "left";
			this.state.floating  = data.float     || false;
			this.state.resizing  = false;
			this.state.hidden    = false;


			this.resizeMode = (data.hasOwnProperty("resizeMode") ? data.resizeMode : "both");
			this.resizableHorizontal = (this.resizeMode === "both" || this.resizeMode === "width");
			this.resizableVertical   = (this.resizeMode === "both" || this.resizeMode === "height");

			if(App.remoteControl) {
				this.state.expanded = false;
			} else {
				this.state.expanded = data.expanded || false;
			}

			this.state.focused  = data.focused  || false;
			this.state.float = {};
			this.state.float.top    = null;
			this.state.float.left   = null;
			this.state.float.width  = null;
			this.state.float.height = null;


			this.minWidth  = parseInt((this.$el.css("min-width")  || "").replace(/\D+$/, ""), 10)  || 240;
			this.minHeight = parseInt((this.$el.css("min-height") || "").replace(/\D+$/, ""), 10)  || 370;

			this.state.docked = {};
			this.state.docked.width = this.minWidth;

			this.$el
				.off("dragstop.panel").on("dragstop.panel", this.update)
				.off("resizestop.panel").on("resizestop.panel", this.update);

			if(Browser.webkit){
				this.$el.off("dragstop.panel resizestop.panel").on("dragstop.panel resizestop.panel", function(){
					$("#body").redraw();
				});
			}

			if(this.state.floating){
				this.convertToFloat();
			} else if (this.state.position === "left") {
				this.dock("left");
			} else if (this.state.position === "right") {
				this.dock("right");
			}
		},
		update : function(){
			this.state.top    = this.$el.offset().top;
			this.state.left   = this.$el.offset().left;
			this.state.width  = this.$el.width();
			this.state.height = this.$el.height();

			if(this.state.expanded){
				this.previouslyExpanded = true;
				this.$el
					.addClass("expanded")
					.removeClass("collapsed");
			} else{
				this.$el
					.removeClass("expanded")
					.addClass("collapsed");
			}

			if(this.state.floating){
				this.state.float.top = this.state.top;
				this.state.float.left = this.state.left;
				this.state.float.width = this.state.width;
				this.state.float.height = this.state.height;

				this.$el
					.removeClass("left right docked")
					.addClass("float");
			} else{

				this.$el
					.removeClass("float left right")
					.addClass("docked");

				if(this.state.position === "left"){
					this.$el.addClass("left");
				} else if(this.state.position === "right"){
					this.$el.addClass("right");
				}
				this.$el.css({"right": "", "left" : ""});

			}
			this.$el.toggleClass("focused", this.state.focused);
			this.checkVisibility();
		},
		dockLeft : function(){
			this.dock("left");
		},
		dockRight : function(){
			this.dock("right");
		},
		float : function(){
			if(!this.state.floating){
				this.convertToFloat();
			}
		},
		toggleDockFloat : function(){
			if(this.state.floating){
				this.dock();
			} else{
				this.convertToFloat();
			}
		},
		focusOrToggleExpand : function(){
			if(this.state.expanded && this.state.focused){
				this.close();
			} else if(this.state.expanded && !this.state.focused){
				//do nothing, handled by setFocus
			} else {
				this.expand();
			}
		},
		setFocus : function(){
			this.trigger("panel:focus", this);
			this.checkVisibility();
		},
		dock : function(dockPosition){
			var self = this;

			this.state.floating = false;
			this.state.resizing = false;

			if(this.$el.data("ui-resizable")) { this.$el.resizable("destroy"); }
			if(!App.remoteControl && this.resizableHorizontal) {
				this.$el.resizable({
					minWidth  : this.minWidth,
					handles   : "n, e, s, w, se",
					resize    : function(event, ui){
						self.$el.css({
							left   : "",
							right  : "",
							height : "",
							top    : "",
							width  : ""
						});

						self.state.resizing = true;
						self.state.docked.width = ui.size.width;
						//Also resize siblings
						//self.$el.siblings(".panel").width(self.$el.width());
						self.trigger("panel:resizing", self, ui.size.width);
					},
					stop : function(event, ui){
						self.$el.css({
							left   : "",
							right  : "",
							height : "",
							top    : "",
							width  : ""
						});

						self.state.resizing = false;
						self.state.docked.width = ui.size.width;
						//Also resize siblings
						//self.$el.siblings(".panel").width(self.$el.width());
						self.$el.addClass("resized");
						self.trigger("panel:resized", self, ui.size.width);
					}
				});

			}

			this.$el.draggable({ disabled : true });
			this.$el.removeAttr("style");

			this.state.position = dockPosition || this.state.position || "right";

			this.resetSize();
			this.$el.find(".ui-resizable-s,.ui-resizable-n,.ui-resizable-e,.ui-resizable-w,.ui-resizable-se").hide();

			if(this.state.position === "left"){
				this.$el.find(".dock-arrow-right").parent().removeClass("hide");
				this.$el.find(".dock-arrow-left").parent().addClass("hide");
				if(this.resizableHorizontal){
					this.$el.find(".ui-resizable-e").show();
				}

			} else if(this.state.position === "right"){
				this.$el.find(".dock-arrow-left").parent().removeClass("hide");
				this.$el.find(".dock-arrow-right").parent().addClass("hide");
				if(this.resizableHorizontal){
					this.$el.find(".ui-resizable-w").show();
				}
			}
			this.checkVisibility();
			this.trigger("panel:dock", this);

			if(this.state.expanded){
				this.trigger("panel:beforeexpand", this);
				this.trigger("panel:expand", this);
			}
			this.$("[rel=tooltip]").tooltip("hide");
			this.update();
		},
		convertToFloat : function(options){
			if(options && options.preventDefault){ options = {}; }
			options = _.defaults(options || {}, {trigger : true});
			var self = this;

			var width  = this.state.float.width  || this.minWidth;
			var height = this.state.float.height || this.minHeight;
			var left   = this.state.float.left;
			var top    = this.state.float.top    || "100px";

			this.state.floating = true;
			this.state.resizing = false;

			this.resetSize();
			if(this.$el.data("ui-resizable")) { this.$el.resizable("destroy"); }
			if(this.resizableHorizontal || this.resizableVertical){
				this.$el.resizable({
					minWidth  : this.minWidth,
					minHeight : this.minHeight,
					containment: "body",
					handles   : "n, e, s, w, se",

					resize    : function(){
						self.state.resizing = true;
						self.update();
						// empty stop function needed to cancel docks "resizables" top/position fixes
					},

					stop      : function(){
						self.state.resizing = false;
						self.update();
						// empty stop function needed to cancel docks "resizables" top/position fixes
					}
				});

				this.$el.find(".ui-resizable-s,.ui-resizable-n,.ui-resizable-e,.ui-resizable-w,.ui-resizable-se").hide();
				if(this.resizableVertical){
					this.$el.find(".ui-resizable-s,.ui-resizable-n").show();
				}
				if(this.resizableHorizontal){
					this.$el.find(".ui-resizable-e,.ui-resizable-w").show();
				}
				if(this.resizableHorizontal && this.resizableVertical){
					this.$el.find(".ui-resizable-se").show();
				}
			}
			this.$el.draggable({
				disabled : false,
				cancel   : ".current, #vm_listing, select",
				containment: "body",
				stop : function(){
					self.update();
					self.$el.redraw();
				}
			});

			this.$el.css({
				width  : width,
				height : height,
				top    : top
			});

			if(!left){
				if(this.state.position === "left"){
					this.$el.css({
						left  : "15px",
						right : ""
					});
				} else if(this.state.position === "right"){
					this.$el.css({
						left  : "",
						right : "15px"
					});
				}

			} else{
				this.$el.css({
					left  : left,
					right : ""
				});
			}


			this.$el.find(".dock-arrow-left").parent().removeClass("hide");
			this.$el.find(".dock-arrow-right").parent().removeClass("hide");

			this.$("[rel=tooltip]").tooltip("hide");
			this.checkVisibility();
			if(options.trigger) { this.trigger("panel:float", this); }
			this.update();
		},
		close : function(options){
			if(options && options.preventDefault){ options = {}; }
			options = _.defaults(options || {}, {trigger : true});

			if(this.state.floating){
				this.dock();
			}

			if(this.state.expanded){
				this.$("[rel=tooltip]").tooltip("hide");
				this.state.expanded = false;
				this.update();

				if(options.trigger) { this.trigger("panel:close", this); }
			}

		},
		expand : function(options){
			if(options && options.preventDefault){ options = {}; }
			options = _.defaults(options || {}, {trigger : true});
			if(!this.state.expanded){
				this.$("[rel=tooltip]").tooltip("hide");
				if(options.trigger) { this.trigger("panel:beforeexpand", this, options); }
				this.state.expanded = true;
				this.update();
				if(options.trigger) { this.trigger("panel:expand", this, options); }
			}
		},
		unfocus : function(){
			this.state.focused = false;
			this.update();
			this.trigger("panel:unfocus", this);
		},
		focus : function(){
			this.state.focused = true;
			this.update();
		},
		setWidth : function(width, options){
			this.state.docked.width = Math.max(width, this.minWidth);
			this.update();
			if(options && options.trigger){
				this.trigger("panel:resized", this, this.state.docked.width);
			}
		},
		setDockedMinWidth : function(minWidth){
			if(this.state.floating || !this.resizableHorizontal){
				return;
			}
			minWidth = parseInt(minWidth, 10);

			if(this.$el.data("ui-resizable")){
				if(minWidth > this.minWidth){
					this.$el.resizable("option", "minWidth", minWidth);
				} else if(minWidth === 0){
					this.$el.resizable("option", "minWidth", this.minWidth);
				}
			}
		},
		resetSize : function(){
			if(!this.resizableHorizontal){
				this.$el.css("width", "");
				this.state.width = this.$el.width();
			}
			if(!this.resizableVertical){
				this.$el.css("height", "");
				this.state.height = this.$el.height();
			}
		},
		show : function(){
			this.$el.removeClass("hide");
			this.state.hidden = false;
			this.checkVisibility();
			this.trigger("panel:show", this);
		},
		hide : function(){
			this.$el.addClass("hide");
			this.state.hidden = true;
			this.checkVisibility();
			this.trigger("panel:hide", this);
		},
		checkVisibility : function(){
			if(this.state.hidden){
				this.state.visible = false;
			} else if(this.state.floating){
				this.state.visible = true;
			} else if(this.state.expanded && (this.state.focused || this.$el.closest(".dock-left,.dock-right").find("> .panel").length === 1)){
				this.state.visible = true;
			} else {
				this.state.visible = false;
			}
		}

	});

});

