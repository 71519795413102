
define('hbs!tpls/console/manual-step-listing.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper;

  return "				<li data-step-id=\""
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-step-number=\""
    + this.escapeExpression(((helper = (helper = helpers.stepNo || (depth0 != null ? depth0.stepNo : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"stepNo","hash":{},"data":data}) : helper)))
    + "\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depths[1] != null ? depths[1].remoteControlMode : depths[1]),{"name":"if","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.program(4, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "				</li>\n";
},"2":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "						<span>"
    + ((stack1 = ((helper = (helper = helpers.stepLabel || (depth0 != null ? depth0.stepLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"stepLabel","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n";
},"4":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "						<a "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.manual.jumpStep",(depth0 != null ? depth0.stepLabel : depth0),{"name":"$$","hash":{},"data":data}))
    + ">"
    + ((stack1 = ((helper = (helper = helpers.stepLabel || (depth0 != null ? depth0.stepLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"stepLabel","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</a>\n";
},"6":function(depth0,helpers,partials,data) {
    return "	<button class=\"btn-no-style steps-button-previous\">\n		<span class=\"fa fa-2x fa-angle-left\"></span>\n	</button>\n	<button class=\"btn-no-style steps-button-next\">\n		<span class=\"fa fa-2x fa-angle-right\"></span>\n	</button>\n";
},"8":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.alternativeLocales : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.program(12, data, 0),"data":data})) != null ? stack1 : "");
},"9":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<div class=\"alternative-locale-choose\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.alternativeLocales",{"name":"$","hash":{},"data":data}))
    + "\n					<ul>\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.alternativeLocales : depth0),{"name":"each","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "					</ul>\n				</div>\n";
},"10":function(depth0,helpers,partials,data) {
    return "							<li><a href=\"#change-language\" class=\"change-locale\" data-locale=\""
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,(helpers.concat || (depth0 && depth0.concat) || helpers.helperMissing).call(depth0,"locales.",depth0,{"name":"concat","hash":{},"data":data}),{"name":"$","hash":{},"data":data}))
    + "</a></li>\n";
},"12":function(depth0,helpers,partials,data) {
    return "				<div class=\"no-manual\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.noManual",{"name":"$","hash":{},"data":data}))
    + "\n				</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<div class=\"instructions-controls\">\n	<div class=\"font-size-adjustment\">\n		<button class=\"btn-no-style make-text-smaller\">A-</button>\n		<button class=\"btn-no-style make-text-larger\">A+</button>\n	</div>\n	<div class=\"expand-collapses-sections\">\n		<button class=\"btn-no-style expand-all-sections\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.manual.expandAllSectionsTooltip",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\">\n			<span class=\"fa fa-angle-double-down\"></span>\n		</button>\n		<button class=\"btn-no-style collapse-all-sections\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.manual.collapseAllSectionsTooltip",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\">\n			<span class=\"fa fa-angle-double-up\"></span>\n		</button>\n	</div>\n	<div id=\"steps_navigation\">\n		<h3 class=\"step-heading\">Page <span class=\"step-number\"></span></h3>\n		<ul>\n"
    + ((stack1 = helpers.each.call(depth0,depth0,{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</ul>\n	</div>\n</div>\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.remoteControlMode : depth0),{"name":"unless","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "<div id=\"steps_wrapper\">\n	<div class=\"steps-holder\">\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.length : depth0),0,{"name":"eq","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		<div id=\"manuals\" class=\"steps\"> </div>\n	</div>\n\n</div>\n\n<div id=\"modal_image_zoom\" class=\"modal fade global fluid hide\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\"><span class=\"fa fa-fw fa-image\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.enlarged",{"name":"$","hash":{},"data":data}))
    + "</h3>\n			</div>\n			<div class=\"modal-body\">\n				<img id=\"zoom_image\" src=\"\" alt=\"\"/>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_table_enlarge\" class=\"modal fade global fluid hide\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\"><span class=\"fa fa-fw fa-table\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.enlargedTable",{"name":"$","hash":{},"data":data}))
    + "</h3>\n			</div>\n			<div class=\"modal-body\">\n				<div id=\"large_table\"></div>\n			</div>\n		</div>\n	</div>\n</div>\n\n\n<div  id=\"modal_media_player\" class=\"modal fade global fluid hide\">\n	<button type=\"button\" class=\"close\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n	<div id=\"media_player_target\"></div>\n</div>";
},"useData":true,"useDepths":true});
Handlebars.registerPartial('console_manual_step_listing', t);
return t;
});

