define('library/vlp/types',['require','underscore','backbone'],function (require) {
	"use strict";

	//library dependencies
	var _         = require("underscore"),
	    Backbone  = require("backbone");


	Backbone.Types = Backbone.Types || {};
	/**
	 * @return {boolean}
	 */
	Backbone.Types.Boolean = function(value){
		if(_.isBoolean(value)){
			return value;
		}
		if(_.isString(value)){
			return !!value.match(/true|1/);
		}
		if(_.isNumber(value)){
			return value !== 0;
		}
		return Boolean(value);
	};
	/**
	 * @return {number}
	 */
	Backbone.Types.Number = function(value){
		if(_.isNumber(value)){
			return value;
		}
		var tValue = 0;
		try{
			tValue = JSON.parse(value);
		} catch(e){}
		if(_.isNumber(tValue)){
			return tValue;
		}
		return 0;
	};
	/**
	 * @return {string}
	 */
	Backbone.Types.String = function(value){
		if(value === null)      { return ""; }
		if(value === undefined) { return ""; }

		return String(value);
	};
	Backbone.Types.Integer = function(value){
		return (!isNaN(value) ? parseInt(value, 10) : null);
	};
	Backbone.Types.Float = function(value){
		return (!isNaN(value) ? parseFloat(value) : null);
	};
	/**
	 * @return {Array}
	 */
	Backbone.Types.Array = function(value){
		if(value === null)      { return null; }
		if(value === undefined) { return null; }

		return (!Array.isArray(value) ? [value] : value);
	};

	return Backbone.Types;

});
