
define('hbs!tpls/console/idle-timed-out.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "				<div class=\"clearfix\">\n					<button class=\"btn btn-default pull-right restart\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labTimedOut.restartLab",{"name":"$","hash":{},"data":data}))
    + "\n					</button>\n				</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"idle-timed-out\">\n	<div class=\"top idle\">\n		<div class=\"interior\">\n\n			<h1><span class=\"fa fa-fw fa-lg fa-clock-o\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labTimedOut.title",{"name":"$","hash":{},"data":data}))
    + "</h1>\n\n			<div id=\"entitlement\">\n				<p class=\"message\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labTimedOut.messageLabEnded",{"name":"$","hash":{},"data":data}))
    + "</p>\n				<h2 class=\"message\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labTimedOut.messageProgressSaved",{"name":"$","hash":{},"data":data}))
    + "</h2>\n			</div>\n			<div class=\"percent-complete-container\">\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labTimedOut.percentComplete",{"name":"$","hash":{},"data":data}))
    + "\n				<div class=\"percent-complete\">"
    + this.escapeExpression((helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.progress : depth0),{"name":"percent","hash":{},"data":data}))
    + "</div>\n			</div>\n			<div class=\"time-remaining-container\">\n				<h3>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.timeRemaining",{"name":"$","hash":{},"data":data}))
    + "</h3>\n				<span class=\"time-remaining\">"
    + this.escapeExpression(((helper = (helper = helpers.labTimeRemainingDisplay || (depth0 != null ? depth0.labTimeRemainingDisplay : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"labTimeRemainingDisplay","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"incomplete",{"name":"eq","hash":{},"data":data}),(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"ready",{"name":"eq","hash":{},"data":data}),(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"gone",{"name":"eq","hash":{},"data":data}),(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"active",{"name":"eq","hash":{},"data":data}),{"name":"or","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('console_idle_timed_out', t);
return t;
});

