
define('hbs!tpls/support/main.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    return "<div id=\"entitlement\">\n	<div class=\"loading building-lab\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"support.entitlement.launching",{"name":"$","hash":{},"data":data}))
    + "\n		<div id=\"deploy_progress\" class=\"progress progress-info progress-striped active\">\n			<div class=\"bar\" style=\"width: 0%;\"></div>\n		</div>\n		<div class=\"time-elapsed\"></div>\n	</div>\n</div>";
},"useData":true});
Handlebars.registerPartial('support_main', t);
return t;
});

