define('models/system-message',['require','exports','module','underscore','backbone','library/vlp/app','library/vlp/model','library/vlp/collection'],function (require, exports) {
	"use strict";

	//library dependencies
	var _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection");


	//Use exports for requirejs circular dependency resolving
	var SystemMessage = exports;

	//Add SystemMessage to the App.Models namespace
	App.Models.SystemMessage = SystemMessage;


	SystemMessage.Model = BaseModel.extend({
		urlRoot   : "/systemMessages",

		/**
		 * Default values for empty Models (new SystemMessage.Model())
		 */
		defaults : {
			title : "",
			content  : "",
			severity : 3,
			isImmediate : false
		},
		types : {
			id       : Backbone.Types.Number,
			severity : Backbone.Types.Number
		},
		getters : {
			severityClass : function(){
				switch(this.get("severity")){
					case 1: return "red";
					case 2: return "yellow";
					case 3: return "green";
					default: return "yellow";
				}
			},
			contentShort : function(){
				return _.prune(_.trim(_.stripTags(this.get("content"))).replace(/(\n\r|\r\n|\n|\r)+/g, "\n"), 50);
			}
		},
		/**
		 * Constructor
		 */
		initialize : function(){

		},
		parse : function(response, options){
			var result;
			if(response.data){
				result = BaseModel.prototype.parse.call(this, response, options);
			} else {
				result = response;
			}

			if(result.id === null || result.id <= 0) {
				result.id = -parseInt(_.uniqueId(), 10);
				result.isImmediate = true;
			}

			return result;
		},
		acknowledge : function(options){
			options = options || {};
			this.action("ack", options);
		}
	});


	SystemMessage.Collection = BaseCollection.extend({
		model : SystemMessage.Model,
		comparator : function(a, b){
			if(a.get("severity") === b.get("severity")) { return 0; }
			if(a.get("severity") < b.get("severity"))   { return 1; }
			return -1;
		},
		parse : function(response, options){
			var results;
			if(response.data){
				results = BaseCollection.prototype.parse.call(this, response, options);
			} else if (!_.isArray(response)){
				results = [response];
			} else {
				results = response;
			}
			_.each(results, function(item){

				if(item.id === null || item.id <= 0) {
					item.id = -parseInt(_.uniqueId(), 10);
					item.isImmediate = true;
				}
			});

			return results;
		}
	});

});

