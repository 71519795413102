
define('hbs!tpls/common/security-prompts.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "		<div id=\"security_question_holder\"></div>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<form id=\"security_questions_get_username_form\" data-error-style=\"tooltip\" "
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + " method=\"post\">\n			<div class=\"modal-dialog\">\n				<div class=\"modal-content\">\n					<div class=\"modal-header\">\n						<h3 class=\"modal-title\">\n							<span class=\"fa fa-fw fa-lock\"></span>\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"securityPrompts.fetchQuestion.title",{"name":"$","hash":{},"data":data}))
    + "\n						</h3>\n					</div>\n					<div class=\"modal-body\">\n						<p class=\"description\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"securityPrompts.fetchQuestion.description",{"name":"$","hash":{},"data":data}))
    + "</p>\n						<fieldset class=\"form-horizontal\">\n							<div class=\"control-group\">\n								<label class=\"control-label\" for=\"security_questions_get_username_username\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"securityPrompts.username",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n								<div class=\"controls\">\n									<input type=\"text\" class=\"input-xlarge\" placeholder=\"user@company.com\" id=\"security_questions_get_username_username\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(8, data, 0),"inverse":this.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "/>\n								</div>\n							</div>\n						</fieldset>\n						<div id=\"security_questions_get_username_invalid\" class=\"message alert alert-error hide\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.errors.error",{"name":"$","hash":{},"data":data}))
    + ": <span class=\"error-message\"></span>\n						</div>\n					</div>\n					<div class=\"modal-footer\">\n						<div class=\"clearfix\">\n							<button id=\"security_questions_get_username_fetch\" class=\"btn btn-large pull-right\" type=\"submit\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","securityPrompts.fetchQuestion.fetching",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"securityPrompts.fetchQuestion.fetch",{"name":"$","hash":{},"data":data}))
    + "</button>\n							<div class=\"spinner save-spinner forgot-password-spinner pull-right hide\"></div>\n						</div>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.cancellable : depth0),{"name":"if","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "					</div>\n				</div>\n			</div>\n		</form>\n";
},"4":function(depth0,helpers,partials,data) {
    return "autocomplete=\"on\"";
},"6":function(depth0,helpers,partials,data) {
    return "autocomplete=\"off\" action=\"https://about:blank\"";
},"8":function(depth0,helpers,partials,data) {
    return "\"email\"";
},"10":function(depth0,helpers,partials,data) {
    return "\"off\"";
},"12":function(depth0,helpers,partials,data) {
    return "							<a class=\"cancel\" type=\"button\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</a>\n";
},"14":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<form class=\"form-horizontal clearfix\" data-error-style=\"tooltip\" id=\"security_questions_form\" method=\"post\">\n			<div class=\"modal-dialog\">\n				<div class=\"modal-content\">\n					<div class=\"modal-header\">\n						<div class=\"modal-title\">\n							<span class=\"fa fa-fw fa-lock\"></span>\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"securityPrompts.answerQuestion",{"name":"$","hash":{},"data":data}))
    + "\n						</div>\n					</div>\n					<div class=\"modal-body\">\n						<fieldset>\n"
    + ((stack1 = (helpers.gt || (depth0 && depth0.gt) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.questionsAnsweredCorrectly : depth0),0,{"name":"gt","hash":{},"fn":this.program(15, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "							<h2 id=\"securityQuestion\">"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.question : depth0)) != null ? stack1.question : stack1), depth0))
    + "</h2>\n\n							<div class=\"alert alert-error hide security-questions-error save-error-general\">\n								<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"error",{"name":"$","hash":{},"data":data}))
    + "</strong>\n							</div>\n\n							<div class=\"control-group\">\n								<label class=\"control-label\" for=\"securityAnswer\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"securityPrompts.answer",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n								<div class=\"controls\">\n									<input type=\"password\" class=\"input-xlarge\" name=\"securityAnswer\" id=\"securityAnswer\">\n									<input type=\"text\" class=\"hide input-xlarge\" id=\"securityAnswer_visible\" autocomplete=\"off\">\n									<div class=\"help-block hide\"></div>\n								</div>\n\n								<label class=\"control-label\" for=\"security_answer_show\">&nbsp;</label>\n								<div class=\"controls\">\n									<label class=\"checkbox\">\n										<input type=\"checkbox\" id=\"security_answer_show\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"securityPrompts.showAnswer",{"name":"$","hash":{},"data":data}))
    + "\n									</label>\n								</div>\n							</div>\n						</fieldset>\n					</div>\n					<div class=\"modal-footer\">\n						<div class=\"clearfix\">\n							<button type=\"submit\" class=\"btn btn-large btn-primary save-changes pull-right\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","loginPrompt.forgotPassword.email.sending",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"securityPrompts.answer",{"name":"$","hash":{},"data":data}))
    + "</button>\n							<button id=\"security_questions_refresh_question\" type=\"button\" class=\"btn btn-default btn-large pull-right\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","securityPrompts.fetchQuestion.fetching",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"securityPrompts.fetchQuestion.fetchAgain",{"name":"$","hash":{},"data":data}))
    + "</button>\n							<div class=\"spinner hide save-spinner pull-right\"></div>\n							<div class=\"alert alert-error hide pull-right save-error\"><strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"error",{"name":"$","hash":{},"data":data}))
    + "</strong></div>\n						</div>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.cancellable : depth0),{"name":"if","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "					</div>\n				</div>\n			</div>\n		</form>\n";
},"15":function(depth0,helpers,partials,data) {
    return "								<div class=\"alert alert-info\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"securityPrompts.nextQuestionMessage",{"name":"$","hash":{},"data":data}))
    + "</div>\n";
},"17":function(depth0,helpers,partials,data) {
    return "		<div id=\"password_holder\"></div>\n";
},"19":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<div id=\"password_saved\">\n			<div class=\"modal-dialog\">\n				<div class=\"modal-content\">\n					<div class=\"modal-header\">\n						<h3 class=\"modal-title\">\n							<span class=\"fa fa-fw fa-lock\"></span>\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"securityPrompts.passwordSaved",{"name":"$","hash":{},"data":data}))
    + "\n						</h3>\n					</div>\n					<div class=\"modal-body\">\n						"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"securityPrompts.passwordSavedMessage",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "\n					</div>\n					<div class=\"modal-footer\">\n						<a class=\"btn btn-large btn-primary\" href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"page":"HOME"},"data":data}))
    + "\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"securityPrompts.continue",{"name":"$","hash":{},"data":data}))
    + "</a>\n					</div>\n				</div>\n			</div>\n		</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"security-prompts\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.setSecurityQuestions : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.answerSecurityQuestionsGetUsername : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.answerSecuirtyQuestions : depth0),{"name":"if","hash":{},"fn":this.program(14, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.setPassword : depth0),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.passwordSaved : depth0),{"name":"if","hash":{},"fn":this.program(19, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});
Handlebars.registerPartial('common_security_prompts', t);
return t;
});

