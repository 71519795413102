define('models/tip',['require','exports','module','library/vlp/app','library/vlp/model','library/vlp/collection'],function (require, exports) {
	"use strict";

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection");


	//Use exports for requirejs circular dependency resolving
	var Tip = exports;

	//Add Tip to the App.Models namespace
	App.Models.Tip = Tip;


	Tip.Model = BaseModel.extend({
		urlRoot  : "/tips",
		//idAttribute : "id",
		/**
		 * Default values for empty Models (new Tip.Model())
		 */
		defaults : {
			title : "",
			tip   : ""
		},

		/**
		 * Constructor
		 */
		initialize : function(){

		}
	});


	Tip.Collection = BaseCollection.extend({
		model : Tip.Model
	});

});

