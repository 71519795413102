
define('hbs!tpls/instructor/console/main.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    return "<div id=\"entitlement\">\n	<div class=\"loading building-lab\">\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.entitlement.launching",{"name":"$","hash":{},"data":data}))
    + "\n	</div>\n</div>";
},"useData":true});
Handlebars.registerPartial('instructor_console_main', t);
return t;
});

