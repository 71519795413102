/**
 * Announcements Backbone View
 */
define('views/common/announcements',['require','underscore','library/vlp/view','models/announcement','hbs!tpls/common/announcements.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var _        = require("underscore");

	//class dependencies
	var BaseView     = require("library/vlp/view"),
	    Announcement = require("models/announcement"),
	    tpl          = require("hbs!tpls/common/announcements.handlebars");

	/**
	 * AnnouncementsView Class
	 *
	 */
	return BaseView.extend({
		template : tpl,

		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		el : "#announcements",

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			//"click a.more-info": "showDetails"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			options = options || {};
			options.fetchDefaults = options.fetchDefaults || {};

			this.fetchDefaults = _.extend({
				limit : 5
			}, options.fetchDefaults);
			this.collection = new Announcement.Collection();
			this.listenTo(this.collection, "request sync error", this.render);

		}
	});


});
