/**
 * Lab Router
 *
 * Code starts running here for the /lab page.
 */
define('routers/console',['require','library/vlp/app','library/vlp/router','views/console/main'],function (require) {
	"use strict";

	//class dependencies
	var App        = require("library/vlp/app"),
	    BaseRouter = require("library/vlp/router"),
	    MainView   = require("views/console/main");


	return BaseRouter.extend({
		routeBase : "console",
		/**
		 * Constructor
		 *
		 * This code is called when new Router([options]) is called in the example.html.
		 *
		 * @param options Map of options (mostly passed on to new MainView([options]);
		 */
		initialize : function(config){
			this.setup(config);

			this.mainView = new MainView();
			//needed for changing browser history when steps change
			this.start();
		},
		/**
		 * List of possible routes
		 */
		routes : {
			"(lab/:labId/):entitlementId/completed"       : "completed",
			"(lab/:labId/):entitlementId/complete"        : "completed",
			"(lab/:labId/)completed"                      : "completed",
			"(lab/:labId/)complete"                       : "completed",
			"(lab/:labId/):entitlementId/expired"         : "completed",
			"(lab/:labId/)expired"                        : "completed",
			"(lab/:labId/)remoteToken/:remoteToken*splat" : "showEntitlementRemote",
			"(lab/:labId/)remoteToken/:remoteToken"       : "showEntitlementRemote",
			"(lab/:labId/):entitlementId(/:consoleId)"    : "showEntitlement",
			"(lab/:labId/):entitlementId/"                : "showEntitlement",
			"(lab/:labId/)*actions"                       : "showEntitlement"
		},
		/**
		 * Show entitlement labId and entitlementId and optional consoleId.
		 * entitlementId can be null and come from the neeToken processing.
		 */
		showEntitlement : function(labId, entitlementId, consoleId){
			var consoleIdAlt = null;
			if(String(entitlementId).indexOf("/") !== -1){
				console.warn("Attempting to fix bad entitlementKey:", entitlementId);
				consoleIdAlt = entitlementId.substring(entitlementId.indexOf("/") + 1);
				entitlementId = entitlementId.substring(0, entitlementId.indexOf("/"));
				console.warn("New entitlementKey:", entitlementId);
			}
			if(consoleId && !consoleId.toString().match(/^(vm|saas)-/)){
				consoleId = parseInt(consoleId, 10);
				if(isNaN(consoleId)){ consoleId = null; }
			}
			if(!consoleId && consoleIdAlt){
				console.warn("Attempting to guess consoleId from bad entitlementKey:", consoleIdAlt);
				if(!consoleIdAlt.toString().match(/^(vm|saas)-/)) {
					consoleId = parseInt(consoleIdAlt, 10);
					if (isNaN(consoleId)) { consoleId = null; }
				} else{
					consoleId = consoleIdAlt;
				}
				console.warn("New consoleId:", consoleId);
			}
			this.mainView.showEntitlement(entitlementId, consoleId);
		},
		/**
		 * Show entitlement from remote token. Token is parsed in vlp-base.js
		 */
		showEntitlementRemote : function(){
			this.mainView.showEntitlement(null, null, null);
		},
		/**
		 * Go home (Catalogs) if page is refreshed on completed
		 */
		completed : function(){
			App.home();
		}

	});
});

