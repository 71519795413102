/* globals ResizeObserver */
define('views/console/manual-step',['require','jquery','underscore','library/vlp/app','library/vlp/view','hbs!tpls/console/manual-step.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App         = require("library/vlp/app"),
	    BaseView    = require("library/vlp/view"),
	    stepInfoTPL = require("hbs!tpls/console/manual-step.handlebars");

	return BaseView.extend({
		template : stepInfoTPL,
		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		className : "step",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events: {
			"click .content img"                            : "onFullImage",
			"click .content .img-enlarge-label"             : "onFullImage",
			"click .content .table-enlarge-label"           : "onTableEnlarged",
			"click .content .mediatag-thumb"                : "onPlayVideo",
			"click .fetch-error .try-again"                 : "onRefetch",
			"click .content a[href^=#]"                     : "onJumpToAnchor",
			"change .checklist-checkbox"                    : "onToggleCheckListItem",
			"click .collapsable-section .collapsable-title" : "onToggleCollapsableSection"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);

			this.stepNo = this.model.get("stepNo");
			this.entitlement = options.entitlement;
			this.model.set("firstStep", options.firstStep);
			this.model.set("lastStep",  options.lastStep);
			this.listenTo(this.model, "change:content change:title change:id change:fetchError", this.render);
			this.listenTo(this.model, "push:timeout error", function(){
				this.model.set("fetchError", true);
			}, this);

			if(!App.remoteControl){
				this.$el
					.on("mousedown", this.onMouseDown)
					.on("mouseup",   this.onMouseUp)
					.on("dragstart", this.onDragStart)
					.on("dragend",   this.onDragEnd);
			}

		},
		remove : function(){
			this.$el.removeClass("transitioning");
			this.$el
				.off("mousedown", this.onMouseDown)
				.off("mouseup",   this.onMouseUp)
				.off("dragstart", this.onDragStart)
				.off("dragend",   this.onDragEnd);

			BaseView.prototype.remove.call(this);
		},
		serialize : function(){
			var data = this.model.toHandlebars();
			data.content = this.replaceContentVariables(data.content);
			data.content = App.VlpGlobal.replaceCommands(data.content);
			data.title   = this.replaceContentVariables(data.title);
			return data;
		},
		afterRender : function(){

			if(!App.config.allowRawManualStyles) {
				this.$(".content *:not(.mediatag-thumb)").attr("style", "");
			}
			var $tooltip = this.$(".image-enlarge-tooltip");
			this.$(".content img").tooltip($tooltip.data());
			this.$(".content > img, .content > p > img").wrap("<div class=\"img-thumbnail\" />");
			var tipTitle = $tooltip.data("title");
			this.$(".content .img-thumbnail").append("<div class=\"img-enlarge-label\">" + tipTitle + "</div>");
			this.$(".content > table").wrap("<div class=\"table-wrapper\"></div>");
			this.$(".content .table-wrapper").wrap("<div class=\"outer-table-wrapper\"></div>");
			this.$(".content .outer-table-wrapper").append("<div class=\"table-enlarge-label\">" + tipTitle + "</div>");
			this.$(".check-list-item, .check-list-item-section > .collapsable-title")
				.wrapInner("<span class=\"check-list-item-label-title\"></span>")
				.prepend("<input type=\"checkbox\" class=\"checklist-checkbox\" />")
				.wrapInner("<div class=\"check-list-item-label\"></div>");
			$tooltip.remove();

			var self = this;
			this.$(".content img").load(function(){
				new ResizeObserver(self.onImageResized).observe(this);
			});
			this.$(".content .table-wrapper").each(function(){
				new ResizeObserver(self.onTableResized).observe(this);
			});

			var $onPlayVideoTooltip = this.$(".play-video-tooltip");
			this.$(".content .mediatag-thumb").tooltip($onPlayVideoTooltip.data());
			$onPlayVideoTooltip.remove();

			//Update youtube thumbnails to better quality and consistent size
			this.$(".content .mediatag-thumb").css({
				width : "",
				height: "",
				"background-size": "",
				"background-repeat": ""
			});
			this.$(".content .mediatag-thumb[data-media-type=\"youtube\"]").each(function(){
				var $this = $(this);
				var thumbUrl = $this.data("thumbUrl") || $this.css("background-image");
				if(thumbUrl && thumbUrl.match(/(0|2)\.jpg/)){
					thumbUrl = thumbUrl.replace(/url\("?([^"]*)"?\)/, "$1");
					thumbUrl = thumbUrl.replace(/(0|2).jpg/, "hqdefault.jpg");
					$this.css("background-image", "url(" + thumbUrl + ")");
				}
			});

			// open links in a new tab
			this.$(".content a[href]").each(function(){
				var $this = $(this);
				var href = String($this.attr("href"));
				var originalHref = href;

				href = _.trim(href);
				if(href.match(/^['"\[\(].*["'\]\)]?$/)){
					href = _.trim(href, "[]()\"'");
				}
				href = href.replace(/^(%[0-9A-F]{2})+/, "");

				if(!href.match(/^[a-zA-Z0-9\-]*[:#]/)){
					href = href.replace(/^[^a-zA-Z0-9\-]+/, "");
				}
				if(href != originalHref){
					console.warn("Fixing bad url:", originalHref, "to:", href);
					$this.attr("href", _.sanitizeURL(href));
				}

				if(href.indexOf("#") != 0){
					$this.attr("target","_blank");
				}
			});

			this.$(".send-text,[data-send-text]").attr("data-title", App.i18n("console.vm.sendText.toolTip")).tooltip();

			App.VlpGlobal.disableInactiveCommands(this.el);

			var sectionsOpen = App.session.get("sectionsOpen") || {};
			var stepId = this.model.get("id");
			if(sectionsOpen[stepId]){
				for(var index in sectionsOpen[stepId]){
					if(sectionsOpen[stepId].hasOwnProperty(index) && sectionsOpen[stepId][index]){
						var $section = $(this.$(".collapsable-section").get(index));

						$section.addClass("open");
						var $content = $section.find("> .collapsable-content");
						$content.each(function(){
							this.style.maxHeight = "fit-content";
							this.style.padding = "inherit";
						});
					}
				}
			}
			this.displayExpandAllCollapseAllButtons();
		},
		replaceContentVariables : function(text){
			var extraVariables = {
				stepNumber : this.model.get("stepNo")
			};
			return App.VlpGlobal.replaceVariables(text, extraVariables);
		},
		activate : function(options){
			var self = this;

			if(options && options.bookmark){
				if(this.entitlement){
					this.entitlement.set("activeStepBookmarkComplete", false);
				}
				this.model.set("fetchError", false);
				this.model.fetch({
					data : {
						entitlementId : this.entitlement ? this.entitlement.get("id") : null,
						bookmark      : App.config.saveManualBookmark,
						stepNo        : this.model.get("stepNo"),
						languagecode  : App.locale.get().toLowerCase()
					},
					returnPath : (this.model.get("partial") ? "push" : "none"),
					success : function(){
						if(App.config.consoleRemoteManualSyncingEnabled){
							self.model.switchPage({params : {
								entitlementId : self.entitlement ? self.entitlement.get("id") : null
							}});
						}

						if(self.entitlement){
							self.entitlement.set("activeStepBookmarkComplete", true);
						}
					}
				});

			} else if(this.model.get("partial") || !this.model.fetched){
				this.onRefetch();
			}
		},
		onRefetch : function(){
			this.model.set("fetchError", false);
			this.model.fetch({
				pushReturn : true,
				data : {
					entitlementId : this.entitlement ? this.entitlement.get("id") : null,
					languagecode : App.locale.get().toLowerCase(),
					stepNo       : this.model.get("stepNo")
				}
			});
		},
		onFullImage : function(event){
			var $target = $(event.currentTarget);
			if($target.hasClass("img-enlarge-label")){
				$target = $target.parent().find("img");
			}
			var $modal = $("#modal_image_zoom");
			var title = $target.data("originalTitle");
			if(!title){
				title = $target.attr("title");
			}
			var src = $target.data("fullSrc");
			if(!src){
				src = $target.attr("src");
			}

			var $zoomImage = $modal.find("#zoom_image");
			$zoomImage.ready(function(){
				$modal.modal("reflow");
			});
			$zoomImage.load(function(){
				$modal.modal("reflow");
			});

			$zoomImage
				.attr("src", src)
				.attr("alt", $target.attr("alt"))
				.attr("title", title)
				.css("height", "inherit")
				.tooltip("destroy");

			$modal.find(".title").text(title);

			if(title === "" || title === null){
				$modal.find(".title").hide();
			}

			//SVG image type is determined by filename searching for .svg or .svgz. This will not correctly determine if
			//an image is an SVG for files that do not have a matching extension, but there is not really a good way to 
			//determine the content type of a <img> that doesn't run into CORS issues. If it does not determine an actual
			//SVG image is a SVG, it will still use the naturalWidth and naturalHeight of the SVG, which if set correctly,
			//will render correctly.
			var isSvg = !!src.match(/\.svgz?([^a-z]|$)/i);

			$modal
				.toggleClass("svg", isSvg)
				.removeClass("tall")
				.modal("show");

			if(isSvg){
				var checkSvgImageSizeInterval;
				var clearCheckSvgImageSizeInteval = function(){
					clearInterval(checkSvgImageSizeInterval);
				};
				var checkSvgImageSize = function(){
					if($modal.prop("scrollHeight") > 0){
						clearCheckSvgImageSizeInteval();
					}
					if(isSvg && ($modal.prop("scrollHeight") > $modal.prop("offsetHeight"))){
						$zoomImage.css("height", ($zoomImage.height() - ($modal.prop("scrollHeight") - $modal.prop("offsetHeight")))  + "px");
						$modal.addClass("tall");
						$modal.modal("reflow");
					}
				};
				checkSvgImageSizeInterval = setInterval(checkSvgImageSize, 33);
				$modal.one("shown", clearCheckSvgImageSizeInteval);
			}
		},

		onTableEnlarged : function(event){
			var $target = $(".table-wrapper", event.currentTarget.parentElement);
			var $modal = $("#modal_table_enlarge");

			var $largeTable = $modal.find("#large_table").empty();
			$largeTable.append($target[0].cloneNode(true));

			$modal.modal("show");
		},

		onPlayVideo : function(event){
			var $target = $(event.currentTarget);
			var $modal = $("#modal_media_player");
			var $mediaPlayerTarget = $("#media_player_target");
			var mediaId   = String($target.data("mediaId")).replace(/[?&]+/, "");
			var mediaType = $target.data("mediaType");
			var mediaURL;

			$mediaPlayerTarget.empty();

			var playerDimensions = this._getMaxDimensionsWithLimit(mediaType);

			var embed = "";
			switch(mediaType){
				case "vimeo":

					mediaURL = "https://player.vimeo.com/video/" + mediaId + "?autoplay=1&title=0&byline=0&badge=0&portrait=0&dnt=1";
					embed = "<iframe width=\""+playerDimensions.width+"\" height=\""+playerDimensions.height+"\" src=\""+mediaURL+"\" frameborder=\"0\" allowfullscreen></iframe>";

					break;
				case "youtube":
					var origin = window.location.protocol + "//" + window.location.host;
					mediaURL = "https://www.youtube-nocookie.com/embed/" + mediaId + "?origin="+origin+"&rel=0&showinfo=0&controls=1&modestbranding=1&autoplay=1";
					embed = "<iframe width=\""+playerDimensions.width+"\" height=\""+playerDimensions.height+"\" src=\""+mediaURL+"\" frameborder=\"0\" allowfullscreen></iframe>";

					break;
				default:
					console.warn("Unsupported embedded media type " + mediaType);
					embed = "Unsupported embedded media type " + mediaType;
					break;
			}

			$mediaPlayerTarget.width(playerDimensions.width);
			$mediaPlayerTarget.height(playerDimensions.height);
			$mediaPlayerTarget.html(embed);

			$modal.modal("show");

			//cleanup on close
			$modal.off("hidden.step").on("hidden.step", function() {
				$mediaPlayerTarget.empty();
			});
		},
		onMouseDown : function(event){
			if($(event.target).closest(".send-text-draggable").length){
				$(event.target).closest(".send-text-draggable").get(0).draggable = true;
				return;
			}
			this.removeSendTextMarks();
		},
		onMouseUp : function(event){
			var self = this;

			clearTimeout(this.mouseUpTimeout);

			this.mouseUpTimeout = setTimeout(function(){
				if($(event.target).closest(".send-text-draggable").length === 0 && window.getSelection){

					var selection = window.getSelection();

					if(selection.rangeCount > 0){
						var range = selection.getRangeAt(0);
						var windowSelectedString = selection.toString().trim().replace(/\u00a0/g, " ");
						var rangeSelectedString  = range.toString().trim().replace(/\u00a0/g, " ");


						//Find matching text in .content's children for if the selection spans multiple nodes.
						var matchingTextNodes = self.$el.find("*")
							.filter(function() {
								var jqContains = $.contains(this, range.startContainer) || $.contains(this, range.endContainer);
								var contained = (selection.containsNode ? selection.containsNode(this, true) : jqContains);

								if(this.hasOwnProperty("innerText") && this.innerText){
									var innerText = String(this.innerText).replace(/\u00a0/g, " ");
									if(innerText === windowSelectedString && contained){
										return true;
									} else if(innerText === rangeSelectedString && contained){
										return true;
									}
								}
								if(this.textContent){
									var textContent = String(this.textContent).replace(/\u00a0/g, " ");
									if(textContent === windowSelectedString && contained){
										return true;
									} else if(textContent === rangeSelectedString && contained){
										return true;
									}
								}
								return false;
							});


						var markNode;
						if(range.startOffset != range.endOffset && range.startContainer === range.endContainer){
							markNode = document.createElement("mark");
							markNode.className = "send-text-draggable";
							markNode.title = App.i18n("console.manual.dragIntoConsole");
							try{
								range.surroundContents(markNode);
							} catch (e){ /*ignored*/ }

						} else if(matchingTextNodes.length){
							$(matchingTextNodes).each(function(){this.normalize();});

							markNode = document.createElement("mark");
							markNode.className = "send-text-draggable";
							markNode.title = App.i18n("console.manual.dragIntoConsole");
							$(matchingTextNodes[0].firstChild).wrap(markNode);

							selection.removeAllRanges();

							var newRange = document.createRange();
							newRange.selectNodeContents(matchingTextNodes[0].firstChild);
							selection.addRange(newRange);
						} else {
							self.removeSendTextMarks();
						}
					} else {
						self.removeSendTextMarks();
					}
				}
			}, 501);
		},
		onDragStart : function(event){
			if(event && event.originalEvent.dataTransfer){
				try{
					event.originalEvent.dataTransfer.setData("text/plain", $(event.target).text());
				} catch (e) {
					event.originalEvent.dataTransfer.setData("text", $(event.target).text());
				}
			}
			$(event.target).addClass("dragging");
		},
		onDragEnd : function(event){
			this.$(".send-text-draggable").each(function(){
				this.draggable = false;
			});
			$(event.target).removeClass("dragging");
		},

		removeSendTextMarks : function(){
			this.$(".send-text-draggable").each(function(){
				$(this).contents().unwrap();
			});
			this.$el.get(0).normalize();
		},
		_getMaxDimensionsWithLimit : function(type){

			// get current viewport height/width of viewport.
			var viewportWidth  = document.documentElement.clientWidth;
			var viewportHeight = document.documentElement.clientHeight;
			var maxWidth  = viewportWidth * 0.94;
			var maxHeight = viewportHeight * 0.94;

			var youtubeDimensions = [
				{ width: 560, height: 340 },
				{ width: 640, height: 385 },
				{ width: 854, height: 505 }
			];

			var vimeoDimensions = [
				{ width: 560, height: 315 },
				{ width: 640, height: 360 },
				{ width: 854, height: 479 }
			];

			var dimensionsCollection = (type === "vimeo" ? vimeoDimensions : youtubeDimensions);

			var maxFound = 0;
			var i = dimensionsCollection.length - 1;
			while(!maxFound && i > -1){
				maxFound = (dimensionsCollection[i].width <= maxWidth && dimensionsCollection[i].height <= maxHeight)? true : false;
				i--;
			}

			return dimensionsCollection[i+1];
		},
		onImageResized : function(images){
			images.forEach(function(image){
				if(image.target.naturalWidth > image.target.offsetWidth || image.target.naturalHeight > image.target.offsetHeight || !!image.target.src.match(/\.svgz?([^a-z]|$)/i)){
					$(image.target).tooltip("enable");
					$(".img-enlarge-label", image.target.parentElement).show();
				} else {
					$(image.target).tooltip("disable");
					$(".img-enlarge-label", image.target.parentElement).hide();
				}
			});

		},
		onTableResized : function(tables){
			tables.forEach(function(table){
				if(table.target.scrollWidth > table.target.offsetWidth){
					$(".table-enlarge-label", table.target.parentElement).show();
				} else {
					$(".table-enlarge-label", table.target.parentElement).hide();
				}
			});
		},

		onJumpToAnchor : function(event){
			var target = String($(event.currentTarget).attr("href")).replace(/^#/, "");
			var $anchor = this.$(".content [name=\"" + target + "\"]");

			if($anchor.length === 0){ return; }

			if(event && event.stopImmediatePropagation){
				event.stopImmediatePropagation();
			}

			this.toggleCollapsableSection($anchor.closest(".collapsable-section"), true, false);

			var scrollY = $anchor.position().top + this.$el.scrollTop();
			this.$el.get(0).scrollTo({
				top: scrollY,
				behavior: "smooth"
			});
		},
		onToggleCollapsableSection : function(event){
			if($(event.target).hasClass("checklist-checkbox")){ return; }

			var $parent = $(event.currentTarget).closest(".collapsable-section");
			this.toggleCollapsableSection($parent);
		},
		toggleCollapsableSection : function($section, open, animate){
			if($section.length === 0) {return; }

			if(open === undefined){
				open = !$section.hasClass("open");
			}

			var stepId = this.model.get("id");
			var sectionIndex = this.$(".collapsable-section").index($section);
			var sectionsOpen = App.session.get("sectionsOpen") || {};
			sectionsOpen[stepId] = sectionsOpen[stepId] || {};
			if(open){
				sectionsOpen[stepId][sectionIndex] = true;
			} else {
				delete sectionsOpen[stepId][sectionIndex];
			}
			App.session.set("sectionsOpen", sectionsOpen);

			if(animate === undefined){
				animate = true;
			}

			var $content = $section.find("> .collapsable-content");

			if(open && $section.hasClass("open")){
				$content.each(function(){
					this.style.maxHeight = "fit-content";
					this.style.padding = null;
				});
				return;
			} else if (!open && !$section.hasClass("open")){
				$content.each(function(){
					this.style.maxHeight = null;
					this.style.padding = null;
				});
				return;
			}
			
			$content.each(function(){
				this.style.maxHeight = (!open ? this.scrollHeight + "px" : null);
				this.style.padding = null;
			});
			$section.one($.support.transition.end, function(){
				$content.each(function(){
					this.style.maxHeight = (open ? "fit-content" : null);
					this.style.padding = null;
				});
			});
			_.defer(function(){
				$section.toggleClass("animate", animate);
				$section.toggleClass("open", open);
				$content.each(function(){
					this.style.maxHeight = (open ? this.scrollHeight + "px" : null);
					this.style.padding = null;
				});
			});

			if(open){
				$section.parents(".collapsable-section").each(function(){
					$(this).addClass("open");
					$(this).find("> .collapsable-content").each(function() {
						this.style.maxHeight = "fit-content";
						this.style.padding = null;
					});
				});
			}
		},
		onToggleCheckListItem : function(event){
			var $target = $(event.currentTarget);
			$target.closest(".collapsable-title, .check-list-item").toggleClass("checked", $target.prop("checked"));
		},
		displayExpandAllCollapseAllButtons : function (){
			var collapsableSections = this.$(".collapsable-section").length;
			$("#step_listing .expand-collapses-sections").toggle(collapsableSections > 0);
		}
	});
});

