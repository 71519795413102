/*globals App */
define('utilities/one-trust',['require','jquery','underscore','library/vlp/base-class'],function (require) {
	"use strict";
	var $ = require("jquery"),
	    _ = require("underscore");

	var BaseClass = require("library/vlp/base-class");

	var fallbackDomain = "vmware.com";

	return BaseClass.extend({
		domainScript : null,
		domain : null,
		ids : {},

		performanceAllowed: false,
		functionalAllowed: false,
		advertisingAllowed: false,
		socialMediaAllowed:  false,

		performanceAllowedCallbacks : [],
		functionalAllowedCallbacks  : [],
		advertisingAllowedCallbacks : [],
		socialMediaAllowedCallbacks : [],

		consentCookie : null,

		_checkConsentInterval : null,
		initialize : function(){
			_.bindAll(this);
		},
		start : function(ids){
			this.loadScript();
			this.addCookieSettingsButton();
		},
		checkConsent : function(){
			if(!window.OptanonWrapper || !window.OptanonWrapper.vlp){
				this.addCallbackScript();
			}
			if (document.cookie.indexOf("OptanonConsent") > -1 && document.cookie.indexOf("groups=") > -1) {
				clearInterval(this._checkConsentInterval);
				this.consentCallBacks();
			}
		},

		consentCallBacks : function (){
			var cookie = decodeURIComponent(document.cookie);

			var oldPerformanceAllowed = this.performanceAllowed;
			var oldFunctionalAllowed  = this.functionalAllowed;
			var oldAdvertisingAllowed = this.advertisingAllowed;
			var oldSocialMediaAllowed = this.socialMediaAllowed;

			this.performanceAllowed = (cookie.indexOf("2:1") > -1);
			this.functionalAllowed  = (cookie.indexOf("3:1") > -1);
			this.advertisingAllowed = (cookie.indexOf("4:1") > -1);
			this.socialMediaAllowed = (cookie.indexOf("5:1") > -1);

			if(
				(oldPerformanceAllowed && !this.performanceAllowed) ||
				(oldFunctionalAllowed  && !this.functionalAllowed)  ||
				(oldAdvertisingAllowed && !this.advertisingAllowed) ||
				(oldSocialMediaAllowed && !this.socialMediaAllowed)
			){
				console.warn("Consent revoked. Reloading page to remove unwanted scripts.");
				window.location.reload();
			} else {
				this.doPerformanceCallbacks();
				this.doFunctionalCallbacks();
				this.doAdvertisingCallbacks();
				this.doSocialMediaCallbacks();
			}
		},
		setIds : function(ids){
			this.ids = ids;
			this.domainScript = null;

			if(_.isEmpty(this.ids)) {
				console.warn("No ids provided for OneTrust");
				return;
			}

			var hostname = location.hostname.toLowerCase();

			for(var oneTrustDomain in this.ids){
				if(this.ids.hasOwnProperty(oneTrustDomain)){
					var id = this.ids[oneTrustDomain];
					var domain = oneTrustDomain.toLowerCase();
					if(domain.indexOf(fallbackDomain) !== -1) {
						//Use .vmware.com oneTrustId as a fallback if found.
						this.domain = domain;
						this.domainScript = id;
					}
					if(hostname.indexOf(domain) !== -1){
						this.domain = hostname;
						this.domainScript = id;
						break;
					}
				}
			}

			if(!this.domainScript) {
				console.warn("No oneTrustId found for this domain.");
			} else {
				var isTestHostname = (hostname === "localhost" || hostname.indexOf(".dev") !== -1 || hostname.indexOf(".stage") !== -1 || hostname.indexOf(".test") !== -1);
				if(isTestHostname && !_.endsWith(this.domainScript, "-test")){
					this.domainScript = this.domainScript + "-test";
				}
			}

			$("meta[name=onetrust-data-domain]").remove();
			$("head").append("<meta name=\"onetrust-data-domain\" content=\"" + this.domainScript + "\" >");
		},

		loadScript : function(){
			if (!this.domainScript) {
				console.warn("No oneTrustId set, not loading scripts.");
				return;
			}

			$("#onetrust_script").remove();

			if(!App.analytics.hasTealium){
				$("head").append("<script src=\"https://cdn.cookielaw.org/scripttemplates/otSDKStub.js\" type=\"text/javascript\" charset=\"UTF-8\" data-domain-script=\""+this.domainScript+"\" id=\"#onetrust_script\"></script>");
			}
			this.addCallbackScript();

			clearInterval(this._checkConsentInterval);
			this._checkConsentInterval = setInterval(this.checkConsent, 250);
		},
		addCookieSettingsButton : function(){
			$("#main_content").remove("#ot-sdk-btn");
			if(App.currentPage() === App.config.pages.CATALOGS){
				$("body").addClass("has-one-trust-button");
				$("#main_content").append("<button id=\"ot-sdk-btn\" class=\"ot-sdk-show-settings\">Cookie Settings</button>");
			}
		},

		addCallbackScript : function(){
			window.OptanonWrapper = function() { 
				window.dataLayer.push({event: "OneTrustGroupsUpdated"}); 
				App.oneTrust.checkConsent();
			};
			window.OptanonWrapper.vlp = true;
		},
		addPerformanceAllowedCallback : function(callback){
			this.performanceAllowedCallbacks.push(callback);
			this.doPerformanceCallbacks();
		},
		addFunctionalAllowedCallbacks : function(callback){
			this.functionalAllowedCallbacks.push(callback);
			this.doFunctionalCallbacks();
		},
		addAdvertisingAllowedCallbacks : function(callback){
			this.advertisingAllowedCallbacks.push(callback);
			this.doAdvertisingCallbacks();
		},
		addSocialMediaAllowedCallbacks : function(callback){
			this.socialMediaAllowedCallbacks.push(callback);
			this.doSocialMediaCallbacks();
		},
		doPerformanceCallbacks : function(){
			if(this.performanceAllowed){
				if(App.analytics){
					App.analytics.performanceCookiesAllowedCallback();
				}

				this.doCallbacks(this.performanceAllowedCallbacks);
				this.performanceAllowedCallbacks = [];
			}
		},
		doFunctionalCallbacks : function(){
			if(this.functionalAllowed){
				if(App.analytics) {
					App.analytics.functionalCookiesAllowedCallback();
				}

				this.doCallbacks(this.functionalAllowedCallbacks);
				this.functionalAllowedCallbacks = [];
			}
		},
		doAdvertisingCallbacks : function(){
			if(this.advertisingAllowed){
				if(App.analytics) {
					App.analytics.advertisingCookiesAllowedCallback();
				}

				this.doCallbacks(this.advertisingAllowedCallbacks);
				this.advertisingAllowedCallbacks = [];
			}
		},
		doSocialMediaCallbacks : function(){
			if(this.socialMediaAllowed){
				if(App.analytics) {
					App.analytics.socialMediaCookiesAllowedCallback();
				}

				this.doCallbacks(this.socialMediaAllowedCallbacks);
				this.socialMediaAllowedCallbacks = [];
			}
		},
		doCallbacks : function(callbacks){
			for(var i = 0; i < callbacks.length; i++){
				callbacks[i]();
			}
		}
	});

});

