/**
 * Base Backbone View
 *
 * This is a Base Class for setting up the layout and header/footer for all pages.
 */
define('library/vlp/view',['require','jquery','underscore','backbone','handlebars'],function(require){
	"use strict";

	//library dependencies
	var $          = require("jquery"),
	    _          = require("underscore"),
	    Backbone   = require("backbone"),
	    Handlebars = require("handlebars");

	/**
	 * BaseView Class
	 *
	 */
	return Backbone.View.extend({

		autoValidate    : true,
		unwrap          : false,
		rendered        : false,
		rendering       : false,
		throttleRending : null,
		template        : null,
		removeKeepEl    : false,
		fetchDefaults   : {},
		validation      : {},

		constructor : function(options){
			options = options || {};
			if(options.hasOwnProperty("template")){
				this.template = options.template;
			}
			if(options.hasOwnProperty("fetchDefaults")){
				this.fetchDefaults = options.fetchDefaults;
			}
			if(options.hasOwnProperty("throttleRending")){
				this.throttleRending = options.throttleRending;
			}


			this._originalRender = this.render;

			if(this.throttleRending && this.throttleRending > 0){
				this.render = _.throttle(_.bind(this._render, this), this.throttleRending);
			} else{
				this.render = this._render;
			}

			Backbone.View.prototype.constructor.apply(this, arguments);

			this.updateBindings();
		},
		setup : function(){

			this.partialTemplates = this.partialTemplates || {};
			//Compile and register partials
			_.each(this.partialTemplates, function(template, name){
				var partial = this.partialTemplates[name];
				if(!partial) {partial = "";}

				Handlebars.registerPartial(name, partial);
			}, this);

		},
		modelBind : function(model, selector, property){
			var hasModelValidations = Object.getPrototypeOf(model) && Object.getPrototypeOf(model).validation && !_.isEmpty(Object.getPrototypeOf(model).validation);
			var viewValidations     = this.validation[property];

			var event = "change.baseModel";

			var self = this;
			if(!this.hasOwnProperty("_bindCallbacks")){
				this._bindCallbacks = {};
			}
			this._bindCallbacks[selector] = function(){
				var $items = self.$(selector);

				var value = model.get(property);
				$items.filter("img").attr("src", value);
				$items.filter(":input").val(value);
				$items.filter(":input[type=checkbox]").prop("checked", value);
				$items.not(":input").text(value);
			};

			this.listenTo(model, "change:" + property, this._bindCallbacks[selector]);

			this.$el.on(event, selector, function(){
				var $this = $(this);
				if($this.is("input[type=checkbox]") && _.isBoolean(model.get(property))){
					model.set(property, $this.prop("checked"));
				} else{
					model.set(property, $this.val());
				}
			});

			if((hasModelValidations || viewValidations)){
				this.$el.off("keyup.baseModelValidate change.baseModelValidate blur.baseModelValidate", selector);
				this.$el.on("keyup.baseModelValidate change.baseModelValidate blur.baseModelValidate", selector, function(event){
					//Ignore tabbing into field
					if(event.type === "keyup" && event.which === 9){ return; }

					var isInvalid = self.$(selector).hasClass("invalid");
					if(!self.autoValidate && !isInvalid) { return; }

					var $this = $(this);
					var value = $this.val();
					var errors = {};
					if(hasModelValidations){
						var modelError = model.preValidate(property, value);
						if(modelError){
							errors[property] = modelError;
						}
					}
					if(viewValidations && (isInvalid  || !(viewValidations.hasOwnProperty("autoValidate") && viewValidations.autoValidate === false))){
						_.defaults(errors, self._preValidate(property, value));
					}
					var props = {};
					props[property] = true;
					self.validated(_.isEmpty(errors), model, errors, props);
				});
			}

			this._updateBindValue(selector, model.get(property));
		},
		_updateBindValue : function(selector, value){
			var $items = this.$(selector);

			$items.filter("img").attr("src", value);
			$items.filter(":input").val(value);
			$items.filter(":input[type=checkbox]").prop("checked", value);
			$items.not(":input").text(value);
		},
		modelUnbind : function(model, selector, property){
			var event = "change.baseModel";

			if(this.hasOwnProperty("_bindCallbacks") && this._bindCallbacks[selector]){
				this.stopListening(model, "change:" + property, this._bindCallbacks[selector]);
			}

			this.$el.off(event, selector);
			this.$el.off("keyup.baseModelValidate change.baseModelValidate blur.baseModelValidate", selector);
		},
		modelUnbindAll : function(){

			if(this.modelBindings){

				if(this.model){
					for(var selector in this.modelBindings){
						if(this.modelBindings.hasOwnProperty(selector)){
							this.modelUnbind(this.model, selector, this.modelBindings[selector]);
						}
					}
				} else if(this.collection){

					this.collection.each(function(model){
						for(var selector in this.modelBindings){
							if(this.modelBindings.hasOwnProperty(selector)) {
								this.modelUnbind(model, selector, this.modelBindings[selector]);
							}
						}
					}, this);

				}
			}
			this.stopListening(this.model, "validated", this.validated);
		},
		updateBindings : function(){

			if(this.modelBindings){

				if(this.model){
					for(var selector in this.modelBindings){
						if(this.modelBindings.hasOwnProperty(selector)){
							this.modelUnbind(this.model, selector, this.modelBindings[selector]);
							this.modelBind(this.model, selector, this.modelBindings[selector]);
						}
					}
				} else if(this.collection){

					this.collection.each(function(model){
						for(var selector in this.modelBindings){
							if(this.modelBindings.hasOwnProperty(selector)) {
								this.modelUnbind(model, selector, this.modelBindings[selector]);
								this.modelBind(model, selector, this.modelBindings[selector]);
							}
						}
					}, this);

				}
			}

			if(Backbone.Validation){
				if(this.model && Object.getPrototypeOf(this.model) && Object.getPrototypeOf(this.model).validation){
					this.stopListening(this.model, "validated", this.validated);
					this.listenTo(this.model, "validated", this.validated);
				}
			}
			//Rebind events to be fired after bindings.
			this.delegateEvents();
		},
		remove : function(){
			this.modelUnbindAll();
			if(this.removeKeepEl){
				this.$el.off();
				this.$el.empty();
			} else{
				this.$el.remove();
			}
			this.stopListening();
			return this;
		},
		serialize: function(){
			if(this.model && this.model.toHandlebars){
				return this.model.toHandlebars();
			} else if(this.collection && this.collection.toHandlebars){
				return this.collection.toHandlebars();
			} else if(this.model && this.model.toJSON){
				return this.model.toJSON();
			} else if(this.collection && this.collection.toJSON){
				return this.collection.toJSON();
			} else if (this.model){
				return this.model;
			} else if (this.collection){
				return this.collection;
			}
			return null;
		},
		render : function(options){
			if(this.template){
				var data = this._serializedData || this.serialize(options);
				this.$el.html(this.template(data));
			}
			return this;
		},
		_render : function(options){
			var self = this;

			options = options || {};

			this.rendering = true;
			this._serializedData = this.serialize(options);
			this.trigger("beforeRender", this, options, this._serializedData);
			if(this.beforeRender && _.isFunction(this.beforeRender)){
				this.beforeRender(options, this._serializedData);
			}
			var result = this;
			if(this._originalRender !== this.render){
				result = this._originalRender.apply(this, arguments);
			}
			if(this.unwrap && this.el && this.el.firstChild){
				//Remove the default top level "<div>" and copy attributes from top child

				var firstChild;

				for (firstChild = this.el.firstChild ; firstChild; firstChild = firstChild.nextSibling) {
					if (firstChild.nodeType === 1) { break; }
				}
				if(!firstChild){
					firstChild = this.el.firstChild;
				}

				if(firstChild && firstChild.childNodes) {
					var attributes = $(firstChild).prop("attributes");

					this.el.removeChild(firstChild);
					while (firstChild.childNodes.length > 0) {
						this.el.appendChild(firstChild.childNodes[0]);
					}

					$.each(attributes, function() {
						self.$el.attr(this.name, this.value);
					});
				}
			}
			this._rebind();

			if(this.$el && navigator.userAgent.match(/(webkit)[ \/]([\w.]+)/i)){
				var $el = this.$el;
				_.defer(function(){
					$el.redraw();
				});
				this.$("img[src!=''][src]").each(function(){
					if(this.width === 0 || this.height === 0){
						$(this).addClass("load-redraw");
					}
				});
				this.$("img.load-redraw").one("load", function(){
					$(this).removeClass("load-redraw");
					_.defer(function(){
						$el.redraw();
					});
				});
			}

			if(this.afterRender && _.isFunction(this.afterRender)){
				this.afterRender(options, this._serializedData);
			}
			this.rendered = true;
			this.rendering = false;
			this.trigger("afterRender", this, options, this._serializedData);
			delete this._serializedData;
			return result;
		},
		_rebind : function(){

			if(this.modelBindings){
				for(var selector in this.modelBindings){
					if(this.modelBindings.hasOwnProperty(selector)){
						this._updateBindValue(selector, this.model.get(this.modelBindings[selector]));
					}
				}
			}
		},
		validate : function(validations, options){
			options = options || {};
			var errors = {};
			var self = this;
			if(_.isArray(validations)){
				validations = _.pick(this.validation, validations);
			}

			if(this.model){
				errors = _.defaults(errors, this.validateModel(this.model, validations, options));
			}
			if(this.collection){
				this.collection.each(function(model){
					var modelErrors = self.validateModel(model, validations);
					errors[model.id] = modelErrors;
				});
			}
			return (_.isEmpty(errors) ? null: errors);
		},
		validateModel : function(model, validations, options){
			var self = this;

			options    = options || {};
			validations = validations || {};

			if(_.isArray(validations)){
				validations = _.pick(this.validation, validations);
			}


			var errors = {};
			_.each(validations, function(propertyValidations, property){
				var value = model.get(property);
				if(_.isFunction(propertyValidations)){
					var result = propertyValidations.call(this, value, property);
					if(result){
						errors[property] = result;
					}
				}
				_.each(propertyValidations, function(validationOptions, validation){
					if(_.isString(validation)){
						validation = Backbone.Validation.validators[validation];
					}

					if(_.isFunction(validationOptions)){
						validationOptions = _.bind(validationOptions, self);
					}
					if(_.isFunction(validation) && !errors[property]){
						var result = validation(value, property, validationOptions, model, model.attributes);
						if(result){
							errors[property] = result;
						}
					}
				});

			});

			if(options.displayErrors){
				this.validated(_.isEmpty(errors), model, errors, validations);
				var firstErrorProperty = _.first(_.keys(errors));
				if(firstErrorProperty){
					for(var selector  in this.modelBindings){
						if(this.modelBindings.hasOwnProperty(selector) && this.modelBindings[selector] === firstErrorProperty){
							this.$(selector).focus();
							break;
						}
					}
				}
			}

			return (_.isEmpty(errors) ? null: errors);
		},
		validated : function(isValid, model, errors, validatedAttributes){

			if(validatedAttributes){
				var self = this;
				_.each(this.modelBindings, function(property, selector){
					if(validatedAttributes[property]){
						if(errors[property]){
							self.showValidationError(selector, property, errors[property]);
						} else{
							self.hideValidationError(selector, property);
						}
					}
				});
			}
		},

		_preValidate : function(property, value){
			var self = this;
			var validations = this.validation[property];

			var errors = {};
			if(validations){
				_.each(validations, function(validationOptions, validation){
					if(_.isString(validation)){
						validation = Backbone.Validation.validators[validation];
					}
					if(_.isFunction(validationOptions)){
						validationOptions = _.bind(validationOptions, self);
					}
					if(_.isFunction(validation) && !errors[property]){
						var result = validation(value, property, validationOptions, self.model, self.model.attributes);
						if(result){
							errors[property] = result;
						}
					}
				});

			}


			return (_.isEmpty(errors) ? null: errors);
		},
		hideValidationError : function(selector, property){
			var $field = this.$(selector);
			$field
				.removeClass("invalid")
				.removeAttr("data-validation-error");

			var $controlGroup = $field.closest(".control-group");
			$controlGroup.removeClass("error");

			var errorStyle = $field.data("errorStyle");
			if(!errorStyle){
				errorStyle = $field.closest("[data-error-style]").data("errorStyle");
			}

			if (errorStyle === "tooltip") {
				if ($field.data("tooltip")) {
					$field.tooltip("hide");
					$field.tooltip("destroy");
					return;
				}
			} else if (errorStyle === "inline") {
				return $controlGroup.find(".help-inline").hide();
			} else {
				return $controlGroup.find(".help-block").hide();
			}
		},
		showValidationError : function(selector, property, errors){
			if(!_.isArray(errors)){
				errors = [errors];
			}

			var $field = this.$(selector);


			var $controlGroup = $field.closest(".control-group");
			$controlGroup.addClass("error");


			var label = property;
			if($field.data("errorLabel")){
				label = $field.data("errorLabel");
			} else if($controlGroup.data("errorLabel")){
				label = $controlGroup.data("errorLabel");
			} else{
				var $label = $controlGroup.find("label:first");
				if($label.length) {
					label = $label.text();
				} else if($field.attr("placeholder")){
					label = $field.attr("placeholder");
				}
			}

			label = label.replace(/:\s*$/, "");


			for(var i = 0; i < errors.length; i++){
				errors[i] = errors[i].replace("Property [" + property + "]", label).replace("[" + property + "]", label);
			}
			errors = errors.join("<br />");

			$field
				.addClass("invalid")
				.attr("data-validation-error", errors);

			var errorStyle = $field.data("errorStyle");
			if(!errorStyle){
				errorStyle = $field.closest("[data-error-style]").data("errorStyle");
			}

			var $target;
			if (errorStyle === "tooltip") {
				$field.tooltip("destroy");
				$field.tooltip({
					title     : errors,
					"class"   : "invalid",
					trigger   : "manual",
					animation : false
				});
				$field.tooltip("show");
			} else if (errorStyle === "inline") {
				if ($controlGroup.find(".help-inline").length === 0) {
					$controlGroup.find(".controls").append("<span class=\"help-inline error-message\"></span>");
				}
				$target = $controlGroup.find(".help-inline").show();
				return $target.html(errors);
			} else {
				if ($controlGroup.find(".help-block").length === 0) {
					$controlGroup.find(".controls").append("<span class=\"help-block error-message\"></span>");
				}
				$target = $controlGroup.find(".help-block").show();
				return $target.html(errors);
			}
		},
		hideValidationErrors : function(selector){
			var self = this;
			var $selector = $(selector || this.$el);

			$selector.find("[data-validation-error]").each(function(){
				self.hideValidationError(this);
			});
		},
		fetchModel : function(options){
			var self = this;

			options = options || {};
			options = _.defaults(options, this.fetchDefaults, {success : this.render});


			_.defer(function(){
				self.model.fetch(options);
			});
		},
		fetchCollection : function(options){
			var self = this;

			options = options || {};
			options = _.defaults(options, this.fetchDefaults, {success : this.render});

			_.defer(function(){
				self.collection.fetchNext(options);
			});
		},
		fetch : function(options){
			if(this.model){
				return this.fetchModel(options);
			} else if(this.collection){
				return this.fetchCollection(options);
			}
		},
		fetchPage : function(page, options){
			options = options || {};
			options = _.defaults(options, this.fetchDefaults, {success : this.render});
			return this.collection.fetchPage(page, options);
		},
		nextPage : function(event){
			if(event && event.preventDefault){
				event.preventDefault();

				if(event.handled) { return; }
				event.handled = true;
			}
			var options = _.defaults(this.fetchDefaults, {success : this.render});

			return this.collection.fetchNext(options);
		},
		prevPage : function(event){
			if(event && event.preventDefault){
				event.preventDefault();

				if(event.handled) { return; }
				event.handled = true;
			}

			var options = _.defaults(this.fetchDefaults, {success : this.render});
			return this.collection.fetchPrev(options);
		},
		page : function(page, options){
			return this.fetchPage(page, options);
		}

	});

});

