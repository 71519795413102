define('views/instructor/raise-hand',['require','underscore','library/vlp/view','hbs!tpls/instructor/raise-hand.handlebars','utilities/gravatar'],function (require) {
	"use strict";

	// library dependencies
	var _ = require("underscore");

	// class dependencies
	var BaseView = require("library/vlp/view"),
		tpl = require("hbs!tpls/instructor/raise-hand.handlebars"),
		gravatar = require("utilities/gravatar");

	return BaseView.extend({
		template: tpl,
		className: "raise-hand-list",

		events: {
			"click .mark-as-resolved": "onMarkAsResolved"
		},

		modelBindings: {},

		/**
		 * constructor
		 *`
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize: function (options) {
			_.bindAll(this);

			options = options || {};
			this.classId = options.classId;
			this.model.set("avatar", gravatar(this.model.get("username"), {size: 40, "default": "mm"}));

			this.listenTo(this.model, "change:status", this.raiseHandChanged);
			this.listenTo(this.model, "remove", this.removeRaisedHand);
			this.render();
		},

		serialize: function () {
			var data = this.model.toHandlebars();
			data.classId = this.classId;
			return data;
		},

		updateReceived: function(info){
			this.model.set("status", info.data.status);
		},

		raiseHandChanged: function () {
			if(this.model.get("status")=="on"){
				this.render();
			}else{
				this.remove();
			}
		},

		removeRaisedHand: function () {
			this.remove();
		},

		onMarkAsResolved: function () {
			this.model.set("status", "off");
			this.model.deleteHelpRequest();
		},

		afterRender: function () {

		}
	});
});

