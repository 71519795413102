define('models/instructor',['require','exports','module','library/vlp/app','models/account','library/vlp/collection'],function (require, exports) {
	"use strict";


	//class dependencies
	var App            = require("library/vlp/app"),
	    Account        = require("models/account"),
	    BaseCollection = require("library/vlp/collection");

	//Use exports for requirejs circular dependency resolving
	var Instructor = exports;

	//Add Instructor to the App.Models namespace
	App.Models.Instructor = Instructor;

	Instructor.Model = Account.Model.extend({
		urlRoot: "/classes",

		/**
		 * Default values for empty Models (new Instructor.Model())
		 */
		defaults: {
		},

		types: {

		},

		/**
		 * Constructor
		 */
		initialize: function () {

		}

	});

	Instructor.Collection = BaseCollection.extend({
		model: Instructor.Model,
		urlRoot: "/classes"
	});
});

