
define('hbs!tpls/common/tenant-eula.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "		<h1>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"eula.title",(depth0 != null ? depth0.tenantName : depth0),{"name":"$","hash":{},"data":data}))
    + "</h1>\n		"
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n		<p></p>\n";
},"3":function(depth0,helpers,partials,data) {
    return "		<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"page-message\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.content : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n	<button type=\"button\" class=\"btn btn-large eula-cancel\" data-toggle=\"modal\" href=\"#modal_cancel\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</button>\n	<button type=\"button\" class=\"btn btn-large btn-primary eula-agree\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","saving",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"eula.agree",{"name":"$","hash":{},"data":data}))
    + "</button>\n</div>\n\n<div id=\"modal_cancel\" class=\"modal hide fade fluid-height\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-times-circle\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"eula.cancel.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<p>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"eula.cancel.tenantMessage",{"name":"$","hash":{},"data":data}))
    + "</p>\n				<button type=\"button\" class=\"btn deny\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"eula.cancel.deny",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<button type=\"button\" class=\"btn btn-primary eula-cancel-return\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"eula.cancel.return",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<div class=\"loading exit-spinner hide\"></div>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('common_tenant_eula', t);
return t;
});

