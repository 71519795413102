
define('hbs!tpls/catalogs/lab-listing-modal.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.image : depth0),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0),"inverse":this.program(8, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var helper;

  return "						<span class=\"image-icon\" style=\"background-image:url("
    + this.escapeExpression(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"image","hash":{},"data":data}) : helper)))
    + ")\"></span>\n";
},"4":function(depth0,helpers,partials,data) {
    var helper;

  return "						<span class=\"fa fa-fw fa-"
    + this.escapeExpression(((helper = (helper = helpers.icon || (depth0 != null ? depth0.icon : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"icon","hash":{},"data":data}) : helper)))
    + "\"></span>\n";
},"6":function(depth0,helpers,partials,data) {
    var helper;

  return "						"
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n";
},"8":function(depth0,helpers,partials,data) {
    var helper;

  return "						"
    + this.escapeExpression(((helper = (helper = helpers.badge || (depth0 != null ? depth0.badge : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"badge","hash":{},"data":data}) : helper)))
    + "\n";
},"10":function(depth0,helpers,partials,data) {
    var stack1;

  return "					<span class=\"fa fa-fw fa-tags\"></span>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0),"inverse":this.program(11, data, 0),"data":data})) != null ? stack1 : "");
},"11":function(depth0,helpers,partials,data) {
    var helper;

  return "						"
    + this.escapeExpression(((helper = (helper = helpers.tag || (depth0 != null ? depth0.tag : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"tag","hash":{},"data":data}) : helper)))
    + "\n";
},"13":function(depth0,helpers,partials,data) {
    var stack1;

  return "					<span class=\"fa fa-fw fa-desktop\"></span>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0),"inverse":this.program(14, data, 0),"data":data})) != null ? stack1 : "");
},"14":function(depth0,helpers,partials,data) {
    var helper;

  return "						"
    + this.escapeExpression(((helper = (helper = helpers.system || (depth0 != null ? depth0.system : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"system","hash":{},"data":data}) : helper)))
    + "\n";
},"16":function(depth0,helpers,partials,data) {
    var stack1;

  return "					<span class=\"fa fa-fw fa-shopping-cart\"></span>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0),"inverse":this.program(17, data, 0),"data":data})) != null ? stack1 : "");
},"17":function(depth0,helpers,partials,data) {
    var helper;

  return "						"
    + this.escapeExpression(((helper = (helper = helpers.product || (depth0 != null ? depth0.product : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"product","hash":{},"data":data}) : helper)))
    + "\n";
},"19":function(depth0,helpers,partials,data) {
    var stack1;

  return "					<span class=\"fa fa-fw fa-user\"></span>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0),"inverse":this.program(20, data, 0),"data":data})) != null ? stack1 : "");
},"20":function(depth0,helpers,partials,data) {
    var helper;

  return "						"
    + this.escapeExpression(((helper = (helper = helpers.author || (depth0 != null ? depth0.author : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"author","hash":{},"data":data}) : helper)))
    + "\n";
},"22":function(depth0,helpers,partials,data) {
    return "					<span class=\"fa fa-fw fa-plus\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.new",{"name":"$","hash":{},"data":data}))
    + "\n";
},"24":function(depth0,helpers,partials,data) {
    return "					<div>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n";
},"26":function(depth0,helpers,partials,data) {
    var stack1;

  return "\n					<div class=\"labs\">\n"
    + ((stack1 = helpers.each.call(depth0,depth0,{"name":"each","hash":{},"fn":this.program(27, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "					</div>\n\n";
},"27":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = this.invokePartial(partials.catalogs_lab_small,depth0,{"name":"catalogs_lab_small","data":data,"indent":"\t\t\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "");
},"29":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.length : depth0),{"name":"if","hash":{},"fn":this.program(30, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"30":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<div class=\"modal-footer\">\n					<ul class=\"lab-listing-pager\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.prevPage : depth0),{"name":"if","hash":{},"fn":this.program(31, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.nextPage : depth0),{"name":"if","hash":{},"fn":this.program(33, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "					</ul>\n				</div>\n";
},"31":function(depth0,helpers,partials,data) {
    var helper;

  return "							<li class=\"previous pull-left\">\n								<button type=\"button\" class=\"btn\" value=\""
    + this.escapeExpression(((helper = (helper = helpers.prevPage || (depth0 != null ? depth0.prevPage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"prevPage","hash":{},"data":data}) : helper)))
    + "\"><span class=\"fa fa-fw fa-arrow-left\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"previous",{"name":"$","hash":{},"data":data}))
    + "</button>\n								<div class=\"spinner prev-page-spinner hide\"></div>\n							</li>\n";
},"33":function(depth0,helpers,partials,data) {
    var helper;

  return "							<li class=\"next pull-right\">\n								<button type=\"button\" class=\"btn\" value=\""
    + this.escapeExpression(((helper = (helper = helpers.nextPage || (depth0 != null ? depth0.nextPage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"nextPage","hash":{},"data":data}) : helper)))
    + "\" class=\"btn\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"next",{"name":"$","hash":{},"data":data}))
    + "<span class=\"fa fa-fw fa-arrow-right\"></span></button>\n								<div class=\"spinner next-page-spinner hide\"></div>\n							</li>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "\n<div class=\"modal-dialog\">\n	<div class=\"modal-content\">\n		<div class=\"modal-header\">\n			<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n			<h3 class=\"modal-title\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.badge : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.tag : depth0),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.system : depth0),{"name":"if","hash":{},"fn":this.program(13, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.product : depth0),{"name":"if","hash":{},"fn":this.program(16, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.author : depth0),{"name":"if","hash":{},"fn":this.program(19, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.newLabs : depth0),{"name":"if","hash":{},"fn":this.program(22, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labs",{"name":"$","hash":{},"data":data}))
    + "\n			</h3>\n		</div>\n		<div class=\"modal-body\">\n			<div class=\"lab-listing\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.loading : depth0),{"name":"if","hash":{},"fn":this.program(24, data, 0),"inverse":this.program(26, data, 0),"data":data})) != null ? stack1 : "")
    + "			</div>\n		</div>\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.loading : depth0),{"name":"unless","hash":{},"fn":this.program(29, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n</div>\n";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('catalogs_lab_listing_modal', t);
return t;
});

