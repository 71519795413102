define('utilities/analytics/tealium',['require','underscore','utilities/analytics/base'],function (require) {
	"use strict";

	var _ = require("underscore");

	var BaseClass = require("utilities/analytics/base");


	return BaseClass.extend({
		mode: "production",
		isTest : true,
		bufferedTrackEvents: [],
		initialize : function (info) {
			BaseClass.prototype.initialize.apply(this, arguments);
			if (!this.enable) { return; }

			info.fields = _.defaults(info.fields || {}, {});

			var hostname = location.hostname.toLowerCase();
			this.isTest = (hostname === "localhost" || hostname.indexOf(".dev") !== -1 || hostname.indexOf(".stage") !== -1 || hostname.indexOf(".test") !== -1);



			if (info.fields) {
				for (var field in info.fields) {
					if (info.fields.hasOwnProperty(field)) {
						this.set(field, info.fields[field]);
					}
				}
			}
			this._initializeService();
		},
		set : function (name, value) {
		},
		_doTrackEvent : function (category, action, label, value, nonInteraction) {
			if(!window.utag){
				this.bufferedTrackEvents.push({
					category      : category,
					action        : action,
					label         : label,
					value         : value,
					nonInteraction: nonInteraction
				});
				return;
			}
			var pageName = category;
			if(action){
				pageName += ": " + action;
			}
			if(label){
				pageName += ": " + label;
			}
			if(value){
				pageName += ": " + value;
			}
			window.utag.view({
					tealium_event: "page_view",
					event_name    : action,
					page_type     : category,
					page_name     : pageName
				},
				function() {}
			);

		},
		_doTiming : function (category, variable, value, label) {

		},
		_initializeService : function () {
			window.utag_cfg_ovrd = {noview : true};

			var metaElement = document.createElement("meta");
			metaElement.setAttribute("name", "microsites-utag");
			if (this.isTest) {
				metaElement.setAttribute("content", "https://tags.tiqcdn.com/utag/vmware/microsites-privacy/qa/utag.js");
			} else {
				metaElement.setAttribute("content", "https://tags.tiqcdn.com/utag/vmware/microsites-privacy/prod/utag.js");
			}
			document.head.appendChild(metaElement);

			var scriptTagParent = document.getElementsByTagName("script")[0].parentNode;

			var utagScript  = document.createElement("script");
			utagScript.type = "text/javascript";
			if(this.isTest) {
				utagScript.src = "//tags.tiqcdn.com/utag/vmware/microsites-privacy/qa/utag.sync.js";
			} else {
				utagScript.src = "//tags.tiqcdn.com/utag/vmware/microsites-privacy/prod/utag.sync.js";
			}
			utagScript.async = false;
			scriptTagParent.appendChild(utagScript);

			var utagDataScript  = document.createElement("script");
			utagDataScript.type = "text/javascript";
			utagDataScript.src  = "https://www.vmware.com/files/templates/inc/utag_data.js";
			utagDataScript.async = false;
			scriptTagParent.appendChild(utagDataScript);

			window.utag_data = { content_system : "microsites-privacy : vmwarelearningplatform.com" };
		},
		performanceCookiesAllowedCallback: function () {
			if(this.bufferedTrackEvents.length && window.utag){
				for(var i = 0; i < this.bufferedTrackEvents.length; i++){
					var event = this.bufferedTrackEvents[i];
					this._doTrackEvent(event.category, event.action, event.label, event.value, event.nonInteraction);
				}
				this.bufferedTrackEvents = [];
			}
		}
	});

});

