define('views/instructor/class',['require','jquery','underscore','library/vlp/app','library/vlp/view','views/instructor/entitlement-listing','views/instructor/instructor-listing','hbs!tpls/instructor/class.handlebars','i18n!nls/console','hbs!tpls/instructor/warn-modal.handlebars'],function (require) {
	"use strict";

	var $ = require("jquery"),
		_ = require("underscore");

	var App = require("library/vlp/app"),
		BaseView = require("library/vlp/view"),
		EntitlementListingView = require("views/instructor/entitlement-listing"),
		InstructorListingView = require("views/instructor/instructor-listing"),
		tpl = require("hbs!tpls/instructor/class.handlebars"),
		pageTexts = require("i18n!nls/console"),
		warnModalTpl = require("hbs!tpls/instructor/warn-modal.handlebars");

	return BaseView.extend({
		template: tpl,
		className: "class",

		events: {
			"click .more-details": "onMoreDetails",
			"click .blank-all-screens": "onBlankAllScreens",
			"submit .form-search": "submitSearch",
			"search .form-search [name=search]": "submitSearch",
			"keyup  .form-search [name=search]": "search",
			"keydown #warning_progress_value": "validateInput",
			"keyup #warning_progress_value": "validateInput"
		},

		modelBindings: {
			"#warning_progress_value": "warningValue",
			"#warning_progress_type": "warningType"
		},

		initialize: function (options) {
			_.bindAll(this);
			this.storageKey = "classSettings_" + this.model.get("id");
			this.classSettings = App.session.get(this.storageKey) || {};
			this.model.set(this.classSettings);

			this.panelManager = options.panelManager;

			this.model.pushSubscribe();
			this.model.pushUpdate();

			App.push.on("other:received:instructorClass:" + this.model.get("id") + ":update", this.classUpdated, this);

			this.listenTo(this.model.get("entitlements"), "change", this.entitlementInfoChanged);
			this.listenTo(this.model, "change:manual", this.onManualChanged);
			this.listenTo(this.model, "change:warningType change:warningValue change:warned", this.onSettingsChanged);
			this.listenTo(this.model, "change:warningType change:warningValue", this.onWarningChanged);
			App.push.on("closing", this.resetClass, this);
			App.locale.mergeI18n(pageTexts);
		},

		classUpdated: function (info) {
			if(info.data){
				this.model.fetch({
					params : {"with" : ["lab", "instructors", "entitlements"]},
					success : this.render
				});
				App.mainView.generalMessage({
					id          : "class_updated_message",
					message     : App.i18n("instructor.class.updated"),
					type        : "info",
					removeDelay : 5000,
					dismissible : true
				});
			} else{
				App.mainView.generalMessage({
					id          : "class_updated_message",
					message     : App.i18n("instructor.class.updatedRefresh"),
					type        : "info",
					dismissible : true
				});
			}
		},

		validateInput: function (event) {
			if (!((event.keyCode > 95 && event.keyCode < 106) || (event.keyCode > 47 && event.keyCode < 58) || event.keyCode == 8)) {
				event.preventDefault();
				return false;
			}

			var $warningProgressValue = this.$("#warning_progress_value");
			var input = $warningProgressValue.val();
			var totalSteps;

			if (this.model.get("warningType") == "percent") {
				totalSteps = 100;
			} else if (this.model.get("warningType") == "step") {
				totalSteps = this.model.get("manual").get("steps").length;
			}

			if (input > totalSteps && event.keyCode != 8) {
				var inputTruncated = (parseInt(input.slice(0, totalSteps.toString().length)) == totalSteps) ? totalSteps : parseInt(input.slice(0, (totalSteps.toString().length - 1)));

				$warningProgressValue.val(parseInt(inputTruncated));
				event.preventDefault();
				return false;
			}
		},

		afterRender: function () {
			if (!this.entitlementListingView) {
				// Instantiate the Entitlement Listing view
				this.entitlementListingView = new EntitlementListingView({
					el: this.$("#entitlement_listing"),
					classId: this.model.get("id")
				});
			}
			this.entitlementListingView.setElement(this.$("#entitlement_listing"));
			this.entitlementListingView.show();

			if (!this.instructorListing) {
				// Instantiate the Instructors view
				this.instructorListing = new InstructorListingView({
					el: this.$("#instructors_container"),
					classId: this.model.get("id"),
					collection: this.model.get("instructors")
				});
			}
			this.instructorListing.setElement(this.$("#instructors_container"));

			this.setProgressValueMax();
			this.updateBlankAllScreensButton();

			this.instructorListing.render();

			if(this.$el.is(":visible")){
				var consoleListingPanel = this.panelManager.getPanel("#vm_listing_panel");
				if(consoleListingPanel){
					consoleListingPanel.hide();
				}
			}
		},

		submitSearch: function (event) {

			if (event && event.preventDefault) {
				event.preventDefault();
				if (event.handled) {
					return;
				}
				event.handled = true;
			}

			this.model.set("searchVal", this.$("[name=search]").val());
			this.entitlementListingView.search(this.model.get("searchVal") || "");
		},

		search: function (event) {

			if (event && event.preventDefault) {
				event.preventDefault();
				if (event.handled) {
					return;
				}
				event.handled = true;
			}

			var self = this;
			if (!this._throttledSearch) {
				this._throttledSearch = _.debounce(function () {
					self.entitlementListingView.search(this.model.get("searchVal") || "");
				}, 1000);
			}

			var searchValue = this.$("[name=search]").val();
			if (this.model.get("searchVal") != searchValue) {
				this.model.set("searchVal", searchValue);
				this._throttledSearch();
			}
		},

		onBlankAllScreens: function () {
			this.model.toggleBlankAllScreens();
			this.updateBlankAllScreensButton();
		},

		updateBlankAllScreensButton: function () {
			if (this.model.get("screenStatus") == "blanked") {
				this.$(".blank-all-screens").button("enable");
			} else {
				this.$(".blank-all-screens").button("reset");
			}
		},

		onWarningChanged: function (event) {
			this.model.set("warned", false);
			this.$("#warning_progress_value").val(0);
			this.entitlementInfoChanged();
		},


		entitlementInfoChanged: function (updatedEntitlement) {

			this.setProgressValueMax();

			if (this.model.get("warningValue") === 0) {
				return;
			}
			if (this.model.get("warned")) {
				return;
			}

			var averageProgress = 0;
			var maxProgress = 0;
			var minProgress = Number.POSITIVE_INFINITY;

			var averageStep = 0;
			var maxStep = 0;
			var minStep = Number.POSITIVE_INFINITY;

			var totalActiveEntitlements = 0;

			this.model.get("entitlements").each(function (entitlement) {

				var phase = entitlement.get("phase");

				if (phase == "running" || phase == "starting" || phase == "end" || phase == "grade") {

					totalActiveEntitlements += 1;

					var progress = entitlement.get("progress");
					maxProgress = Math.max(maxProgress, progress);
					minProgress = Math.min(minProgress, progress);
					averageProgress += progress;

					var activeStepNumber = parseInt(entitlement.get("activeStepNumber"), 10);
					if (isNaN(activeStepNumber)) {
						activeStepNumber = 0;
					}
					maxStep = Math.max(maxStep, activeStepNumber);
					minStep = Math.min(minStep, activeStepNumber);
					averageStep += activeStepNumber;
				}
			});

			averageProgress = averageProgress / totalActiveEntitlements;
			averageStep = averageStep / totalActiveEntitlements;

			if (this.model.get("warningType") == "step" && this.model.get("warningValue") != "" && this.model.get("warningValue") <= minStep && totalActiveEntitlements > 0) {
				this.showWarning();
			} else if (this.model.get("warningType") == "percent" && this.model.get("warningValue") != "" && this.model.get("warningValue") <= minProgress && totalActiveEntitlements > 0) {
				this.showWarning();
			}
		},

		onSettingsChanged: function () {
			var settings = this.model.pick("warningType", "warningValue", "warned");
			App.session.set(this.storageKey, settings, 1);
		},

		onManualChanged: function () {
			this.setProgressValueMax();
			this.stopListening(this.model.get("manual"));
			this.listenTo(this.model.get("manual"), "change:steps", this.setProgressValueMax);
		},

		setProgressValueMax: function () {
			var $warningProgressValue = this.$("#warning_progress_value");
			if (this.model.get("warningType") == "step") {
				if (this.model.get("manual") && this.model.get("manual").get("steps")) {
					$warningProgressValue.attr("max", this.model.get("manual").get("steps").length);
				} else {
					$warningProgressValue.removeAttr("max");
				}
			} else {
				$warningProgressValue.attr("max", 100);
				if ($warningProgressValue.val() > 100) {
					$warningProgressValue.val(100);
				}
			}
		},

		showWarning: function () {
			this.model.set("warned", true);
			if ($("#modal_instructor_warning").length) {
				$("#modal_instructor_warning").modal("hide");
				$("#modal_instructor_warning").remove();
			}
			var modalHtml = warnModalTpl(this.model.toHandlebars());
			$("body").append(modalHtml);
			$("#modal_instructor_warning").modal("show");
		},

		clearEntitlementChanges: function () {
			this.model.set({
				broadcastingDisplay: false
			});
			if (this.model.has("entitlements") && this.model.get("entitlements").length) {
				this.model.get("entitlements").each(function (entitlement) {
					entitlement.set({
						instructorViewing: false,
						broadcastingDisplay: false,
						takenOver: false
					});
				});
			}
		},

		resetClass: function () {
			this.model.set({
				screenStatus: "normal",
				broadcastingDisplay: false,
				broadcastingEntitlement: null,
				activeScreenTicket: false,
				activeVmx: false
			});
			if (this.model.has("entitlements") && this.model.get("entitlements").length) {
				this.model.get("entitlements").each(function (entitlement) {
					entitlement.set({
						instructorViewing: false,
						broadcastingDisplay: false,
						takenOver: false
					});
				});
			}
		}
	});
});

