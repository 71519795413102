
define('hbs!tpls/catalogs/lab-landing.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "	<div class=\"loading well\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labLanding.loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.fetched : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(42, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "		<div class=\"lab-outer\">\n			<div class=\"lab-box lab-item"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.newItem : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.systems : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.badges : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"ratingEnabled",{"name":"config","hash":{},"data":data}),(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"reviewsEnabled",{"name":"config","hash":{},"data":data}),{"name":"or","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n				"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.newItem : depth0),{"name":"if","hash":{},"fn":this.program(13, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n				<div class=\"lab-top\">\n"
    + ((stack1 = this.invokePartial(partials.catalogs_lab_systems,(depth0 != null ? depth0.systems : depth0),{"name":"catalogs_lab_systems","data":data,"indent":"\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + ((stack1 = this.invokePartial(partials.catalogs_lab_reviews_and_ratings,depth0,{"name":"catalogs_lab_reviews_and_ratings","data":data,"indent":"\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + ((stack1 = this.invokePartial(partials.catalogs_lab_badges,(depth0 != null ? depth0.badges : depth0),{"name":"catalogs_lab_badges","data":data,"indent":"\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "				</div>\n\n				<div class=\"lab-middle\">\n					<div class=\"lab-image\">\n						<img data-property=\"thumbnail\" src=\""
    + this.escapeExpression(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"alt","catalogs.labs.thumbnailAlt",(depth0 != null ? depth0.name : depth0),{"name":"$$","hash":{},"data":data}))
    + "/>\n					</div>\n					<div class=\"lab-text\">\n						<h3 class=\"lab-name\">"
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.enrolled : depth0),{"name":"if","hash":{},"fn":this.program(15, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":this.program(23, data, 0),"inverse":this.program(25, data, 0),"data":data})) != null ? stack1 : "")
    + "					</div>\n					<div class=\"lab-buttons\">\n"
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.display : depth0)) != null ? stack1.showInvite : stack1),{"name":"if","hash":{},"fn":this.program(27, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.partOfCourse : depth0),{"name":"unless","hash":{},"fn":this.program(29, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "					</div>\n				</div>\n\n				<div class=\"info-box-header lab-detail-header expanded\" data-target=\"#lab_landing_info\">\n					<h3>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.showDetails",{"name":"$","hash":{},"data":data}))
    + "</h3>\n				</div>\n				<div id=\"lab_landing_info\" class=\"info-box lab-detail\">\n\n"
    + ((stack1 = this.invokePartial(partials.catalogs_lab_common_metadata,depth0,{"name":"catalogs_lab_common_metadata","data":data,"indent":"\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + ((stack1 = this.invokePartial(partials.catalogs_lab_common_metadata_links,depth0,{"name":"catalogs_lab_common_metadata_links","data":data,"indent":"\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "\n				</div>\n			</div>\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"reviewsEnabled",{"name":"config","hash":{},"fn":this.program(31, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n\n\n\n		<div class=\"other-labs\">\n			<div class=\"related-labs\">\n				<div class=\"header-bar inverted\">\n					<h3>\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labLanding.related",{"name":"$","hash":{},"data":data}))
    + "\n					</h3>\n				</div>\n\n				<div class=\"labs\">\n\n"
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.relatedLabs : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":this.program(37, data, 0),"inverse":this.program(40, data, 0),"data":data})) != null ? stack1 : "")
    + "				</div>\n\n			</div>\n		</div>\n";
},"5":function(depth0,helpers,partials,data) {
    return " new-lab";
},"7":function(depth0,helpers,partials,data) {
    return "with-systems";
},"9":function(depth0,helpers,partials,data) {
    return "with-badges";
},"11":function(depth0,helpers,partials,data) {
    return "with-reviews";
},"13":function(depth0,helpers,partials,data) {
    return "<span class=\"new-lab-label\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.new",{"name":"$","hash":{},"data":data}))
    + "</span>";
},"15":function(depth0,helpers,partials,data) {
    var stack1;

  return "							<div class=\"lab-status\">\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,((stack1 = (depth0 != null ? depth0.entitlement : depth0)) != null ? stack1.status : stack1),"completed",{"name":"eq","hash":{},"fn":this.program(16, data, 0),"inverse":this.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "							</div>\n";
},"16":function(depth0,helpers,partials,data) {
    return "									<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"transcript/lab/{{entitlement.id}}","page":"catalogs"},"data":data}))
    + "\" class=\"enrolled completed\"><span class=\"fa fa-fw fa-check-circle\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.completed",{"name":"$","hash":{},"data":data}))
    + "</a>\n";
},"18":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,((stack1 = (depth0 != null ? depth0.entitlement : depth0)) != null ? stack1.status : stack1),"expired",{"name":"eq","hash":{},"fn":this.program(19, data, 0),"inverse":this.program(21, data, 0),"data":data})) != null ? stack1 : "");
},"19":function(depth0,helpers,partials,data) {
    return "										<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"transcript/lab/{{entitlement.id}}","page":"catalogs"},"data":data}))
    + "\" class=\"enrolled expired\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.expired",{"name":"$","hash":{},"data":data}))
    + "</a>\n";
},"21":function(depth0,helpers,partials,data) {
    return "										<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"enrollments/lab/{{entitlement.id}}","page":"catalogs"},"data":data}))
    + "\" class=\"enrolled incomplete\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.enrolled",{"name":"$","hash":{},"data":data}))
    + "</a>\n";
},"23":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "							<div class=\"full-description user-data\">"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n";
},"25":function(depth0,helpers,partials,data) {
    var helper;

  return "							<p class=\"short-description user-data\">"
    + this.escapeExpression(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</p>\n";
},"27":function(depth0,helpers,partials,data) {
    return "							<button type=\"button\" class=\"btn invite-friend\" data-toggle=\"popover\" data-html=\"true\" data-trigger=\"click\" data-placement=\"top\" data-class=\"popover-invite\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"share.invite.buttonInvite",{"name":"$","hash":{},"data":data}))
    + "</button>\n";
},"29":function(depth0,helpers,partials,data) {
    var stack1;

  return "							<div class=\"enrollment\">\n"
    + ((stack1 = this.invokePartial(partials.catalogs_lab_enroll_button,depth0,{"name":"catalogs_lab_enroll_button","data":data,"indent":"\t\t\t\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "							</div>\n";
},"31":function(depth0,helpers,partials,data) {
    var stack1;

  return "\n				<div class=\"lab-box lab-item\">\n					<h3 class=\"title\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.reviews.title",(depth0 != null ? depth0.reviewCount : depth0),{"name":"$","hash":{},"data":data}))
    + "\n					</h3>\n\n					<div class=\"lab-reviews\">\n"
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.reviews : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":this.program(32, data, 0),"inverse":this.program(35, data, 0),"data":data})) != null ? stack1 : "")
    + "					</div>\n				</div>\n";
},"32":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.reviews : depth0),{"name":"each","hash":{},"fn":this.program(33, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"33":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = this.invokePartial(partials.catalogs_lab_review,depth0,{"name":"catalogs_lab_review","data":data,"indent":"\t\t\t\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "");
},"35":function(depth0,helpers,partials,data) {
    return "							<div class=\"alert alert-info\">\n								"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.reviews.noReviews",{"name":"$","hash":{},"data":data}))
    + "\n							</div>\n";
},"37":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.relatedLabs : depth0),{"name":"each","hash":{},"fn":this.program(38, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"38":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = this.invokePartial(partials.catalogs_lab_small,depth0,{"name":"catalogs_lab_small","data":data,"indent":"\t\t\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "");
},"40":function(depth0,helpers,partials,data) {
    return "						<div class=\"no-related\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labLanding.noRelated",{"name":"$","hash":{},"data":data}))
    + "\n						</div>\n";
},"42":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.loggedIn : depth0),{"name":"if","hash":{},"fn":this.program(43, data, 0),"inverse":this.program(45, data, 0),"data":data})) != null ? stack1 : "");
},"43":function(depth0,helpers,partials,data) {
    return "\n			<div class=\"lab-404\">\n				<div class=\"alert alert-warn\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labLanding.notFound",{"name":"$","hash":{},"data":data}))
    + "\n				</div>\n			</div>\n";
},"45":function(depth0,helpers,partials,data) {
    return "			<div class=\"alert alert-danger\">\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labLanding.pleaseLogin",{"name":"$","hash":{},"data":data}))
    + "\n			</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.loading : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n<div id=\"modal_course_no_access\" class=\"modal fluid fade global hide\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-question-circle\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"errors.accessDenied",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<div id=\"support_options\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"errors.accessDeniedMsg",{"name":"$","hash":{},"data":data}))
    + "\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('catalogs_lab_landing', t);
return t;
});

