define('models/promo-content',['require','exports','module','library/vlp/app','library/vlp/model','library/vlp/collection'],function (require, exports) {
	"use strict";

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection");


	//Use exports for requirejs circular dependency resolving
	var PromoContent = exports;

	//Add PromoContent to the App.Models namespace
	App.Models.PromoContent = PromoContent;


	PromoContent.Model = BaseModel.extend({
		urlRoot : "events/promoContent",
		/**
		 * Default values for empty Models (new PromoContent.Model())
		 */
		defaults  : {
		},
		setters : {
			content : function(value){
				if(value.match(/^\s*https?:/i)){
					if(value.match(/\.(jpe?g|png|gif)(\?.+)?$/i)){
						value = "<img src=\""+ value +"\" />";
					} else {
						value = "<iframe src=\""+ value +"\" width=\"100%\" frameborder=\"0\" />";
					}
				}
				return value;
			}
		},
		/**
		 * Constructor
		 */
		initialize : function(){

		}
	});


	PromoContent.Collection = BaseCollection.extend({
		model : PromoContent.Model
	});

});

