/**
 * Offers Backbone View
 */
define('views/common/offers',['require','underscore','library/vlp/view','models/offer','hbs!tpls/common/offers.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var _        = require("underscore");

	//class dependencies
	var BaseView = require("library/vlp/view"),
	    Offer    = require("models/offer"),
	    tpl      = require("hbs!tpls/common/offers.handlebars");

	return BaseView.extend({
		template : tpl,
		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		el : "#offers",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			//"click a.more-info": "showDetails"
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			options = options || {};
			options.fetchDefaults = options.fetchDefaults || {};

			this.fetchDefaults = _.extend({}, options.fetchDefaults);
			this.collection = new Offer.Collection();
			this.listenTo(this.collection, "request sync error", this.render);
		}
	});

});
