
define('hbs!tpls/layout/header.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.tenantInstructorGuideUrl : depth0),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var helper;

  return "							<li>\n								<a id=\"show_user_guide\" href=\""
    + this.escapeExpression(((helper = (helper = helpers.tenantInstructorGuideUrl || (depth0 != null ? depth0.tenantInstructorGuideUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"tenantInstructorGuideUrl","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n									"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.userGuide",{"name":"$","hash":{},"data":data}))
    + " <i class=\"fa fa-external-link\"></i>\n								</a>\n							</li>\n";
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.supportGuideUrl : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"5":function(depth0,helpers,partials,data) {
    var helper;

  return "							<li>\n								<a id=\"show_user_guide\" href=\""
    + this.escapeExpression(((helper = (helper = helpers.supportGuideUrl || (depth0 != null ? depth0.supportGuideUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"supportGuideUrl","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n									"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.userGuide",{"name":"$","hash":{},"data":data}))
    + " <i class=\"fa fa-external-link\"></i>\n								</a>\n							</li>\n";
},"7":function(depth0,helpers,partials,data) {
    return "						<li id=\"help_request_container\" class=\"nested\">\n							<button id=\"help_request\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-requested-text","header.helpRequested",{"name":"$$","hash":{},"data":data}))
    + ">\n								"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.helpRequest",{"name":"$","hash":{},"data":data}))
    + "\n							</button>\n						</li>\n";
},"9":function(depth0,helpers,partials,data) {
    return "				<li id=\"help_request_container\" class=\"top\">\n					<button id=\"help_request\"  "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-requested-text","header.helpRequested",{"name":"$$","hash":{},"data":data}))
    + ">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.helpRequest",{"name":"$","hash":{},"data":data}))
    + "\n					</button>\n				</li>\n";
},"11":function(depth0,helpers,partials,data) {
    return "				<li>\n					<button id=\"change-tenant\" class=\"hide\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.changeTenant",{"name":"$","hash":{},"data":data}))
    + "\n					</button>\n				</li>\n";
},"13":function(depth0,helpers,partials,data) {
    return " hide";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<header>\n\n	<h1><a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"page":"HOME"},"data":data}))
    + "\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","header.title",{"name":"$$","hash":{},"data":data}))
    + " class=\"home tenant-name\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.title",{"name":"$","hash":{},"data":data}))
    + "</a></h1>\n\n	<div class=\"header-right\">\n		<div id=\"welcome_name\" class=\"logged-in-visible\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.greeting",{"name":"$","hash":{},"data":data}))
    + " <span class=\"display-user-name\"></span></div>\n		<ul class=\"actions logged-in-visible\">\n			<li>\n				<button id=\"system_messages_button\" class=\"hide\" rel=\"popover\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","header.systemMessages",{"name":"$$","hash":{},"data":data}))
    + " data-trigger=\"manual\" data-placement=\"bottom\" data-container=\"body\" data-class=\"system-messages-popover\">\n					<span class=\"header-button-text\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.systemMessages",{"name":"$","hash":{},"data":data}))
    + " </span>\n					<span class=\"header-button-icon fa fa-lg fa-fw fa-comment hide\"></span>\n					(<span class=\"system-message-count\"></span>)\n				</button>\n			</li>\n			<li class=\"dropdown\">\n				<button id=\"help_button\" data-toggle=\"dropdown\">\n					<span class=\"caret\"></span>\n					<span class=\"header-button-text\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.help",{"name":"$","hash":{},"data":data}))
    + "</span>\n					<span class=\"header-button-icon fa fa-lg fa-fw fa-question hide\"></span>\n				</button>\n				<ul class=\"dropdown-menu help-options\">\n					<li class=\"live-chat\">\n						<button id=\"live_chat\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.liveChat",{"name":"$","hash":{},"data":data}))
    + "\n						</button>\n					</li>\n					<li>\n						<button id=\"show_support_options\" data-toggle=\"modal\" data-target=\"#modal_support_options\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.supportOptions",{"name":"$","hash":{},"data":data}))
    + "\n						</button>\n					</li>\n					<li>\n						<button id=\"show_welcome_tips\" data-toggle=\"modal\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.tips",{"name":"$","hash":{},"data":data}))
    + "\n						</button>\n					</li>\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.pageName : depth0),"instructor",{"name":"eq","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n					<li>\n						<button id=\"show_about\" data-toggle=\"modal\" data-target=\"#modal_about\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.about",{"name":"$","hash":{},"data":data}))
    + "\n						</button>\n					</li>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.stationId : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n					<li id=\"help_request_instructor_container\" class=\"nested\" style=\"display: none;\">\n						<button id=\"help_request_instructor\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-requested-text","header.helpRequested",{"name":"$$","hash":{},"data":data}))
    + ">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.helpRequest",{"name":"$","hash":{},"data":data}))
    + "\n						</button>\n					</li>\n				</ul>\n			</li>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.stationId : depth0),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n			<li id=\"help_request_instructor_container\" class=\"top\"  style=\"display: none;\">\n				<button id=\"help_request_instructor\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-requested-text","header.helpRequested",{"name":"$$","hash":{},"data":data}))
    + ">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.helpRequest",{"name":"$","hash":{},"data":data}))
    + "\n				</button>\n			</li>\n\n			<li class=\"dropdown\">\n				<button id=\"help_button\" data-toggle=\"dropdown\">\n					<span class=\"caret\"></span>\n					<span class=\"header-button-text\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.privacy",{"name":"$","hash":{},"data":data}))
    + "</span>\n					<span class=\"header-button-icon fa fa-lg fa-fw fa-lock hide\"></span>\n				</button>\n				<ul class=\"dropdown-menu help-options\">\n					<li>\n						<a id=\"privacy\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"href","header.privacyUrl",{"name":"$$","hash":{},"data":data}))
    + " target=\"_blank\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.privacyPolicy",{"name":"$","hash":{},"data":data}))
    + "\n						</a>\n					</li>\n					<li class=\"oneTrust-cookie-settings hide\">\n						<a class=\"ot-sdk-show-settings\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.cookieSettings",{"name":"$","hash":{},"data":data}))
    + "\n						</a>\n					</li>\n				</ul>\n			</li>\n\n			<li>\n				<button id=\"show_profile\">\n					<span class=\"header-button-text\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.profile",{"name":"$","hash":{},"data":data}))
    + "</span>\n					<span class=\"header-button-icon fa fa-lg fa-fw fa-user hide\"></span>\n				</button>\n			</li>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.allowTenantSwitching : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "			<li>\n				<button class=\"logout-button\" data-toggle=\"modal\" data-target=\"#modal_logout\">\n					<span class=\"header-button-text\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.logOut",{"name":"$","hash":{},"data":data}))
    + "</span>\n					<span class=\"header-button-icon fa fa-lg fa-fw fa-sign-out hide\"></span>\n				</button>\n			</li>\n			<li>\n				<button type=\"button\" id=\"show_language_chooser\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","header.language",{"name":"$$","hash":{},"data":data}))
    + " rel=\"tooltip\" data-toggle=\"modal\" data-placement=\"left\" data-class=\"inverse\">\n					<span class=\"header-button-text\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"languageName",{"name":"$","hash":{},"data":data}))
    + "</span>\n					<i class=\"fa fa-globe\"></i>\n				</button>\n			</li>\n		</ul>\n\n		<div id=\"registered_station\" class=\"station-label"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.stationId : depth0),{"name":"unless","hash":{},"fn":this.program(13, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n			<button type=\"button\"><span class=\"station-id\">"
    + this.escapeExpression(((helper = (helper = helpers.stationId || (depth0 != null ? depth0.stationId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"stationId","hash":{},"data":data}) : helper)))
    + "</span></button>\n		</div>\n\n		<ul class=\"actions login logged-out-visible\">\n			<li>\n				<button type=\"button\" class=\"login-button\" data-cancellable=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.login",{"name":"$","hash":{},"data":data}))
    + "</button>\n			</li>\n			<li class=\"dropdown\">\n				<button id=\"help_button\" data-toggle=\"dropdown\">\n					<span class=\"caret\"></span>\n					<span class=\"header-button-text\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.privacy",{"name":"$","hash":{},"data":data}))
    + "</span>\n					<span class=\"header-button-icon fa fa-lg fa-fw fa-lock hide\"></span>\n				</button>\n				<ul class=\"dropdown-menu help-options pull-right\">\n					<li>\n						<a id=\"privacy\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"href","header.privacyUrl",{"name":"$$","hash":{},"data":data}))
    + " target=\"_blank\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.privacyPolicy",{"name":"$","hash":{},"data":data}))
    + "\n						</a>\n					</li>\n					<li class=\"oneTrust-cookie-settings hide\">\n						<a class=\"ot-sdk-show-settings\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.cookieSettings",{"name":"$","hash":{},"data":data}))
    + "\n						</a>\n					</li>\n				</ul>\n			</li>\n			<li>\n				<button type=\"button\" id=\"show_language_chooser\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","header.language",{"name":"$$","hash":{},"data":data}))
    + " rel=\"tooltip\" data-toggle=\"modal\" data-placement=\"left\" data-class=\"inverse\">\n					<span class=\"header-button-text\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"languageName",{"name":"$","hash":{},"data":data}))
    + "</span>\n					<i class=\"fa fa-globe\"></i>\n				</button>\n			</li>\n		</ul>\n\n	</div>\n</header>\n";
},"useData":true});
Handlebars.registerPartial('layout_header', t);
return t;
});

