
define('hbs!tpls/catalogs/lab/class-details.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var helper;

  return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"class.description",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd>"
    + this.escapeExpression(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"description","hash":{},"data":data}) : helper)))
    + "</dd>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"class.instructors",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd>\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.instructors : depth0),{"name":"each","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "			</dd>\n";
},"4":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "					<span>"
    + this.escapeExpression(((helper = (helper = helpers.displayName || (depth0 != null ? depth0.displayName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"displayName","hash":{},"data":data}) : helper)))
    + "</span>"
    + ((stack1 = helpers.unless.call(depth0,(data && data.last),{"name":"unless","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"5":function(depth0,helpers,partials,data) {
    return ", ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"class-details\">\n	<dl class=\"dl-horizontal dl-wide\">\n		<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"class.name",{"name":"$","hash":{},"data":data}))
    + "</dt>\n		<dd>"
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</dd>\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n		<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"class.startDate",{"name":"$","hash":{},"data":data}))
    + "</dt>\n		<dd>"
    + this.escapeExpression((helpers.date_format || (depth0 && depth0.date_format) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.startDate : depth0),"dateFormatFull",{"name":"date_format","hash":{},"data":data}))
    + "</dd>\n\n		<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"class.endDate",{"name":"$","hash":{},"data":data}))
    + "</dt>\n		<dd>"
    + this.escapeExpression((helpers.date_format || (depth0 && depth0.date_format) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.endDate : depth0),"dateFormatFull",{"name":"date_format","hash":{},"data":data}))
    + "</dd>\n\n		<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"class.status",{"name":"$","hash":{},"data":data}))
    + "</dt>\n		<dd>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,(helpers.concat || (depth0 && depth0.concat) || helpers.helperMissing).call(depth0,"class.statuses.",(depth0 != null ? depth0.status : depth0),{"name":"concat","hash":{},"data":data}),{"name":"$","hash":{},"data":data}))
    + "\n		</dd>\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.instructors : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "	</dl>\n</div>";
},"useData":true});
Handlebars.registerPartial('catalogs_lab_class_details', t);
return t;
});

