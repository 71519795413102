/**
 * Panel Manager
 */
define('views/console/panel-manager',['require','jquery','underscore','backbone','views/console/panel'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var Panel     = require("views/console/panel");

	return Backbone.View.extend({
		attached : {
			left  : [],
			right : []
		},
		initialize : function(options){
			_.bindAll(this);

			options = options || {};
			this.panels = [];

			this.$containerLeft  = this.$(".dock-left");
			this.$containerRight = this.$(".dock-right");
			this.$containerFloat = this.$(".floating");
		},
		addPanel : function(selector){
			var panel = new Panel({
				el        : selector
			});

			panel.on("panel:focus",    this.setFocus);
			panel.on("panel:expand",   this.expandPanels);
			panel.on("panel:close",    this.closePanels);
			panel.on("panel:dock",     this.panelDocked);
			panel.on("panel:float",    this.panelFloated);
			panel.on("panel:hide",     this.panelHidden);
			panel.on("panel:show",     this.panelShown);

			panel.on("panel:resizing", this.resizePanels);
			panel.on("panel:resized",  this.updateContainers);

			this.panels.push(panel);


			if(!panel.state.floating){
				panel.trigger("panel:dock", panel);
			} else {
				panel.trigger("panel:float", panel);
			}

		},
		getPanel : function(selector){
			for(var i = 0; i < this.panels.length; i++){
				if(this.panels[i].$el.is(selector)){
					return this.panels[i];
				}
			}
			return null;
		},
		setFocus : function(panel){
			_.each(this.panels, function(p){
				if(p === panel){
					p.focus();
					return;
				}

				if (p.state.position == panel.state.position && p.state.focused) {
					p.unfocus();
				}

			}, this);
		},
		expandPanels : function(panel, options){
			options = _.defaults(options || {}, {immediate : false});

			_.each(this.panels, function(p){
				if((p === panel || p.state.position === panel.state.position) && !p.state.floating){
					p.expand({trigger : false});
				}

			}, this);

			this.updateContainers(options.immediate);
		},
		closePanels : function(panel){
			_.each(this.panels, function(p){
				if((p === panel || p.state.position === panel.state.position) && !p.state.floating){
					p.close({trigger : false});
				}
			}, this);
			this.updateContainers();
		},
		resizePanels : function(){
			this.updateContainers(true);
		},
		panelDocked : function(panel){

			this.appendPanelToContainer(panel);

			this.resetSizes();
			this.updateContainersMinWidth();

			for(var i = 0; i < this.panels.length; i++){
				var p = this.panels[i];
				if(p !== panel && !p.state.floating && p.state.position !== panel.state.position){
					this.setFocus(p);
				}
			}

			//this.updateContainers();
		},
		panelFloated: function(panel){
			this.$containerFloat.append(panel.$el);
			this.resetSizes();
			this.updateContainers();
		},
		panelHidden: function(panel){
			this.appendPanelToContainer(panel);
		},
		panelShown: function(panel){
			this.appendPanelToContainer(panel);
		},
		appendPanelToContainer: function(panel){
			var $container = (panel.state.position === "left" ? this.$containerLeft : this.$containerRight);

			if($container.has(panel.$el).length === 0 && !panel.state.hidden){
				$container.append(panel.$el);
			} else if($container.has(panel.$el).length > 0 && panel.state.hidden){
				this.$containerFloat.append(panel.$el);
			}
			var $panels = $container.children(".panel").sort(function(a, b) {
				var orderA = $(a).data("order");
				var orderB = $(b).data("order");
				return (orderA < orderB) ? -1 : (orderA > orderB) ? 1 : 0;
			});
			$container.append($panels);
			this.updateContainers();
		},
		updateContainers : function(immediate){
			if(typeof immediate === "object") { return; }

			immediate = immediate && !(immediate.preventDefault || immediate.el);
			immediate = !!immediate;
			this.updateContainersMinWidth();

			this.updateContainer("left", immediate);
			this.updateContainer("right", immediate);
		},
		updateContainer : function(position, immediate){

			var cssProps = ["left", "right", "width"];
			var $container = (position === "left" ? this.$containerLeft : this.$containerRight);

			var containerBefore  = $container.css(cssProps);
			containerBefore.expanded = $container.hasClass("expanded");

			var width = 180;
			var expanded = false;

			$("body").removeClass("has-left-panel left-panel-expanded has-right-panel right-panel-expanded");

			var panels = [];

			//set container size
			for(var i = 0; i < this.panels.length; i++){
				var panel = this.panels[i];
				if(panel.state.position === position && !panel.state.floating && !panel.state.hidden){
					panels.push(panel);
					if(panel.state.expanded && !panel.$el.hasClass("hide")){
						expanded = true;
					}

					if(panel.state.docked.width >= width){
						width = panel.state.docked.width;
					}
					width = Math.max(width, parseInt(panel.$el.css("min-width"), 10));
				}

				//Set body classes
				if(panel.state.position === "left" && !panel.state.floating && !panel.state.hidden){
					$("body").addClass("has-left-panel");
					if(panel.state.expanded){
						$("body").addClass("left-panel-expanded");
					}
				} else if(panel.state.position === "right" && !panel.state.floating && !panel.state.hidden){
					$("body").addClass("has-right-panel");
					if(panel.state.expanded){
						$("body").addClass("right-panel-expanded");
					}
				}
			}

			var beforeWidth = parseInt(containerBefore.width, 10);
			if(!containerBefore.expanded && expanded){
				containerBefore[position] = -width + "px";
				$container
					.addClass("immediate")
					.css(position, containerBefore[position])
					.width(width)
					.css([position, "width"]);
				$container.removeClass("immediate");
			} else if (expanded && width > beforeWidth) {
				$container
					.addClass("immediate")
					.css(position, -(width - beforeWidth))
					.width(width)
					.css([position]);
				$container.removeClass("immediate");
			}

			var newPosition = (expanded ? 0 : -width) + "px";

			var transitionTarget = $container.data("transitionTarget") || {};

			var containerAfter = $container.css(cssProps);
			containerAfter[position] = newPosition;
			containerAfter.width = width + "px";
			containerAfter.expanded = expanded;


			var changed = !_.isEqual(containerAfter, containerBefore) && !_.isEqual(transitionTarget, containerAfter);

			if(changed){
				$container
					.toggleClass("expanded", expanded)
					.toggleClass("immediate", immediate || (!containerBefore.expanded && !expanded))
					.width(width)
					.css(position, newPosition);

				$container.data("transitionTarget", _.clone(containerAfter));
				containerAfter.width = parseInt(containerAfter.width, 10);
				containerAfter.right = parseInt(containerAfter.right, 10);
				containerAfter.left  = parseInt(containerAfter.left, 10);
				containerAfter.immediate = immediate;
				this.trigger("panels:dock" + position, containerAfter);
			}
		},
		updateContainersMinWidth : function(){
			this.updateContainerMinWidth("left");
			this.updateContainerMinWidth("right");
		},
		updateContainerMinWidth : function(position){
			var i, p;
			var minWidth = 0;

			//Find minWidth
			for(i = 0; i < this.panels.length; i++){
				p = this.panels[i];

				if( p.state.position === position && !p.state.floating && !p.state.hidden){
					if(p.minWidth > minWidth){
						minWidth = p.minWidth;
					}
				}
			}

			//set minWidth size
			for(i = 0; i < this.panels.length; i++){
				p = this.panels[i];

				if((p.state.position === position) && !p.state.floating && !p.state.hidden){
					p.setDockedMinWidth(minWidth);
				} else{
					p.setDockedMinWidth(0);
				}
			}
		},
		getPanels : function(position){
			var result = [];
			_.each(this.panels, function(p){
				if((p.state.position === position && !p.state.floating) || !position){
					result.push(p);
				}
			});
			return result;
		},
		resetSizes : function(){
			_.each(["left", "right"], function(dir){
				var panels = this.getPanels(dir);
				if(panels.length <= 1){
					_.invoke(panels, "resetSize");
				}
			}, this);
		},
		clean : function(){
		}
	});

});

