/**
 * Miscellaneous project specific Handlebars helper functions.
 */
define('utilities/handlebar-helpers',['require','underscore','handlebars','library/vlp/app'],function (require) {
	"use strict";

	//library dependencies
	var _          = require("underscore"),
	    Handlebars = require("handlebars");

	//class dependencies
	var App = require("library/vlp/app");

	var precompiled = {};
	return (function() {
		/**
		 * Make an href for a page in this project
		 */
		Handlebars.registerHelper("appUrl", function() {
			var options = {};
			var urlOptions = {};

			if(arguments.length > 0){
				options = arguments[arguments.length - 1];
				urlOptions = options.hash;
			}
			if(arguments.length >= 2){
				urlOptions.page = arguments[0];
			}
			if(arguments.length >= 3){
				urlOptions.action = arguments[1];
			}
			if(arguments.length >= 4){
				urlOptions.tenant = arguments[2];
			}

			for(var option in urlOptions){

				if(urlOptions.hasOwnProperty(option)){
					var temp = urlOptions[option];
					if(_.isString(temp) && temp.match(/\{\{.+\}\}/)){
						var template;
						if(precompiled[temp]){
							template = precompiled[temp];
						} else{
							template = Handlebars.compile(temp);
						}
						urlOptions[option] = template(this);
						precompiled[temp] = template;
					}
				}
			}
			return App.makeUrl(urlOptions);
		});
	}).call(this);

});
