
define('hbs!tpls/console/manual-toc-item.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var helper;

  return " class=\"twisty "
    + this.escapeExpression(((helper = (helper = helpers.leaf || (depth0 != null ? depth0.leaf : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"leaf","hash":{},"data":data}) : helper)))
    + "\"";
},"3":function(depth0,helpers,partials,data) {
    return " leaf";
},"5":function(depth0,helpers,partials,data) {
    return "		<ul class=\"toc-children hide\"></ul>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<li>\n	<h"
    + this.escapeExpression((helpers.defaultValue || (depth0 && depth0.defaultValue) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.level : depth0),2,{"name":"defaultValue","hash":{},"data":data}))
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.leaf : depth0),{"name":"unless","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n		<a class=\"manual-toc-item"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.leaf : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-step-id=\""
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-step-number=\""
    + this.escapeExpression(((helper = (helper = helpers.stepno || (depth0 != null ? depth0.stepno : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"stepno","hash":{},"data":data}) : helper)))
    + "\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.manual.toc.gotoStep",(depth0 != null ? depth0.stepno : depth0),{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper)))
    + "</a>\n	</h"
    + this.escapeExpression((helpers.defaultValue || (depth0 && depth0.defaultValue) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.level : depth0),2,{"name":"defaultValue","hash":{},"data":data}))
    + ">\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.leaf : depth0),{"name":"unless","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</li>";
},"useData":true});
Handlebars.registerPartial('console_manual_toc_item', t);
return t;
});

