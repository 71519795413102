
define('hbs!tpls/console/vm-console.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "				<button type=\"button\" class=\"btn-no-style toggle-panel-left maximize-compact-panel-button\">\n					<span class=\"fa fa-angle-double-right\"></span>\n				</button>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<button type=\"button\" class=\"btn-no-style show-send-text-modal\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.sendText.toolTip",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-target=\"#modal_send_text\" data-toggle=\"modal\">\n					<span class=\"fa fa-fw fa-share-square-o\"></span>\n					<span class=\"btn-label\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.sendText.label",{"name":"$","hash":{},"data":data}))
    + "</span>\n				</button>\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"expandedConsoleShortcuts",{"name":"config","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.showNotes : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n				<button type=\"button\" class=\"btn-no-style power-down first\" rel=\"popover\" data-trigger=\"click\" data-placement=\"bottom\" data-html=\"true\" data-content-target=\"#popover_content_power_down\" data-class=\"popover-power power-down\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","console.vm.buttons.poweringDown",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","console.vm.powerDown.title",{"name":"$$","hash":{},"data":data}))
    + ">\n					<span rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.powerDownTooltip",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\">\n						<span class=\"fa fa-fw fa-power-off\"></span>\n						<span class=\"btn-label\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.buttons.powerDown",{"name":"$","hash":{},"data":data}))
    + "</span>\n					</span>\n				</button>\n				<button type=\"button\" class=\"btn-no-style power-on\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","console.vm.buttons.poweringOn",{"name":"$$","hash":{},"data":data}))
    + ">\n					<span rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.powerOnTooltip",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\">\n						<span class=\"fa fa-fw fa-power-off\"></span>\n						<span class=\"btn-label\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.buttons.powerOn",{"name":"$","hash":{},"data":data}))
    + "</span>\n					</span>\n				</button>\n				<button type=\"button\" class=\"btn-no-style power-reset\" rel=\"popover\" data-trigger=\"click\" data-placement=\"bottom\" data-html=\"true\" data-content-target=\"#popover_content_power_reset\" data-class=\"popover-power power-down\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","console.vm.buttons.poweringDown",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","console.vm.powerReset.title",{"name":"$$","hash":{},"data":data}))
    + ">\n					<span rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.resetTooltip",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\">\n						<span class=\"fa fa-fw fa-refresh\"></span>\n						<span class=\"btn-label\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.buttons.reset",{"name":"$","hash":{},"data":data}))
    + "</span>\n					</span>\n				</button>\n";
},"4":function(depth0,helpers,partials,data) {
    return "					<button type=\"button\" class=\"btn-no-style shortcut-keys\" rel=\"popover\" data-trigger=\"click\" data-placement=\"bottom\" data-html=\"true\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","console.vm.shortcutKeys.title",{"name":"$$","hash":{},"data":data}))
    + " data-content-target=\"#popover_content_shortcut_keys\" data-class=\"popover-vm-shortcut-keys\">\n						<span rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.shortcutKeysTooltip",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\">\n							<span class=\"fa fa-fw fa-keyboard-o\"></span>\n							<span class=\"btn-label\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.buttons.shortcutKeys",{"name":"$","hash":{},"data":data}))
    + "</span>\n						</span>\n					</button>\n";
},"6":function(depth0,helpers,partials,data) {
    return "					<button type=\"button\" class=\"btn-no-style control-alt-del\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.controlAltDeleteTooltip",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\">\n						<span class=\"fa fa-fw fa-keyboard-o\"></span>\n						<span class=\"btn-label\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.buttons.controlAltDelete",{"name":"$","hash":{},"data":data}))
    + "</span>\n					</button>\n";
},"8":function(depth0,helpers,partials,data) {
    var helper;

  return "					<button type=\"button\" class=\"btn-no-style note\" rel=\"popover\" data-trigger=\"click\" data-placement=\"bottom\" data-html=\"true\" data-title=\""
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" data-content-target=\"#popover_content_vm_notes\" data-class=\"popover-vm-password\">\n						<span rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.notesTooltip",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\">\n							<span class=\"fa fa-fw fa-comment-o\"></span>\n							<span class=\"btn-label\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.buttons.notes",{"name":"$","hash":{},"data":data}))
    + "</span>\n						</span>\n					</button>\n";
},"10":function(depth0,helpers,partials,data) {
    return "				<button type=\"button\" class=\"btn-no-style connection-strength level-0 maximized-inline\" rel=\"popover\" data-trigger=\"click\" data-placement=\"bottom\" data-html=\"true\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","console.vm.latency.title",{"name":"$$","hash":{},"data":data}))
    + " data-content-target=\"#popover_content_connection_stats\" data-class=\"connection-stats-popover\">\n					<span class=\"indicator\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.connectionStrength",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\">\n						<span class=\"bar bar-1\"></span>\n						<span class=\"bar bar-2\"></span>\n						<span class=\"bar bar-3\"></span>\n						<span class=\"bar bar-4\"></span>\n						<span class=\"bar bar-5\"></span>\n					</span>\n				</button>\n";
},"12":function(depth0,helpers,partials,data) {
    return "				<button type=\"button\" class=\"btn-no-style full-screen maximized-inline\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.fullScreen",{"name":"$$","hash":{},"data":data}))
    + " data-container=\"body\" data-placement=\"bottom\">\n					<span class=\"fa fa-fw fa-desktop\"></span>\n				</button>\n";
},"14":function(depth0,helpers,partials,data) {
    return "				<button type=\"button\" class=\"btn-no-style toggle-panel-right maximize-compact-panel-button\">\n					<span class=\"fa fa-angle-double-left\"></span>\n				</button>\n";
},"16":function(depth0,helpers,partials,data) {
    return "						<button type=\"button\" class=\"connection-strength level-0\" rel=\"popover\" data-trigger=\"click\" data-placement=\"bottom\" data-html=\"true\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","console.vm.latency.title",{"name":"$$","hash":{},"data":data}))
    + " data-content-target=\"#popover_content_connection_stats\" data-class=\"connection-stats-popover\">\n							<span class=\"indicator\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.connectionStrength",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"left\">\n								<span class=\"bar bar-1\"></span>\n								<span class=\"bar bar-2\"></span>\n								<span class=\"bar bar-3\"></span>\n								<span class=\"bar bar-4\"></span>\n								<span class=\"bar bar-5\"></span>\n							</span>\n						</button>\n";
},"18":function(depth0,helpers,partials,data) {
    var stack1;

  return "						<button\n							type=\"button\"\n							class=\"vm-full-screen\"\n							rel=\"tooltip\"\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"maximizeOnFullscreen",{"name":"config","hash":{},"fn":this.program(19, data, 0),"inverse":this.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + "							data-placement=\"left\">\n							<span class=\"fa fa-fw fa-desktop\"></span>\n						</button>\n";
},"19":function(depth0,helpers,partials,data) {
    return "								"
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.fullScreenAndMaximize",{"name":"$$","hash":{},"data":data}))
    + "\n";
},"21":function(depth0,helpers,partials,data) {
    return "								"
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.fullScreen",{"name":"$$","hash":{},"data":data}))
    + "\n";
},"23":function(depth0,helpers,partials,data) {
    var helper;

  return "						<button class=\"quick-send-text\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.sendText.username",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"top\" data-trigger=\"hover\" data-html=\"true\" data-text=\""
    + this.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"username","hash":{},"data":data}) : helper)))
    + "\">\n							<span class=\"fa fa-share-square-o\"></span>\n						</button>\n						<span class=\"value\">"
    + this.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"username","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"25":function(depth0,helpers,partials,data) {
    return "						<em>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.empty",{"name":"$","hash":{},"data":data}))
    + "</em>\n";
},"27":function(depth0,helpers,partials,data) {
    var helper;

  return "						<button class=\"quick-send-text\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.sendText.password",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"top\" data-trigger=\"hover\" data-html=\"true\" data-text=\""
    + this.escapeExpression(((helper = (helper = helpers.password || (depth0 != null ? depth0.password : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"password","hash":{},"data":data}) : helper)))
    + "\">\n							<span class=\"fa fa-share-square-o\"></span>\n						</button>\n						<span class=\"value\">"
    + this.escapeExpression(((helper = (helper = helpers.password || (depth0 != null ? depth0.password : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"password","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"29":function(depth0,helpers,partials,data) {
    var helper;

  return "						<button class=\"quick-send-text\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.sendText.networkName",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"top\" data-trigger=\"hover\" data-html=\"true\" data-text=\""
    + this.escapeExpression(((helper = (helper = helpers.networkName || (depth0 != null ? depth0.networkName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"networkName","hash":{},"data":data}) : helper)))
    + "\">\n							<span class=\"fa fa-share-square-o\"></span>\n						</button>\n						<span class=\"value\">"
    + this.escapeExpression(((helper = (helper = helpers.networkName || (depth0 != null ? depth0.networkName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"networkName","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"31":function(depth0,helpers,partials,data) {
    return "							<em>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.empty",{"name":"$","hash":{},"data":data}))
    + "</em>\n";
},"33":function(depth0,helpers,partials,data) {
    var helper;

  return "						<button class=\"quick-send-text\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.sendText.networkIp",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"top\" data-trigger=\"hover\" data-html=\"true\" data-text=\""
    + this.escapeExpression(((helper = (helper = helpers.networkIp || (depth0 != null ? depth0.networkIp : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"networkIp","hash":{},"data":data}) : helper)))
    + "\">\n							<span class=\"fa fa-share-square-o\"></span>\n						</button>\n						<span class=\"value\">"
    + this.escapeExpression(((helper = (helper = helpers.networkIp || (depth0 != null ? depth0.networkIp : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"networkIp","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"35":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "					"
    + ((stack1 = ((helper = (helper = helpers.notes || (depth0 != null ? depth0.notes : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"notes","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n";
},"37":function(depth0,helpers,partials,data) {
    return "					<em>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.noNotes",{"name":"$","hash":{},"data":data}))
    + "</em>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div id=\"vm_group\">\n\n	<div id=\"vm_above_bar\">\n\n		<div class=\"buttons\">\n\n"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"maximizeCompactPanelTabs",{"name":"config","hash":{},"data":data}),(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"compactPanelTabs",{"name":"config","hash":{},"data":data}),{"name":"or","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"showConsoleButtons",{"name":"config","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n		<div class=\"buttons maximized-block\">\n"
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.entitlement : depth0)) != null ? stack1.showConsoleConnectionStrength : stack1),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.allowFullscreen : depth0),{"name":"if","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "			<button type=\"button\" class=\"btn-no-style maximize maximized-inline\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.exitMaximize",{"name":"$$","hash":{},"data":data}))
    + " data-container=\"body\" data-placement=\"left\">\n				<span class=\"fa fa-fw fa-times\"></span>\n			</button>\n\n"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"maximizeCompactPanelTabs",{"name":"config","hash":{},"data":data}),(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"compactPanelTabs",{"name":"config","hash":{},"data":data}),{"name":"or","hash":{},"fn":this.program(14, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n\n	<div class=\"vm-center\">\n		<div class=\"vm-icon-frame powered-down\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.poweredDownTooltip",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.poweredDown",{"name":"$","hash":{},"data":data}))
    + "</div>\n		<div class=\"vm-loading vm-icon-frame "
    + this.escapeExpression(((helper = (helper = helpers.osType || (depth0 != null ? depth0.osType : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"osType","hash":{},"data":data}) : helper)))
    + "\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n		<div class=\"vm-unavailable vm-icon-frame\">\n			<div>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.unavailable",{"name":"$","hash":{},"data":data}))
    + "</div>\n			<button type=\"button\" class=\"btn screen-retry\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","console.vm.buttons.retrying",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.buttons.screenRetry",{"name":"$","hash":{},"data":data}))
    + "</button>\n		</div>\n		<div class=\"vm-cannot-connect vm-icon-frame\">\n			<div>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.cannotConnect",{"name":"$","hash":{},"data":data}))
    + "</div>\n			<button type=\"button\" class=\"btn page-refresh\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.buttons.refresh",{"name":"$","hash":{},"data":data}))
    + "</button>\n		</div>\n\n		<div id=\"vm_console_wrapper\" class=\"docked\">\n			<div id=\"vm_console_wrapper_inner\">\n				<div class=\"connection-lost\">\n					<span>\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.connectionLost",{"name":"$","hash":{},"data":data}))
    + "\n					</span>\n				</div>\n				<div class=\"screen-blanked\"><span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"class.instructorBlankedScreen",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n				<div class=\"screen-taken-over\"><span class=\"animated infinite flash slower\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"class.takenOver",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n\n				<div class=\"sending-commands-message\">\n					<span class=\"running-message\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.sendToConsole.running.message",{"name":"$","hash":{},"data":data}))
    + "</span>\n					<span class=\"user-input-disabled\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.sendToConsole.running.userInputDisabled",{"name":"$","hash":{},"data":data}))
    + "</span>\n					<span class=\"cancel-message\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.sendToConsole.running.cancelMessage",{"name":"$","hash":{},"data":data}))
    + "</span>\n					<span class=\"command-progress\"></span>\n				</div>\n\n				<div class=\"controls\">\n"
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.entitlement : depth0)) != null ? stack1.showConsoleConnectionStrength : stack1),{"name":"if","hash":{},"fn":this.program(16, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.allowFullscreen : depth0),{"name":"if","hash":{},"fn":this.program(18, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "					<button type=\"button\" class=\"vm-maximize\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-maximize-text","console.vm.buttons.exitMaximize",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.maximize",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"left\">\n						<span class=\"fa fa-fw fa-arrows-alt\"></span>\n					</button>\n					<button type=\"button\" class=\"vm-dock-left\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockLeft",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"left\">\n						<span class=\"fa fa-fw fa-caret-left\"></span>\n					</button>\n					<button type=\"button\" class=\"vm-dock-right\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockRight",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"left\">\n						<span class=\"fa fa-fw fa-caret-right\"></span>\n					</button>\n					<button type=\"button\" class=\"vm-dock-center\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockCenter",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"left\">\n						<span class=\"fa fa-fw fa-expand\"></span>\n					</button>\n					<button type=\"button\" class=\"vm-float\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.toggleFloat",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"left\">\n						<span class=\"fa fa-fw fa-external-link\"></span>\n					</button>\n					<button type=\"button\" class=\"vm-refresh\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.refresh",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"left\">\n						<span class=\"fa fa-fw fa-rotate-left\"></span>\n					</button>\n				</div>\n				<div id=\"vm_console\"></div>\n			</div>\n		</div>\n	</div>\n\n\n	<div id=\"browser_fullscreen_message\" class=\"hide alert alert-info alert-icon\">\n		<a class=\"close\" data-dismiss=\"alert\">&times;</a>\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.fullScreenMessage",{"name":"$","hash":{},"data":data}))
    + "\n	</div>\n	<div id=\"browser_fullscreen_warning\" class=\"hide alert alert-warning alert-icon\">\n		<a class=\"close\" data-dismiss=\"alert\">&times;</a>\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"warnings.safariFullscreen",{"name":"$","hash":{},"data":data}))
    + "\n	</div>\n</div>\n\n<div class=\"hide\">\n	<div id=\"popover_content_power_down\">\n		<p>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.powerDown.message",{"name":"$","hash":{},"data":data}))
    + "</p>\n		<div class=\"button-container\">\n			<button type=\"button\" class=\"btn btn-primary vm-power-down\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","saving",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.powerDown.confirm",{"name":"$","hash":{},"data":data}))
    + "</button>\n		</div>\n	</div>\n\n	<div id=\"popover_content_power_reset\">\n		<p>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.powerReset.message",{"name":"$","hash":{},"data":data}))
    + "</p>\n		<div class=\"button-container\">\n			<button type=\"button\" class=\"btn btn-primary vm-power-reset\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","saving",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.powerReset.confirm",{"name":"$","hash":{},"data":data}))
    + "</button>\n		</div>\n	</div>\n\n	<div id=\"popover_content_vm_notes\">\n		<div class=\"user-data\">\n			<ul>\n				<li>\n					<label>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.userName",{"name":"$","hash":{},"data":data}))
    + "</label>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.username : depth0),{"name":"if","hash":{},"fn":this.program(23, data, 0),"inverse":this.program(25, data, 0),"data":data})) != null ? stack1 : "")
    + "				</li>\n				<li>\n					<label>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.password",{"name":"$","hash":{},"data":data}))
    + "</label>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.username : depth0),{"name":"if","hash":{},"fn":this.program(27, data, 0),"inverse":this.program(25, data, 0),"data":data})) != null ? stack1 : "")
    + "				</li>\n				<li>\n					<label>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.networkName",{"name":"$","hash":{},"data":data}))
    + "</label>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.networkName : depth0),{"name":"if","hash":{},"fn":this.program(29, data, 0),"inverse":this.program(31, data, 0),"data":data})) != null ? stack1 : "")
    + "				</li>\n				<li>\n					<label>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.networkIp",{"name":"$","hash":{},"data":data}))
    + "</label>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.networkIp : depth0),{"name":"if","hash":{},"fn":this.program(33, data, 0),"inverse":this.program(31, data, 0),"data":data})) != null ? stack1 : "")
    + "				</li>\n			</ul>\n			<p>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.notes : depth0),{"name":"if","hash":{},"fn":this.program(35, data, 0),"inverse":this.program(37, data, 0),"data":data})) != null ? stack1 : "")
    + "			</p>\n		</div>\n	</div>\n\n"
    + ((stack1 = this.invokePartial(partials.console_vm_shortcut_keys_popup,depth0,{"name":"console_vm_shortcut_keys_popup","data":data,"indent":"\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "\n	<div id=\"popover_content_connection_stats\">\n		<div class=\"hide latency-unstable alert alert-warning alert-small alert-icon\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.unstable",{"name":"$","hash":{},"data":data}))
    + "</div>\n		<div class=\"hide latency-connection-lost alert alert-error alert-small alert-icon\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.lost",{"name":"$","hash":{},"data":data}))
    + "</div>\n		<ul>\n			<li>\n				<span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.current",{"name":"$","hash":{},"data":data}))
    + ":</span>\n				<span class=\"value\"><span class=\"latency-current\">0</span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.milliseconds",{"name":"$","hash":{},"data":data}))
    + "</span>\n			</li>\n			<li>\n				<span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.average",{"name":"$","hash":{},"data":data}))
    + ":</span>\n				<span class=\"value\"><span class=\"latency-average\">0</span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.milliseconds",{"name":"$","hash":{},"data":data}))
    + "</span>\n			</li>\n			<li>\n				<span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.high",{"name":"$","hash":{},"data":data}))
    + ":</span>\n				<span class=\"value\"><span class=\"latency-high\">0</span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.milliseconds",{"name":"$","hash":{},"data":data}))
    + "</span>\n			</li>\n			<li>\n				<span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.low",{"name":"$","hash":{},"data":data}))
    + ":</span>\n				<span class=\"value\"><span class=\"latency-low\">0</span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.milliseconds",{"name":"$","hash":{},"data":data}))
    + "</span>\n			</li>\n		</ul>\n	</div>\n</div>\n\n\n<div id=\"console_commands\"></div>\n<div id=\"vm_settings_prompts\"></div>\n<div id=\"paste_catcher\" contenteditable=\"true\"></div>\n<div id=\"console_drag_containment\"></div>\n";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('console_vm_console', t);
return t;
});

