
define('hbs!tpls/instructor/raise-hand.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var helper;

  return "<div class=\"message\">\n	<div class=\"image\">\n		<a data-username=\""
    + this.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"username","hash":{},"data":data}) : helper)))
    + "\" class=\"public-profile\" data-id=\""
    + this.escapeExpression(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"userId","hash":{},"data":data}) : helper)))
    + "\"\n		   data-name=\""
    + this.escapeExpression(((helper = (helper = helpers.displayName || (depth0 != null ? depth0.displayName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"displayName","hash":{},"data":data}) : helper)))
    + "\">\n			<img class=\"student-info-images\" src=\""
    + this.escapeExpression(((helper = (helper = helpers.avatar || (depth0 != null ? depth0.avatar : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"avatar","hash":{},"data":data}) : helper)))
    + "\">\n		</a>\n	</div>\n\n	<div class=\"data\">\n		<a data-username=\""
    + this.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"username","hash":{},"data":data}) : helper)))
    + "\" class=\"student-info-student-name public-profile\"\n		   data-id=\""
    + this.escapeExpression(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"userId","hash":{},"data":data}) : helper)))
    + "\"\n		   data-name=\""
    + this.escapeExpression(((helper = (helper = helpers.displayName || (depth0 != null ? depth0.displayName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"displayName","hash":{},"data":data}) : helper)))
    + "\">"
    + this.escapeExpression(((helper = (helper = helpers.displayName || (depth0 != null ? depth0.displayName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"displayName","hash":{},"data":data}) : helper)))
    + "</a>\n\n		<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"{{classId}}/console/{{entitlementId}}"},"data":data}))
    + "\" id=\"btn-open-console\"\n		   class=\"open-console btn btn-no-style\">\n			<i class=\"fa fa-desktop vlp-tooltip\">\n				<span class=\"tooltiptext\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.entitlements.openConsole",{"name":"$","hash":{},"data":data}))
    + "</span>\n			</i>\n		</a>\n		<a class=\"mark-as-resolved\">\n			<i class=\"fa fa-check vlp-tooltip\">\n				<span class=\"tooltiptext\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.entitlements.markAsResolved",{"name":"$","hash":{},"data":data}))
    + "</span>\n			</i>\n		</a>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('instructor_raise_hand', t);
return t;
});

