
define('hbs!tpls/layout/layout-empty.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"wrapper\">"
    + ((stack1 = this.invokePartial(partials.main,depth0,{"name":"main","data":data,"helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('layout_layout_empty', t);
return t;
});

