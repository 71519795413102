define('utilities/analytics/omniture',['require','underscore','utilities/analytics/base'],function (require) {
	"use strict";

	var _ = require("underscore");

	var BaseClass = require("utilities/analytics/base");

	return BaseClass.extend({
		initialize : function(info){
			BaseClass.prototype.initialize.apply(this, arguments);
			if(!this.enable){ return; }

			info.fields = _.defaults(info.fields || {}, {
				mode : "production"
			});

			if(info.fields){
				for(var field in info.fields){
					if(info.fields.hasOwnProperty(field)){
						this.set(field, info.fields[field]);
					}
				}
			}
		},
		set : function(name, value){

		},
		_doTrackEvent : function(category, action, label, value, nonInteraction){

		},
		_doTiming  : function(category, variable, value, label){

		}

	});

});
