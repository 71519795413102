define('views/catalogs/enrollment-listing',['require','jquery','underscore','library/vlp/app','library/vlp/view','models/entitlement','views/catalogs/enrollment-lab','hbs!tpls/catalogs/enrollment-listing.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App                   = require("library/vlp/app"),
	    BaseView              = require("library/vlp/view"),
	    Entitlement           = require("models/entitlement"),
	    EntitlementLabView    = require("views/catalogs/enrollment-lab"),
	    //EntitlementCourseView = require("views/catalogs/enrollment-course"),
	    enrollmentListingTpl  = require("hbs!tpls/catalogs/enrollment-listing.handlebars");


	return BaseView.extend({
		template : enrollmentListingTpl,
		removeKeepEl : true,

		activeItemId : null,
		children : {},

		pageNum    : 1,
		scrollTop  : 0,

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .fetch-more .btn"   : "onLoadMore"
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			options = options || {};
			options.fetchDefaults = options.fetchDefaults || {};

			this.fetchDefaults = _.extend({
				params  : {"with" : ["lab", "class", "completionInfo"]},
				perPage : App.config.itemsPerPage,
				loginCancelable : true
			}, options.fetchDefaults);

			this.collection = new Entitlement.Collection();

			this.listenTo(this.collection, "request", this.onFetching);
			this.listenTo(this.collection, "sync",    this.onFetched);
			this.listenTo(this.collection, "add",     this.onItemAdded);
			this.listenTo(this.collection, "remove",  this.onItemRemoved);
			this.listenTo(this.collection, "reset",   this.onReset);
			this.listenTo(this.collection, "error",   this.fetchError);

			if(App.config.catalogInfiniteScrolling){
				$(window).on("scroll", this.onScroll);
			}

		},
		serialize : function(){
			var data = this.collection.toHandlebars();
			data.loggedIn = App.user.get("loggedIn");
			return data;
		},
		beforeRender : function(){
			this.$("[rel=tooltip]").tooltip("hide");
			_.each(this.children, function(view){
				view.$el.detach();
			});
		},
		afterRender : function(){

			var self = this;
			this.$itemHolder = this.$(".items-holder");
			if(this.$itemHolder.length && this.collection.length){
				this.collection.each(function(model){
					var itemView = self.children[model.get("id")];

					if(!itemView){
						itemView = new EntitlementLabView({
							model        : model,
							className    : "lab-item"
						});
						self.children[model.get("id")] = itemView;
					}

					self.$itemHolder.append(itemView.el);
					itemView.setElement(itemView.el);
					//This is hacky, but otherwise dom events seem to get detached.
					if(!itemView.rendered){
						itemView.render();
					} else{
						itemView.afterRender();
					}
				});

				if(this.activeItemId){
					this.$(".lab-box[data-lab-id=\"" + this.activeItemId + "\"]").addClass("active");
					_.defer(function(){
						if(self.$(".lab-box[data-lab-id=\"" + self.activeItemId + "\"]").length){
							$("html, body").scrollTop(self.$(".lab-box[data-lab-id=\"" + self.activeItemId + "\"]").offset().top - 70);
						}
					});
				}
			}
			$("html, body").scrollTop(this.scrollTop);
			this.$("[rel=tooltip]").tooltip();
		},

		onReset : function(){
			this.scrollTop = 0;
			this.removeChildren();
			this.$(".items-holder").empty();
		},
		onFetching : function(){
			if(App.config.catalogInfiniteScrolling){
				if(!this.rendered){
					this.render();
				}
				this.$(".fetch-more .btn").button("loading");
			} else{
				this.render();
			}
			App.analytics.trackEvent("Enrollment", "View", null, this.collection.page || 1);
		},
		onFetched : function(){
			this.render();
			this.onScroll();
			this.$(".fetch-more-trigger").button("reset");
		},
		onItemAdded : function(item){

			var itemView = new EntitlementLabView({
				model     : item,
				className : "lab-item"
			});
			this.children[item.get("id")] = itemView;

			this.$(".items-holder").append(itemView.render().el);
		},
		onItemRemoved : function(item){
			delete this.children[item.get("id")];
		},
		onLoadMore : function(event){
			if(this.collection.length <= this.collection.totalCount && !this.collection.loading){
				this.fetch({add : true, remove : false});
				this.$(".fetch-more .btn").button("loading");
			}
		},
		onScroll : function(event){
			var $trigger = this.$(".fetch-more-trigger");
			if($trigger.length === 0) { return; }

			this.scrollTop = $(window).scrollTop();
			if(this.scrollTop > ($trigger.offset().top + $trigger.outerHeight())- $(window).height()) {
				this.onLoadMore();
			}
		},
		show : function(pageNum, activeItemId){
			if(activeItemId){
				this.activeItemId = activeItemId;
			}
			if(App.config.catalogInfiniteScrolling || !pageNum){
				this.pageNum = 1;
			} else {
				this.pageNum = pageNum;
			}

			if(!this.collection.loading){
				this.collection.reset();
				this.fetchPage(this.pageNum);
			} else{
				this.render();
			}

			this.scrollTop = 0;
			$("html, body").scrollTop(0);
		},

		fetchError : function(collection, jqXHR){
			if(jqXHR.statusText === "loginCanceled"){
				this.render();
			} else{
				return false;
			}

		},
		remove : function(){
			$(window).off("scroll", this.onScroll);
			this.removeChildren();
			BaseView.prototype.remove.call(this);
		},
		removeChildren : function(){
			_.each(this.children, function(view){
				view.remove();
			});
			this.children = {};
		}
	});

});

