
define('hbs!tpls/screen-saver/default-content.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    return "<div class=\"carousel-inner \">\n	<div class=\"item promo default\">\n		<div class=\"content\">\n			<div class=\"alert alert-info\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"screenSaver.loginPrompt",{"name":"$","hash":{},"data":data}))
    + "</div>\n		</div>\n	</div>\n</div>";
},"useData":true});
Handlebars.registerPartial('screen_saver_default_content', t);
return t;
});

