
define('hbs!tpls/compatibility/tests/bandwidth.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return " disabled";
},"3":function(depth0,helpers,partials,data) {
    return "<span class=\"disabled\">("
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"compatibility.disabled",{"name":"$","hash":{},"data":data}))
    + ")</span>";
},"5":function(depth0,helpers,partials,data) {
    return "in";
},"7":function(depth0,helpers,partials,data) {
    return "				<div id=\"tests_holder\"></div>\n";
},"9":function(depth0,helpers,partials,data) {
    return "				<div class=\"alert alert-success\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"compatibility.tests.bandwidth.success",{"name":"$","hash":{},"data":data}))
    + "</div>\n";
},"11":function(depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.webWorkers : stack1),{"name":"if","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.cloud : stack1),{"name":"if","hash":{},"fn":this.program(14, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.warnings : depth0)) != null ? stack1.connection : stack1),{"name":"if","hash":{},"fn":this.program(16, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"12":function(depth0,helpers,partials,data) {
    return "					<div class=\"alert alert-error\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"compatibility.tests.bandwidth.errorWebWorkers",{"name":"$","hash":{},"data":data}))
    + "\n					</div>\n";
},"14":function(depth0,helpers,partials,data) {
    return "					<div class=\"alert alert-error\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"compatibility.tests.bandwidth.errorCloudConnection",{"name":"$","hash":{},"data":data}))
    + "\n					</div>\n";
},"16":function(depth0,helpers,partials,data) {
    var stack1;

  return "					<div class=\"alert alert-warning\">\n						<div>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"compatibility.tests.bandwidth.failure",{"name":"$","hash":{},"data":data}))
    + "</div>\n\n"
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.warnings : depth0)) != null ? stack1.cloud : stack1),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.warnings : depth0)) != null ? stack1.ui : stack1),{"name":"if","hash":{},"fn":this.program(19, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "					</div>\n";
},"17":function(depth0,helpers,partials,data) {
    return "							<div>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"compatibility.tests.bandwidth.warningCloudConnection",{"name":"$","hash":{},"data":data}))
    + "</div>\n";
},"19":function(depth0,helpers,partials,data) {
    return "							<div>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"compatibility.tests.bandwidth.warningUIConnection",{"name":"$","hash":{},"data":data}))
    + "</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"accordion-group "
    + this.escapeExpression(((helper = (helper = helpers.status || (depth0 != null ? depth0.status : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"status","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.enabled : depth0),{"name":"unless","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n	<h3 class=\"accordion-heading\">\n		<a class=\"accordion-toggle collapsed\" data-toggle=\"collapse\" data-target=\"#test_"
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n"
    + ((stack1 = this.invokePartial(partials.compatibility_status_indicator,depth0,{"name":"compatibility_status_indicator","data":data,"indent":"\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"compatibility.tests.bandwidth.title",{"name":"$","hash":{},"data":data}))
    + "\n			"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.enabled : depth0),{"name":"unless","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n		</a>\n	</h3>\n	<div id=\"test_"
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" class=\"accordion-body collapse "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"failure",{"name":"eq","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"warning",{"name":"eq","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"running",{"name":"eq","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.expanded : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n		<div class=\"accordion-body-content-container\">\n			<p>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"speedTest.description",{"name":"$","hash":{},"data":data}))
    + "</p>\n\n"
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.webWorkers : stack1),{"name":"unless","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"success",{"name":"eq","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"success",{"name":"ne","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n</div>\n";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('compatibility_tests_bandwidth', t);
return t;
});

