/**
 * Vm Backbone View
 */
define('views/support/vm-console',['require','underscore','views/instructor/console/vm-console','views/console/vm-console','hbs!tpls/instructor/console/vm-console.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var _   = require("underscore");

	//class dependencies
	var InstructorVmView   = require("views/instructor/console/vm-console"),
		BaseVmView         = require("views/console/vm-console"),
		vmTPL              = require("hbs!tpls/instructor/console/vm-console.handlebars");

	var studentMethodsToRestore = [
		"onScreenStatusChanged", "wmksSetupEvents",
		"sendControlAltDelete", "powerOn", "powerReset", "sendText", "onQuickSendText",
		"onDragEnter", "onDragLeave", "onDragOver", "onDropText", "sendShortcutKeys"
	];
	return InstructorVmView.extend({
		template : vmTPL,
		events : {

		},
		initialize : function(){
			InstructorVmView.prototype.initialize.apply(this, arguments);
			this.events = _.defaults(this.events, InstructorVmView.prototype.events);
			for(var i = 0; i < studentMethodsToRestore.length; i++){
				this[studentMethodsToRestore[i]] = _.bind(BaseVmView.prototype[studentMethodsToRestore[i]], this);
			}
		}
	});

});

