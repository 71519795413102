
define('hbs!tpls/common/public-profile.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-user\"></span> "
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"publicProfile.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n			</div>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.loggedIn : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(42, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"4":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "				<div class=\"modal-header\">\n					<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n					<h3 class=\"modal-title\">\n						<span class=\"fa fa-fw fa-user\"></span> "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.displayName : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\n					</h3>\n				</div>\n				<div class=\"modal-body\">\n\n					<div class=\"profile-top-bar row-fluid\">\n						<div class=\"span3 profile-image\">\n							<img id=\"profile_avatar\" src=\""
    + this.escapeExpression(((helper = (helper = helpers.avatarLarge || (depth0 != null ? depth0.avatarLarge : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"avatarLarge","hash":{},"data":data}) : helper)))
    + "\" width=\"200\" height=\"200\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"alt","profile.image",{"name":"$$","hash":{},"data":data}))
    + " />\n						</div>\n						<div class=\"span9 profile-info\">\n							<div class=\"username\">\n								<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"publicProfile.username",{"name":"$","hash":{},"data":data}))
    + "</h4>\n								<h3>"
    + this.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"username","hash":{},"data":data}) : helper)))
    + "</h3>\n							</div>\n							<div class=\"row-fluid\">\n"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.country : depth0),(depth0 != null ? depth0.state : depth0),(depth0 != null ? depth0.city : depth0),{"name":"or","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.email : depth0),{"name":"if","hash":{},"fn":this.program(14, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.company : depth0),{"name":"if","hash":{},"fn":this.program(16, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "							</div>\n						</div>\n					</div>\n\n					<div class=\"profile-wrapper\">\n						<div class=\"header-bar\">\n							<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"publicProfile.title",{"name":"$","hash":{},"data":data}))
    + "</h2>\n						</div>\n						<div class=\"profile-content-wrapper\">\n							<div class=\"row-fluid\">\n								<div class=\"span5\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.displayName : depth0),{"name":"if","hash":{},"fn":this.program(18, data, 0),"inverse":this.program(20, data, 0),"data":data})) != null ? stack1 : "")
    + "\n\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.phone : depth0),{"name":"if","hash":{},"fn":this.program(26, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.streetAddress1 : depth0),(depth0 != null ? depth0.streetAddress2 : depth0),(depth0 != null ? depth0.postalCode : depth0),(depth0 != null ? depth0.city : depth0),(depth0 != null ? depth0.state : depth0),(depth0 != null ? depth0.country : depth0),{"name":"or","hash":{},"fn":this.program(28, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n\n								</div>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.shortBio : depth0),{"name":"if","hash":{},"fn":this.program(36, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "							</div>\n							<div class=\"row-fluid\">\n"
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.authoredLabs : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":this.program(38, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "							</div>\n						</div>\n					</div>\n\n\n				</div>\n";
},"5":function(depth0,helpers,partials,data) {
    var helper;

  return this.escapeExpression(((helper = (helper = helpers.displayName || (depth0 != null ? depth0.displayName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"displayName","hash":{},"data":data}) : helper)));
},"7":function(depth0,helpers,partials,data) {
    var helper;

  return this.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"username","hash":{},"data":data}) : helper)));
},"9":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "									<div class=\"span4\">\n										<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"publicProfile.location",{"name":"$","hash":{},"data":data}))
    + "</h4>\n										<span class=\"user-data\">\n"
    + ((stack1 = (helpers.and || (depth0 && depth0.and) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.city : depth0),(depth0 != null ? depth0.state : depth0),{"name":"and","hash":{},"fn":this.program(10, data, 0),"inverse":this.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "											"
    + this.escapeExpression(((helper = (helper = helpers.country || (depth0 != null ? depth0.country : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"country","hash":{},"data":data}) : helper)))
    + "\n										</span>\n									</div>\n";
},"10":function(depth0,helpers,partials,data) {
    var helper;

  return "												"
    + this.escapeExpression(((helper = (helper = helpers.city || (depth0 != null ? depth0.city : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"city","hash":{},"data":data}) : helper)))
    + ", "
    + this.escapeExpression(((helper = (helper = helpers.state || (depth0 != null ? depth0.state : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"state","hash":{},"data":data}) : helper)))
    + "\n";
},"12":function(depth0,helpers,partials,data) {
    var helper;

  return "												"
    + this.escapeExpression(((helper = (helper = helpers.city || (depth0 != null ? depth0.city : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"city","hash":{},"data":data}) : helper)))
    + " "
    + this.escapeExpression(((helper = (helper = helpers.state || (depth0 != null ? depth0.state : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"state","hash":{},"data":data}) : helper)))
    + "\n";
},"14":function(depth0,helpers,partials,data) {
    var helper;

  return "									<div class=\"span4\">\n										<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"publicProfile.email",{"name":"$","hash":{},"data":data}))
    + "</h4>\n										<span class=\"user-data\">"
    + this.escapeExpression(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"email","hash":{},"data":data}) : helper)))
    + "</span>\n									</div>\n";
},"16":function(depth0,helpers,partials,data) {
    var helper;

  return "									<div class=\"span4\">\n										<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"publicProfile.company",{"name":"$","hash":{},"data":data}))
    + "</h4>\n										<span class=\"user-data\">"
    + this.escapeExpression(((helper = (helper = helpers.company || (depth0 != null ? depth0.company : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"company","hash":{},"data":data}) : helper)))
    + "</span>\n									</div>\n";
},"18":function(depth0,helpers,partials,data) {
    var helper;

  return "										<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"publicProfile.name",{"name":"$","hash":{},"data":data}))
    + "</h4>\n										<p class=\"user-data\">"
    + this.escapeExpression(((helper = (helper = helpers.displayName || (depth0 != null ? depth0.displayName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"displayName","hash":{},"data":data}) : helper)))
    + "</p>\n";
},"20":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.and || (depth0 && depth0.and) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.firstName : depth0),(depth0 != null ? depth0.lastName : depth0),{"name":"and","hash":{},"fn":this.program(21, data, 0),"inverse":this.program(23, data, 0),"data":data})) != null ? stack1 : "");
},"21":function(depth0,helpers,partials,data) {
    var helper;

  return "											<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"publicProfile.name",{"name":"$","hash":{},"data":data}))
    + "</h4>\n											<p class=\"user-data\">"
    + this.escapeExpression(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"firstName","hash":{},"data":data}) : helper)))
    + " "
    + this.escapeExpression(((helper = (helper = helpers.lastName || (depth0 != null ? depth0.lastName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"lastName","hash":{},"data":data}) : helper)))
    + "</p>\n";
},"23":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.firstName : depth0),(depth0 != null ? depth0.lastName : depth0),{"name":"or","hash":{},"fn":this.program(24, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"24":function(depth0,helpers,partials,data) {
    var helper;

  return "												<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"publicProfile.name",{"name":"$","hash":{},"data":data}))
    + "</h4>\n												<p class=\"user-data\">"
    + this.escapeExpression(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"firstName","hash":{},"data":data}) : helper)))
    + " "
    + this.escapeExpression(((helper = (helper = helpers.lastName || (depth0 != null ? depth0.lastName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"lastName","hash":{},"data":data}) : helper)))
    + "</p>\n";
},"26":function(depth0,helpers,partials,data) {
    var helper;

  return "										<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"publicProfile.phone",{"name":"$","hash":{},"data":data}))
    + "</h4>\n										<p><a href=\"tel:"
    + this.escapeExpression(((helper = (helper = helpers.phone || (depth0 != null ? depth0.phone : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"phone","hash":{},"data":data}) : helper)))
    + "\">"
    + this.escapeExpression(((helper = (helper = helpers.phone || (depth0 != null ? depth0.phone : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"phone","hash":{},"data":data}) : helper)))
    + "</a></p>\n";
},"28":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "										<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"publicProfile.address",{"name":"$","hash":{},"data":data}))
    + "</h4>\n										<address>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.streetAddress1 : depth0),{"name":"if","hash":{},"fn":this.program(29, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.streetAddress2 : depth0),{"name":"if","hash":{},"fn":this.program(31, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.city : depth0),(depth0 != null ? depth0.state : depth0),(depth0 != null ? depth0.postalCode : depth0),{"name":"or","hash":{},"fn":this.program(33, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "											"
    + this.escapeExpression(((helper = (helper = helpers.country || (depth0 != null ? depth0.country : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"country","hash":{},"data":data}) : helper)))
    + "\n										</address>\n";
},"29":function(depth0,helpers,partials,data) {
    var helper;

  return "												"
    + this.escapeExpression(((helper = (helper = helpers.streetAddress1 || (depth0 != null ? depth0.streetAddress1 : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"streetAddress1","hash":{},"data":data}) : helper)))
    + " <br />\n";
},"31":function(depth0,helpers,partials,data) {
    var helper;

  return "												"
    + this.escapeExpression(((helper = (helper = helpers.streetAddress2 || (depth0 != null ? depth0.streetAddress2 : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"streetAddress2","hash":{},"data":data}) : helper)))
    + " <br />\n";
},"33":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "												"
    + this.escapeExpression(((helper = (helper = helpers.city || (depth0 != null ? depth0.city : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"city","hash":{},"data":data}) : helper)))
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.state : depth0),(depth0 != null ? depth0.postalCode : depth0),{"name":"or","hash":{},"fn":this.program(34, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + this.escapeExpression(((helper = (helper = helpers.state || (depth0 != null ? depth0.state : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"state","hash":{},"data":data}) : helper)))
    + " "
    + this.escapeExpression(((helper = (helper = helpers.postalCode || (depth0 != null ? depth0.postalCode : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"postalCode","hash":{},"data":data}) : helper)))
    + " <br />\n";
},"34":function(depth0,helpers,partials,data) {
    return ",";
},"36":function(depth0,helpers,partials,data) {
    var helper;

  return "									<div class=\"span7\">\n										<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"publicProfile.biography",{"name":"$","hash":{},"data":data}))
    + "</h4>\n										<p class=\"user-data\">\n											"
    + this.escapeExpression(((helper = (helper = helpers.shortBio || (depth0 != null ? depth0.shortBio : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"shortBio","hash":{},"data":data}) : helper)))
    + "\n										</p>\n									</div>\n";
},"38":function(depth0,helpers,partials,data) {
    var stack1;

  return "									<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"publicProfile.authoredLabs",{"name":"$","hash":{},"data":data}))
    + "</h4>\n									<p>\n										<span class=\"skin-text-popout user-data\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.authoredLabs : depth0),{"name":"each","hash":{},"fn":this.program(39, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "										</span>\n									</p>\n";
},"39":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "												<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"lab/{{id}}"},"data":data}))
    + "\" class=\"authored-lab\">"
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</a>"
    + ((stack1 = helpers.unless.call(depth0,(data && data.last),{"name":"unless","hash":{},"fn":this.program(40, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"40":function(depth0,helpers,partials,data) {
    return ", ";
},"42":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<div class=\"modal-header\">\n					<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n					<h3 class=\"modal-title\">\n						<span class=\"fa fa-fw fa-user\"></span> "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.displayName : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\n					</h3>\n				</div>\n				<div class=\"modal-body\">\n					<div class=\"alert alert-danger\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"publicProfile.pleaseLogin",{"name":"$","hash":{},"data":data}))
    + "\n					</div>\n				</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"modal-dialog\">\n	<div class=\"modal-content\">\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.loading : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n	</div>\n</div>";
},"useData":true});
Handlebars.registerPartial('common_public_profile', t);
return t;
});

