/**
 * Gravatar image generation
 */
define('utilities/gravatar',['require','jquery','underscore','library/utilities/md5'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore"),
	    md5 = require("library/utilities/md5");

	var gravatarBase =  "//www.gravatar.com/avatar/";

	return function(email, size, defaultImage){
		var params = {
			size     : 80,
			"default": "mm"
		};
		if (typeof size === "object") {
			params = _.defaults(size, params);
			size = null;
			defaultImage = null;
		}

		if(size){ params.size = size; }
		if(defaultImage){ params["default"] = defaultImage; }

		return gravatarBase + md5(email) + "?" + $.param(params);
	};

});

