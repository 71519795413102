
define('hbs!tpls/console/walkthrough/step-3.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var helper;

  return "<div class=\"console-walkthrough-title\">\n	<button class=\"btn btn-default console-walkthrough-exit\">&times;</button>\n	<span class=\"console-walkthrough-step-number\">"
    + this.escapeExpression(((helper = (helper = helpers.stepNumber || (depth0 != null ? depth0.stepNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"stepNumber","hash":{},"data":data}) : helper)))
    + "</span>\n	"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.settings.title",{"name":"$","hash":{},"data":data}))
    + "\n</div>\n\n<div class=\"console-walkthrough-content step-"
    + this.escapeExpression(((helper = (helper = helpers.stepNumber || (depth0 != null ? depth0.stepNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"stepNumber","hash":{},"data":data}) : helper)))
    + "\">\n	"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.settings.description",{"name":"$","hash":{},"data":data}))
    + "\n	<img class=\"img-responsive\" src=\"/images/console/walkthrough/console-walkthrough-settings-modal.png\" />\n</div>\n\n<div class=\"console-walkthrough-actions\">\n	<button class=\"btn btn-primary console-walkthrough-prev\"><span class=\"fa fa-va fa-lg fa-caret-left\"></span> "
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"previous",{"name":"$","hash":{},"data":data}))
    + "</button>\n	<button class=\"btn btn-primary console-walkthrough-next\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"next",{"name":"$","hash":{},"data":data}))
    + " <span class=\"fa fa-va fa-lg fa-caret-right\"></span></button>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('console_walkthrough_step_3', t);
return t;
});

