/**
 * Verify Account Router
 *
 * Code starts running here for the /maintenance page.
 */
define('routers/verify-account',['require','library/vlp/router','views/verify-account/main'],function (require) {
	"use strict";

	//class dependencies
	var BaseRouter = require("library/vlp/router"),
	    MainView   = require("views/verify-account/main");


	return BaseRouter.extend({
		routeBase : "verify-account",
		/**
		 * Constructor
		 *
		 * This code is called when new Router([options]) is called in the example.html.
		 *
		 * @param options Map of options (mostly passed on to new MainView([options]);
		 */
		initialize : function(config){

			this.setup(config);

			this.mainView = new MainView(config);

			this.start();
		},
		/**
		 * List of possible routes
		 */
		routes : {
			//verify-account#akjsdhakjsdhakdj
			":token"   : "index",
			"*actions" : "index"
		},
		/**
		 * Default (initial) route. Loads first step.
		 */
		index : function(token){
			if(token){
				this.mainView.setToken(token);
			}
		}
	});
});

