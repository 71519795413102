define('library/underscore/is-plain-object',['require','underscore'],function (require) {
	"use strict";
	var _  = require("underscore");

	_.mixin({
		isPlainObject: function(obj){
			return Object(obj) === obj && Object.getPrototypeOf(obj) === Object.prototype && !_.isArguments(obj);
		}
	});

	return _;
});
