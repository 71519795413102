/**
 * Event-promos main Backbone View
 */
define('views/event-promos/main',['require','jquery','underscore','library/vlp/app','views/vlp-base','models/promo-content','views/common/layout-tpls','hbs!tpls/event-promos/main.handlebars','hbs!tpls/event-promos/promo-content.handlebars','i18n!nls/event-promos'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App         = require("library/vlp/app"),
	    VlpBaseView     = require("views/vlp-base"),
	    PromoContent    = require("models/promo-content"),
	    LayoutTPLs      = require("views/common/layout-tpls"),
	    mainTPL         = require("hbs!tpls/event-promos/main.handlebars"),
	    promoContentTPL = require("hbs!tpls/event-promos/promo-content.handlebars"),
	    pageTexts       = require("i18n!nls/event-promos");

	return VlpBaseView.extend({
		name : "EVENT_PROMOS",

		className : "general-page",

		checkLoggedIn         : false,
		checkMaintenance      : true,
		checkBrowserSupported : false,
		stationPush           : true,
		checkTenantPath       : false,
		checkSystemMessages   : false,
		secondScreenPage      : true,

		layoutTemplate : LayoutTPLs.layoutNoLogin,
	//Required
		mainTemplate : mainTPL,
		events : {
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){

			_.bindAll(this);

			App.locale.mergeI18n(pageTexts);
			//Checks logged in
			this.setup(options);
			$("a.home").removeAttr("href");

			App.windowCommunication.on("receive:activeLab", this.onActiveLabChanged);
			App.windowCommunication.on("response:checkActiveLab", this.onActiveLabChanged);

			App.windowCommunication.call("checkActiveLab");

			this.promoContent = new PromoContent.Collection();
			this.once(VlpBaseView.STARTUP_COMPLETE, function(){
				this.render();

				this.promoContent.fetch({
					success : this.render,
					error   : this.render
				});

			}, this);

		},
		/**
		 * Display the content.
		 */
		render : function(){

			//Always return this for chaining
			this.$("#promo_content").html(promoContentTPL(this.promoContent));
			this.$("#promo_content .item").first().addClass("active");
			this.$("#promo_content").carousel({
				interval : App.config.eventPromoCycleInterval,
				pause    : false
			});

			return this;
		},
		onActiveLabChanged : function(activeLab, event){
			if(activeLab.entitlementId){
				App.redirect({page: App.config.pages.REMOTE_CONTROL, action: activeLab.entitlementId, tenant: activeLab.tenant,  forceHost : true});
			} else if(activeLab.remoteToken){
				App.redirect({page: App.config.pages.REMOTE_CONTROL, action: "remoteToken/" + activeLab.remoteToken, tenant: activeLab.tenant,  forceHost : true});
			}

		}


	});

});
