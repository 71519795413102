
define('hbs!tpls/instructor/warn-modal.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.warning.step.title",{"name":"$","hash":{},"data":data}))
    + "\n";
},"3":function(depth0,helpers,partials,data) {
    return "						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.warning.percent.title",{"name":"$","hash":{},"data":data}))
    + "\n";
},"5":function(depth0,helpers,partials,data) {
    return "					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.warning.step.message",(depth0 != null ? depth0.warningValue : depth0),{"name":"$","hash":{},"data":data}))
    + "\n";
},"7":function(depth0,helpers,partials,data) {
    return "					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.warning.percent.message",(depth0 != null ? depth0.warningValue : depth0),{"name":"$","hash":{},"data":data}))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"modal_instructor_warning\" class=\"modal fluid fade global hide\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.warningType : depth0),"step",{"name":"eq","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "				</h3>\n			</div>\n			<div class=\"modal-body\">\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.warningType : depth0),"step",{"name":"eq","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "			</div>\n		</div>\n	</div>\n</div>";
},"useData":true});
Handlebars.registerPartial('instructor_warn_modal', t);
return t;
});

