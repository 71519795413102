/**
 * Security Prompts Backbone View
 */
define('views/common/security-prompts',['require','jquery','underscore','library/vlp/app','library/vlp/view','views/common/profile','hbs!tpls/common/security-prompts.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App         = require("library/vlp/app"),
	    BaseView    = require("library/vlp/view"),
	    ProfileView = require("views/common/profile"),
	    tpl         = require("hbs!tpls/common/security-prompts.handlebars");

	return BaseView.extend({

		template : tpl,

		autoValidate : false,
		removeKeepEl : true,
		previouslyAnsweredQuestions : [],

		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		el : "#user_profile",

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"submit #security_questions_form"              : "onAnswerSecurityQuestion",
			"submit #security_questions_get_username_form" : "onFetchSecurityQuestion",
			"click .cancel"                                : "onCancel",
			"click #password_saved .btn-success"           : "onCompleteContinue",
			"click #security_questions_refresh_question"   : "onFetchSecurityQuestion",
			"change #security_answer_show"                 : "showHideSecurityAnswer"
		},

		modelBindings : {
			"#security_questions_get_username_username": "username",
			"#securityAnswer": "security_answer",
			"#securityAnswer_visible": "security_answer",
		},

		validation : {
			username : {
				required  : true
			},
			security_answer : {
				required  : true
			}
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){

			_.bindAll(this);
			options = options || {};
			this.model.set("questionsAnsweredCorrectly", 0);

			this.passwordSaved = false;
			if(this.model.get("securityQuestions") && this.model.get("securityQuestions").length){
				this.question = this.model.get("securityQuestions")[_.random(0, this.model.get("securityQuestions").length - 1)];
			} else{
				this.question = {};
			}

			this.cancellable = (options.hasOwnProperty("cancellable") ? options.cancellable : false);
			this.isModal     = (options.hasOwnProperty("isModal")     ? options.isModal : false);
			this.token = options.token;
		},

		serialize : function(){
			var data = this.model.toHandlebars();

			if(this.model.get("setQuestions")){
				data.setSecurityQuestions = true;
			} else if(this.model.get("answerQuestions") && this.model.get("securityQuestions") && this.model.get("securityQuestions").length){
				data.answerSecuirtyQuestions = true;
			} else if(this.model.get("answerQuestions") && this.model.get("username")){
				data.answerSecurityQuestionsGetUsername = true;
			} else if(this.model.get("answerQuestions")){
				data.answerSecurityQuestionsGetUsername = true;
			} else if(this.model.get("passwordExpired")){
				data.setPassword = true;
			} else if(this.model.get("setPassword")){
				data.setPassword = true;
			} else if(this.passwordSaved){
				data.passwordSaved = true;
			}

			data.question    = this.question;
			data.cancellable = this.cancellable;
			data.isModal     = this.isModal;

			return data;
		},
		/**
		 * Display the content.
		 */
		afterRender : function(options, data){
			$(window).off("beforeunload", this.forceLogout);

			if(data.answerSecurityQuestionsGetUsername && this.model.get("username")){
				//this.onFetchSecurityQuestion();
			} else if(this.model.get("setQuestions")){
				this.showSecurityQuestionsSetPrompt();
				$(window).on("beforeunload", this.forceLogout);
			} else if(this.model.get("setPassword") || this.model.get("passwordExpired")){
				this.showPasswordPrompt();
			}

			if(data.answerSecurityQuestionsGetUsername || data.answerSecuirtyQuestions || data.setPassword){
				this.$("input:visible:first").focus();
			}

			//Always return this for chaining
			return this;
		},

		remove : function(){
			if(this.profileView){
				this.profileView.remove();
				this.profileView = null;
			}
			BaseView.prototype.remove.apply(this, arguments);
			$(window).off("beforeunload", this.forceLogout);
		},
		showHideSecurityAnswer : function(){

			if(this.$("#security_answer_show").prop("checked")){
				this.$("#securityAnswer_visible").show();
				this.$("#securityAnswer").hide();
			} else{
				this.$("#securityAnswer_visible").hide();
				this.$("#securityAnswer").show();
			}

		},

		showSecurityQuestionsSetPrompt : function(){
			var self = this;
			if(!this.profileView){
				this.profileView = new ProfileView({
					model      : this.model,
					el         : this.$("#security_question_holder"),
					verifyMode : true,
					isModal    : this.isModal
				});
			}
			this.profileView.setElement(this.$("#security_question_holder"));

			this.listenTo(this.model, "securityQuestionsUpdated", function(){
				$(window).on("beforeunload", this.forceLogout);
				self.model.set("setQuestions", false);
				self.model.set("setPassword", true);
				self.model.set("loggedIn", true);
				self.render();
			});

			this.listenTo(this.profileView, "afterRender", function(){
				this.trigger("afterRender");
			});
			this.profileView.render({
				showGeneral           : false,
				showSecurityQuestions : true,
				showPassword          : false
			});
		},

		onFetchSecurityQuestion : function(event){

			if(event) { event.preventDefault(); }
			var self = this;
			var $form = this.$("#security_questions_get_username_form");

			var $saveSuccess = $form.find(".save-success").hide();
			var $saveError   = $form.find(".save-error").hide();

			this.model.unset("security_answer");
			var $error = $form.find("#security_questions_get_username_invalid").slideUp("fast");

			if(this.validate(["username"], {displayErrors: true})){ return; }

			var $saveSpinner = $form.find(".save-spinner").show();
			var $saveButton = $form.find(".save-changes").button("loading");

			var current_question_id = null;
			var answered_question_id = null;
			if(self.question) {
				current_question_id = self.question.id;
				answered_question_id = self.answeredQuestionId;
			}

			this.model.getSecurityQuestion({

				success : function(){
					$saveSpinner.hide();
					$saveSuccess.show().stop().delay(3000).fadeOut("slow");
					$saveError.hide();
					$error.slideUp("fast");
					$saveButton.button("reset");

					self.question = self.model.get("securityQuestions")[0];
					self.model.set("answerQuestions", true);
					self.render();
				},
				error  : function(model, jqXHR){
					$saveSpinner.hide();
					$saveSuccess.hide();
					$saveError.show().stop().delay(6000).fadeOut("slow");
					$error.html(_.escapeHTML(jqXHR.response.message)).slideDown("fast");
					$saveButton.button("reset");
					self.trigger("afterRender");
				},

				data: {
					excludeQuestionId: current_question_id,
					answeredQuestionId: answered_question_id
				}
			});
		},

		onAnswerSecurityQuestion : function(event){
			if(event) { event.preventDefault(); }

			var self = this;
			var $form = this.$("#security_questions_form");

			var $error       = $form.find(".security-questions-error").slideUp("fast");
			var $saveSuccess = $form.find(".save-success").hide();
			var $saveError   = $form.find(".save-error").hide();

			if(this.validate(["security_answer"], {displayErrors: true})){ return; }

			var questions = _.clone(this.previouslyAnsweredQuestions);
			this.question.answer = this.model.get("security_answer");

			questions.push(this.question);
			this.model.set("securityQuestions", questions);

			var $saveSpinner = $form.find(".save-spinner");
			var $saveButton = $form.find(".save-changes");

			this.model.checkSecurityQuestion(questions, {

				success: function () {
					$saveSpinner.hide();
					$saveSuccess.show().stop().delay(3000).fadeOut("slow");
					$saveError.hide();
					$error.slideUp("fast");
					self.model.set("questionsAnsweredCorrectly", self.model.get("questionsAnsweredCorrectly") + 1);
					self.answeredQuestionId = self.question.id;

					self.previouslyAnsweredQuestions = questions;
					if (self.model.get("questionsAnsweredCorrectly") > 1) {
						self.previouslyAnsweredQuestions = [];
						self.model.set("answerQuestions", false);
						self.model.set("setPassword", true);
						self.render();
					} else {
						self.onFetchSecurityQuestion();
					}

				},
				error  : function(model, jqXHR){
					$saveSpinner.hide();
					$saveError.show().delay(6000).fadeOut("slow");
					$error.html(_.escapeHTML(jqXHR.response.message)).slideDown("fast");
					self.trigger("afterRender");
				},
				beforeSend : function(){
					$saveSpinner.show();
					$saveButton.button("loading");
				},
				complete : function(){
					$saveSpinner.hide();
					$saveButton.button("reset");
				}
			});
		},

		showPasswordPrompt : function(){
			if(!this.profileView){
				this.profileView = new ProfileView({
					model      : this.model,
					el         : this.$("#password_holder"),
					verifyMode : true,
					isModal    : this.isModal
				});
			}

			this.profileView.setElement(this.$("#password_holder"));
			this.profileView.verifyMode    = !!this.token;
			this.profileView.resetPassword = !this.model.get("passwordExpired") && !this.token;

			this.model.set("passwordCurrent", "forgotPass");
			this.model.set("password", "");

			this.listenTo(this.model, "passwordUpdated", this.onPasswordSaved);

			this.profileView.render({
				showGeneral           : false,
				showSecurityQuestions : false,
				showPassword          : true
			});
		},

		onPasswordSaved : function(){
			this.model.set("setPassword", false);
			this.model.set("passwordExpired", false);
			this.passwordSaved = true;
			this.render();
		},

		onCancel : function(event){
			if(event) { event.preventDefault(); }
			this.forceLogout();
			this.trigger("cancelled");
		},

		onCompleteContinue : function(event){
			if(this.isModal && event){ event.preventDefault(); }
			this.trigger("securityCompleted");
		},
		forceLogout : function(){
			this.model.unset("securityQuestions");
			this.model.set("questionsAnsweredCorrectly", 0);

			//Force logout call for OTP users
			App.user.logout({
				logoutFlush : true,
				retries : 0,
				force : true,
				error : function(){}
			});
		}

	});

});

