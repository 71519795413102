define('views/instructor/console/entitlement',['require','jquery','underscore','library/vlp/app','library/vlp/view','views/instructor/console/vm-console','views/console/saas-console','views/console/console-listing','views/console/entitlement-settings','models/entitlement','hbs!tpls/instructor/console/entitlement.handlebars'],function (require) {
	"use strict";

	var $        = require("jquery"),
	    _        = require("underscore");

	var App                     = require("library/vlp/app"),
	    BaseView                = require("library/vlp/view"),
	    VmConsoleView           = require("views/instructor/console/vm-console"),
		SaasConsoleView         = require("views/console/saas-console"),
	    ConsoleListingView      = require("views/console/console-listing"),
	    EntitlementSettingsView = require("views/console/entitlement-settings"),
	    Entitlement             = require("models/entitlement"),
	    entitlementTPL          = require("hbs!tpls/instructor/console/entitlement.handlebars");

	return BaseView.extend({
		removeKeepEl : true,
		template : entitlementTPL,

		events : {
			"click .send-text"                    : "onSendText",
			"click .take-over"                    : "onToggleTakeover",
			"click .broadcast-display"            : "onToggleBroadcast",
			"click .send-new-token:not(.disabled)": "onSendNewToken",

			"click .toggle-panel-left"            : "onTogglePanelLeft",
			"click .toggle-panel-right"           : "onTogglePanelRight"
		},

		modelBindings: {
			".completed-steps"               : "activeStepNumber",
			".total-steps"                   : "totalSteps"
		},

		initialize : function(options){
			_.bindAll(this);
			this.class        = options.class;
			this.panelManager = options.panelManager;

			this.consolesReady = false;
			this.warningShown = false;
			this.dangerShown = false;

			this.listenTo(this.model, "change:status", this.statusChanged);
			this.listenTo(this.model, "change:timeAllotted", this.timeAllottedChanged);
			this.listenTo(this.model, "change:instructorViewing change:raisedHand change:takenOver change:broadcastingDisplay", this.model.classPushUpdate);
			this.listenTo(this.model, "change:activeScreenTicket change:activeVmx", this.screenTicketUpdated);
			this.listenTo(this.model, "change:progress", this.progressChanged);
			this.listenTo(this.model, "change:activeConsoleId", this.onActiveConsoleIdChanged);

			App.push.on("other:classEntitlement:" + this.model.get("id") + ":reset", this.vAppReset);
			App.push.on("received:cloneVapp:" + this.model.id + ":update", _.throttle(this.onCloneVapp, 1000, {leading: false}));

			this.model.pushSubscribe();
			$(window).on("beforeunload", this.cleanupConnections);
		},

		serialize : function(){
			var data = this.model.toHandlebars();

			data.allowInstructorToResetLab     = App.config.allowInstructorToResetLab;
			data.allowInstructorToSendNewToken = App.config.allowInstructorToSendNewToken;
			data.userIdleDelayMinutes          = Math.max(Math.round(App.config.userIdleDelay / 60000), 1);
			data.broadcastDisplayEnabled       = App.config.broadcastDisplayEnabled;

			return data;
		},

		afterRender : function(){
			if(!this.checkEntitlementIsValid()){
				this.returnToClass();
				return;
			}

			if(this.panelManager){
				this.panelManager.updateContainers(true);
			}

			if(!this.entitlementSettingsView){
				this.entitlementSettingsView = new EntitlementSettingsView({
					el   : this.$("#entitlement_settings"),
					model: this.model
				});
				this.entitlementSettingsView.render();
			}
			this.$("[rel=tooltip]").tooltip();
			$("body").addClass("has-vm-console");

			this.statusChanged();

			$(document).off("click", ".entitlement-reset", this.reset);
			$(document).off("click", ".entitlement-reset-confirmed", this.resetConfirmed);
			$(document).off("show",  "#modal_reset", this.setupResetModal);

			$(document).on("click",  ".entitlement-reset", this.reset);
			$(document).on("click",  ".entitlement-reset-confirmed", this.resetConfirmed);
			$(document).on("show",   "#modal_reset", this.setupResetModal);


			if ((!this.model.has("vms") || this.model.get("vms").length === 0) && !this.model.get("saasConsoleOnly")){
				this.model.fetchRelated("vms", {
					success : this.renderConsoles,
					error   : this.vmFetchError
				});
			} else{
				this.renderConsoles();
			}

			return this;
		},

		renderConsoles : function(){
			if(!this.rendered){
				this.once("afterRender", this.renderConsoles);
				return;
			}

			this.model.set("instructorViewing", true);

			this.model.get("vms").sort();

			if(!this.consoleListing){
				this.consoleListing = new ConsoleListingView({
					el            : $("#vm_listing"),
					collection    : this.model.get("consoles"),
					entitlement   : this.model,
					classMode     : true,
					panel         : this.panelManager.getPanel("#vm_listing_panel")
				});

				$("#vm_listing").off("click.classEntitlement");
				$("#vm_listing").on("click.classEntitlement", ".vm", this.switchConsole);
			}

			this.consoleListing.render();

			this.consolesReady = true;

			this.startTimer();
			this.showConsole();

			if(this.model.get("integrationCode")){
				try{
					$("body").append(this.model.get("integrationCode"));
				} catch(e){
					console.warn(e);
				}
			}
		},

		onCloneVapp : function(info){
			var data = (info && info.data) || null;
			if(data && data.phase == "start"){
				App.generalMessage({
					id     : "cloud_vapp",
					type   : "warning",
					message: App.i18n("console.entitlement.cloneVapp.started")
				});
			}
		},

		checkEntitlementIsValid : function(){
			if(
				this.model.get("status") === Entitlement.EXPIRED ||
				this.model.get("status") === Entitlement.COMPLETED ||
				this.model.get("status") === Entitlement.CANCELED ||
				this.model.get("status") === Entitlement.AWAITING_GRADE
			){
				this.statusChanged();
				return false;
			}

			return true;
		},

		vmFetchError : function(info){
			alert(App.i18n("instructor.entitlement.errors.cannotFetchVms"));
			this.returnToClass();
		},

		returnToClass : function(){
			App.router.navigate(this.class.get("id") + "/", {trigger : true});
		},

		onActiveConsoleIdChanged : function(){
			this.showConsole();
			this.setupBroadcasting();
		},

		showConsole : function(){
			if(!this.rendered){
				this.once("afterRender", this.showConsole);
				return;
			}

			if(!this.consolesReady){ return; }

			var activeConsole;
			if(this.model.get("activeConsoleIdNumber")){
				if(this.model.get("activeConsoleIdType") === "saas"){
					activeConsole = this.model.get("saasConsoles").get(this.model.get("activeConsoleIdNumber"));
					if(activeConsole){
						this.showSaasConsole(activeConsole);
					}
				} else if(this.model.get("activeConsoleIdType") === "vm"){
					activeConsole = this.model.get("vms").get(this.model.get("activeConsoleIdNumber"));
					if(activeConsole){
						this.showVmConsole(activeConsole);
					}
				}
			}

			if(!activeConsole){
				var initialConsoleId = this.getInitialConsoleId();
				if(initialConsoleId){
					console.log("No console id specified or id not found. Setting to default:", initialConsoleId);
					this.model.set("activeConsoleId", initialConsoleId);
				} else{
					console.warn("no consoles to show");
				}
			} else {
				App.VlpGlobal.setActiveConsoleView(this.activeConsoleView);
				if(this.activeConsoleView){
					$("body").toggleClass("has-vm-console", this.activeConsoleView.consoleType === "vm");
					$("body").toggleClass("has-saas-console", this.activeConsoleView.consoleType === "saas");
				}
			}
			this.setupTakeover();
			this.setupBroadcasting();

		},
		showVmConsole : function(vmConsole){
			if(!vmConsole){ return; }

			if(!this.vmConsoleView){
				this.vmConsoleView = new VmConsoleView({
					el : this.$("#vm"),
					entitlement : this.model,
					entitlementView : this,
					panelManager : this.panelManager
				});
			}
			this.detachConsoleView(this.saasConsoleView);

			this.activeConsoleView = this.vmConsoleView;
			this.vmConsoleView.setModel(vmConsole);
			this.attachConsoleView(this.vmConsoleView);
		},
		showSaasConsole : function(saasConsole){
			if(!saasConsole){ return; }

			if(!this.saasConsoleView){
				this.saasConsoleView = new SaasConsoleView({
					el : this.$("#saas_console"),
					entitlement : this.model,
					entitlementView : this,
					panelManager : this.panelManager
				});
			}
			this.detachConsoleView(this.vmConsoleView);

			this.activeConsoleView = this.saasConsoleView;
			this.saasConsoleView.setModel(saasConsole);
			this.attachConsoleView(this.saasConsoleView);
		},

		getInitialConsoleId : function(){
			var initialConsole;
			if(this.model.get("vms") && this.model.get("vms").length > 0){
				initialConsole = this.model.get("vms").findWhere({initial : true});
				if(!initialConsole){
					initialConsole = this.model.get("vms").at(0);
				}
			} else if(this.model.get("saasConsoles") && this.model.get("saasConsoles").length > 0){
				initialConsole = this.model.get("saasConsoles").at(0);
			}

			if(initialConsole){
				return initialConsole.get("consoleType") + "-" + initialConsole.get("id");
			} else {
				return null;
			}
		},

		switchConsole : function(eventOrConsoleId){
			var consoleIdentifier;
			if(eventOrConsoleId && eventOrConsoleId.currentTarget){
				consoleIdentifier = $(eventOrConsoleId.currentTarget).data("switchConsole");
			} else{
				consoleIdentifier = eventOrConsoleId;
			}
			if(!consoleIdentifier){ return; }


			var saasConsole, vm, id;
			if(consoleIdentifier.toString().indexOf("saas-") === 0){
				consoleIdentifier = consoleIdentifier.replace(/^saas-/, "");
				saasConsole = this.model.get("saasConsoles").findWhere({name: consoleIdentifier});
				id = parseInt(consoleIdentifier, 10);
				if(!saasConsole && !isNaN(id)){
					saasConsole = this.model.get("saasConsoles").get(id);
				}
			} else if(consoleIdentifier.toString().indexOf("vm-") === 0){
				consoleIdentifier = consoleIdentifier.replace(/^vm-/, "");
				vm = this.model.get("vms").findWhere({name: consoleIdentifier});
				id = parseInt(consoleIdentifier, 10);
				if(!vm && !isNaN(id)){
					vm = this.model.get("vms").get(id);
				}
			} else {
				saasConsole = this.model.get("saasConsoles").findWhere({name: consoleIdentifier});
				vm = this.model.get("vms").findWhere({name: consoleIdentifier});
				id = parseInt(consoleIdentifier, 10);
				if(!saasConsole && !vm && !isNaN(id)){
					saasConsole = this.model.get("saasConsoles").get(id);
					vm = this.model.get("vms").get(id);
				}
			}

			var newActiveConsoleId;
			if(saasConsole){
				newActiveConsoleId = "saas-" + saasConsole.get("id");
			} else if (vm){
				newActiveConsoleId = "vm-" + vm.get("id");
			}

			if(newActiveConsoleId === this.model.get("activeConsoleId")){
				if(this.activeConsoleView){
					this.activeConsoleView.refreshConsole();
				}
			} else if(newActiveConsoleId){
				this.model.set("activeConsoleId", newActiveConsoleId);
			}
		},

		detachConsoleView : function(consoleView){
			if(!consoleView) {return; }

			consoleView.pause();
			consoleView.$el.detach();
		},
		attachConsoleView : function(consoleView){
			if(!consoleView) {return; }

			consoleView.$el.appendTo(this.$("#lab"));
			consoleView.resume();
		},

		vmsChanged : function(){
			this.model.fetchRelated("vms", {
				success : this.renderConsoles,
				error   : this.vmFetchError
			}, true);
		},

		statusChanged : function(model, value){
			if(this.model.get("status") === Entitlement.EXPIRED){
				this.showExpired();
			} else if(
				this.model.get("status") === Entitlement.COMPLETED ||
				this.model.get("status") === Entitlement.CANCELED ||
				this.model.get("status") === Entitlement.AWAITING_GRADE
			){
				this.showComplete();
			}
		},

		progressChanged : function(model, value){
			this.$(".progress").text(App.i18n("percent", Math.round(this.model.get("progress"))));
			this.$("#progressbar .progress-bar").css("width", + this.model.get("progress") + "%");
		},

		timeAllottedChanged : function(model){
			var diff = model.get("timeAllotted") - model.get("timeElapsed");
			this.startTimeRemaining = diff * 60;
		},

		startTimer : function(){
			this.trigger("started");
			if(this.timerInterval){
				this.stopTimer();
			}
			this.startTimeRemaining = this.model.get("timeRemaining") * 60;
			this.model.set("labTimeRemaining", this.startTimeRemaining);

			this.timer();
			this.timerInterval = setInterval(this.timer, 500);
		},

		stopTimer : function(){
			if(this.timerInterval){
				clearTimeout(this.timerInterval);
			}
			this.timerInterval = null;
		},

		timer : function(){
			var display;

			if(!this.startTime){
				this.startTime = Date.now();
			}
			var now = Date.now();
			var timeDiff = (now - this.startTime) / 1000; //in seconds

			if(App.config.timeRemainingDiffRefresh > 0){
				if(!this.lastTick){
					this.lastTick = now;
				} else{
					var timeSinceLastTick = (now - this.lastTick);
					if(timeSinceLastTick >= App.config.timeRemainingDiffRefresh || timeSinceLastTick < (App.config.timeRemainingDiffRefresh / -2)){
						this.showTimeDiffRemainingRefresh();
						return;
					}
					this.lastTick = now;
				}
			}
			var timeRemaining = this.startTimeRemaining - timeDiff;

			var progress = 1 - (timeRemaining / (this.model.get("timeAllotted") * 60));
			if(progress < 0){
				progress = 0;
			} else if(progress > 1){
				progress = 1;
			}

			this.$(".progress-holder .progress .bar").css("width", +(progress * 100) + "%");

			if(timeRemaining < 0){
				display = "00:00:00";

			} else{
				var remainingHours = Math.floor(timeRemaining / 3600);
				if(remainingHours > 24){
					var daysRemaining = Math.floor(timeRemaining / 86400);
					display = App.i18n(daysRemaining === 1 ? "time.day" : "time.days", daysRemaining);
				} else{
					var remainingMinutes = Math.floor((timeRemaining - (remainingHours * 3600)) / 60);
					var remainingSeconds = Math.floor((timeRemaining - (remainingHours * 3600) - (remainingMinutes * 60)));
					display = _.lpad(remainingHours, 2, "0") + ":" + _.lpad(remainingMinutes, 2, "0") + ":" + _.lpad(remainingSeconds, 2, "0");
				}
			}

			this.model.set("labTimeRemaining", Math.max(0, timeRemaining));
			this.$(".time-remaining").text(display);

			timeRemaining *= 1000;
			if(!this.dangerShown && timeRemaining < App.config.timeRemainingDanger){
				this.$(".time-remaining").removeClass("warning").addClass("danger");
				this.dangerShown = true;
			} else if(!this.warningShown && timeRemaining < App.config.timeRemainingWarning){
				this.$(".time-remaining").addClass("warning");
				this.warningShown = true;
			} else if (this.warningShown && timeRemaining > App.config.timeRemainingWarning){
				this.dangerShown = false;
				this.warningShown = false;
				this.$(".time-remaining").removeClass("warning danger");
			} else if (this.dangerShown && timeRemaining > App.config.timeRemainingDanger){
				this.dangerShown = false;
				this.$(".time-remaining").removeClass("danger");
			} else if (this.warningShown && (timeRemaining < App.config.timeRemainingWarning) && (timeRemaining > App.config.timeRemainingDanger) && !this.$(".time-remaining").hasClass("warning")){
				this.$(".time-remaining").addClass("warning");
			}
		},

		stop : function(){

			this.stopTimer();
			this.model.set({
				instructorViewing   : false,
				broadcastingDisplay : false
			});

			this.class.set("broadcastingDisplay", false);
			if(this.consoleListing){
				this.consoleListing.remove();
			}

			this.stopListening(this.model);
			$("#vm_listing").off("click.classEntitlement");
			this.cleanupConnections();

			$("body").removeClass("maximize");
			this.$("[rel=tooltip]").tooltip("hide");
			this.$("[rel=popover]").popover("hide");
			this.stopped = true;
		},

		showExpired : function(){
			this.stop();
			alert("expired");
		},

		showComplete : function(){
			this.ended = true;

			this.stop();
			alert("completed");

			if(this.panelManager){
				this.panelManager.clean();
			}
			if(this.vmConsoleView){
				this.vmConsoleView.clean();
			}
		},

		showTimeDiffRemainingRefresh : function(){
			this.stop();

			var retryTime = Date.now() + App.config.timeRemainingDiffCountdown;
			var countdownInterval;
			var countdownFunction = function(){
				var time = Date.now();
				var seconds = Math.max(0, Math.round((retryTime - time) / 1000));
				$("#modal_time_remaining_diff_refresh .time-remaining-diff-countdown").text(seconds);
				if(retryTime - (time + 1) <= 0){
					clearInterval(countdownInterval);
					App.reload();
				}
			};
			countdownInterval = setInterval(countdownFunction, 1000);
			countdownFunction();

			$("#modal_time_remaining_diff_refresh").modal("show");

		},

		onError: function(response){
			alert(_.escapeHTML(response.data.message));
		},

		cleanupConnections : function(){
			if(this.vmConsoleView){
				this.vmConsoleView.remove();
			}
		},

		onSendText : function(event){
			var $target = $(event.currentTarget);
			var text = _.unescapeString($target.data("sendText")) || _.unescapeString($target.data("text")) || $target.val() || $target.text();
			this.sendText(text);
		},

		sendText : function(text){
			if(this.vmConsoleView){
				this.vmConsoleView.sendText(text);
			}
		},

		onSendNewToken : function(event){
			var self = this;

			if(event){
				//keeps dropdown menu open
				event.preventDefault();
				event.stopImmediatePropagation();
			}

			this.model.get("account").newTokenByInstructor({
				beforeSend : function(){
					self.$(".send-new-token")
						.removeClass("success")
						.button("loading");
				},
				complete : function(){
					_.delay(function(){
						self.$(".send-new-token")
							.removeClass("success")
							.button("reset");
					}, 5000);
				},
				success : function(){
					self.$(".send-new-token")
						.addClass("success")
						.button("sent");
				}
			});
		},

		onToggleTakeover : function(event){
			this.model.set("takenOver", !this.model.get("takenOver"));
			this.model.set("userActionAllowed", this.model.get("takenOver"));
			this.setupTakeover();
		},

		onToggleBroadcast : function(event){

			if(!this.class.get("broadcastingDisplay")){
				if(
					App.config.instructorScreenTicketStaleRefreshTimeout &&
					this.vmConsoleView &&
					Date.now() - this.model.get("activeScreenTicketUpdatedTime") > App.config.instructorScreenTicketStaleRefreshTimeout
				){
					this.vmConsoleView.refreshConsole();
					this.class.set({
						broadcastingDisplay     : true,
						broadcastingEntitlement : this.model.get("id"),
						activeScreenTicket      : null,
						activeVmx               : null
					});
				} else {
					this.class.set({
						broadcastingDisplay     : true,
						broadcastingEntitlement : this.model.get("id"),
						activeScreenTicket      : this.model.get("activeScreenTicket"),
						activeVmx               : this.model.get("activeVmx")
					});
				}
			} else{
				this.class.set({
					broadcastingDisplay     : false,
					broadcastingEntitlement : null,
					activeScreenTicket      : null,
					activeVmx               : null
				});
			}
			this.model.set("broadcastingDisplay", this.class.get("broadcastingDisplay"));
			this.setupBroadcasting();
		},

		reset : function(event){
			var self = this;

			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			this.model.reset({
				beforeSend : function(){
					$("#modal_reset .reset-spinner").removeClass("hide");
					$("#modal_reset button.entitlement-reset").button("loading");
					$("#modal_reset button.cancel").hide();
				},
				complete : function(){
					$("#modal_reset .reset-spinner").addClass("hide");
					$("#modal_reset button.entitlement-reset").button("reset");
					$("#modal_reset button.cancel").show();
				},
				success : function(){
					$("#modal_reset .reset-confirmation").hide();
					$("#modal_reset .reset-sent").show();
					self.afterReset();
				}
			});
		},

		setupResetModal : function(){
			$("#modal_reset .reset-confirmation").show();
			$("#modal_reset .reset-sent").hide();
			$("#modal_reset button.entitlement-reset").button("reset");
			$("#modal_reset .reset-spinner").addClass("hide");
			$("#modal_reset button.cancel").show();
		},

		resetConfirmed : function(){
			$("#modal_reset").modal("hide");
			this.returnToClass();
		},

		afterReset : function(){
			if(this.vmConsoleView) {
				this.vmConsoleView.displayState(this.vmConsoleView.UNAVAILABLE);
				this.vmConsoleView.unload();
			}
			this.activeConsoleId = null;
			this.model.unset("vms");
			this.model.set("phase", "reset");
		},

		screenTicketUpdated : function(){
			this.class.set({
				broadcastingEntitlement : this.model.get("id"),
				activeScreenTicket      : this.model.get("activeScreenTicket"),
				activeVmx               : this.model.get("activeVmx")
			});
		},

		vAppReset : function(){
			App.mainView.generalMessage({
				id          : "class_updated_message",
				message     : App.i18n("instructor.entitlement.vAppReset"),
				type        : "info",
				removeDelay : 5000,
				dismissible : true
			});
			this.returnToClass();
		},

		setupTakeover : function(){
			if(this.model.get("takenOver")){
				this.$(".take-over").button("taken");
				this.$(".take-over").addClass("btn-warning");
			} else{
				this.$(".take-over").button("reset");
				App.mainView.removeGeneralMessage("instructor_viewing_message");
				this.$(".take-over").removeClass("btn-warning");
			}
		},

		setupBroadcasting : function(){
			var self = this;

			if(this.class.get("broadcastingDisplay")){
				this.$(".broadcast-display").button("broadcasting");
				this.$(".broadcast-display").addClass("btn-warning");
				this.$(".broadcast-display").prop("disabled", false);
			} else{
				this.$(".broadcast-display").button("reset");
				this.$(".broadcast-display").removeClass("btn-warning");
				this.$(".broadcast-display").prop("disabled", this.model.get("activeConsoleIdType") !== "vm");
				setTimeout(function(){
					//setTimeout needed because of button("reset") also uses setTimeout and sets disabled
					self.$(".broadcast-display").prop("disabled", self.model.get("activeConsoleIdType") !== "vm");
				});
			}
		},

		remove : function(){
			this.model.set("instructorViewing", false);
			$(window).off("beforeunload", this.cleanupConnections);
			$(document).off("click",  ".entitlement-reset", this.reset);
			$(document).off("click", ".entitlement-reset-confirmed", this.resetConfirmed);
			$(document).off("show",   "#modal_reset", this.setupResetModal);
			$("#vm_listing").off("click.classEntitlement", ".vm", this.switchConsole);
			App.VlpGlobal.setActiveConsoleView(null);
			this.cleanupConnections();
			BaseView.prototype.remove.apply(this, arguments);
		},


		getPanelId: function(panelName){
			if(panelName === "consoles"){
				panelName = "vm_listing";
			} else if (panelName === "manual"){
				panelName = "instructions";
			} else if (panelName === "zoom"){
				panelName = "video_conference";
			}
			return "#"+ panelName + "_panel";
		},
		closePanelByName : function(panelName){
			if(!this.panelManager) { return; }
			var panel = this.panelManager.getPanel(this.getPanelId(panelName));
			if(panel){
				panel.close();
			}
		},
		openPanelByName : function(panelName){
			if(!this.panelManager) { return; }
			var panel = this.panelManager.getPanel(this.getPanelId(panelName));
			if(panel){
				panel.expand();
				panel.setFocus();
			}
		},
		togglePanelByName : function(panelName){
			if(!this.panelManager) { return; }
			var panel = this.panelManager.getPanel(this.getPanelId(panelName));
			if(panel){
				panel.focusOrToggleExpand();
			}
		},
		hidePanelByName : function(panelName){
			if(!this.panelManager) { return; }
			var panel = this.panelManager.getPanel(this.getPanelId(panelName));
			if(panel){
				panel.hide();
			}
		},
		showPanelByName : function(panelName){
			if(!this.panelManager) { return; }
			var panel = this.panelManager.getPanel(this.getPanelId(panelName));
			if(panel){
				panel.show();
			}
		},
		closeAllPanels : function(position){
			if(!this.panelManager) { return; }
			var panels = this.panelManager.getPanels(position);
			if(panels){
				_.each(panels, function(panel){panel.close();});
			}
		},
		openAllPanels : function(position){
			if(!this.panelManager) { return; }
			var panels = this.panelManager.getPanels(position);
			if(panels){
				_.each(panels, function(panel){panel.expand();});
			}
		},
		onTogglePanelLeft : function(){
			this.togglePanel("left");
		},
		onTogglePanelRight : function(){
			this.togglePanel("right");
		},
		togglePanel : function(position){
			this.$(".toggle-panel-" + position).tooltip("hide");
			var $dock = $(".panels .dock-" + position);
			if($dock.find(".panel.expanded.focused").length > 0){
				$dock.find(".panel.expanded.focused .open-close-panel").click();
			} else if($dock.find(".panel.expanded").length > 0){
				$dock.find(".panel.expanded .open-close-panel").click();
			} else if($dock.find(".collapsed.focused").length > 0){
				$dock.find(".panel.collapsed.focused:first .open-close-panel").click();
			} else {
				$dock.find(".panel.collapsed:first .open-close-panel").click();
			}
		},
		getTogglePanelTitle : function(position){
			var showHide = "show";
			if($("body").hasClass(position + "-panel-expanded")){
				showHide = "hide";
			}
			var $dock = $(".dock-" + position);

			var panelType = "";
			if($dock.find("#instructions_panel,#vm_listing_panel,#help_request_instructor_panel").length == 3) {
				panelType = "All";
			} else if($dock.find("#instructions_panel,#vm_listing_panel").length == 2){
				panelType = "ManualAndVmChooser";
			} else if($dock.find("#instructions_panel,#help_request_instructor_panel").length == 2){
				panelType = "ManualAndHelpRequest";
			} else if($dock.find("#vm_listing_panel,#help_request_instructor_panel").length == 2){
				panelType = "VmChooserAndHelpRequest";
			} else if($dock.find("#instructions_panel").length == 1){
				panelType = "Manual";
			} else if($dock.find("#vm_listing_panel").length == 1){
				panelType = "VmChooser";
			} else if($dock.find("#help_request_instructor_panel").length == 1){
				panelType = "HelpRequest";
			}
			var titleKey = "console.vm.togglePanel." + showHide + panelType + "Panel";
			return App.i18n(titleKey);
		}
	});

});

