/**
 * Verify Account main Backbone View
 */
define('views/verify-account/main',['require','underscore','library/vlp/app','views/vlp-base','views/common/security-prompts','views/common/layout-tpls','hbs!tpls/verify-account/main.handlebars','i18n!nls/verify-account'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//class dependencies
	var App                 = require("library/vlp/app"),
	    VlpBaseView         = require("views/vlp-base"),
	    SecurityPromptsView = require("views/common/security-prompts"),
	    LayoutTPLs          = require("views/common/layout-tpls"),
	    mainTPL             = require("hbs!tpls/verify-account/main.handlebars"),
	    pageTexts           = require("i18n!nls/verify-account");

	return VlpBaseView.extend({
		name : "VERIFY_ACCOUNT",
		checkLoggedIn   : false,
		forceLogout     : true,
		checkTenantPath : false,
		className : "general-page default-layout",

		layoutTemplate : LayoutTPLs.layoutNoLogin,
		//Required
		mainTemplate   : mainTPL,

		events : {
			"click button.new-token"      : "newToken",
			"click button.enter-token"    : "enterToken",
			"submit #enter_token_form"    : "manualTokenSubmitted"
		},
		modelBindings : {
			"#token" : "token"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);

			this.question = {};

			this.once(VlpBaseView.STARTUP_COMPLETE, function(){
				this.model = App.user;
				this.updateBindings();
				this.render();
				if(App.user && App.user.get("token")){
					this.verifyAccount();
				} else{
					this.enterToken();
				}
			}, this);

			App.locale.mergeI18n(pageTexts);
			App.page = this;
			//Checks logged in
			this.setup(options);

		},

		/**
		 * Display the content.
		 */
		render : function(referringPage){
			this.$(".page-content").show();

			//Always return this for chaining
			return this;
		},
		setToken : function(token){
			App.user.set("token", encodeURIComponent(token));
		},
		showSection : function(section){
			this.$("#enter_token").toggle(section === "enter_token");
			this.$("#checking_token").toggle(section === "checking_token");
			this.$("#new_token").toggle(section === "new_token");
			this.$("#invalid_token").toggle(section === "invalid_token");
			this.$("#security_prompts_holder").toggle(section === "security_prompts_holder");
		},
		verifyAccount : function(){
			this.showSection("checking_token");

			App.user.verifyAccount({
				success : this.showSecurityPrompts,
				error   : this.showInvalidToken
			});
		},
		enterToken : function(){
			App.user.set("token", "");
			this.showSection("enter_token");
		},
		manualTokenSubmitted : function(event){


			if(event){
				event.preventDefault();
			}

			//Validate and reject if invalid
			if(this.validateModel(App.user, {token : { required : true}}, {displayErrors: true})){
				return;
			}
			this.verifyAccount();
		},
		showSecurityPrompts : function(){
			this.showSection("security_prompts_holder");

			App.user.set("setPassword", true);
			App.user.set("passwordCurrent", "verifyAccount");
			if(!this.securityPromptsView){
				this.securityPromptsView = new SecurityPromptsView({
					model : App.user,
					el    : this.$("#security_prompts_holder"),
					token : App.user.get("token")
				});
			}
			this.securityPromptsView.off("securityCompleted", this.returnHome).on("securityCompleted", this.returnHome);
			this.securityPromptsView.off("cancelled", this.cancelled).on("cancelled", this.cancelled);
			this.securityPromptsView.render({});
			App.analytics.trackEvent("Account", "Verify", "Verified");
		},

		showInvalidToken : function(response){
			try{
				response = JSON.parse(response.responseText);
			} catch(error){
				response = { };
			}
			this.$("#invalid_token .response").html(_.escapeHTML(response.message));
			this.$(".new-token").button("reset");
			this.showSection("invalid_token");
			App.analytics.trackEvent("Account", "Verify", "Invalid");
		},
		newToken : function(){

			this.$(".new-token").button("loading");
			App.user.newToken({
				success : this.showNewTokenSuccess,
				error   : this.showInvalidToken
			});

		},

		showNewTokenSuccess : function(){
			this.showSection("new_token");
		},
		returnHome : function(){
			App.redirect({page: App.config.pages.HOME});
		},
		cancelled : function(){
			App.user.logout({
				success : this.returnHome,
				error : this.returnHome,
				force : true
			});
		}
	});

});

