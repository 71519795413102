/* globals dateFormat*/
define('models/entitlement',['require','exports','module','underscore','backbone','library/utilities/file-info','library/vlp/app','library/vlp/model','library/vlp/collection','models/lab','models/course','models/vm','models/manual','models/survey','models/account','models/instructor-help-request','library/utilities/days-hours-minutes-label'],function (require, exports) {
	"use strict";

	//library dependencies
	var _        = require("underscore"),
	    Backbone = require("backbone"),
	    fileInfo = require("library/utilities/file-info");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection"),
	    Lab            = require("models/lab"), //jshint ignore:line
	    Course         = require("models/course"), //jshint ignore:line
	    Vm             = require("models/vm"), //jshint ignore:line
	    Manual         = require("models/manual"), //jshint ignore:line
	    Survey         = require("models/survey"),
	    Account        = require("models/account"), //jshint ignore:line
	    InstructorHelpRequest = require("models/instructor-help-request"); //jshint ignore:line

	var daysHoursMinutesLabel = require("library/utilities/days-hours-minutes-label");

	//Use exports for requirejs circular dependency resolving
	var Entitlement = exports;

	//Add Entitlement to the App.Models namespace
	App.Models.Entitlement = Entitlement;

	//Class Constants
	Entitlement.STARTED         = "started";
	Entitlement.INCOMPLETE      = "incomplete";
	Entitlement.ACTIVE          = "active";
	Entitlement.READY           = "ready";
	Entitlement.CANCELED        = "canceled";
	Entitlement.COMPLETED       = "completed";
	Entitlement.GONE            = "gone";
	Entitlement.AWAITING_GRADE  = "awaitingGrade";
	Entitlement.SUSPENDED       = "suspended";
	Entitlement.EXPIRED         = "expired";

	Entitlement.Model = BaseModel.extend({
		urlRoot : "/entitlements",
		pushService : "entitlements",
		studentClassSyncPropertyTriggers : [
			"state", "status", "timeAllotted", "extensionCount", "completionStatusForLabCriteria",
			"extensionsRemaining", "activeStepNumber", "totalSteps", "raisedHand"
		],
		studentClassSyncImmediatePropertyTriggers : [
			"phase"
		],
		studentSupportSyncPropertyTriggers : [
			"state", "status", "timeAllotted", "extensionCount",
			"extensionsRemaining", "activeStepNumber", "totalSteps"
		],
		studentSupportSyncImmediatePropertyTriggers : [
			"phase"
		],
		studentSyncConsoleMonitoringPropertyTriggers : [
			"consoleLatencyCurrent", "consoleLatencyAverage", "consoleLatencyHigh", "consoleLatencyLow", "consoleLatencyWarning"
		],
		studentSyncConsoleMonitoringImmediatePropertyTriggers : [
			"consoleLatencyUnstable", "consoleConnectionLost"
		],
		supportSyncPropertyTriggers : [
			"supportViewing"
		],

		defaults : {
			expirationDate      : "",
			completionDateTime  : "",
			language            : "English",
			location            : "NASA-West",
			progress            : 0,
			status              : "incomplete",
			timeAllotted        : 0,
			timeElapsed         : 0,
			timeRemaining       : 0,
			labTimeRemaining    : 0,
			hideRemainingTime   : false,
			type                : "lab",
			networkTopology     : "",
			bookmarks           : [],
			endAllowed          : true,
			exitAllowed         : true,
			resetAllowed        : true,
			remoteControlMode   : false,
			phase               : "notStarted",
			raisedHand          : false,
			fetchThumbnails     : true,
			canExtend           : false,
			extensionCount      : 0,
			extensionsRemaining : 0,
			requiresGrading     : false,

			manualShortcuts                      : true,
			fixANSI                              : false,
			interceptPaste                       : false,
			macOsRemapCommandToControl           : true,
			keyboardLayoutId                     : null,
			reverseScrollY                       : false,
			savePanelPositions                   : false,
			fixANSIPromptShown                   : false,
			interceptPastePromptShown            : false,
			macOsRemapCommandToControlPromptShown: false,

			activeConsoleId : null,
			activeConsoleIdType : "vm",
			activeConsoleIdNumber : null,

			totalSteps         : 0,
			activeStepNumber   : 0,
			screenStatus       : "normal",
			instructorViewing  : false,
			supportViewing     : false,
			broadcastingDisplay: false,
			takenOver          : false,
			contentAccessKey   : "",

			requiredMinimumTimeSpent      : 0,
			requiredStepsVisitedPercentage: 0,
			manualStepCount               : 0,
			manualStepsVisited            : null,
			startTime                     : 0,
			startTimeRemaining            : 0,
			completionStatusForLabCriteria: "",
			activeStepBookmarkComplete    : false,

			consoleConnectionMonitoring   : false,
			showConsoleConnectionStrength : true,

			consoleLatencyCount         : 0,
			consoleLatencyCurrent       : 0,
			consoleLatencyAverage       : 0,
			consoleLatencyHigh          : 0,
			consoleLatencyLow           : Number.MAX_VALUE,
			consoleLatencyPreviousValues: [],
			consoleLatencyUnstable      : false,
			consoleLatencyWarning       : false,
			consoleConnectionLost       : false,

			studentConsoleLatencyCount         : 0,
			studentConsoleLatencyCurrent       : 0,
			studentConsoleLatencyAverage       : 0,
			studentConsoleLatencyHigh          : 0,
			studentConsoleLatencyLow           : Number.MAX_VALUE,
			studentConsoleLatencyPreviousValues: [],
			studentConsoleLatencyUnstable      : false,
			studentConsoleLatencyWarning       : false,
			studentConsoleConnectionLost       : false,

			resources: [],
			configProperties: {},
			liveChat                      : false,
			liveChatUrl                   : ""
		},

		types: {
			canExtend                     : Backbone.Types.Boolean,
			consoleConnectionMonitoring   : Backbone.Types.Boolean,
			showConsoleConnectionStrength : Backbone.Types.Boolean,
			deployTime                    : Backbone.Types.Number,
			endAllowed                    : Backbone.Types.Boolean,
			exitAllowed                   : Backbone.Types.Boolean,
			resetAllowed                  : Backbone.Types.Boolean,
			remoteControlMode             : Backbone.Types.Boolean,
			extensionCount                : Backbone.Types.Number,
			extensionsRemaining           : Backbone.Types.Number,
			fetchThumbnails               : Backbone.Types.Boolean,
			requiresGrading               : Backbone.Types.Boolean,
			hideRemainingTime             : Backbone.Types.Boolean,
			timeAllotted                  : Backbone.Types.Number,
			timeElapsed                   : Backbone.Types.Number,
			requiredMinimumTimeSpent      : Backbone.Types.Number,
			requiredStepsVisitedPercentage: Backbone.Types.Number,
			manualStepCount               : Backbone.Types.Number,
			liveChat                      : Backbone.Types.Boolean,
			liveChatUrl                   : Backbone.Types.String
		},

		relations : [
			{
				key          : "lab",
				type         : Backbone.HasOne,
				relatedModel : "Lab.Model"
			},
			{
				key          : "course",
				type         : Backbone.HasOne,
				relatedModel : "Course.Model"
			},
			{
				key            : "vms",
				type           : Backbone.HasMany,
				relatedModel   : "Vm.Model",
				collectionType : "Vm.Collection"
			},
			{
				key          : "manual",
				type         : Backbone.HasOne,
				relatedModel : "Manual.Model"
			},
			{
				key          : "account",
				type         : Backbone.HasOne,
				relatedModel : "Account.Model"
			},
			{
				key          : "instructorHelpRequest",
				type         : Backbone.HasOne,
				relatedModel : "InstructorHelpRequest.Model"
			}
		],

		setters : {
			networkTopology : function(value) {
				if(!value) { return value; }
				if(value.match(/https?:/)) { return value; }

				if(value.match(/^\//)){
					var server = this.serverBase.match(/^https?:\/\/[^\/]+/)[0];
					value = server + value;
				} else{
					value = this.serverBase + value;
				}
				return value;
			},
			expirationTimeRemaining : function(value, options){
				value = Backbone.Types.Number(value);
				this.set("localExpirationDate", new Date(Date.now() + value * 1000), options);
				return value;
			},
			remoteToken : function(value) {
				return _.trim(value);
			},
			completionStatus : function(value, options){
				if(value === "complete"){ value = Entitlement.COMPLETED; }
				this.set("status", value, options);
				return value;
			},
			endType : function(value, options){
				this.set("status", value, options);
				return value;
			},
			activeStepNumber : function(value){
				if(!this.get("manualStepsVisited")){
					this.set("manualStepsVisited", []);
				}
				if(this.get("manualStepsVisited").indexOf(value) === -1) {
					this.get("manualStepsVisited").push(value);
				}
				return value;
			},
			labTimeRemaining: function(value) {
				value = Math.max(0, value);
				var progress = 0;
				if(this.get("timeAllotted")){
					progress = 1 - (value / (this.get("timeAllotted") * 60));
					if(progress < 0){
						progress = 0;
					} else if(progress > 1){
						progress = 1;
					}
				}
				this.set("progress", progress * 100);
				return value;
			},
			resources : function(value){
				if(!value){
					return new BaseCollection();
				}

				var tempValue =_.clone(value);
				for (var i = 0; i < tempValue.length; i++) {
					var resource = tempValue[i];
					var resourceInfo = fileInfo(resource.path);
					resource.type = resourceInfo.type;
					resource.icon = resourceInfo.icon;

					if(resource.path){
						resource.path = _.sanitizeURL(resource.path + (resource.path.indexOf("?") !== -1 ? "&" : "?") + "entitlementId=" + this.get("id"));
					}
					resource.page = this.getResourceBookmarkPage(resource.id);
				}
				return new BaseCollection(tempValue);
			},
			timeElapsed : function(value){
				if(!this.attributes.hasOwnProperty("startTimeElapsed")){
					this.attributes.startTimeElapsed = value;
				}

				var labTimeElapsedDiff = (this.get("labTimeElapsed") / 60000) - value;
				if(Math.abs(labTimeElapsedDiff) > 1){
					this.attributes.startTimeElapsed-= (labTimeElapsedDiff > 0 ? Math.floor(labTimeElapsedDiff) : Math.ceil(labTimeElapsedDiff));
				}
				return value;
			},
			activeConsoleId : function(value, options){
				if(value === undefined || value === ""){
					value = null;
				}
				var consoleType = "vm", consoleId = null;

				if(value !== null){
					var consoleIdParts = String(value).split("-");
					if(consoleIdParts.length === 2) {
						consoleType = consoleIdParts[0];
						consoleId = parseInt(consoleIdParts[1], 10);
						if(!consoleType.match(/vm|saas/)){
							console.warn("invalid activeConsoleId value", value);
							consoleType = "vm";
						}
					} else if(consoleIdParts.length === 1){
						consoleId = parseInt(consoleIdParts[0], 10);
					}
					if(isNaN(consoleId)){
						console.warn("invalid activeConsoleId value", value);
						consoleId = null;
						value = null;
					} else {
						value = consoleType + "-" + consoleId;
					}
				}


				this.set("activeConsoleIdType", consoleType, options);
				this.set("activeConsoleIdNumber", consoleId, options);
				return value;
			}
		},

		getters : {
			consoleConnectionMonitoring : function(){
				return App.config.consoleConnectionMonitoring && this.attributes.consoleConnectionMonitoring;
			},
			studentConsoleConnectionMonitoring : function(){
				return this.get("consoleConnectionMonitoring");
			},
			showConsoleConnectionStrength : function(){
				return this.get("consoleConnectionMonitoring") && App.config.showConsoleConnectionStrength;
			},
			userEmail : function(){
				return App.user.get("email");
			},
			displayName : function(){
				return this.get("account") && !_.isString(this.get("account")) ? this.get("account").get("displayName") : this.get("username");
			},

			state : function(){
				if(this.attributes.status === Entitlement.ACTIVE || this.attributes.status === Entitlement.GONE){
					return Entitlement.STARTED;
				} else if(this.attributes.status !== Entitlement.INCOMPLETE && this.attributes.status !== Entitlement.READY){
					return this.attributes.status;
				} else if(this.attributes.timeElapsed > 0){
					return Entitlement.STARTED;
				} else if(this.attributes.progress > 0){
					return Entitlement.STARTED;
				} else{
					return Entitlement.INCOMPLETE;
				}
			},

			status : function(){
				return this.attributes.endType || this.attributes.status || this.attributes.completionStatus || this.defaults.status;
			},

			remoteUrl : function(){
				return App.makeUrl({page : App.config.pages.REMOTE_CONTROL, action: "remoteToken/" + this.get("remoteToken"), forceHost : true});
			},
			remoteMailToUrl : function(){
				return "mailto:?subject=" + encodeURIComponent(App.i18n("console.remoteToken.email.title", this.id)) + "&body=" + encodeURIComponent(App.i18n("console.remoteToken.email.title", this.id) + ": " + this.get("remoteUrl"));
			},

			thumbnail : function(){
				if(this.attributes.hasOwnProperty("thumbnail") && this.attributes.thumbnail){
					return this.attributes.thumbnail;
				}
				if(this.has("lab")){
					return this.get("lab").get("thumbnail");
				}
				return App.config.defaultLabThumbnail;
			},

			saasConsoles : function(){
				if(this.has("lab")){
					return this.get("lab").get("saasConsoles") || [];
				}
				return [];
			},
			saasConsoleOnly : function(){
				if(this.has("lab") && this.get("lab").has("saasConsoleOnly")){
					return this.get("lab").get("saasConsoleOnly");
				}
				return (this.get("saasConsoles").length > 0 && this.get("vms").length === 0);
			},
			hasConsoles : function(){
				return (this.get("saasConsoles").length > 0 || this.get("vms").length > 0);
			},
			consoles : function(){
				if(!this.attributes.consoles){
					this.attributes.consoles = new BaseCollection();
				}
				var consoles = [];
				if(this.get("vms").length > 0){
					consoles = consoles.concat(this.get("vms").models);
				}
				if(this.get("saasConsoles").length > 0){
					consoles = consoles.concat(this.get("saasConsoles").models);
				}
				this.attributes.consoles.set(consoles);
				return this.attributes.consoles;
			},
			integrationCode : function(){
				if(this.has("lab")){
					return this.get("lab").get("integrationCode");
				}
				return null;
			},

			screenshot : function(){
				if(this.attributes.hasOwnProperty("screenshot") && this.attributes.screenshot){
					return this.attributes.screenshot;
				}
				if(this.has("lab")){
					return this.get("lab").get("screenshot");
				}
				return null;
			},

			rating : function(){
				if(this.has("lab")){
					return this.get("lab").get("ratingAvg");
				}
				return 0;
			},

			ratingAvg : function(){
				if(this.has("lab")){
					return this.get("lab").get("ratingAvg");
				}
				return 0;
			},

			name : function(){
				if(this.has("lab")){
					return this.get("lab").get("name");
				}
				return this.get("id");
			},

			sku : function(){
				if(this.has("lab")){
					return this.get("lab").get("sku");
				}
				return this.attributes.sku || this.attributes.labSku;
			},

			groupName : function(){
				if(this.has("lab")){
					return this.get("lab").get("groupName");
				}
				return this.attributes.groupName;
			},

			groupColor : function(){
				if(this.has("lab")){
					return this.get("lab").get("groupColor");
				}
				return this.attributes.groupColor;
			},

			hasRating : function(){
				if(this.has("lab")){
					return this.get("lab").get("hasRating");
				}
				return false;
			},

			hasManual : function(){
				return this.get("manualId") || !!this.get("pdfManualResource");
			},
			pdfManualResource : function(){
				if(!this.get("resources") || this.get("resources").length === 0|| this.get("manualId")){
					return null;
				}

				for (var i = 0; i < this.get("resources").length; i++) {
					var resource = this.get("resources").at(i);
					if (resource.get("type") === "pdf") {
						return resource;
					}
				}
				return null;
			},
			expirationTime : function(){
				var now = new Date();
				if(this.has("localExpirationDate")){
					return Math.floor((this.attributes.localExpirationDate - now) / 60000);
				} else if(this.attributes.hasOwnProperty("expirationDate") && this.attributes.expirationDate){
					var expirationDate = new Date(this.attributes.expirationDate);
					return Math.floor((expirationDate - now) / 60000);
				}

				return null;
			},

			expirationTimeLabel : function(){
				if(!this.get("expirationDate")){
					return "";
				}
				var label = daysHoursMinutesLabel(this.get("expirationTime"), {roundToDays : true});

				if(!label){
					label = App.i18n("catalogs.labs.expired");
				}
				return label;
			},

			labTimeRemainingDisplay : function(){
				var hms = _.secondsToDHMS(Math.max(0, this.attributes.labTimeRemaining));
				if(hms.days > 0){
					return daysHoursMinutesLabel(this.get("timeRemaining"));
				}
				return App.i18n("timeRemainingFormat", { hash : hms});
			},

			labTimeElapsed : function(){
				var now = Date.now();
				var startTime = this.attributes.startTime || Date.now();
				var timeDiff = (now - startTime);
				var timeElapsed = this.attributes.hasOwnProperty("startTimeElapsed") ?
					this.attributes.startTimeElapsed :
					this.attributes.timeElapsed;
				return (timeElapsed * 60000) + timeDiff;
			},

			manualStepsVisitedCount : function(){
				if(!this.get("manualStepsVisited")){
					return this.attributes.manualStepsVisitedCount || 0;
				} else {
					return this.get("manualStepsVisited").length;
				}
			},

			requiredMinimumTimeSpentMet : function(){
				return this.get("requiredMinimumTimeSpent") * 60000 <= this.get("labTimeElapsed");
			},
			requiredStepsVisitedPercentageMet : function(){
				return this.get("stepVisitedPercent") >= this.get("requiredStepsVisitedPercentage");
			},
			stepVisitedPercent : function(){
				if(!this.get("manualStepCount")) { return 0; }
				return (this.get("manualStepsVisitedCount") / this.get("manualStepCount")) * 100;
			},

			hasLabCompletionCriteria : function(){
				return this.get("requiredStepsVisitedPercentage") > 0 || this.get("requiredMinimumTimeSpent");
			},
			completionCriteriaMet : function(){
				return this.get("requiredMinimumTimeSpentMet") && this.get("requiredStepsVisitedPercentageMet");
			},

			timeRemaining : function(){
				var value = this.defaults.timeRemaining;
				if(this.attributes.hasOwnProperty("timeAllotted")){
					value = this.attributes.timeAllotted;
				}
				if(isNaN(value) || value === null && this.has("lab")){
					value = this.get("lab").get("timelimit");
				}
				value = value - this.attributes.timeElapsed;

				var now = new Date();
				var endDate = new Date(now.getTime() + (value * 60000));
				if(this.attributes.hasOwnProperty("localExpirationDate")){
					if(this.attributes.localExpirationDate < endDate){
						value = (this.attributes.localExpirationDate - now) / 60000;
					}
				} else if(this.attributes.hasOwnProperty("expirationDate")){
					var expirationDate = new Date(this.attributes.expirationDate);
					if(expirationDate < endDate){
						value = (expirationDate - now) / 60000;
					}
				}
				return Math.max(value, 0);
			},

			timeRemainingLabel : function(){
				var label = daysHoursMinutesLabel(this.get("timeRemaining"));
				if(!label){
					label = App.i18n("catalogs.labs.expired");
				}
				return label;
			},

			survey : function(){
				if(this.attributes.survey){
					return this.attributes.survey;
				}
				this.attributes.survey = new Survey.Model({
					entitlementId : this.id
				});
				return this.attributes.survey;
			},

			userActionAllowed : function(){
				if(this.attributes.hasOwnProperty("userActionAllowed")) {
					return this.attributes.userActionAllowed;
				}
				return this.attributes.screenStatus === "normal" && !this.attributes.takenOver;
			}
		},

		initialize : function(){
			_.bindAll(this,
				"labSet", "resetStudentConsoleLatency",
				"classPushUpdate", "studentClassPushUpdate", "studentClassEntitlementUpdated", "studentClassUpdated", "_doStudentClassPushUpdate",
				"studentSupportPushUpdate", "studentSupportEntitlementUpdated", "_doStudentSupportPushUpdate",
				"remoteActiveConsoleChanged", "mainConsolePushUpdateToRemote"
			);

			if(!this.get("keyboardLayoutId")){
				this.set("keyboardLayoutId", App.config.consoleKeyboardDefaultLayout);
			}
			this.on("change:lab", this.labSet);
			this._throttledStudentClassPushUpdate          = _.throttle(this.studentClassPushUpdate,   1000);
			this._throttledStudentClassLatencyPushUpdate   = _.throttle(this.studentClassPushUpdate,   5000);
			this._throttledStudentSupportPushUpdate        = _.throttle(this.studentSupportPushUpdate, 1000);
			this._throttledStudentSupportLatencyPushUpdate = _.throttle(this.studentSupportPushUpdate, 5000);
			this._throttledStudentSupportLatencyPushUpdate = _.throttle(this.studentSupportPushUpdate, 5000);
		},

		labSet : function(model, lab){
			if(lab.has("exitAllowed") && !lab.get("exitAllowed")){
				this.set("exitAllowed", false);
			}
			if(lab.has("endAllowed") && !lab.get("endAllowed")){
				this.set("endAllowed", false);
			}
			if(lab.has("resetAllowed") && !lab.get("resetAllowed")){
				this.set("resetAllowed", false);
			}
			if(lab.has("remoteControlMode") && lab.get("remoteControlMode")){
				this.set("remoteControlMode", true);
			}
			if(lab.has("resources") && lab.get("resources")){
				this.set("resources", lab.get("resources"));
			}
		},

		parse : function(){
			var result = BaseModel.prototype.parse.apply(this, arguments);
			// 'objectKeysLimit' is hacky way of allowing the bootstrap of the console to complete
			// when the VM objects returned from core with the /start call contain the extra fields for
			// 'networkName' and 'networkIp'.
			var objectKeysLimit = App.config.showVmNetworkInfo ? 5 : 3;
			if(result.vms && _.isArray(result.vms) && result.vms.length && _.isObject(result.vms[0]) && _.keys(result.vms[0]).length < objectKeysLimit){
				result._returnedVMs = result.vms;
				result.vms = _.map(result.vms, function(item){ return item.id; });
			}

			if(result.itemId && !result.entitlementKey){
				result.entitlementKey = result.itemId;
			}
			if(result.itemId && !result.id){
				result.id = result.itemId;
				delete result.itemId;
			}

			if(result.entitlement_id && !result.id){
				result.id = result.entitlement_id;
				delete result.entitlement_id;
			}

			if(result.entitlementKey && (!result.id || _.isNumber(result.id))){
				result.id = result.entitlementKey;
			}
			if(result.bookmarks && _.isObject(result.bookmarks)){
				result.bookmarks = [result.bookmarks];
			}

			if(result.account && _.isString(result.account) && result.account.indexOf("@") != -1){
				result.username = result.account;
				result.account = {
					username: result.username,
					displayName: result.username
				};
			}

			if(result.status && result.status === "gone"){
				result.status = Entitlement.INCOMPLETE;
			}

			return result;
		},

		start : function(options){

			options = options || {};
			if(this.isNew()){
				throw new Error("Cannot start a new Entitlement");
			}

			options = options || {};
			options.properties = [];
			options.params = options.params || {};
			options.params["with"] = "account";
			return this.action("start", options);
		},

		reset : function(options){

			if(this.isNew()){
				throw new Error("Cannot reset a new Entitlement");
			}

			options = options || {};
			options.properties = [];
			return this.action("reset", options);
		},

		end : function(options){

			if(this.isNew()){
				throw new Error("Cannot end a new Entitlement");
			}

			options = options || {};
			options.properties = [];
			options.pushReturn = true;
			return this.action("end", options);
		},

		exit : function(options){

			options = options || {};
			if(this.isNew()){
				throw new Error("Cannot exit a new Entitlement");
			}

			//options.async = false;
			options = options || {};
			options.properties = [];
			options.pushReturn = true;
			return this.action("exit", options);
		},

		gone : function(options){

			options = options || {};
			if(this.isNew()){
				throw new Error("Cannot exit a new Entitlement");
			}

			options = options || {};
			options.properties = [];
			return this.action("gone", options);
		},

		expire : function(options){

			options = options || {};
			if(this.isNew()){
				throw new Error("Cannot exit a new Entitlement");
			}

			options = options || {};
			options.properties = [];
			return this.action("expired", options);
		},

		progress : function(options){

			options = options || {};
			options.method = "read";
			options.properties = [];
			options.params = options.params || {};
			options.params.phase = this.get("phase");
			//options.pushReturn = true;
			options.pushSync = true;
			options.pushBroadcast = true;
			return this.action("progress", options);
		},

		extendTime : function(options){

			options = options || {};
			options.properties = [];
			return this.action("extendTime", options);
		},

		updateNotification: function (success, options) {
			options = options || {};
			options.data = {
				success: success
			};
			options.pushSync = false;
			options.pushBroadcast = true;
			options.local = true;
			return this.action("updateNotification", options);
		},

		deleteEntitlementFromQueue : function (options) {
			options = options || {};
			options.pushSync = false;
			options.pushBroadcast = true;
			options.local = true;
			return this.action("deleteEntitlementFromQueue", options);
		},

		leaveQueue: function(options){
			options = options || {};
			options.pushSync = false;
			options.pushBroadcast = true;
			options.local = true;
			return this.action("leaveQueue", options);
		},

		refresh : function(options){

			options = options || {};
			options.method = "update";
			options.pushSync = true;
			options.pushBroadcast = true;
			options.local = true;
			return this.action("refresh", options);
		},

		remoteToken : function(options){

			options = options || {};
			options.method = "read";
			options.properties = [];
			return this.action("remoteToken", options);
		},

		consoleLatency : function(status, options){

			if(!this.get("consoleConnectionMonitoring")){ return; }
			if(this.get("consoleLatencyCount") <= 0){ return; }

			var startDate = this.get("startDate") || new Date();
			options = options || {};
			options.properties = [];
			options.data = {
				average   : this.get("consoleLatencyAverage"),
				high      : this.get("consoleLatencyHigh"),
				low       : this.get("consoleLatencyLow"),
				startDate : dateFormat(startDate, "UTC:yyyy-mm-dd\"T\"HH:MM:ss\"Z\""),
				status    :  status
			};
			//Reset to prevent multiple sends with same data
			this.set({
				consoleLatencyCount   : 0,
				consoleLatencyAverage : 0,
				consoleLatencyLow     : 0,
				consoleLatencyHigh    : 0
			});
			return this.action("consoleLatency", options);
		},

		getBookmarkId : function(tag){
			if(!this.has("bookmarks") || this.get("bookmarks").length === 0) { return null; }

			if(!tag){
				for(var i in this.attributes.bookmarks){
					if(this.attributes.bookmarks.hasOwnProperty(i) && this.attributes.bookmarks[i].masterPlaceHolder){
						return this.attributes.bookmarks[i].nodeId;
					}
				}
			}
			return null;
		},

		getResourceBookmarkPage : function(resourceId) {
			if(!this.has("bookmarks") || this.get("bookmarks").length === 0) { return 0; }
			for(var i in this.attributes.bookmarks){
				var bookmark = this.attributes.bookmarks[i];
				if (_.isArray(bookmark)) {
					for(var j in bookmark){
						var bArrayElement = bookmark[j];
						if (bArrayElement && bArrayElement.name === "ResourceBookmark" && (!resourceId || bArrayElement.nodeId === resourceId.toString())) {
							return bArrayElement.stepNo;
						}
					}
				} else {
					if (bookmark && bookmark.name === "ResourceBookmark" && (!resourceId || bookmark.nodeId === resourceId.toString())) {
						return bookmark.stepNo;
					}
				}

			}
			return 0;
		},

		setResourceBookmarkPage: function (resourceId, pageNo, options) {
			options = options || {};
			options.properties = options.properties || [];
			options.params = options.params || {};
			options.data = {pageNo: pageNo, resourceId: resourceId};
			return this.action("setResourceBookmark", options);
		},

		pushSubscribe : function(){
			if(!App.push) { return; }

			var entitlementMessage = {
				service: "entitlement",
				channel: "/entitlement/" + this.get("id")
			};

			App.push.subscribe(entitlementMessage);
		},

		studentSupportPushSubscribe : function(){
			if(!App.push) { return; }

			if(this.studentSupportSubscribedId && this.studentSupportSubscribedId == this.get("id")){ return; }

			this.studentSupportSubscribedId = this.get("id");

			var entitlementMessage = {
				service: "supportEntitlement",
				channel: "/supportEntitlement/" + this.get("id")
			};

			App.push.subscribe(entitlementMessage);

			App.push.on("other:supportEntitlement:" + this.get("id") + ":supportSync", this.studentSupportPushUpdate);
			App.push.on("other:supportEntitlement:" + this.get("id") + ":supportUpdate", this.studentSupportEntitlementUpdated);

			var triggers = "change:" + this.studentSupportSyncPropertyTriggers.join(" change:");
			this.on(triggers, this._throttledStudentSupportPushUpdate);


			triggers = "change:" + this.studentSyncConsoleMonitoringPropertyTriggers.join(" change:");
			this.on(triggers, this._throttledStudentSupportLatencyPushUpdate);

			triggers = "change:" + this.studentSyncConsoleMonitoringImmediatePropertyTriggers.join(" change:");
			this.on(triggers, this.studentSupportPushUpdate);

			triggers = "change:" + this.studentSupportSyncImmediatePropertyTriggers.join(" change:");
			this.on(triggers, this._doStudentSupportPushUpdate);
		},
		studentSupportRequestSync : function(){
			if (!App.push) { return; }
			var message = {
				service      : "supportEntitlement",
				channel      : "/supportEntitlement/" + this.get("id"),
				operation    : "studentSync",
				data         : this.studentSupportPushData(),
				requestParams: {
					id: this.get("id")
				}
			};

			App.push.send(message);
		},
		studentSupportPushUpdate : function(inMessage){
			if (!App.push) { return; }
			if (inMessage) {
				this.studentSupportEntitlementUpdated(inMessage);
			}

			if (!this.get("supportViewing")) { return; }

			//in timeout in case multiple properties are updated at the same time
			setTimeout(this._doStudentSupportPushUpdate);

		},
		_doStudentSupportPushUpdate : function(inMessage){
			if (!App.push) { return; }

			if (!this.get("supportViewing")) { return; }

			var message = {
				service      : "supportEntitlement",
				channel      : "/supportEntitlement/" + this.get("id"),
				operation    : "studentUpdate",
				data         : this.studentSupportPushData(),
				requestParams: {
					id: this.get("id")
				}
			};
			App.push.send(message);
		},
		studentSupportEntitlementUpdated : function(message){
			if (message && message.data) {
				if (message.data.hasOwnProperty("supportViewing")) {
					this.set("supportViewing", message.data.supportViewing);
				}
			}
		},
		studentSupportPushData : function(){
			return {
				id                            : this.get("id"),
				state                         : this.get("state"),
				status                        : this.get("status"),
				phase                         : this.get("phase"),
				progress                      : this.get("progress"),
				timeAllotted                  : this.get("timeAllotted"),
				timeElapsed                   : this.get("timeElapsed"),
				extensionCount                : this.get("extensionCount"),
				completionStatusForLabCriteria: this.get("completionStatusForLabCriteria"),
				extensionsRemaining           : this.get("extensionsRemaining"),
				totalSteps                    : this.get("totalSteps"),
				raisedHand                    : this.get("raisedHand"),
				activeStepNumber              : (this.get("activeStepNumber") === 0 && this.get("totalSteps") === 0 ? 0 : this.get("activeStepNumber") + 1),
				labTimeRemaining              : this.get("labTimeRemaining"),
				studentConsoleLatencyCurrent  : this.get("consoleLatencyCurrent"),
				studentConsoleLatencyAverage  : this.get("consoleLatencyAverage"),
				studentConsoleLatencyHigh     : this.get("consoleLatencyHigh"),
				studentConsoleLatencyLow      : this.get("consoleLatencyLow") === Number.MAX_VALUE ? 0 : this.get("consoleLatencyLow"),
				studentConsoleLatencyUnstable : this.get("consoleLatencyUnstable"),
				studentConsoleLatencyWarning  : this.get("consoleLatencyWarning"),
				studentConsoleConnectionLost  : this.get("consoleConnectionLost")
			};
		},


		supportPushSubscribe : function(){
			if(!App.push) { return; }

			if(this.supportSubscribedId && this.supportSubscribedId == this.get("id")){ return; }
			this.supportSubscribedId = this.get("id");

			var entitlementMessage = {
				service: "supportEntitlement",
				channel: "/supportEntitlement/" + this.get("id")
			};

			App.push.subscribe(entitlementMessage);

			App.push.on("other:supportEntitlement:" + this.get("id") + ":studentSync", this.supportPushUpdate, this);
			App.push.on("other:supportEntitlement:" + this.get("id") + ":studentUpdate", this.supportEntitlementUpdated, this);

			var triggers = "change:" + this.supportSyncPropertyTriggers.join(" change:");
			this.on(triggers, this.supportPushUpdate, this);
		},
		/**
		 * Request sync of entitlement (not the class, see below) from instructor
		 */
		supportRequestSync : function(){
			if(!App.push) { return; }

			var message = {
				service      : "supportEntitlement",
				channel      : "/supportEntitlement/" + this.get("id"),
				operation    : "supportSync",
				data: this.supportPushData(),
				requestParams : {
					id : this.get("id")
				}
			};

			App.push.send(message);
		},
		supportPushUpdate: function (inMessage) {
			if(!App.push) { return; }

			if (inMessage) {
				this.supportEntitlementUpdated(inMessage);
			}

			var message = {
				service: "supportEntitlement",
				operation: "supportUpdate",
				channel: "/supportEntitlement/" + this.get("id"),
				data: this.supportPushData(),
				requestParams: {
					id: this.get("id")
				}
			};
			App.push.send(message);
		},
		supportEntitlementUpdated: function (message) {
			if (message && message.data) {
				this.set(message.data);
			}
		},
		supportPushData : function(){
			return {
				id            : this.get("id"),
				supportViewing: this.get("supportViewing")
			};
		},


		/*
		 * Class/Instructor related methods
		 */
		studentClassPushSubscribe : function(){
			if(!App.push) { return; }
			if (!this.get("classId")) { return; }

			if(this.studentClassSubscribedId && this.studentClassSubscribedId == this.get("classId")){ return; }
			this.studentClassSubscribedId = this.get("classId");

			var classMessage = {
				service      : "instructorClass",
				channel      : "/instructorClass/" + this.get("classId"),
				requestParams: {type: "instructorClass"}
			};

			App.push.subscribe(classMessage);

			var entitlementMessage = {
				service: "classEntitlement",
				channel: "/classEntitlement/" + this.get("id")
			};

			App.push.subscribe(entitlementMessage);

			App.push.on("other:classEntitlement:" + this.get("id") + ":instructorSync", this.studentClassPushUpdate);
			App.push.on("other:classEntitlement:" + this.get("id") + ":instructorUpdate", this.studentClassEntitlementUpdated);
			App.push.on("other:instructorClass:" + this.get("classId") + ":instructorUpdate", this.studentClassUpdated);

			var triggers = "change:" + this.studentClassSyncPropertyTriggers.join(" change:");
			this.on(triggers, this._throttledStudentClassPushUpdate);

			triggers = "change:" + this.studentSyncConsoleMonitoringPropertyTriggers.join(" change:");
			this.on(triggers, this._throttledStudentClassLatencyPushUpdate);

			triggers = "change:" + this.studentSyncConsoleMonitoringImmediatePropertyTriggers.join(" change:");
			this.on(triggers, this.studentClassPushUpdate);

			triggers = "change:" + this.studentClassSyncImmediatePropertyTriggers.join(" change:");
			this.on(triggers, this._doStudentClassPushUpdate);
		},
		/**
		 * Request status of the Class model
		 */
		studentClassRequestSync : function(){
			if(!App.push) { return; }
			if (!this.get("classId")) { return; }

			var message = {
				service      : "instructorClass",
				channel      : "/instructorClass/" + this.get("classId"),
				operation    : "studentSync",
				data         : this.studentClassPushData(),
				requestParams: {
					id           : this.get("classId"),
					entitlementId: this.get("id")
				}
			};

			App.push.send(message);
		},
		studentClassPushUpdate : function(inMessage){
			if (!this.get("classId")) { return; }

			if(inMessage){
				this.studentClassEntitlementUpdated(inMessage);
			}

			//in timeout in case multiple properties are updated at the same time
			setTimeout(this._doStudentClassPushUpdate);
		},
		_doStudentClassPushUpdate : function(){
			if(!App.push) { return; }
			if (!this.get("classId")) { return; }

			var message = {
				service      : "classEntitlement",
				operation    : "studentUpdate",
				channel      : "/classEntitlement/" + this.get("id"),
				data         : this.studentClassPushData(),
				requestParams: {
					id: this.get("id")
				}
			};
			App.push.send(message);
		},
		studentClassEntitlementUpdated : function(message){
			if(message && message.data){
				if(message.data.hasOwnProperty("instructorViewing")){
					this.set("instructorViewing", message.data.instructorViewing);
				}
				if(message.data.hasOwnProperty("takenOver")){
					this.set("takenOver", message.data.takenOver);
				}
				if(message.data.hasOwnProperty("broadcastingDisplay")){
					this.set("broadcastingDisplay", message.data.broadcastingDisplay);
				}if(message.data.hasOwnProperty("raisedHand")){
					this.set("raisedHand", message.data.raisedHand);
				}
			}
		},
		/**
		 * Called when variables are updated on the Class model (from push other:instructorClass:$id:update send from instructor)
		 * @param message
		 */
		studentClassUpdated : function(message){
			if(message && message.data){
				if(message.data.screenStatus){
					this.set("screenStatus", message.data.screenStatus);
				}

				if(message.data.hasOwnProperty("broadcastingDisplay")){
					this.set({
						classBroadcastingDisplay    : message.data.broadcastingDisplay,
						classBroadcastingEntitlement: message.data.broadcastingEntitlement,
						classActiveScreenTicket     : message.data.activeScreenTicket,
						classActiveVmx              : message.data.activeVmx
					});
				}
			}
		},
		studentClassPushThumbnail : function(){
			if(!App.push) { return; }
			if (!this.get("classId")) { return; }
			var message = {
				service      : "classEntitlement",
				operation    : "studentThumbnail",
				channel      : "/classEntitlement/" + this.get("id"),
				data         : {
					id             : this.get("id"),
					activeThumbnail: this.get("activeThumbnail")
				},
				requestParams: {
					id: this.get("id")
				}
			};
			App.push.send(message);
		},
		studentClassPushData : function(){
			return {
				id                            : this.get("id"),
				state                         : this.get("state"),
				status                        : this.get("status"),
				phase                         : this.get("phase"),
				progress                      : this.get("progress"),
				timeAllotted                  : this.get("timeAllotted"),
				timeElapsed                   : this.get("timeElapsed"),
				extensionCount                : this.get("extensionCount"),
				completionStatusForLabCriteria: this.get("completionStatusForLabCriteria"),
				extensionsRemaining           : this.get("extensionsRemaining"),
				totalSteps                    : this.get("totalSteps"),
				raisedHand                    : this.get("raisedHand"),
				activeStepNumber              : (this.get("activeStepNumber") === 0 && this.get("totalSteps") === 0 ? 0 : this.get("activeStepNumber") + 1),
				labTimeRemaining              : this.get("labTimeRemaining"),
				studentConsoleLatencyCurrent  : this.get("consoleLatencyCurrent"),
				studentConsoleLatencyAverage  : this.get("consoleLatencyAverage"),
				studentConsoleLatencyHigh     : this.get("consoleLatencyHigh"),
				studentConsoleLatencyLow      : this.get("consoleLatencyLow") === Number.MAX_VALUE ? 0 : this.get("consoleLatencyLow"),
				studentConsoleLatencyUnstable : this.get("consoleLatencyUnstable"),
				studentConsoleLatencyWarning  : this.get("consoleLatencyWarning"),
				studentConsoleConnectionLost  : this.get("consoleConnectionLost")
			};

		},


		/**
		 * Request sync of entitlement (not the class, see below) from instructor
		 */
		classRequestSync : function(){
			if(!App.push) { return; }

			var message = {
				service      : "classEntitlement",
				channel      : "/classEntitlement/" + this.get("id"),
				operation    : "instructorSync",
				data         : this.classPushData(),
				requestParams: {
					id: this.get("id")
				}
			};

			App.push.send(message);
		},
		classPushUpdate : function(){
			if(!App.push) { return; }

			var message = {
				service      : "classEntitlement",
				channel      : "/classEntitlement/" + this.get("id"),
				operation    : "instructorUpdate",
				data         : this.classPushData(),
				requestParams: {
					id: this.get("id")
				}
			};

			App.push.send(message);
		},
		classRequestConsoleThumbnail : function(height, width){
			if(!App.push) { return; }

			if(height && !width){
				width = height;
			}
			var message = {
				service      : "classEntitlement",
				channel      : "/classEntitlement/" + this.get("id"),
				operation    : "getThumbnail",
				requestParams: {
					id    : this.get("id"),
					height: height || 100,
					width : width  || 100
				}
			};

			App.push.send(message);
		},
		classPushData : function(){
			return {
				instructorViewing  : this.get("instructorViewing"),
				takenOver          : this.get("takenOver"),
				broadcastingDisplay: this.get("broadcastingDisplay"),
				raisedHand         : this.get("raisedHand")
			};
		},

		remoteSetupMainConsole : function(){
			if(!App.push) { return; }
			App.push.on("other:entitlement:" + this.get("id") + ":remoteDataSync", this.mainConsolePushUpdateToRemote);
			App.push.on("other:entitlement:" + this.get("id") + ":activeConsoleSync", this.remoteActiveConsoleChanged);
			this.on("change:activeConsoleId", this.remoteSyncActiveConsole, this);
		},
		remoteSetupRemoteConsole : function(){
			if(!App.push) { return; }
			App.push.on("other:entitlement:" + this.get("id") + ":activeConsoleSync", this.remoteActiveConsoleChanged);
			this.on("change:activeConsoleId", this.remoteSyncActiveConsole, this);
		},
		/**
		 * Remote control requests sync of entitlement data from main console.
		 * Sends websocket based request from remoteControl to main console (if running)
		 * in another browser to get current data.
		 */
		remoteRequestDataSync : function(){
			if(!App.push) { return; }

			var message = {
				service      : "entitlement",
				channel      : "/entitlement/" + this.get("id"),
				operation    : "remoteDataSync",
				requestParams: {
					id: this.get("id")
				}
			};


			var fetchThumbnailsSetupCallback;
			if(this.get("fetchThumbnails")){
				//prevent fetching thumbnails if main console is available
				//(images will be sent in push message response).
				this.set("fetchThumbnails", false);

				var self = this;
				fetchThumbnailsSetupCallback = setTimeout(function(){
					_.delay(function(){
						self.set("fetchThumbnails", true);
					}, Math.max(0, App.config.thumbnailCheckDelay - App.config.pushTimeout));
				}, App.config.pushTimeout);
			}

			App.push.once("other:entitlement:" + this.get("id") + ":remoteDataUpdate", function(message){
				clearTimeout(fetchThumbnailsSetupCallback);
				this.remoteDataUpdated(message);
			}, this);

			App.push.send(message);
		},


		//Handles main console response of sync data.
		mainConsolePushUpdateToRemote : function(){
			if(!App.push) { return; }

			var message = {
				service      : "entitlement",
				channel      : "/entitlement/" + this.get("id"),
				operation    : "remoteDataUpdate",
				data         : this.remotePushData(),
				requestParams: {
					id: this.get("id")
				}
			};

			App.push.send(message);
		},
		remotePushData : function(){
			return {
				id:                 this.get("id"),
				vms:                this.get("vms") ? this.get("vms").toJSON() : [],
				activeConsoleId:    this.get("activeConsoleId"),
				startTimeRemaining: this.get("startTimeRemaining"),
				startTime:          this.get("startTime"),
				now:                Date.now()
			};
		},

		//Handles main console response of sync data.
		remoteDataUpdated: function(message){
			if(!message || !message.data){ return; }

			this.set("fetchThumbnails", false);

			var self = this;

			if(message.data.hasOwnProperty("activeConsoleId")){
				this.set("activeConsoleId", message.data.activeConsoleId, {sync: false});
			}

			if(!_.isEmpty(message.data.vms) && this.has("vms") && this.get("vms").length){
				_.each(message.data.vms, function(vmInfo){
					var vm = self.get("vms").get(vmInfo.id);
					if(vm){
						vm.set("thumbnail", vmInfo.thumbnail);
					}
				});
			}

			if(message.data.hasOwnProperty("startTimeRemaining")){
				this.set("startTimeRemaining", message.data.startTimeRemaining);
			}

			if(message.data.hasOwnProperty("startTime")){
				var startTimeDiff = 0;
				if(message.data.hasOwnProperty("now")){
					startTimeDiff = Math.ceil((Date.now() - message.data.now) / 1000) * 1000;
				}
				this.set("startTime", message.data.startTime + startTimeDiff);
			}
		},

		remoteSyncActiveConsole : function(model, value, options){
			options = _.defaults(options || {}, {
				sync  : true
			});
			if(!App.push || !options.sync || !App.remoteControl) { return; }

			var message = {
				service      : "entitlement",
				channel      : "/entitlement/" + this.get("id"),
				operation    : "activeConsoleSync",
				data         : {
					activeConsoleId : this.get("activeConsoleId")
				},
				requestParams: {
					id: this.get("id")
				}
			};
			App.push.send(message);
		},
		remoteActiveConsoleChanged : function(message){
			if(!message || !message.data){ return; }
			this.set("activeConsoleId", message.data.activeConsoleId, {sync: false});
		},


		getLatencyLevel: function(value) {
			if(App.config.consoleConnectionLatencyLevels && App.config.consoleConnectionLatencyLevels.length >= 5){
				if(value === Number.MAX_VALUE || value <= 0) {
					return "level-0";
				} else if(value < App.config.consoleConnectionLatencyLevels[0]){
					return "level-1";
				} else if (value < App.config.consoleConnectionLatencyLevels[1]) {
					return "level-2";
				} else if (value < App.config.consoleConnectionLatencyLevels[2]) {
					return "level-3";
				} else if (value < App.config.consoleConnectionLatencyLevels[3]) {
					return "level-4";
				} else if (value < App.config.consoleConnectionLatencyLevels[4]) {
					return "level-5";
				} else {
					return "level-6";
				}
			} else {
				return "level-0";
			}
		},

		getLatencyDisplayValue: function(value) {
			value = Math.round(value);
			if(value === Number.MAX_VALUE || value <= 0){
				return "--";
			}
			return value.toLocaleString();
		},
		resetStudentConsoleLatency : function(){
			this.set({
				studentConsoleLatencyCurrent : 0,
				studentConsoleLatencyAverage : 0,
				studentConsoleLatencyLow     : 0,
				studentConsoleLatencyHigh    : 0,
				studentConsoleLatencyWarning : false,
				studentConsoleLatencyUnstable: false,
				studentConsoleConnectionLost : false
			});
		}
	});

	Entitlement.Collection = BaseCollection.extend({
		//A collection of Entitlement
		model : Entitlement.Model,
		parse : function(){
			var parentResults = BaseCollection.prototype.parse.apply(this, arguments);

			var results = {};
			_.each(parentResults, function(item){

				if(item.itemId && !item.entitlementKey){
					item.entitlementKey = item.itemId;
				}

				if(item.itemId && !item.id){
					item.id = item.itemId;
					delete item.itemId;
				}

				if(item.entitlement_id && !item.id){
					item.id = item.entitlement_id;
					delete item.entitlement_id;
				}

				if(item.entitlementKey && (!item.id || _.isNumber(item.id))){
					item.id = item.entitlementKey;
				}
				if(item.course && !item.lab){
					item.type = "course";
				} else{
					item.type = "lab";
				}
				results[item.id] = item;
			});

			_.each(results, function(item, index){
				if(index.match(/:\d+$/)){
					var parentId = index.split(":")[0];
					if(results[parentId]){
						results[parentId].labs = results[parentId].labs || [];
						results[parentId].labs.push(item);
						delete results[index];
					}
				}
			});
			return _.toArray(results);
		}
	});

});

