define('views/compatibility/test',['require','library/vlp/view','hbs!tpls/compatibility/status-indicator.handlebars','hbs!tpls/compatibility/supported-browsers.handlebars'],function (require) {
	"use strict";

	//class dependencies
	var BaseView = require("library/vlp/view");

	//Registered as partial
	require("hbs!tpls/compatibility/status-indicator.handlebars");
	require("hbs!tpls/compatibility/supported-browsers.handlebars");

	return BaseView.extend({
		unwrap : true,

		name   : "base",
		status : "untested",
		warnings : {},
		errors   : {},
		enabled  : true,

		events : {
			"show .collapse" : "onExpanded",
			"hide .collapse" : "onCollapsed"
		},
		serialize : function(){
			var data = {
				name     : this.name,
				status   : this.status,
				warnings : this.warnings,
				errors   : this.errors,
				expanded : this.expanded,
				enabled  : this.enabled
			};
			return data;
		},
		setStatus : function(status){
			this.status = status;
			this.render();
		},
		reset : function(){
			this.setStatus("untested");
			this.warnings = {};
			this.errors   = {};
		},
		onExpanded : function(){
			this.expanded = true;
		},
		onCollapsed : function(){
			this.expanded = false;
		}
	},
	{
		COMPLETE : "complete",
		PROGRESS : "progress"
	});

});

