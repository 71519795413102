
define('hbs!tpls/console/walkthrough/step-1.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var helper;

  return "<div class=\"console-walkthrough-title\">\n	<button class=\"btn btn-default console-walkthrough-exit\">&times;</button>\n	<span class=\"console-walkthrough-step-number\">"
    + this.escapeExpression(((helper = (helper = helpers.stepNumber || (depth0 != null ? depth0.stepNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"stepNumber","hash":{},"data":data}) : helper)))
    + "</span>\n	"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labTime.title",{"name":"$","hash":{},"data":data}))
    + "\n</div>\n\n<div class=\"console-walkthrough-content step-"
    + this.escapeExpression(((helper = (helper = helpers.stepNumber || (depth0 != null ? depth0.stepNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"stepNumber","hash":{},"data":data}) : helper)))
    + "\">\n	<ul>\n		<li>\n			<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labTime.timeRemaining.title",{"name":"$","hash":{},"data":data}))
    + ": </strong>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labTime.timeRemaining.description",{"name":"$","hash":{},"data":data}))
    + "\n			<em class=\"note\">\n				<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.general.note",{"name":"$","hash":{},"data":data}))
    + ": </strong>\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labTime.timeRemaining.note",{"name":"$","hash":{},"data":data}))
    + "\n			</em>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labTime.timeRemaining.expiration",{"name":"$","hash":{},"data":data}))
    + "\n		</li>\n		<li>\n			<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labTime.end.title",{"name":"$","hash":{},"data":data}))
    + ": </strong>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labTime.end.description",{"name":"$","hash":{},"data":data}))
    + "\n		</li>\n		<li>\n			<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labTime.exit.title",{"name":"$","hash":{},"data":data}))
    + ": </strong>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labTime.exit.description",{"name":"$","hash":{},"data":data}))
    + "\n			<em class=\"note note-end\">\n				<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.general.note",{"name":"$","hash":{},"data":data}))
    + ": </strong>\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labTime.exit.note",{"name":"$","hash":{},"data":data}))
    + "\n			</em>\n		</li>\n		<li>\n			<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labTime.extend.title",{"name":"$","hash":{},"data":data}))
    + ": </strong>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labTime.extend.description",{"name":"$","hash":{},"data":data}))
    + "\n		</li>\n	</ul>\n</div>\n\n<div class=\"console-walkthrough-actions\">\n	<button class=\"btn btn-primary console-walkthrough-next\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"next",{"name":"$","hash":{},"data":data}))
    + " <span class=\"fa fa-va fa-lg fa-caret-right\"></span></button>\n</div>";
},"useData":true});
Handlebars.registerPartial('console_walkthrough_step_1', t);
return t;
});

