/**
 * Speed-test Backbone View
 */
define('views/speed-test/main',['require','jquery','underscore','library/vlp/app','views/vlp-base','models/system','views/speed-test/speedometer','hbs!tpls/speed-test/main.handlebars','hbs!tpls/speed-test/test-results.handlebars','i18n!nls/speed-test'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App                 = require("library/vlp/app"),
	    VlpBaseView         = require("views/vlp-base"),
	    System              = require("models/system"),
	    Speedometer         = require("views/speed-test/speedometer"),
	    mainTPL             = require("hbs!tpls/speed-test/main.handlebars"),
	    testResultsTPL      = require("hbs!tpls/speed-test/test-results.handlebars"),
	    pageTexts           = require("i18n!nls/speed-test");


	return VlpBaseView.extend({
		name : "SPEED_TEST",
		className : "general-page",

		anonymousAccess        : false,
		checkLoggedIn          : true,
		checkMaintenance       : true,
		checkSystemMessages    : false,
		checkBrowserSupported  : false,
		checkTenantPath        : false,

		colors : [
			{"size" : 999999, "color" : "#91cdc8"},
			{"size" : 10000,  "color" : "#91cdc8"},
			{"size" : 3000,   "color" : "#22a3aa"},
			{"size" : 1540,   "color" : "#185152"},
			{"size" : 256,    "color" : "#133f3f"},
			{"size" : 56,     "color" : "#0d302f"}
		],

	//Required
		mainTemplate : mainTPL,
		events : {
			"click #trigger" : "startTest"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){

			_.bindAll(this);

			App.locale.mergeI18n(pageTexts);

			this.pageStartupOperations.push("fetchCloudURLBase");
			//Checks logged in
			this.setup(options);

			this.speedometer = new Speedometer({
				el : this.$("#bandwidth_speedometer")
			});

			this.on(VlpBaseView.LOGGED_IN, this.onLoggedIn);
			this.once(VlpBaseView.STARTUP_COMPLETE, this.startupComplete);
		},
		/**
		 * Display the content.
		 */
		render : function(){
			//Always return this for chaining
			return this;
		},
		_op_fetchCloudURLBase : function(){

			var system = new System.Model();

			system.fetchCloudURLBase({
				success : this.nextOperation,
				error   : this.nextOperation
			});
		},
		startupComplete : function(){
			this.tests = [
				{
					test : "runTestLatency",
					type : "cloud",
					urls : App.config.speedTestCloudUrls
				},
				/*
								{
									test : "runTestDownload",
									type : "cloud",
									urls : App.config.speedTestCloudUrls,
									fileSize : App.config.speedTestCloudFileSize
								},
				 */
				{
					test : "runTestLatency",
					type : "ui",
					urls : [App.config.speedTestUiFile]
				},
				{
					test : "runTestDownload",
					type : "ui",
					urls : [App.config.speedTestUiFile],
					fileSize : App.config.speedTestUiFileSize
				}
			];

			var data = {};
			data.tests = this.tests;
			this.$("#results").html(testResultsTPL(data));
		},
		startTest : function(){
			this.startTests();
			this.runAll();
		},

		runAll : function(){
			this.activeTests = _.clone(this.tests);
			this.nextTest();
		},

		runTestLatency : function(type, urls){
			var self = this;

			var startLabel    = App.i18n("speedTest.tests."+ type +".latencyTestStart");
			var completeLabel = App.i18n("speedTest.tests."+ type +".latencyTestComplete");

			var completedLatencyChecks = [];

			function completed(error, latency) {
				var $entry = self.$("ul.latency li." + type);
				$entry.removeClass("not-run success fail error");
				var speedLabel = "";
				if (error) {
					App.generalMessage({
						message    : App.i18n("speedTest.errors.latency." + error, type),
						type       : "error",
						removeDelay: 7000
					});
					if (self.activeTests[0] && self.activeTests[0].test === "runTestDownload" && self.activeTests[0].type === type) {
						$entry = $entry.add(self.$("ul.speed li." + type));
						self.activeTests.shift();
					}

					speedLabel = App.i18n("speedTest.errors." + error);
					$entry.addClass("error");
				} else {
					self.$("#status").html(completeLabel);
					speedLabel = Math.round(latency) + " ms";

					if (latency < App.config.speedTestLatencyFail) {
						$entry.addClass("success");
					} else if (latency > 450) {
						$entry.addClass("fail");
					}
				}

				$entry.find(".result").html(speedLabel);

				self.speedometer.setLatency(latency);
				self.nextTest();
			}

			function createLatencyTestWorker(latencyUrl) {
				self.$("#status").html(startLabel);
				var latencyWorker = new Worker("/scripts/library/utilities/speed-test-worker.js");

				latencyWorker.postMessage({
					testType             : "latency",
					imageAddress         : latencyUrl,
					numberOfLatencyChecks: App.config.speedTestLatencyRunCount,
					testTimeout          : App.config.speedTestLatencyTimeout
				});

				latencyWorker.onmessage = function (event) {
					latencyWorker.terminate();

					var testResult = self.results[type][latencyUrl];
					testResult.latency = Math.round(event.data.latency);
					testResult.latencyFail   = (testResult.latency === -1 || testResult.latency > App.config.speedTestLatencyFail);
					testResult.fail          = testResult.bandwidthFail || testResult.latencyFail;
					testResult.error         = testResult.latency === -1;

					completedLatencyChecks.push({latency: event.data.latency});
					if (completedLatencyChecks.length === urls.length) {
						completedLatencyChecks.sort(function(a, b){ return a.latency-b.latency; });
						completed(event.error, completedLatencyChecks[0].latency);
					}

				};
			}

			if (urls.length > 0) {
				_.each(urls, function (latencyUrl) {
					createLatencyTestWorker(latencyUrl);
				});
			} else {
				completed("cloudsUnavailable", -1);
			}
		},
		runTestDownload : function(type, urls, fileSize){
			var self = this;

			var startLabel    = App.i18n("speedTest.tests." + type + ".downloadTestStart");
			var completeLabel = App.i18n("speedTest.tests." + type + ".downloadTestComplete");

			this.$("#status").html(startLabel);

			var completed = function(bandwidth){
				var kbPerSec = bandwidth / 1024;
				var mbPerSec = kbPerSec / 1024;

				self.$("#status").html(completeLabel);

				var displaySpeed = "0";
				if(mbPerSec > 1){
					displaySpeed = _.numberFormat(mbPerSec, 1, App.i18n("decimalSeparator"), App.i18n("thousandsSeparator")) + " Mb/s";
				} else{
					displaySpeed = _.numberFormat(kbPerSec, 1, App.i18n("decimalSeparator"), App.i18n("thousandsSeparator")) + " Kb/s";
				}

				var mySpeedColor = self.colors[0].color;
				for(var i = 0; i < self.colors.length; i++){
					if(kbPerSec < self.colors[i].size){
						mySpeedColor = self.colors[i].color;
					} else{
						break;
					}
				}

				var $entry = self.$("ul.speed li." + type);
				$entry.find(".result").html(displaySpeed);
				$entry.find(".indicator").css("background", mySpeedColor);
				$entry.removeClass("not-run");

				self.speedometer.setSpeed(kbPerSec);

				self.nextTest();
			};

			var downloadTestWorkersResults = [];
			var numberOfWorkers = App.config.speedTestDownloadWorkers;


			function createDownloadTestWorker(downloadSize, downloadUrl) {
				var worker = new Worker("/scripts/library/utilities/speed-test-worker.js");

				worker.postMessage({
					testType: "download",
					imageAddress: downloadUrl,
					downloadSize: downloadSize,
					testDuration: App.config.speedTestDownloadDuration,
					testTimeout: App.config.speedTestDownloadTimeout
				});

				worker.onmessage = function (event) {
					worker.terminate();
					downloadTestWorkersResults.push(event.data);
					if (downloadTestWorkersResults.length === numberOfWorkers * urls.length) {
						var bestResult;
						for (var i = 0; i < urls.length; i++) {
							var url = urls[i];
							var testResult = self.results[type][url];
							var currentCloudSpeeds = downloadTestWorkersResults.filter(function(cloudData) {
								return cloudData.imageAddress === url;
							});

							var bandwidth = _.reduce(currentCloudSpeeds, function(memo, cloudData){ return memo + cloudData.bsec; }, 0);

							testResult.bandwidth = Math.round(bandwidth);
							testResult.bandwidthFail = (testResult.bandwidth === -1 || testResult.bandwidth < App.config.speedTestBandwidthFail);
							testResult.fail = testResult.bandwidthFail || testResult.latencyFail;

							if(!bestResult || testResult.latency < bestResult.latency){
								bestResult = testResult;
							}
						}
						completed(bestResult.bandwidth);
					}
				};
			}


			_.each(urls, function(downloadUrl){
				for (var j = 0; j < numberOfWorkers; j++) {
					createDownloadTestWorker(fileSize, downloadUrl);
				}
			});
		},
		nextTest : function(){
			var nextTest = this.activeTests.shift();
			if(!nextTest){
				this.endTime = Date.now();
				_.delay(this.testsComplete, 100);
			} else {
				this.initTestResults(nextTest.type, nextTest.urls);
				var self = this;
				_.delay(function(){
					self[nextTest.test].call(self, nextTest.type, nextTest.urls, nextTest.fileSize);
				}, 100);
			}
		},
		initTestResults : function(type, urls){
			this.results = this.results || {};
			this.results[type] = this.results[type] || {};
			for(var i = 0; i < urls.length; i++){
				var url = urls[i];
				var parsedURL = _.parseURL(url);

				this.results[type][url] = _.defaults({}, this.results[type][url], {
					destination: parsedURL.host + (parsedURL.port !== "" ? ":" + parsedURL.port : ""),
					latency: 0,
					latencyFail: false,
					bandwidth : 0,
					bandwidthFail : false,
					fail : false,
					error : false
				});
			}
		},
		startTests : function(){
			this.$("#load_box").show();

			this.$("#results > ul > li")
				.addClass("not-run")
				.removeClass("error success fail");

			this.$("ul li .result").html("---");

			this.$("#status").html(App.i18n("loading"));
			$("#trigger").prop("disabled",true);
			this.results = {};
			this.speedometer.reset();
			this.startTime = Date.now();
		},
		testsComplete : function(){
			this.$("#load_box").hide();

			$("#trigger").prop("disabled",false);
			var self = this;


			if(App.user.get("loggedIn")){
				var modifiedResults = {
					clouds : []
				};

				_.each(this.results, function(result, type){
					_.each(result, function(urlResult, url){
						var saveResult = {
							destination: urlResult.destination,
							bandwidth  : urlResult.bandwidth,
							latency    : urlResult.latency
						};
						if(type === "ui" && (!modifiedResults.ui || urlResult.latency < modifiedResults.ui.latency)){
							modifiedResults.ui = saveResult;
						} else if(type === "cloud"){
							modifiedResults.clouds.push(saveResult);
						}

						if(urlResult.error){
							self.errors[type] = true;
						} else if(urlResult.fail){
							self.warnings.connection = true;
							self.warnings[type] = true;
						}
					});
				});


				$.ajax({
					type  : "POST",
					url   : App.config.serverBase + "compatibility?tenant=" + (App.getTenant() || App.config.defaultTenantPath),
					contentType : "application/json",
					data  : JSON.stringify({bandwidth : modifiedResults}),
					error : function(){
						//empty function just to suppress error message
					}
				});
			}

			App.analytics.trackEvent("Support", "Run Speed Test");
			if(this.startTime && this.endTime && this.endTime > this.startTime){
				App.analytics.timing("Support", "speedTest", (this.endTime - this.startTime) / 1000.0);
			}
		},
		onLoggedIn : function(){
			if(!App.getTenantFromURL()){
				if(App.user.get("visibleTenants").length > 1) {
					this.showTenantChooser({force : true});
				} else if(App.user.get("visibleTenants").length === 1){
					App.redirect({page : this.name, tenant: App.user.get("visibleTenants")[0].name});
				}
			}
		}
	});

});

