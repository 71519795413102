/**
 * Screen-saver Backbone View
 */
define('views/screen-saver/main',['require','jquery','underscore','library/vlp/app','views/vlp-base','models/promo-content','hbs!tpls/screen-saver/main.handlebars','hbs!tpls/screen-saver/promo-content.handlebars','hbs!tpls/screen-saver/default-content.handlebars','i18n!nls/screen-saver'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App               = require("library/vlp/app"),
	    VlpBaseView       = require("views/vlp-base"),
	    PromoContent      = require("models/promo-content"),
	    mainTPL           = require("hbs!tpls/screen-saver/main.handlebars"),
	    promoContentTPL   = require("hbs!tpls/screen-saver/promo-content.handlebars"),
	    defaultContentTPL = require("hbs!tpls/screen-saver/default-content.handlebars"),
	    pageTexts         = require("i18n!nls/screen-saver");


	return VlpBaseView.extend({
		name : "SCREEN_SAVER",
		className : "general-page",

		checkLoggedIn         : false,
		checkMaintenance      : true,
		checkBrowserSupported : false,
		checkTenantPath       : false,
		checkSystemMessages   : false,
		screenSaver           : false,
		stationPush           : true,

		//Required
		mainTemplate : mainTPL,

		events : {
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){

			_.bindAll(this);

			App.locale.mergeI18n(pageTexts);


			this.promoContent = new PromoContent.Collection();
			this.once(VlpBaseView.STARTUP_COMPLETE, function(){
				this.checkUserLoggedIn(this.onLoggedIn);
				this.render();

				this.promoContent.fetch({
					success : this.render,
					error   : this.render
				});
				$(document).on("mousemove mousedown mouseup keydown keyup scroll", this.onActivity);
			}, this);

			//Checks logged in
			this.setup(options);
			this.on(VlpBaseView.LOGGED_IN, this.onLoggedIn);
			$("a.home").removeAttr("href");


			App.windowCommunication.on("receive:activeLab", this.onActiveLabChanged);
			App.windowCommunication.on("respond:checkActiveLab", this.onActiveLabChanged);

			App.windowCommunication.call("checkActiveLab");


		},
		/**
		 * Display the content.
		 */
		render : function(){

			//Always return this for chaining
			this.$("#promo_content").html(promoContentTPL(this.promoContent));
			if(this.promoContent.length === 0){
				this.$("#promo_content").html(defaultContentTPL());
			} else if(this.promoContent.length > 1){
				this.$("#promo_content").carousel({
					interval : App.config.eventPromoCycleInterval,
					pause    : false
				});
			}

			this.$("#promo_content .item").first().addClass("active");
			return this;
		},
		onActiveLabChanged : function(activeLab, event){
			if(activeLab.entitlementId){
				App.redirect({page: App.config.pages.REMOTE_CONTROL, action: activeLab.entitlementId, tenant: activeLab.tenant,  forceHost : true});
			}
			if(activeLab.remoteToken){
				App.redirect({page: App.config.pages.REMOTE_CONTROL, action: "remoteToken/" + activeLab.remoteToken, tenant: activeLab.tenant,  forceHost : true});
			}


		},
		startTimeout : function(){
			this.stopTimeout();
			this.idleTimedOut = setTimeout(this.onIdle, App.config.screenSaverIdleDelay);
		},
		stopTimeout : function(){
			if(this.idleTimedOut){
				clearTimeout(this.idleTimedOut);
				this.idleTimedOut = null;
			}
		},
		onActivity : function(){
			if(!this.loginPromptView || !this.loginPromptView.isOpen){
				this.showLogInPrompt();
			}
			this.loginPromptView.off("loggedIn", this.onLoggedIn).on("loggedIn", this.onLoggedIn);
			if(this.promoContent.length > 1){
				this.$("#promo_content").carousel("pause");
			}
			this.startTimeout();
		},
		onIdle : function(){
			if(this.loginPromptView && this.loginPromptView.submitting){
				this.startTimeout();
			} else{
				this.hideLogInPrompt();
				if(this.promoContent.length > 1){
					this.$("#promo_content").carousel("cycle");
				}
				this.stopTimeout();
			}
		},
		onLoggedIn : function(){
			App.redirect({page: App.config.pages.HOME});
		}

	});

});
