/**
 * Language Chooser Backbone View
 */
define('views/common/student-enroll-lab',['require','jquery','underscore','library/vlp/app','library/vlp/view','hbs!tpls/common/student-enroll.handlebars','hbs!tpls/catalogs/lab/full-details.handlebars','hbs!tpls/catalogs/lab/class-details.handlebars','hbs!tpls/catalogs/lab/systems.handlebars','hbs!tpls/catalogs/lab/reviews-and-ratings.handlebars','hbs!tpls/catalogs/lab/badges.handlebars','hbs!tpls/catalogs/lab/common-metadata.handlebars','hbs!tpls/catalogs/lab/common-metadata-links.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
		_ = require("underscore");

	//class dependencies
	var App          = require("library/vlp/app"),
		BaseView     = require("library/vlp/view"),
		tpl          = require("hbs!tpls/common/student-enroll.handlebars");

	//Used as partial
	require("hbs!tpls/catalogs/lab/full-details.handlebars");
	require("hbs!tpls/catalogs/lab/class-details.handlebars");
	require("hbs!tpls/catalogs/lab/systems.handlebars");
	require("hbs!tpls/catalogs/lab/reviews-and-ratings.handlebars");
	require("hbs!tpls/catalogs/lab/badges.handlebars");
	require("hbs!tpls/catalogs/lab/common-metadata.handlebars");
	require("hbs!tpls/catalogs/lab/common-metadata-links.handlebars");

	return BaseView.extend({

		template : tpl,
		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		el : "#modal_enroll_student",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"submit form"             : "onEnroll",
			"click .lab-detail-header": "showDetails",
			"click .read-more"        : "showFullDescription",
			"click .read-less"        : "hideFullDescription"
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 */
		initialize : function(){
			_.bindAll(this);

			if(this.model.has("lab")){
				//May get related info separately from catalogs call
				this.listenTo(this.model.get("lab"), "change:badges change:screenshot", this.render);
			}

			this.enrolled = this.model.get("enrolled");
		},
		afterRender : function(){
			$("#modal_enroll_student").off("hide.classEnroll").on("hide.classEnroll", this.closed);
			$("#modal_enroll_student").modal("reflow");
			this.setupImageErrorHandlers();
			if(this.enrolled){
				this.onEnrollSuccess();
			} else if(this.model.get("full")){
				this.onClassFull();
			}
		},
		remove : function(){
			$("#modal_enroll_student").off("hide.classEnroll");
			this.$(".screenshot").off("error.lab-screenshot load.lab-screenshot");
			this.$(".thumbnail").off("error.lab-screenshot load.lab-screenshot");
			BaseView.prototype.remove.apply(this, arguments);
		},
		show : function(options){
			this.render();
			$("#modal_enroll_student").modal("show");
		},
		hide : function(){
			$("#modal_enroll_student").modal("hide");
		},
		closed : function(){
			$("#modal_enroll_student").off("hide.languages", this.closed);
			this.trigger("closed");
		},

		onEnroll : function(event){
			event.preventDefault();
			var self = this;
			this.model.enroll({
				success : function(){
					//Re-fetch user info in case user got added to the tenant after self-registering.
					App.user.fetch({
						success : function(){
							self.enrolled = true;
							self.onEnrollSuccess();
						}
					});
				},
				beforeSend : function(){
					self.$(".enroll-button").button("loading");
				},
				complete : function(){
					self.$(".enroll-button").button("reset");
				}
			});
		},
		onEnrollSuccess : function(){
			this.$(".enroll-prompt").hide();
			this.$(".enroll-success").show();
			$("#modal_enroll_student").modal("reflow");
			if(this.model.get("entitlement")) {
				App.router.navigate("enrollments/lab/" + this.model.get("entitlement").get("entitlementKey"), {trigger: true});
			} else if(!this.model.get("entitlementsEnabled")){
				this.$("#class_enrolled").hide();
				this.$("#class_not_started").show();
			}
		},
		onClassFull : function(){
			this.$(".enroll-prompt").hide();
			this.$(".class-full").show();
			$("#modal_enroll_student").modal("reflow");
		},
		showDetails : function(event){
			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			var $this = $(event.currentTarget);

			if ($this.hasClass("expanded")) {
				$this.removeClass("expanded").addClass("closed");
			} else {
				$this.removeClass("closed").addClass("expanded");
			}

			var $fullDescription = this.$(".lab-detail");
			$fullDescription.slideToggle("slow", "easeInOutQuint", function(){
				$("#modal_enroll_student").modal("reflow");
			});
		},
		setupImageErrorHandlers : function(){
			var imgProperties = ["thumbnail", "screenshot", "networkTopology"];
			for(var i in imgProperties){
				if(imgProperties.hasOwnProperty(i)){
					var property = imgProperties[i];

					var events = "error.lab-" + property + " load.lab-" + property;
					var $images = this.$("img[data-property=\"" + property + "\"]");
					$images.off(events);

					if($images.length && this.model.get("lab") && this.model.get("lab").get(property) && this.model.get("lab").get(property) !== App.config.defaultLabThumbnail){
						$images.one(events, this.errorLoadingImage);
					}
				}
			}
		},

		errorLoadingImage : function(event){
			if(event.type !== "error") {
				return;
			}


			var $target = $(event.currentTarget);
			var property = $target.data("property");
			var events = "error.lab-" + property + " load.lab-" + property;

			$target.off(events);

			if(property === "thumbnail"){
				$target.attr("src", App.config.defaultLabThumbnail);
				this.model.get("lab").set(property, App.config.defaultLabThumbnail, {silent : true});
			} else {
				$target.attr("src", "");
				this.model.get("lab").set(property, "");
			}

		},

		showFullDescription : function(){
			var self = this;

			this.$(".short-description").fadeOut("fast", function(){
				self.$(".full-description").fadeIn("fast");
			});
		},
		hideFullDescription : function(){
			var self = this;

			this.$(".full-description").fadeOut("fast", function(){
				self.$(".short-description").fadeIn("fast");
			});

		}
	});

});

