define('views/catalogs/lab-certificate',['require','underscore','library/vlp/app','library/vlp/view','hbs!tpls/catalogs/certificate-modal.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//class dependencies
	var App       = require("library/vlp/app"),
	    BaseView  = require("library/vlp/view"),
	    Template  = require("hbs!tpls/catalogs/certificate-modal.handlebars");

	return BaseView.extend({
		template : Template,
		events : {
			"click .print-certificate" : "onPrintCertificate",
			"click .email-certificate" : "onEmailCertificate",
			"hidden .modal"            : "onHidden"
		},
		initialize : function(options){
			_.bindAll(this);
		},
		serialize : function(){
			var data = this.model.toHandlebars();
			if(data.lab) {
				data = _.defaults(data, data.lab);
			}

			data.display = data.display || {};
			data.display.showDescription = false;
			data.userDisplayName = App.user.get("displayName") || (App.user.get("firstName") + " " + App.user.get("lastName"));
			return data;
		},
		render : function() {
			this.$el.html(this.template(this.serialize()));
		},
		show : function(){
			this.$(".modal").modal("show");
		},
		onHidden : function(){
			this.trigger("hidden");
		},
		onPrintCertificate : function(){
			console.warn("print-certificate");
		},
		onEmailCertificate : function(event){
			var self = this;

			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}
			this.$(".email-certificate").button("loading");

			this.model.emailCertificate({
				success : function(){
					self.$(".email-certificate")
						.button("requested")
						.tooltip("show");

					_.defer(function(){
						self.$(".email-certificate").prop("disabled", true);
					});
					_.delay(function(){
						self.$(".email-certificate").tooltip("hide");
					}, 5000);
					App.analytics.trackEvent("Transcript", "Email: Certificate", self.model.get("sku"));
				},
				error : function(){
					self.$(".email-certificate")
						.prop("disabled", false).button("reset");

				}
			});
		}
	});
});
