define('routers/manual-preview',['require','library/vlp/router','views/manual-preview/main'],function (require) {
	"use strict";
	var BaseRouter = require("library/vlp/router"),
	    MainView   = require("views/manual-preview/main");


	return BaseRouter.extend({
		routeBase : "manual-preview",

		initialize : function(config){
			this.setup(config);
			this.mainView = new MainView(config);

			this.start();
		},
		routes : {
			":manualId" : "showPreview"
		},
		showPreview : function(manualId){
			this.mainView.showPreview(manualId);
		}
	});
});

