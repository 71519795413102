
define('hbs!tpls/console/console-listing.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0,depth0,{"name":"each","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.consoleType : depth0),"vm",{"name":"eq","hash":{},"fn":this.program(3, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.consoleType : depth0),"saas",{"name":"eq","hash":{},"fn":this.program(16, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"3":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper;

  return ((stack1 = helpers['if'].call(depth0,(depths[3] != null ? depths[3].isPrimaryStudentConsole : depths[3]),{"name":"if","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.program(6, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\n				<div class=\"vm thumbnail "
    + this.escapeExpression(((helper = (helper = helpers.osType || (depth0 != null ? depth0.osType : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"osType","hash":{},"data":data}) : helper)))
    + "\" data-vm=\""
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" rel=\"popover\" data-content=\"<div class='user-data'>"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.notes : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.program(10, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</div>\" title=\""
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" data-container=\"body\" data-html=\"true\">\n					<img src=\""
    + this.escapeExpression(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" width=\""
    + this.escapeExpression(this.lambda((depths[1] != null ? depths[1].thumbWidth : depths[1]), depth0))
    + "\" height=\""
    + this.escapeExpression(this.lambda((depths[1] != null ? depths[1].thumbHeight : depths[1]), depth0))
    + "\" alt=\"\"/>\n					<span class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vms.loading",{"name":"$","hash":{},"data":data}))
    + "</span>\n					<span class=\"powered-down\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vms.poweredDown",{"name":"$","hash":{},"data":data}))
    + "</span>\n					<h5>"
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h5>\n				</div>\n"
    + ((stack1 = helpers['if'].call(depth0,(depths[3] != null ? depths[3].isPrimaryStudentConsole : depths[3]),{"name":"if","hash":{},"fn":this.program(12, data, 0, blockParams, depths),"inverse":this.program(14, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"4":function(depth0,helpers,partials,data,blockParams,depths) {
    var helper;

  return "				<a href=\""
    + this.escapeExpression(this.lambda((depths[3] != null ? depths[3].vmUrlBase : depths[3]), depth0))
    + "vm-"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"thumbnail-holder\" data-switch-console=\"vm-"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n";
},"6":function(depth0,helpers,partials,data) {
    var helper;

  return "				<div class=\"thumbnail-holder\" data-switch-console=\"vm-"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n";
},"8":function(depth0,helpers,partials,data) {
    var helper;

  return this.escapeExpression(((helper = (helper = helpers.notes || (depth0 != null ? depth0.notes : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"notes","hash":{},"data":data}) : helper)));
},"10":function(depth0,helpers,partials,data) {
    return "<em>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.noNotes",{"name":"$","hash":{"singleQuote":true},"data":data}))
    + "</em>";
},"12":function(depth0,helpers,partials,data) {
    return "				</a>\n";
},"14":function(depth0,helpers,partials,data) {
    return "				</div>\n";
},"16":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper;

  return ((stack1 = helpers['if'].call(depth0,(depths[3] != null ? depths[3].isPrimaryStudentConsole : depths[3]),{"name":"if","hash":{},"fn":this.program(17, data, 0, blockParams, depths),"inverse":this.program(19, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "				<div class=\"saas thumbnail\" rel=\"popover\" data-content=\"<div class='user-data'>"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.notes : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.program(10, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</div>\" title=\""
    + this.escapeExpression(((helper = (helper = helpers.displayName || (depth0 != null ? depth0.displayName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"displayName","hash":{},"data":data}) : helper)))
    + "\" data-container=\"body\" data-html=\"true\">\n					<span class=\"saas-icon fa-stack fa-lg\">\n						<i class=\"fa fa-cloud fa-stack-2x fa-inverse\"></i>\n						<i class=\"fa fa-cogs fa-stack-1x\"></i>\n					</span>\n					<h5>"
    + this.escapeExpression(((helper = (helper = helpers.displayName || (depth0 != null ? depth0.displayName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"displayName","hash":{},"data":data}) : helper)))
    + "</h5>\n				</div>\n"
    + ((stack1 = helpers['if'].call(depth0,(depths[3] != null ? depths[3].isPrimaryStudentConsole : depths[3]),{"name":"if","hash":{},"fn":this.program(12, data, 0, blockParams, depths),"inverse":this.program(14, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"17":function(depth0,helpers,partials,data,blockParams,depths) {
    var helper;

  return "				<a href=\""
    + this.escapeExpression(this.lambda((depths[3] != null ? depths[3].vmUrlBase : depths[3]), depth0))
    + "saas-"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"thumbnail-holder\" data-switch-console=\"saas-"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n";
},"19":function(depth0,helpers,partials,data) {
    var helper;

  return "				<div class=\"thumbnail-holder\" data-switch-console=\"saas-"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n";
},"21":function(depth0,helpers,partials,data) {
    return "	<div class=\"no-consoles\">\n		<div class=\"alert\">\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vms.empty",{"name":"$","hash":{},"data":data}))
    + "\n		</div>\n	</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.length : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.program(21, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});
Handlebars.registerPartial('console_console_listing', t);
return t;
});

