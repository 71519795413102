define('views/instructor/console/main',['require','backbone','underscore','library/vlp/app','library/vlp/view','views/instructor/console/entitlement','models/class-entitlement','hbs!tpls/instructor/console/main.handlebars','i18n!nls/console'],function (require) {
	"use strict";

	var Backbone = require("backbone"),
		_        = require("underscore");

	var App                     = require("library/vlp/app"),
		BaseView                = require("library/vlp/view"),
		EntitlementView         = require("views/instructor/console/entitlement"),
		ClassEntitlement        = require("models/class-entitlement"),
		tpl                     = require("hbs!tpls/instructor/console/main.handlebars"),
		pageTexts               = require("i18n!nls/console");

	return BaseView.extend({
		template     : tpl,
		className    : "console",
		removeKeepEl : true,
		entitlementId : null,

		initialize: function (options) {
			_.bindAll(this);

			App.locale.mergeI18n(pageTexts);

			this.class        = options.class;
			this.panelManager = options.panelManager;

			return this;
		},

		showEntitlement : function(entitlementId){

			if(!entitlementId){
				entitlementId = App.entitlementId;
			}

			if(entitlementId != this.entitlementId){
				this.model = null;
				if(this.entitlementView){
					this.entitlementView.remove();
				}
				this.entitlementView = null;
			}

			this.entitlementId = entitlementId;
			App.entitlementId  = entitlementId;

			if (!this.model && entitlementId) {
				this.model = ClassEntitlement.Model.findOrCreate({
					id       : entitlementId,
					classId  : this.class.get("id"),
					className: this.class.get("name")
				});
			}

			if(this.model){
				App.VlpGlobal.setActiveEntitlement(this.model);
				this.model.fetch({
					params  : {
						"with" : ["account"]
					},
					success: this.renderEntitlement,
					error  : this.onEntitlementError
				});
			}

			this.render();
		},

		stop : function(){
			if(this.entitlementView){
				this.entitlementView.stop();
			}
		},

		renderEntitlement: function () {
			if (!this.entitlementView) {
				this.entitlementView = new EntitlementView({
					model       : this.model,
					class       : this.class,
					el          : this.$("#entitlement"),
					panelManager: this.panelManager
				});
			}
			App.VlpGlobal.setActiveEntitlementView(this.entitlementView);
			this.entitlementView.render();
		},

		onEntitlementError: function (model, response) {
			if (this._exiting) {
				return;
			}
			if (!(model instanceof Backbone.Model)) {
				response = model;
			}
			var message = App.i18n("errors.getLab");

			if (response && response.data && response.data.message) {
				message = _.escapeHTML(response.data.message);
			} else if (!response || !response.response) {
				message = App.i18n("errors.getLab");
			} else if (response.response.errorCode === 1310) {
				message = App.i18n("errors.labExpired");
			} else if (response.response.message && response.response.message.length > 0) {
				message = _.escapeHTML(response.response.message);
			}
			alert(message);
		},

		remove: function () {
			if (this.entitlementView) {
				this.entitlementView.stop();
				this.entitlementView.remove();
			}
			this.entitlementView = null;
			App.VlpGlobal.setActiveEntitlementView(null);
			App.VlpGlobal.setActiveEntitlement(null);
			BaseView.prototype.remove.apply(this, arguments);
		}
	});
});

