define('models/service-term',['require','exports','module','underscore','library/vlp/app','library/vlp/model'],function (require, exports) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model");


	//Use exports for requirejs circular dependency resolving
	var ServiceTerm = exports;

	//Add ServiceTerm to the App.Models namespace
	App.Models.ServiceTerm = ServiceTerm;


	ServiceTerm.Model = BaseModel.extend({
		urlRoot : "/serviceTerms",
		/**
		 * Default values for empty Models (new ServiceTerm.Model())
		 */
		defaults : {
			content : ""
		},

		/**
		 * Constructor
		 */
		initialize : function(){
		},
		parse : function(){
			var result = BaseModel.prototype.parse.apply(this, arguments);
			if(_.isString(result)){
				var tResult = {};
				tResult.content = result;
				result = tResult;
			}
			return result;
		},
		setters : { },
		fetch : function(options){
			options = options ? _.clone(options) : {};
			options.url = options.url || this.url() + "/getContent";

			return BaseModel.prototype.fetch.call(this, options);
		}
	});


	return ServiceTerm;

});

