(function(root) {
define("library/bootstrap/bootstrap-modal", ["jquery"], function() {
  return (function() {
/* =========================================================
 * bootstrap-modal.js v2.2.2
 * http://twitter.github.com/bootstrap/javascript.html#modals
 * =========================================================
 * Copyright 2012 Twitter, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================================================= */


!function ($) {

    "use strict"; // jshint ;_;


    /* MODAL CLASS DEFINITION
     * ====================== */

    var Modal = function (element, options) {
        this.options = options;
        this.$element = $(element)
            .delegate('[data-dismiss="modal"]', 'click.dismiss.modal', $.proxy(this.hide, this));
        this.$body = $(document.body);
    }

    Modal.prototype = {

        constructor: Modal

        , toggle: function () {
            return this[!this.isShown ? 'show' : 'hide']()
        }

        , show: function () {
            var that = this
                , e = $.Event('show')

            this.$element.trigger(e)

            if (this.isShown || e.isDefaultPrevented()) return;

            if (this.isHiding){
                if(this.$backdrop){
                    this.$backdrop.removeClass('fade in');
                    this.$backdrop.off($.support.transition.end);
                    this.removeBackdrop();
                }
                if (this.$element.hasClass('fade')){
                    this.$element.off($.support.transition.end);
                    this.$element.removeClass('fade');
                    this.hideModal();
                    this.$element.addClass('fade');
                } else{
                    this.hideModal();
                }
            }

            this.isShowing = true;
            this.isShown = true;

            this.$body.addClass('modal-open');

            this.escape()
            if(this.$element.hasClass('global')){
                this.$placeHolder = $('<div style="display:none"></div>');
                this.$element.before(this.$placeHolder);
                this.$element.detach();
                if($('#modal_holder').length){
                    this.$element.appendTo($('#modal_holder'));
                } else{
                    if($('body > .modal').length){
                        this.$element.insertBefore(('body > .modal:first'));
                    } else{
                        this.$element.appendTo(document.body);
                    }
                }
                this.$element.delegate('[data-dismiss="modal"]', 'click.dismiss.modal', $.proxy(this.hide, this))
                var maxZ = 0;
                $(".modal.in").each(function(){
                    if(this !== that.$element[0]){
                        maxZ = Math.max(maxZ, $(this).zIndex())
                    }
                })
                if(maxZ !== 0){
                    this.$element.zIndex(maxZ + 2)
                }
            }

            if(this.$element.hasClass('fluid') || this.$element.hasClass('fluid-height')|| this.$element.hasClass('fluid-width')){
                $(window).on('resize.modal', function(){ that.reflow();});
                this.$element.find('img').on('load.modal', function(){ that.reflow();});
                this.$element.on('show.modal shown.modal hide.modal hidden.modal', ".collapse", function(){ that.reflow();});
            }

            this.backdrop(function () {
                var transition = $.support.transition && that.$element.hasClass('fade')

                if (!that.$element.parent().length) {
                    that.$element.appendTo(document.body) //don't move modals dom position
                }

                that.$element
                    .show()

                if (transition) {
                    that.$element[0].offsetWidth // force reflow
                }

                that.$element
                    .addClass('in')
                    .attr('aria-hidden', false)
                that.reflow();

                that.enforceFocus()
                that.$element.trigger('showReady')
                transition ?
                    that.$element.one($.support.transition.end, $.proxy(that.shown, that)) :
                    that.shown()

                that.reflow();
            })
            if(this.options.remote){
                var $modalBody = this.$element.find(this.options.remoteTarget)
                if(!this.hasOwnProperty("$originalBody")){
                    this.$originalBody = $modalBody.html();
                }
                if(!this.originalClasses){
                    this.originalClasses = this.$element.attr('class').split(/\s/);
                } else{
                    var classes = this.$element.attr('class').split(/\s/);
                    for(var i = 0; i < classes.length; i++){
                        var tclass = classes[i];
                        if(tclass && $.inArray(tclass, this.originalClasses) == -1 && tclass != 'in'){
                            this.$element.removeClass(tclass);
                        }
                    }
                }
                $modalBody.html(this.$originalBody);
                var url = this.options.remote;
                if(!this.options.cache){
                    var selector,
                        off = url.indexOf(" "),
                        rquery = /\?/,
                        rts = /([?&])_=[^&]*/;

                    if(off >= 0){
                        selector = url.slice(off, url.length);
                        url = url.slice(0, off);
                    }
                    var ts = jQuery.now(),
                    // try replacing _= if it is there
                        ret = url.replace( rts, "$1_=" + ts );

                    // if nothing was replaced, add timestamp to the end
                    url = ret + ( ( ret === url ) ? ( rquery.test( url ) ? "&" : "?" ) + "_=" + ts : "" );

                    if(selector){
                        url = url + " " + selector;
                    }
                }
                if(url.match(/http:\/\//) && window.self.location.protocol == "https:"){
                    url = url.replace(/http:\/\//, "https://");
                }
                $modalBody.off('submit.modal', 'form');
                $modalBody.load(url, $.proxy(function(){
                    if(that.$element.find('.modal-classes').length){
                        var classes = that.$element.find('.modal-classes').attr('class');
                        classes = classes.replace('modal-classes', '');
                        that.$element.addClass(classes);
                        that.$element.find('.modal-classes').remove();
                        if(that.options.focus){
                            that.$element.find(that.options.focus).focus();
                        }
                    }

                    that.$element.trigger("remoteLoaded")

                    if(this.options.modalForm){

                        $modalBody.on('submit.modal', 'form', function(event){
                            var formItem = event.currentTarget;
                            event.preventDefault();
                            //var data = window.FormData ? new FormData(formItem) : $(formItem).serializeArray();
                            var data = $(formItem).serializeArray()

                            var url = $(event.currentTarget).attr('action');
                            $.ajax({
                                url : url,
                                data : data,
                                type: 'POST',
                                //processData: false,
                                //contentType: false,
                                success : function(response){
                                    $modalBody.html(response);
                                    that.$element.trigger("remoteSubmitted");
                                    that.submitted = true;
                                },
                                error   : function(jqXHR){
                                    $modalBody.html(jqXHR.responseText);
                                    that.$element.trigger("remoteError", [formItem]);
                                },
                                beforeSend : function(){
                                    that.$element.trigger("remoteSubmitting", [formItem]);
                                },
                                complete : function(){
                                    that.$element.scrollTop(0);
                                    that.reflow();
                                    that.$element.trigger("remoteComplete");
                                }
                            });

                        });
                        $modalBody.off('click.modal').on('click.modal', 'a:not([href^=mailto],[data-bypass],[data-dismiss],[data-toggle],[data-target])', function(event){
                            if($(event.currentTarget).attr('href')){
                                event.preventDefault();
                                $modalBody.html(that.$originalBody);
                                that.reflow();
                                $modalBody.load($(event.currentTarget).attr('href'), $.proxy(that.reflow, that));
                            }
                        });
                    }
                    this.reflow();
                }, this));
            }
        }

        , hide: function (e) {
            e && e.preventDefault()
            e && e.stopImmediatePropagation()

            var that = this

            e = $.Event('hide')

            this.$element.trigger(e)

            if (!this.isShown || e.isDefaultPrevented()) return

            if (this.isShowing) {
                if(this.$backdrop){
                    this.$backdrop.removeClass('fade in');
                    this.$backdrop.off($.support.transition.end);
                    this.removeBackdrop();
                }
                if(this.$element.hasClass('fade')) {
                    this.$element.off($.support.transition.end);
                    this.$element.removeClass('fade');
                    this.shown();
                    this.$element.addClass('fade');
                } else{
                    this.shown();
                }
            }

            this.isHiding = true;
            this.isShown = false

            this.$body.removeClass('modal-open');

            this.escape()

            $(document).off('focusin.modal')
            this.$element.find('.modal-body').off('submit.modal', 'form')
            this.$element
                .removeClass('in')
                .attr('aria-hidden', true)

            if(this.submitted && this.options.refreshAfterSubmit){
                window.self.location.reload();
            }
            $.support.transition && this.$element.hasClass('fade') ?
                this.hideWithTransition() :
                this.hideModal()
        }

        , enforceFocus: function () {
            var that = this
            $(document).on('focusin.modal', function (e) {
                if (that.$element[0] !== e.target && !that.$element.has(e.target).length && !$(e.target).hasClass("modal") && !$(e.target).closest(".modal").length) {
                    that.$element.focus()
                }
            })
        }
        , reflow: function () {
            if(!this.$element || !this.$element[0]) return;
            this.$element[0].offsetWidth // force reflow
            if(this.$element.hasClass('fluid') || this.$element.hasClass('fluid-width')){
                this.$element.css('margin-left', this.$element.outerWidth() / -2);
            }
            if(this.$element.hasClass('fluid') || this.$element.hasClass('fluid-height')){
                this.$element.css('margin-top', this.$element.outerHeight() / -2);
            }
            this.$element[0].offsetWidth // force reflow

            if($(document).height() < this.$element.height() && this.$element.css('position') == 'fixed') {
                this.$element.css('position', 'absolute');
            } else{
                this.$element.css('position', '');
            }

            var contentHeight = this.$element.outerHeight() - this.$element.height();
            this.$element.find("> *").each(function(){contentHeight+=this.scrollHeight});
            this.$element.toggleClass("full-height", $(window).height() < contentHeight);

            this.$element.css({top: ""});
            if(this.$element.offset().top < 0){
                this.$element.offset({top: 0});
                this.$element.offset({top: 0});
            }
        }
        , escape: function () {
            var that = this
            if (this.isShown && this.options.keyboard) {
                this.$element.on('keyup.dismiss.modal', function ( e ) {
                    e.which == 27 && that.hide()
                })
            } else if (!this.isShown) {
                this.$element.off('keyup.dismiss.modal')
            }
        }

        , hideWithTransition: function () {
            var that = this

            clearTimeout(this.hideTimeout)
            this.hideTimeout = setTimeout(function () {
                that.$element.off($.support.transition.end)
                that.hideModal()
            }, 500)

            this.$element.one($.support.transition.end, function () {
                clearTimeout(that.hideTimeout)
                that.hideModal()
            })
        }

        , hideModal: function (that) {
            var that = this
            clearTimeout(this.hideTimeout)

            if(this.$element.hasClass('global') && this.$placeHolder){
                this.$placeHolder.replaceWith(this.$element);
                delete(this.$placeHolder);
            }
            this.isHiding = false;

            this.$element
                .hide()
                .off('load.modal', 'img')
                .off('show.modal shown.modal hide.modal hidden.modal', '.collapse')
                .find('img').off('load.modal');
            $(window).off('resize.modal');
            this.$element.css("zIndex", null);
            this.backdrop(function(){
                that.$element.trigger('hidden')
            })
        }

        , shown: function () {
            this.$element.focus().trigger('shown')
            this.isShowing = false;
            this.isHiding = false;
        }
        , removeBackdrop: function (callback) {
            if(this.$backdrop){
                this.$backdrop.css("zIndex", null);
                this.$backdrop.remove()
            }
            this.$backdrop = null
            $('body').redraw()
            if(callback){
                callback()
            }
        }

        , backdrop: function (callback) {
            var that = this
                , animate = this.$element.hasClass('fade') ? 'fade' : ''

            if (this.isShown && this.options.backdrop) {
                var doAnimate = $.support.transition && animate

                if(!this.$backdrop){


                    if($('body > .modal-backdrop').length){
                        this.$backdrop = $('<div class="modal-backdrop" />')
                            .insertAfter($('body > .modal-backdrop:last'));

                        this.$backdrop.zIndex(this.$element.zIndex() - 1)
                        doAnimate = '';
                    } else{
                        this.$backdrop = $('<div class="modal-backdrop ' + animate + '" />')
                            .appendTo(document.body)
                    }

                    this.$backdrop.click(
                            this.options.backdrop == 'static' ?
                            $.proxy(this.$element[0].focus, this.$element[0])
                            : $.proxy(this.hide, this)
                    )
                }

                if (doAnimate) this.$backdrop[0].offsetWidth // force reflow

                this.$backdrop.addClass('in')

                doAnimate ?
                    this.$backdrop.one($.support.transition.end, callback) :
                    callback()

            } else if (!this.isShown && this.$backdrop) {
                this.$backdrop.removeClass('in')

                $.support.transition && this.$backdrop.hasClass('fade')?
                    this.$backdrop.one($.support.transition.end, $.proxy(function(){this.removeBackdrop(callback)}, this)) :
                    this.removeBackdrop(callback)

            } else if (callback) {
                callback()
            }
        }
    }


    /* MODAL PLUGIN DEFINITION
     * ======================= */

    var old = $.fn.modal

    $.fn.modal = function (option) {
        return this.each(function () {
            var $this = $(this)
                , data = $this.data('modal')
                , options = $.extend({}, $.fn.modal.defaults, $this.data(), typeof option == 'object' && option)
            if (!data) {
                $this.data('modal', (data = new Modal(this, options)))
            } else{
                data.options = $.extend({}, data.options, typeof option == 'object' && option)
            }
            if (typeof option == 'string') data[option]()
            else if (options.show) data.show()
        })
    }

    $.fn.modal.defaults = {
        backdrop: true
        , keyboard: true
        , show: true
        , modalForm : false
        , cache : false
        , refreshAfterSubmit : false
        , focus : null
        , remoteTarget : ".modal-body"
    }

    $.fn.modal.Constructor = Modal


    /* MODAL NO CONFLICT
     * ================= */

    $.fn.modal.noConflict = function () {
        $.fn.modal = old
        return this
    }


    /* MODAL DATA-API
     * ============== */

    $(document).on('click.modal.data-api', '[data-toggle="modal"]', function (e) {
        var $this = $(this)
            , href = $this.attr('href')
            , $target = $($this.attr('data-target') || (href && href.replace(/.*(?=#[^\s]+$)/, ''))) //strip for ie7
            , option = $target.data('modal') ? 'toggle' : $.extend({ remote:!/#/.test(href) && href }, $target.data(), $this.data())

        if($target.data('modal')){
            $target.data('modal').options = $.extend({}, $target.data('modal').options, $.fn.modal.defaults, $target.data(), $this.data())
        }
        e.preventDefault()

        $target
            .modal(option)
            .on('hide', function (event) {
                if(event.target === $target.get(0)){
                    $target.off('hide')
                    $this.focus()
                }
            })
    })

}(window.jQuery);


  }).apply(root, arguments);
});
}(this));

