define('nls/root/instructor',{
	"instructor": {
		"entitlements": {
			"empty"           : "There are no entitlements to show.",
			"allFetched"      : "All entitlements displayed.",
			"openConsole"     : "Open Console",
			"markAsResolved"  : "Mark As Resolved",
			"refresh"         : "Refresh",
			"search"          : "Search Name, vApp",
			"showThumbnail"   : "Thumbnail",
			"thumbnailTooltip": "Toggle Thumbnail",
			"phase" : {
				"notStarted" : "Not Started",
				"starting"   : "Starting",
				"running"    : "Running",
				"gone"       : "Gone",
				"exit"       : "Exited",
				"end"        : "Ended",
				"grade"      : "Grade",
				"logout"     : "Logged Out",
				"reset"      : "Resetting"
			}
		},
		"class"       : {
			"loading"         : "Loading...",
			"blankAllScreens" : "Blank All Screens",
			"enableAllScreens": "Enable All Screens",
			"warning"         : {
				"label"  : "Warn me when class is",
				"percent": {
					"label"  : "% Complete",
					"title"  : "Completion Progress",
					"message": "Class reached progress %s%%"
				},
				"step"   : {
					"label"  : "Step Number",
					"title"  : "Step Progress",
					"message": "Class reached step %s"
				}
			},
			"classInfo" :{
				"description": "Description",
				"notes"      : "Notes",
				"seats"      : "Seats",
				"startDate"  : "Start Date",
				"endDate"    : "End Date",
				"empty"      : "(empty)"
			},
			"updatedRefresh" : "Class has been updated. Please refresh the page to update the data.",
			"updated" : "Class has been updated. Refreshing data.",
			"labChooser" : {
				"label" : "Lab:"
			}
		},
		"instructors" : {
			"joinAsInstructor": "Join as Instructor"
		},
		"entitlement": {
			"launching"          : "Launching Student's Console",
			"backToClass"        : "Back to Class",
			"takeOver"           : "Take Over",
			"takeOverTip"        : "Take Over student's console",
			"sendNewToken"       : "Send New Token",
			"sendNewTokenTip"    : "Send new token to the student",
			"sendingNewToken"    : "Sending...",
			"sentNewToken"       : "Token sent",
			"broadcastDisplay"   : "Broadcast Display",
			"broadcastDisplayTip": "Broadcast this student's display to all other students",
			"stopTakeOver"       : "Release Control",
			"stopBroadcast"      : "Stop Broadcasting",
			"vAppReset"          : "vApp Reset. Please wait until the entitlement again is ready to view this console.",
			"errors"             : {
				"noVms"         : "Entitlement has no VMs. The entitlement may not have been started or the vApp has been powered down.",
				"cannotFetchVms": "Cannot load VM info. Entitlement may not have been started or the vApp has been powered down."
			},
			"studentConnection" : {
				"title"         : "Student's Console Connection",
				"strength"      : "Student's network connection strength to console",
				"unstable"      : "Student's network connection is unstable.",
				"connectionLost": "Student has lost their connection to the console.",
				"warning"       : "Student's network connection is degraded. Their console experience may slow."
			},
			"phaseChanged" : {
				"starting"   : "%s started the lab.",
				"gone"       : "%s left the lab.",
				"exit"       : "%s exited the lab.",
				"end"        : "%s ended the lab.",
				"grade"      : "%s submitted the lab for grading.",
				"logout"     : "%s logged out.",
				"reset"      : "%s reset the lab.",
				"error"      : "%s had an error with their lab."
			}
		}
	},
	"errors"    : {
		"invalidRequest": "Invalid Request"
	},
	"title"     : "Class"
});
