/**
 * Entitlement Expired Backbone View
 */
define('views/console/expired',['require','jquery','underscore','backbone','library/vlp/app','views/common/offers','views/common/announcements','hbs!tpls/console/expired.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App               = require("library/vlp/app"),
	    OffersView        = require("views/common/offers"),
	    AnnouncementsView = require("views/common/announcements"),
	    expiredTPL        = require("hbs!tpls/console/expired.handlebars");

	return Backbone.View.extend({
		template : expiredTPL,
		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		//el : "",

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .additional-time" : "additionalTime",
			"click .external-return" : "exitExternal",
			"click .survey-launch"   : "showSurveyModal"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			options = options || {};

			this.entitlementView = options.entitlementView;

			this.model.get("survey").on("change:url", function(){
				if(this.model.get("survey").has("url")){
					this.render();
				}
			}, this);

			this._showSurveyModalThrottled = _.debounce(this.showSurveyModal, 500);

			$("body").on("click", ".survey-start", this.surveyStart);
		},
		/**
		 * Display the content.
		 */
		render : function(){
			var self = this;
			var data = this.model.toHandlebars();
			data.externalSite  = App.session.get("externalSite");
			data.returnUrl     = _.sanitizeURL(App.session.get("returnUrl"));
			data.announcements = App.config.announcements;
			data.offers        = App.config.offers;
			data.ratingEnabled = App.config.ratingEnabled;

			data.surveyUrl        = this.model.get("survey").get("url");
			data.surveyImage      = this.model.get("survey").get("image");
			data.showPromoPage    = this.model.get("survey").get("showPromoPage");
			data.surveyPromoText  = this.model.get("survey").get("promoText");

			this.$el.html(this.template(data));

			if(App.config.offers){
				if(!this.offersView){
					this.offersView = new OffersView({
						el : this.$("#offers")
					});
				}

				this.offersView.fetch();
			}

			if(App.config.announcements){
				if(!this.announcementsView){
					this.announcementsView = new AnnouncementsView({
						el : this.$("#announcements")
					});
				}
				this.announcementsView.fetch();
			}


			if(this.model.get("survey").get("url")){
				if(!this.surveyShown){
					this.surveyShown = true;
					this.$(".survey").slideDown();
				} else{
					this.$(".survey").show();
				}
				if(this.model.get("survey").get("autoLaunch")){
					this._showSurveyModalThrottled();
				}
			}
			if(App.config.ratingEnabled){
				this.$(".rating").raty({
					click : function(score, evt){
						self.model.get("lab").rate(score, {
							success : function() {
								App.analytics.trackEvent("Lab", "Rate", self.model.get("lab").get("sku"), score);
							},
							error : function(model, resp){
								var response = resp.response || resp;
								var message = ((response && response.errorCode === 5301) ? App.i18n("labCompleted.alreadyRated") : _.escapeHTML(response.message));

								self.$(".rate .rating").tooltip({
									placement : "left",
									title     : message,
									trigger   : "manual",
									"class"   : "error"
								}).tooltip("show");

								_.delay(function(){
									self.$(".rate .rating").tooltip("destroy");
								}, 5000);
							}
						});
					}
				});
			}

			//Always return this for chaining
			return this;
		},

		additionalTime : function(event){
			if(event) {
				event.preventDefault();
			}
			alert("Not implemented");

		},
		showSurveyModal : function(){
			if(this.$surveyModal && $("#modal_survey").get(0) !== this.$surveyModal.get(0)){
				this.$surveyModal.removeClass("fade");
				this.$surveyModal.modal("hide");
				this.$surveyModal.remove();
				this.$surveyModal = null;
			}

			if(this.model.get("survey").get("showPromoPage")){
				this.showSurveyIntro();
			} else{
				this.surveyStart();

			}
		},
		showSurveyIntro : function(){
			var self = this;
			this.$surveyModal = $("#modal_survey");
			this.$surveyModal.off("hide.complete").on("hide.complete", function(){
				self.surveyModalShown = false;
			});
			this.$surveyModal
				.off("hidden.complete", this.entitlementView.startLogoutTimeout)
				.on("hidden.complete",  this.entitlementView.startLogoutTimeout);




			this.$surveyModal.removeClass("survey-survey").addClass("survey-intro fluid-height");

			this.$surveyModal.find(".survey-promo").show();
			this.$surveyModal.find(".survey-frame").hide();
			this.$surveyModal.modal("reflow");

			this.entitlementView.startLogoutTimeout();


			if(this.surveyModalShown) { return; }
			this.$surveyModal.modal("show");

			this.surveyModalShown = true;
		},
		surveyStart : function(){
			this.$surveyModal = $("#modal_survey");
			var self = this;
			this.$surveyModal.off("hide.complete").on("hide.complete", function(){
				self.surveyModalShown = false;
			});
			this.$surveyModal
				.off("hidden.complete", this.entitlementView.startLogoutTimeout)
				.on("hidden.complete",  this.entitlementView.startLogoutTimeout);

			this.$surveyModal.removeClass("survey-intro fluid-height").addClass("survey-survey");

			this.$surveyModal.find(".survey-promo").hide();
			this.$surveyModal.find(".survey-frame").show();
			this.$surveyModal.find(".survey-frame iframe")
				.attr("src", this.model.get("survey").get("url"))
				.load(function(){self.$surveyModal.modal("reflow");});

			this.$surveyModal.modal("reflow");


			if(this.entitlementView){
				this.entitlementView.stopLogoutTimeout();
			}
			if(this.surveyModalShown) { return; }

			this.$surveyModal.modal("show");
			this.surveyModalShown = true;
		},
		exitExternal : function(event){
			App.session.remove("externalSite");
			window.self.close();
			$("#modal_return").modal("show");
		}
	});

});

