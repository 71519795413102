/**
 * Tenant Chooser View
 */
define('views/common/tenant-chooser',['require','jquery','underscore','library/vlp/app','library/vlp/view','hbs!tpls/common/tenant-chooser.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App      = require("library/vlp/app"),
	    BaseView = require("library/vlp/view"),
	    tpl      = require("hbs!tpls/common/tenant-chooser.handlebars");

	return BaseView.extend({
		template : tpl,

	//Required
		el : "#tenants",
		events : {
			"click .tenant" : "tenantLink"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){

			_.bindAll(this);
			options = options || {};

			this.tenants = options.tenants;

		},
		/**
		 * Display the content.
		 */
		render : function(options){

			if(!options.chooseNewTenant){
				var activeTenant = App.getTenant().toLowerCase();
				_.each(this.tenants, function(tenant){
					tenant.active = (activeTenant === tenant.name.toLowerCase());
					tenant.defaultTenant = App.user.get("defaultTenant") && App.user.get("defaultTenant").toLowerCase() === tenant.name.toLowerCase();
				});
			}

			this.$el.html(this.template(this.tenants));
			if(options && options.force){
				$("#modal_tenant_chooser .close").hide();
			}

		},
		tenantLink : function(event){
			var tenantPath = $(event.currentTarget).data("tenantPath");
			var page = App.currentPage();
			if(page === App.config.pages.MAINTENANCE){
				page = App.config.pages.CATALOGS;
				App.redirect({page: page + window.self.location.search, tenant: tenantPath});
			} else{
				App.redirect({page: page + window.self.location.search + window.self.location.hash, tenant: tenantPath});
			}

		}
	});

});

