/* jshint maxdepth: 6 */
define('utilities/storage',['require','underscore','library/vlp/base-class','utilities/browser'],function(require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//class dependencies
	var BaseClass = require("library/vlp/base-class"),
	    Browser   = require("utilities/browser");


	var ONE_WEEK = 604800000; //In Milliseconds

	return BaseClass.extend({

		fallbackKey  : "__sess__",

		initialize : function() {
			var self = this;

			_.each(_.functions(this._common),   function(f) { self._common[f]   = _.bind(self._common[f],   self); });
			_.each(_.functions(this._fallback), function(f) { self._fallback[f] = _.bind(self._fallback[f], self); });
			_.each(_.functions(this.store),     function(f) { self.store[f]     = _.bind(self.store[f],     self); });
			_.each(_.functions(this.session),   function(f) { self.session[f]   = _.bind(self.session[f],   self); });
			_.each(_.functions(this.cookie),    function(f) { self.cookie[f]    = _.bind(self.cookie[f],    self); });
			_.each(_.functions(this.memory),    function(f) { self.memory[f]    = _.bind(self.memory[f],    self); });


			//Aliases
			this.store.add     = this.store.set;
			this.cookie.add    = this.cookie.set;
			this.session.add   = this.session.set;
			this.memory.add    = this.memory.set;
			this._fallback.add = this._fallback.set;

			if (Browser.support.sessionStorage && !Browser.support.localStorage) {
				this.store   = this.session;
			} else if (!Browser.support.sessionStorage && Browser.support.localStorage) {
				this.session = this.store;
			} else if (!Browser.support.sessionStorage && !Browser.support.localStorage && Browser.support.cookies) {
				this.session = this._fallback;
				this.store   = this._fallback;
			} else if(!Browser.support.sessionStorage && !Browser.support.localStorage){
				this.session = this.memory;
				this.store   = this.memory;
			}

			if(Browser.support.localStorage){
				this.store.gc(2, Date.now() - ONE_WEEK * 4);
			}

		},
		store      : {

			set    : function(key, value, priority) {
				var result = this._common.set(localStorage, key, value, priority);

				if(result !== true) {
					this.trigger("error", "localStorage" + result);
				}
				return result;
			},
			remove : function(key) {
				try {
					localStorage.removeItem(key);
				} catch (e) {
					this.trigger("error", "localStorageDisabled");
					return null;
				}
			},
			get    : function(key) {
				var result = null;
				try {
					result = localStorage.getItem(key);
				} catch (e) {
					this.trigger("error", "localStorageDisabled");
					return null;
				}

				if(result === null || result === ""){
					return result;
				}

				try {
					var data = JSON.parse(result);
					return (data === Object(data) && data.hasOwnProperty("_d") ? data._d : data);
				} catch (e) {
					return null;
				}
			},
			gc : function(priority, time){
				return this._common.clean(localStorage, priority, time);
			}
		},
		cookie     : {
			set    : function(key, value) {
				var stringified = JSON.stringify(value);
				document.cookie = key + "=" + stringified + "; path=/";
			},
			remove : function(key) {
				document.cookie = key + "=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
			},
			get    : function(key) {
				var result;
				//get from cookie
				var keyEQ = key + "=";
				var ca = document.cookie.split(";");
				for (var i = 0, len = ca.length; i < len; i++) {
					var c = ca[i];
					while (c.charAt(0) === " ") { c = c.substring(1, c.length); }
					if (c.indexOf(keyEQ) === 0) {
						result = c.substring(keyEQ.length, c.length);
						break;
					}
				}
				if (result !== null) {
					if (result === "") {
						return result;
					}
					try {
						return JSON.parse(result);
					} catch (e) {
						return null;
					}
				} else {
					return null;
				}
			},
			gc : function(priority, time){
				return 0;
			}
		},

		session    : {
			set    : function(key, value, priority) {
				var result = this._common.set(sessionStorage, key, value, priority);

				if(result !== true) {
					var tValue = JSON.stringify(value);
					//Fallback to store 0 priority items in the cookie
					if(priority < 2 && tValue.length <= 256){
						this.session.remove(key);
						var cookieSession = this.cookie.get(this.fallbackKey) || {};
						cookieSession[key] = value;

						this.cookie.set(this.fallbackKey, cookieSession);
						return true;
					} else{
						this.trigger("error", "sessionStorage" + result);
					}
				}

				return result;
			},
			remove : function(key) {
				try {
					sessionStorage.removeItem(key);
				} catch (e) {
					this.trigger("error", "sessionStorageDisabled");
				}
			},
			get    : function(key) {
				var result = null;
				try {
					result = sessionStorage.getItem(key);
				} catch (e) {
					this.trigger("error", "sessionStorageDisabled");
				}

				if(result === null && this.cookie.get(this.fallbackKey)){
					//Check if it got stored in a cookie
					var cookieSession = this.cookie.get(this.fallbackKey) || {};
					return cookieSession[key];
				}

				if(result === null || result === ""){
					return result;
				}

				try {
					var data = JSON.parse(result);
					return (data === Object(data) && data.hasOwnProperty("_d") ? data._d : data);
				} catch (e) {
					return null;
				}
			},
			gc : function(priority, time){
				return this._common.clean(sessionStorage, priority, time);
			}
		},
		memory     : {
			data   : {},
			set    : function(key, value, priority) {

				this.memory.data[key] = value;
			},
			remove : function(key) {
				delete this.memory.data[key];
			},
			get    : function(key) {
				if (!this.memory.data.hasOwnProperty(key)) {
					return null;
				}
				return this.memory.data[key];
			},
			gc : function(priority, time){
				return 0;
			}
		},
		_fallback : {
			data   : {},
			set    : function(key, value, priority) {
				var tValue = JSON.stringify(value);

				if(tValue.length <= 128 && priority < 2){
					var cookieSession = this.cookie.get(this.fallbackKey) || {};
					cookieSession[key] = value;

					this.cookie.set(this.fallbackKey, cookieSession);
					return true;
				} else{
					this.memory.data[key] = value;
				}
			},
			remove : function(key) {
				delete this.memory.data[key];

				var session = this.store.get(this.fallbackKey) || {};
				delete session[key];
				this.cookie.set(this.fallbackKey, session);
			},
			get    : function(key) {
				if (!this.memory.data.hasOwnProperty(key)) {
					var cookieSession = this.cookie.get(this.fallbackKey) || {};
					return cookieSession[key];
				}
				return this.memory.data[key];
			},
			gc : function(priority, time){
				return 0;
			}
		},
		_common : {
			clean : function(storage, priority, time, freeSpace){
				if(priority === undefined){
					priority = 2;
				}
				if(time === undefined || time === null){
					time = Date.now() - ONE_WEEK; //one week
				}
				var clearedLength = 0;
				var priorityRegex = new RegExp("['\"]\\s*_p\\s*['\"]\\s*:\\s*(\\d+)");
				var timeRegex     = new RegExp("['\"]\\s*_t\\s*['\"]\\s*:\\s*(\\d+)");


				var potentials = [];
				for(var i = storage.length - 1; i >= 0; i--){
					var key = storage.key(i);
					var value = storage.getItem(key);
					if(value === null || value === undefined){
						storage.removeItem(key);
						continue;
					}
					var prioirtyMatches = value.match(priorityRegex);
					if(!prioirtyMatches){
						clearedLength+= key.length + value.length;
						storage.removeItem(key);
						continue;
					}
					if(parseInt(prioirtyMatches[1], 10) === priority){
						var timeMatches = value.match(timeRegex);
						var matchTime = (timeMatches ? parseInt(timeMatches[1], 10) : 0);
						if(!timeMatches || matchTime < time){
							if(freeSpace){
								potentials.push({
									key    : key,
									time   : matchTime,
									length : value.length
								});
							} else{
								clearedLength+= key.length + value.length;
								storage.removeItem(key);
							}
							continue;
						}
					}
				}

				if(freeSpace && clearedLength < freeSpace){
					potentials = _.sortBy(potentials,  "time");
					for(var j = 0; j < potentials.length && clearedLength < freeSpace; j++){
						var info = potentials[j];
						clearedLength+= info.key.length + info.length;
						storage.removeItem(info.key);
					}
				}
				return clearedLength;
			},
			set : function(storage, key, value, priority){
				if(priority === undefined){
					priority = 2;
				}

				var data = JSON.stringify({
					_d : value,
					_p : priority,
					_t : Date.now()
				});

				try {
					storage.setItem(key, data);
				} catch (e) {
					if (e.toString().match(/quota/i) || e.name.match(/quota/i)) {
						//Ran out of space
						console.warn("Ran out of space. Trying to make room for:", key);
						for(var gcPriority = 2; gcPriority > 0 && gcPriority >= priority; gcPriority--){
							try{

								//Garbage Collection
								this._common.clean(storage, gcPriority, null, key.length + data.length);
								storage.setItem(key, data);

								return true;
							} catch(e2){
								try{
									//Remove all of this priority, but quit after enough free space is cleared
									this._common.clean(storage, gcPriority, Date.now(), key.length + data.length);
									storage.setItem(key, data);

									return true;
								} catch(e3){
									//One more time clearing everything all of this priority
									try{
										//Remove all of this priority
										this._common.clean(storage, gcPriority, Date.now());
										storage.setItem(key, data);

										return true;
									} catch(e4){ } //Move on to lower priority
								}

							}
						}
						return "Full";
					} else {
						return "Disabled";
					}
				}

				return true;
			}
		}
	});

});
