define('views/catalogs/catalog-listing',['require','underscore','backbone','library/vlp/app','library/vlp/view','models/catalog','views/catalogs/catalog','views/catalogs/catalog-menu','hbs!tpls/catalogs/catalog-empty.handlebars','hbs!tpls/catalogs/catalog-invalid.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App                  = require("library/vlp/app"),
	    BaseView             = require("library/vlp/view"),
	    Catalog              = require("models/catalog"),
	    CatalogInfoView      = require("views/catalogs/catalog"),
	    CatalogMenuView      = require("views/catalogs/catalog-menu"),
	    emptyTemplate        = require("hbs!tpls/catalogs/catalog-empty.handlebars"),
	    invalidTemplate      = require("hbs!tpls/catalogs/catalog-invalid.handlebars");

	return BaseView.extend({
		removeKeepEl : true,

		activeId      : null,
		activeCatalog : null,
		allCatalog    : null,
		labPageNum    : 1,
		visible       : false,
		firstLoad     : false,

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			options = options || {};
			options.fetchDefaults = options.fetchDefaults || {};


			this.firstLoad = true;
			this.fetchDefaults = _.extend({
				perPage  : 100,
				orderBy  : "displayOrder",
				orderDir : "asc",
				loginCancelable : true
			}, options.fetchDefaults);


			this.collection = new Catalog.Collection();

			this.allCatalog = new Catalog.Model({
				allCatalog : true
			});
			//this.listenTo(this.collection, "activeChanged", this.onActiveChanged);
			this.listenTo(this.collection, "sync", this.onFetched);
			this.listenTo(App, "catalogsReset", this.onUnenroll);

			this.search = options.search;

		},

		serialize : function(){
			var data = this.collection.toHandlebars();
			return data;
		},

		beforeRender : function(){
		},
		afterRender : function(){
		},
		show : function(activeId, labPageNum){

			var self = this;
			this.visible = true;

			if(!App.user.get("loggedIn")){
				this.stopListening(App.user, "change:loggedIn");
				this.listenToOnce(App.user, "change:loggedIn", function(){
					self.firstLoad = true;
					self.fetchPage(1);
				});
			}

			if(!this.catalogsMenuView){
				this.catalogsMenuView = new CatalogMenuView({
					el           : this.$("#catalog_nav"),
					$allNewCount : this.$(".all-labs-new-count"),
					collection   : this.collection
				});
				this.catalogsMenuView.render();
			}


			if(!this.collection.fetched && !this.collection.loading){
				this.fetchPage(1);
			}


			this.setActive(activeId, labPageNum);

		},
		hide : function(){
			this.visible = false;
			if(this.catalogView){
				this.catalogView.remove();
			}
			this.activeId = null;
			this.activeCatalog = null;
			this.labPageNum = 1;
		},
		setActive : function(activeId, labPageNum){
			if(!this.visible) { return; }

			if(activeId instanceof Backbone.Model){
				activeId = activeId.get("id");
			}

			if(activeId !== undefined){
				this.activeId = activeId;
			}

			if(labPageNum !== undefined){
				this.labPageNum = labPageNum;
			}


			if(!this.activeId && this.collection.length) {
				this.activeId = this.collection.first().get("id");
				App.router.navigate("catalog/" + this.activeId, {trigger : true, replace : true});
			} else if(this.activeId === "all" && this.collection.length === 1) {
				this.activeId = this.collection.first().get("id");
				App.router.navigate("catalog/" + this.activeId, {trigger : true, replace : true});
			}


			this.catalogsMenuView.setActive(this.activeId);
			var activeCatalog;

			if(this.activeId === "all"){
				activeCatalog = this.allCatalog;
			} else{
				activeCatalog = this.collection.get(this.activeId);
			}

			if(!activeCatalog && this.firstLoad && this.collection.length){
				activeCatalog = this.collection.first();
				console.warn("Invalid catalog. Switching to first catalog retrieved.");
				App.router.navigate("catalog/" + activeCatalog.get("id"), {trigger : true, replace : true});
			}

			if(activeCatalog){

				if(this.activeCatalog != activeCatalog){
					if(this.catalogView){
						this.catalogView.remove();
					}

					this.catalogView = new CatalogInfoView({
						model : activeCatalog,
						id : "catalog_" + activeCatalog.get("id")
					});
					this.$("#catalogs").empty().append(this.catalogView.el);
				}
				this.catalogView.show(this.labPageNum);

				this.activeCatalog = activeCatalog;

				if(this.activeCatalog.get("name")){
					App.mainView.setTitle(
						"title",
						(App.config.tenantName ? App.config.tenantName : App.getTenant()),
						this.activeCatalog.get("name"),
						"catalogs.catalogs.labs.title"
					);
				}

			} else if(this.collection.fetched){
				if(this.collection.length === 0) {
					this.$("#catalogs").empty().html(emptyTemplate());
				} else {
					this.$("#catalogs").empty().html(invalidTemplate());
				}
			}
		},
		onFetched : function(){
			if(this.collection.length > 1){
				this.allCatalog.set("id", this.collection.pluck("id").join(","));
				if(this.allCatalog.get("labs")) { this.allCatalog.get("labs").reset(); }

				this.collection.each(function(catalog){
					if(catalog.get("labs")){ catalog.get("labs").reset(); }
				});
			}
			this.setActive();
			this.firstLoad = false;
		},
		onUnenroll : function(){
			if(this.collection.length > 1){
				if(this.allCatalog.get("labs")) { this.allCatalog.get("labs").reset(); }

				this.collection.each(function(catalog){
					if(catalog.get("labs")){ catalog.get("labs").reset(); }
				});
			}
		}
	});

});
