define('views/compatibility/tests/websockets',['require','../test','utilities/browser','hbs!tpls/compatibility/tests/websockets.handlebars'],function (require) {
	"use strict";

	//class dependencies
	var TestBase = require("../test"),
		Browser  = require("utilities/browser"),
		template = require("hbs!tpls/compatibility/tests/websockets.handlebars");

	return TestBase.extend({
		template : template,
		name : "websockets",
		run : function(){
			var result = Browser.support.webSockets;

			if(result && Browser.safari && Browser.version.float < 6){
				result = false;
				this.warnings.incompleteSupport = true;
			}
			this.setStatus(result ? "success" : "failure");
			this.trigger(TestBase.COMPLETE, result);
		}
	});

});

