define('models/vm',['require','exports','module','underscore','backbone','library/vlp/app','library/vlp/model','library/vlp/collection'],function (require, exports) {
	"use strict";

	//library dependencies
	var _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection");

	//Use exports for requirejs circular dependency resolving
	var Vm = exports;

	//Add Vm to the App.Models namespace
	App.Models.Vm = Vm;


	//Class Constants
	Vm.POWERED_ON  = "POWERED_ON";
	Vm.POWERED_OFF = "POWERED_OFF";

	Vm.VMWARE     = "vmware";
	Vm.MICROSOFT  = "microsoft";
	Vm.APPLE      = "apple";
	Vm.LINUX      = "linux";
	Vm.OS_DEFAULT = "default";

	Vm.Model = BaseModel.extend({
		urlRoot : "/vms",
		pushService : "vms",

		defaults : {
			consoleType     : "vm",
			allowResize     : true,
			automaticResize : true,
			initial         : false,
			username        : "",
			password        : "",
			name            : null,
			os              : "",
			thumbnail       : "",
			host            : "",
			port            : null,
			vmx             : "",
			ticket          : "",
			notes           : ".",
			status          : Vm.POWERED_OFF,
			active          : false,
			networkName     : "",
			networkIp       : ""
		},
		types : {
			initial : Backbone.Types.Boolean
		},
		setters : {
			os : function(value, options){
				var osType = Vm.OS_DEFAULT;
				if(value){
					if(value.match(/microsoft|window|ms(\-| )?dos/i)){
						osType = Vm.MICROSOFT;
					} else if(value.match(/\bmac\b|os\s?x|apple|\bios\b/i)){
						osType = Vm.APPLE;
					} else if(value.match(/linux|red\s?hat|debian|centos|fedora|gentoo|suse|ubuntu|slackware|android/i)){
						osType = Vm.LINUX;
					} else if(value.match(/ESXi|vmware/i)){
						osType = Vm.VMWARE;
					}
				}
				this.set("osType", osType, options);
				return value;
			},
			osType : function(value){
				if(_.contains(value, [Vm.VMWARE, Vm.LINUX, Vm.APPLE, Vm.MICROSOFT, Vm.OS_DEFAULT])){
					return value;
				}
				var osType = Vm.OS_DEFAULT;
				if(value){
					if(value.match(/microsoft|window|ms(\-| )?dos/i)){
						osType = Vm.MICROSOFT;
					} else if(value.match(/\bmac\b|os\s?x|apple|\bios\b/i)){
						osType = Vm.APPLE;
					} else if(value.match(/linux|red\s?hat|debian|centos|fedora|gentoo|suse|ubuntu|slackware|android/i)){
						osType = Vm.LINUX;
					} else if(value.match(/ESXi|vmware/i)){
						osType = Vm.VMWARE;
					}
				}
				this.attributes.os = value;
				return osType;
			},
			thumbnail : function(value) {
				if(value === "null"){
					return null;
				}
				return value;
			}
		},
		getters :{
			poweredOn : function(){
				return this.attributes.status === Vm.POWERED_ON;
			},
			poweredOff : function(){
				return this.attributes.status === Vm.POWERED_ON;
			},
			connectionUrl : function(){
				return "wss://" + this.get("host") + "/" + this.get("port") + ";" + this.get("ticket");
			},
			port : function(){
				if (this.attributes.port) {
					return this.attributes.port;
				} else if(this.get("vmx")){
					return 902;
				} else {
					return 443;
				}
			},
			displayName: function(){
				return this.attributes.displayName ? this.attributes.displayName : this.get("name");
			}
		},
		initialize : function(){
		},
		parse : function(){
			var result = BaseModel.prototype.parse.apply(this, arguments);
			if(result && result.osType){
				result.os = result.osType;
				delete result.osType;
			}
			return result;
		},
		powerOn : function(options){
			var self = this;

			options = options || {};
			var success = options.success;
			options.success = function(response){
				self.set("status", Vm.POWERED_ON);
				if(success) {
					success(response);
				}
			};
			if(this.isNew()){
				throw new Error("Cannot power on a new VM");
			}
			return this.action("powerOn", options);
		},
		powerOff : function(options){
			var self = this;
			options = options || {};

			var success = options.success;
			options.success = function(response){
				self.set("status", Vm.POWERED_OFF);
				if(success) {
					success(response);
				}
			};

			if(this.isNew()){
				throw new Error("Cannot power off a new VM");
			}
			return this.action("powerOff", options);
		},
		screenTicket : function(options){
			var self = this;
			options = options || {};

			options.method = "read";
			options.properties = [];
			if(App.config.screenTicketPushReturn){
				options.pushReturn = true;
			}
			var success = options.success;
			options.success = function(response){
				self.set("status", Vm.POWERED_ON);
				if(success) {
					success(response);
				}
			};

			if(this.isNew()){
				throw new Error("Cannot get a screenTicket for a new VM");
			}
			return this.action("screenTicket", options);
		},
		powerReset : function(options){
			var self = this;
			options = options || {};

			var success = options.success;
			options.success = function(response){
				self.set("status", Vm.POWERED_ON);
				if(success) {
					success(response);
				}
			};

			if(this.isNew()){
				throw new Error("Cannot reset a new VM");
			}
			return this.action("reset", options);
		},
		getThumbnail : function(options){
			options = options || {};
			options.params = options.params || {};
			options.method = "read";
			options.retries = 0;
			return this.action("thumbnail", options);
		}
	});


	Vm.Collection = BaseCollection.extend({
		model : Vm.Model,
		comparator : function(model){
			if(model.has("name")) { return model.get("name"); }
			return model.id;
		}
	});

});

