define('views/instructor/main',['require','jquery','underscore','library/vlp/app','views/vlp-base','models/account','models/class','views/instructor/class','views/instructor/console/main','hbs!tpls/instructor/main.handlebars','views/common/public-profile','views/console/panel-manager','views/instructor/manual','views/console/video-conference','views/instructor/resources','views/instructor/messages','i18n!nls/console','views/instructor/help-request-instructor','i18n!nls/instructor'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App                             = require("library/vlp/app"),
	    VlpBaseView                     = require("views/vlp-base"),
	    Account                         = require("models/account"),
	    Class                           = require("models/class"),
	    ClassView                       = require("views/instructor/class"),
	    ConsoleView                     = require("views/instructor/console/main"),
	    mainTPL                         = require("hbs!tpls/instructor/main.handlebars"),
	    PublicProfileView               = require("views/common/public-profile"),
	    PanelManager                    = require("views/console/panel-manager"),
	    ManualView                      = require("views/instructor/manual"),
	    VideoConferenceView             = require("views/console/video-conference"),
	    ResourcesView                   = require("views/instructor/resources"),
	    MessagesView                    = require("views/instructor/messages"),
	    pageTextsConsole                = require("i18n!nls/console"),
	    HelpRequestInstructorView       = require("views/instructor/help-request-instructor"),
	    pageTextsInstructor             = require("i18n!nls/instructor");

	return VlpBaseView.extend({
		name: "INSTRUCTOR",
		className: "general-page",

		//Required
		mainTemplate: mainTPL,
		events: {
			"click .public-profile"   : "showPublicProfile",
			"click .dock-arrow-left"  : "setPadding",
			"click .dock-arrow-right" : "setPadding",
			"click .toggle-dock-float": "setPadding"
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize: function (options) {
			_.bindAll(this);

			$("body").addClass("console-common");

			this.options = options || {};

			App.locale.mergeI18n(pageTextsConsole);
			App.locale.mergeI18n(pageTextsInstructor);

			this.setup(options);
			this.once(VlpBaseView.STARTUP_COMPLETE, this.launch);

			this.on("App.config.change", this.alignPanels, this);
		},

		alignPanels: function () {
			$("#instructions_panel").removeClass("left right");
			$("#instructions_panel").data("position", App.config.instructionsPanelLocation);
			$("#instructions_panel").addClass(App.config.instructionsPanelLocation);

			$("#resources_panel").removeClass("left right");
			$("#resources_panel").data("position", App.config.instructionsPanelLocation);
			$("#resources_panel").addClass(App.config.instructionsPanelLocation);

			$("#vm_listing_panel").data("position", App.config.vmListingPanelLocation);
			$("#vm_listing_panel").removeClass("left right");
			$("#vm_listing_panel").addClass(App.config.vmListingPanelLocation);

			$("#video_conference_panel").data("position", App.config.videoConferencePanelLocation);
			$("#video_conference_panel").removeClass("left right");
			$("#video_conference_panel").addClass(App.config.videoConferencePanelLocation);
		},

		setPadding: function(){
			if($(".dock-left").html() == 0){
				this.$("#class").css("padding-left","20px");
			} else{
				this.$("#class").css("padding-left","45px");
			}

			if($(".dock-right").html() == 0){
				this.$("#class").css("padding-right","20px");
			} else{
				this.$("#class").css("padding-right","45px");
			}
		},

		showClass: function (classId) {
			this.$("#class").show();
			this.$("#active_entitlement").hide();
			App.entitlementId = null;
			this.setupClass(classId);
		},

		showConsole : function(classId, entitlementId){
			this.$("#class").hide();
			this.$("#active_entitlement").show();
			App.entitlementId = entitlementId;
			this.setupClass(classId);
		},

		setupClass : function(classId){
			if(this.isLoggedIn()){
				if(!classId){
					classId = App.classId;
				}

				if(classId === null || classId === undefined || classId === 0 || classId === ""){
					this.$(".invalid-request").show();
					this.$("#entitlement").hide();
				} else{
					this.$(".invalid-request").hide();
					this.$("#entitlement").show();

					this.classId = classId;
					App.classId = classId;
					this.start();
				}
			} else{
				var self = this;
				this.once(VlpBaseView.LOGGED_IN, function(){
					self.setupClass(classId);
				});
			}
		},

		launch : function(){
			if(App.config.defaultConsolePosition === "maximized"){
				App.config.defaultConsolePosition = "center";
			}
			this.$("#entitlement").show();
		},

		start : function(){
			$(".panels").hide();

			var self = this;

			if(!this.model){
				this.model = new Class.Model({id : this.classId});
				this.model.fetch({
					params : {"with" : ["labs", "instructors", "entitlements", "videoConference"]},
					success : this.renderClass,
					error : function(model, response, settings){
						if(response.status == "403"){
							alert(App.i18n("errors.accessDenied"));
							App.redirect({page: App.config.pages.HOME});
						} else{
							return self._ajaxError(model, response, settings);
						}
					}
				});
			} else{
				this.renderClass();
			}
		},

		createPanelManager :function(){
			var self = this;
			if(!this.panelManager){
				this.panelManager = new PanelManager({
					el        : this.$(".panels")
				});

				this.$(".panels > .panel").each(function(){
					self.panelManager.addPanel(this);
				});
				this.panelManager.updateContainers(true);
				this.consoleListingPanel = this.panelManager.getPanel("#vm_listing_panel");
				if(this.consoleListingPanel){
					this.consoleListingPanel.hide();
				}
			}
		},

		renderClass : function(){

			if(!this.model.get("activeLabSku")){
				var activeLab = this.model.get("labs") && this.model.get("labs").findWhere({manualId: this.model.get("manualId")});
				if(activeLab){
					this.model.set("activeLabSku", activeLab.get("sku"));
				}
			}
			
			this.createPanelManager();

			if(!this.classView){
				this.classView = new ClassView({
					model : this.model,
					el    : this.$("#class"),
					panelManager : this.panelManager
				});
			}
			this.classView.render();

			if(App.entitlementId){
				if(!this.consoleView){
					this.consoleView = new ConsoleView({
						model       : this.model,
						el          : this.$("#active_entitlement"),
						class       : this.model,
						panelManager: this.panelManager
					});
				}
				if(this.model.get("entitlements")){
					var entitlement = this.model.get("entitlements").findWhere({entitlementKey: App.entitlementId});
					if(entitlement){
						this.model.set("activeLabSku", entitlement.get("labSku"));
					}
				}
				this.consoleView.showEntitlement(App.entitlementId);
			} else{
				if(this.consoleView){
					this.consoleView.remove();
					this.consoleView = null;
				}
				this.classView.clearEntitlementChanges();
			}


			if(!this.resources){
				this.resources = new ResourcesView({
					el                  : this.$("#resources"),
					collection          : this.model.get("resources"),
					class               : this.model,
					labs                : this.model.get("labs"),
					panel               : this.panelManager.getPanel("#resources_panel"),
				});
				this.resources.render();
			}

			if(!this.manualListing){
				this.manualListing = new ManualView({
					el                  : this.$("#instructions"),
					model               : this.model.get("manual"),
					manualId            : this.model.get("manualId"),
					networkTopology     : this.model.get("networkTopology"),
					labs                : this.model.get("labs"),
					class               : this.model,
					title               : this.model.get("name"),
					panel               : this.panelManager.getPanel("#instructions_panel"),
					consoleListingPanel : this.panelManager.getPanel("#vm_listing_panel")
				});
				App.VlpGlobal.setManualView(this.manualListing);
				this.manualListing.render();
			}

			if(!this.messagesView){
				this.messagesView = new MessagesView({
					el                  : this.$("#messages"),
					panel               : this.panelManager.getPanel("#instructions_panel")
				});
				this.messagesView.render();
			}

			if (!this.helpRequestInstructorView) {
				this.helpRequestInstructorView = new HelpRequestInstructorView({
					el: this.$("#help-requests"),
					panel: this.panelManager.getPanel("#help_request_instructor_panel"),
					classId: this.model.get("id")
				});
				this.helpRequestInstructorView.render();
			}


			if(!this.videoConference){
				this.videoConference = new VideoConferenceView({
					el                  : this.$("#video_conference"),
					mode                : "instructor",
					entitlement         : this.model,
					panel               : this.panelManager.getPanel("#video_conference_panel")
				});
				this.videoConference.render();
			}


			$(".panels").show();
		},

		showPublicProfile: function (event) {
			var $item = $(event.currentTarget);

			$(".modal").removeClass("fade").modal("hide");

			var account = Account.Model.findOrCreate({
				id: $item.data("id"),
				username: $item.data("username")
			});

			if (this.publicProfileView) {
				this.publicProfileView.remove();
				this.publicProfileView = null;
			}

			this.publicProfileView = new PublicProfileView({
				model: account
			});
			this.publicProfileView.show(1);
		}
	});

});

