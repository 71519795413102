/*globals App */
/**
 * Base Backbone Collection
 *
 * This is a base collection for all relational Collections with the standard data format returned by the server.
 */
define('library/vlp/collection',['require','exports','module','jquery','underscore','backbone','library/vlp/model'],function (require, exports) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var BaseModel = require("library/vlp/model");

	//The Backbone model definition
	return Backbone.Collection.extend({
		fetched : false,
		loading : false,

		totalCount : 0,

		page       : 0,
		perPage    : 10,
		pageCount  : 1,
		constructor : function(options){

			options = options || {};
			Backbone.Collection.prototype.constructor.apply(this, arguments);

			if(_.isEmpty(this.urlRoot) && this.model && _.isEmpty(this.model.prototype.urlRoot)){
				this.urlRoot = this.model.prototype.urlRoot;
			}

			if(_.isEmpty(this.pushService) && this.model && !_.isEmpty(this.model.prototype.pushService)){
				this.pushService = this.model.prototype.pushService;
			}

			if(options.orderBy){
				this.orderBy = options.orderBy;
			}
			if(options.orderDir){
				this.orderDir = options.orderDir;
			}
			if(options.perPage){
				this.perPage = options.perPage;
			}


			if(options.pushListen){
				this._setupPush();
			}
			this.listenTo(this, "request", this._startFetch);
			this.listenTo(this, "sync",    this._endFetch);
			this.listenTo(this, "error",   this._errorFetch);

		},
		url : function(models){
			var url;

			var urlRoot = this.basePath();
			if(urlRoot.match(/^https?:/)){
				url = urlRoot;
			} else{
				url = this.serverBase + urlRoot;
			}

			url = url || ( models && models.length && models[0].basePath() );

			// Build a url to retrieve a set of models. This assume the last part of each model's idAttribute
			// (set to "resource_uri") contains the model"s id.
			if(models && models.length){
				url += _.pluck(models, "id").join(",") + "/";
			}

			return url || null;
		},

		basePath : function(){
			var basePath = _.result(this, "urlRoot");
			if(!basePath && this.model){
				basePath = this.model.prototype.urlRoot;
			}

			if(this.serverBase.charAt(this.serverBase.length - 1) === "/" && basePath.charAt(0) === "/"){
				basePath = basePath.substring(1);
			}
			if(basePath.charAt(this.serverBase.length - 1) !== "/"){
				basePath += "/";
			}
			return basePath;
		},

		_makeURL : function(options, model){
			var url = options.url;
			if(!url){
				if(model && model.url){
					url = model.url();
				} else{
					url = this.url();
				}
			}
			if(this.syncParams){
				options.params = options.params || {};
				_.defaults(options.params, this.syncParams, Backbone.Collection.prototype.syncParams);
			}
			if(options.params){
				if(url.indexOf("?") === -1){
					url += "?";
				} else{
					url += "&";
				}
				url += $.param(options.params);
			}
			return url;
		},
		sync : function(method, model, options){
			return BaseModel.prototype.sync.call(this, method, model, options);
		},

		reset : function(){
			this.fetched = false;
			this.page = 0;
			return Backbone.Collection.prototype.reset.apply(this, arguments);
		},
		parse : function(response, options){
			var result = response;

			if(options && !options._parsed){
				options._parsed = true;
				if(!result || !result.hasOwnProperty("data")){
					return [];
				}
			}

			if(result && result.hasOwnProperty("data")) { result = result.data; }

			if(_.isObject(result)){
				delete result[""];
				delete result._empty_;
			}

			if(_.isEmpty(result) || result === null || result === "null"){
				result = [];
			}
			if(_.isIndexArray(result)){
				result = _.values(result);
			}
			if(!_.isArray(result)){
				result = [result];
			}

			this.totalCount = response.count || result.length;

			this.pageCount = Math.ceil(this.totalCount / this.perPage);
			if(this.nextPage > this.pageCount){
				this.nextPage = null;
			}

			return result;
		},
		fetch : function(options){

			var tOptions = _.clone(options || {});

			tOptions.params = tOptions.params || {};

			for(var i in tOptions.params){
				if(tOptions.params.hasOwnProperty(i) && _.isArray(tOptions.params[i])){
					tOptions.params[i] = tOptions.params[i].join(",");
				}
			}

			if(tOptions.perPage){
				this.perPage = tOptions.perPage;
			} else if(tOptions.limit){
				this.perPage = tOptions.limit;
			} else if(tOptions.params.perPage){
				this.perPage = tOptions.params.perPage;
			} else if(tOptions.params.limit){
				this.perPage = tOptions.params.limit;
			}
			if(tOptions.hasOwnProperty("page")){
				tOptions.page = parseInt(tOptions.page, 10);
				if(tOptions.page <= 0){
					tOptions.page = 1;
				}
				tOptions.params.limit = this.perPage;
				tOptions.params.offset = (tOptions.page - 1) * this.perPage;

				this.page = tOptions.page;
				this.nextPage = tOptions.page + 1;
				this.prevPage = Math.max(tOptions.page - 1, 0);

				delete tOptions.page;
			}
			if(tOptions.orderBy && !tOptions.params.orderBy){
				tOptions.params.orderBy = tOptions.orderBy;
			} else if(this.orderBy && !tOptions.params.orderBy){
				tOptions.params.orderBy = this.orderBy;
			}
			if(tOptions.orderDir && !tOptions.params.orderDir){
				tOptions.params.orderDir = tOptions.orderDir;
			} else if(this.orderDir && !tOptions.params.orderDir){
				tOptions.params.orderDir = this.orderDir;
			}
			if(tOptions.error){
				tOptions._hasOriginalError = true;
			}
			//call parent setup
			return Backbone.Collection.prototype.fetch.call(this, tOptions);

		},
		fetchPage : function(pageNumber, options){
			var tOptions = _.clone(options || {});
			tOptions.page = pageNumber;
			return this.fetch(tOptions);
		},
		fetchNext : function(options){
			return this.fetchPage(this.page + 1, options);
		},
		fetchPrev : function(options){
			return this.fetchPage(this.page - 1, options);
		},
		toHandlebars : function(){
			return Backbone.toHandlebars(this);
		},

		_startFetch : function(collection){
			if(collection === this) {
				this.loading = true;
			}
		},
		_endFetch : function(collection){
			if(collection === this){
				this.loading  = false;
				this.fetched = true;
			}
		},
		_errorFetch : function(collection){
			if(collection === this) {
				this.loading = false;
			}
		},
		_setupPush : function(pushService){
			pushService = pushService || this.pushService;
			if(_.isEmpty(pushService)) { return; }
			var self = this;
			this.listenTo(App.push, pushService, function(info){
				self.add(info.data);
			});

		}
	});

});
