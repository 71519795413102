
define('hbs!tpls/support/entitlement.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "true";
},"3":function(depth0,helpers,partials,data) {
    return "false";
},"5":function(depth0,helpers,partials,data) {
    return "expanded";
},"7":function(depth0,helpers,partials,data) {
    return "collapsed";
},"9":function(depth0,helpers,partials,data) {
    return "hide";
},"11":function(depth0,helpers,partials,data) {
    return " connection-monitoring";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div id=\"lab\">\n\n	<div class=\"panels\">\n\n		<div class=\"dock-left\"></div>\n		<div class=\"dock-right\"></div>\n		<div class=\"floating\"></div>\n\n		<div id=\"vm_listing_panel\" data-position=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"vmListingPanelLocation",{"name":"config","hash":{},"data":data}))
    + "\" data-expanded=\"false\" class=\"panel docked left collapsed\" data-resize-mode=\"height\" data-order=\"1\">\n\n\n			<div data-function=\"slide-or-focus\" class=\"open-close-panel\">\n				<span class=\"tab-img fa fa-desktop\"></span>\n				<div class=\"tab-caption\"><span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.consoleTitle",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n			</div>\n\n			<div class=\"panel-interior\">\n\n				<div class=\"panel-controls\">\n					<ul>\n						<li><button data-function=\"close\" class=\"btn-no-style dock-close fa fa-times\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.close",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"float\" class=\"btn-no-style toggle-dock-float fa fa-external-link\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.toggleFloat",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"dock-left\" class=\"btn-no-style dock-arrow-left fa fa-caret-left\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockLeft",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"dock-right\" class=\"btn-no-style dock-arrow-right fa fa-caret-right\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockRight",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n					</ul>\n				</div>\n\n				<div id=\"vm_listing\" class=\"content\">\n					<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.consolesLoading",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n			</div>\n		</div>\n\n		<div id=\"instructions_panel\" data-position=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"instructionsPanelLocation",{"name":"config","hash":{},"data":data}))
    + "\" data-expanded=\"false\" data-resize-mode=\"both\" class=\"panel docked right collapsed\" data-order=\"0\">\n\n			<div data-function=\"slide-or-focus\" class=\"open-close-panel\">\n				<span class=\"tab-img fa fa-book\"></span>\n				<div class=\"tab-caption\"><span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.manualTitle",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n			</div>\n\n			<div class=\"panel-interior\">\n\n				<div class=\"panel-controls\">\n					<ul>\n						<li><button data-function=\"close\" class=\"btn-no-style dock-close expanded fa fa-times\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.close",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"float\" class=\"btn-no-style toggle-dock-float fa fa-external-link\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.toggleFloat",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"dock-left\" class=\"btn-no-style dock-arrow-left fa fa-caret-left\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockLeft",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"dock-right\" class=\"btn-no-style dock-arrow-right fa fa-caret-right\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockRight",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<!--<li><a data-function=\"bottom\" class=\"dock-arrow-down btn-clear btn-clear-mini\"></a></li>-->\n					</ul>\n				</div>\n\n\n				<div id=\"instructions\" class=\"content\">\n					<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.manualLoading",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n\n			</div>\n		</div>\n\n		<div id=\"resources_panel\" data-position=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"instructionsPanelLocation",{"name":"config","hash":{},"data":data}))
    + "\" data-expanded=\"false\" data-resize-mode=\"both\" class=\"panel docked right collapsed\" data-order=\"2\">\n\n			<div data-function=\"slide-or-focus\" class=\"open-close-panel\">\n				<span class=\"tab-img fa fa-files-o\"></span>\n				<div class=\"tab-caption\"><span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.resources",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n			</div>\n\n			<div class=\"panel-interior\">\n\n				<div class=\"panel-controls\">\n					<ul>\n						<li><button data-function=\"close\" class=\"btn-no-style dock-close expanded fa fa-times\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.close",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"float\" class=\"btn-no-style toggle-dock-float fa fa-external-link\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.toggleFloat",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"dock-left\" class=\"btn-no-style dock-arrow-left fa fa-caret-left\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockLeft",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"dock-right\" class=\"btn-no-style dock-arrow-right fa fa-caret-right\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockRight",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n					</ul>\n				</div>\n\n\n				<div id=\"resources\" class=\"content\">\n					<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n\n			</div>\n		</div>\n\n		<div id=\"video_conference_panel\" data-position=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"videoConferencePanelLocation",{"name":"config","hash":{},"data":data}))
    + "\" data-expanded=\""
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.remoteControl : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\" data-resize-mode=\"both\" class=\"panel docked right "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.remoteControl : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hideVideoConferencePanel : depth0),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-order=\"3\">\n\n			<div data-function=\"slide-or-focus\" class=\"open-close-panel\">\n				<span class=\"tab-img fa fa-video-camera\"></span>\n				<div class=\"tab-caption\"><span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.videoConferenceTitle",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n			</div>\n\n			<div class=\"panel-interior\">\n\n				<div class=\"panel-controls\">\n					<ul>\n						<li><button data-function=\"close\" class=\"btn-no-style dock-close expanded fa fa-times\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.close",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"float\" class=\"btn-no-style toggle-dock-float fa fa-external-link\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.toggleFloat",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"dock-left\" class=\"btn-no-style dock-arrow-left fa fa-caret-left\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockLeft",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"dock-right\" class=\"btn-no-style dock-arrow-right fa fa-caret-right\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockRight",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<!--<li><a data-function=\"bottom\" class=\"dock-arrow-down btn-clear btn-clear-mini\"></a></li>-->\n					</ul>\n				</div>\n\n\n				<div id=\"video_conference\" class=\"content\">\n					<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.videoConferenceLoading",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n\n		</div>\n	</div>\n\n	<div id=\"info_bar\">\n		<div class=\"info-bar-inner\">\n			<div class=\"lab-name\" data-toggle=\"modal\" data-target=\"#modal_detailed_information\">\n				<i class=\"fa fa-info-circle\"></i> <span>"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.lab : depth0)) != null ? stack1.name : stack1), depth0))
    + "</span>\n			</div>\n\n			<div class=\"student-info-id\">\n				<a data-username=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.username : stack1), depth0))
    + "\" class=\"public-profile\" data-id=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" data-name=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\">\n					<img class=\"student-info-images\" src=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.avatar : stack1), depth0))
    + "\" alt=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\">\n				</a>\n\n				<a data-username=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.username : stack1), depth0))
    + "\" class=\"student-info-student-name public-profile\" data-id=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" data-name=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\">"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "</a>\n			</div>\n\n			<div class=\"student-progress\">\n				<div id=\"progressbar\" class=\"student-info-progress\">\n					<div class=\"student-info-progressbar\">\n						<div class=\"progress-bar\" style=\"width: "
    + this.escapeExpression(((helper = (helper = helpers.progress || (depth0 != null ? depth0.progress : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"progress","hash":{},"data":data}) : helper)))
    + "%;\">\n							<span class=\"progress\">"
    + this.escapeExpression((helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.progress : depth0),{"name":"percent","hash":{},"data":data}))
    + "</span>\n						</div>\n					</div>\n\n					<label><span class=\"completed-steps\">"
    + this.escapeExpression(((helper = (helper = helpers.activeStepNumber || (depth0 != null ? depth0.activeStepNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"activeStepNumber","hash":{},"data":data}) : helper)))
    + "</span>/<span class=\"total-steps\">"
    + this.escapeExpression(((helper = (helper = helpers.totalSteps || (depth0 != null ? depth0.totalSteps : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"totalSteps","hash":{},"data":data}) : helper)))
    + "</span>\n					</label>\n					<i class=\"fa fa-book\"></i>\n				</div>\n				<div class=\"time-remaining-wrapper\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.timeRemaining",{"name":"$","hash":{},"data":data}))
    + " <span class=\"time-remaining\">00:00:00</span>\n				</div>\n			</div>\n\n			<div class=\"settings-wrapper\">\n				<button type=\"button\" class=\"btn-no-style settings\" data-toggle=\"modal\" data-target=\"#modal_settings\" rel=\"tooltip\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.settings.title",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"left\"><span class=\"fa fa-cog\"></span></button>\n			</div>\n		</div>\n	</div>\n\n	<div id=\"info_bar_maximize\" class=\"hide"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.studentConsoleConnectionMonitoring : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n		<span class=\"time-remaining-label\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.timeRemaining",{"name":"$","hash":{},"data":data}))
    + "</span> <span class=\"time-remaining\">00:00:00</span>\n	</div>\n	<div id=\"vm\">\n		<div id=\"vm_group\" class=\"temp-loading\"><div id=\"vm_above_bar\"></div></div>\n		<div class=\"loading building-lab\">\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"support.console.building",{"name":"$","hash":{},"data":data}))
    + "\n		</div>\n	</div>\n	<div id=\"saas_console\"></div>\n</div>\n\n<div id=\"model_status_message\" class=\"modal global hide fade fluid-height\" data-backdrop=\"static\" data-keyboard=\"false\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<h3 class=\"modal-title\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"support.statusMessages.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<p class=\"status-message\"></p>\n			</div>\n		</div>\n	</div>\n</div>\n\n\n<div id=\"modal_detailed_information\" class=\"modal hide fade global fluid-height\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-info-circle\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.labInformation",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body user-data\">\n				<div class=\"lab-information\">\n					<h3>"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.lab : depth0)) != null ? stack1.name : stack1), depth0))
    + "</h3>\n					<p>"
    + ((stack1 = this.lambda(((stack1 = (depth0 != null ? depth0.lab : depth0)) != null ? stack1.description : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"entitlement_settings\"></div>\n";
},"useData":true});
Handlebars.registerPartial('support_entitlement', t);
return t;
});

