define('views/instructor/entitlement',['require','jquery','underscore','library/vlp/app','library/vlp/view','hbs!tpls/instructor/entitlement.handlebars'],function (require) {
	"use strict";

	var $ = require("jquery"),
		_ = require("underscore");

	var App = require("library/vlp/app"),
		BaseView = require("library/vlp/view"),
		tpl = require("hbs!tpls/instructor/entitlement.handlebars");

	return BaseView.extend({
		template: tpl,
		className: "entitlement",

		events: {
			"click .more-details": "onMoreDetails",
			"click .show-thumbnail": "onToggleThumbnail"
		},

		modelBindings: {
			".completed-steps": "activeStepNumber",
			".total-steps"    : "totalSteps"
		},

		initialize: function (options) {
			_.bindAll(this);

			options = options || {};
			this.classId = options.classId;
			this.model.set("thumbnailOpen", false);
			this.model.set("activeStepNumber", "#");
			this.model.set("totalSteps", "#");

			if (this.model.get("completionStatus") === "gone") {
				this.model.set("phase", "gone");
			}

			this.listenTo(this.model, {
				"change:progress"                      : this.onProgressChanged,
				"change:thumbnailOpen"                 : this.onThumbnailOpenChanged,
				"change:phase"                         : this.onPhaseChanged,
				"change:completionStatusForLabCriteria": this.onCompletionStatusForLabCriteriaChanged,
				"change:studentConsoleLatencyCurrent"  : this.onStudentConsoleLatencyCurrentChanged,
				"change:studentConsoleLatencyAverage"  : this.onStudentConsoleLatencyAverageChanged,
				"change:studentConsoleLatencyLow"      : this.onStudentConsoleLatencyLowChanged,
				"change:studentConsoleLatencyHigh"     : this.onStudentConsoleLatencyHighChanged,
				"change:studentConsoleLatencyUnstable" : this.onStudentConsoleLatencyUnstableChanged,
				"change:studentConsoleConnectionLost"  : this.onStudentConsoleConnectionLostChanged
			});

			App.push.on("other:classEntitlement:" + this.model.get("id") + ":studentThumbnail", this.thumbnailUpdated);
		},

		serialize: function () {
			var data = this.model.toHandlebars();
			data.classId = this.classId;
			return data;
		},

		afterRender: function () {
			this.$("[rel=tooltip]").tooltip();
			this.$("[rel=popover]").popover();
			this.onRefresh();
			this.refreshChangedValues();
		},

		remove: function () {
			App.push.off("other:classEntitlement:" + this.model.get("id") + ":studentThumbnail", this.thumbnailUpdated);
			clearInterval(this.thumbnailUpdateInterval);
			BaseView.prototype.remove.call(this);
		},

		onMoreDetails: function (event) {
			$(".student-card-vm", this.el).slideToggle("slow");
		},

		onProgressChanged: function (model, value) {
			this.$(".progress").text(App.i18n("percent", Math.round(value)));
			this.$(".progress-bar").css("width", value + "%");
		},

		onPhaseChanged: function (model, value) {
			var key = "instructor.entitlements.phase." + value;
			var text = App.i18n(key);

			if (text === key) {
				text = _.capitalize(value);
			}
			this.$el.removeClass("notStarted running starting end grade logout gone exit reset error");
			this.$el.addClass(value);
			this.$(".phase").text(text);
			this.$(".show-thumbnail").prop("disabled", value === "notStarted" || value === "gone"|| value === "exit" || value === "starting");

			// Deactivate the open console link for not started, gone and exit phases
			this.$(".open-console").removeClass("not-active");
			if (!App.config.instructorCanConnectToInactiveEntitlements && (value === "notStarted" || value === "gone" || value === "exit")) {
				this.$(".open-console").addClass("not-active");
			}

			if(value !== "running"){
				setTimeout(this.model.resetStudentConsoleLatency);
			}
		},
		onCompletionStatusForLabCriteriaChanged: function (model, value) {
			/*
			this.$(".lab-completion-status")
				.removeClass("percentageNotCompleted minTimeNotCompleted minTimeAndPercentageNotCompleted completed")
				.addClass(this.model.get("completionStatusForLabCriteria"));

			this.$(".lab-completion-status").text(App.i18n("completionCriteria." + this.model.get("completionStatusForLabCriteria")));
			 */
		},

		onRefresh: function (message) {
			this.model.classRequestSync();
		},

		onToggleThumbnail: function () {
			this.model.set("thumbnailOpen", !this.model.get("thumbnailOpen"));
		},

		onThumbnailOpenChanged: function (model, value) {
			if (value) {
				this.showThumbnail();
			} else {
				this.hideThumbnail();
			}
		},

		showThumbnail: function () {
			clearInterval(this.thumbnailUpdateInterval);

			this.$(".show-thumbnail").popover({
				content: "<div class='thumbnail-holder'><img class='thumbnail' /></div>",
				title: this.model.get("account").get("displayName"),
				placement: "bottom",
				html: true,
				trigger: "manual",
				"class": "thumbnail-popover",
				autoHide: false
			});
			this.$(".show-thumbnail").popover("show");
			this.$(".show-thumbnail").addClass("open");
			this.updateThumbnail();
			this.fetchThumbnail();
			this.thumbnailUpdateInterval = setInterval(this.fetchThumbnail, App.config.instructorThumbnailFrequency);
			this.model.set("thumbnailOpen", true);
		},

		hideThumbnail: function () {
			this.$(".show-thumbnail").removeClass("open");
			this.$(".show-thumbnail").popover("destroy");
			clearInterval(this.thumbnailUpdateInterval);
			this.model.set("thumbnailOpen", false);
		},

		fetchThumbnail: function () {
			this.$(".thumbnail-holder").addClass("data-loading");
			this.model.classRequestConsoleThumbnail(App.config.instructorThumbnailSize);
		},

		thumbnailUpdated: function (message) {
			if (message && message.data && message.data.activeThumbnail) {
				this.model.set("activeThumbnail", message.data.activeThumbnail);
			} else {
				this.model.set("activeThumbnail", null);
			}

			this.$(".thumbnail-holder").removeClass("data-loading");
			this.updateThumbnail();
		},

		updateThumbnail: function () {
			if (this.model.get("activeThumbnail")) {
				this.$(".thumbnail-holder").removeClass("data-loading empty");
				this.$(".thumbnail").attr("src", this.model.get("activeThumbnail"));
			} else {
				this.$(".thumbnail-holder").addClass("data-loading empty");
			}
			this.$(".show-thumbnail.open").popover("reflow");
		},

		refreshChangedValues : function(){
			this.model.triggerChange(
				"progress", "phase", "completionStatusForLabCriteria",
				"studentConsoleLatencyCurrent", "studentConsoleLatencyAverage", "studentConsoleLatencyLow",
				"studentConsoleLatencyHigh", "studentConsoleLatencyWarning", "studentConsoleLatencyUnstable",
				"studentConsoleConnectionLost"
			);
		},
		onStudentConsoleLatencyCurrentChanged: function (model, value) {
			this.$(".student-connection-strength,.student-latency-current").removeClass("level-1 level-2 level-3 level-4 level-5 level-6");
			this.$(".student-connection-strength").addClass(this.model.getLatencyLevel(value));
			this.$(".student-latency-current")
				.addClass(this.model.getLatencyLevel(value))
				.text(this.model.getLatencyDisplayValue(value));
		},
		onStudentConsoleLatencyAverageChanged: function (model, value) {
			this.$(".student-latency-average")
				.removeClass("level-1 level-2 level-3 level-4 level-5 level-6")
				.addClass(this.model.getLatencyLevel(value))
				.text(this.model.getLatencyDisplayValue(value));
		},
		onStudentConsoleLatencyLowChanged: function (model, value) {
			this.$(".student-latency-low")
				.removeClass("level-1 level-2 level-3 level-4 level-5 level-6")
				.addClass(this.model.getLatencyLevel(value))
				.text(this.model.getLatencyDisplayValue(value));
		},
		onStudentConsoleLatencyHighChanged: function (model, value) {
			this.$(".student-latency-high")
				.removeClass("level-1 level-2 level-3 level-4 level-5 level-6")
				.addClass(this.model.getLatencyLevel(value))
				.text(this.model.getLatencyDisplayValue(value));
		},
		onStudentConsoleLatencyUnstableChanged: function (model, value) {
			if(value){
				this.$(".student-latency-current").text("--");
			}
			this.$(".student-latency-current").toggleClass("unstable", value);
			this.$(".student-latency-unstable").toggle(value);
			this.$(".student-connection-strength").toggleClass("unstable", value);
		},
		onStudentConsoleConnectionLostChanged: function (model, value) {
			if(value){
				this.$(".student-latency-current").text("--");
			}
			this.$(".student-latency-current").toggleClass("lost", value);
			this.$(".student-connection-lost").toggle(value);
			this.$(".student-connection-strength").toggleClass("lost", value);
		}
		
	});
});

