define('nls/root/console-walkthrough',{
	"consoleWalkthrough" : {
		"steps" : {
			"welcome" : {
				"title"     : "Welcome to the console interface!",
				"firstTime" : "We see that it's your first time here.",
				"tour"      : "This tour will guide you through using the Console Options and Features. You can access this at anytime by selecting this link at the top left of your screen."
			},
			"labTime" : {
				"title" : "Lab Time",
				"timeRemaining" : {
					"title"       : "Time Remaining",
					"description" : "Displays the amount of time remaining you have to complete your lab.",
					"note"        : "Labs have two time limits: Time Remaining and Expiration Date. Time remaining is the total amount of time that the lab can be active. The counter for Time Remaining only changes when the lab is in use (09:58:14 in our example).",
					"expiration"  : "When the Expiration Date arrives, the lab will end even if some allotted time is still available. For example, a lab could configured with a one hour limit to do the lab but expire in three days. Thus the user could use the lab for up to one hour of total time and could break up that time into smaller intervals spread out over three days. In this example, the lab would end when the first of the time limits is reached."
				},
				"end" : {
					"title"       : "End",
					"description" : "Displays a screen that allows you to confirm or cancel the ending of your lab. If you confirm that you want to \"end\" your lab, then VMware Lab Platform will consider you have completed the lab and returns you to the VMware Lab Platform lab console login page. Thus you will not be able to resume the lab. You must click Exit to come back and resume your lab."
				},
				"exit" : {
					"title"       : "Exit",
					"description" : "Displays a screen that allows you confirm or cancel the exiting from your lab. If you confirm that you want to \"exit\" your lab, then VMware Lab Platform returns you to the catalog page. VMware Lab Platform will recognize that you may want to resume the lab and will provide you that option.",
					"note"        : "The Exit option is displayed only if it has been enabled by your tenant admin."
				},
				"extend" : {
					"title"       : "Extend",
					"description" : "Allows you to extend the time remaining in your lab. The extend time is configured by your tenant admin."
				}
			},
			"consoleMenu" : {
				"title": "Console Menu",
				"sendText" : {
					"title"       : "Send Text",
					"description" : "Displays the Send Text to Console window which allows you to type in text or copy/paste text from your lab's manual. When you click Send Text, VMware Lab Platform sends the text you entered to your lab console. The text appears where your cursor has focus inside of your console.",
					"note"        : "You cannot copy/paste from the host to the guest, thus the Send Text option acts as a workaround."
				},
				"ctrlAltDel" : {
					"title"       : "CTRL+ALT+DEL",
					"description" : "Sends the CTRL+ALT+DEL key combination to the virtual machine (VM).",
					"note"        : "The result of sending the key combination is operation system dependent."
				},
				"notes" : {
					"title"       : "Notes",
					"description" : "Displays information about the lab as configured by the tenant admin including:"
				},
				"powerDown" : {
					"title"       : "Power Down",
					"description" :"Powers down the VM for the lab you are running."
				},
				"powerReset" : {
					"title"       : "Power Reset",
					"description" : "Hard-reboots the machine as if the reset button on the physical hardware had been selected. It is independent of the power options."
				}
			},
			"settings" : {
				"title"       : "Settings",
				"description" : "Displays keyboard settings"
			},
			"labManual" : {
				"title"           : "Lab Manual",
				"moreOptions"     : "More Options",
				"manual"          : "Click tab to display the instructional manual for your lab.",
				"manualClose"     : "Closes the Manual panel",
				"dockFloat"       : "Toggles between Dock/Float or docks your screen",
				"paging"          : "Previous/Next page",
				"fontSize"        : "Increase/Decrease font size",
				"networkTopology" : {
					"title"       : "Network Topology",
					"description" : "Your lab is comprised of virtual machines. If your tenant administrator has included a network topology image in your tenant, then your manual console will include a Network Topology option. Clicking Network Topology displays an image that shows the network topology of the virtual machines that comprise your lab."
				},
				"tableOfContents" : {
					"title"       : "Table of Contents",
					"description" : "Displays links to each module of the manual. Manuals are typically comprised of modules which you complete sequentially."
				},
				"splitScreen"     : {
					"title"       : "Split Screen",
					"description" : "Displays the Use a Second Screen or Device screen which includes links to open the manual in a new window and email a link to open the manual with another device such as an iPad or a second window on your desktop. Thus you can go full screen on your console and have the lab’s manual on another desktop screen or on your iPad. VMware Lab Platform keeps the manual steps on the second screen in sync with the main screen. When you change to the next step on one of your screens, it changes on your other screen. In order to get to that second screen, you can email yourself a link, scan a QR code or copy and paste a special key."
				}
			},
			"consolesTab" : {
				"title"            : "Consoles Tab",
				"multipleConsoles" : "If a lab you start includes multiple consoles, then VMware Lab Platform displays the Consoles tab.",
				"singleConsole"    : "For example, your lab might include a Windows console icon and a Linux (RHEL) console icon. Most labs only have a single visible virtual machine that you interact with for the exercises, so the console tab will not be shown."
			},
			"consoleControls" : {
				"title" : "Console Controls",
				"fullScreen" : {
					"title"       : "Full Screen",
					"description" : "Use this to enter full screen mode."
				},
				"maximize" : {
					"title"       : "Maximize",
					"description" : "Use this to enter full screen mode."
				},
				"dockLeft" : {
					"title"       : "Dock Left",
					"description" : "Use this to dock your screen display to the LEFT."
				},
				"dockRight" : {
					"title"       : "Dock Right",
					"description" : "Use this to dock your screen display to the RIGHT."
				},
				"reestablishConnection" : {
					"title"       : "Re-establish Connection",
					"description" : "This options does not refresh your screen. Use this option only if your console locks up or your connection drops."
				},
				"floatDock" : {
					"title"       : "Float/Dock Screen",
					"description" : "Use this to toggle between a docked or floating screen."
				}
			}
		},
		"general" : {
			"note" : "Note"
		},
		"actions" : {
			"takeTour" : "Take Tour",
			"noThanks" : "No Thanks",
			"done"     : "Done"
		}
	}
});

