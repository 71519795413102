define('nls/root/not-found',{
	"notFound": {
		"errorCode": "404",
		"title":     "Oops!",
		"status":    "Page Not Found",
		"message":   "We couldn't find the page you requested.",
		"back":      "Back"
	},
	"title":    "Page not Found"
});

