
define('hbs!tpls/landing/main.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<header>\n	<div class=\"content\">\n		<div class=\"project-title\">\n			<span class=\"vmware\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.vmware",{"name":"$","hash":{},"data":data}))
    + "</span>\n			<span class=\"vlp\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.labPlatform",{"name":"$","hash":{},"data":data}))
    + "</span>\n		</div>\n		<div class=\"links\">\n			<a href=\""
    + this.escapeExpression(((helper = (helper = helpers.contactUsLink || (depth0 != null ? depth0.contactUsLink : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"contactUsLink","hash":{},"data":data}) : helper)))
    + "\">\n				<span class=\"fa fa-envelope-o\"></span>\n				<span class=\"text\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.contactUs",{"name":"$","hash":{},"data":data}))
    + "</span>\n			</a>\n		</div>\n	</div>\n	<hr />\n</header>\n\n<div class=\"hero\">\n	<div class=\"content\">\n		<div class=\"main\">\n			<div class=\"description\">\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.description",{"name":"$","hash":{},"data":data}))
    + "\n			</div>\n			<a class=\"learn-more\" href=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"learnMoreUrl",{"name":"config","hash":{},"data":data}))
    + "\">\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.learnMore",{"name":"$","hash":{},"data":data}))
    + "\n			</a>\n			<div class=\"login-prompt\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.loginPrompt",{"name":"$","hash":{},"data":data}))
    + "</div>\n		</div>\n		<div class=\"side-block\">\n			<span class=\"vlp-logo\">\n				<span class=\"icon\"></span>\n				<span class=\"title\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.labPlatform",{"name":"$","hash":{},"data":data}))
    + "\n					<sup>&trade;</sup>\n				</span>\n			</span>\n			<div class=\"monitor\">\n				<iframe src=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"landingVideoUrl",{"name":"config","hash":{},"data":data}))
    + "\" width=\"405\" height=\"232\" frameborder=\"0\" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div class=\"key-points\">\n	<div class=\"content\">\n		<div>\n			<h2>\n				<span class=\"fa fa-2x fa-va fa-fw fa-graduation-cap\"></span>\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.info1.title",{"name":"$","hash":{},"data":data}))
    + "\n			</h2>\n			<ul>\n				<li>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.info1.point1",{"name":"$","hash":{},"data":data}))
    + "\n				</li>\n			</ul>\n		</div>\n		<div>\n			<h2>\n				<span class=\"fa fa-2x fa-va fa-fw fa-globe\"></span>\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.info2.title",{"name":"$","hash":{},"data":data}))
    + "\n			</h2>\n			<ul>\n				<li>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.info2.point1",{"name":"$","hash":{},"data":data}))
    + "</li>\n				<li>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.info2.point2",{"name":"$","hash":{},"data":data}))
    + "</li>\n			</ul>\n		</div>\n		<div>\n			<h2>\n				<span class=\"fa fa-2x fa-va fa-fw fa-flask\"></span>\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.info3.title",{"name":"$","hash":{},"data":data}))
    + "\n			</h2>\n			<ul>\n				<li>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.info3.point1",{"name":"$","hash":{},"data":data}))
    + "</li>\n				<li>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.info3.point2",{"name":"$","hash":{},"data":data}))
    + "</li>\n				<li>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.info3.point3",{"name":"$","hash":{},"data":data}))
    + "</li>\n			</ul>\n		</div>\n	</div>\n	<hr />\n</div>\n<footer>\n	<div class=\"content\">\n		<span class=\"vmware\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.vmware",{"name":"$","hash":{},"data":data}))
    + "</span>\n		<div class=\"links\">\n			<div>\n				<span>\n					"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.footer.copyright",(depth0 != null ? depth0.copyrightYear : depth0),{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "\n				</span>\n				<a href=\"https://www.vmware.com/help/legal.html\" target=\"_blank\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.footer.termsOfUse",{"name":"$","hash":{},"data":data}))
    + "\n				</a>\n				<a href=\"https://www.vmware.com/help/privacy.html\" target=\"_blank\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.footer.privacy",{"name":"$","hash":{},"data":data}))
    + "\n				</a>\n				<a class=\"ot-sdk-show-settings\" href=\"#\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"landing.footer.cookieSettings",{"name":"$","hash":{},"data":data}))
    + "\n				</a>\n			</div>\n			<div class=\"social-media-links\">\n				<a href=\"https://www.twitter.com/vmwarecloud\" target=\"_blank\">\n					<span class=\"fa fa-twitter\"></span>\n				</a>\n				<a href=\"https://www.facebook.com/VMware-Cloud\" target=\"_blank\">\n					<span class=\"fa fa-facebook\"></span>\n				</a>\n				<a href=\"https://www.linkedin.com/showcase/4829968/\" target=\"_blank\">\n					<span class=\"fa fa-linkedin\"></span>\n				</a>\n				<a href=\"https://www.youtube.com/channel/UCpv9_RvYYB-j_YrWrWDuKfg\" target=\"_blank\">\n					<span class=\"fa fa-youtube\"></span>\n				</a>\n			</div>\n		</div>\n	</div>\n</footer>\n";
},"useData":true});
Handlebars.registerPartial('landing_main', t);
return t;
});

