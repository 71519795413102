/* globals dateFormat*/
/**
 * Base Backbone View
 *
 * This is a Base Class for setting up the layout and header/footer for all pages.
 */
define('views/vlp-base',['require','jquery','underscore','backbone','library/vlp/app','library/vlp/main-view','views/common/support-options','views/common/login-prompt','views/common/profile','views/common/tips','views/common/language-chooser','views/common/tenant-eula','views/common/tenant-chooser','views/common/byod-chooser','views/common/general-messages','views/common/system-messages','views/common/system-messages-popover','views/common/help-center','views/common/help-request','views/common/student-enroll-lab','views/common/layout-tpls','hbs!tpls/common/switch-tenant-modal.handlebars','hbs!tpls/common/byod-unavailable-modal.handlebars','models/station','models/account','models/system','models/class','library/vlp/push','utilities/window-communication','utilities/browser','i18n!nls/common','i18n!nls/not-found','library/backbone/backbone-validation','utilities/handlebar-helpers'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App                        = require("library/vlp/app"),
	    BaseView                   = require("library/vlp/main-view"),

	    SupportOptions             = require("views/common/support-options"),
	    LoginPromptView            = require("views/common/login-prompt"),
	    ProfileView                = require("views/common/profile"),
	    TipsView                   = require("views/common/tips"),
	    LanguageChooserView        = require("views/common/language-chooser"),
	    //EulaView                   = require("views/common/eula"),
	    TenantEulaView             = require("views/common/tenant-eula"),
	    TenantChooserView          = require("views/common/tenant-chooser"),
	    ByodChooserView            = require("views/common/byod-chooser"),
	    GeneralMessagesView        = require("views/common/general-messages"),
	    SystemMessagesView         = require("views/common/system-messages"),
	    SystemMessagesPopoverView  = require("views/common/system-messages-popover"),
	    HelpCenterView             = require("views/common/help-center"),
	    HelpRequestView            = require("views/common/help-request"),
	    EnrollLabView              = require("views/common/student-enroll-lab"),

	    LayoutTPLs = require("views/common/layout-tpls"),
	    switchTenantModalTpl = require("hbs!tpls/common/switch-tenant-modal.handlebars"),
	    byodUnavailableModalTpl = require("hbs!tpls/common/byod-unavailable-modal.handlebars"),

	    Station       = require("models/station"),
	    Account       = require("models/account"),
	    System        = require("models/system"),
	    Class         = require("models/class"),

	    Push                = require("library/vlp/push"),
	    WindowCommunication = require("utilities/window-communication"),
	    Browser             = require("utilities/browser"),

	    commonTexts = require("i18n!nls/common"),
	    notFoundTexts = require("i18n!nls/not-found");

	require("library/backbone/backbone-validation");
	require("utilities/handlebar-helpers");


	var VlpBaseView = BaseView.extend({
		name                  : "vlp-base",

		_isLoggedIn           : false,
		_startupComplete      : false,

		checkBrowserCompatibility : true, //when false, sets checkBrowserSupported, checkBrowserRejected, and checkCompatibility to false

		checkBrowserSupported : true, //Un-supported will still see the catalog page until they log in.
		checkBrowserRejected  : true, //Cannot provide base level of functionality for this browser
		checkCompatibility    : true,

		checkSystem           : true,
		checkMaintenance      : true,
		checkLoggedIn         : true,
		checkVerifyByDate     : true,
		forceLogout           : false,
		checkEula             : true,
		checkTenantPath       : true,
		checkSystemMessages   : true,
		checkByodSection      : false,
		showWelcomeTips       : false,
		screenSaver           : true,
		periodicRefresh       : true,
		allowHiddenTenant     : false,
		anonymousPush         : false,
		anonymousAccess       : false,
		stationPush           : false,

		secondScreenPage      : false,

		startupOperations     : [],
		pageStartupOperations : [],


		layoutTemplate : LayoutTPLs.layout,
		//Required
		partialTemplates : {
			header            : LayoutTPLs.header,
			footer            : LayoutTPLs.footer,
			global_modals     : LayoutTPLs.globalModals,
			global_components : LayoutTPLs.globalComponents
		},
		constructor : function(){
			App.locale.mergeI18n(notFoundTexts);
			App.locale.mergeI18n(commonTexts);
			this.partialTemplates = _.defaults(this.partialTemplates, VlpBaseView.prototype.partialTemplates);

			if(this.mainTemplate){
				this.partialTemplates.main = this.mainTemplate;
			}
			BaseView.prototype.constructor.apply(this, arguments);

			_.extend(Backbone.Validation.messages, App.locale.i18nStrings.validationMessages);
		},
		setup : function(options){
			App.setState("startingSetup");
			this._setupOptions = options || {};
			this.name = App.config.pages[this.name] || this.name;
			$("body").addClass("setup logged-out");
			if(this.checkLoggedIn){
				$("body").addClass("checking-login");
			}
			App.mainView = this;
			App.windowCommunication = new WindowCommunication();
			App.push = new Push();

			//call parent
			BaseView.prototype.setup.apply(this, arguments);

			this._setupEnvironmentVariables();

			this._setupAjax();

			App.setGlobalParam("tenant", App.getTenant());

			$.fn.tooltip.defaults.delay = {show : 100, hide : 500};
			$.fn.popover.defaults.delay = {show : 100, hide : 500};

			//prevent escape key from removing websocket connections.
			$(window).keydown(function(event){
				if(event.keyCode === 27) { event.preventDefault(); }
				if (event.which === 8 && !$(event.target).is("input:not([readonly]):not([type=radio]):not([type=checkbox]), textarea, [contentEditable], [contentEditable=true]")) {
					console.warn("Blocking backspace from navigating.", event.target);
					event.preventDefault();
				}
			});


			App.user = this.user = new Account.Model({id : "me"});
			App.VlpGlobal.setUser(App.user);

			this.listenTo(this.user, "loggedIn", function(){
				App.windowCommunication.call("loggedIn");
			});

			this.listenTo(this.user, "loggedOut", function(){
				App.windowCommunication.call("loggedOut");
				this.loggedOut();
			});

			this.listenTo(this.user, "change:helpRequest", function(){
				if(!this.helpRequestView){
					this.helpRequestView = new HelpRequestView({ el : this.el });
				}
				this.helpRequestView.update();
			});


			this.events = this.events || {};
			//add common events
			this.events["click #logout,.logout"]         = "logout";
			this.events["click .login-button"]           = "showLogInPrompt";
			this.events["click #show_support_options"]   = "showSupportOptions";
			this.events["click #show_welcome_tips"]      = "showWelcomeTipsModal";
			this.events["click #show_language_chooser"]  = "showLanguageChooserViewModal";
			this.events["click #login_language_chooser"] = "showLanguageChooserViewModal";
			this.events["click #show_profile"]           = "showProfile";
			this.events["click #live_chat"]              = "showLiveChat";
			this.events["click #help_request"]           = "onHelpRequest";
			this.events["click .small-share"]            = "onShare";
			this.events["click .copy-value input"]       = "onCopyInputValue";
			this.events["click #change-tenant"]          = "showTenantChooser";
			this.events["click .change-tenant"]          = "showTenantChooser";
			this.events["click #system_messages_button"] = "showSystemMessagesPopover";
			this.events["click .show-system-messages"]   = "showSystemMessages";
			this.events["click #registered_station"]     = "showHelpCenter";

			this.delegateEvents();

			$(document).on("shown hidden", ".collapse", function(e){
				$(e.target).redraw();
			});


			this.startupOperations = [];

			this.startupOperations.push("checkForOtherPages");
			this.startupOperations.push("checkEventRegistration");
			this.startupOperations.push("layout");
			if(this.checkBrowserRejected){
				this.startupOperations.push("checkBrowserReject");
			}

			/*
			if(this.checkBrowserSupported){
				this.startupOperations.push("checkBrowserSupport");
			}
			*/
			if(this.checkSystem){
				this.startupOperations.push("systemCall");
				this.startupOperations.push("getAuthenticationProviders");
			}
			this.startupOperations.push("setupAnalytics");
			if(this.forceLogout){
				this.startupOperations.push("forceLogout");
			}

			this.startupOperations.push("checkStudentToken");

			if(this.checkLoggedIn){
				this.startupOperations.push("checkLoggedIn");
			} else{
				if(this.anonymousPush || this.stationPush){
					this.startupOperations.push("anonymousPush");
				}
				this.startupOperations.push("statusComplete");
				if(this.checkTenantPath){
					this.startupOperations.push("checkTenantPath");
				}
			}

			this.startupOperations.push("pageStartupOperations");
			this.startupOperations.push("showMainContent");

			if(this.checkCompatibility){
				this.startupOperations.push("checkCompatibility");
			}
			this.startupOperations.push("startUpComplete");


			App.setState("setupComplete");
			//Start the startup operations
			this.nextOperation();
		},
		_setupEnvironmentVariables : function(){
			var self = this;

			var makeUrl = function(path, hash, queryParts){
				var url = path;

				var queryString = $.param(queryParts);
				if(queryString.length){
					url += "?" + queryString;
				}

				if(hash && hash !== "#"){
					url += hash;
				}
				return url;
			};

			var path = window.self.location.pathname;
			var hash = window.self.location.hash;
			var queryParts = _.parseQueryString();

			var originalUrl = makeUrl(path, hash, queryParts);


			var findAndCleanQueryParameter = function(searchKey, keepOne){
				var match = queryParts[searchKey];
				for(var key in queryParts){
					if(key.toLowerCase() === searchKey.toLowerCase()){
						if(!match){
							match = queryParts[key];
						}
						delete queryParts[key];
						if(keepOne){
							queryParts[searchKey] = match;
						}
					}
				}
				return match;
			};

			var matchToken = function(tokenType){
				var tokenMatch = findAndCleanQueryParameter(tokenType, true);

				var hashMatches = hash.match(new RegExp(tokenType + "\/(.+)", "i"));
				if(hashMatches){
					hash = hash.replace(hashMatches[0], "");
					if(!tokenMatch){
						tokenMatch = hashMatches[1];
					}
					if(!queryParts[tokenType]){
						queryParts[tokenType] = hashMatches[1];
					}
				}

				var pathMatches = path.match(new RegExp(tokenType + "\/(.+)", "i"));
				if(pathMatches){
					path = path.replace(pathMatches[0], "");
					if(!tokenMatch){
						tokenMatch = pathMatches[1];
					}
					if(!queryParts[tokenType]){
						queryParts[tokenType] = pathMatches[1];
					}
				}
				return tokenMatch;
			};

			var setBaseAttribute = function(attribute){
				var queryParameterValue = findAndCleanQueryParameter(attribute);
				if(queryParameterValue){
					queryParameterValue = _.toBoolean(queryParameterValue);
					App.session.set(attribute, queryParameterValue, 0);
					self[attribute] = self[attribute] && queryParameterValue;
				} else if(App.session.get(attribute) !== null){
					self[attribute] = self[attribute] && App.session.get(attribute);
				}
			};


			App.remoteToken = matchToken("remoteToken");
			App.neeToken = matchToken("neeToken");

			App.accessToken  = queryParts.accessToken;
			App.studentToken = queryParts.studentToken;


			var returnUrl = findAndCleanQueryParameter("returnUrl") || App.session.get("returnUrl");
			if(returnUrl){
				App.session.set("returnUrl", _.sanitizeURL(returnUrl), 0);
			}

			var hideLogout = findAndCleanQueryParameter("hideLogout");
			hideLogout = hideLogout ? _.toBoolean(hideLogout) : false;
			if(hideLogout){
				App.session.set("hideLogout", true, 0);
			}
			App.autoConnectVideoConference = findAndCleanQueryParameter("connectVideoConference");

			setBaseAttribute("checkBrowserCompatibility");
			setBaseAttribute("checkVerifyByDate");
			setBaseAttribute("checkCompatibility");
			setBaseAttribute("checkBrowserSupported");
			setBaseAttribute("checkBrowserRejected");
			setBaseAttribute("showWelcomeTips");
			setBaseAttribute("checkEula");

			App.ajaxDataType = Browser.msie && Browser.version.major < 10 ? "jsonp" : "json";
			if(!this.checkBrowserCompatibility){
				this.checkCompatibility = false;
				this.checkBrowserSupported = false;
				this.checkBrowserRejected = false;
				App.ajaxDataType = "json";
			}

			var newUrl = makeUrl(path, hash, queryParts);
			if(newUrl !== originalUrl){
				App.replaceState(newUrl);
			}
		},
		_setupAjax : function(){
			var self = this;
			$.support.cors = true;

			var headers = {
				"X-NEE"         : App.config.xNee || "nee",
				"cache-control" : "no-cache"
			};

			if(App.session.get("nee-token")){
				headers["x-nee-token"] = App.session.get("nee-token");
			}
			if(App.session.get("nee-station")){
				headers["x-nee-station"] = App.session.get("nee-station");
			}


			$.ajaxSetup({
				xhrFields  : {
					withCredentials : true
				},
				headers    : headers,
				dataType : App.ajaxDataType || "json",
				timeout : App.config.ajaxTimeout || 0,
				converters : {
					"* text"    : window.String,
					"text html" : true,
					"text xml"  : $.parseXML,
					"text json" : function(value){
						var result = null;
						if(value === "") { value = "{}"; }
						try{
							result = $.parseJSON(value);
						} catch(e){
							result = {};
						}
						return result;
					}
				},
				cache      : false

			});

			$(document).ajaxComplete(this._ajaxComplete);

			var originalAjax = $.ajax;
			$.ajax = function(url, options){
				// If url is an object, simulate pre-1.5 signature
				if(typeof url === "object"){
					options = url;
					url = undefined;
				}

				// Force options to be an object
				options = options || {};
				if(options.error && !options.originalError){
					options.originalError = options.error;
				}
				options.error = function(jqXHR, textStatus, errorThrown){
					self._ajaxError(jqXHR, textStatus, errorThrown, options);
				};
				options.error._ajaxError = true;

				if(Browser.msie && Browser.version.major < 10 && self.checkBrowserCompatibility){

					if(options.success && !options.originalSuccess){
						options.originalSuccess = options.success;
					}
					options.success = function(result, textStatus, jqXHR){
						self._ajaxJSONPResult(result, textStatus, jqXHR, options);
					};
					options.error._ajaxSuccess = true;
				}

				return originalAjax.call(this, options);
			};
		},
		nextOperation : function(){
			if(this.startupOperations.length){
				var operation = this.startupOperations.shift();
				var func;
				if(_.isFunction(operation)){
					func = operation;
				} else if(this["_op_" + operation]){
					func = this["_op_" + operation];
				} else if(this[operation]){
					func = this[operation];
				}
				if(func){
					func();
				} else{
					this.nextOperation();
				}
			}
		},

		/**
		 * Render layout
		 * @param options
		 */
		_op_layout : function(options){

			options = options || {};
			options = _.extend(this._setupOptions, options);

			options.isMobile             = Browser.mobile;
			options.isTablet             = Browser.tablet;
			options.partialSupport       = Browser.partialSupport;
			options.partialSupportPrompt = Browser.partialSupport && !App.remoteControl;
			options.stationId            = App.station.get("stationId");
			options.copyrightYear        = new Date().getFullYear();
			options.pageName             = this.name;

			App.config.learnMoreUrl = App.makeUrl({page: App.config.learnMoreUrl, params: {utm_source: "vlp_user_ui", utm_content: App.config.tenant}});

			// Deleting the 'amd' property from define seems to prevent 3rd party
			// js library includes conflicting with requirejs's define.
			// This conflict occurred with the inclusion of some tenant specific scripts
			// like salesforce chat (blendedlearning) and tealium analytics (HOL).
			delete define.amd;

			if(this.viewport){
				$("meta[name=viewport]").attr("content", this.viewport);
			}

			//Call super
			BaseView.prototype.layout.call(this, options);

			this.$checkingStatus = this.$("#checking_status");

			if(Browser.mobile){
				$("body").addClass("mobile");
			}

			if(Browser.tablet){
				$("body").addClass("tablet");
			}
			if(App.session.get("hideLogout")){
				$("body").addClass("no-logout");
			}

			this.$("[rel=tooltip]").tooltip();

			this.setTitle();

			this.nextOperation();
		},
		_op_pageStartupOperations : function(){
			if(this.pageStartupOperations.length){
				this.startupOperations = this.pageStartupOperations.concat(this.startupOperations);
				this.$checkingStatus.show();
			}
			this.nextOperation();
		},
		_op_startUpComplete : function(){
			this._startupComplete = true;
			App.setState("started");

			if(App.push.inUse && !App.push.connected){
				App.push.off("opened error", null, this);
				App.push.once("opened error", function(){
					this.trigger(VlpBaseView.STARTUP_COMPLETE);
				}, this);
			} else{
				this.trigger(VlpBaseView.STARTUP_COMPLETE);
			}

			//Hack for disappearing fonts in chrome.
			if(Browser.chrome && Browser.version.major < 35){
				var count = 0;
				var chromeFontFixInterval = setInterval(function(){
					$("#chrome_font_fix").remove();
					if(count >= 11){
						clearInterval(chromeFontFixInterval);
					} else{
						$("head").append("<style id=\"chrome_font_fix\">/* Web Fonts*/ @font-face{font-family: \"webfont\"; src: url(/fonts/fontawesome-webfont.eot);font-weight: normal;font-style: normal; }}</style>");
					}
					count++;
				}, 1000);
			}
		},
		_op_checkForOtherPages : function(){
			var self = this;
			var pagesUpdated = function(page){
				if (page && page.info && page.info.secondScreenPage) {
					self.setupScreenSaver();
				}
				if (App.station.isRegistered() && App.push) {
					App.push.pingPacket.requestParams = App.push.pingPacket.requestParams || {};
					App.push.pingPacket.requestParams.windowCount = App.windowCommunication.pageCount;
				}
				if(!document.hasFocus() && App.windowCommunication.pageCount > 1 && App.currentPage() != App.config.pages.CONSOLE){
					App.push.pausePing();
				} else{
					App.push.resumePing();
				}
			};

			App.windowCommunication.on("added:page",   pagesUpdated);
			App.windowCommunication.on("removed:page", pagesUpdated);
			$(window).on("blur", pagesUpdated);
			$(window).on("focus", pagesUpdated);

			App.windowCommunication.on("called:loggedIn", function(){
				if(App.user && !App.user.get("loggedIn")){
					if(App.currentPage() != App.config.pages.EVENT){
						App.reload();
					} else{
						App.redirect({page: App.config.pages.CATALOGS});
					}
				}
			}, this);

			App.windowCommunication.on("called:loggedOut", this.loggedOut);

			App.windowCommunication.initPage(this.name, {secondScreenPage : this.secondScreenPage});

			this.nextOperation();
		},
		_op_checkEventRegistration : function(){
			App.station = new Station.Model();
			App.station.setupStation();
			if(!App.station.isRegistered()){
				App.config.enableAutocomplete = (window.location.protocol === "https:" || window.location.hostname === "localhost");
			}
			this.nextOperation();
		},
		_op_checkBrowserReject : function(){
			if(Browser.reject){
				App.redirect({page : App.config.pages.UNSUPPORTED});
				return;
			}

			this.nextOperation();
		},
		_op_checkBrowserSupport : function(){
			if(!Browser.supported){
				App.redirect({page : App.config.pages.UNSUPPORTED});
				return;
			}

			this.nextOperation();
		},
		_op_systemCall : function(){
			var self = this;

			var system = new System.Model();
			system.fetch({
				retries : 5,
				success : function(system){
					self._systemSetup(system);
					self.nextOperation();
				},
				error   : function(model, jqXHR){
					if(jqXHR.status === 0 && Browser.msie && Browser.version.major === 10){
						//Most likely a TLS 1.2 failure for IE 10.
						App.redirect({page: App.config.pages.UNSUPPORTED});
					} else if(jqXHR.response && (jqXHR.response.errorCode == 9001 || jqXHR.response.errorCode == 9002)){
						//handled above
					} else if(jqXHR.response && (jqXHR.response.errorCode == 5110 || jqXHR.response.errorCode == 5201)){
						self.$("#checking_status .loading").hide();
						self.$("#checking_status .tenant-not-found").removeClass("hide");
					} else if(!App.redirecting){
						self.$("#checking_status .loading").hide();
						self.$("#checking_status .system-error").removeClass("hide");

						self.generalMessage({
							message     : self.formatErrorMessage(jqXHR.response && jqXHR.response.message, null, jqXHR),
							type        : "error"
						});
						_.delay(function(){
							App.redirect({page: App.config.pages.MAINTENANCE});
						}, 10000);
					}

				}
			});
		},
		_op_getAuthenticationProviders : function(){
			this.user.set("authenticationProviders", [{"name":"LOCAL","type":"LOCAL","method":"POST"}]);

			if(!App.config.allowStudentSSOLogin){
				//SSO not configured, skip authentication providers fetch and set default.
				this.nextOperation();
				return;
			}
			var self = this;

			var options = {};
			options.type = "GET";
			options.url = App.config.authBase + "login/" + App.getTenant() + "/providers";

			options.success = function(response){
				self.user.set("authenticationProviders", response.providers);
				self.nextOperation();
			};

			options.error = function(){
				console.error("Error getting login providers.");
				self.nextOperation();
			};

			$.ajax(options);
		},
		_op_setupAnalytics : function(){
			App.oneTrust.setIds(App.config.oneTrustIds);
			App.analytics.addService(App.config.analytics);
			if(App.config.oneTrustEnabled || App.analytics.hasTealium) {
				App.oneTrust.start();
				this.$(".oneTrust-cookie-settings").show();
			} else {
				this.$(".oneTrust-cookie-settings").hide();
				//call allCookiesAllowedCallback to allow analytics when OneTrust is disabled
				App.analytics.allCookiesAllowedCallback();
			}
			this.nextOperation();
		},
		_op_checkStudentToken : function(){
			if(App.studentToken){
				var self = this;
				App.class = new Class.Model({studentToken : App.studentToken});
				App.class.verify({
					success : function(){
						if(App.class.get("studentTokenMode") === "selfReg") {
							App.config.allowRegistration = true;
						}
						self.nextOperation();
					},
					error : function(model, jqXHR, ajaxSettings){
						self.generalMessage({
							message       : _.escapeHTML(jqXHR.response.message),
							type          : "error"
						});
						self.nextOperation();
					}
				});
			} else {
				this.nextOperation();
			}
		},

		_op_checkClassEnrolled : function(){
			if(App.studentToken){
				var self = this;

				App.class.verify({
					success : function(){
						if(App.class.get("entitlement")){
							App.router.navigate("enrollments/lab/" + App.class.get("entitlement").get("entitlementKey") + "?studentToken="+App.studentToken, {trigger : true});
							self.nextOperation();
						} else if(!App.class.get("entitlement")){
							// the take them to class enrollment confirmation modal dialog
							if(App.class.get("studentTokenMode") == "selfReg"){
								//show add to class/lab modal
								self.showEnrollLabViewModal();
							} else if(App.class.get("studentTokenMode") == "autoAdd"){
								if(App.config.allowRegistration || _.find(App.user.get("tenants"), function(tenant){return tenant.name.toLowerCase() === App.getTenant().toLowerCase();})){
									self.showEnrollLabViewModal();
								} else {
									self.nextOperation();
								}
							} else if(App.class.get("studentTokenMode") == "launchOnly") {
								// check if token is launch only then take him catalog tab
								// Check if this is correct routing for catalog tab
								App.router.navigate("catalog/?studentToken="+App.studentToken, {trigger : true});
								self.nextOperation();
							} else{
								//should never get here since deactivated studentTokenMode returns 404
								self.nextOperation();
							}
						}
					},
					error : self.nextOperation
				});
			} else {
				this.nextOperation();
			}
		},

		_op_checkByodSection : function(){
			var self = this;
			if(!this.user.get("loggedIn") && this.allowAnonymousAccess){
				return this.nextOperation();
			}

			if(App.config.concurrentEntitlementsLimit && !App.station.isRegistered()){
				App.station.sections({
					success : function(){
						self.showByodChooser({force : true, nextOperation : true});
					},
					error : function(response){
						if(response && response.response && response.response.errorCode === 8001){
							self.showByodUnavailableModal();
						} else{
							self.nextOperation();
						}
					}
				});

			} else{
				this.nextOperation();
			}
		},

		_op_forceLogout : function(){
			var self = this;
			this.user.logout({
				logoutFlush : true,
				retries     : 0,
				force       : true,
				success : self.nextOperation,
				error   : self.nextOperation
			});
		},
		_op_checkLoggedIn : function(){
			if(App.neeToken){
				return this._checkToken("neeToken", App.neeToken);
			} else if(App.remoteToken){
				return this._checkToken("remoteToken", App.remoteToken);
			} else if(App.accessToken){
				return this._checkToken("accessToken", App.accessToken);
			} else if(this.checkLoggedIn){
				this._checkUserLoggedIn();
			}
		},
		_op_checkSystemMessages : function(){
			if(this.user.get("loggedIn")){

				var previousSystemMessages = App.session.get("systemMessages") || [];
				var systemMessageIds = this.user.getRelation("systemMessages").keyContents;
				var newMessages = _.difference(systemMessageIds, previousSystemMessages);
				if(newMessages.length){
					this.showSystemMessages();
				} else if(this.user.getRelation("systemMessages").keyContents.length){
					this.$("#system_messages_button .system-message-count").text(this.user.getRelation("systemMessages").keyContents.length);
					this.$("#system_messages_button").popover("destroy").show();
					$("body").addClass("system-messages");
				}

				this.user.get("systemMessages")._setupPush();
				this.listenTo(this.user.get("systemMessages"), "add remove", this.systemMessagesUpdated);

				if(App.config.systemMessagesEnabled){
					App.push.on("systemMessage:update", this.systemMessagesReceived);
					App.push.subscribe({
						service      : "systemMessage",
						channel      : "/systemMessage/loggedIn"
					});

					App.push.subscribe({
						service      : "systemMessage",
						channel      : App.push.makePath("systemMessage", App.getTenant().toLowerCase(), "loggedIn")
					});
				}
			}
			this.nextOperation();
		},
		_op_checkVerifyByDate : function(){
			if(this.user.get("loggedIn") && this.user.get("verifyByDate") && App.config.requirePasswordForLogin){
				this.showAccountVerificationMessage();
			}
			this.nextOperation();
		},
		/*
		_op_checkEula : function(){
			this.$checkingStatus.hide();

			if(!this.user.get("loggedIn") && this.allowAnonymousAccess){
				return this.nextOperation();
			}
			if(this.user.get("loggedIn") && !this.checkEula){
				return this.nextOperation();
			}

			if(!this.user.get("eulaAccepted")){
				this.showEula();
			} else{
				this._eulaOk();
			}
		},
		*/
		_op_checkTenantEula : function(){
			this.$checkingStatus.hide();

			if(!this.user.get("loggedIn") && this.allowAnonymousAccess){
				return this.nextOperation();
			}
			if(this.user.get("loggedIn") && !this.checkEula){
				return this.nextOperation();
			}

			if(App.config.eulaEnabled && App.config.serviceTermId && this.user.get("activeTenant") && this.user.get("activeTenant").showServiceTerm){
				this.showTenantEula();
			} else{
				this._tenantEulaOk();
			}
		},
		_op_statusComplete : function(){
			this.$checkingStatus.hide();
			this.nextOperation();
		},
		_op_checkTenantPath : function(){
			var self = this;
			this.$checkingStatus.hide();

			if(!this.user.get("loggedIn") && this.allowAnonymousAccess){
				return this.nextOperation();
			}

			var currentTenant = App.getTenant();
			var findTenant = function(tenant){return tenant.name.toLowerCase() === currentTenant.toLowerCase();};
			var findDefaultTenant = function(tenant){
				if(tenant.maintenanceMode) { return false; }
				return tenant.name.toLowerCase() === self.user.get("defaultTenant").toLowerCase();
			};

			var queryString = $.param(_.omit(App.queryParts, "studentToken"));
			if(queryString.length){queryString = "?" + queryString; }
			var redirectPage = App.currentPage() + queryString + window.self.location.hash;

			if(this.user.get("tenants").length !== 0 && currentTenant === "" && this.user.get("defaultTenant") !== "" && _.find(this.user.get("visibleTenants"), findDefaultTenant) ){

				return App.redirect({page: redirectPage, tenant: this.user.get("defaultTenant")});

			} else if(this.user.get("tenants").length !== 0 && (currentTenant === "" || (!this.allowHiddenTenant && !_.find(this.user.get("visibleTenants"), findTenant) )) ){

				if(this.user.get("visibleTenants").length === 1){
					return App.redirect({page: redirectPage, tenant: this.user.get("visibleTenants")[0].name});
				} else if(this.user.get("tenants").length === 1){
					return this.showTenantChooser({force : true});
				} else if(App.entitlementTenant){
					return App.redirect({page: redirectPage, tenant: App.entitlementTenant});
				} else{
					return this.showTenantChooser({force : true});
				}
			} else if(this.user.get("tenants").length !== 0 && (currentTenant === "" || !_.find(this.user.get("tenants"), findTenant) )){
				if(this.user.get("tenants").length === 1){
					return App.redirect({page: redirectPage, tenant: this.user.get("tenants")[0].name});
				} else if(App.entitlementTenant){
					return App.redirect({page: redirectPage, tenant: App.entitlementTenant});
				} else{
					return this.showTenantChooser({force : true});
				}
			} else if(this.user.get("chooseNewTenant")){
				return this.showTenantChooser({force : true, chooseNewTenant : true});
			}


			if(this.user.get("tenants").length > 1){
				this.$("#change-tenant").show();
			}

			this.nextOperation();
		},
		_op_showMainContent : function(){
			this.showMainContent();
			this.nextOperation();
		},
		_op_checkCompatibility : function(){
			if(!Browser.support.cookies){
				this.generalMessage({ key : "warnings.cookiesDisabled"});
			}
			if(Browser.support.privateBrowsing){
				this.generalMessage({ key : "warnings.safariPrivateBrowsing", oneTime : true});
			} else if(!Browser.support.localStorage && !Browser.support.sessionStorage){
				this.generalMessage({ key : "warnings.storageDisabled", oneTime : true});
			} else if(!Browser.support.localStorage || !Browser.support.sessionStorage){
				if(!Browser.support.localStorage){
					this.generalMessage({ key : "warnings.localStorageDisabled", oneTime : true});
				}
				if(!Browser.support.sessionStorage){
					this.generalMessage({ key : "warnings.sessionStorageDisabled", oneTime : true});
				}
			}


			if(Browser.safari && Browser.version.major === 7 && !App.remoteControl){
				this.generalMessage({ key : "warnings.safariSeven", oneTime : true});
			}

			this.nextOperation();
		},


		_op_showWelcomeTips : function(){

			if(!this.user.get("showTips") || App.session.get("welcomeShown") || this.secondScreenPage){
				return this.nextOperation();
			}

			if(!this.tipsView){
				this.tipsView = new TipsView({
					model : this.user
				});
			}

			this.tipsView.show({autoHide : true});

			this.nextOperation();
		},

		_systemSetup : function(system){
			App.config = _.extend(
				App.config,
				system.toJSON()
			);

			this.trigger("App.config.change");

			App.setMode(App.config.mode);

			App.jsLogger.setEnabled(App.config.userUiClientLogging);

			if(App.config.tenantId){
				App.config.tenantIds = [App.config.tenantId];
				App.setGlobalParam("tenantId", App.config.tenantId);
			}
			if(App.config.tenantKey){
				App.config.tenant = App.config.tenantKey;
			}
			App.setGlobalParam("tenant", App.getTenant());

			// apply custom tenant styling if any
			if (App.config.tenantCSSFile && $("#tenant_styles").length === 0) {
				var tenantCss = document.createElement("link");
				tenantCss.href = _.sanitizeURL(App.config.tenantCSSFile);
				tenantCss.rel = "stylesheet";
				tenantCss.id = "tenant_styles";
				document.getElementsByTagName("head")[0].appendChild(tenantCss);
			}

			if(App.config.liveChat && App.config.liveChatUrl){
				this.enableLiveChat();
			} else{
				this.disableLiveChat();
			}
			if(App.config.helpRequestButtonLocation === "top"){
				this.$(".actions .nested").remove();
				this.$("#help_request_container").show();
			} else{
				this.$(".actions .top").remove();
				this.$("#help_request_container").show();
			}

			if(App.config.tenantName){
				$(".tenant-name").text(App.config.tenantName);
			}

			if(App.config.userUiUrlForceRedirect && !_.isEmpty(App.config.canonicalStudentUiUrl)){
				var parsedStudentUiUrl = _.parseURL(App.config.canonicalStudentUiUrl);
				var parsedCurrentUrl = _.parseURL(window.self.location.href);
				if(
					parsedCurrentUrl.protocol != parsedStudentUiUrl.protocol ||
					parsedCurrentUrl.host != parsedStudentUiUrl.host ||
					parsedCurrentUrl.port != parsedStudentUiUrl.port ||
					(!_.isEmpty(parsedStudentUiUrl.path) && parsedCurrentUrl.path.indexOf(parsedStudentUiUrl.path) !== 0)
				) {
					var a = document.createElement("a");
					a.href = window.self.location.href;
					a.protocol = parsedStudentUiUrl.protocol;
					a.hostname = parsedStudentUiUrl.host;
					a.port = parsedStudentUiUrl.port;
					a.pathname = parsedStudentUiUrl.path + parsedCurrentUrl.path.replace(new RegExp("^" + _.escapeRegExp(parsedStudentUiUrl.path)), "");
					console.log("Redirecting to canonical Student Interface", a.href);
					App.redirect(a.href);
				}
			}
			if(!App.config.announcements){
				$("body").addClass("no-announcements");
			}
			if(!App.config.offers){
				$("body").addClass("no-offers");
			}
			if (App.config.showNewLogo) {
				$("body").addClass("logo-new");
			}

			this.allowAnonymousAccess = this.anonymousAccess && (App.config.allowAnonymousBrowsing || this.name !== App.config.pages.CATALOGS);

			App.config.browserShare = Browser.canShare;

			if(this.periodicRefresh){
				setTimeout(function(){window.self.location.reload();}, App.config.pageRefreshTimeout);
			}
		},
		_checkUserLoggedIn : function(){
			var self = this;
			var options = {
				success : this._userLoggedIn,
				error : function(model, jqXHR, ajaxSettings){
					if(App.remoteToken && jqXHR && jqXHR.response && jqXHR.response.errorCode === 9003){
						jqXHR.response.errorCode = -9003;
						if(Browser.mobile || Browser.tablet){
							jqXHR.response.message = App.i18n("errors.thirdPartyCookiesMobile");

						} else{
							jqXHR.response.message = App.i18n("errors.thirdPartyCookies");
						}
						self.showLogInPrompt(jqXHR.response);
					} else{
						if(App.studentToken && (App.class.get("studentTokenMode") === "selfReg" ||
							App.class.get("studentTokenMode") === "autoAdd" || App.class.get("studentTokenMode") === "launchOnly")){
							// Need to check
							self.showLogInPrompt(jqXHR.response);
						} else if(self.allowAnonymousAccess){

							if(self.anonymousPush || self.stationPush){
								self.startupOperations.unshift("anonymousPush");
							}
							$("body").addClass("logged-out").removeClass("logged-in checking-login");

							self.nextOperation();
							self.once(VlpBaseView.LOGGED_IN, self.showMainContent);
						} else if(App.getTenant() === ""){
							self.gotoLanding();
						} else if(self.stationPush){
							self._setupPush();
						}
					}
					App.session.remove("tempLocale");
					if(self.allowAnonymousAccess && App.config.anonymousLanguage){
						App.locale.set(App.config.anonymousLanguage);
					}
				},
				noLoginPrompt : (this.allowAnonymousAccess || App.getTenant() === "")
			};
			this.user.checkLoggedIn(options);

		},
		_checkToken : function(tokenType, tokenValue){
			if(!tokenValue || this._lastToken === tokenValue){
				return;
			}
			this._lastToken = tokenValue;
			var self = this;

			var options = {};
			options.type = "GET";
			options.url = App.config.serverBase + "processToken";

			options.data = {};
			options.data[tokenType] = tokenValue;

			options.success = function(response){
				var currentTenant = App.getTenant();
				App.entitlementTenant = response.data.tenant || currentTenant || "PSO";
				App.entitlementId = response.data.entitlementKey;

				if(response.data["nee-token"]){
					App.session.set("nee-token", response.data["nee-token"], 0);
					var headers = $.ajaxSetup().headers || {};
					headers["x-nee-token"] = response.data["nee-token"];
					$.ajaxSetup({ headers : headers });
				}

				if(tokenType === "neeToken"){
					self._setExternalSite();
				}


				var queryParts = _.parseQueryString();
				delete queryParts[tokenType];

				App.replaceState({page: window.self.location.pathname, params: queryParts, hash: window.self.location.hash});
				var newPage = App.currentPage();
				if(App.entitlementId){
					if(newPage !== App.config.pages.CONSOLE && newPage !== App.config.pages.REMOTE_CONTROL){
						newPage = App.config.pages.CONSOLE;
						return App.redirect({page: newPage + "/" + App.entitlementId, tenant: App.entitlementTenant, params : queryParts, hash: window.self.location.hash});
					} else if(currentTenant !== App.entitlementTenant){
						return App.redirect({page: newPage + "/" + App.entitlementId, tenant: App.entitlementTenant, params : queryParts, hash: window.self.location.hash});
					} else {
						if(Backbone.History.started){
							App.router.navigate(App.entitlementId, {trigger: true, replace: true});
						} else{
							App.replaceState({page: newPage + "/" + App.entitlementId, params: queryParts, hash: window.self.location.hash});
						}
					}
				}
				self._checkUserLoggedIn();
			};

			options.error = function(jqXHR){
				if(!jqXHR.response){
					try{
						jqXHR.response = JSON.parse(jqXHR.responseText);
					} catch(e){}
				}
				self.showLogInPrompt(jqXHR.response);
			};

			$.ajax(options);
		},
		_setExternalSite : function(){
			App.session.set("externalSite", true, 0);
			App.session.set("hideLogout", true, 0);
			$("body").addClass("no-logout");
		},
		_op_anonymousPush : function(){
			this._setupPush();
			this.nextOperation();
		},
		_setupPush : function(){
			var pushRequestURL, connectionType;
			var rootParams = {};
			if(this.user.get("loggedIn")){
				pushRequestURL = App.config.pushBase + "?id=" + this.user.get("username") + "-" + App.getTenant().toLowerCase();
				connectionType = "user";
				rootParams.username = this.user.get("username");
			} else if(this.stationPush && App.station.isRegistered()){
				pushRequestURL = App.config.pushBase + "?id=station-" + App.station.get("stationKey");
				connectionType = "station";
			} else if(this.anonymousPush){
				pushRequestURL = App.config.pushBase + "?id=tenant-" + App.getTenant();
				connectionType = "tenant";
			}
			if(!pushRequestURL){
				if(App.push && App.push.connected){
					App.push.disconnect();
				}
				return;
			}

			try{
				if(App.push && App.push.connected){
					App.push.disconnect();
				}
				if(App.remoteControl){
					App.config.pushPingInterval = 0;
				}

				App.push = App.push || new Push();
				App.push.connectionType = connectionType;
				App.push.setup(pushRequestURL, {
					rootParams            : rootParams,

					reconnectWait         : App.config.pushReconnectDelay,
					useResendQueue        : App.config.pushDisconnectedSendBuffer,
					pingInterval          : App.config.pushPingInterval,
					atmosphereTimeout     : App.config.atmosphereTimeout,
					reconnectAttemptCount : App.config.pushReconnectAttemptCount
				});
				this._openPush();

			} catch(e){
				console.error(App.i18n("errors.pushConnection"), e);
				this.generalMessage({
					message       : App.i18n("errors.pushConnection"),
					type          : "error",
					autoRemove    : true
				});
			}
		},
		_openPush : function(){
			try{
				if(App.station.isRegistered()) {
					App.push.pingPacket = {
						service  : "station",
						operation: "ping",
						component: "eventmanagement"
					};

					var stationType = App.station.has("byodSection") ? "section" : "station";

					App.push.pingPacket[stationType] = App.station.get("stationKey");
					App.push.pingPacket.requestParams = App.push.pingPacket.requestParams || {};
					if(this.user.get("loggedIn") && App.push.connectionType == "user"){
						App.push.pingPacket.username = this.user.get("username");
						App.push.pingPacket.requestParams.username = this.user.get("username");
					}
					App.push.pingPacket.requestParams.windowCount = App.windowCommunication.pageCount;

					App.push.pingInterval = App.config.pushStationPingInterval;

					App.push.once("opened", function () {
						App.push.subscribe({
							service      : "station",
							channel      :  App.push.makePath(App.getTenant(), "station", App.station.get("stationId")),
							requestParams: {
								stationKey: App.station.get("stationKey")
							}
						});
						App.push.subscribe({
							service      : "station",
							channel      : App.push.makePath("station", App.station.get("stationKey"))
						});
					});
				}

				App.push.open();

				App.push.sendId = App.id;
				App.push.off("send", null, this);
				App.push.off("connectionFailed", this.logout);
				App.push.off("closing", null, this);
				App.push.off("maintenanceMode",  this._maintenanceMode);
				App.push.off("alert",            this._alertMessage);
				App.push.off("station:invalid",  this._stationInvalid);
				App.push.off("station:update",   this._stationUpdate);

				App.push.on("connectionFailed", this.logout);

				App.push.on("maintenanceMode",  this._maintenanceMode);
				App.push.on("alert",            this._alertMessage);
				App.push.on("station:invalid",  this._stationInvalid);
				App.push.on("station:update",   this._stationUpdate);


				App.push.on("closing", function(){
					if(App.station.isRegistered()){
						if(!App.redirecting){
							App.station.ping();
						}
					}
				}, this);

			} catch(e){
				if(App.config.pushBase.match(/^http:/) && window.self.location.protocol === "https:"){
					App.config.pushBase = App.config.pushBase.substr(0, 4) + "s" + App.config.pushBase.substr(4);
					this._setupPush();
				} else{
					console.error(App.i18n("errors.websockethubConnection"), e);
					this.generalMessage({
						message       : App.i18n("errors.websockethubConnection"),
						type          : "error",
						autoRemove    : true
					});
				}
			}
		},
		_maintenanceMode : function(info) {
			var data = info.data;
			if (data.enabled === "true" || data.enabled === true) {
				if(info.requestParams && info.requestParams.tenant){
					if(info.requestParams.tenant.toLowerCase() == App.getTenant().toLowerCase()){
						App.redirect({page: App.config.pages.MAINTENANCE});
					}
				} else{
					App.redirect({page: App.config.pages.MAINTENANCE});
				}
			} else {
				console.log("Maintenance disabled");
			}
		},
		_alertMessage : function(info) {
			var data = info.data || {};
			if(data.severity == 1){
				data.type = "error";
			} else if(data.severity == 2){
				data.type = "warning";
			} else if(data.severity == 3 || !data.severity){
				data.type = "info";
			}
			data.id = "alert_" + String(_.slugify(JSON.stringify(info))).substring(0, 255);
			this.generalMessage(data);
		},
		_stationInvalid : function(info) {
			console.warn("station is no longer valid:", info && info.message);
			var self = this;

			if(info && info.message){
				this.generalMessage({ id : "station-invalid", type : "warning", message : info.message});
			}
			$("#registered_station").hide();

			if(this.user.get("loggedIn")){
				if(App.station && !App.station.has("byodSection")){
					if(!this.helpRequestView){
						this.helpRequestView = new HelpRequestView({ el : this.el });
					}
					this.helpRequestView.open();
				}
				App.station.unregister({
					params : {invalid : true},
					complete : function(){
						self._setupPush();
					}
				});
			} else{
				App.station.unregister({params : {invalid : true}});

				if(this.helpRequestView){ this.helpRequestView.close(); }

				if(App.currentPage() != App.config.pages.EVENT) {
					App.redirect({page: App.config.pages.EVENT});
				}
			}
		},
		_stationUpdate : function(info) {
			if(!info || !info.hasOwnProperty("data")){
				return;
			}

			var data = info.data || {};

			var state = data.state;


			if(state === "OPEN" || state === "NEW"){
				App.station.unAssign();
				if(App.user.get("loggedIn")){
					this.loggedOut();
				}
			} else if(state === "ASSIGNED" || (!state && App.station.get("assignedName"))){
				if(data.account){
					App.station.assign(data.account);
				}

				if(App.station.get("assignedName")){
					this.generalMessage({
						id      : "station-assigned",
						type    : "info",
						message : App.i18n("infos.stationAssigned", _.escape(App.station.get("assignedName"))),
						username: App.station.get("assignedUsername")
					});
				}
			}

			if(!App.station.get("assignedName")){
				this.removeGeneralMessage("station-assigned");
			}
		},
		_onInvalid : function(user){
			this.setupScreenSaver();
		},

		_userLoggedIn : function(){
			if(this.loginPromptView){
				this.loginPromptView.hide();
			}
			this.$checkingStatus.hide();

			App.jsLogger.setUserInfo(this.user.get("email"), this.user.get("displayName"));
			if(this.user.get("userId")){
				App.analytics.set("userId", this.user.get("userId"));
			}

			this._isLoggedIn = true;
			this._setupPush();
			$("body").addClass("logged-in").removeClass("logged-out checking-login");
			this.$(".display-user-name").text(this.user.get("displayName"));


			this.setupScreenSaver();
			App.station.unAssign();
			this.removeGeneralMessage("station-assigned");

			$("body").removeClass("login-prompt");

			if(this.checkSystemMessages){
				this.startupOperations.unshift("checkSystemMessages");
			}
			if(this.checkVerifyByDate){
				this.startupOperations.unshift("checkVerifyByDate");
			}
			if(this.showWelcomeTips){
				this.startupOperations.unshift("showWelcomeTips");
			}

			this.startupOperations.unshift("checkByodSection");
			this.startupOperations.unshift("checkTenantEula");
			//this.startupOperations.unshift("checkEula");

			if(this.checkTenantPath){
				this.startupOperations.unshift("checkTenantPath");
			}
			if(this.checkBrowserSupported){
				this.startupOperations.unshift("checkBrowserSupport");
			}

			if(App.class && App.class.get("studentTokenMode") != "disabled"){
				this.startupOperations.unshift("checkClassEnrolled");
			}

			this.nextOperation();
		},
		enableLiveChat: function(){
			this.$(".live-chat").removeClass("disabled").show();
			this.$("#live_chat").prop("disabled", false);
		},
		disableLiveChat: function(){
			this.$(".live-chat").addClass("disabled").hide();
			this.$("#live_chat").prop("disabled", true);
		},
		setTitle : function(){
			var parts = [];
			_.each(_.toArray(arguments), function(part){
				parts.push(App.i18n(part));
			});
			if(parts.length === 0){
				parts.push(App.i18n("title"));
			}
			parts.push(App.i18n("commonTitle"));
			document.title = parts.join(App.i18n("titleSeparator"));
		},
		checkUserLoggedIn : function(callback){
			var self = this;
			this.user.checkLoggedIn({
				success : function(){
					self._userLoggedIn();
					if(_.isFunction(callback)){
						callback(self.user);
					}
				}
			});
		},
		showLogInPrompt : function(options){
			this._isLoggedIn = false;
			if(this.checkBrowserSupported){
				if(!Browser.supported){
					App.redirect({page : App.config.pages.UNSUPPORTED});
					return;
				}
			}

			if(!this._startupComplete){
				this.$checkingStatus.hide();
				this.$mainContent.hide();
				$("body").addClass("login-prompt").removeClass("main-content checking-login");
			}
			if(!this.loginPromptView){
				this.loginPromptView = new LoginPromptView({
					model : this.user
				});
				this.loginPromptView.render();
				this.loginPromptView.on("loggedIn", this._userLoggedIn);
				this.loginPromptView.on("loggedInvalid", this._onInvalid);
				this.loginPromptView.on("loginCanceled", function(){this.trigger("loginCanceled");}, this);
			}

			options = options || {};

			if(options.currentTarget){
				//options is jquery event
				options = $(options.currentTarget).data();
			}
			if((options.status || options.responseText || options.success === "false") && options.errorCode !== 9003){
				//Login attempt had error
				this.loginPromptView.loginInvalid(options);
			}
			this.loginPromptView.show(options);
			this.setupScreenSaver();
		},
		hideLogInPrompt : function(){
			if(this.loginPromptView){
				this.loginPromptView.hide();
			}
			this.$mainContent.show();
			$("body").removeClass("login-prompt").addClass("main-content");
		},
		showMainContent : function(){
			$("body").removeClass("setup");
			$("body").addClass("main-content");
			if(App.station.isRegistered()){
				$("body").addClass("event-station");

				this._stationUpdate();
			}

			if(App.session.get("returnUrl")){
				$("a.home").removeAttr("href");
			}

			this.$checkingStatus.hide();
			if(this.loginPromptView){
				this.loginPromptView.hide();
			}
			this.$mainContent.show();

			this.listenTo(App.locale, "loadStart", function(){
				if(this._reloadMessage && this.generalMessages){
					this.generalMessages.collection.remove(this._reloadMessage);
				}
				this._reloadMessage = this.generalMessage({ key : "infos.localeLoading"});
			}, this);
			this.listenTo(App.locale, "loadStop", function(){
				if(this._reloadMessage && this.generalMessages){
					this.generalMessages.collection.remove(this._reloadMessage);
				}
				_.extend(Backbone.Validation.messages, App.locale.i18nStrings.validationMessages);
			}, this);

			if(App.config.userUiCustomScript){
				try{
					$("body").append(App.config.userUiCustomScript);
				} catch(e){
					console.warn(e);
				}
			}
		},
		setupScreenSaver : function(){

			if(!this.screenSaver || App.config.screenSaverRedirectDelay <= 0 || !App.station.isRegistered() || !this.loginPromptView || !this.loginPromptView.isOpen || this._isLoggedIn){
				if(this._screenSaverStartTimeout){
					this._screenSaverStopTimeout();
					$(document).off("mousemove mousedown mouseup keydown keyup scroll", this._screenSaverStartTimeout);
				}
				return;
			}
			if(!this._screenSaverStartTimeout){
				this._screenSaverOnIdle = _.bind(function(){
					if(!this.loginPromptView || !this.loginPromptView.isOpen || this.loginPromptView.submitting || this._isLoggedIn){
						return;
					}
					if(_.isEmpty(this.secondaryPages)){
						App.redirect({page: App.config.pages.SCREEN_SAVER});
					}
				}, this);


				this._screenSaverStopTimeout = _.bind(function(){
					if(this.screenSaverRedirectTimeout){
						clearTimeout(this.screenSaverRedirectTimeout);
						this.screenSaverRedirectTimeout = null;
					}
				}, this);

				this._screenSaverStartTimeout = _.bind(function(){
					this._screenSaverStopTimeout();
					this.screenSaverRedirectTimeout = setTimeout(this._screenSaverOnIdle, App.config.screenSaverRedirectDelay);
				}, this);

			}

			$(document).off("mousemove mousedown mouseup keydown keyup scroll", this._screenSaverStartTimeout);
			$(document).on("mousemove mousedown mouseup keydown keyup scroll", this._screenSaverStartTimeout);
			this._screenSaverStartTimeout();
		},
		showTenantChooser : function(options){
			options = options || {};

			this.$checkingStatus.hide();

			if(!this.tenantChooser){
				this.tenantChooser = new TenantChooserView({
					tenants : this.user.get("visibleTenants")
				});
			}

			this.tenantChooser.render(options);
			if(options.force){
				this.$("#modal_tenant_chooser").modal({ backdrop : "static", keyboard : false, show : true});
			} else{
				this.$("#modal_tenant_chooser").modal("show");
			}
		},
		showByodChooser : function(options){
			options = options || {};

			this.$checkingStatus.hide();

			if(!App.station.has("sections") || App.station.get("sections").length === 0){
				if(options.nextOperation){
					this.nextOperation();
				}
				return;
			}
			if(!this.byodChooser){
				this.byodChooser = new ByodChooserView({
					sections : App.station.get("sections")
				});
			}

			this.byodChooser.render(options);
			if(options.force){
				this.$("#modal_byod_chooser").modal({ backdrop : "static", keyboard : false, show : true});
			} else{
				this.$("#modal_byod_chooser").modal("show");
			}
			this.byodChooser.on("sectionChosen", function(){
				$("#modal_byod_chooser").modal("hide");
				App.reload();
			});
			if(options.nextOperation){
				this.byodChooser.on("sectionChosen", this.nextOperation);
			}
		},
		showByodUnavailableModal : function(){
			console.log("showByodUnavailableModal")
			$("#modal_byod_unavailable").remove();
			var modalHtml = byodUnavailableModalTpl();
			$("body").append(modalHtml);
			$("#modal_byod_unavailable").modal({ backdrop : "static", keyboard : false, show : true});
			$("#modal_byod_unavailable .reload-page").click(function(){
				App.reload();
			});
		},
		showSwitchTenantModal : function(tenant){
			$("#modal_switch_tenant").remove();
			var otherTenantUrl = App.makeUrl({tenant: tenant});
			var modalHtml = switchTenantModalTpl({otherTenant: tenant, otherTenantUrl: otherTenantUrl, tenant: App.getTenant()});
			$("body").append(modalHtml);
			$("#modal_switch_tenant").modal({ backdrop : "static", keyboard : false, show : true});
		},
		showAccountVerificationMessage : function(){
			var self = this;

			this.generalMessage({
				id   : "verification_warning",
				type : "warning",
				message       : App.i18n("verification.warning", dateFormat(this.user.get("verifyByDate"), App.i18n("dateFormatFull"))),
				confirmMessage: App.i18n("verification.resendEmail"),
				confirmCallback : function(){
					self.user.sendNewToken({
						success : function(){
							self.generalMessage({ type : "success", message : App.i18n("verification.success"), removeDelay : 5000});
						},
						error : function(){
							self.generalMessage({ type : "error",   message : App.i18n("verification.error"),   removeDelay : 5000});
						}
					});
				}
			});
		},
		/*
		showEula : function(){
			$("body").addClass("eula");
			this.$checkingStatus.hide();
			if(this.loginPromptView){ this.loginPromptView.hide();	}

			if(!this.eulaView){
				this.eulaView = new EulaView({
					el    : this.$("#eula"),
					model : this.user
				});
			}

			this.eulaView.on("eulaAccepted", this._eulaOk);
			this.eulaView.once("eulaDenied", this.logout);
			this.eulaView.render();
			this.$("#eula").show();
		},

		_eulaOk : function(){
			$("body").removeClass("eula");
			if(this.loginPromptView){
				this.loginPromptView.hide();
			}
			if(this.eulaView){
				this.eulaView.remove();
				delete this.eulaView;
			}
			App.mainView.setTitle();
			this.$("#eula").remove();
			this.nextOperation();


		},
		 */

		gotoLanding : function(){
			if(this.name === "landing"){
				this.nextOperation();
			} else{
				App.redirect({page : App.config.pages.HOME, tenant : null});
			}
		},
		showTenantEula : function(){
			$("body").addClass("eula");
			this.$checkingStatus.hide();
			if(this.loginPromptView){ this.loginPromptView.hide();	}

			if(!this.tenantEulaView){
				this.tenantEulaView = new TenantEulaView({
					el             : this.$("#tenant_eula"),
					model          : this.user,
					serviceTermId  : App.config.serviceTermId
				});
			}

			this.tenantEulaView.on("tenantEulaAccepted", this._tenantEulaOk);
			this.tenantEulaView.once("tenantEulaDenied", this.logout);
			this.tenantEulaView.render();
			this.$("#tenant_eula").show();
		},
		_tenantEulaOk : function(){
			$("body").removeClass("eula");
			if(this.loginPromptView){
				this.loginPromptView.hide();
			}
			if(this.tenantEulaView){
				this.tenantEulaView.remove();
				delete this.tenantEulaView;
			}
			App.mainView.setTitle();
			this.$("#tenant_eula").remove();
			this.trigger(VlpBaseView.LOGGED_IN);
			this.nextOperation();


		},
		logout : function(event){

			if(event){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			var params = {};
			if(event && event.currentTarget && $(event.currentTarget).data("tenant")){
				params.tenant = $(event.currentTarget).data("tenant");
			}

			if(App.user){
				App.user.logout({
					force : true,
					params : params,
					error : this.loggedOut
				});
			}
		},
		loggedOut : function(options){
			options = options || {};

			if(App.user && App.user.get("loggedIn")){
				App.user.set("loggedIn", false);
			}


			var headers = $.ajaxSetup().headers || {};
			delete headers["x-nee-token"];
			$.ajaxSetup({ headers : headers });

			App.session.remove("nee-token");
			App.session.remove("nee-station");

			App.session.remove("welcomeShown");
			App.session.remove("systemMessages");
			App.session.remove("externalSite");
			App.session.remove("returnUrl");
			App.session.remove("hideLogout");
			App.session.remove("toggleManualShortcuts");
			App.session.remove("byodSection");
			App.session.remove("consoleSettings");
			App.session.remove("nextHelpRequestTime");

			if(App.station.isRegistered()){
				App.store.remove("vm_listing_panel");
				App.store.remove("instructions_panel");
				App.store.remove("remote-vm_listing_panel");
				App.store.remove("vmState");
				App.store.remove("fontSizeIndex");
			}

			if(this.helpRequestView){ this.helpRequestView.close(); }

			App.analytics.trackEvent("Account", "Logged Out");

			if(this.checkLoggedIn && !options.logoutFlush){

				if(App.session.get("returnUrl")){
					App.redirect(_.sanitizeURL(App.session.get("returnUrl")));
				} else{
					App.redirect({page: App.config.pages.HOME});
				}
			}
		},
		isLoggedIn : function(){
			return this._isLoggedIn;
		},
		isStartupComplete : function(){
			return this._startupComplete;
		},
		showSupportOptions : function(){

			if(!this.supportOptionsView){
				this.supportOptionsView = new SupportOptions();
				this.supportOptionsView.render();
			}
		},
		showWelcomeTipsModal : function(){

			if(!this.tipsView){
				this.tipsView = new TipsView({
					model : this.user
				});
			}

			this.tipsView.show({autoHide : false});
		},
		showLanguageChooserViewModal : function(){

			if(!this.languageChooserView){
				this.languageChooserView = new LanguageChooserView({
					model : this.user
				});
			}

			this.languageChooserView.show();
		},
		showEnrollLabViewModal : function(){

			if(!this.enrollLabView){
				this.enrollLabView = new EnrollLabView({
					//check if we are getting correct class
					model : App.class
				});
				this.enrollLabView.on("closed", this.nextOperation);
			}

			this.enrollLabView.show();
		},
		showProfile : function(options){
			options = options || {showGeneral : true, showPassword : true, showSecurityQuestions : true};
			var self = this;

			this.$("#modal_profile").modal("show");
			if(!this.profileView){
				this.profileView = new ProfileView({
					model : this.user
				});
			}

			if(!this.user){
				this.once(VlpBaseView.LOGGED_IN, function(){
					self.profileView.render(options);
					App.analytics.trackEvent("Account", "Show: Profile");
				});
			} else{
				this.profileView.render(options);
				App.analytics.trackEvent("Account", "Show: Profile");
			}
		},
		systemMessagesUpdated : function(){
			this.$("#system_messages_button .system-message-count").text(this.user.get("systemMessages").length);
			this.$("#system_messages_button").show();
			$("body").addClass("system-messages");
		},
		systemMessagesReceived : function(info){
			this.user.get("systemMessages").add(info.data, {parse: true});
			this.systemMessagesUpdated();
			this.showSystemMessages(info.data);
		},
		showSystemMessagesPopover : function(){
			var self = this;
			if(!this.user.get("systemMessages").fetched){
				this.user.fetchRelated("systemMessages", {
					success : this.systemMessagesUpdated
				});
			} else{
				this.systemMessagesUpdated();
			}
			this.$("#system_messages_button").off("show").on("show", function(){
				if(!self.systemMessagesPopoverView){
					self.systemMessagesPopoverView = new SystemMessagesPopoverView({
						collection : self.user.get("systemMessages")
					});
					self.listenTo(self.systemMessagesPopoverView, "showMessage", function(message){
						self.showSystemMessages(message);
					});
				}
			});
			this.$("#system_messages_button").off("hidden").on("hidden", function(){
				if(self.systemMessagesPopoverView){
					self.stopListening(self.systemMessagesPopoverView, "showMessage");
					self.systemMessagesPopoverView.remove();
					self.systemMessagesPopoverView = null;
				}
			});
			this.$("#system_messages_button").popover({
				content : function(){
					if(self.systemMessagesPopoverView){
						self.systemMessagesPopoverView.render();
						return self.systemMessagesPopoverView.el;
					}
				}
			});

			this.$("#system_messages_button").popover("toggle");
		},
		showSystemMessages : function(message){
			var self = this;
			if(!this.user.get("systemMessages").fetched){
				this.user.fetchRelated("systemMessages", {
					success : this.systemMessagesUpdated
				});
			} else{
				this.systemMessagesUpdated();
			}

			this.$("#modal_system_message").off("show").on("show", function(){

				if(!self.systemMessagesView){
					self.systemMessagesView = new SystemMessagesView({
						collection : self.user.get("systemMessages")
					});
					self.$("#modal_system_message #system_messages").append(self.systemMessagesView.render().el);
				}
				if(message){
					self.systemMessagesView.showMessage(message);
				}
			});

			this.$("#modal_system_message").off("hidden").on("hidden", function(){
				App.session.set("systemMessages", self.user.get("systemMessages").pluck("id"), 1);
				if(self.systemMessagesView){
					self.systemMessagesView.remove();
					self.systemMessagesView = null;
				}
			});
			this.$("#modal_system_message").modal("show");
			if(this.systemMessagesPopoverView){
				this.$("#system_messages_button").popover("hide");
			}

		},
		showLiveChat : function(event){

			if(App.config.liveChatUrl){
				var url = App.VlpGlobal.replaceVariables(App.config.liveChatUrl);
				url+= "&name=" + encodeURIComponent(this.user.get("displayName"));
				url+= "&email=" + encodeURIComponent(this.user.get("email"));
				url = _.sanitizeURL(url);
				window.open(url, "hoc_chat_login", "width=720,height=550,scrollbars=no,status=0,toolbar=no,location=no,resizable=no");
				return;
			}
		},
		showHelpCenter : function(event){

			if(App.config.showHelpCenter && App.user.get("loggedIn") && App.station.isRegistered()){
				if(!this.helpCenterView){
					this.helpCenterView = new HelpCenterView();
				}
				$("#modal_help_center").off("show.vlp-base").on("show.vlp-base", this.helpCenterView.render);
				$("#modal_help_center").modal("show");
			}
		},
		onHelpRequest : function(event){

			if(event && event.preventDefault){
				event.preventDefault();
				event.stopImmediatePropagation();
				if(event.handled) { return; }
				event.handled = true;
			}

			if(!this.helpRequestView){
				this.helpRequestView = new HelpRequestView({ el : this.el });
			}
			this.helpRequestView.toggle();
		},
		generalMessage : function(info){
			if(!this.generalMessages){
				this.generalMessages = new GeneralMessagesView({
					el : this.$("#general_messages")
				});
			}
			return this.generalMessages.addMessage(info);
		},
		removeGeneralMessage : function(id){
			if(this.generalMessages){
				this.generalMessages.removeMessage(id);
			}
		},
		onShare : function(event){
			var data    = $(event.currentTarget).data();
			if(Browser.canShare && data.shareUrl){
				navigator.share({
					url: data.shareUrl,
					title: data.shareTitle,
					text: data.shareText
				}).catch(function(ignore){});
			}
		},
		onCopyInputValue : function(event){
			$(event.currentTarget).select();
			$(event.currentTarget).tooltip("show");
			try{
				document.execCommand("copy");
			} catch(ignore){ }
		},
		_ajaxComplete : function(event, xhr, ajaxOptions){
		},
		_ajaxError : function(jqXHR, textStatus, errorThrown, ajaxSettings){
			var response;
			if(App.unloading) { return; }

			if(jqXHR instanceof Backbone.Model){
				jqXHR = textStatus;
				textStatus = "error";
				ajaxSettings = errorThrown;
				errorThrown = jqXHR.statusText;

			}
			if(jqXHR && !jqXHR.response){
				try{
					jqXHR.response = JSON.parse(jqXHR.responseText);
				} catch(e){
					var contentType = jqXHR.getResponseHeader("content-type") || "";
					if(contentType.indexOf("html") > -1 || contentType.indexOf("xml") > -1){
					} else{
						jqXHR.response = {message : jqXHR.responseText};
					}
				}
			}
			if(jqXHR.response){
				if(jqXHR.response.data && jqXHR.response.data.message){
					response = jqXHR.response.data.message;
				} else if(jqXHR.response.message){
					response = jqXHR.response.message;
				}
			}

			var retryLimit = App.config.apiRetryLimit;
			if(ajaxSettings.hasOwnProperty("retries")){
				retryLimit = ajaxSettings.retries;
			}
			var showLogin = true;
			if(ajaxSettings && ajaxSettings.noLoginPrompt) { showLogin = false; }

			if(!ajaxSettings.hasOwnProperty("tryCount")){
				ajaxSettings.tryCount = 0;
			} else{
				ajaxSettings.tryCount++;
			}
			if(ajaxSettings.tryCount > retryLimit){
				return;
			}


			var noRepeatStatus = [400, 401, 403, 404, 418];

			var retryable = true;
			if(_.contains(noRepeatStatus, jqXHR.status)){
				retryable = false;
			}
			if(ajaxSettings.tryCount >= retryLimit){
				retryable = false;
			}
			if(ajaxSettings.url.indexOf(App.config.pushBase) !== -1){
				this._ajaxOriginalError(jqXHR, textStatus, errorThrown, ajaxSettings);
				retryable = false;
				return;
			}
			if(App.unloading){
				retryable = false;
			}

			var retryDelay = App.config.apiRetryDelay || 10000;
			if(jqXHR.getResponseHeader && jqXHR.getResponseHeader("retry-after")){
				var retryAfter = jqXHR.getResponseHeader("retry-after");
				if(_.isNumber(retryAfter)){
					retryDelay = retryAfter * 1000;
				} else {
					retryDelay = new Date(retryAfter) - new Date();
				}
			}
			if(jqXHR.response && jqXHR.response.retryAfter){
				retryDelay = jqXHR.response.retryAfter * 1000;
			}
			if(isNaN(retryDelay) || retryDelay < App.config.apiRetryDelay){
				retryDelay = App.config.apiRetryDelay;
			}


			if(jqXHR.status == 401 || jqXHR.status == 418 || (jqXHR.status == 0 && Browser.msie)){
				//Access denied

				if(App.push.connected && App.push.connectionType === "user"){
					App.push.close();
				}
				if(jqXHR.status == 401 || jqXHR.status == 418){
					if(ajaxSettings.logoutFlush || ajaxSettings.loggingIn){
						App.session.remove("nee-token");
						var headers = $.ajaxSetup().headers || {};
						delete headers["x-nee-token"];
						$.ajaxSetup({ headers : headers });

						this._ajaxOriginalError(jqXHR, textStatus, errorThrown, ajaxSettings);
						return;
					} else if(this.user){
						this.user.logout({
							logoutFlush : true,
							retries : 0,
							force : true,
							error : function(){}
						});
					}
				}
				if(this.checkLoggedIn && showLogin){
					if(!ajaxSettings.url.match(/\/accounts\/me/)){
						var loggedIn, loginCanceled;
						var self = this;

						loggedIn = function(){
							self.off(VlpBaseView.LOGGED_IN, loggedIn, self);
							self.off("loginCanceled", loginCanceled, self);
							self._ajaxLoginRecall(ajaxSettings);
						};
						loginCanceled = function(){
							self.off(VlpBaseView.LOGGED_IN, loggedIn, self);
							self.off("loginCanceled", loginCanceled, self);
							jqXHR.statusText = "loginCanceled";
							if(!self._ajaxOriginalError(jqXHR, textStatus, errorThrown, ajaxSettings)){
								if(ajaxSettings.originalError && !ajaxSettings.originalError._ajaxError){
									//Backbone error wrapper. Not really provided by user code
									ajaxSettings.originalError(jqXHR, textStatus, errorThrown);
								}
							}
						};
						this.on(VlpBaseView.LOGGED_IN, loggedIn, this);
						this.on("loginCanceled", loginCanceled, this);
					}
					if(jqXHR.response && jqXHR.response.errorCode){
						jqXHR.response.cancellable = !!ajaxSettings.loginCancelable;
						this.showLogInPrompt(jqXHR.response);
					} else{
						this.showLogInPrompt({cancellable : !!ajaxSettings.loginCancelable});
					}
				}
				if(!ajaxSettings.loginCancelable){
					this._ajaxOriginalError(jqXHR, textStatus, errorThrown, ajaxSettings);
				}
			} else if(jqXHR.status == 429 && jqXHR.response && jqXHR.response.errorCode === 9012){
				if(this.checkLoggedIn && showLogin){
					this.showLogInPrompt(jqXHR.response);
				}
				this._ajaxOriginalError(jqXHR, textStatus, errorThrown, ajaxSettings);
			} else if(jqXHR.status == 400 && jqXHR.response && jqXHR.response.errorCode === 2100){
				if(this.checkLoggedIn && showLogin){
					this.showLogInPrompt(jqXHR.response);
				}
				this._ajaxOriginalError(jqXHR, textStatus, errorThrown, ajaxSettings);
			} else if(jqXHR.response && (jqXHR.response.errorCode == 9001 || jqXHR.response.errorCode == 9002)){
				//System in Maintenance
				this._ajaxOriginalError(jqXHR, textStatus, errorThrown, ajaxSettings);
				if(this.checkMaintenance){
					this.generalMessage({
						message       : this.formatErrorMessage(response, null, jqXHR),
						type          : "error",
						autoRemove    : true
					});

					App.redirect({page: App.config.pages.MAINTENANCE});
				}
			} else if(jqXHR.response && jqXHR.response.errorCode == 5116){
				// Logged into different tenant.
				this.showSwitchTenantModal(jqXHR.response && jqXHR.response.data && jqXHR.response.data.tenant);

			} else if(retryable){
				this.generalMessage({
					message       : this.formatErrorMessage(response, App.i18n("errors.retry"), jqXHR),
					type          : "error",
					retryDelay    : retryDelay, 
					retryCallback : function(){
						if(ajaxSettings.url && ajaxSettings.url.indexOf("debug=") !== -1){
							ajaxSettings.url = ajaxSettings.url.replace(/([?&]debug=[^&]*)\-r\d+/, "$1");
							ajaxSettings.url = ajaxSettings.url.replace(/([?&]debug=[^&]*)/, "$1-r" + (ajaxSettings.tryCount + 1));
						}
						if(ajaxSettings.params && ajaxSettings.params.debug){
							ajaxSettings.params.debug = ajaxSettings.params.debug.replace(/\-r\d+$/, "");
							ajaxSettings.params.debug += "-r" + (ajaxSettings.tryCount + 1);
						}
						if(ajaxSettings.data && ajaxSettings.data.debug){
							ajaxSettings.data.debug = ajaxSettings.data.debug.replace(/\-r\d+$/, "");
							ajaxSettings.data.debug+= "-r" + (ajaxSettings.tryCount + 1);
						}
						$.ajax(ajaxSettings);
					}
				});

			} else{
				if(!this._ajaxOriginalError(jqXHR, textStatus, errorThrown, ajaxSettings)){

					if(ajaxSettings.originalError && !ajaxSettings.originalError._ajaxError){
						//Backbone error wrapper. Not really provided by user code
						ajaxSettings.originalError(jqXHR, textStatus, errorThrown);
					}
					if(App.unloading) { return; }

					this.generalMessage({
						message       : this.formatErrorMessage(response, null, jqXHR),
						type          : "error",
						autoRemove    : true
					});
				}
			}
		},
		/**
		 * Handle potential error result from JSONP call for IE8 and IE9
		 */
		_ajaxJSONPResult : function(result, textStatus, jqXHR, options){

			var isSuccess = result && result.status && (result.status >= 200 && result.status < 300 || result.status === 304);
			if(options.originalSuccess && isSuccess){
				options.originalSuccess(result, textStatus, jqXHR);
			} else {
				var errorThrown = "error";

				jqXHR.status = result.status;
				jqXHR.statusText = textStatus = errorThrown;

				if(options.error){
					options.error(jqXHR, textStatus, errorThrown);
				} else if (options.originalError){
					options.originalError(jqXHR, textStatus, errorThrown);
				}
			}

		},
		_ajaxOriginalError : function(jqXHR, textStatus, errorThrown, ajaxSettings){
			var originalError = ajaxSettings.originalError;
			if(originalError && originalError.hasOwnProperty("_hasUserHandler") && !originalError._hasUserHandler){
				originalError = null;
			}
			if(originalError){
				//Let original user error handler work
				originalError(jqXHR, textStatus, errorThrown);
				return true;
			}
			return false;
		},
		_ajaxLoginRecall : function(ajaxSettings){
			if(ajaxSettings.dataType === "json"){
				var data = {};
				try{
					data = (_.isObject(ajaxSettings.data) ? ajaxSettings.data : JSON.parse(ajaxSettings.data));
				} catch(e){}

				if(data.hasOwnProperty("username") && !data.username){
					data.username = App.user.get("username");
				}
				if(data.hasOwnProperty("email") && !data.email){
					data.email = App.user.get("email");
				}
				if(_.isString(ajaxSettings.data)){
					ajaxSettings.data = JSON.stringify(data);
				} else{
					ajaxSettings.data = data;
				}
			}
			$.ajax(ajaxSettings);
		},
		formatErrorMessage : function(errorMessage, defaultMessage, jqXHR){
			if(!defaultMessage) {
				defaultMessage = App.i18n("errors.general");
			}
			var message = _.escapeHTML(errorMessage) || defaultMessage;
			if(jqXHR) {
				if(jqXHR.response && jqXHR.response.errorCode) {
					message += " (error code: " + jqXHR.response.errorCode + ")";
				}
				message = "HTTP " + jqXHR.status + ": " + message;
			}
			return message;
		}

	}, {
		//Class properties
		LOGGED_IN        : "loggedIn",
		STARTUP_COMPLETE : "startupComplete"
	});

	//Return the requireJS module object.
	return VlpBaseView;
});

