
define('hbs!tpls/console/main.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "	<div class=\"partial-support\"></div>\n";
},"3":function(depth0,helpers,partials,data) {
    return "	<div id=\"entitlement\">\n		<div class=\"loading building-lab\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.building",{"name":"$","hash":{},"data":data}))
    + "\n			<div id=\"deploy_progress\" class=\"progress progress-info progress-striped active\">\n				<div class=\"bar\" style=\"width: 0%;\"></div>\n			</div>\n			<div class=\"time-elapsed\"></div>\n		</div>\n	</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"invalid-request alert alert-error hide\">\n	"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.invalid",{"name":"$","hash":{},"data":data}))
    + "\n</div>\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.partialSupportPrompt : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});
Handlebars.registerPartial('console_main', t);
return t;
});

