/**
 * Account Profile Backbone View
 */
define('views/common/profile',['require','jquery','underscore','backbone','library/vlp/app','library/vlp/view','views/common/public-profile','nls/manifest','hbs!tpls/common/profile.handlebars','hbs!tpls/common/profile/general.handlebars','hbs!tpls/common/profile/contact-info.handlebars','hbs!tpls/common/profile/preferences.handlebars','hbs!tpls/common/profile/about.handlebars','hbs!tpls/common/profile/timezones.handlebars','hbs!tpls/common/profile/timezone-map.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App               = require("library/vlp/app"),
	    BaseView          = require("library/vlp/view"),
	    PublicProfileView = require("views/common/public-profile"),
	    Translations      = require("nls/manifest"),
	    tpl               = require("hbs!tpls/common/profile.handlebars");

	//These register as handlebars partials.
	require("hbs!tpls/common/profile/general.handlebars");
	require("hbs!tpls/common/profile/contact-info.handlebars");
	require("hbs!tpls/common/profile/preferences.handlebars");
	require("hbs!tpls/common/profile/about.handlebars");
	require("hbs!tpls/common/profile/timezones.handlebars");
	require("hbs!tpls/common/profile/timezone-map.handlebars");

	return BaseView.extend({
		template : tpl,
		removeKeepEl : true,

		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		el : "#user_profile",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"submit #profile_general_form"             : "saveGeneral",
			"submit #profile_contact_info_form"        : "saveContactInfo",
			"submit #profile_about_form"               : "saveAbout",
			"submit #profile_preferences_form"         : "savePreferences",
			"submit #profile_security_questions_form"  : "saveSecurityQuestions",
			"submit #profile_password_form"            : "savePassword",
			"change #profile_password_show"            : "showHidePassword",
			"change #profile_security_password_show"   : "showHideSecurityPassword",
			"change #profile_security_answers_show_hide" : "showHideSecurityAnswers",
			"change #profile_email_password_show"      : "showHideEmailPassword",
			"change #profile_email"                    : "showConfirmPassword",
			"change [name=securityQuestion]"           : "securityQuestionChanged",
			"click .cancel-password"                   : "cancelPassword",
			"click .preview-public-profile"            : "previewPublicProfile",
			"click [data-toggle=button]"               : "updateToggleButtonValue",
			"hidden .collapse"                         : "sectionHidden"
		},
		modelBindings : {
			"form #profile_username"                           : "username",
			"form #profile_company"                            : "company",
			"form #profile_password"                           : "password",
			"form #profile_password_visible"                   : "password",
			"form #profile_passwordConfirm"                    : "passwordConfirm",
			"form #profile_passwordConfirm_visible"            : "passwordConfirm",
			"form #profile_passwordCurrent"                    : "passwordCurrent",
			"form #profile_passwordCurrent_visible"            : "passwordCurrent",
			"form #profile_passwordCurrentSecurity"            : "securityPasswordConfirm",
			"form #profile_passwordCurrentSecurity_visible"    : "securityPasswordConfirm",
			"form #profile_emailChangePasswordConfirm"         : "emailChangePasswordConfirm",
			"form #profile_emailChangePasswordConfirm_visible" : "emailChangePasswordConfirm",
			"form #profile_firstName"                          : "firstName",
			"form #profile_lastName"                           : "lastName",
			"form #profile_displayName"                        : "displayName",
			"form #profile_email"                              : "email",
			"form #profile_phone"                              : "phone",
			"form #profile_streetAddress1"                     : "streetAddress1",
			"form #profile_streetAddress2"                     : "streetAddress2",
			"form #profile_city"                               : "city",
			"form #profile_state"                              : "state",
			"form #profile_zip"                                : "postalCode",
			"form #profile_country"                            : "country",
			"form #profile_shortBio"                           : "shortBio",
			"form #profile_language"                           : "language",
			"form #profile_location"                           : "location",
			"form [name=timezone]"                             : "timezone",
			"form #profile_showTips"                           : "showTips",
			"#profile_avatar"                                  : "avatar",
			"#current_username"                                : "username"
		},

		validation : {
			firstName : {
				maxLength : 255,
				required  : true
			},
			lastName : {
				maxLength : 255,
				required  : true
			},
			displayName : {
				maxLength : 255,
				required  : true
			},
			company : {
				maxLength : 255,
				required  : true
			},
			phone : {
				maxLength : 255
			},
			streetAddress1 : {
				maxLength : 255
			},
			streetAddress2 : {
				maxLength : 255
			},
			city : {
				maxLength : 255
			},
			state : {
				maxLength : 255
			},
			postalCode : {
				maxLength : 255
			},
			country : {
				maxLength : 255
			},
			email :{
				required : true,
				pattern : "email",
				maxLength : 255
			},
			shortBio :{
				required : false,
				maxLength : 1024
			},

			//Used in changing the email address
			emailChangePasswordConfirm : {
				required : function(value, attr, computedState) {
					return this.initialValues.email !== this.model.get("email");
				}
			},
			securityPasswordConfirm : {
				required : true
			},
			password : {
				required : true
			},
			//Used in changing the email address
			passwordConfirm : {
				required : true,
				equalTo  : "password",
				autoValidate : false
			},
			passwordCurrent : {
				required : true
			}

		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			options = options || {};
			this.verifyMode    = options.verifyMode;
			this.isModal       = options.isModal;
			this.resetPassword = options.resetPassword;
			this.token         = options.token;

			$("#modal_profile").on("hidden", this.closed);
		},

		/**
		 * Get Data to render
		 */
		serialize : function(options){
			options = _.extend({showGeneral : true, showPassword : true, showSecurityQuestions : true}, options || this.lastOptions || {});
			this.lastOptions = options;

			var data = this.model.toHandlebars();
			data.states    = _.map(App.config.states,    function(label, key){ return {label : label, value : key}; });
			data.locations = _.map(App.config.locations, function(label, key){ return {label : label, value : key}; });
			data.countries = _.map(App.config.countries, function(label, key){ return {label : label, value : key}; });

			var localeStrings = (App.locale && App.locale.i18nStrings && App.locale.i18nStrings.locales) || {};
			data.languages = _.map(App.config.locales, function(label, key){
				label = localeStrings[key] || label;
				return {
					label : label,
					value : key,
					manualOnly : (key != "default" && key != "en" && !Translations[key.toLowerCase()])
				};
			});
			data.showGeneral           = this.lastOptions.showGeneral;
			data.showPassword          = this.lastOptions.showPassword;
			data.showSecurityQuestions = this.lastOptions.showSecurityQuestions;
			data.verifyMode            = this.verifyMode;
			data.isModal               = this.isModal;
			data.resetPassword         = this.resetPassword || this.verifyMode;

			data.activePanels = [];
			if(this.lastOptions.showGeneral){
				data.activePanels.push("general");
			}
			if(this.lastOptions.showPassword){
				data.activePanels.push("password");
			}
			if(this.lastOptions.showSecurityQuestions){
				data.activePanels.push("securityQuestions");
			}

			data.stationId = App.station && App.station.get("stationId");

			data.collapsable = (data.activePanels.length > 1);


			if(this.lastOptions.showGeneral){
				data.activePanel = "general";
			} else if(this.lastOptions.showPassword){
				data.activePanel = "password";
			} else if(this.lastOptions.showSecurityQuestions){
				data.activePanel = "securityQuestions";
			}

			return data;
		},
		/**
		 * Display the content.
		 */
		beforeRender : function(options){
			this.initialValues = this.model.toJSON();
		},
		afterRender : function(){
			var self = this;

			this.$("[rel=tooltip]").tooltip();
			this.updateBindings();


			//timezone handling
			$("#modal_timezone_map").off("shown.profile").on("shown.profile", function(){
				$("#timezone-image").timezonePicker("updateTimezone", "");
				$("#timezone-image").timezonePicker("updateTimezone", self.model.get("timezone"));
			});
			//timezone handling
			$("#modal_timezone_map").off("hidden.profile").on("hidden.profile", function(){
				self.model.set("timezone", $("#modal_timezone_map [name=timezone]").val());
			});
			$("#timezone-image").timezonePicker({
				target   : "[name=timezone]"
			});
			$("#modal_timezone_map [name=timezone]").change(function(){
				self.model.set("timezone", $(this).val());
			});

			$("#modal_timezone_map .timezone-detect").click(function(){
				$("#timezone-image").timezonePicker("detectLocation");
			});

			this.$(".help-block").hide();

			this.$("[data-toggle=button]")
				.tooltip({
					title : function(){
						return $(this).hasClass("active") ? App.i18n("profile.privateTooltip") : App.i18n("profile.publicTooltip");
					}
				})
				.each(function(){
					var prop = $(this).data("property");
					if(self.model.get(prop)){
						$(this).addClass("active");
					}
				});
			$("#modal_profile").modal("reflow");
		},
		showSaveError : function(response, responseAlt){
			var self = this;

			var $form;
			if(responseAlt && responseAlt.response){
				response = responseAlt.response;
			}
			if(responseAlt instanceof $){
				$form = responseAlt;
			}
			if(response && response.response){
				response = response.response;
			}
			if(response){
				var errorsList = response.errors || response;
				if(errorsList && errorsList.message){
					errorsList = [errorsList.message];
				}
				_.each(errorsList, function(errors, property){
					if(_.isNumber(property) && _.isArray(errors)){
						property = _.keys(errors)[0];
						errors = _.values(errors);
					} else if (_.isNumber(property)){
						property = null;
					}

					if(property){
						if(this.$("form #profile_" + property).length){
							self.showValidationError("form #profile_" + property, property, errors);
						} else if(this.$("form [name=" + property + "]").length){
							self.showValidationError("form [name=" + property + "]", property, errors);
						}
					} else if(errors && errors.length){
						if($form && $form.find(".save-error-general").length) {
							$form.find(".save-error-general")
								.html(_.isArray(errors) ? errors.join("<br />") : errors)
								.show();
						} else{
							App.generalMessage({
								type        : "error",
								message     : _.isArray(errors) ? errors.join("<br />") : errors,
								removeDelay : 10000
							});
						}
					}
				}, this);
			}

			if($form) {
				$form.find(".invalid:first").focus();
			}
		},
		resetFormErrors : function(){
			this.$("form .control-group.error")
				.removeClass("error")
				.find(".help-block")
				.hide()
				.empty();
			this.$("form .save-error-general").hide();
		},



		saveGeneral : function(event){
			if(event){ event.preventDefault(); }

			var self = this;
			var $form = this.$("#profile_general_form");

			//Properties to save in this form
			var saveProperties = [
				"username",
				"company",     "company_public",
				"firstName",   "firstName_public",
				"lastName",    "lastName_public",
				"displayName", "displayName_public"
			];


			//Validate and reject if invalid
			if(this.validate(saveProperties, {displayErrors: true})){
				$form.find(".invalid:first").focus();
				return;
			}

			var $saveSpinner = $form.find(".save-spinner");
			var $saveSuccess = $form.find(".save-success");
			var $saveError   = $form.find(".save-error");
			var $saveButton  = $form.find(".save-changes");

			this.model.save({}, {
				properties : saveProperties,
				success : function(){
					$(".display-user-name").text(self.model.get("displayName"));
					self.initialValues = self.model.toJSON();
					$saveSuccess.show().stop().delay(3000).fadeOut("slow");
					App.analytics.trackEvent("Account", "Update Profile", "General");
				},
				error : function(model, response){
					$saveError.show().stop().delay(6000).fadeOut("slow");
					self.showSaveError(response);
				},
				beforeSend : function(){
					$saveSpinner.show();
					$saveSuccess.hide();
					$saveError.hide();
					$saveButton.button("loading");
				},
				complete : function(){
					$saveSpinner.hide();
					$saveButton.button("reset");
				}
			});
		},
		saveContactInfo : function(event){
			if(event){ event.preventDefault(); }

			this.resetFormErrors();

			var self = this;
			var $form = this.$("#profile_contact_info_form");

			//Properties to save in this form
			var saveProperties = [
				"phone",          "phone_public",
				"streetAddress1", "streetAddress1_public",
				"streetAddress2", "streetAddress2_public",
				"city",           "city_public",
				"state",          "state_public",
				"postalCode",     "postalCode_public",
				"country",        "country_public",
				"email_public"
			];


			var validateProperties = _.clone(saveProperties);
			validateProperties.push("emailChangePasswordConfirm");

			//Add email to save validation if changed
			if(this.initialValues.email !== this.model.get("email")){
				this.model.set("passwordCurrent", this.model.get("emailChangePasswordConfirm"));
				saveProperties.push("email");
				saveProperties.push("passwordCurrent");

				validateProperties.push("email");
			}


			//Validate and reject if invalid
			if(this.validate(validateProperties, {displayErrors: true})){
				$form.find(".invalid:first").focus();
				return;
			}


			var $saveSpinner   = $form.find(".save-spinner");
			var $saveSuccess   = $form.find(".save-success");
			var $saveError     = $form.find(".save-error");
			var $saveButton    = $form.find(".save-changes");


			this.model.save({}, {
				properties : saveProperties,
				success : function(){
					self.model.unset("emailChangePasswordConfirm", "");
					self.model.set("passwordCurrent", "");
					self.initialValues = self.model.toJSON();
					self.$(".confirmPassword").hide();
					$saveSuccess.show().stop().delay(3000).fadeOut("slow");
					App.analytics.trackEvent("Account", "Update Profile", "Contact Information");
				},
				error : function(model, response){
					$saveError.show().stop().delay(6000).fadeOut("slow");
					self.showSaveError(response, $form);
				},
				beforeSend : function(){
					$saveSpinner.show();
					$saveSuccess.hide();
					$saveError.hide();
					$saveButton.button("loading");
				},
				complete : function(){
					$saveSpinner.hide();
					$saveButton.button("reset");
				}
			});
		},

		savePreferences : function(event){
			if(event){ event.preventDefault(); }

			var self = this;
			var $form = this.$("#profile_preferences_form");

			//Properties to save in this form
			var saveProperties = [
				"language",
				"location",
				"showTips",
				"timezone"
			];


			//Validate and reject if invalid
			if(this.validate(saveProperties, {displayErrors: true})){
				$form.find(".invalid:first").focus();
				return;
			}



			var $saveSpinner = $form.find(".save-spinner");
			var $saveSuccess = $form.find(".save-success");
			var $saveError   = $form.find(".save-error");
			var $saveButton  = $form.find(".save-changes");

			this.model.save({}, {
				properties : saveProperties,
				success : function(){
					self.initialValues = self.model.toJSON();
					$saveSuccess.show().stop().delay(3000).fadeOut("slow");
					App.locale.set(self.model.get("locale"));
					App.analytics.trackEvent("Account", "Update Profile", "Preferences");
				},
				error : function(model, response){
					$saveError.show().stop().delay(6000).fadeOut("slow");
					self.showSaveError(response);
				},
				beforeSend : function(){
					$saveSpinner.show();
					$saveSuccess.hide();
					$saveError.hide();
					$saveButton.button("loading");
				},
				complete : function(){
					$saveSpinner.hide();
					$saveButton.button("reset");
				}
			});
		},
		showConfirmPassword : function(event){
			if(event){
				event.preventDefault();
			}

			if(this.initialValues.email !== this.model.get("email")){
				this.$(".confirmPassword").show();
				this.$(".confirmPassword input[type=password]").focus();
			} else{
				this.$(".confirmPassword").hide();
			}

		},
		securityQuestionChanged : function(event){
			if(event){
				event.preventDefault();
			}
		},
		saveAbout : function(event){
			if(event){
				event.preventDefault();
			}

			var saveProperties = ["shortBio", "shortBio_public"];

			if(this.validate(saveProperties, {displayErrors: true})){ return; }
			var self = this;

			var $form = this.$("#profile_about_form");

			var $saveSpinner = $form.find(".save-spinner");
			var $saveSuccess = $form.find(".save-success");
			var $saveError   = $form.find(".save-error");
			var $saveButton  = $form.find(".save-changes");


			//Save call also validates
			this.model.save({}, {
				properties : saveProperties,
				success : function(){
					self.initialValues = self.model.toJSON();
					$saveSuccess.show().stop().delay(3000).fadeOut("slow");
					App.analytics.trackEvent("Account", "Update Profile", "About");
				},
				error : function(model, response){
					$saveError.show().stop().delay(6000).fadeOut("slow");
					self.showSaveError(response, $form);
				},
				beforeSend : function(){
					$saveSpinner.show();
					$saveSuccess.hide();
					$saveError.hide();
					$saveButton.button("loading");
				},
				complete : function(){
					$saveSpinner.hide();
					$saveButton.button("reset");
				}
			});
		},
		savePassword : function(event){
			if(event){
				event.preventDefault();
			}
			this.resetFormErrors();

			var self = this;

			var $form = this.$("#profile_password_form");

			var validateProperties = ["password", "passwordConfirm"];
			if(!this.verifyMode && !this.resetPassword){
				validateProperties.push("passwordCurrent");
			}
			if(this.validate(validateProperties, {displayErrors: true})){ return; }

			var $saveSpinner = $form.find(".save-spinner");
			var $saveSuccess = $form.find(".save-success");
			var $saveError   = $form.find(".save-error");
			var $saveButton  = $form.find(".save-changes");

			//Save call also validates
			this.model.changePassword({
				success : function(){
					self.model.set("password", "");
					self.model.set("passwordConfirm", "");
					self.model.set("passwordCurrent", "");
					self.model.unset("token");
					$saveSuccess.show().stop().delay(3000).fadeOut("slow");
					self.model.trigger("passwordUpdated");
					App.analytics.trackEvent("Account", "Update Profile", "Password");
				},
				error   : function(model, response){
					if(!(model instanceof Backbone.Model)){
						response = model;
					}
					$saveError.show().stop().delay(6000).fadeOut("slow");
					self.showSaveError(response, $form);
				},
				beforeSend : function(){
					$saveSpinner.show();
					$saveSuccess.hide();
					$saveError.hide();
					$saveButton.button("loading");
				},
				complete : function(){
					$saveSpinner.hide();
					$saveButton.button("reset");
				}
			});
		},
		saveSecurityQuestions : function(event){
			if(event){
				event.preventDefault();
			}
			this.resetFormErrors();

			var self = this;

			var $form = this.$("#profile_security_questions_form");
			var errors = false;

			var validateProperties = [];
			if(!this.verifyMode){
				validateProperties.push("securityPasswordConfirm");
			}


			var questions = [];
			$form.find("[name=securityQuestion]").each(function(index){
				var question = {
					id       : null,
					question : false,
					answer  : ""
				};

				var $this = $(this);
				var $controlGroup = $this.closest(".control-group");
				var $answer = $controlGroup.find("[name=securityAnswer]");

				question.answer = _.trim($answer.val());
				question.question = _.trim($this.val());

				question.id = $this.data("id");

				//var modelQuestion = self.model.get("securityQuestions") && self.model.get("securityQuestions")[index] && self.model.get("securityQuestions")[index].question;

				if(question.answer === ""){
					if(self.model.get("setQuestions") === true ){
						$answer.siblings(".help-block").text(App.i18n("profile.securityQuestions.questionError")).show();
						$controlGroup.addClass("error").focus();
						errors = true;
					}else{
						question.answer = _.repeat("X", 256);
					}
				} else if (question.answer.length > 255){
					$answer.siblings(".help-block").text(App.i18n("profile.securityQuestions.answerTooLong", question.answer.length)).show();
					$controlGroup.addClass("error").focus();
					errors = true;
				}
				if(question.question === ""){
					$answer.siblings(".help-block").text(App.i18n("profile.securityQuestions.questionError")).show();
					$controlGroup.addClass("error").focus();
					errors = true;
				}
				questions.push(question);

			});

			if(this.validate(validateProperties, {displayErrors: true}) || errors) { return; }



			var $saveSpinner = $form.find(".save-spinner");
			var $saveSuccess = $form.find(".save-success");
			var $saveError   = $form.find(".save-error");
			var $saveButton  = $form.find(".save-changes");

			var model = this.model;
			var properties = ["securityQuestions"];
			if(!this.verifyMode){
				this.model.set("passwordCurrent", this.model.get("securityPasswordConfirm"));
				properties.push("passwordCurrent");
			}


			this.model.set("securityQuestions", questions);
			var options = {
				properties : properties,
				success : function(){
					_.each(self.model.get("securityQuestions"), function(question, index){
						var $question = $form.find("[name=securityQuestion]:eq(" + index + ")");
						if($question){
							$question.val(question.question);
							$question.attr("data-id", question.id);
						}
					});
					self.model.set("passwordCurrent", "");
					self.model.set("securityPasswordConfirm", "");
					$saveSuccess.show().stop().delay(3000).fadeOut("slow");
					self.model.trigger("securityQuestionsUpdated");
					App.analytics.trackEvent("Account", "Update Profile", "Security Questions");
				},
				error   : function(model, response){
					$saveError.show().stop().delay(6000).fadeOut("slow");
					self.showSaveError(response, $form);
				},
				beforeSend : function(){
					$saveSpinner.show();
					$saveSuccess.hide();
					$saveError.hide();
					$saveButton.button("loading");
				},
				complete : function() {
					$saveSpinner.hide();
					$saveButton.button("reset");
				}
			};

			model.saveSecurityQuestion(options);
		},
		showHidePassword : function(){

			if(this.$("#profile_password_show").prop("checked")){
				this.$("#profile_password_visible").show();
				this.$("#profile_passwordConfirm_visible").show();
				this.$("#profile_passwordCurrent_visible").show();
				this.$("#profile_password").hide();
				this.$("#profile_passwordConfirm").hide();
				this.$("#profile_passwordCurrent").hide();
			} else{
				this.$("#profile_password_visible").hide();
				this.$("#profile_passwordConfirm_visible").hide();
				this.$("#profile_passwordCurrent_visible").hide();
				this.$("#profile_password").show();
				this.$("#profile_passwordConfirm").show();
				this.$("#profile_passwordCurrent").show();
			}

		},
		showHideSecurityAnswers : function(){

			if ($(".security-answer").prop("type") === "password") {
				$(".security-answer").prop("type", "text");
			} else {
				$(".security-answer").prop("type", "password");
			}

		},
		showHideSecurityPassword : function(){

			if(this.$("#profile_security_password_show").prop("checked")){
				this.$("#profile_passwordCurrentSecurity_visible").show();
				this.$("#profile_passwordCurrentSecurity").hide();
			} else{
				this.$("#profile_passwordCurrentSecurity_visible").hide();
				this.$("#profile_passwordCurrentSecurity").show();
			}

		},
		showHideEmailPassword : function(){

			if(this.$("#profile_email_password_show").prop("checked")){
				this.$("#profile_emailChangePasswordConfirm_visible").show();
				this.$("#profile_emailChangePasswordConfirm").hide();
			} else{
				this.$("#profile_emailChangePasswordConfirm_visible").hide();
				this.$("#profile_emailChangePasswordConfirm").show();
			}

		},
		cancelPassword : function(){
			this.$(".cancel-password").button("loading");
			App.user.logout({
				complete : function(){
					App.redirect({page: App.config.pages.HOME});
				}
			});
		},
		updateToggleButtonValue : function(event){
			var prop = $(event.currentTarget).data("property");
			this.model.set(prop, !$(event.currentTarget).hasClass("active"));
		},
		previewPublicProfile : function(event){
			if(event){
				event.preventDefault();
			}

			if(this.publicProfileView){
				this.publicProfileView.remove();
				this.publicProfileView = null;
			}

			this.publicProfileView = new PublicProfileView({
				model : App.user
			});
			this.publicProfileView.show();
		},
		closed : function(event){
			if(event && event.currentTarget !== event.target){ return; }

			this.model.set("password", "");
			this.model.set("passwordConfirm", "");
			this.model.set("passwordCurrent", "");
			this.model.unset("emailChangePasswordConfirm", "");
			this.model.set(this.initialValues);

			if(this.publicProfileView){
				this.publicProfileView.remove();
				this.publicProfileView = null;
			}

		},
		sectionHidden : function(event){
			if($(event.target).hasClass("collapse")){
				this.hideValidationErrors(event.currentTarget);
			}
		}
	});

});

