/**
 * Vm Backbone View
 */
define('views/console/entitlement-settings',['require','underscore','library/vlp/app','library/vlp/view','utilities/browser','hbs!tpls/console/entitlement-settings.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//class dependencies
	var App                = require("library/vlp/app"),
	    BaseView           = require("library/vlp/view"),
	    Browser            = require("utilities/browser"),
	    tpl                = require("hbs!tpls/console/entitlement-settings.handlebars");

	return BaseView.extend({
		template : tpl,

		modelBindings : {
			"#enable_manual_shortcuts"       : "manualShortcuts",
			"#intercept_paste"               : "interceptPaste",
			"#macos_remap_command_to_control": "macOsRemapCommandToControl",
			"#keyboard_layout_id"            : "keyboardLayoutId",
			"#fix_ansi"                      : "fixANSI",
			"#reverse_mouse_y"               : "reverseScrollY",
			"#save_panel_positions"          : "savePanelPositions"
		},

		initialize : function(options){
			_.bindAll(this);
			options = options || {};

			this.listenTo(this.model, {
				"change:manualShortcuts"                      : this.onSettingsChanged,
				"change:fixANSI"                              : this.onSettingsChanged,
				"change:keyboardLayoutId"                     : this.onSettingsChanged,
				"change:interceptPaste"                       : this.onSettingsChanged,
				"change:macOsRemapCommandToControl"           : this.onSettingsChanged,
				"change:savePanelPositions"                   : this.onSettingsChanged,
				"change:fixANSIPromptShown"                   : this.onSettingsChanged,
				"change:interceptPastePromptShown"            : this.onSettingsChanged,
				"change:macOsRemapCommandToControlPromptShown": this.onSettingsChanged,
				"change:reverseScrollY"                       : this.onSettingsChanged
			});
			this.model.set(App.session.get("consoleSettings") || {});
		},
		remove : function(){
			BaseView.prototype.remove.call(this);
		},
		serialize : function(){
			var data = this.model.toHandlebars();
			data.fixANSISupported = Browser.fixANSISupported;
			data.isMacOs = (Browser.osType === "Mac");
			return data;
		},
		onSettingsChanged : function(){
			if(this.model.get("fixANSI")){
				this.model.set("fixANSIPromptShown", true, {silent: true});
			}
			var settings = this.model.pick(
				"manualShortcuts",
				"fixANSI",
				"keyboardLayoutId",
				"interceptPaste",
				"macOsRemapCommandToControl",
				"savePanelPositions",
				"fixANSIPromptShown",
				"interceptPastePromptShown",
				"macOsRemapCommandToControlPromptShown",
				"reverseScrollY"
			);
			App.session.set("consoleSettings", settings, 1);

			App.analytics.trackEvent("Entitlement", "Change Settings", this.model.get("sku"));
		}
	});

});

