
define('hbs!tpls/console/broadcast-vm.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "				<button type=\"button\" class=\"btn-no-style full-screen maximized-inline\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.fullScreen",{"name":"$$","hash":{},"data":data}))
    + " data-container=\"body\" data-placement=\"bottom\">\n					<span class=\"fa fa-fw fa-desktop\"></span>\n				</button>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div id=\"vm_group\">\n\n	<div id=\"vm_above_bar\">\n		<span class=\"broadcast-message animated infinite flash slower\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"class.showingBroadcastDisplay",{"name":"$","hash":{},"data":data}))
    + "</span>\n		<div class=\"buttons maximized-block\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.allowFullscreen : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n\n	<div class=\"vm-center\">\n		<div class=\"vm-icon-frame powered-down\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.poweredDownTooltip",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.poweredDown",{"name":"$","hash":{},"data":data}))
    + "</div>\n		<div class=\"vm-loading vm-icon-frame "
    + this.escapeExpression(((helper = (helper = helpers.osType || (depth0 != null ? depth0.osType : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"osType","hash":{},"data":data}) : helper)))
    + "\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n		<div class=\"vm-unavailable vm-icon-frame\">\n			<div>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.unavailable",{"name":"$","hash":{},"data":data}))
    + "</div>\n			<button type=\"button\" class=\"btn screen-retry\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","console.vm.buttons.retrying",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.buttons.screenRetry",{"name":"$","hash":{},"data":data}))
    + "</button>\n		</div>\n		<div class=\"vm-cannot-connect vm-icon-frame\">\n			<div>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.cannotConnect",{"name":"$","hash":{},"data":data}))
    + "</div>\n			<button type=\"button\" class=\"btn page-refresh\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.buttons.refresh",{"name":"$","hash":{},"data":data}))
    + "</button>\n		</div>\n\n		<div id=\"vm_console_wrapper\" class=\"docked\">\n			<div id=\"vm_console_wrapper_inner\">\n				<div id=\"vm_console\"></div>\n			</div>\n		</div>\n	</div>\n\n</div>";
},"useData":true});
Handlebars.registerPartial('console_broadcast_vm', t);
return t;
});

