define('models/help-request',['require','exports','module','underscore','library/vlp/app','library/vlp/model'],function (require, exports) {
	"use strict";

//library dependencies
	var _ = require("underscore");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model");

	//Use exports for requirejs circular dependency resolving
	var HelpRequest = exports;

	//Add HelpRequest to the App.Models namespace
	App.Models.HelpRequest = HelpRequest;


	HelpRequest.Model = BaseModel.extend({
		urlRoot     : "events",
		pushService : "helpRequest",

		/**
		 * Default values for empty Models (new HelpRequest.Model())
		 */
		defaults  : {
			stationId : null
		},
		/**
		 * Constructor
		 */
		initialize : function(){

		},

		url : function () {
			var urlRoot = _.result(this, "basePath");
			return this.serverBase + urlRoot;
		},
		toggle : function(options){
			options = options || {};
			options.params = options.params || {};
			options.params.stationId = options.params.stationId || this.get("stationId") || (App.station && App.station.get("stationId"));
			options.method = "read";

			return this.action("requestHelp", options);
		},
		toggleOn : function(options){
			options = options || {};
			options.params = options.params || {};
			options.params.stationId = options.params.stationId || this.get("stationId") || (App.station && App.station.get("stationId"));
			options.params.status = "on";
			options.method = "read";

			return this.action("requestHelp", options);
		},
		toggleOff : function(options){
			options = options || {};
			options.params = options.params || {};
			options.params.stationId = options.params.stationId || this.get("stationId") || (App.station && App.station.get("stationId"));
			options.params.status = "off";
			options.method = "read";

			return this.action("requestHelp", options);
		},
		requestCaptain : function(options){
			options = options || {};
			options.params = options.params || {};
			options.params.station = this.get("stationId");
			options.params.tenant = App.getTenant();
			if(App.entitlementId){
				options.params.entitlement = App.entitlementId;
			}

			options.properties = [];
			return this.action("captainRequest", options);
		}
	});

});

