
define('hbs!tpls/catalogs/enrollment-listing.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "		<div class=\"loading well\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.length : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(depth0,helpers,partials,data) {
    return "			<div class=\"items-holder\"></div>\n";
},"6":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<div class=\"lab-item no-enrollments\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.loggedIn : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "			</div>\n";
},"7":function(depth0,helpers,partials,data) {
    return "					<h3>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.enrollments.empty",{"name":"$","hash":{},"data":data}))
    + "</h3>\n";
},"9":function(depth0,helpers,partials,data) {
    return "					<div class=\"alert alert-danger\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.enrollments.pleaseLogin",{"name":"$","hash":{},"data":data}))
    + "\n					</div>\n";
},"11":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"catalogInfiniteScrolling",{"name":"config","hash":{},"fn":this.program(12, data, 0),"inverse":this.program(17, data, 0),"data":data})) != null ? stack1 : "");
},"12":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<div class=\"fetch-more\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.nextPage : depth0),{"name":"if","hash":{},"fn":this.program(13, data, 0),"inverse":this.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "		</div>\n";
},"13":function(depth0,helpers,partials,data) {
    var helper;

  return "				<button type=\"button\" value=\""
    + this.escapeExpression(((helper = (helper = helpers.nextPage || (depth0 != null ? depth0.nextPage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"nextPage","hash":{},"data":data}) : helper)))
    + "\" class=\"fetch-more-trigger btn\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"fetchMore",{"name":"$","hash":{},"data":data}))
    + "<span class=\"fa fa-fw fa-ellipsis-v\" /></button>\n";
},"15":function(depth0,helpers,partials,data) {
    return "				<span class=\"all-fetched\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.enrollments.allFetched",{"name":"$","hash":{},"data":data}))
    + "</span>\n";
},"17":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<ul class=\"enrollment-pager\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.prevPage : depth0),{"name":"if","hash":{},"fn":this.program(18, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.nextPage : depth0),{"name":"if","hash":{},"fn":this.program(20, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</ul>\n";
},"18":function(depth0,helpers,partials,data) {
    return "				<li class=\"previous pull-left\">\n					<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"enrollments/{{prevPage}}"},"data":data}))
    + "\" class=\"btn\"><span class=\"fa fa-fw fa-arrow-left\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"previous",{"name":"$","hash":{},"data":data}))
    + "</a>\n				</li>\n";
},"20":function(depth0,helpers,partials,data) {
    return "				<li class=\"next pull-right\">\n					<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"enrollments/{{nextPage}}"},"data":data}))
    + "\" class=\"btn\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"next",{"name":"$","hash":{},"data":data}))
    + "<span class=\"fa fa-fw fa-arrow-right\"></span></a>\n				</li>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"header-bar\">\n	<h3>\n		<span class=\"fa fa-flask skin-icon\"></span>\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.enrollments.title",{"name":"$","hash":{},"data":data}))
    + "\n	</h3>\n</div>\n<div class=\"content\">\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.loading : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.loading : depth0),{"name":"unless","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
Handlebars.registerPartial('catalogs_enrollment_listing', t);
return t;
});

