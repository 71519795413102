/**
 * Instructor Router
 *
 * Code starts running here for the /instructor page.
 */
define('routers/support',['require','library/vlp/router','views/support/main'],function (require) {
	"use strict";

	//library dependencies

	//class dependencies
	var BaseRouter = require("library/vlp/router"),
	    MainView   = require("views/support/main");


	return BaseRouter.extend({
		routeBase : "support",
		/**
		 * Constructor
		 *
		 * This code is called when new Router([options]) is called in the example.html.
		 *
		 * @param options Map of options (mostly passed on to new MainView([options]);
		 */
		initialize : function(config){

			this.setup(config);

			this.mainView = new MainView(config);

			this.start();
		},
		/**
		 * List of possible routes
		 */
		routes: {
			"console/:entitlementId(/:consoleId)" : "showEntitlement",
			"console/:entitlementId"              : "showEntitlement",
			"console/:entitlementId/"             : "showEntitlement"
		},
		showEntitlement : function(entitlementId, consoleId){
			this.mainView.showEntitlement(entitlementId, consoleId);
		}
	});
});

