define('views/common/system-messages',['require','jquery','underscore','backbone','hbs!tpls/common/system-messages.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var tpl = require("hbs!tpls/common/system-messages.handlebars");

	return Backbone.View.extend({
		template : tpl,

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
//			"click .previous" : "previous",
			"slid .carousel"    : "changeMessage",
			"click .acknowledge" : "acknowledge"
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(){
			_.bindAll(this);
			this.listenTo(this.collection, "request sync error", function(object){
				if(object === this.collection){
					this.render();
				}
			}, this);
			this.listenTo(this.collection, "add", function(data){
				this.render();
				this.showMessage(data);
			}, this);
		},
		/**
		 * Display the content.
		 */
		render : function(){
			this.$el.html(this.template(this.collection));

			this.$(".left").addClass("hide");
			if(this.collection.length <= 1){
				this.$(".right").addClass("hide");
			}

			this.$(".item").first().addClass("active");
			$("#modal_system_message").modal("reflow");
			this.$(".carousel").carousel();
			//Always return this for chaining
			return this;
		},
		acknowledge : function(event){
			var self = this;
			var $button = $(event.currentTarget);
			var id = $button.val();
			var message = this.collection.get(id);
			if(message){
				var index = this.collection.indexOf(message);
				var successCallback = function () {
					$button.button("reset");
					if (self.collection.length === 1) {
						self.collection.remove(message);
						$("#modal_system_message").modal("hide");
					} else if (index >= self.collection.length - 1) {
						self.$(".carousel").carousel("prev");
						self.$(".carousel").one("slid", function () {
							self._removeMessage(message);
						});

					} else {
						self.$(".carousel").carousel("next");
						self.$(".carousel").one("slid", function () {
							self._removeMessage(message);
						});
					}
					//
				};
				if(!message.get("isImmediate")) {
					$button.button("loading");
					message.acknowledge({ success: successCallback });
				} else {
					successCallback();
				}
			}
		},
		showMessage : function(message){
			var index = this.collection.indexOf(message);
			if(index !== -1){
				this.$(".carousel").carousel(index);
				this.$(".carousel").carousel("pause");
				if(index > 0) {
					this.$(".left").removeClass("hide");
				} else if(index === 0){
					this.$(".left").addClass("hide");
				}
				if(index < this.collection.length -1){
					this.$(".right").removeClass("hide");
				} else if(index === this.collection.length -1){
					this.$(".right").addClass("hide");
				}
			}
		},
		changeMessage : function(event){
			$("#modal_system_message").modal("reflow");
		},
		_removeMessage : function(message){
			this.collection.remove(message);

			this.$(".carousel").carousel("pause");
			this.$(".item[data-id="+message.get("id")+"]").remove();
			if(this.collection.length <= 1){
				this.$(".left").addClass("hide");
				this.$(".right").addClass("hide");
			}
		}
	});

});
