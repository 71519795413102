/**
 * Vm Backbone View
 */
define('views/instructor/console/vm-console',['require','underscore','library/vlp/app','views/console/vm-console','hbs!tpls/instructor/console/vm-console.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var _   = require("underscore");

	//class dependencies
	var App        = require("library/vlp/app"),
	    BaseVmView = require("views/console/vm-console"),
	    vmTPL      = require("hbs!tpls/instructor/console/vm-console.handlebars");


	return BaseVmView.extend({
		template : vmTPL,
		events : {

		},

		initialize : function(){
			BaseVmView.prototype.initialize.apply(this, arguments);
			this.events = _.defaults(this.events, BaseVmView.prototype.events);

			this.listenTo(this.entitlement, {
				"change:phase"                        : this.onPhaseChanged,
				"change:studentConsoleLatencyCurrent" : this.onStudentConsoleLatencyCurrentChanged,
				"change:studentConsoleLatencyAverage" : this.onStudentConsoleLatencyAverageChanged,
				"change:studentConsoleLatencyLow"     : this.onStudentConsoleLatencyLowChanged,
				"change:studentConsoleLatencyHigh"    : this.onStudentConsoleLatencyHighChanged,
				"change:studentConsoleLatencyWarning" : this.onStudentConsoleLatencyWarningChanged,
				"change:studentConsoleLatencyUnstable": this.onStudentConsoleLatencyUnstableChanged,
				"change:studentConsoleConnectionLost" : this.onStudentConsoleConnectionLostChanged
			});
		},

		resize : function(){
			if(!this.$vmConsole || this.$vmConsole.width() <= 0 || this.$vmConsole.height() <= 0){
				return;
			}

			this.setWmksOption("fitToParent", true);
			if(this.model.get("userResizeRequested") || this.model.get("automaticResize")) {
				this.setWmksOption("fitGuest", false);
			}
			this.setWmksOption("updateFitGuestSize",true);

			if(!this.state.resizing && this.state.position === "center"){
				var consoleResolution = this.getConsoleResolution();
				if(consoleResolution && consoleResolution.scaledWidth > 0 && consoleResolution.scaledHeight > 0){
					this.$vmConsole.width(consoleResolution.scaledWidth);
					this.$vmConsole.height(consoleResolution.scaledHeight);
					this.setWmksOption("fitToParent", true);
				}
			}

			this.model.set("resolutionChangeRequested", true);
			clearTimeout(this.resetResizeRequestTimeout);
			this.resetResizeRequestTimeout = setTimeout(this.resetResizeRequest, 5000);
		},
		afterRender : function(){
			this.refreshChangedValues();
		},
		stopVM : function(){
			this.$(".student-connection-strength,.student-latency-current,.student-latency-average,.student-latency-high,.student-latency-low")
				.removeClass("level-1 level-2 level-3 level-4 level-5 level-6 unstable");
			App.mainView.removeGeneralMessage("student_latency_warning");
			App.mainView.removeGeneralMessage("student_latency_unstable");
			App.mainView.removeGeneralMessage("student_connection_lost");
			App.mainView.removeGeneralMessage("student_phase_changed");
			BaseVmView.prototype.stopVM.apply(this, arguments);
		},
		displayState : function(state){
			BaseVmView.prototype.displayState.apply(this, arguments);
			if(state === this.POWERED_ON){
				this.refreshChangedValues();
			}
		},
		refreshChangedValues : function(){

			this.model.triggerChange(
				"phase",
				"studentConsoleLatencyCurrent", "studentConsoleLatencyAverage", "studentConsoleLatencyLow",
				"studentConsoleLatencyHigh", "studentConsoleLatencyWarning", "studentConsoleLatencyUnstable",
				"studentConsoleConnectionLost"
			);
			this.onStudentConsoleLatencyCurrentChanged( this.entitlement, this.entitlement.get("studentConsoleLatencyCurrent"));
			this.onStudentConsoleLatencyAverageChanged( this.entitlement, this.entitlement.get("studentConsoleLatencyAverage"));
			this.onStudentConsoleLatencyLowChanged(     this.entitlement, this.entitlement.get("studentConsoleLatencyLow"));
			this.onStudentConsoleLatencyHighChanged(    this.entitlement, this.entitlement.get("studentConsoleLatencyHigh"));
			this.onStudentConsoleLatencyWarningChanged( this.entitlement, this.entitlement.get("studentConsoleLatencyWarning"));
			this.onStudentConsoleLatencyUnstableChanged(this.entitlement, this.entitlement.get("studentConsoleLatencyUnstable"));
			this.onStudentConsoleConnectionLostChanged( this.entitlement, this.entitlement.get("studentConsoleConnectionLost"));
		},
		onScreenStatusChanged : function(){
			this.$(".power-on,.powered-down,.power-down,.power-reset,.vm-power-down,.vm-power-reset").prop("disabled", !this.entitlement.get("takenOver"));
			this.$(".control-alt-del,#send_text,.quick-send-text,.show-send-text-modal").prop("disabled", !this.entitlement.get("takenOver"));

			this.wmksSetupEvents();
		},
		wmksSetupEvents : function() {
			if (this.$vmConsole && this.$vmConsole.data("wmks-wmks")) {
				this.$vmConsole.wmks("disconnectEvents");
				if (this.entitlement.get("takenOver")) {
					this.$vmConsole.wmks("connectEvents");
				}
			}
		},
		onBroadcastingDisplayChanged : function(){
			//overridden does nothing
		},
		sendControlAltDelete : function(){
			if (this.entitlement.get("takenOver")) {
				BaseVmView.prototype.sendControlAltDelete.apply(this, arguments);
			}
		},
		sendShortcutKeys : function(){
			if (this.entitlement.get("takenOver")) {
				BaseVmView.prototype.sendShortcutKeys.apply(this, arguments);
			}
		},
		powerOff : function(){
			if (this.entitlement.get("takenOver")) {
				BaseVmView.prototype.powerOff.apply(this, arguments);
			}
		},
		powerOn : function(){
			if (this.entitlement.get("takenOver")) {
				BaseVmView.prototype.powerOn.apply(this, arguments);
			}
		},
		powerReset : function(){
			if (this.entitlement.get("takenOver")) {
				BaseVmView.prototype.powerReset.apply(this, arguments);
			}
		},
		sendText : function(){
			if (this.entitlement.get("takenOver")) {
				BaseVmView.prototype.sendText.apply(this, arguments);
			}
		},
		onQuickSendText : function(){
			if (this.entitlement.get("takenOver")) {
				BaseVmView.prototype.onQuickSendText.apply(this, arguments);
			}
		},
		onDragEnter : function(){
			if (this.entitlement.get("takenOver")) {
				BaseVmView.prototype.onDragEnter.apply(this, arguments);
			}
		},
		onDragLeave : function(){
			if (this.entitlement.get("takenOver")) {
				BaseVmView.prototype.onDragLeave.apply(this, arguments);
			}
		},
		onDragOver : function(){
			if (this.entitlement.get("takenOver")) {
				BaseVmView.prototype.onDragOver.apply(this, arguments);
			}
		},
		onDropText : function(){
			if (this.entitlement.get("takenOver")) {
				BaseVmView.prototype.onDropText.apply(this, arguments);
			}
		},
		onPhaseChanged: function (model, value) {
			if (value !== "running" && value !== "notStarted") {
				var key = "instructor.entitlement.phaseChanged." + value;
				var text = App.i18n(key, _.escape(this.entitlement.get("displayName")));
				App.mainView.generalMessage({
					id         : "student_phase_changed",
					message    : text,
					type       : "info",
					username   : this.entitlement.get("username"),
					autoRemove : true
				});
			} else {
				App.mainView.removeGeneralMessage("student_phase_changed");
			}
		},
		onStudentConsoleLatencyCurrentChanged: function (model, value) {
			this.$(".student-connection-strength,.student-latency-current").removeClass("level-1 level-2 level-3 level-4 level-5 level-6");
			this.$(".student-connection-strength").addClass(this.entitlement.getLatencyLevel(value));
			this.$(".student-latency-current")
				.addClass(this.entitlement.getLatencyLevel(value))
				.text(this.entitlement.getLatencyDisplayValue(value));
		},
		onStudentConsoleLatencyAverageChanged: function (model, value) {
			this.$(".student-latency-average")
				.removeClass("level-1 level-2 level-3 level-4 level-5 level-6")
				.addClass(this.entitlement.getLatencyLevel(value))
				.text(this.entitlement.getLatencyDisplayValue(value));
		},
		onStudentConsoleLatencyLowChanged: function (model, value) {
			this.$(".student-latency-low")
				.removeClass("level-1 level-2 level-3 level-4 level-5 level-6")
				.addClass(this.entitlement.getLatencyLevel(value))
				.text(this.entitlement.getLatencyDisplayValue(value));
		},
		onStudentConsoleLatencyHighChanged: function (model, value) {
			this.$(".student-latency-high")
				.removeClass("level-1 level-2 level-3 level-4 level-5 level-6")
				.addClass(this.entitlement.getLatencyLevel(value))
				.text(this.entitlement.getLatencyDisplayValue(value));
		},
		onStudentConsoleLatencyWarningChanged: function (model, value) {
			if(value && !this.entitlement.get("studentConsoleConnectionLost") && !this.entitlement.get("studentConsoleLatencyUnstable")){
				App.mainView.generalMessage({
					id         : "student_latency_warning",
					message    : App.i18n("instructor.entitlement.studentConnection.warning"),
					type       : "warning",
					username   : this.entitlement.get("username")
				});
			} else {
				App.mainView.removeGeneralMessage("student_latency_warning");
			}
		},
		onStudentConsoleLatencyUnstableChanged: function (model, value) {
			if(value){
				this.$(".student-latency-current").text("--");
			}
			this.$(".student-latency-current").toggleClass("unstable", value);
			this.$(".student-latency-unstable").toggle(value);
			this.$(".student-connection-strength").toggleClass("unstable", value);

			if (value && !this.entitlement.get("studentConsoleConnectionLost")) {
				App.mainView.removeGeneralMessage("student_latency_warning");
				App.mainView.generalMessage({
					id         : "student_latency_unstable",
					message    : App.i18n("instructor.entitlement.studentConnection.unstable"),
					type       : "warning",
					username   : this.entitlement.get("username")
				});
			} else {
				App.mainView.removeGeneralMessage("student_latency_unstable");
			}
		},
		onStudentConsoleConnectionLostChanged: function (model, value) {
			if (value) {
				App.mainView.removeGeneralMessage("student_latency_warning");
				App.mainView.removeGeneralMessage("student_latency_unstable");
				App.mainView.generalMessage({
					id         : "student_connection_lost",
					message    : App.i18n("instructor.entitlement.studentConnection.connectionLost"),
					type       : "warning",
					username   : this.entitlement.get("username")
				});
			} else {
				App.mainView.removeGeneralMessage("student_connection_lost");
			}
			if(value){
				this.$(".student-latency-current").text("--");
			}
			this.$(".student-latency-current").toggleClass("lost", value);
			this.$(".student-connection-lost").toggle(value);
			this.$(".student-connection-strength").toggleClass("lost", value);
		}
	});

});

