
define('hbs!tpls/catalogs/enrollment-unenroll.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.state : depth0),"started",{"name":"eq","hash":{},"data":data}),(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.state : depth0),"active",{"name":"eq","hash":{},"data":data}),{"name":"or","hash":{},"fn":this.program(2, data, 0),"inverse":this.program(27, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.completionStatusForLabCriteria : depth0),"completed",{"name":"ne","hash":{},"fn":this.program(3, data, 0),"inverse":this.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "		\n		\n		<div id=\"modal_unenroll_"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"modal fade global fluid-height hide unenroll-modal\">\n			<div class=\"modal-dialog\">\n				<div class=\"modal-content\">\n					<div class=\"modal-header\">\n						<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n						<h3 class=\"modal-title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.unenroll.endTitle",(depth0 != null ? depth0.name : depth0),{"name":"$","hash":{},"data":data}))
    + "</h3>\n					</div>\n					<div class=\"modal-body\">\n						<p>\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.unenroll.endMessage",(depth0 != null ? depth0.name : depth0),{"name":"$","hash":{},"data":data}))
    + "\n						</p>\n						<dl class=\"dl-horizontal\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.requiresGrading : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"expired",{"name":"ne","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.className : depth0),{"name":"if","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.progress : depth0),undefined,{"name":"ne","hash":{},"fn":this.program(14, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.timeElapsed : depth0),undefined,{"name":"ne","hash":{},"fn":this.program(16, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.timeAllotted : depth0),{"name":"if","hash":{},"fn":this.program(18, data, 0),"inverse":this.program(20, data, 0),"data":data})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.validPeriod : depth0),{"name":"if","hash":{},"fn":this.program(23, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "						</dl>\n"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiredMinimumTimeSpent : depth0),(depth0 != null ? depth0.requiredStepsVisitedPercentage : depth0),{"name":"or","hash":{},"fn":this.program(25, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "						<button type=\"button\" class=\"btn cancel\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</button>\n						<button type=\"button\" class=\"btn btn-primary unenroll-lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","catalogs.labs.unenroll.ending",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.unenroll.end",{"name":"$","hash":{},"data":data}))
    + "</button>\n						<div class=\"loading unenroll-spinner hide\"></div>\n					</div>\n				</div>\n			</div>\n		</div>\n";
},"3":function(depth0,helpers,partials,data) {
    var helper;

  return "			<div \n				class=\"unenroll-popover-trigger\" \n				rel=\"popover\" \n				data-trigger=\"hover\" \n				data-placement=\"left\" \n				data-html=\"true\" \n				data-content-target=\"#popover_content_completion_criteria_"
    + this.escapeExpression(((helper = (helper = helpers.entitlementKey || (depth0 != null ? depth0.entitlementKey : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"entitlementKey","hash":{},"data":data}) : helper)))
    + "\" \n				"
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","completionCriteria.notMetTitle",{"name":"$$","hash":{},"data":data}))
    + "\n				data-class=\"lab-completion-criteria-warning "
    + this.escapeExpression(((helper = (helper = helpers.entitlementKey || (depth0 != null ? depth0.entitlementKey : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"entitlementKey","hash":{},"data":data}) : helper)))
    + "\"\n				>\n				<button type=\"button\" class=\"btn unenroll-prompt unenroll-end\" data-toggle=\"modal\" data-target=\"#modal_unenroll_"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.unenroll.end",{"name":"$","hash":{},"data":data}))
    + "\n				</button>\n			</div>\n			<div class=\"hide\">\n				<div id=\"popover_content_completion_criteria_"
    + this.escapeExpression(((helper = (helper = helpers.entitlementKey || (depth0 != null ? depth0.entitlementKey : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"entitlementKey","hash":{},"data":data}) : helper)))
    + "\" class=\"lab-completion-criteria-warning "
    + this.escapeExpression(((helper = (helper = helpers.entitlementKey || (depth0 != null ? depth0.entitlementKey : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"entitlementKey","hash":{},"data":data}) : helper)))
    + "\">\n					<div class=\"warning-message\"></div>\n					<dl class=\"dl-horizontal dl-wide\">\n						<div class=\"time-elapsed\">\n							<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.timeElapsedLabel",{"name":"$","hash":{},"data":data}))
    + "</dt>\n							<dd class=\"time-elapsed-value\"></dd>\n						</div>\n						<div class=\"manual-steps-read\">\n							<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.manualStepsReadLabel",{"name":"$","hash":{},"data":data}))
    + "</dt>\n							<dd class=\"manual-steps-read-value\"></dd>\n						</div>\n					</dl>\n				</div>\n			</div>\n";
},"5":function(depth0,helpers,partials,data) {
    var helper;

  return "			<button type=\"button\" class=\"btn unenroll-prompt unenroll-end\" data-toggle=\"modal\" data-target=\"#modal_unenroll_"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.unenroll.end",{"name":"$","hash":{},"data":data}))
    + "</button>\n";
},"7":function(depth0,helpers,partials,data) {
    return "								<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.requiresGrading",{"name":"$","hash":{},"data":data}))
    + "</dt>\n								<dd class=\"skin-text-popout\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"yes",{"name":"$","hash":{},"data":data}))
    + "</dd>\n";
},"9":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.expirationDate : depth0),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"10":function(depth0,helpers,partials,data) {
    var helper;

  return "									<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.expiration",{"name":"$","hash":{},"data":data}))
    + "</dt>\n									<dd class=\"skin-text-popout\">\n										<span class=\"\">"
    + this.escapeExpression((helpers.date_format || (depth0 && depth0.date_format) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.expirationDate : depth0),"dateFormatFull",{"name":"date_format","hash":{},"data":data}))
    + "</span>\n										<span class=\"expiration-from-now\">(<span class=\"expiration-time-label\">"
    + this.escapeExpression(((helper = (helper = helpers.expirationTimeLabel || (depth0 != null ? depth0.expirationTimeLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"expirationTimeLabel","hash":{},"data":data}) : helper)))
    + "</span> "
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.expirationFromNow",{"name":"$","hash":{},"data":data}))
    + ")</span>\n\n									</dd>\n";
},"12":function(depth0,helpers,partials,data) {
    var helper;

  return "								<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.className",{"name":"$","hash":{},"data":data}))
    + "</dt>\n								<dd class=\"skin-text-popout\">"
    + this.escapeExpression(((helper = (helper = helpers.className || (depth0 != null ? depth0.className : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"className","hash":{},"data":data}) : helper)))
    + "</dd>\n";
},"14":function(depth0,helpers,partials,data) {
    return "								<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.progress",{"name":"$","hash":{},"data":data}))
    + "</dt>\n								<dd class=\"skin-text-popout\">"
    + this.escapeExpression((helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.progress : depth0),{"name":"percent","hash":{},"data":data}))
    + "</dd>\n";
},"16":function(depth0,helpers,partials,data) {
    return "								<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.timeElapsed",{"name":"$","hash":{},"data":data}))
    + "</dt>\n								<dd class=\"skin-text-popout\">\n									"
    + this.escapeExpression((helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.timeElapsed : depth0),{"name":"daysHoursMinutes","hash":{},"data":data}))
    + "\n								</dd>\n";
},"18":function(depth0,helpers,partials,data) {
    return "								<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.timeAllotted",{"name":"$","hash":{},"data":data}))
    + "</dt>\n								<dd class=\"skin-text-popout\">\n									"
    + this.escapeExpression((helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.timeAllotted : depth0),{"name":"daysHoursMinutes","hash":{"roundToDays":true},"data":data}))
    + "\n								</dd>\n";
},"20":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.timelimit : depth0),{"name":"if","hash":{},"fn":this.program(21, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"21":function(depth0,helpers,partials,data) {
    return "									<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.timeLimit",{"name":"$","hash":{},"data":data}))
    + "</dt>\n									<dd class=\"skin-text-popout\">\n										"
    + this.escapeExpression((helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.timelimit : depth0),{"name":"daysHoursMinutes","hash":{"roundToDays":true},"data":data}))
    + "\n									</dd>\n";
},"23":function(depth0,helpers,partials,data) {
    return "								<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.validPeriod",{"name":"$","hash":{},"data":data}))
    + "</dt>\n								<dd class=\"skin-text-popout\">\n									"
    + this.escapeExpression((helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.validPeriod : depth0),{"name":"daysHoursMinutes","hash":{"milliseconds":true,"roundToDays":true},"data":data}))
    + "\n								</dd>\n";
},"25":function(depth0,helpers,partials,data) {
    var helper;

  return "							<div class=\"lab-completion-criteria-warning "
    + this.escapeExpression(((helper = (helper = helpers.entitlementKey || (depth0 != null ? depth0.entitlementKey : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"entitlementKey","hash":{},"data":data}) : helper)))
    + " alert alert-warning alert-icon\">\n								<div class=\"warning-message\"></div>\n								<dl class=\"dl-horizontal dl-wide\">\n									<div class=\"time-elapsed\">\n										<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.timeElapsedLabel",{"name":"$","hash":{},"data":data}))
    + "</dt>\n										<dd class=\"time-elapsed-value\"></dd>\n									</div>\n									<div class=\"manual-steps-read\">\n										<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.manualStepsReadLabel",{"name":"$","hash":{},"data":data}))
    + "</dt>\n										<dd class=\"manual-steps-read-value\"></dd>\n									</div>\n								</dl>\n							</div>\n";
},"27":function(depth0,helpers,partials,data) {
    var helper;

  return "		<button type=\"button\" class=\"btn unenroll-prompt\" data-toggle=\"modal\" data-target=\"#modal_unenroll_"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.unenroll.unenroll",{"name":"$","hash":{},"data":data}))
    + "</button>\n\n		<div id=\"modal_unenroll_"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"modal fade global fluid-height hide unenroll-modal\">\n			<div class=\"modal-dialog\">\n				<div class=\"modal-content\">\n					<div class=\"modal-header\">\n						<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n						<h3 class=\"modal-title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.unenroll.unenrollTitle",(depth0 != null ? depth0.name : depth0),{"name":"$","hash":{},"data":data}))
    + "</h3>\n					</div>\n					<div class=\"modal-body\">\n						<p>\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.unenroll.unenrollMessage",(depth0 != null ? depth0.name : depth0),{"name":"$","hash":{},"data":data}))
    + "\n						</p>\n						<button type=\"button\" class=\"btn cancel\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</button>\n						<button type=\"button\" class=\"btn btn-primary unenroll-lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","catalogs.labs.unenroll.unenrolling",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.unenroll.unenroll",{"name":"$","hash":{},"data":data}))
    + "</button>\n						<div class=\"loading unenroll-spinner hide\"></div>\n					</div>\n				</div>\n			</div>\n		</div>\n\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.and || (depth0 && depth0.and) || helpers.helperMissing).call(depth0,(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labUnenrollAllowed",{"name":"config","hash":{},"data":data}),(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.endAllowed : depth0),true,{"name":"eq","hash":{},"data":data}),{"name":"and","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
Handlebars.registerPartial('catalogs_enrollment_unenroll', t);
return t;
});

