define('nls/root/speed-test',{
	"speedTest" : {
		"title"               : "Network Connection",
		"startTest"           : "Start Test",
		"networkLatencyLabel" : "Network Latency",
		"runTest"             : "...",
		"description"         : "This speed test will help us evaluate any potential performance issues you are having with VMware Lab Platform that may be related to network bottlenecks. The test will be run twice, connecting to a different server each time. It first tests latency (network delay) and then speed (network bandwidth).",
		"disclaimer"          : "Disclaimer: After running this test, VMware will collect certain information about your browser and network connection. By running this test, you authorize VMware to collect this information.",
		"recommended" : {
			"title" : "Recommended Scores",
			"latency"   : "Latency - A good latency score would be less than 300 ms; the lower the better",
			"bandwidth" : "Speed - A good speed score would be greater than 1.5Mb/s; the higher the better"
		},
		"tests"               : {
			"cloud" : {
				"latencyLabel"         : "Cloud Latency",
				"latencyTestStart"     : "Cloud Latency...",
				"latencyTestComplete"  : "Cloud Latency...Done",
				"downloadLabel"        : "Cloud Speed",
				"downloadTestStart"    : "Cloud Bandwidth...",
				"downloadTestComplete" : "Cloud Bandwidth...Done"
			},
			"ui" : {
				"latencyLabel"         : "VLP Latency",
				"latencyTestStart"     : "VLP Latency...",
				"latencyTestComplete"  : "VLP Latency...Done",
				"downloadLabel"        : "Estimated Speed",
				"downloadTestStart"    : "Estimated Speed...",
				"downloadTestComplete" : "Estimated Speed...Done"
			}
		},
		"errors" : {
			"timedOut"          : "Timed Out",
			"cloudsUnavailable" : "No Clouds",
			"latency" : {
				"timedOut" : "Connection timed-out connecting to \"%s\" server",
				"cloudsUnavailable" : "There are no active clouds for this tenant."
			}
		}
	},
	"errors"    : {
	},
	"title"     : "System Bandwidth Test"
});

