/**
 * Dashboard Backbone View
 */
define('views/dashboard/main',['require','jquery','underscore','library/vlp/app','views/vlp-base','models/dashboard-content','hbs!tpls/dashboard/main.handlebars','hbs!tpls/dashboard/promo-content.handlebars','hbs!tpls/dashboard/default-content.handlebars','i18n!nls/dashboard','widgets/widget'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App               = require("library/vlp/app"),
	    VlpBaseView       = require("views/vlp-base"),
	    DashboardContent  = require("models/dashboard-content"),
	    mainTPL           = require("hbs!tpls/dashboard/main.handlebars"),
	    promoContentTPL   = require("hbs!tpls/dashboard/promo-content.handlebars"),
	    defaultContentTPL = require("hbs!tpls/dashboard/default-content.handlebars"),
	    pageTexts         = require("i18n!nls/dashboard");
	
	require("widgets/widget");


	return VlpBaseView.extend({

		className : "general-page",

		checkLoggedIn         : false,
		anonymousPush         : true,
		checkMaintenance      : true,
		checkBrowserSupported : false,
		checkTenantPath       : false,
		checkSystemMessages   : false,
		periodicRefresh       : false,

		//Required
		mainTemplate : mainTPL,
		events: {
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize: function(options) {

			var self = this;

			_.bindAll(this);

			this.dashboardContent = new DashboardContent.Collection();

			this.widgets = [];

			this.once(VlpBaseView.STARTUP_COMPLETE, function(){
				this.render();


				this.dashboardContent.fetch({
					success : this.setupPromoContent,
					error   : this.setupPromoContent
				});

			}, this);

			App.locale.mergeI18n(pageTexts);
			//Checks logged in
			this.setup(options);
			$(window).off("message.dashboard").on("message.dashboard", function(event){
				if(event.originalEvent.origin === window.location.protocol + "//" + window.location.host){
					//ignore self
					return;
				}
				try{
					var data = JSON.parse(event.originalEvent.data);
					if(data.operation && data.operation === "subscribe"){
						self.addRemoteWidget(data, event.originalEvent.source);
					}
				} catch(e){}
			});

		},
		/**
		 * Display the content.
		 */
		render: function() {

			this.widgets = [];

			this.setupWidgets();


			//Always return this for chaining
			return this;
		},
		setupWidgets : function(){
			var self = this;
			this.$("widget").each(function(){
				if(!$(this).hasClass("initialized")){
					var $widgetEl = $(this);
					$widgetEl.html("<div class=\"loading\">" + App.i18n("loading") + "</div>");
					require(["widgets/" + $(this).attr("type")], function(Widget){
						var newWidget = new Widget({
							el   : $widgetEl,
							info : $widgetEl.data()
						});
						$widgetEl.addClass("initialized");
						self.widgets.push(newWidget);
					});
				}
			});
		},
		addRemoteWidget : function(data, target){
			var self = this;
			data.target = target;
			require(["widgets/remote"], function(Widget){
				var newWidget = new Widget({
					info : data
				});
				self.widgets.push(newWidget);
			});
		},
		setupPromoContent : function(){
			this.$("#slideshow .item.promo").remove();
			var useDefault = (this.dashboardContent.length === 0);
			if(useDefault){
				this.$("#slideshow .carousel-inner").append(defaultContentTPL());
			} else{
				this.$("#slideshow .carousel-inner").append(promoContentTPL(this.dashboardContent));
			}

			if(this.dashboardContent.length > 1 || useDefault){

				this.$("#slideshow .carousel-inner .item:first").addClass("active");
				$(".carousel").carousel({
					interval : App.config.dashboardCycleInterval,
					pause    : "hover"
				});
			} else{
				this.$("#slideshow .carousel-inner .item.promo").first().show();
			}
			if(this.dashboardContent.length === 1 && this.dashboardContent.at(0).get("isURL")){
				$("body").addClass("full-screen");
			} else{
				$("body").removeClass("full-screen");
			}
			this.setupWidgets();
		}
	});
});

