/**
 * Internationalization lookup Handlebars helper functions.
 */
/*globals global,window*/
define('library/utilities/i18n-get',['require','underscore'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	var root = window || global;

	return function(key){
		var i18n = (root.App && root.App.locale && root.App.locale.i18nStrings) || root.i18nStrings;
		var optionsHash;

		var args = _.toArray(arguments).slice(1);
		if(args.length > 0 && args[args.length - 1].hash){
			optionsHash = args.pop().hash;
		}

		var keys = key.split(".");

		var notFound = false;
		var result = i18n;

		for(var i = 0; i < keys.length; i++){
			var k = keys[i];
			if(result.hasOwnProperty(k)){
				result = result[k];
			} else{
				//console.warn("i18n string not found:", key);
				result = key;
				notFound = true;
				break;
			}
		}

		if(root.App && root.App.i18nTest){
			return "i18nTest";
		}

		if(!notFound){
			if(args.length){
				args.unshift(result);
				try{
					result = _.sprintf.apply(_, args);
				} catch(e){
					console.error("_.sprintf failed with params:", args, e);
				}

			} else if(!_.isEmpty(optionsHash)){

				try{
					result = _.sprintf(result, optionsHash);
				} catch(e){
					console.error("_.sprintf failed with params:", result, optionsHash, e);
				}

			}
		}
		return result + "";
	};

});
