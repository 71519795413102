
define('hbs!tpls/catalogs/entitlement-life-cycle.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "	<div class=\"spinner indented-spinner\"></div>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "	<dl class=\"dl-horizontal\">\n		<dt>Total Time:</dt>\n		<dd>"
    + this.escapeExpression((helpers.hoursMinutesSecondsFromMs || (depth0 && depth0.hoursMinutesSecondsFromMs) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.totalTime : depth0),{"name":"hoursMinutesSecondsFromMs","hash":{},"data":data}))
    + "</dd>\n		<dt>Enroll Time:</dt>\n		<dd>"
    + this.escapeExpression((helpers.date_format || (depth0 && depth0.date_format) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.enrollTime : depth0),"dateFormatFull",{"name":"date_format","hash":{},"data":data}))
    + "</dd>\n	</dl>\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.activeSessions : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<table>\n			<tr>\n				<th>Start Time</th>\n				<th>Duration</th>\n			</tr>\n\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.activeSessions : depth0),{"name":"each","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</table>\n";
},"5":function(depth0,helpers,partials,data) {
    return "				<tr>\n					<td>"
    + this.escapeExpression((helpers.date_format || (depth0 && depth0.date_format) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.startTime : depth0),"dateFormatFull",{"name":"date_format","hash":{},"data":data}))
    + "</td>\n					<td>"
    + this.escapeExpression((helpers.hoursMinutesSecondsFromMs || (depth0 && depth0.hoursMinutesSecondsFromMs) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.duration : depth0),{"name":"hoursMinutesSecondsFromMs","hash":{},"data":data}))
    + "</td>\n				</tr>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.awaitingResponse : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});
Handlebars.registerPartial('catalogs_entitlement_life_cycle', t);
return t;
});

