define('views/instructor/resources',['require','jquery','underscore','library/vlp/app','library/vlp/view','hbs!tpls/instructor/resources.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App          = require("library/vlp/app"),
	    BaseView     = require("library/vlp/view"),
	    resourcesTPL = require("hbs!tpls/instructor/resources.handlebars");


	return BaseView.extend({
		template : resourcesTPL,
		removeKeepEl    : true,

		throttleRending : 100,


		events : {
			"change .lab-chooser-select": "onLabChanged"
		},
		initialize : function(options){
			_.bindAll(this);
			options = options || {};

			this.labs = options.labs;
			this.panel = options.panel;
			this.class = options.class;

			if(this.class){
				this.listenTo(this.class, "change:activeLabSku", this.onActiveLabSkuChanged);
			}
			if(this.labs && this.class.get("activeLabSku")){
				this.activeLab = this.labs.findWhere({sku: this.class.get("activeLabSku")});
				if(this.activeLab){
					this.collection = this.class.get("resources");

				}
			}

			this.listenTo(this.collection, "change", this.render);
		},
		serialize : function(){
			var data = this.collection.toHandlebars();
			data.labs = this.labs && this.labs.toHandlebars();
			data.activeLabSku = this.class.get("activeLabSku");
			return data;
		},
		afterRender : function(){
			if(this.collection.length === 0 || !App.config.showPanels || !App.config.showResourcesPanel){
				this.panel.hide();
			} else{
				this.panel.show();
			}
			this.$el.toggleClass("has-lab-chooser", this.labs && this.labs.length > 1);
		},
		onLabChanged : function(event){
			var labSku = $(event.currentTarget).val();
			this.class.set("activeLabSku", labSku);
		},
		onActiveLabSkuChanged : function(){
			if(!this.activeLab || this.activeLab.get("sku") !== this.class.get("activeLabSku")){
				var lab = this.labs.findWhere({sku: this.class.get("activeLabSku")});
				if(lab){
					this.changeResources(lab.get("sku"));
				}
			}
		},
		changeResources : function(labSku){
			this.activeLab = this.labs.findWhere({sku: labSku});
			if(this.activeLab){
				this.collection = this.class.get("resources");
				this.render();
			}
		}
	});

});

