
define('hbs!tpls/common/help-center.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "autocomplete=\"on\"";
},"3":function(depth0,helpers,partials,data) {
    return "autocomplete=\"off\" action=\"https://about:blank\"";
},"5":function(depth0,helpers,partials,data) {
    return "\"email\"";
},"7":function(depth0,helpers,partials,data) {
    return "\"off\"";
},"9":function(depth0,helpers,partials,data) {
    return "\"current-password\"";
},"11":function(depth0,helpers,partials,data) {
    return "		<hr />\n		<fieldset class=\"form-horizontal\">\n			<h3>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"helpCenter.helpDeskTicket",{"name":"$","hash":{},"data":data}))
    + "</h3>\n			<div>\n				<input type=\"text\" class=\"input-large summary\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"placeholder","helpCenter.summary",{"name":"$$","hash":{},"data":data}))
    + ">\n			</div>\n\n			<div class=\"error-enter-summary message alert alert-error hide\">\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"helpCenter.errors.enterSummary",{"name":"$","hash":{},"data":data}))
    + "\n			</div>\n			<div>\n				<textarea class=\"input-large detail\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"placeholder","helpCenter.detail",{"name":"$$","hash":{},"data":data}))
    + " rows=\"4\" cols=\"40\"></textarea>\n			</div>\n\n			<button class=\"btn help-desk-ticket\" type=\"button\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","submitting",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"submit",{"name":"$","hash":{},"data":data}))
    + "</button>\n\n			<span class=\"help-desk-ticket-spinner hide spinner save-spinner\"/>\n			<div class=\"alert alert-success hide message help-desk-ticket-success\">\n				<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"helpCenter.sent",{"name":"$","hash":{},"data":data}))
    + "</strong>\n			</div>\n\n			<div class=\"error-help-ticket message alert alert-error hide\"></div>\n		</fieldset>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<form method=\"post\" "
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n	<fieldset class=\"form-inline\">\n		<input type=\"text\" class=\"input-small username\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"placeholder","loginPrompt.username",{"name":"$$","hash":{},"data":data}))
    + " autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n		<input type=\"password\" class=\"input-small password\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"placeholder","loginPrompt.password",{"name":"$$","hash":{},"data":data}))
    + " autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(9, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n	</fieldset>\n	<div class=\"error-enter-username message alert alert-error hide\">\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.errors.enterUsername",{"name":"$","hash":{},"data":data}))
    + "\n	</div>\n	<div class=\"error-enter-password message alert alert-error hide\">\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.errors.enterPassword",{"name":"$","hash":{},"data":data}))
    + "\n	</div>\n\n	<hr />\n	<button class=\"btn request-captain\" type=\"button\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","submitting",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"helpCenter.requestCaptain",{"name":"$","hash":{},"data":data}))
    + "</button>\n	<span class=\"captain-request-spinner hide spinner save-spinner\"/>\n	<div class=\"alert alert-success hide message captain-request-success\">\n		<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"helpCenter.sent",{"name":"$","hash":{},"data":data}))
    + "</strong>\n	</div>\n	<div class=\"error-captain-request message alert alert-error hide\"></div>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.helpDesk : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n</form>";
},"useData":true});
Handlebars.registerPartial('common_help_center', t);
return t;
});

