/**
 * Miscellaneous Handlebars helper functions.
 */
define('library/handlebars/misc-helpers',['require','underscore','handlebars','library/utilities/i18n-get','library/vlp/app','library/utilities/days-hours-minutes-label'],function (require) {
	"use strict";

	//library dependencies
	var _          = require("underscore"),
	    Handlebars = require("handlebars"),
	    i18nGet    = require("library/utilities/i18n-get");

	//class dependencies
	var App      = require("library/vlp/app");

	var daysHoursMinutesLabel = require("library/utilities/days-hours-minutes-label");

	/**
	 * Convert new line (\n\r) to <br>
	 * from http://phpjs.org/functions/nl2br:480
	 */
	Handlebars.registerHelper("nl2br", function(text){
		var nl2br = (text + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1<br>$2");
		return new Handlebars.SafeString(nl2br);
	});

	var returnResult = function(value, options, context){
		if(options.fn){
			if(value){
				return options.fn(context);
			}
			return options.inverse(context);
		} else {
			return value;
		}
	};
	/**
	 * If equals
	 */
	Handlebars.registerHelper("eq", function(a, b, options){
		return returnResult(a == b, options, this);
	});

	/**
	 * If not equals
	 */
	Handlebars.registerHelper("ne", function(a, b, options){
		return returnResult(a != b, options, this);
	});

	/**
	 * If greater than
	 */
	Handlebars.registerHelper("gt", function(a, b, options){
		return returnResult(a > b, options, this);
	});

	/**
	 * If less than
	 */
	Handlebars.registerHelper("lt", function(a, b, options){
		return returnResult(a < b, options, this);
	});

	/**
	 * If greater than or equal
	 */
	Handlebars.registerHelper("gte", function(a, b, options){
		return returnResult(a >= b, options, this);
	});

	/**
	 * If less than or equal
	 */
	Handlebars.registerHelper("lte", function(a, b, options){
		return returnResult(a <= b, options, this);
	});

	/**
	 * Or
	 */
	Handlebars.registerHelper("or", function(a, b){
		var result = false;
		var options = arguments[arguments.length - 1];

		for(var i = 0; i < arguments.length - 1; i++){
			var value = arguments[i];
			if(_.isFunction(value)){
				value = value();
			}
			if(value) {
				result = true;
				break;
			}
		}

		return returnResult(result, options, this);
	});

	/**
	 * And
	 */
	Handlebars.registerHelper("and", function(a, b){
		var result = true;
		var options = arguments[arguments.length - 1];

		for(var i = 0; i < arguments.length - 1; i++){
			var value = arguments[i];
			if(_.isFunction(value)){
				value = value();
			}
			if(!value) {
				result = false;
				break;
			}
		}

		return returnResult(result, options, this);
	});
	/**
	 * Get config value
	 */
	Handlebars.registerHelper("config", function(value, options){
		return returnResult(App.config[value], options, this);
	});

	/**
	 * Format time in Hours:Minutes
	 */
	Handlebars.registerHelper("hoursMinutes", function(time, inMinutes){
		if(inMinutes){
			time = time * 60;
		}
		var remainingHours = Math.floor(time / 3600);
		var remainingMinutes = Math.floor((time - (remainingHours * 3600)) / 60);
		return _.lpad(remainingHours, 2, "0") + ":" + _.lpad(remainingMinutes, 2, "0");
	});

	/**
	 * Format time in Hours:Minutes:Seconds
	 */
	Handlebars.registerHelper("hoursMinutesSeconds", function(time){
		var remainingHours = Math.floor(time / 3600);
		var remainingMinutes = Math.floor((time - (remainingHours * 3600)) / 60);
		var remainingSeconds = Math.floor((time - (remainingHours * 3600) - (remainingMinutes * 60)));
		return _.lpad(remainingHours, 2, "0") + ":" + _.lpad(remainingMinutes, 2, "0") + ":" + _.lpad(remainingSeconds, 2, "0");
	});

	/**
	 * Format time in Hours:Minutes:Seconds from millisecond
	 */
	Handlebars.registerHelper("hoursMinutesSecondsFromMs", function(time){
		time = time/1000;
		var remainingHours = Math.floor(time / 3600);
		var remainingMinutes = Math.floor((time - (remainingHours * 3600)) / 60);
		var remainingSeconds = Math.floor((time - (remainingHours * 3600) - (remainingMinutes * 60)));
		return _.lpad(remainingHours, 2, "0") + ":" + _.lpad(remainingMinutes, 2, "0") + ":" + _.lpad(remainingSeconds, 2, "0");
	});

	/**
	 * Format time in Hours:Minutes:Seconds from millisecond
	 */
	Handlebars.registerHelper("daysHoursMinutes", function(time){
		var options = (arguments.length > 0 ? arguments[arguments.length - 1].hash : null);
		return daysHoursMinutesLabel(time, options);
	});

	/**
	 * I18n function
	 * Must register the root strings object to root.App.i18n or root.i18n
	 */
	Handlebars.registerHelper("$", function(key){
		var text = i18nGet.apply(this, arguments);
		var hash = (arguments.length > 0 ? arguments[arguments.length - 1].hash : null);
		var quote = "\"";

		if(hash && hash.singleQuote){
			quote = "\'";
			delete hash.singleQuote;
		}
		var result = "<i18n data-key=" + quote + key + quote;

		if(arguments.length > 2 || (hash && !_.isEmpty(hash))) {
			var args = Array.prototype.slice.call(arguments);
			args.shift();
			if(_.isEmpty(hash)){
				args.pop();
			} else{
				delete args[args.length - 1].data;
			}
			result += " data-args="+ quote + JSON.stringify(args).replace(/'/g, "\\'").replace(/"/g, "&quot;") + quote;
		}

		result+= ">" + text + "</i18n>";
		return new Handlebars.SafeString(result);
	});

	/**
	 * I18n for properites function
	 * Must register the root strings object to root.App.i18n or root.i18n
	 */
	Handlebars.registerHelper("$$", function(property, key){
		var args = Array.prototype.slice.call(arguments, 0);
		args.shift();
		var text = i18nGet.apply(this, args);

		var hash = (args.length > 0 ? args[args.length - 1].hash : null);
		if(_.isEmpty(hash)){
			args.pop();
		} else{
			delete args[args.length - 1].data;
		}

		args.unshift(property);

		return new Handlebars.SafeString(property + "=\"" + text.replace(/'/g, "&#39;").replace(/"/g, "&#34;") + "\" data-i18n data-i18n-" + property + "=\'" + JSON.stringify(args).replace(/'/g, "&#39;").replace(/"/g, "&#34;") + "\'");

	});


	/**
	 * I18n function
	 * Must register the root strings object to root.App.i18n or root.i18n
	 */
	Handlebars.registerHelper("l10n", function(key){
		return i18nGet.apply(this, arguments);
	});

	Handlebars.registerHelper("numberFormat", function(value, decimals, decimalSeparator, thousandsSeparator){
		if(arguments.length < 3){
			decimals = 2;
		}
		if(arguments.length < 4){
			decimalSeparator = App.i18n("decimalSeparator");
		}
		if(arguments.length < 5){
			thousandsSeparator = App.i18n("thousandsSeparator");
		}
		return new Handlebars.SafeString(_.numberFormat(value, decimals, decimalSeparator, thousandsSeparator));
	});

	/**
	 * Pluralize if value is not 1
	 */
	Handlebars.registerHelper("pluralize", function(singular, plural, value){
		if(value != 1){
			return plural;
		}
		return singular;
	});

	/**
	 * Default value
	 */
	Handlebars.registerHelper("defaultValue", function(value, defaultValue){
		if(value){
			return value;
		}
		return defaultValue;
	});

	/**
	 * Repeats a block n times
	 */
	Handlebars.registerHelper("repeat", function(n, block) {
		var accum = "";
		for(var i = 0; i < n; ++i){
			accum += block.fn(i);
		}
		return accum;
	});

	/**
	 * Repeats a block n times
	 */
	Handlebars.registerHelper("percent", function(value, decimals) {
		if(!decimals){ decimals = 0; }
		if(!_.isNumber(value)){
			value = _.toNumber(value);
		}
		var result = _.numberFormat(value, decimals, App.i18n("decimalSeparator"), App.i18n("thousandsSeparator"));
		return App.i18n("percent", result);
	});
});

