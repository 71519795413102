
define('hbs!tpls/verify-account/main.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "\"one-time-code\"";
},"3":function(depth0,helpers,partials,data) {
    return "\"off\"";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"page-content hide\">\n	<div id=\"checking_token\">\n		<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"verifyAccount.checkingToken",{"name":"$","hash":{},"data":data}))
    + "...</h2>\n	</div>\n	<div id=\"invalid_token\" class=\"hide\">\n		<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"verifyAccount.invalidToken",{"name":"$","hash":{},"data":data}))
    + "</h2>\n		<div class=\"response\"></div>\n		<button class=\"btn btn-large btn-primary new-token\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","verifyAccount.sendingToken",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"verifyAccount.getNewToken",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-large enter-token\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"verifyAccount.enterToken.button",{"name":"$","hash":{},"data":data}))
    + "</button>\n	</div>\n\n	<div id=\"new_token\" class=\"hide\">\n		<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"verifyAccount.newTokenSent",{"name":"$","hash":{},"data":data}))
    + "</h2>\n		<p>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"verifyAccount.newTokenSentMessage",{"name":"$","hash":{},"data":data}))
    + "\n		</p>\n	</div>\n	<div id=\"enter_token\" class=\"hide\">\n		<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"verifyAccount.enterToken.title",{"name":"$","hash":{},"data":data}))
    + "</h2>\n		<form id=\"enter_token_form\" method=\"post\" autocomplete=\"off\">\n			<fieldset class=\"form-horizontal\" data-error-style=\"tooltip\">\n\n				<div class=\"control-group\">\n					<label class=\"control-label required\" for=\"manual_token\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"verifyAccount.enterToken.label",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n					<div class=\"controls\">\n						<input type=\"text\" class=\"input-xlarge\" id=\"token\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n					</div>\n				</div>\n				<button class=\"btn btn-large btn-primary\" type=\"submit\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"submit",{"name":"$","hash":{},"data":data}))
    + "</button>\n			</fieldset>\n		</form>\n	</div>\n	<div id=\"security_prompts_holder\" class=\"hide\"></div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('verify_account_main', t);
return t;
});

