/**
 * Support Options Backbone View
 */
define('views/common/support-options',['require','jquery','underscore','backbone','library/vlp/app','models/support-option','hbs!tpls/common/support-options.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App           = require("library/vlp/app"),
	    SupportOption = require("models/support-option"),
	    tpl           = require("hbs!tpls/common/support-options.handlebars");

	return Backbone.View.extend({
		template : tpl,

		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		el : "#support_options",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			//"click a.more-info": "showDetails"
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			this.supportOptions = new SupportOption.Collection();
		},
		/**
		 * Display the content.
		 */
		render : function(options){
			options = options || {};
			this.supportOptions.fetch({
				data    : options,
				success : this.supportOptionsLoaded
			});
			App.analytics.trackEvent("Support", "Show: Support Options");
			//Always return this for chaining
			return this;
		},

		supportOptionsLoaded : function(){
			this.$el.html(this.template(this.supportOptions));
			$("#modal_support_options").modal("reflow");
		}
	});

});
