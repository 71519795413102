/**
 * Lab Backbone View
 */
define('views/console/main',['require','jquery','underscore','backbone','library/vlp/app','views/vlp-base','views/console/entitlement','models/entitlement','views/console/waiting-room','hbs!tpls/console/main.handlebars','hbs!tpls/console/partial-support-prompt.handlebars','i18n!nls/console','models/instructor-help-request','utilities/browser'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
		_ = require("underscore"),
		Backbone = require("backbone");

	//class dependencies
	var App = require("library/vlp/app"),
		VlpBaseView = require("views/vlp-base"),
		EntitlementView = require("views/console/entitlement"),
		Entitlement = require("models/entitlement"),
		WaitingRoomView = require("views/console/waiting-room"),
		mainTPL = require("hbs!tpls/console/main.handlebars"),
		partialSupportPromptTPL = require("hbs!tpls/console/partial-support-prompt.handlebars"),
		pageTexts = require("i18n!nls/console"),
		InstructorHelpRequest = require("models/instructor-help-request"),
		Browser = require("utilities/browser");

	return VlpBaseView.extend({
		name: "CONSOLE",

		allowHiddenTenant: true,
		checkByodSection: true,
		showWelcomeTips: true,
		periodicRefresh: false,

		//Required
		mainTemplate: mainTPL,
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events: {
			//"#submit"     : "send"
			"click #help_request_instructor": "toggleHelpRequest"
		},

		entitlementId: null,
		consoleId: null,
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize: function (options) {
			_.bindAll(this);

			$("body").addClass("console-common");
			if (App.remoteControl) {
				$("body").addClass("console");
				this.secondScreenPage = true;
				this.checkByodSection = false;

				this.name = App.config.pages.REMOTE_CONTROL;
				this.viewport = "width=device-width,initial-scale=1";
			}

			this.fetchDefaults = {
				params: {"with": ["lab", "course", "vms", "manual", "manual.steps"]}
			};

			App.locale.mergeI18n(pageTexts);
			//Checks logged in
			this.setup(options);
			this.startTimer();
			this.once(VlpBaseView.STARTUP_COMPLETE, this.launch);
		},
		/**
		 * Display the content.
		 */
		render: function () {
			if (App.station.isRegistered() && App.remoteControl) {
				$("a.home").removeAttr("href");
			}
			if (Browser.partialSupport && !App.remoteControl) {
				var data = {
					entitlementId: this.entitlementId
				};

				this.$(".partial-support").html(partialSupportPromptTPL(data));
			}

			if(this.waitingRoomData){
				if (!this.waitingRoomView) {
					this.model.set("waitingRoomData", this.waitingRoomData);
					this.waitingRoomView = new WaitingRoomView({
						model: this.model,
						el: this.$("#entitlement")
					});
					this.waitingRoomView.render();
				}
			}

			if (this.started) {
				this.model.set("activeConsoleId", this.consoleId);
				if (!this.entitlementView) {
					this.entitlementView = new EntitlementView({
						model: this.model,
						el: this.$("#entitlement")
					});
					App.VlpGlobal.setActiveEntitlementView(this.entitlementView);
					this.entitlementView.off("started", this.stopTimer).on("started", this.stopTimer);
					this.entitlementView.render();
				}
			}
			//Always return this for chaining
			return this;
		},
		launch: function () {
			var self = this;
			if (Browser.partialSupport && !App.remoteControl) {
				return;
			}
			if(!App.remoteControl && App.config.defaultConsolePosition === "maximized"){
				$("body").addClass("maximize");
				$("body").toggleClass("compact-maximize", App.config.maximizeCompactPanelTabs || App.config.compactPanelTabs);
			}

			$("body").toggleClass("compact-panel-tabs", App.config.compactPanelTabs);

			if (!this.model && this.entitlementId) {
				this.model = new Entitlement.Model({
					id: this.entitlementId,
					phase: "starting",
					activeConsoleId : this.consoleId
				});

				App.VlpGlobal.setActiveEntitlement(this.model);

				this.model.studentClassPushUpdate();

				this.instructorHelpRequestModel = new InstructorHelpRequest.Model({
					classId      : null,
					status       : null,
					entitlementId: null
				});

				this.listenTo(this.model, "change:classId", this.classChanged);

				this.listenTo(this.instructorHelpRequestModel, "change:status", this.updateRaisedHandDisplayBorder);

				App.push.off("received:entitlements:" + this.model.id + ":ready", this.onEntitlementReady);
				App.push.off("received:entitlements:" + this.model.id + ":error", this.onEntitlementError);
				App.push.off("received:labError", this.onEntitlementError);

				App.push.on("received:entitlements:" + this.model.id + ":ready", this.onEntitlementReady);
				App.push.on("received:cloneVapp:" + this.model.id + ":update", _.throttle(this.onCloneVapp, 1000, {leading: false}));
				App.push.on("received:entitlements:" + this.model.id + ":error", this.onEntitlementError);
				App.push.on("received:labError", this.onEntitlementError);
				this.listenTo(App.push, "received:entitlements:" + this.model.id + ":onDemandProvisioning", this.onDemandProvisioning);

				App.push.on("received:instructorHelpRequest:" + this.model.id + ":update", this.onInstructorHelpRequestMsg);

				if(App.config.systemMessagesEnabled) {
					App.push.subscribe({
						service: "systemMessage",
						channel: "/systemMessage/lab"
					});
					App.push.subscribe({
						service: "systemMessage",
						channel: App.push.makePath("systemMessage", App.getTenant().toLowerCase(), "lab")
					});
				}

				this.model.start({
					success: function () {
						App.VlpGlobal.applyEntitlementOverrides(self.model);
						// supports the new case where on a lab level there could be separate chat URL
						var labLiveChat = self.model.get("liveChat");
						var labLiveChatUrl = self.model.get("liveChatUrl");
						if (labLiveChat && labLiveChatUrl){
							App.config.liveChatUrl = self.model.get("liveChatUrl");
							self.enableLiveChat();
						}
						App.push.off("received:entitlements:" + self.model.id + ":ready", self.onEntitlementReady);
						App.push.off("received:entitlements:" + self.model.id + ":error", self.onEntitlementError);
						App.push.off("received:labError", self.onEntitlementError);

						self.started = true;
						self.startTime = Date.now();
						if (self.model.get("sku")) {
							App.router.navigate("lab/" + self.model.get("sku") + "/" + self.model.id + "/" + (self.consoleId ? self.consoleId : ""), {trigger: false, replace: true});
						}
						self.render();
						App.analytics.trackEvent("Entitlement", "Start", self.model.get("sku"));
					},
					error: this.onEntitlementError
				});
			}
		},
		onInstructorHelpRequestMsg: function(info){
			this.instructorHelpRequestModel.set("status",info.data.status);
		},
		onCloneVapp : function(info){
			var data = (info && info.data) || null;
			if(data && data.phase == "start"){
				this.generalMessage({ id : "cloud_vapp", type : "warning", message : App.i18n("console.entitlement.cloneVapp.started")});
			}

			if(data && data.phase == "end"){
				this.generalMessage({ id : "cloud_vapp", type : "warning", message : App.i18n("console.entitlement.cloneVapp.finished")});
			}
		},
		onEntitlementReady: function (info){
			var data = (info && info.data) || null;
			if (data) {
				this.model.set(this.model.parse(data));
			}
		},

		onEntitlementError: function (model, response){
			if (response && response.response.data && !response.response.data.hasCapacity) {
				this.waitingRoomData = response.response.data;
				this.render();
				return;
			}
			if (this._exiting) {
				return;
			}
			if (!(model instanceof Backbone.Model)) {
				response = model;
			}
			this._exiting = true;
			var message = App.i18n("errors.getLab");

			if (response && response.data && response.data.message) {
				message = _.escapeHTML(response.data.message);
			} else if (!response || !response.response) {
				message = App.i18n("errors.getLab");
			} else if (response.response.errorCode === 1310) {
				message = App.i18n("errors.labExpired");
			} else if (response.response.message && response.response.message.length > 0) {
				message = _.escapeHTML(response.response.message);
			}
			this.model.set("phase", "error");
			this.model.studentClassPushUpdate({data: {status: "error", message: message}});
			alert(message);
			this.leave();
		},
		leave: function () {
			this.$("#entitlement").hide();
			if (App.session.get("returnUrl")) {
				App.redirect(_.sanitizeURL(App.session.get("returnUrl")));
			} else if (App.session.get("externalSite")) {
				window.self.close();
				$("#modal_return").modal("show");
			} else {
				if (App.station.isRegistered() && App.remoteControl) {
					App.redirect({page: App.config.pages.EVENT_PROMOS});
				} else {
					App.redirect({page: App.config.pages.HOME});
				}
			}
		},
		showEntitlement: function (entitlementId, consoleId) {

			if (this.isLoggedIn()) {
				if (!entitlementId) {
					entitlementId = App.entitlementId;
				}

				if (entitlementId === null || entitlementId === undefined || entitlementId === 0 || entitlementId === "") {
					this.$(".invalid-request").show();
					this.$("#entitlement").hide();
				} else {
					this.$(".invalid-request").hide();
					this.$("#entitlement").show();

					this.entitlementId = entitlementId;
					App.entitlementId = entitlementId;
					this.consoleId = consoleId;
					this.render();
				}
			} else {
				var self = this;
				this.once(VlpBaseView.LOGGED_IN, function () {
					self.showEntitlement(entitlementId, consoleId);
				});
			}
		},

		startTimer: function () {
			if (this.timerInterval) {
				this.stopTimer();
			}
			this.timer();
			this.timerInterval = setInterval(this.timer, 500);
		},
		stopTimer: function () {
			if (this.timerInterval) {
				clearTimeout(this.timerInterval);
			}
			this.timerInterval = null;
		},
		timer: function () {
			var timeDiff = this.getDeployTimeElapsed();

			var minutes = Math.floor(timeDiff / 60);
			var seconds = Math.floor((timeDiff - (minutes * 60)));
			var display = _.lpad(minutes, 2, "0") + ":" + _.lpad(seconds, 2, "0");

			this.$(".time-elapsed").text(display);
			if (this.model && this.model.has("deployTime") && this.model.get("deployTime") > 0) {
				var deployPercent = (timeDiff / this.model.get("deployTime")) * 100;
				deployPercent = Math.min(100, deployPercent);
				this.$("#deploy_progress .bar").css("width", deployPercent + "%");
			}
		},
		getDeployTimeElapsed: function () {
			if (!this.startTime) {
				this.startTime = Date.now();
			}
			var now = Date.now();
			var timeDiff = (now - this.startTime) / 1000; //in seconds
			if (this.model) {
				this.model.set("deployTimeElapsed", timeDiff);
			}
			return timeDiff;
		},

		classChanged: function () {
			if (this.model.get("classId")) {
				this.$("#help_request_instructor_container").show();
				$("#help_request_container").remove();
			}
			this.model.studentClassPushSubscribe();
			this.model.studentClassRequestSync();
		},

		toggleHelpRequest: function () {
			 if (this.instructorHelpRequestModel.get("status")==="on") {
				 this.deleteHelpRequest();
			 } else {
				 this.createHelpRequest();
			}
		},

		createHelpRequest: function () {
			this.instructorHelpRequestModel.set({
				classId      : this.model.get("classId"),
				status       : "on",
				entitlementId: this.model.get("id")
			});

			this.instructorHelpRequestModel.createHelpRequest();
		},

		deleteHelpRequest: function () {
			this.instructorHelpRequestModel.set({
				classId      : this.model.get("classId"),
				status       : "off",
				entitlementId: this.model.get("id")
			});

			this.instructorHelpRequestModel.deleteHelpRequest();
		},

		updateRaisedHandDisplayBorder: function () {
			if (this.instructorHelpRequestModel.get("status")==="on") {
				this.addBorder();
			} else {
				this.removeBorder();
			}
		},

		addBorder: function () {
			$("body").addClass("help-request");
			this.$("#help_request_instructor").addClass("active").button("requested");
			$("#wrapper").prepend("<div id=\"help_request_indicator\"><span></span><span></span><span></span><span></span></div>");
			$("#help_request_indicator").addClass("flash");
			$("#help_request_indicator > span").css("background-color", "red");
		},

		removeBorder: function () {
			$("body").removeClass("help-request");
			this.$("#help_request_instructor").removeClass("active").button("reset");
			$("#help_request_indicator").remove();
		},

		onDemandProvisioning: function (info) {
			if (info && info.data) {
				this.model.set("onDemandProvisioning", true);
			}
		}
	});

});

