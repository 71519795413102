
define('hbs!tpls/event/registered.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var helper;

  return "<div class=\"page-content\">\n\n	<div id=\"register_form\">\n		<div class=\"unregister\">\n			<div class=\"spinner\"></div>\n			<button class=\"btn btn-default\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","event.registered.unregistering",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"event.registered.unregister",{"name":"$","hash":{},"data":data}))
    + "</button>\n		</div>\n		<h1 class=\"title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"event.registered.registered",{"name":"$","hash":{},"data":data}))
    + "</h1>\n		<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"event.registered.stationId",{"name":"$","hash":{},"data":data}))
    + ": <span>"
    + this.escapeExpression(((helper = (helper = helpers.stationId || (depth0 != null ? depth0.stationId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"stationId","hash":{},"data":data}) : helper)))
    + "</span></h2>\n		<a class=\"btn btn-large btn-primary open-promo-window\" href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"page":"CATALOGS"},"data":data}))
    + "\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"event.registered.openCatalog",{"name":"$","hash":{},"data":data}))
    + "</a>\n		<a class=\"btn btn-large btn-primary\" href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"page":"EVENT_PROMOS"},"data":data}))
    + "\" target=\"_blank\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"event.registered.openSecondWindow",{"name":"$","hash":{},"data":data}))
    + "</a>\n	</div>\n\n</div>";
},"useData":true});
Handlebars.registerPartial('event_registered', t);
return t;
});

