define('views/manual-preview/manual',['require','views/console/manual'],function (require) {
	"use strict";
	//class dependencies
	var ConsoleManualView = require("views/console/manual");

	return ConsoleManualView.extend({
		resourcePageChanged : function(){
			// do nothing
		}
	});
});

