/* globals unescape */
define('models/account',['require','exports','module','jquery','underscore','backbone','library/vlp/app','library/vlp/model','library/vlp/collection','models/system-message','models/lab','models/help-request','library/utilities/md5'],function (require, exports) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection"),
	    SystemMessage  = require("models/system-message"), //jshint ignore:line
	    Lab            = require("models/lab"),            //jshint ignore:line
	    HelpRequest    = require("models/help-request"),    //jshint ignore:line
	    md5            = require("library/utilities/md5");


	//Use exports for requirejs circular dependency resolving
	var Account = exports;

	//Add Account to the App.Models namespace
	App.Models.Account = Account;


	Account.Model = BaseModel.extend({
		urlRoot : "/accounts",

		defaults : {
			userId                    : 0,
			username                  : "",
			password                  : "",
			company                   : "",
			firstName                 : "",
			lastName                  : "",
			displayName               : "",
			email                     : "",
			phone                     : "",
			streetAddress1            : "",
			streetAddress2            : "",
			city                      : "",
			state                     : "",
			postalCode                : "",
			country                   : "",
			shortBio                  : "",
			location                  : "NASA-West",
			language                  : "",
			locale                    : "en",
			hasCredits                : true,
			showTips                  : true,
			showWalkthrough           : true,
			eulaAccepted              : false,
			setPassword               : false,
			passwordExpired           : false,
			otpUsed                   : false,
			tenants                   : [],
			systemMessages            : [],
			authenticationProviders   : [],
			loggedIn                  : false,
			helpRequest               : null,
			passwordConfirm           : "",
			passwordCurrent           : "",
			passwordNew               : "",
			verifyByDate              : null,
			questionsAnsweredCorrectly: 0
		},

		types : {
			city_public               : Backbone.Types.Boolean,
			company_public            : Backbone.Types.Boolean,
			country_public            : Backbone.Types.Boolean,
			displayName_public        : Backbone.Types.Boolean,
			email_public              : Backbone.Types.Boolean,
			firstName_public          : Backbone.Types.Boolean,
			lastName_public           : Backbone.Types.Boolean,
			phone_public              : Backbone.Types.Boolean,
			postalCode_public         : Backbone.Types.Boolean,
			shortBio_public           : Backbone.Types.Boolean,
			state_public              : Backbone.Types.Boolean,
			streetAddress1_public     : Backbone.Types.Boolean,
			streetAddress2_public     : Backbone.Types.Boolean,
			answerQuestions           : Backbone.Types.Boolean,
			hasCredits                : Backbone.Types.Boolean,
			eulaAccepted              : Backbone.Types.Boolean,
			passwordExpired           : Backbone.Types.Boolean,
			otpUsed                   : Backbone.Types.Boolean,
			setPassword               : Backbone.Types.Boolean,
			setQuestions              : Backbone.Types.Boolean,
			showTips                  : Backbone.Types.Boolean,
			showWalkthrough           : Backbone.Types.Boolean,
			questionsAnsweredCorrectly: Backbone.Types.Number
		},
		relations : [
			{
				key            : "systemMessages",
				type           : Backbone.HasMany,
				relatedModel   : "SystemMessage.Model",
				collectionType : "SystemMessage.Collection"
			},
			{
				key            : "tenantSystemMessages",
				type           : Backbone.HasMany,
				relatedModel   : "SystemMessage.Model",
				collectionType : "SystemMessage.Collection"
			},
			{
				key            : "globalSystemMessages",
				type           : Backbone.HasMany,
				relatedModel   : "SystemMessage.Model",
				collectionType : "SystemMessage.Collection"
			},
			{
				key            : "authoredLabs",
				type           : Backbone.HasMany,
				relatedModel   : "Lab.Model"
			},
			{
				key            : "helpRequest",
				type           : Backbone.HasOne,
				relatedModel   : "HelpRequest.Model"
			}
		],
		initialize : function(){

		},
		parse : function(){
			var result = BaseModel.prototype.parse.apply(this, arguments);
			if(result.tenants){
				result.tenants = _.map(result.tenants, function(item){
					item.name = item.name || item.tenant;
					delete item.tenant;
					item = _.defaults(item, {
						visible            : true,
						showServiceTerm    : false,
						maintenanceMode    : false,
						maintenanceMessage : ""
					});
					item.visible         = _.toBoolean(item.visible);
					item.maintenanceMode = _.toBoolean(item.maintenanceMode);
					item.showServiceTerm = _.toBoolean(item.showServiceTerm);
					return item;
				});
			}
			if(result.securityQuestions && !_.isArray(result.securityQuestions)){
				result.securityQuestions = [result.securityQuestions];
			}
			if(result.systemMessages){
				var systemMessages = [];
				if(result.systemMessages.tenant){
					result.tenantSystemMessages = result.systemMessages.tenant;
					systemMessages = systemMessages.concat(result.systemMessages.tenant);
				}
				if(result.systemMessages.global){
					result.globalSystemMessages = result.systemMessages.global;
					systemMessages = systemMessages.concat(result.systemMessages.global);
				}
				result.systemMessages = systemMessages;
			}

			if(this.attributes.id === "me"){
				//keep id == "me"
				result.userId = result.id;
				delete result.id;
			}
			return result;
		},

		url : function () {

			var urlRoot = _.result(this, "basePath");
			if (this.id === "me") {
				urlRoot += this.id;
			} else if(this.attributes.username){
				urlRoot += this.attributes.username;
			}
			if (urlRoot.match(/^https?:/)) {
				return urlRoot;
			}
			return this.serverBase + urlRoot;
		},
		getters : {
			accountVerified : function(){
				return this.attributes.hasOwnProperty("accountVerified") ? this.attributes.accountVerified : !this.attributes.verifyByDate;
			},
			avatar : function(){
				var email = this.get("email") || this.get("username");

				var baseGravatar = (window.self.location.protocol === "https:" ? "https://secure.gravatar.com/avatar/" : "http://www.gravatar.com/avatar/");
				return baseGravatar + md5(email) + "?d=mm";
			},
			avatarLarge : function(){
				return this.get("avatar") + "&s=200";
			},
			hasAuthenticationProviders : function(){
				return this.get("hasLocalAuthenticationProviders") || this.get("hasRemoteAuthenticationProviders");
			},
			hasLocalAuthenticationProviders : function(){
				if(App.config.disableStudentLocalLogin){
					return false;
				} else {
					return _.some(this.get("authenticationProviders"), function(provider){return provider.type === "LOCAL";});
				}
			},
			hasRemoteAuthenticationProviders : function(){
				if(App.config.allowStudentSSOLogin){
					return _.some(this.get("authenticationProviders"), function(provider){return provider.type !== "LOCAL";});
				} else {
					return false;
				}
			},
			remoteAuthenticationProviders : function(){
				if(App.config.allowStudentSSOLogin){
					return _.filter(this.get("authenticationProviders"), function(provider){return provider.type !== "LOCAL";});
				} else {
					return [];
				}
			}
		},
		setters : {
			language : function(value, options){
				var locale = "en";
				if(value){
					for(var index in App.config.locales){
						if(App.config.locales.hasOwnProperty(index)){
							if(index.toLowerCase() === value.toLowerCase()){
								value = index;
								locale = value.toLowerCase();
							} else if(App.config.locales[index].toLowerCase() === value.toLowerCase()){
								value = index;
								locale = value.toLowerCase();
								break;
							}
						}
					}
				} else {
					value = "default";
				}
				if(value === "default"){
					locale = "default";
				}
				this.set("locale", locale, options);
				return value;
			},
			tenants : function(value, options){
				var visibleTenants = _.filter(value, function(item){ return item.visible; });
				this.set("visibleTenants", visibleTenants, options);
				var activeTenant = null;
				_.each(value, function(tenant){
					if(tenant.name === App.getTenant()){
						activeTenant = tenant;
					}
				});
				this.set("activeTenant", activeTenant, options);
				return value;
			}
		},

		login : function(options){

			var self = this;
			options = options || {};

			options.type = "POST";
			options.url = this.serverBase + "login";
			options.params = options.params || {};
			options.loggingIn = true;

			var tenant = App.getTenant();
			if(tenant){
				options.params.tenant = tenant;
			}
			options.url = this._makeURL(options);

			var AuthToken = unescape(encodeURIComponent(this.get("username") + ":" + this.get("password")));
			var Auth = "Basic " + btoa(AuthToken);
			options.headers = {
				Authorization : Auth
			};


			var neeToken;
			var error = options.error;

			var success = options.success;

			var fetchSuccess = function(model, response){
				self.set("loggedIn", true);
				App.cookie.set("loggedIn", true);
				self.trigger("loggedIn");

				if(self.get("setQuestions") || self.get("answerQuestions")){
					if(!App.config.tenant){
						App.config.tenant = self.getDefaultTenant();
						App.setGlobalParam("tenant", App.getTenant());
						self.set("usingDefaultTenant", true);

						if(self.has("tenants") && self.get("tenants").length > 1){
							self.set("chooseNewTenant", true);
						}
					}
				}

				if(App.tempLocale){
					App.locale.set(App.tempLocale);
					App.session.set("tempLocale", App.tempLocale);
					delete App.tempLocale;
				} else{
					App.locale.set(self.get("locale"));
				}
				if(success){
					success(self, response);
				}
			};

			options.success = function(resp){

				if(!self.set(self.parse(resp, options), options)) { return false; }
				neeToken = resp["nee-token"] || resp.neeToken || (resp.data && (resp.data["nee-token"] || resp.data.neeToken ));

				self.fetch({
					loggingIn : true,
					success : fetchSuccess,
					error : function(model, jqXHR, ajaxSettings){
						if(jqXHR && jqXHR.response && jqXHR.response.errorCode === 9003) {
							if (!App.thirdPartyCookieHack) {
								App.thirdPartyCookieHack = true;
								App.session.set("nee-token", neeToken, 0);

								var headers = $.ajaxSetup().headers || {};
								headers["x-nee-token"] = neeToken;
								$.ajaxSetup({ headers : headers });

								self.fetch({
									success : fetchSuccess,
									error   : function (model, jqXHR, ajaxSettings) {
										if (jqXHR && jqXHR.response && jqXHR.response.errorCode === 9003) {
											jqXHR.response.errorCode = -9003;
											jqXHR.response.message = App.i18n("errors.thirdPartyCookies");
										}
										if(error){
											error(model, jqXHR, ajaxSettings);
										}
									}
								});
							}
						} else{
							if(error){
								error(model, jqXHR, ajaxSettings);
							}
						}
					}
				});
			};

			options.error = function(jqXHR){
				if(jqXHR && jqXHR.response) {
					if(jqXHR.response.errorCode === 9004) {
						self.set("passwordExpired", true);
					}
					if(jqXHR.response.errorCode === 9005) {
						self.set("otpUsed", true);
					}
				}
				if(error){
					error(jqXHR);
				}
			};
			return $.ajax(options);
		},
		logout : function(options){
			var self = this;
			options = options || {};
			if(!this.get("loggedIn") && !options.force){
				if(options.success){
					this.set("loggedIn", false);
					App.cookie.remove("loggedIn");
					App.session.remove("nee-token");
					App.session.remove("tempLocale");
					options.success(this);
				}
				return;
			}
			this._logoutSuccesses = this._logoutSuccesses || [];
			if(options.success){
				this._logoutSuccesses.push(options.success);
			}
			this._logoutErrors = this._logoutErrors || [];
			if(options.error){
				this._logoutErrors.push(options.error);
			}

			if(this._loggingOut) { return; }
			this._loggingOut = true;
			var loggingOutDeferreds = [];
			if(!options.logoutFlush){
				this.trigger("loggingOut", this, loggingOutDeferreds);
			}
			var finishLogout = function(){

				self.set("loggedIn", false);
				App.cookie.remove("loggedIn");
				App.session.remove("tempLocale");
				options.url = self.serverBase + "logout";

				options.properties = [];
				options.params = options.params || {};
				if(App.station && App.station.has("stationKey")){
					options.params.stationKey = App.station.get("stationKey");
				}
				options.success = function(model, resp){
					model.trigger("loggedOut", options);
					App.session.remove("nee-token");
					if(App.station){
						if(App.station && App.station.has("byodSection")){
							App.session.remove("byodSection");
							App.session.remove("stationKey");
							App.store.remove("stationKey");
						} else {
							if(App.config.anonymousLanguage){
								App.locale.set(App.config.anonymousLanguage);
							} else{
								App.locale.set("default");
							}
						}
					}

					if(self._logoutSuccesses && self._logoutSuccesses.length){
						for(var i = 0; i < self._logoutSuccesses.length; i++){
							self._logoutSuccesses[i](self, resp);
						}
					}
					delete self._loggingOut;
					delete self._logoutSuccesses;
					delete self._logoutErrors;
				};
				options.error = function (resp) {
					if(self._logoutErrors && self._logoutErrors.length){
						for(var i = 0; i < self._logoutErrors.length; i++){
							self._logoutErrors[i](self, resp, options);
						}
					}

					delete self._loggingOut;
					delete self._logoutSuccesses;
					delete self._logoutErrors;
				};

				self.action("logout", options);
			};

			return $.when.apply($, loggingOutDeferreds).always(finishLogout);
		},
		checkLoggedIn : function(options){
			options = options || {};

			var self = this;
			if(!options.force){
				if(this.get("loggedIn")){
					if(options.success){
						options.success(this);
					}
					return;
				}
			}

			var success = options.success;

			options.success = function(model, resp){
				model.set("loggedIn", true);
				App.cookie.set("loggedIn", true);

				var params  = {};
				if(self.get("usingDefaultTenant")) {
					params.tenant = "";
				}
				self.fetch({
					params : params,
					success : function(){

						if(!App.config.tenant){
							App.config.tenant = self.getDefaultTenant();
							App.setGlobalParam("tenant", App.getTenant());
							self.set("usingDefaultTenant", true);

							if(self.has("tenants") && self.get("tenants").length > 1){
								self.set("chooseNewTenant", true);
							}
						}

						if(App.session.get("tempLocale")){
							App.locale.set(App.session.get("tempLocale"));
						} else{
							App.locale.set(self.get("locale"));
						}

						//Not refreshing page to load new locale
						if(!App.redirecting){
							if(success){
								success(self, resp);
							}
						}
					},
					error : options.error
				});
			};
			options.method = "read";
			return this.action("authed", options);

		},

		verifyAccount : function(options){

			var self = this;
			options = options || {};

			options.type = "GET";
			options.params = {
				token : this.get("token")
			};
			options.url = this.serverBase + "verifyAccount";
			options.url = this._makeURL(options);


			var success = options.success;
			options.success = function(resp){
				if(!self.set(self.parse(resp, options), options)) { return false; }

				if(self.get("username") && self.get("email")) {
					self.set("loggedIn", true);
					App.cookie.set("loggedIn", true);
				}
				if(!App.config.tenant){
					App.config.tenant = self.getDefaultTenant();
					App.setGlobalParam("tenant", App.getTenant());
					self.set("usingDefaultTenant", true);
				}

				if(success){
					success(self, resp);
				}
			};
			return $.ajax(options);
		},
		getDefaultTenant : function(){
			var tenant = App.config.tenant;
			if(!tenant && this.get("tenants") && this.get("tenants").length){
				var i, t;
				if(this.get("defaultTenant")){
					tenant = this.get("defaultTenant");
					for(i = 0; i < this.get("tenants").length; i++){
						t = this.get("tenants")[i];
						if(t.name.toLowerCase() === tenant.toLowerCase()){
							if(t.maintenanceMode){
								tenant = false;
							}
							break;
						}
					}
				}
				for(i = 0; i < this.get("tenants").length; i++){
					t = this.get("tenants")[i];
					if(!t.maintenanceMode){
						tenant = t.name;
						break;
					}
				}
				if(!tenant){
					tenant = this.get("tenants")[0].name;
				}
			}
			return tenant;
		},
		newToken : function(options){

			var self = this;
			options = options || {};

			options.type = "GET";
			options.params = {
				token : this.get("verificationToken") || this.get("token")
			};
			options.url = this.serverBase + "resendToken";
			options.url = this._makeURL(options);


			var success = options.success;
			options.success = function(resp){
				if(!self.set(self.parse(resp, options), options)) { return false; }
				if(success){
					success(self, resp);
				}
			};
			return $.ajax(options);
		},
		newTokenByInstructor : function(options){

			var self = this;
			options = options || {};

			options.type = "GET";
			options.params = {
				username : this.get("username")
			};
			options.url = this.serverBase + "resendTokenByInstructor";
			options.url = this._makeURL(options);


			var success = options.success;
			options.success = function(resp){
				if(!self.set(self.parse(resp, options), options)) { return false; }
				if(success){
					success(self, resp);
				}
			};
			return $.ajax(options);
		},
		sendNewToken : function(options){

			var self = this;
			options = options || {};

			options.type = "GET";
			options.params = {
				username : this.get("username")
			};
			options.url = this.serverBase + "sendNewToken";
			options.url = this._makeURL(options);


			var success = options.success;
			options.success = function(resp){
				if(!self.set(self.parse(resp, options), options)) { return false; }
				if(success){
					success(self, resp);
				}
			};
			return $.ajax(options);
		},

		getSecurityQuestion : function(options){
			options = options || {};
			options.data = options.data || {};

			options.data.username = this.get("username");
			options.url = this.serverBase + "getQuestions";
			return this.fetch(options);
		},
		checkSecurityQuestion : function(question, options){
			var self = this;
			options = options || {};
			options.properties = options.properties || ["securityQuestions"];

			if(!_.contains(options.properties, "securityQuestions")){
				options.properties.push("securityQuestions");
			}

			if(self.get("questionsAnsweredCorrectly") >= 1){
				var success = options.success;
				options.success = function(model, resp){
					model.set("loggedIn", true);
					App.cookie.set("loggedIn", true);
					if(success){
						success(self, resp);
					}
				};
			}

			if(!this.get("loggedIn")){
				options.properties.push("username");
				options.properties.push("password");
				options.url = this.serverBase + "answerQuestions";
				return this.action("answerQuestions", options);
			} else{
				return this.action("checkQuestions", options);
			}
		},
		saveSecurityQuestion : function(options){
			options = options || {};
			options.properties = options.properties || ["securityQuestions"];

			if(!_.contains(options.properties, "securityQuestions")){
				options.properties.push("securityQuestions");
			}
			if(!this.get("loggedIn")){
				options.properties.push("username");
				options.properties.push("password");
				options.url = this.serverBase + "setQuestions";
				return this.action("setQuestions", options);
			} else{
				if(this.get("token")){
					options.params = options.params || {};
					options.params.token = this.get("token");
				}
				return this.save({}, options);
			}

		},
		changePassword : function(options){
			options = options || {};
			options.properties = options.properties || ["password", "passwordConfirm"];

			if(!_.contains(options.properties, "password")){
				options.properties.push("password");
			}
			if(!_.contains(options.properties, "passwordConfirm")){
				options.properties.push("passwordConfirm");
			}
			if(this.get("token")){
				options.params = options.params || {};
				options.params.token = this.get("token");
				options.url = this.serverBase + "changePassword";
				return this.action("changePassword", options);
			} else{
				if(!_.contains(options.properties, "passwordCurrent")){
					options.properties.push("passwordCurrent");
				}
				return this.save({}, options);
			}

		},

		forgotPassword : function(options){

			options = options || {};

			options.type = "POST";
			options.url = this.serverBase + "forgotPassword";
			options.params = options.params || {};
			options.params.user = this.get("username");
			options.url = this._makeURL(options);

			return $.ajax(options);
		},
		register : function(options){
			options = options || {};
			options.properties = options.properties || ["email", "firstName", "lastName", "tenant", "company"];
			if(this.has("captchaResponse") && this.get("captchaResponse").length){
				options.properties.push("captchaResponse");
			} else if(this.has("alternativeCaptchaResponse") && this.get("alternativeCaptchaResponse").length){
				options.properties.push("alternativeCaptchaResponse");
			}
			options.url = this.serverBase + "selfReg";

			return this.action("register", options);
		},
		creditCheck : function(options){
			options = options || {};
			options.method = "read";
			return this.action("creditCheck", options);
		},
		changeExpiredPassword : function(options) {
			options = options || {};
			options.properties = options.properties || ["username", "password", "passwordNew", "passwordConfirm"];
			options.url = this.serverBase + "changeExpiredPassword";
			return this.action("changeExpiredPassword", options);
		},
		getAuthenticationProviderForUsername : function(username, options){
			options = options || {};
			options.type = "GET";
			options.url = App.config.authBase + "login/" + App.getTenant() + "/check";
			options.data = {
				username : username || this.get("username")
			};
			return $.ajax(options);
		}
	});


	Account.Collection = BaseCollection.extend({
		model : Account.Model
	});

});

