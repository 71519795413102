define('models/lab-review',['require','exports','module','underscore','backbone','library/vlp/app','library/vlp/model','library/vlp/collection'],function (require, exports) {
	"use strict";

	//library dependencies
	var _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection");

	//Use exports for requirejs circular dependency resolving
	var LabReview = exports;

	//Add Message to the App.Models namespace
	App.Models.LabReview = LabReview;


	LabReview.Model = BaseModel.extend({
		urlRoot   : "/lab-reviews",

		/**
		 * Default values for empty Models (new Message.Model())
		 */
		defaults : {
			title      : "This lab is really great!",
			date       : "2014-03-31T09:55:59Z",
			authorName : "John Doe",
			rating     : 3,
			review     : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vehicula nec urna nec egestas. Aliquam faucibus lorem sed justo ullamcorper feugiat. Sed consectetur, purus ac luctus vehicula, felis felis condimentum arcu, ultrices accumsan urna nunc vel tellus. Nullam tristique magna dolor, ac suscipit velit dapibus at. Nulla tempor nibh sed nisi faucibus malesuada. Pellentesque et augue non tellus interdum porta."
		},

		types : {
			rating      : Backbone.Types.Integer
		},

		/**
		 * Constructor
		 */
		initialize : function(){

		}
	});


	LabReview.Collection = BaseCollection.extend({
		labId : null,
		model : LabReview.Model,
		fetch : function(options){
			options = options ? _.clone(options) : {};
			options.url = options.url || this.url() + this.labId + "/reviews";

			return BaseModel.prototype.fetch.call(this, options);
		}
	});

});
