define('nls/root/dashboard',{
	"dashboard" : {
		"widgets" : {
			"realTime": {
				"title": "Real-Time",
				"historicalTitle": "Historical Labs in Use"
			},
			"popularLabs": {
				"title": "Popular Labs"
			},
			"averageLabsTaken": {
				"title": "Average Labs Taken"
			},
			"helpRequests" : {
				"title" : "Help Requests",
				"noLab" : "No Lab",
				"seat"  : "Seat",
				"sku"   : "SKU"
			},
			"helpRequestsPerLab" : {
				"title" : "Help Requests per Lab"
			}
		}
	},
	"errors"    : {
	},
	"title"     : "Dashboard"
});
