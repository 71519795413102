define('views/console/saas-console',['require','jquery','underscore','library/vlp/app','views/console/base-console','utilities/browser','hbs!tpls/console/saas-console.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
		_ = require("underscore");

	//class dependencies
	var App             = require("library/vlp/app"),
		BaseConsoleView = require("views/console/base-console"),
	    Browser         = require("utilities/browser"),
	    saasConsoleTPL  = require("hbs!tpls/console/saas-console.handlebars");


	return BaseConsoleView.extend({
		testConsoleWidth  : 800,
		testConsoleHeight : 480,

		consoleType : "saas",

		template : saasConsoleTPL,
		events : {

		},

		initialize : function(){
			BaseConsoleView.prototype.initialize.apply(this, arguments);
			this.events = _.defaults(this.events, BaseConsoleView.prototype.events);
		},


		serialize : function(){
			var data = {};
			if(this.model){
				data = this.model.toHandlebars();
				if(this.model.get("url")){
					data.url = App.VlpGlobal.replaceVariables(this.model.get("url"));
				}
			}
			data.loading = (this._displayState === this.LOADING);
			data.allowFullscreen = Browser.support.fullscreen;
			data.testConsoleWidth  = this.testConsoleWidth;
			data.testConsoleHeight = this.testConsoleHeight;

			return data;
		},
		/**
		 * Display the content.
		 */
		beforeRender : function(){
			this.iframe = null;
			this.testConsole = (this.model.get("type") === "test");
			if(this.model.get("type") === "isim"){
				$(window).off("message.saasConsole").on("message.saasConsole", this.onIframeMessage);
			}
		},
		afterRender : function(){
			if(this.model && this.model.get("type") === "test"){
				this.renderTestConsole();
			}

			BaseConsoleView.prototype.afterRender.apply(this, arguments);

			var self = this;

			if(this.model){

				this.$(".toggle-panel-left").tooltip({
					placement : "bottom",
					container : "body",
					title     : function(){ return self.entitlementView.getTogglePanelTitle("left"); },
					trigger   : "hover"
				});
				this.$(".toggle-panel-right").tooltip({
					placement : "left",
					container : "body",
					title     : function(){ return self.entitlementView.getTogglePanelTitle("right"); },
					trigger   : "hover"
				});

				this.$(".note").off("shown.console").on("shown.console", function(){
					self.$("[rel=tooltip]").tooltip();
				});

				this.iframe = this.$("#saas_console_iframe").get(0);
			} else{
				console.warn("No console loaded");
			}
		},

		resize : function(){
			if(this.testConsole){
				_.defer(this.resizeTestConsole);
			}

			BaseConsoleView.prototype.resize.apply(this, arguments);
		},
		refreshConsole : function(){
			var self = this;
			this.displayState(this.LOADING);
			this.render();

			setTimeout(function(){
				self.displayState(self.POWERED_ON);
				self.render();
			}, 1000);
		},
		onIframeMessage : function(event){
			try{
				var data = _.isString(event.originalEvent.data) ? JSON.parse(event.originalEvent.data) : event.originalEvent.data;
				// console.log("onIframeMessage data", data, {color : "orange"});
				if(data && data.topic){
					if(data.topic === "isim.end"){
						this.trigger("isimEnd", this.model.get("name"));
					}
				}
			} catch(e){}
		},

		sendIframeMessage : function(data){
			if(this.iframe){
				this.iframe.contentWindow.postMessage(data, "*");
			}
		},
		iSimNavigate : function(value){
			this.sendIframeMessage({
				topic: "isim.navigate",
				payload: String(value)
			});
		},
		iSimDisplay : function(value){
			this.sendIframeMessage({
				topic: "isim.display",
				payload: value
			});
		},
		renderTestConsole : function(){
			//Draw test console grid
			var $canvas = this.$("#test_console");
			if($canvas.length === 0){ return; }

			var context = $canvas[0].getContext("2d");

			var flip = false;
			for(var x = 0; x < this.testConsoleWidth; x+=32){
				for(var y = 0; y < this.testConsoleHeight; y+= 32){
					if(flip){
						context.fillStyle = "#9d261d";
					} else{
						context.fillStyle = "#0064cd";
					}
					context.fillRect(x, y, 32, 32);
					flip = !flip;
				}
			}
			context.fillStyle = "rgb(255, 255, 255)";
			context.font = "48px sans-serif";
			context.textAlign = "center";
			context.fillText("TEST CONSOLE", this.testConsoleWidth / 2, this.testConsoleHeight / 2);
		},

		resizeTestConsole : function(){
			var $canvas = this.$("#test_console");

			//Scale console
			var parentWidth  = this.$console.width();
			var parentHeight = this.$console.height();

			var horizScale = parentWidth / this.testConsoleWidth;
			var vertScale = parentHeight / this.testConsoleHeight;

			var newScale = Math.max(0.1, Math.min(horizScale, vertScale));

			$canvas.css("transform", "scale(" + newScale + ")");
			$canvas.css({
				left: (parentWidth - this.testConsoleWidth) / 2,
				top: (parentHeight - this.testConsoleHeight) / 2,
				width: this.testConsoleWidth,
				height: this.testConsoleHeight
			});
		},
	});

});

