define('views/support/entitlement',['require','jquery','underscore','library/vlp/app','library/vlp/view','views/support/vm-console','views/console/saas-console','views/console/console-listing','models/entitlement','hbs!tpls/support/entitlement.handlebars','views/console/entitlement-settings','views/console/panel-manager','views/instructor/manual','views/console/video-conference','views/console/resources'],function (require) {
	"use strict";

	var $ = require("jquery"),
		_ = require("underscore");

	var App                     = require("library/vlp/app"),
	    BaseView                = require("library/vlp/view"),
	    VmConsoleView           = require("views/support/vm-console"),
	    SaasConsoleView         = require("views/console/saas-console"),
	    ConsoleListingView      = require("views/console/console-listing"),
	    Entitlement             = require("models/entitlement"),
	    entitlementTPL          = require("hbs!tpls/support/entitlement.handlebars"),
	    EntitlementSettingsView = require("views/console/entitlement-settings"),
	    PanelManager            = require("views/console/panel-manager"),
	    ManualView              = require("views/instructor/manual"),
	    VideoConferenceView     = require("views/console/video-conference"),
	    ResourcesView           = require("views/console/resources");

	return BaseView.extend({
		template: entitlementTPL,

		events: {
			"click .toggle-panel-left"          : "onTogglePanelLeft",
			"click .toggle-panel-right"         : "onTogglePanelRight"
		},
		modelBindings: {
			".completed-steps": "activeStepNumber",
			".total-steps"    : "totalSteps"
		},

		initialize: function (options) {
			_.bindAll(this);

			this.consolesReady = false;

			App.push.on("received:entitlements:" + this.model.get("id") + ":ready", this.ready);
			App.push.on("received:entitlements:" + this.model.get("id") + ":error", this.onError);
			App.push.on("other:classEntitlement:" + this.model.get("id") + ":reset", this.vAppReset);
			this.listenTo(this.model, "change:progress", this.progressChanged);
			this.listenTo(this.model, "change:status", this.statusChanged);
			this.listenTo(this.model, "change:activeConsoleId", this.onActiveConsoleIdChanged);

			$(window).on("beforeunload", this.cleanupConnections);
			App.push.on("closing", this.pushClosing);

			this.model.pushSubscribe();
			this.model.supportPushSubscribe();
			this.model.set("supportViewing", true);
			this.model.supportRequestSync();
		},

		afterRender: function () {
			var self = this;

			this.createSettingsView();
			this.createPanelManager();
			this.createManualPanel();
			this.createVideoConferencePanel();
			this.createResourcesPanel();

			this.$("[rel=tooltip]").tooltip();
			$("body").addClass("has-vm-console");

			this.$(".toggle-panel-left").tooltip({
				placement : "bottom",
				container : "body",
				title     : function(){ return self.getTogglePanelTitle("left"); },
				trigger   : "hover"
			});
			this.$(".toggle-panel-right").tooltip({
				placement : "left",
				container : "body",
				title     : function(){ return self.getTogglePanelTitle("right"); },
				trigger   : "hover"
			});

			this.statusChanged();

			if ((!this.model.has("vms") || this.model.get("vms").length === 0) && !this.model.get("saasConsoleOnly")){
				this.model.fetchRelated("vms", {
					success: this.renderConsoles,
					error: this.vmFetchError
				});
			} else {
				this.ready();
			}

			return this;
		},

		createSettingsView : function(){
			if(!this.entitlementSettingsView){
				this.entitlementSettingsView = new EntitlementSettingsView({
					el   : this.$("#entitlement_settings"),
					model: this.model
				});
				this.entitlementSettingsView.render();
			}
		},
		createManualPanel: function () {
			if (!this.manualListing) {
				this.manualListing = new ManualView({
					el: this.$("#instructions"),
					model: this.model.get("manual"),
					manualId: this.model.get("manualId"),
					classId: this.model.get("classId"),
					networkTopology: this.model.get("networkTopology"),
					entitlement: this.model,
					currentStepId: this.model.getBookmarkId(),
					title: this.model.get("name"),
					panel: this.panelManager.getPanel("#instructions_panel"),
					consoleListingPanel: this.panelManager.getPanel("#vm_listing_panel")
				});
				App.VlpGlobal.setManualView(this.manualListing);

				this.manualListing.render();
			}
		},

		createVideoConferencePanel: function () {
			this.videoConference = new VideoConferenceView({
				el                  : this.$("#video_conference"),
				entitlement         : this.model,
				mode                : "support",
				panel               : this.panelManager.getPanel("#video_conference_panel")
			});
			this.videoConference.render();
		},
		createResourcesPanel: function () {
			if(!this.resources){
				this.resources = new ResourcesView({
					el                  : this.$("#resources"),
					collection          : this.model.get("resources"),
					panel               : this.panelManager.getPanel("#resources_panel"),
				});
				this.resources.render();
			}
		},

		createConsoleListingPanel: function () {
			this.model.get("vms").sort();

			if (!this.consoleListing) {
				this.consoleListing = new ConsoleListingView({
					el: $("#vm_listing"),
					collection: this.model.get("consoles"),
					entitlement: this.model,
					panel: this.panelManager.getPanel("#vm_listing_panel")
				});
			}

			this.consoleListing.render();
		},

		createPanelManager: function () {
			var self = this;
			if (!this.panelManager) {
				this.panelManager = new PanelManager({
					el: this.$(".panels")
				});

				this.$(".panels > .panel").each(function () {
					self.panelManager.addPanel(this);
				});
				this.panelManager.updateContainers(true);
				var consoleListingPanel = this.panelManager.getPanel("#vm_listing_panel");
				if(consoleListingPanel){
					consoleListingPanel.hide();
				}
			}
		},

		ready: function (info) {
			var self = this;

			App.push.off("received:entitlements:" + this.model.id + ":ready", this.ready);

			var data = (info && info.data) || null;

			if (data) {
				this.model.set(this.model.parse(data));
			}

			if (this.model.get("hasConsoles")) {
				this.renderConsoles();
			} else {
				this.model.progress({
					success: function (model, response) {
						if (!response.data || !response.data.vms || response.data.vms.length === 0) {
							self.model.set("phase", "error");
							alert(App.i18n("console.entitlement.vmsError"));
							self.leaveOrShowExternal();
						}
						self.vmsChanged();
					}
				});
			}
		},

		renderConsoles: function () {
			if (!this.rendered) {
				this.once("afterRender", this.renderConsoles);
				return;
			}

			this.createConsoleListingPanel();

			this.consolesReady = true;

			this.showConsole();
		},

		vmFetchError: function (info) {
			alert(App.i18n("instructor.entitlement.errors.cannotFetchVms"));
		},

		onActiveConsoleIdChanged : function(){
			this.showConsole();
		},
		updateConsoleNavigation : function(){
			App.router.navigate("console/" + this.model.id + "/" + this.model.get("activeConsoleId"), {trigger: false});
		},

		showConsole : function(){
			if(!this.rendered){
				this.once("afterRender", this.showConsole);
				return;
			}

			if(!this.consolesReady){ return; }

			var activeConsole;
			if(this.model.get("activeConsoleIdNumber")){
				if(this.model.get("activeConsoleIdType") === "saas"){
					activeConsole = this.model.get("saasConsoles").get(this.model.get("activeConsoleIdNumber"));
					if(activeConsole){
						this.showSaasConsole(activeConsole);
					}
				} else if(this.model.get("activeConsoleIdType") === "vm"){
					activeConsole = this.model.get("vms").get(this.model.get("activeConsoleIdNumber"));
					if(activeConsole){
						this.showVmConsole(activeConsole);
					}
				}
			}

			if(!activeConsole){
				var initialConsoleId = this.getInitialConsoleId();
				if(initialConsoleId){
					console.log("No console id specified or id not found. Setting to default:", initialConsoleId);
					this.model.set("activeConsoleId", initialConsoleId);
				} else{
					console.warn("no consoles to show");
				}
			} else {
				App.VlpGlobal.setActiveConsoleView(this.activeConsoleView);
				this.updateConsoleNavigation();
				if(this.activeConsoleView){
					$("body").toggleClass("has-vm-console", this.activeConsoleView.consoleType === "vm");
					$("body").toggleClass("has-saas-console", this.activeConsoleView.consoleType === "saas");
				}
			}
		},
		showVmConsole : function(vmConsole){
			if(!vmConsole){ return; }

			if(!this.vmConsoleView){
				this.vmConsoleView = new VmConsoleView({
					el : this.$("#vm"),
					entitlement : this.model,
					entitlementView : this,
					panelManager : this.panelManager
				});
			}
			this.detachConsoleView(this.saasConsoleView);

			this.activeConsoleView = this.vmConsoleView;
			this.vmConsoleView.setModel(vmConsole);
			this.attachConsoleView(this.vmConsoleView);
		},
		showSaasConsole : function(saasConsole){
			if(!saasConsole){ return; }

			if(!this.saasConsoleView){
				this.saasConsoleView = new SaasConsoleView({
					el : this.$("#saas_console"),
					entitlement : this.model,
					entitlementView : this,
					panelManager : this.panelManager
				});
			}
			this.detachConsoleView(this.vmConsoleView);

			this.activeConsoleView = this.saasConsoleView;
			this.saasConsoleView.setModel(saasConsole);
			this.attachConsoleView(this.saasConsoleView);
		},

		getInitialConsoleId : function(){
			var initialConsole;
			if(this.model.get("vms") && this.model.get("vms").length > 0){
				initialConsole = this.model.get("vms").findWhere({initial : true});
				if(!initialConsole){
					initialConsole = this.model.get("vms").at(0);
				}
			} else if(this.model.get("saasConsoles") && this.model.get("saasConsoles").length > 0){
				initialConsole = this.model.get("saasConsoles").at(0);
			}

			if(initialConsole){
				return initialConsole.get("consoleType") + "-" + initialConsole.get("id");
			} else {
				return null;
			}
		},

		switchConsole : function(eventOrConsoleId){
			var consoleIdentifier;
			if(eventOrConsoleId && eventOrConsoleId.currentTarget){
				consoleIdentifier = $(eventOrConsoleId.currentTarget).data("switchConsole");
			} else{
				consoleIdentifier = eventOrConsoleId;
			}
			if(!consoleIdentifier){ return; }


			var saasConsole, vm, id;
			if(consoleIdentifier.toString().indexOf("saas-") === 0){
				consoleIdentifier = consoleIdentifier.replace(/^saas-/, "");
				saasConsole = this.model.get("saasConsoles").findWhere({name: consoleIdentifier});
				id = parseInt(consoleIdentifier, 10);
				if(!saasConsole && !isNaN(id)){
					saasConsole = this.model.get("saasConsoles").get(id);
				}
			} else if(consoleIdentifier.toString().indexOf("vm-") === 0){
				consoleIdentifier = consoleIdentifier.replace(/^vm-/, "");
				vm = this.model.get("vms").findWhere({name: consoleIdentifier});
				id = parseInt(consoleIdentifier, 10);
				if(!vm && !isNaN(id)){
					vm = this.model.get("vms").get(id);
				}
			} else {
				saasConsole = this.model.get("saasConsoles").findWhere({name: consoleIdentifier});
				vm = this.model.get("vms").findWhere({name: consoleIdentifier});
				id = parseInt(consoleIdentifier, 10);
				if(!saasConsole && !vm && !isNaN(id)){
					saasConsole = this.model.get("saasConsoles").get(id);
					vm = this.model.get("vms").get(id);
				}
			}

			var newActiveConsoleId;
			if(saasConsole){
				newActiveConsoleId = "saas-" + saasConsole.get("id");
			} else if (vm){
				newActiveConsoleId = "vm-" + vm.get("id");
			}

			if(newActiveConsoleId === this.model.get("activeConsoleId")){
				if(this.activeConsoleView){
					this.activeConsoleView.refreshConsole();
				}
			} else if(newActiveConsoleId){
				this.model.set("activeConsoleId", newActiveConsoleId);
			}
		},


		detachConsoleView : function(consoleView){
			if(!consoleView) {return; }

			consoleView.pause();
			consoleView.$el.detach();
		},
		attachConsoleView : function(consoleView){
			if(!consoleView) {return; }

			consoleView.$el.appendTo(this.$("#lab"));
			consoleView.resume();
		},

		vmsChanged: function () {
			this.model.fetchRelated("vms", {
				success: this.renderConsoles,
				error: this.vmFetchError
			});
		},

		statusChanged: function (model, value) {
			if (this.model.get("status") === Entitlement.EXPIRED) {
				this.afterStatusChange("support.statusMessages.expired");
			} else if (this.model.get("status") === Entitlement.COMPLETED ||
				this.model.get("status") === Entitlement.CANCELED ||
				this.model.get("status") === Entitlement.AWAITING_GRADE) {
				this.afterStatusChange("support.statusMessages.completed");
			} else if (this.model.get("status") === Entitlement.INCOMPLETE) {
				this.afterStatusChange("support.statusMessages.incomplete");
			} else if (this.model.get("status") === Entitlement.GONE) {
				this.afterStatusChange("support.statusMessages.gone");
			} else if (this.model.get("status") === Entitlement.SUSPENDED) {
				this.afterStatusChange("support.statusMessages.suspended");
			}
		},

		progressChanged: function (model, value) {
			this.$(".progress").text(App.i18n("percent", Math.round(this.model.get("progress"))));
			this.$("#progressbar .progress-bar").css("width", +this.model.get("progress") + "%");
		},
		vAppReset : function(){
			App.mainView.generalMessage({
				id          : "class_updated_message",
				message     : App.i18n("instructor.entitlement.vAppReset"),
				type        : "info",
				removeDelay : 5000,
				dismissible : true
			});
			this.consolesReady = false;

			App.push.on("received:entitlements:" + this.model.get("id") + ":ready", this.ready);
		},
		stop: function () {
			this.model.set("supportViewing", false);

			this.stopListening(this.model);
			this.cleanUp();
		},

		afterStatusChange: function (messageKey) {
			$("#model_status_message").modal("show");
			$("#model_status_message .status-message").text(App.i18n(messageKey));
			this.cleanupConnections();
		},

		onError: function (response) {
			alert(_.escapeHTML(response.data.message));
		},

		cleanupConnections: function () {
			this.stop();
			this.cleanUp();
		},

		pushClosing : function() {
			this.model.set("supportViewing", false);
		},

		cleanUp: function () {
			$("#vm_listing").off("click.classEntitlement");

			if (this.panelManager) {
				this.panelManager.clean();
			}

			if (this.panelManager) {
				this.panelManager.remove();
			}

			if (this.consoleListing) {
				this.consoleListing.remove();
			}

			if (this.vmConsoleView) {
				this.vmConsoleView.remove();
			}

			this.remove();

			$("body").removeClass("maximize");
			this.$("[rel=tooltip]").tooltip("hide");
			this.$("[rel=popover]").popover("hide");
		},

		getPanelId: function(panelName){
			if(panelName === "consoles"){
				panelName = "vm_listing";
			} else if (panelName === "manual"){
				panelName = "instructions";
			} else if (panelName === "zoom"){
				panelName = "video_conference";
			}
			return "#"+ panelName + "_panel";
		},
		closePanelByName : function(panelName){
			if(!this.panelManager) { return; }
			var panel = this.panelManager.getPanel(this.getPanelId(panelName));
			if(panel){
				panel.close();
			}
		},
		openPanelByName : function(panelName){
			if(!this.panelManager) { return; }
			var panel = this.panelManager.getPanel(this.getPanelId(panelName));
			if(panel){
				panel.expand();
				panel.setFocus();
			}
		},
		togglePanelByName : function(panelName){
			if(!this.panelManager) { return; }
			var panel = this.panelManager.getPanel(this.getPanelId(panelName));
			if(panel){
				panel.focusOrToggleExpand();
			}
		},
		hidePanelByName : function(panelName){
			if(!this.panelManager) { return; }
			var panel = this.panelManager.getPanel(this.getPanelId(panelName));
			if(panel){
				panel.hide();
			}
		},
		showPanelByName : function(panelName){
			if(!this.panelManager) { return; }
			var panel = this.panelManager.getPanel(this.getPanelId(panelName));
			if(panel){
				panel.show();
			}
		},
		closeAllPanels : function(position){
			if(!this.panelManager) { return; }
			var panels = this.panelManager.getPanels(position);
			if(panels){
				_.each(panels, function(panel){panel.close();});
			}
		},
		openAllPanels : function(position){
			if(!this.panelManager) { return; }
			var panels = this.panelManager.getPanels(position);
			if(panels){
				_.each(panels, function(panel){panel.expand();});
			}
		},

		onTogglePanelLeft : function(){
			this.togglePanel("left");
		},
		onTogglePanelRight : function(){
			this.togglePanel("right");
		},
		togglePanel : function(position){
			this.$(".toggle-panel-" + position).tooltip("hide");
			var $dock = $(".panels .dock-" + position);
			if($dock.find(".panel.expanded.focused").length > 0){
				$dock.find(".panel.expanded.focused .open-close-panel").click();
			} else if($dock.find(".panel.expanded").length > 0){
				$dock.find(".panel.expanded .open-close-panel").click();
			} else if($dock.find(".collapsed.focused").length > 0){
				$dock.find(".panel.collapsed.focused:first .open-close-panel").click();
			} else {
				$dock.find(".panel.collapsed:first .open-close-panel").click();
			}
		},
		getTogglePanelTitle : function(position){
			var showHide = "show";
			if($("body").hasClass(position + "-panel-expanded")){
				showHide = "hide";
			}
			var $dock = $(".dock-" + position);

			var panelType = "";
			if($dock.find("#instructions_panel,#vm_listing_panel,#help_request_instructor_panel").length == 3) {
				panelType = "All";
			} else if($dock.find("#instructions_panel,#vm_listing_panel").length == 2){
				panelType = "ManualAndVmChooser";
			} else if($dock.find("#instructions_panel,#help_request_instructor_panel").length == 2){
				panelType = "ManualAndHelpRequest";
			} else if($dock.find("#vm_listing_panel,#help_request_instructor_panel").length == 2){
				panelType = "VmChooserAndHelpRequest";
			} else if($dock.find("#instructions_panel").length == 1){
				panelType = "Manual";
			} else if($dock.find("#vm_listing_panel").length == 1){
				panelType = "VmChooser";
			} else if($dock.find("#help_request_instructor_panel").length == 1){
				panelType = "HelpRequest";
			}
			var titleKey = "console.vm.togglePanel." + showHide + panelType + "Panel";
			return App.i18n(titleKey);
		}
	});

});

