/**
 * Unsupported Router
 *
 * Code starts running here for the /unsupported page.
 */
define('routers/unsupported',['require','library/vlp/router','views/unsupported/main'],function (require) {
	"use strict";

	//class dependencies
	var BaseRouter = require("library/vlp/router"),
	    MainView   = require("views/unsupported/main");


	return BaseRouter.extend({
		routeBase : "unsupported",
		/**
		 * Constructor
		 *
		 * This code is called when new Router([options]) is called in the example.html.
		 *
		 * @param options Map of options (mostly passed on to new MainView([options]);
		 */
		initialize : function(config){

			this.setup(config);

			this.mainView = new MainView(config);

			this.start();
		},
		/**
		 * List of possible routes
		 */
		routes : {
			"*actions" : "index"
		},
		/**
		 * Default (initial) route. Loads first step.
		 */
		index : function(){

		}
	});
});

