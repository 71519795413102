/**
 * Landing Router
 *
 * Code starts running here for the /catalogs page.
 */
define('routers/tenant-styler',['require','library/vlp/router','views/tenant-styler/main'],function (require) {
	"use strict";
	var BaseRouter = require("library/vlp/router"),
	    MainView   = require("views/tenant-styler/main");


	return BaseRouter.extend({
		routeBase : "",
		/**
		 * Constructor
		 *
		 * This code is called when new Router([options]) is called in the example.html.
		 *
		 * @param options Map of options (mostly passed on to new MainView([options]);
		 */
		initialize : function(config){

			this.setup(config);
			this.mainView = new MainView(config);

			this.start();
		},
		/**
		 * List of possible routes
		 */
		routes : {
			"*actions" : "index"
		},
		/**
		 * Default (initial) route. Loads first step.
		 */
		index : function(){
		}
	});
});
