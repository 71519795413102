define('nls/root/eula',{
	"eula"   : {
		"title" : "Terms of service for %s",
		"agree"  : "I Agree",
		"cancel" : {
			"title"         : "I Do Not Agree",
			"message"       : "You must agree to the EULA in order to use this service. You will now be logged out of the service.",
			"tenantMessage" : "You must agree to the terms of service in order to use this service. You will now be logged out of the service.",
			"returning"     : "Returning",
			"return"        : "Return to Agreement",
			"deny"          : "Logout"
		},
		"pageTitle"  : "EULA"
	},
	"errors" : {
	}
});
