define('library/underscore/is-color',['require','underscore'],function (require) {
	"use strict";
	var _  = require("underscore");

	_.mixin({
		isColor: function(color){
			color = String(color).toLowerCase();
			var s = new Option().style;
			s.color = color;
			var styleColor = String(s.color).toLowerCase();
			return styleColor == color || styleColor.indexOf("rgb") === 0;
		}
	});

	return _;
});
