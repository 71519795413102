/**
 * Maintenance main Backbone View
 */
define('views/maintenance/main',['require','underscore','library/vlp/app','views/vlp-base','models/system','models/support-option','views/common/layout-tpls','hbs!tpls/maintenance/main.handlebars','hbs!tpls/maintenance/system-error.handlebars','hbs!tpls/maintenance/system-maintenance.handlebars','hbs!tpls/maintenance/tenant-maintenance.handlebars','i18n!nls/maintenance'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//class dependencies
	var App                  = require("library/vlp/app"),
	    VlpBaseView          = require("views/vlp-base"),
	    System               = require("models/system"),
	    SupportOption        = require("models/support-option"),
	    LayoutTPLs           = require("views/common/layout-tpls"),
	    mainTPL              = require("hbs!tpls/maintenance/main.handlebars"),
	    systemErrorTPL       = require("hbs!tpls/maintenance/system-error.handlebars"),
	    systemMaintenanceTPL = require("hbs!tpls/maintenance/system-maintenance.handlebars"),
	    tenantMaintenanceTPL = require("hbs!tpls/maintenance/tenant-maintenance.handlebars"),
	    pageTexts            = require("i18n!nls/maintenance");


	return VlpBaseView.extend({
		name : "MAINTENANCE",
		className : "general-page",

		checkLoggedIn    : false,
		checkMaintenance : false,
		checkEula        : false,
		checkTenantPath  : false,
		checkSystem      : false,

		layoutTemplate : LayoutTPLs.layoutNoLogin,
		//Required
		mainTemplate   : mainTPL,

		initialRetryDelay          : 11000,
		frequencyRandomScale       : 60000,
		minimumMaintenanceFrequency: 10000,

		initialRetryCount : 2,


		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){

			_.bindAll(this);

			this.once(VlpBaseView.STARTUP_COMPLETE, this.render);
			App.locale.mergeI18n(pageTexts);

			//Checks logged in
			this.setup(options);
		},
		/**
		 * Display the content.
		 */
		render : function(){
			this.checkSystemStatus(this.initialRetryCount);
			return this;
		},
		checkSystemStatus : function(retries){
			var self = this;

			var system = new System.Model();
			system.fetch({
				retries : 0,
				success : function(){
					if(_.isNumber(retries) && retries > 0) {
						//One of the cores might not have up-to-date maintenance status, wait a bit.
						return self.retrySystemCall(retries);
					}

					self.available();
				},
				error : function(model, jqXHR){
					if(jqXHR.response && (jqXHR.response.errorCode == 9001 || jqXHR.response.errorCode == 9002)) {
						if (jqXHR.response.errorCode == 9002) {
							self.tenantMaintenance(_.escapeHTML(jqXHR.response.message), jqXHR.response.data);
						} else {
							self.systemMaintenance(_.escapeHTML(jqXHR.response.message));
						}
					} else{
						self.systemError(jqXHR);
					}
				},
				beforeSend : function(){
					self.$("#status_indicator").addClass("fa-spin");
					self.$(".checking-status-message").css("visibility", "visible");
				},
				complete : function(){
					self.$("#status_indicator").removeClass("fa-spin");
					self.$(".checking-status-message").css("visibility", "hidden");
				}

			});
		},
		stopPolling : function(){
			clearTimeout(this.checkInterval);
			this.checkInterval = null;
		},
		startPolling : function(){
			if(App.config.maintenanceFrequency > 0){
				var frequency = App.config.maintenanceFrequency + (Math.random() * this.frequencyRandomScale);
				frequency = Math.max(this.minimumMaintenanceFrequency, Math.floor(frequency));
				if(!this.checkInterval){
					this.checkInterval = setInterval(this.checkSystemStatus, frequency);
				}
			}
		},
		retrySystemCall : function(retries){
			var self = this;

			_.delay(function(){
				self.checkSystemStatus(retries - 1);
			}, this.initialRetryDelay);

			_.defer(function(){
				self.$("#status_indicator").addClass("fa-spin");
				self.$(".checking-status-message").css("visibility", "visible");
			});
		},
		available : function(){
			this.$("#maintenance_info").hide();
			this.$("#ok_info").show();
			this.stopPolling();
		},
		systemMaintenance : function(message){
			this.$("#maintenance_message").html(systemMaintenanceTPL({message : message}));
			this.startPolling();
		},
		systemError : function(jqXHR){
			var data = {
				status : jqXHR.status,
				message : jqXHR.statusText
			};
			if(jqXHR.response){
				data.message   = _.escapeHTML(jqXHR.response.message) || jqXHR.statusText;
				data.errorCode = jqXHR.response.errorCode;
			}

			this.$("#maintenance_message").html(systemErrorTPL(data));
			this.startPolling();
		},
		tenantMaintenance : function(message, tenantInfo){

			var tenant = (tenantInfo && tenantInfo.tenant) || {displayName : App.getTenant()};

			var supportOptions = (tenantInfo && tenantInfo.supportOptions) || [];
			var supportOptionsCollection = new SupportOption.Collection(supportOptions || []);


			var data = {
				message        : message,
				tenant         : tenant,
				supportOptions : supportOptionsCollection.toHandlebars()
			};

			this.$("#maintenance_message").html(tenantMaintenanceTPL(data));

			this.startPolling();
		}
	});

});

