/**
 * App Class
 *
 * Globally accessible app class. Used for session/local storage, holding global
 * configuration values, and invoking the Router
 *
 * It also assigns the serverBase for models/collections.
 */
/*globals global */
define('library/vlp/app',['require','jquery','underscore','backbone','library/vlp/model','library/vlp/collection','utilities/js-logger','utilities/analytics','utilities/one-trust','library/vlp/push','utilities/browser','utilities/storage','utilities/locale','utilities/captcha','library/utilities/log','library/vlp/vlp-global','library/underscore/extend-recursive'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection"),
	    JSLogger       = require("utilities/js-logger"),
	    Analytics      = require("utilities/analytics"),
	    OneTrust       = require("utilities/one-trust"),
	    Push           = require("library/vlp/push"),
	    Browser        = require("utilities/browser"),
	    Storage        = require("utilities/storage"),
	    Locale         = require("utilities/locale"),
	    Captcha        = require("utilities/captcha"),
	    Log            = require("library/utilities/log"),
	    VlpGlobal      = require("library/vlp/vlp-global");

	require("library/underscore/extend-recursive");

	return (function(){

		// Save a reference to the global object (`window` in the browser, `global`
		// on the server).
		var root = window || global;

		root.App = _.extend({

			unloading     : false,
			router        : null,

			config        : {},
			mode          : "development",
			state         : "uninitialized",
			urlRoot       : "/",
			globalParams  : {},

			Models        : {},
			Views         : {},

			init : function(router, config){
				var self = this;
				_.bindAll(this);
				this.startUrl = window.location.href;
				this.startTime = Date.now();

				this.setState("initializing");
				this.id = this.makeId();

				_.extendRecursive(this.config, config);
				this.setMode(this.config.mode);

				this.router = router;
				this.router.pushState = true;

				$(window).unbind("beforeunload.app").bind("beforeunload.app", function(){
					self.unloading = true;
				});

				$(document).off("click.app").on("click.app", "a:not([href^=mailto],[data-bypass],[data-toggle],[data-target])", this.linkClickHandler);

				this._setupServerBase();
				this._setupStorage();
				this._setupLog();
				this._setupLocale();
				this._setupAnalytics();
				this._setupJsLogger();
				this._setupGlobalParams();
				this._setupUrlRoot();
				this._setupCaptcha();
				this._setupVlpGlobal();

				this.setState("initialized");
			},
			linkClickHandler : function(event){
				try{
					var $link = $(event.currentTarget);
					if($link.attr("href")){
						var urlInfo = _.parseURL($(event.currentTarget).attr("href"));
						var domain = urlInfo.host + (urlInfo.port ? ":" + urlInfo.port : "");
						var thisDomain = window.self.location.host;

						if($link.attr("target") == "_blank"){
							this.redirecting = true;
						} else if(thisDomain === domain && urlInfo.path.indexOf(this.urlRoot) === 0) {
							event.preventDefault();
							Backbone.history.navigate(urlInfo.path.substr(this.urlRoot.length), true);
						} else if(thisDomain === domain && urlInfo.path.indexOf(this.config.appPath) === 0 && window.self.location.pathname !== urlInfo.path){
							this.redirecting = true;
						}
					}
				} catch(e){}
			},

			setMode : function(mode){
				if(!mode) { return; }
				this.mode = mode;

				if(this.jsLogger){
					this.jsLogger.setMode(this.mode);
				}
				if(this.analytics){
					this.analytics.set("mode", this.mode);
				}
				if(this.mode === "development"){
					this.test();
				}
			},
			setState : function(value){
				this.state = value;
			},
			run : function(){
				console.warn("Override me in the html file");
			},
			makeId : function(){
				var id = String(Date.now()).substr(-3);
				var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456798";
				for(var i = 0; i < 6; i++){
					id+= chars[_.random(chars.length - 1)];
				}
				return id;
			},
			setGlobalParam : function(param, value){
				BaseModel.prototype.syncParams = BaseModel.prototype.syncParams || {};
				BaseModel.prototype.syncParams[param] = value;
				BaseCollection.prototype.syncParams = BaseCollection.prototype.syncParams || {};
				BaseCollection.prototype.syncParams[param] = value;
				Push.prototype.requestParams = Push.prototype.requestParams || {};
				Push.prototype.requestParams[param] = value;
				if(this.push){
					this.push.requestParams = Push.prototype.requestParams || {};
					this.push.requestParams[param] = value;
				}

				this.globalParams[param] = value;
			},
			getTenantFromURL : function(path, keepCase){
				if(!path){
					path = window.self.location.pathname;
				} else{
					var parsed = _.parseURL(path);
					path = (parsed && parsed.path ? parsed.path : path);
				}
				path = path.replace(new RegExp("^" + this.config.appPath), "");

				path = path.replace(/\/$/, "");


				var tenantPath = "";
				var tempPathParts = path.split("/");
				var pathParts = [];


				for(var i = 0; i < tempPathParts.length; i++){
					var part = tempPathParts[i];
					var partLowerCase = part.toLowerCase();
					if(_.contains(this.config.pages, partLowerCase)){
						break;
					}
					if(part !== ""){
						if(keepCase) {
							pathParts.push(part);
						} else{
							pathParts.push(partLowerCase);
						}
					}
				}

				if(pathParts.length === 1){
					tenantPath = pathParts.pop();
				} else if(pathParts.length > 1){
					tenantPath = pathParts[pathParts.length - 2];
				}

				return tenantPath;
			},
			getTenant : function(){
				if(this.config.tenant){
					return this.config.tenant;
				}
				var tenant = this.getTenantFromURL();

				if(!tenant){
					tenant = this.store.get("stationTenant");
				}

				if(!tenant){
					//tenant = this.config.defaultTenantPath;
					tenant = "";
				}
				return tenant;
			},
			makeUrl : function(options){
				options = options || {};

				var page       = options.page || "";
				var tenantPath = null;
				if(options.hasOwnProperty("tenant")){
					tenantPath = options.tenant;
				} else{
					tenantPath = options.tenantPath || this.getTenant();
				}
				var action     = options.action || null;

				if(action && !page){
					page = this.currentPage();
				}

				if(this.config.pages && this.config.pages.hasOwnProperty(page)){
					page = this.config.pages[page];
				}

				var a = document.createElement("a");
				a.href = page;

				if(options.params){
					var newParams = _.trim(_.isObject(options.params) ? $.param(options.params) : options.params, "?&");
					if(newParams.length){
						a.search = (a.search ? a.search + "&" : "?") + newParams;
					}
				}
				if(options.hash){
					a.hash = (options.hash[0] === "#" ? options.hash : "#" + options.hash);
				}

				page = page.replace(/[\?|#].*/, "") + a.search + a.hash;

				if(page.match(/^(https?:|\/\/)/)) { return _.sanitizeURL(page); }


				var path = this.config.appPath;
				page = page.replace(new RegExp("^" + this.config.appPath), "");

				if(tenantPath){

					var urlTenant = this.getTenantFromURL(null, true);
					if(tenantPath.toLowerCase() === urlTenant.toLowerCase()){
						tenantPath = urlTenant;
					}

					path += tenantPath + "/";
					page = page.replace(new RegExp("^" + tenantPath.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + "/"), "");
				}

				path += _.ltrim(page, "/");
				if(action){
					if(_.isArray(action)){
						action = action.join("/");
					}
					path+= "/" + action;
				}
				if(options.forceHost){
					path = window.location.protocol + "//" + window.location.host + path;
				}

				if(!options.noVariables && this.VlpGlobal){
					path = this.VlpGlobal.replaceVariables(path);
				}
				return _.sanitizeURL(path);
			},
			currentPage : function(path){
				if(!path){
					if(this.urlRoot){
						path = this.urlRoot;
					} else {
						path = window.self.location.pathname;

					}
				}
				path = path.replace(new RegExp("^" + this.config.appPath), "");

				path = path.replace(/\/$/, "");

				var pages = _.values(this.config.pages);
				for(var index in pages){
					if(pages.hasOwnProperty(index)) {
						var page = pages[index];
						if (!page) {
							continue;
						}
						var regex = new RegExp(page + "$");
						if (path.match(regex)) {
							return page;
						}
					}
				}

				return this.config.pages.HOME;
			},

			home : function(){
				this.redirect({page : this.config.pages.HOME});
			},

			redirect : function(options){
				if(_.isString(options)){
					options = { page : options};
				}

				var url = this.makeUrl(options);
				if(url){
					try{

						var noHash = url.split("#")[0];
						if(url.match(/^http/) && noHash !== window.self.location.href.split("#")[0]){
							this.redirecting = true;
						} else if(url.match(/^\//) && noHash !== window.self.location.pathname){
							this.redirecting = true;
						}
					} catch(e){}
				}
				if(options.force){
					Backbone.history.stop();
				}
				window.self.location = _.sanitizeURL(url);
			},

			reload : function(){
				this.redirecting = true;
				window.self.location.reload();
			},
			replaceState : function(options){
				if(_.isString(options)){
					options = {page : options};
				}

				var url = this.makeUrl(options);
				if(Backbone.history.history.replaceState){
					Backbone.history.history.replaceState({}, document.title, url);
				} else {
					this.redirect(url);
				}
			},
			changeTenant : function(tenant){
				var pathParts = window.self.location.pathname.split("/");
				if(pathParts.length === 0){
					this.redirect({tenant: tenant});
				}
				if(pathParts[0] === ""){
					pathParts.shift();
				}


				if(pathParts[0].toLowerCase() === this.getTenantFromURL()){
					pathParts.shift();
					pathParts.unshift(tenant);
				}
				this.redirect({page: "/" + pathParts.join("/"), tenant : tenant});
			},
			generalMessage : function(info){
				if(this.mainView && this.mainView.generalMessage){
					this.mainView.generalMessage(info);
				} else{
					if(info.type === "error" || info.type === undefined){
						alert(info.message);
					}
					if(info.callback){
						info.callback();
					}
				}
			},
			jq : function(){
				//Call in prod to restore jquery to global scope for browser dev console.
				window.$ = $;
			},
			_ : function(){
				//Call in prod to restore jquery to global scope for browser dev console.
				window._ = _;
			},
			test: function(){
				this.jq();
				this._();
			},
			_setupLog : function(){
				if(!this.logger){
					this.logger = new Log(this);
				}
			},
			_setupServerBase : function(){
				this.config.serverBase  = this.config.coreUrl + this.config.coreApiPath;
				this.config.pushBase    = this.config.websockethubUrl;
				this.config.contentBase = this.config.contentUrl + this.config.contentApiPath;
				this.config.authBase    = this.config.authUrl + this.config.authApiPath;

				for(var i = 0; i < this.config.domainWhiteList.length; i++){
					var domain = this.config.domainWhiteList[i];
					if (_.endsWith(window.location.hostname, domain)) {
						var coreNameParts = window.location.hostname.split(".");
						var wsNameParts = window.location.hostname.split(".");
						var authNameParts = window.location.hostname.split(".");
						if (window.location.hostname === domain) {
							coreNameParts.unshift("core");
							wsNameParts.unshift("ws");
							authNameParts.unshift("login");
						} else {
							var subDomainEnvironment = coreNameParts[0].split("-")[1];
							coreNameParts[0] = (subDomainEnvironment ? "core-" + subDomainEnvironment : "core");
							wsNameParts[0] = (subDomainEnvironment ? "ws-" + subDomainEnvironment : "ws");
							authNameParts[0] = (subDomainEnvironment ? "login-" + subDomainEnvironment : "login");
						}
						var serverBase = document.createElement("a");
						serverBase.href = this.config.serverBase;
						serverBase.hostname = coreNameParts.join(".");
						this.config.serverBase = _.sanitizeURL(serverBase.href);

						var pushBase = document.createElement("a");
						pushBase.href = this.config.pushBase;
						pushBase.hostname = wsNameParts.join(".");
						this.config.pushBase = _.sanitizeURL(pushBase.href.replace(/\/^/, ""));

						var authBase = document.createElement("a");
						authBase.href = this.config.authBase;
						authBase.hostname = authNameParts.join(".");
						this.config.authBase = _.sanitizeURL(authBase.href.replace(/\/^/, ""));
						break;
					}
				}
			},
			_setupUrlRoot : function(){
				var root = this.config.appPath;
				var tenant = this.getTenant();
				if(tenant){
					root+= tenant + "/";
				}
				if(this.router.routeBase){
					root+= this.router.routeBase + "/";
				}
				this.urlRoot = root;
			},
			_setupStorage : function(){
				if(!this.storage){
					this.storage = new Storage();
					this.store   = this.storage.store;
					this.session = this.storage.session;
					this.cookie  = this.storage.cookie;

					var self = this;

					this.storage.on("error", function(errorType){
						self.generalMessage({
							key  : "warnings." + errorType,
							type : "error"
						});
					});
				}

			},
			_setupLocale : function(){
				if(!this.locale){
					this.locale = new Locale(this.store, this.config.localeFullMap);
					this.locale.on("changed", function(newLocale){
						this.setGlobalParam("locale", newLocale);
						if(this.analytics){
							this.analytics.set("locale", newLocale);
						}
					}, this);
				}
				this.i18n = this.locale.i18n;
			},
			_setupJsLogger : function(){
				this.jsLogger = new JSLogger({
					mode    : this.config.mode,
					version : this.config.version,
					enabled : this.config.userUiClientLogging
				});
				this.jsLogger.addMetaData("tenant", {
					name : this.getTenant()
				});
			},
			_setupCaptcha : function(){
				this.captcha = new Captcha(this);
			},
			_setupVlpGlobal : function(){
				this.VlpGlobal = new VlpGlobal(this);
				root.VLP = this.VLP = this.VlpGlobal.publicClass;
			},
			_setupAnalytics : function(){

				var queryParts = _.parseQueryString();
				this.trackingCode = queryParts.tID || queryParts.tId || queryParts.tid || queryParts.trackingCode;


				this.analytics = new Analytics({
					mode         : this.config.mode,
					appVersion   : this.config.version,
					appName      : "user-ui",
					locale       : this.locale.get(),
					page         : window.self.location.pathname,
					trackingCode : this.trackingCode,
					tenant       : this.getTenant()
				});

				this.analytics.addService({type   : "trackingCode"});

				this.router.on("route", function(){
					this.analytics.set("page", window.self.location.pathname);
				}, this);
				this.oneTrust = new OneTrust();

			},
			_setupGlobalParams : function(){
				this.browserId = this.store.get("browserId") || this.makeId();
				this.store.set("browserId", this.browserId);

				BaseModel.prototype.serverBase      = this.config.serverBase;
				BaseCollection.prototype.serverBase = this.config.serverBase;

				BaseModel.prototype.eventUrl      = this.config.eventUrl;
				BaseCollection.prototype.eventUrl = this.config.eventUrl;

				this.setGlobalParam(this.config.senderParameter, this.id);
				this.setGlobalParam(this.config.browserIdParameter, this.browserId);
				this.setGlobalParam("locale", this.locale.get());

				if(this.config.version){
					this.setGlobalParam("_v", this.config.version);
				}
				if(Browser.trackingTag){
					this.setGlobalParam("_c", Browser.trackingTag);
				}
				if(this.trackingCode){
					this.setGlobalParam("trackingCode", this.trackingCode);
				}
			}
		}, Backbone.Events, root.App);

		Backbone.Relational.store.addModelScope( root.App.Models );
		return root.App;

	}).call(this);
});

