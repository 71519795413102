/**
 * Language Chooser Backbone View
 */
define('views/common/language-chooser',['require','jquery','underscore','library/vlp/app','library/vlp/view','nls/manifest','hbs!tpls/common/language-chooser.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App          = require("library/vlp/app"),
	    BaseView     = require("library/vlp/view"),
	    Translations = require("nls/manifest"),
	    tpl          = require("hbs!tpls/common/language-chooser.handlebars");

	return BaseView.extend({

		template : tpl,
		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		el : "#modal_language_chooser",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"submit form" : "onUpdate"
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 */
		initialize : function(){
			_.bindAll(this);
		},
		serialize : function(options){
			var activeLocale = App.store.get("locale") || App.locale.get();
			var data = {
				languages : _.map(App.config.locales,   function(label, key){
					return {
						labelKey   : "locales." + key.toLowerCase(),
						value      : key,
						selected   : activeLocale == key.toLowerCase(),
						manualOnly : (key != "default" && key != "en" && !Translations[key.toLowerCase()])
					};
				})
			};

			return data;
		},
		/**
		 * Display the content.
		 */
		render : function(options){

			this.$el.html(this.template(this.serialize()));

			//Always return this for chaining
			return this;
		},
		afterRender : function(){
			if($("#login_prompt.in").length){
				$("#modal_language_chooser .modal-body").outerHeight($("#login_prompt.in .modal-body").outerHeight());
				$("#modal_language_chooser .modal-footer").outerHeight($("#login_prompt.in .modal-footer").outerHeight());
				$("#modal_language_chooser").outerHeight($("#login_prompt.in").outerHeight());
			} else{
				$("#modal_language_chooser .modal-body").css("height", "");
				$("#modal_language_chooser .modal-footer").css("height", "");
				$("#modal_language_chooser").css("height", "");
			}
			$("#modal_language_chooser").off("hide.languages").on("hide.languages", this.closed);
			$("#modal_language_chooser").modal("reflow");
		},

		show : function(options){
			this.render();
			$("#modal_language_chooser").modal("show");
		},
		hide : function(){
			$("#modal_language_chooser").modal("hide");
			this.trigger("closed");
		},
		closed : function(){
			$("#modal_language_chooser").off("hide.languages", this.closed);
		},
		onUpdate : function(event){
			event.preventDefault();
			var language = this.$("#language").val();
			this.model.set("language", language);
			App.locale.set(this.model.get("locale"));

			if(this.model.get("loggedIn")){
				this.model.save({}, {
					properties : ["language"],
					success : function(){
						delete App.tempLocale;
						App.session.remove("tempLocale");
						App.analytics.trackEvent("Account", "Language Changed", App.locale.get());
					},
					loginCancelable : true
				});
			} else {
				App.tempLocale = this.model.get("locale");
			}
			this.hide();
		}

	});

});
