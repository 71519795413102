define('nls/root/compatibility',{
	"compatibility" : {
		"title"       : "Test Your Browser Compatibility",
		"description" : "VMware Lab Platform is a cutting edge application that uses some newer features that may not be supported by your browser. Run the tests below to check your browser's compatibility.",
		"runTests"    : "Run Tests",
		"running"     : "Running...",
		"pass"        : "Pass",
		"fail"        : "Fail",
		"disabled"    : "Disabled",

		"progressMessage" : "Running test %(currentNumber)s of %(totalTestsCount)s",
		"testsPassed"     : "All tests passed.",
		"testFailures"    : "%s potential issue(s). Check below.",

		"tests"       : {
			"cookies" : {
				"title"                    : "Cookies",
				"description"              : "Cookies are a basic feature of the web used on most web sites. This application uses cookies to keep you logged in and track your active session.",
				"success"                  : "Your browser has cookies enabled.",
				"failure"                  : "Cookie failure message.",
				"failureCallToAction"      : "Check your cookie settings.",
				"errorCookies"             : "Your browser does not have cookies enabled or this site is not allowed to set cookies. This app may not operate correctly if not corrected.",
				"errorCookiesCallToAction" : "Please enable cookies.",
				"errorThirdPartyCookies"   : "Your browser could not set and retrieve a cookie on this app's server. If not corrected, you will not stay logged into this app. Try activate third-party cookies if deactivated and verify the domain \"%(apiDomain)s\" is allowed to set cookies.",
				"errorThirdPartyCookiesCallToAction" : "Please check your cookie settings."
			},
			"resolution" : {
				"title"                         : "Window/Tab Size",
				"description"                   : "The labs run on this site require a reasonable amount of screen space to function properly. The minimum acceptable browser window/tab size is %(minWidth)s x %(minHeight)s.",
				"success"                       : "The current browser window/tab size is adequate.",
				"warningWindowSize"             : "The current browser window/tab size is too small. Please increase your browser window/tab size to %(minWidth)s x %(minHeight)s (current size is %(currentWidth)s x %(currentHeight)s).",
				"warningWindowSizeCallToAction" : "Resize your browser window/tab.",
				"warningMonitorResolution"      : "Your monitor resolution is too low. The minimum acceptable monitor resolution is %(minWidth)s x %(minHeight)s (your monitor resolution is %(monitorWidth)s x %(monitorHeight)s). Labs taking on this monitor might not fit properly. You might want to use this app on another computer."
			},
			"popups" : {
				"title"                     : "New Windows/Popups",
				"description"               : "A popup is a new browser window created without user interaction. Most browsers block popups by default. Some instruction manuals used in this site may open content in a new window popup.",
				"success"                   : "Popups are allowed for this site.",
				"warningPopups"             : "Your browser has popups deactivated for this site. Not all manuals use this feature, but by allowing popups for this site you will not encounter any potential issues with popups when taking a lab.",
				"warningPopupsCallToAction" : "Please allow popups for this site."
			},
			"storage" : {
				"title"                 : "Web Storage",
				"description"           : "Web Storage (localStorage and sessionStorage) an HTML5 feature that provides the ability to store some semi-persistent information on your computer. Web Storage is used throughout the application to save information that improves your experience.",
				"success"               : "Your browser has Web Storage enabled.",
				"failure"               : "Your browser does not have Web Storage enabled. If cookies are enabled, important values will be stored as cookies. Some features of this site might not operate correctly.",
				"failureCallToAction"   : "For an optimum experience, please enable Web Storage.",
				"warningLocalStorage"   : "Your browser has sessionStorage enabled, but localStorage is disabled/unsupported. sessionStorage will be used as a fallback for localStorage.",
				"warningSessionStorage" : "Your browser has localStorage enabled, but sessionStorage is disabled/unsupported. localStorage will be used as a fallback for sessionStorage."
			},
			"websockets" : {
				"title"                    : "WebSockets",
				"description"              : "WebSocket is an HTML5 feature enables websites to communicate over a full-duplex network channel.",
				"descriptionRequired"      : "This application requires WebSockets.",
				"success"                  : "Your browser supports WebSockets.",
				"failure"                  : "Your browser does not have proper support for WebSockets. This application will not work with your browser.",
				"failureCallToAction"      : "You must switch to a supported browser to use this site.",
				"warningIncompleteSupport" : "Although your browser does support WebSockets, its support is incomplete and will not work with this application."
			},
			"proxy" : {
				"title"                    : "Web Proxy",
				"description"              : "A Web Proxy is a server that acts as an intermediary for requests from clients (browsers) seeking resources from other servers. This application works best when a web proxy is not used.",
				"success"                  : "You do not appear to be behind a web proxy.",
				"failure"                  : "You appear to be behind a web proxy. This application may experience some issues when used behind a web proxy.",
				"failureCallToAction"      : "If you experience any issues with this application, try disabling your web proxy."
			},
			"canvas" : {
				"title"               : "Canvas",
				"description"         : "Canvas is an HTML5 feature that enables dynamic generation of images in your browser. This application requires Canvas.",
				"descriptionRequired" : "This application requires Canvas.",
				"success"             : "Your browser supports Canvas.",
				"failureCallToAction" : "You must switch to a supported browser to use this site.",
				"failure"             : "Your browser does not support Canvas."
			},
			"browser" : {
				"title"               : "Browser",
				"description"         : "Certain browsers have known incompatibilities that prevent this application from functioning properly.",
				"success"             : "Your browser is supported.",
				"failure"             : "Your browser is not supported.",
				"failureCallToAction" : "You must switch to a supported browser to use this site.",
				"warningMsie10"       : "Internet Explore 10 needs TLS 1.2 support enabled to work with this app. Click <a href=\"/unsupported/\">here</a> to read more."
			},
			"bandwidth" : {
				"title"       : "Network Connection",
				"failure"     : "Your connection speed may be too low.",
				"success"     : "Your connection is sufficient.",
				"errorCloudConnection"   : "Could not connect to the Cloud server.",
				"errorWebWorkers"        : "Could not run latency and bandwidth tests because your browser does not have WebWorker support.",
				"warningCloudConnection" : "Your connection to the Cloud server is not optimal.",
				"warningUIConnection"    : "Your connection to this application server is not optimal."
			}
		}
	},
	"errors"  : {
	},
	"title"   : "Test Your Browser Compatibility"
});

