/**
 * Catalog Router
 *
 * Code starts running here for the /catalogs page.
 */
define('routers/catalogs',['require','underscore','library/vlp/router','views/catalogs/main'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//class dependencies
	var BaseRouter = require("library/vlp/router"),
	    MainView   = require("views/catalogs/main");


	return BaseRouter.extend({
		routeBase : "catalogs",
		/**
		 * Constructor
		 *
		 * This code is called when new Router([options]) is called in the example.html.
		 *
		 * @param options Map of options (mostly passed on to new MainView([options]);
		 */
		initialize : function(config){

			this.setup(config);

			this.mainView = new MainView(config);

			this.start();
		},
		/**
		 * List of possible routes
		 */
		routes : {
			"catalogs/all"                             : "showCatalogAll",
			"catalog/all"                              : "showCatalogAll",
			"catalogs(/:catalogId)(/:section)(/:page)" : "showCatalog",
			"catalog(/:catalogId)(/:section)(/:page)"  : "showCatalog",

			"enrolled"                     : "showEnrolled",
			"enrollments(/:pageNum)"       : "showEnrolled",
			"enrollments"                  : "showEnrolled",
			"enrollments/lab/:labId"       : "showEnrolledLab",
			"enrollments/lab/"             : "showEnrolled",
			"enrollments/course/:courseId" : "showEnrolledCourse",
			"enrollments/course/"          : "showEnrolled",

			"lab/:labId" : "showLab",

			"transcripts"                 : "showTranscript",
			"transcript/lab/:labId"       : "showTranscriptLab",
			"transcript/lab/"             : "showTranscript",
			"transcript/course/:courseId" : "showTranscriptCourse",
			"transcript/course/"          : "showTranscript",
			"transcript/*splat"           : "showTranscript",
			"transcript"                  : "showTranscript",

			"*actions" : "index"
		},
		/**
		 * Default (initial) route. Loads first step.
		 */
		index : function(){
			this.mainView.showCatalog(null);
		},
		/**
		 * Show Courses/Labs from a specific Catalog
		 */
		showCatalog : function(catalogId, section, pageNum){
			this.mainView.showCatalog(catalogId, section, pageNum);
		},
		showCatalogAll : function(){
			this.mainView.showCatalog("all");
		},
		showLab : function(labId){
			this.mainView.showLab(labId);
		},
		/**
		 * Show Courses/Labs currently enrolled
		 */
		showEnrolled : function(pageNum){
			if(pageNum === null || pageNum === undefined){
				pageNum = 1;
			}
			this.mainView.showEnrolled(pageNum);
		},
		/**
		 * Show Courses/Labs currently enrolled
		 */
		showEnrolledLab : function(labId){
			this.mainView.showEnrolledLab(labId);
		},
		/**
		 * Show Courses/Labs currently enrolled
		 */
		showEnrolledCourse : function(courseId){
			this.mainView.showEnrolledCourse(courseId);
		},
		/**
		 * Show Courses/Labs completed
		 */
		showTranscript : function(options){
			options = options || "";

			var searchMatch = options.match(/search\/(.+)\/?/);
			var search = "";
			if(searchMatch){
				search = _.rtrim(searchMatch[1], "/");
				options = options.replace(searchMatch[0], "");
			}
			var pageNum = parseInt(options, 10);
			if(pageNum === null || isNaN(pageNum)){
				pageNum = 1;
			}

			this.mainView.showTranscript(pageNum, search);
		},
		/**
		 * Show Courses/Labs completed
		 */
		showTranscriptCourse : function(courseId){
			this.mainView.showTranscriptCourse(courseId);
		},
		/**
		 * Show Courses/Labs completed
		 */
		showTranscriptLab : function(labId){
			this.mainView.showTranscriptLab(labId);
		}
	});
});
