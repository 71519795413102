define('models/system',['require','exports','module','jquery','underscore','backbone','library/vlp/app','library/vlp/model'],function (require, exports) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model");


	//Use exports for requirejs circular dependency resolving
	var System = exports;

	//Add System to the App.Models namespace
	App.Models.System = System;


	System.Model = BaseModel.extend({
		urlRoot : "/system",

		/**
		 * Default values for empty Models (new System.Model())
		 */
		defaults: {
			ajaxTimeout                         : 60000,
			allowStudentSSOLogin                : false,
			apiRetryDelay                       : 10000,
			dashboardCycleInterval              : 8000,
			disableEndButtonUntilComplete       : false,
			disableStudentLocalLogin            : false,
			eulaEnabled                         : false,
			eventPromoCycleInterval             : 8000,
			itemsPerPage                        : 20,
			labCompleteTimeout                  : 60000,
			maintenanceFrequency                : 60000,
			progressFrequency                   : 60000,
			pushReconnectDelay                  : 5000,
			pushTimeout                         : 30000,
			recaptchaEnabled                    : false,
			requirePasswordForLogin             : true,
			screenTicketDelay                   : 5000,
			screenTicketMaxTries                : 3,
			syncActivityTimeout                 : 30000,
			tenantId                            : 1000,
			thumbnailFrequency                  : 180000,
			timeRemainingDanger                 : 60000,
			timeRemainingWarning                : 180000,
			useCaptcha                          : true,
			userIdleCountDown                   : 120000,
			userIdleDelay                       : 600000,
			vmConnectionWait                    : 10000
		},
		types: {
			allowAnonymousBrowsing                     : Backbone.Types.Boolean,
			allowInstructorToResetLab                  : Backbone.Types.Boolean,
			allowInstructorToSendNewToken              : Backbone.Types.Boolean,
			allowRawManualStyles                       : Backbone.Types.Boolean,
			allowRegistration                          : Backbone.Types.Boolean,
			allowResize                                : Backbone.Types.Boolean,
			allowStudentSSOLogin                       : Backbone.Types.Boolean,
			allowTenantSwitching                       : Backbone.Types.Boolean,
			announcements                              : Backbone.Types.Boolean,
			apiRetryLimit                              : Backbone.Types.Number,
			atmosphereTimeout                          : Backbone.Types.Number,
			catalogInfiniteScrolling                   : Backbone.Types.Boolean,
			compactPanelTabs                           : Backbone.Types.Boolean,
			compatibilityCheckerCheckBandwidth         : Backbone.Types.Boolean,
			compatibilityCheckerCheckPopups            : Backbone.Types.Boolean,
			compatibilityCheckerCheckProxy             : Backbone.Types.Boolean,
			compatibilityCheckerCheckResolution        : Backbone.Types.Boolean,
			compatibilityMinWidth                      : Backbone.Types.Number,
			compatibilityMinHeight                     : Backbone.Types.Number,
			consoleKeyboardUseAltManager               : Backbone.Types.Boolean,
			consoleRemoteManualSyncingEnabled          : Backbone.Types.Boolean,
			debug                                      : Backbone.Types.Boolean,
			disableEndButtonUntilComplete              : Backbone.Types.Boolean,
			disableStudentLocalLogin                   : Backbone.Types.Boolean,
			expandedConsoleShortcuts                   : Backbone.Types.Boolean,
			eulaEnabled                                : Backbone.Types.Boolean,
			eventMode                                  : Backbone.Types.Boolean,
			expandCatalogLabDetails                    : Backbone.Types.Boolean,
			expiresWarning                             : Backbone.Types.Number,
			fetchManualsFromContent                    : Backbone.Types.Boolean,
			helpRequestSubmissionDelay                 : Backbone.Types.Number,
			instructionsPanelLocation                  : Backbone.Types.String,
			instructorCanConnectToInactiveEntitlements : Backbone.Types.Boolean,
			labCompletedRequiresGrading                : Backbone.Types.String,
			labCompletedThankYou                       : Backbone.Types.String,
			labCompletedTitle                          : Backbone.Types.String,
			labCompletedTitleGrading                   : Backbone.Types.String,
			labCompletionCriteriaNotMet                : Backbone.Types.String,
			labCompletionCriteriaNotMetGraded          : Backbone.Types.String,
			labEndMessage                              : Backbone.Types.String,
			labEndMessageNoExit                        : Backbone.Types.String,
			labEndTitle                                : Backbone.Types.String,
			labExitMessage                             : Backbone.Types.String,
			labExitMessageNoPoweroff                   : Backbone.Types.String,
			labExitTitle                               : Backbone.Types.String,
			labResetConfirmation                       : Backbone.Types.String,
			labResetMessage                            : Backbone.Types.String,
			labResetTitle                              : Backbone.Types.String,
			labResetWarning                            : Backbone.Types.String,
			liveChat                                   : Backbone.Types.Boolean,
			concurrentEntitlementsLimit                : Backbone.Types.Boolean,
			maintenanceFrequency                       : Backbone.Types.Number,
			maxConcurrentUserEntitlementsPerLab        : Backbone.Types.Number,
			maximizeCompactPanelTabs                   : Backbone.Types.Boolean,
			maximizeOnFullscreen                       : Backbone.Types.Boolean,
			offers                                     : Backbone.Types.Boolean,
			oneTrustEnabled                            : Backbone.Types.Boolean,
			pushDisconnectedSendBuffer                 : Backbone.Types.Boolean,
			pushPingInterval                           : Backbone.Types.Number,
			pushReconnectAttemptCount                  : Backbone.Types.Number,
			pushReconnectDelay                         : Backbone.Types.Number,
			pushStationPingInterval                    : Backbone.Types.Number,
			pushTimeoutRetries                         : Backbone.Types.Number,
			ratingEnabled                              : Backbone.Types.Boolean,
			recaptchaEnabled                           : Backbone.Types.Boolean,
			requirePasswordForLogin                    : Backbone.Types.Boolean,
			saveConsolePanelPlacement                  : Backbone.Types.Boolean,
			screenSaverIdleDelay                       : Backbone.Types.Number,
			screenSaverRedirectDelay                   : Backbone.Types.Number,
			screenTicketPushReturn                     : Backbone.Types.Boolean,
			showCatalog                                : Backbone.Types.Boolean,
			showCertificate                            : Backbone.Types.Boolean,
			showHelpCenter                             : Backbone.Types.Boolean,
			showInvite                                 : Backbone.Types.Boolean,
			showConsoleButtons                         : Backbone.Types.Boolean,
			showNewLogo                                : Backbone.Types.Boolean,
			showVmNetworkInfo                          : Backbone.Types.Boolean,
			showRemoteControl                          : Backbone.Types.Boolean,
			showPrepopBadge                            : Backbone.Types.Boolean,
			showPrepopWarning                          : Backbone.Types.Boolean,
			showVlpInBetaMsg                           : Backbone.Types.Boolean,
			speedTestTargetDownloadSize                : Backbone.Types.Number,
			syncActivityTimeout                        : Backbone.Types.Number,
			systemMessagesEnabled                      : Backbone.Types.Boolean,
			thumbnailCheckDelay                        : Backbone.Types.Number,
			timeRemainingDiffCountdown                 : Backbone.Types.Number,
			timeRemainingDiffRefresh                   : Backbone.Types.Number,
			useCaptcha                                 : Backbone.Types.Boolean,
			userIdleCountDown                          : Backbone.Types.Number,
			userIdleDelay                              : Backbone.Types.Number,
			videoConferencePanelLocation               : Backbone.Types.String,
			vmListingPanelLocation                     : Backbone.Types.String,
			consoleConnectionMonitoring                : Backbone.Types.Boolean,
			consoleConnectionMonitoringFrequency       : Backbone.Types.Number,
			consoleConnectionDisconnectTimeout         : Backbone.Types.Number,
			consoleConnectionDisconnectVerifyTimeout   : Backbone.Types.Number,
			consoleConnectionDisconnectRefreshTimeout  : Backbone.Types.Number,
			consoleConnectionLatencyRescheduleTimeout  : Backbone.Types.Number,
			consoleConnectionLatencyRescheduleMaxCount : Backbone.Types.Number,
			consoleConnectionLatencyUnstableTimeout    : Backbone.Types.Number,
			consoleConnectionLatencyWarningThreshold   : Backbone.Types.Number,
			consoleConnectionLatencyWarningCheckCount  : Backbone.Types.Number,
			showConsoleConnectionStrength              : Backbone.Types.Boolean,
			instructorScreenTicketStaleRefreshTimeout  : Backbone.Types.Number,
			speedTestDownloadWorkers                   : Backbone.Types.Number,
			speedTestDownloadDuration                  : Backbone.Types.Number,
			speedTestLatencyTimeout                    : Backbone.Types.Number,
			speedTestDownloadTimeout                   : Backbone.Types.Number,
			broadcastDisplayEnabled                    : Backbone.Types.Boolean
		},
		/**
		 * Constructor
		 */
		initialize : function(){
		},
		parse : function(){
			var result = BaseModel.prototype.parse.apply(this, arguments);
			result.eulaEnabled = result.EULAEnabled;
			delete result.EULAEnabled;

			return result;
		},
		setters : {
			liveChatUrl : function(value){
				if(!value || value === "null") { return ""; }

				if(!value.match(/^http/) && value.match(/http[^""]+/)){
					value = value.match(/http[^""]+/)[0];
				}
				return value;
			},
			progressFrequency : function(value){
				value = _.toNumber(value);
				if(value < 60000){
					value = this.defaults.progressFrequency;
				}
				return value;
			},
			itemsPerPage : function(value){
				value = _.toNumber(value);
				if(value < 0){
					value = this.defaults.itemsPerPage;
				}
				return value;
			},
			screenTicketDelay : function(value){
				value = _.toNumber(value);
				if(value < 33){
					value = this.defaults.screenTicketDelay;
				}
				return value;
			},
			vmConnectionWait : function(value){
				value = _.toNumber(value);
				if(value < 33){
					value = this.defaults.vmConnectionWait;
				}
				return value;
			},

			screenTicketMaxTries : function(value){
				value = _.toNumber(value);
				if(value < 1){
					value = this.defaults.screenTicketMaxTries;
				}
				return value;
			},

			thumbnailFrequency : function(value){
				value = _.toNumber(value);
				if(value < 33){
					value = this.defaults.thumbnailFrequency;
				}
				return value;
			},
			timeRemainingDanger : function(value){
				value = _.toNumber(value);
				if(value < 33){
					value = this.defaults.timeRemainingDanger;
				}
				return value;
			},

			timeRemainingWarning : function(value){
				value = _.toNumber(value);
				if(value < 33){
					value = this.defaults.timeRemainingWarning;
				}
				return value;
			},
			dashboardCycleInterval : function(value){
				value = _.toNumber(value);
				if(value < 33){
					value = this.defaults.dashboardCycleInterval;
				}
				return value;
			},

			eventPromoCycleInterval : function(value){
				value = _.toNumber(value);
				if(value < 33){
					value = this.defaults.eventPromoCycleInterval;
				}
				return value;
			},
			labCompleteTimeout : function(value){
				value = _.toNumber(value);
				if(value === 0){
					return value;
				}
				if(value < 33){
					value = this.defaults.labCompleteTimeout;
				}
				return value;
			},
			apiRetryDelay : function(value){
				value = _.toNumber(value);
				if(value < 33){
					value = this.defaults.apiRetryDelay;
				}
				return _.toNumber(value);
			},
			ajaxTimeout : function(value){
				value = _.toNumber(value);
				if(value < 33){
					value = this.defaults.ajaxTimeout;
				}
				return value;
			},
			pushTimeout : function(value){
				value = _.toNumber(value);
				if(value < 33){

					value = this.defaults.pushTimeout;
				}
				return value;
			},

			anonymousLanguage : function(value){
				return String(value).toLowerCase();
			},
			maxConcurrentUserEntitlementsPerLab: function(value){
				if(value <= 0){
					value = 1;
				}
				return value;
			},
			consoleConnectionDisconnectTimeout : function(value){
				value = Math.max(_.toNumber(value), 0);
				if(value !== 0 && value < App.config.consoleConnectionDisconnectTimeoutMin){
					value = App.config.consoleConnectionDisconnectTimeoutMin;
				}
				return value;
			},
			consoleConnectionMonitoringFrequency : function(value){
				return Math.max(_.toNumber(value), App.config.consoleConnectionMonitoringFrequencyMin);
			},
			consoleConnectionDisconnectVerifyTimeout : function(value){
				return Math.max(_.toNumber(value), App.config.consoleConnectionDisconnectVerifyTimeoutMin);
			},
			consoleConnectionDisconnectRefreshTimeout : function(value){
				return Math.max(_.toNumber(value), App.config.consoleConnectionDisconnectRefreshTimeoutMin);
			},
			consoleConnectionLatencyRescheduleTimeout : function(value){
				return Math.max(_.toNumber(value), App.config.consoleConnectionLatencyRescheduleTimeoutMin);
			},
			consoleConnectionLatencyRescheduleMaxCount : function(value){
				return Math.max(_.toNumber(value), 0);
			},
			consoleConnectionLatencyUnstableTimeout : function(value){
				value = Math.max(_.toNumber(value), 0);
				if(value !== 0 && value < App.config.consoleConnectionLatencyUnstableTimeoutMin){
					value = App.config.consoleConnectionLatencyUnstableTimeoutMin;
				}
				return value;
			},
			consoleConnectionLatencyWarningThreshold : function(value){
				value = Math.max(_.toNumber(value), 0);
				if(value !== 0 && value < App.config.consoleConnectionLatencyWarningThresholdMin){
					value = App.config.consoleConnectionLatencyWarningThresholdMin;
				}
				return value;
			},
			consoleConnectionLatencyWarningCheckCount : function(value){
				return Math.max(_.toNumber(value), App.config.consoleConnectionLatencyWarningCheckCountMin);
			},
			instructorScreenTicketStaleRefreshTimeout : function(value){
				value = Math.max(_.toNumber(value), 0);
				if(value !== 0 && value < App.config.instructorScreenTicketStaleRefreshTimeoutMin){
					value = App.config.instructorScreenTicketStaleRefreshTimeoutMin;
				}
				return value;
			}
		},

		fetchCloudURLBase : function(options){
			var queryParts = _.parseQueryString();
			var queryCloudHost = queryParts.cloudHost;

			options = options || {};
			options.type = "GET";
			options.url = App.config.serverBase + "compatibility/cloudServers?tenant=" + (App.getTenant() || App.config.defaultTenantPath);

			options.contentType = "application/json";
			var success = options.success;

			options.success = function(data){
				var speedTestCloudUrls = [];
				if (queryCloudHost) {
					data.data = [queryCloudHost];
				}
				if(data.data && data.data !== "null"){
					for (var i = 0; i < data.data.length; i++) {
						var cloudFile = "//" + data.data[i] + App.config.speedTestCloudPath;
						cloudFile = cloudFile.replace(/^\/\//, "https://");
						cloudFile = cloudFile.replace(/^http:\/\//, "https://");
						speedTestCloudUrls.push(cloudFile);
					}
				}
				if (speedTestCloudUrls.length > 0) {
					App.config.speedTestCloudUrls = speedTestCloudUrls;
				}

				if(success){
					success(data);
				}
			};

			return $.ajax(options);
		}
	});

});

