
define('hbs!tpls/dashboard/default-content.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    return "<div class=\"item promo\">\n	<div class=\"widget-wrapper\">\n		<div class=\"header-bar\">\n			<h3><span class=\"fa fa-dashboard skin-icon\"></span> "
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"dashboard.widgets.realTime.title",{"name":"$","hash":{},"data":data}))
    + "</h3>\n		</div>\n		<div class=\"content\">\n			<widget type=\"speedometer\"\n					data-source=\"vms\"\n					data-source-component=\"core\"\n					data-min-Value1=\"0\" data-max-value1=\"100\"\n					data-min-value2=\"0\" data-max-value2=\"1000\"\n					data-value1-key=\"realtime\" data-value2-key=\"historical\"\n					data-realtime=\"0\" data-historical=\"0\"\n					data-auto-rescale=\"true\"\n					/>\n\n			<widget type=\"speedometer\"\n					data-source=\"labs\"\n					data-source-component=\"core\"\n					data-min-Value1=\"0\" data-max-value1=\"100\"\n					data-min-value2=\"0\" data-max-value2=\"1000\"\n					data-value1-key=\"realtime\" data-value2-key=\"historical\"\n					data-realtime=\"0\" data-historical=\"0\"\n					data-auto-rescale=\"true\"\n					/>\n		</div>\n	</div>\n</div>\n<div class=\"item\">\n	<div class=\"widget-wrapper widget-block-wrapper\">\n		<div class=\"header-bar\">\n			<h3><span class=\"fa fa-thumbs-o-up skin-icon\"></span> "
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"dashboard.widgets.popularLabs.title",{"name":"$","hash":{},"data":data}))
    + "</h3>\n		</div>\n		<div class=\"content\">\n		<widget type=\"bar\"\n				data-source=\"popularlabs\"\n				data-source-component=\"core\"\n				data-label=\"Taken Count\"\n				data-entries-key=\"values\"\n				data-name-key=\"name\"\n				data-value-key=\"total\"\n				data-auto-sort=\"true\"\n				data-item-limit=\"6\"\n				/>\n		</div>\n	</div>\n</div>\n<div class=\"item\">\n	<div class=\"widget-wrapper widget-block-wrapper\">\n		<div class=\"header-bar\">\n		<h3><span class=\"fa fa-thumbs-o-up skin-icon\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"dashboard.widgets.helpRequestsPerLab.title",{"name":"$","hash":{},"data":data}))
    + "</h3>\n		</div>\n		<div class=\"content\">\n			<widget type=\"bar\"\n					data-source=\"help-requests-stats\"\n					data-source-component=\"core\"\n					data-label=\"Taken Count\"\n					data-entries-key=\"values\"\n					data-name-key=\"name\"\n					data-value-key=\"total\"\n					data-auto-sort=\"true\"\n					data-item-limit=\"6\"\n			/>\n		</div>\n	</div>\n</div>\n<div class=\"item\">\n	<div class=\"widget-wrapper\">\n		<div class=\"header-bar\">\n			<h3><span class=\"fa fa-signal skin-icon\"></span> "
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"dashboard.widgets.averageLabsTaken.title",{"name":"$","hash":{},"data":data}))
    + "</h3>\n		</div>\n		<div class=\"content\">\n		<widget type=\"pie\"\n				data-source=\"averagetaken\"\n				data-source-component=\"core\"\n				data-label=\"Percent\"\n				data-other-label=\"Other\"\n				data-entries-key=\"values\"\n				data-value-key=\"values\"\n				data-name-key=\"name\"\n				data-item-limit=\"8\"\n				/>\n		</div>\n	</div>\n</div>\n\n<div class=\"item\">\n	<div class=\"widget-wrapper widget-block-wrapper\">\n		<div class=\"header-bar\">\n			<h3><span class=\"fa fa-question skin-icon\"></span> "
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"dashboard.widgets.helpRequests.title",{"name":"$","hash":{},"data":data}))
    + "</h3>\n		</div>\n		<div class=\"content\">\n		<widget type=\"help-requests\"\n				data-source=\"help-requests\"\n				data-source-component=\"core\"\n				data-title=\"Help Requests\"\n				data-value-key=\"requests\"\n				data-label=\"Station\"\n				data-empty-text=\"No Requests\"\n				data-item-limit=\"30\"\n				/>\n		</div>\n	</div>\n</div>";
},"useData":true});
Handlebars.registerPartial('dashboard_default_content', t);
return t;
});

