
define('hbs!tpls/common/switch-tenant-modal.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var helper;

  return "<div id=\"modal_switch_tenant\" class=\"modal fluid-height fade global hide\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<h3 class=\"modal-title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"switchTenant.title",{"name":"$","hash":{"tenant":(depth0 != null ? depth0.tenant : depth0)},"data":data}))
    + "</h3>\n			</div>\n			<div class=\"modal-body\">\n				<p>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"switchTenant.body",{"name":"$","hash":{"otherTenant":(depth0 != null ? depth0.otherTenant : depth0),"tenant":(depth0 != null ? depth0.tenant : depth0)},"data":data}))
    + "</p>\n				<p>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"switchTenant.options",{"name":"$","hash":{},"data":data}))
    + "\n					<ul>\n						<li><button type=\"button\" id=\"logout\" class=\"btn btn-large btn-primary\" data-tenant=\""
    + this.escapeExpression(((helper = (helper = helpers.otherTenant || (depth0 != null ? depth0.otherTenant : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"otherTenant","hash":{},"data":data}) : helper)))
    + "\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"switchTenant.switch",{"name":"$","hash":{"tenant":(depth0 != null ? depth0.tenant : depth0),"otherTenant":(depth0 != null ? depth0.otherTenant : depth0)},"data":data}))
    + "</button></li>\n						<li><a class=\"\" href=\""
    + this.escapeExpression(((helper = (helper = helpers.otherTenantUrl || (depth0 != null ? depth0.otherTenantUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"otherTenantUrl","hash":{},"data":data}) : helper)))
    + "\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"switchTenant.gotoOtherTenant",{"name":"$","hash":{"otherTenant":(depth0 != null ? depth0.otherTenant : depth0)},"data":data}))
    + "</a></li>\n					</ul>\n				</p>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('common_switch_tenant_modal', t);
return t;
});

