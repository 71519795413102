
define('hbs!tpls/catalogs/lab/small.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return " new-lab";
},"3":function(depth0,helpers,partials,data) {
    return "<span class=\"new-lab-label-small\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.new",{"name":"$","hash":{},"data":data}))
    + "</span>";
},"5":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<div class=\"lab-status\">\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,((stack1 = (depth0 != null ? depth0.entitlement : depth0)) != null ? stack1.status : stack1),"completed",{"name":"eq","hash":{},"fn":this.program(6, data, 0),"inverse":this.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "				</div>\n";
},"6":function(depth0,helpers,partials,data) {
    return "						<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"transcript/lab/{{entitlement.id}}"},"data":data}))
    + "\" class=\"enrolled completed\"><span class=\"fa fa-fw fa-check-circle\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.completed",{"name":"$","hash":{},"data":data}))
    + "</a>\n";
},"8":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,((stack1 = (depth0 != null ? depth0.entitlement : depth0)) != null ? stack1.status : stack1),"expired",{"name":"eq","hash":{},"fn":this.program(9, data, 0),"inverse":this.program(11, data, 0),"data":data})) != null ? stack1 : "");
},"9":function(depth0,helpers,partials,data) {
    return "							<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"transcript/lab/{{entitlement.id}}"},"data":data}))
    + "\" class=\"enrolled expired\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.expired",{"name":"$","hash":{},"data":data}))
    + "</a>\n";
},"11":function(depth0,helpers,partials,data) {
    return "							<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"enrollments/lab/{{entitlement.id}}"},"data":data}))
    + "\" class=\"enrolled incomplete\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.enrolled",{"name":"$","hash":{},"data":data}))
    + "</a>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"lab-box small"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.newItem : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-id=\""
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n	"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.newItem : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n\n	<div class=\"lab-top\">\n"
    + ((stack1 = this.invokePartial(partials.catalogs_lab_badges,(depth0 != null ? depth0.badges : depth0),{"name":"catalogs_lab_badges","data":data,"indent":"\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = this.invokePartial(partials.catalogs_lab_reviews_and_ratings,depth0,{"name":"catalogs_lab_reviews_and_ratings","data":data,"indent":"\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "\n\n	</div>\n\n	<div class=\"lab-middle\">\n\n		<div class=\"lab-text user-data\">\n			<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"lab/{{id}}"},"data":data}))
    + "\"><h4>"
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h4></a>\n			<p class=\"description\">"
    + this.escapeExpression(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</p>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.enrolled : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n\n</div>";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('catalogs_lab_small', t);
return t;
});

