define('models/lab',['require','exports','module','jquery','underscore','backbone','library/vlp/app','library/vlp/model','library/vlp/collection','models/entitlement','models/catalog','models/lab-review','models/vm','library/utilities/days-hours-minutes-label'],function (require, exports) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection"),
	    Entitlement    = require("models/entitlement"),
	    Catalog        = require("models/catalog"), //jshint ignore:line
	    LabReview      = require("models/lab-review"), //jshint ignore:line
	    Vm             = require("models/vm"); //jshint ignore:line

	var daysHoursMinutesLabel = require("library/utilities/days-hours-minutes-label"); //jshint ignore:line

	//Use exports for requirejs circular dependency resolving
	var Lab = exports;

	//Add Lab to the App.Models namespace
	App.Models.Lab = Lab;


	Lab.Badge = BaseModel.extend({
		getters : {
			iconIsUrl : function(){
				return this.get("icon") && this.get("icon").match(/^\s*https?:/i);
			}
		}
	});
	Lab.SaasConsole = BaseModel.extend({
		defaults  : {
			consoleType : "saas"
		}
	});
	Lab.SaasConsoleCollection = BaseCollection.extend({

	});
	Lab.Model = BaseModel.extend({
		urlRoot : "/labs",

		defaults  : {
			tenantId         : 1,
			catalogId        : null,
			sku              : "",
			description      : "",
			name             : "",
			shortDescription : "",
			timelimit        : 60,
			validPeriod      : 0,
			enrollmentCount  : 0,
			thumbnail        : "",
			screenshot       : "",
			requiresGrading  : false,
			vAppExitPowerOff : true,
			endAllowed   : true,
			exitAllowed  : true,
			resetAllowed : false,
			remoteControlMode : false,
			reviewCount  : 0,
			groupName    : null,
			groupColor   : null,

			requiredStepsVisitedPercentage: 0,

			averageDeployTime : 0,
			prepopsAvailable : false,

			reviews       : [],
			badges        : [],
			authors       : [],
			systems       : [],
			tags          : [],
			products      : [],
			relatedLabs   : [],
			vms           : []
		},

		types: {
			averageDeployTime             : Backbone.Types.Integer,
			description                   : Backbone.Types.String,
			endAllowed                    : Backbone.Types.Boolean,
			enrollmentCount               : Backbone.Types.Integer,
			exitAllowed                   : Backbone.Types.Boolean,
			newItem                       : Backbone.Types.Boolean,
			remoteControlMode             : Backbone.Types.Boolean,
			requiresGrading               : Backbone.Types.Boolean,
			requiredStepsVisitedPercentage: Backbone.Types.Integer,
			requiredMinimumTimeSpent      : Backbone.Types.Integer,
			resetAllowed                  : Backbone.Types.Boolean,
			reviewCount                   : Backbone.Types.Integer,
			prepopsAvailable              : Backbone.Types.Boolean,
			vAppExitPowerOff              : Backbone.Types.Boolean
		},
		relations : [
			{
				key          : "entitlement",
				type         : Backbone.HasOne,
				relatedModel : "Entitlement.Model"
			},
			{
				key            : "authors",
				type           : Backbone.HasMany,
				relatedModel   : "Account.Model",
				collectionType : "Account.Collection"
			},
			{
				key            : "reviews",
				type           : Backbone.HasMany,
				relatedModel   : "LabReview.Model",
				collectionType : "LabReview.Collection"
			},
			{
				key            : "badges",
				type           : Backbone.HasMany,
				relatedModel   : "Lab.Badge"
			},
			{
				key            : "catalogs",
				type           : Backbone.HasMany,
				relatedModel   : "Catalog.Model",
				collectionType : "Catalog.Collection"
			},
			{
				key            : "vms",
				type           : Backbone.HasMany,
				relatedModel   : "Vm.Model",
				collectionType : "Vm.Collection"
			},
			{
				key            : "saasConsoles",
				type           : Backbone.HasMany,
				relatedModel   : "Lab.SaasConsole",
				collectionType : "Lab.SaasConsoleCollection"
			}
		],
		setters : {
			rating : function(value, options){
				for(var prop in value){
					if(value.hasOwnProperty(prop)){
						this.set(prop, value[prop], options);
					}
				}
				return value;
			},
			avgStar : function(value, options){
				this.set("ratingAvg", value, options);
				return value;
			},
			description : function(value){
				delete this._longDescriptionText;
				return value;
			},
			totalNum : function(value, options){
				this.set("ratingNum", value, options);
				return Math.max(0, _.toNumber(value));
			},
			oneStar : function(value){
				return Math.max(0, _.toNumber(value));
			},
			twoStar : function(value){
				return Math.max(0, _.toNumber(value));
			},
			threeStar : function(value){
				return Math.max(0, _.toNumber(value));
			},
			fourStar : function(value){
				return Math.max(0, _.toNumber(value));
			},
			fiveStar : function(value){
				return Math.max(0, _.toNumber(value));
			},
			ratingNum : function(value){
				return Math.max(0, _.toNumber(value));
			}
		},
		getters : {
			enrolled : function(){
				return this.attributes.enrolled || (this.attributes.entitlement && this.attributes.entitlement.id);
			},
			shortDescription : function(){
				if(this.attributes.shortDescription) {
					return this.attributes.shortDescription;
				}
				if(this._longDescriptionText){
					return this._longDescriptionText;
				}
				if(this.attributes.description){
					this._longDescriptionText = _.trim($("<div>" + this.attributes.description + "</div>").text());
					return this._longDescriptionText;
				}
				return "";
			},
			showReviewsOrRatings : function(){
				return App.config.reviews;
			},
			hasRating : function(){
				return this.has("ratingAvg");
			},
			oneStarPercent : function(){
				return (this.get("oneStar") / this.get("ratingNum")) * 100;
			},
			twoStarPercent : function(){
				return (this.get("twoStar") / this.get("ratingNum")) * 100;
			},
			threeStarPercent : function(){
				return (this.get("threeStar") / this.get("ratingNum")) * 100;
			},
			fourStarPercent : function(){
				return (this.get("fourStar") / this.get("ratingNum")) * 100;
			},
			fiveStarPercent : function(){
				return (this.get("fiveStar") / this.get("ratingNum")) * 100;
			},
			shareUrl : function(){
				return App.makeUrl({page: App.config.pages.CATALOGS, action: "lab/" + this.get("id"), forceHost: true});
			},
			thumbnail : function(){
				if(!this.attributes.thumbnail){
					return App.config.defaultLabThumbnail;
				}
				return this.attributes.thumbnail;
			}
		},
		initialize : function(){

			/** test data*/
			if(App.config.reviewsEnabled){
				console.warn("Lab.Model: Using test data for reviews");
				this.set("reviews", _.sample([{}, {}, {}, {}, {}, {}, {}, {}], _.random(0, 7)));
			}
		},
		enroll : function(options){
			var self = this;

			options = options || {};
			var entitlement = new Entitlement.Model();

			var attributes = options.attributes || {};

			var username = App.user.get("username") || App.user.get("email");
			attributes = _.extend({
				username : username,
				course_code : this.get("sku")
			}, attributes);

			delete options.attributes;
			options.properties = ["username", "course_code"];

			this.set("entitlement", entitlement);

			options.debug = options.debug || [];
			options.debug.push("models.lab:enroll");


			var success = options.success;
			options.success = function(model, resp){
				if(resp && resp.data && resp.data.hasOwnProperty("enrollmentCount")){
					self.set("enrollmentCount", resp.data.enrollmentCount);
				}
				if(success) {
					success(model, resp);
				}
			};

			return entitlement.save(attributes, options);
		},
		rate : function(rating, options){
			options = options || {};
			options.params = options.params || {};
			options.params.labId = this.id;
			options.params.rating = rating;
			options.properties = [];

			var success = options.success;
			options.success = function(model, resp){
				model.set("userRating", rating);
				model.set("userRated", true);
				if(success) {
					success(model, resp);
				}
			};

			var error = options.error;
			options.error = function (model, resp, options) {
				if(resp && resp.errorCode == 5301){
					model.set("userRated", true);
				}
				if(resp.response && resp.response.data){
					model.set(model.parse(resp.response, options), options);
				}
				if (error) { error(model, resp, options); }
			};

			if(!options.url){
				options.url = this.serverBase + "rate/sendRating";
			}
			return this.action("sendRating", options);
		}
	});


	Lab.Collection = BaseCollection.extend({
		perPage : 10,
		orderBy  : "displayOrder",
		orderDir : "asc",
		model : Lab.Model
	});

});

