define('library/underscore/data-convert',['require','underscore'],function (require) {
	"use strict";
	var _  = require("underscore");

	_.mixin({
		toBoolean: function(value, strict){
			if(_.isBoolean(value)){
				return value;
			}
			if(_.isString(value)){
				if(value.match(/true|1/)){
					return true;
				} else if(strict) {
					return value.match(/false|0/) ? false : undefined;
				} else {
					return false;
				}
			}
			if(_.isNumber(value)){
				return value !== 0;
			}
			return Boolean(value);
		},
		toNumber : function(value){
			if(_.isNumber(value)){
				return value;
			}
			try{
				var tValue = JSON.parse(value);
				if(_.isNumber(tValue)){
					return tValue;
				}
			} catch(e){}
			return 0;
		},
		secondsToDHMS : function(value) {
			/* jshint ignore:start */
			return {
				days    : ~~(value / 86400),
				hours   : ~~((value / 3600) % 24),
				minutes : ~~((value % 3600) / 60),
				seconds : value % 60
			};
			/* jshint ignore:end */
		},
		unescapeString : function(value){
			if(value === undefined || value === null){
				return value;
			}

			return String(value)
				.replace(/\\\\/g, "___REPLACE_SLASH__")
				.replace(/\\n/g, "\n")
				.replace(/\\'/g, "\'")
				.replace(/\\"/g, "\"")
				.replace(/\\&/g, "\&")
				.replace(/\\r/g, "\r")
				.replace(/\\t/g, "\t")
				.replace(/\\v/g, "\v")
				.replace(/\\0/g, "\0")
				.replace(/\\b/g, "\b")
				.replace(/\\f/g, "\f")
				.replace(/___REPLACE_SLASH__/g, "\\");
		}
	});

	return _;
});
