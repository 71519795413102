
define('hbs!tpls/console/vm-settings-prompts.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var helper;

  return "								<option value=\""
    + this.escapeExpression(((helper = (helper = helpers.key || (data && data.key)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"key","hash":{},"data":data}) : helper)))
    + "\">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\n";
},"3":function(depth0,helpers,partials,data) {
    return "deactivated";
},"5":function(depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"modal_keyboard_mismatch_prompt\" class=\"modal hide fade fluid-height global\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-keyboard-o\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.keyboardMismatch.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<p>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.keyboardMismatch.message",{"name":"$","hash":{},"data":data}))
    + "</p>\n\n				<div class=\"well well-small\">\n					<div class=\"setting\">\n						<label class=\"checkbox\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.keyBoardLayoutTitle",{"name":"$","hash":{},"data":data}))
    + "\n						</label>\n						<select id=\"prompt_keyboard_layout_id\">\n"
    + ((stack1 = helpers.each.call(depth0,(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"consoleKeyboardLayouts",{"name":"config","hash":{},"data":data}),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "						</select>\n						<span class=\"fa fa-4x fa-keyboard-o pull-right\"></span>\n						<p>\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.keyBoardLayoutDescription",{"name":"$","hash":{},"data":data}))
    + "\n						</p>\n						<p>\n							<em>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.keyboardMismatch.settingsNote",{"name":"$","hash":{},"data":data}))
    + " <span class=\"fa fa-gear\"></span></em>\n						</p>\n					</div>\n					<div class=\"setting "
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.fixANSISupported : depth0),{"name":"unless","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n						<label class=\"checkbox\">\n							<input type=\"checkbox\" id=\"prompt_fix_ansi\" "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.fixANSI : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.fixANSITitle",{"name":"$","hash":{},"data":data}))
    + "\n						</label>\n						<span class=\"fa fa-4x fa-language pull-right\"></span>\n						<p class=\"alert alert-warning disabled-message\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.fixANSIDisabled",{"name":"$","hash":{},"data":data}))
    + "</p>\n						<p>\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.fixANSIDescription",{"name":"$","hash":{},"data":data}))
    + "\n						</p>\n\n						<p>\n							<em>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.keyboardMismatch.settingsNote",{"name":"$","hash":{},"data":data}))
    + " <span class=\"fa fa-gear\"></span></em>\n						</p>\n					</div>\n				</div>\n				<button type=\"button\" class=\"btn btn-cancel\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_intercept_paste\" class=\"modal hide fade fluid-height global\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-clipboard\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.interceptPaste.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<p>\n					<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.interceptPaste.prompt",{"name":"$","hash":{},"data":data}))
    + "</strong>\n				</p>\n				<p>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.interceptPaste.message",{"name":"$","hash":{},"data":data}))
    + "</p>\n				<p>\n					<em>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.interceptPaste.settingsNote",{"name":"$","hash":{},"data":data}))
    + " <span class=\"fa fa-gear\"></span></em>\n				</p>\n				<button id=\"intercept_paste_cancel\" type=\"button\" class=\"btn btn-cancel\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.cancel",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<button id=\"intercept_paste_confirm\" type=\"button\" class=\"btn btn-primary\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.confirm",{"name":"$","hash":{},"data":data}))
    + "</button>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_macos_remap_shortcuts\" class=\"modal hide fade fluid-height global\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-apple\"></span>\n					<span class=\"fa fa-fw fa-long-arrow-right\"></span>\n					<span class=\"fa fa-fw fa-windows\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<p>\n					<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.macOsRemapCommandToControl.prompt",{"name":"$","hash":{},"data":data}))
    + "</strong>\n				</p>\n				<p>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.macOsRemapCommandToControl.message",{"name":"$","hash":{},"data":data}))
    + "</p>\n				<dl>\n					<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.shortcuts",{"name":"$","hash":{},"data":data}))
    + "</dt>\n\n					<dd>\n						<span class=\"type\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.copy",{"name":"$","hash":{},"data":data}))
    + "</span>\n						<span class=\"key\">⌘</span> + <span class=\"key\">c</span>\n						<span class=\"fa fa-long-arrow-right\"></span>\n						<span class=\"key\">ctrl</span> + <span class=\"key\">c</span>\n					</dd>\n					<dd>\n						<span class=\"type\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.cut",{"name":"$","hash":{},"data":data}))
    + "</span>\n						<span class=\"key\">⌘</span> + <span class=\"key\">x</span>\n						<span class=\"fa fa-long-arrow-right\"></span>\n						<span class=\"key\">ctrl</span> + <span class=\"key\">x</span>\n					</dd>\n					<dd>\n						<span class=\"type\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.paste",{"name":"$","hash":{},"data":data}))
    + "</span>\n						<span class=\"key\">⌘</span> + <span class=\"key\">v</span>\n						<span class=\"fa fa-long-arrow-right\"></span>\n						<span class=\"key\">ctrl</span> + <span class=\"key\">v</span>\n					</dd>\n					<dd>\n						<span class=\"type\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.save",{"name":"$","hash":{},"data":data}))
    + "</span>\n						<span class=\"key\">⌘</span> + <span class=\"key\">s</span>\n						<span class=\"fa fa-long-arrow-right\"></span>\n						<span class=\"key\">ctrl</span> + <span class=\"key\">s</span>\n					</dd>\n					<dd>\n						<span class=\"type\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.selectAll",{"name":"$","hash":{},"data":data}))
    + "</span>\n						<span class=\"key\">⌘</span> + <span class=\"key\">a</span>\n						<span class=\"fa fa-long-arrow-right\"></span>\n						<span class=\"key\">ctrl</span> + <span class=\"key\">a</span>\n					</dd>\n					<dd>\n						<span class=\"type\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.undo",{"name":"$","hash":{},"data":data}))
    + "</span>\n						<span class=\"key\">⌘</span> + <span class=\"key\">z</span>\n						<span class=\"fa fa-long-arrow-right\"></span>\n						<span class=\"key\">ctrl</span> + <span class=\"key\">z</span>\n					</dd>\n				</dl>\n				<p>\n					<em>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.macOsRemapCommandToControl.settingsNote",{"name":"$","hash":{},"data":data}))
    + " <span class=\"fa fa-gear\"></span></em>\n				</p>\n				<button id=\"macos_remap_command_to_control_cancel\" type=\"button\" class=\"btn btn-cancel\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.cancel",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<button id=\"macos_remap_command_to_control_confirm\" type=\"button\" class=\"btn btn-primary\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.confirm",{"name":"$","hash":{},"data":data}))
    + "</button>\n			</div>\n		</div>\n	</div>\n</div>\n\n";
},"useData":true});
Handlebars.registerPartial('console_vm_settings_prompts', t);
return t;
});

