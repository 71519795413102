define('views/compatibility/tests/proxy',['require','../test','hbs!tpls/compatibility/tests/proxy.handlebars'],function (require) {
	"use strict";

	//class dependencies
	var TestBase = require("../test"),
		template = require("hbs!tpls/compatibility/tests/proxy.handlebars");

	return TestBase.extend({
		template : template,
		name : "proxy",
		run : function(){
			var self = this;

			this.setStatus("running");

			try{
				var request = new XMLHttpRequest();
				request.open("GET", document.location, false);
				request.send();
				var result = this.checkForProxyHeader(request);

				self.setStatus(!result ? "success" : "warning");
				self.trigger(TestBase.COMPLETE, result);
			} catch (ignore){
				self.setStatus("success");
				self.trigger(TestBase.COMPLETE, true);
			}
		},
		checkForProxyHeader : function(request){
			var viaHeader = request.getResponseHeader("via");
			if(viaHeader){
				viaHeader = viaHeader.toString().toLowerCase();
				//ignore cloudfront added via header.
				return (viaHeader.indexOf("cloudfront") === -1);
			} else if(request.getResponseHeader("x-forwarded-for")){
				return true;
			}
			return false;
		}
	});

});

