define('nls/root/event',{
	"event" : {
		"stationId" : "Station Identification",
		"register" : {
			"title"         : "Register Station",
			"register"      : "Register",
			"registering"   : "Registering...",
			"forceRegister" : "click here to force re-register this station"
		},
		"registered" : {
			"unregister"       : "Un-register",
			"unregistering"    : "Un-registering...",
			"registered"       : "Registered",
			"stationId"        : "Station ID",
			"openSecondWindow" : "Open Promos / Second Window",
			"openCatalog"      : "Open Catalog"
		}
	},
	"errors"    : {
	},
	"title"     : "Event"
});
