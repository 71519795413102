/**
 * Handlebars date formatting helper functions.
 *
 * See http://blog.stevenlevithan.com/archives/date-time-format for date formats.
 */
/* globals dateFormat,global */
define('library/handlebars/date-format',['require','library/vlp/app','handlebars','library/utilities/date.format'],function (require) {
	"use strict";

	//library dependencies
	var App        = require("library/vlp/app"),
	    Handlebars = require("handlebars");

	require("library/utilities/date.format");

	var root = window || global;

	/**
	 * Convert new line (\n\r) to <br>
	 * from http://phpjs.org/functions/nl2br:480
	 */
	Handlebars.registerHelper("date_format", function(date, format){
		var i18n = App.locale.i18nStrings || root.i18nStrings;
		if(i18n && i18n[format]){
			var key = format;
			format = i18n[key];

			var text = dateFormat(date, format);

			var result = "<i18n data-type=\"date_format\" data-key=\"" + key + "\"";

			result += " data-args=\"" + JSON.stringify([date]).replace(/'/g, "\\'").replace(/"/g, "&quot;") + "\"";

			result+= ">" + text + "</i18n>";
			return new Handlebars.SafeString(result);
		} else{
			return dateFormat(date, format);
		}
	});
});
