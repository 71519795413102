
define('hbs!tpls/catalogs/catalog.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "		<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "\n		<form class=\"form-search\">\n			<div class=\"control-group\">\n				<div class=\"controls\">\n					<div class=\"input-append\">\n						<input type=\"search\" name=\"search\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"placeholder","catalogs.allCatalogs.search",{"name":"$$","hash":{},"data":data}))
    + " />\n						<button class=\"add-on btn\" type=\"button\"><i class=\"fa fa-search\"></i></button>\n					</div>\n				</div>\n			</div>\n		</form>\n\n		<div class=\"nav-bar header-bar\">\n			<h3>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.allCatalog : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\n			</h3>\n		</div>\n\n		<div class=\"labs-holder active\"></div>\n\n";
},"4":function(depth0,helpers,partials,data) {
    return "					<span class=\"fa fa-archive skin-icon\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.allCatalogs.title",{"name":"$","hash":{},"data":data}))
    + "\n";
},"6":function(depth0,helpers,partials,data) {
    var helper;

  return "					<span class=\"fa fa-archive skin-icon\"></span>\n					"
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"catalog-box\" data-catalog-id=\""
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.loading : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});
Handlebars.registerPartial('catalogs_catalog', t);
return t;
});

