
define('hbs!tpls/compatibility/main.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    return "<div class=\"page-content\">\n	<h2 class=\"title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"compatibility.title",{"name":"$","hash":{},"data":data}))
    + "</h2>\n	<p>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"compatibility.description",{"name":"$","hash":{},"data":data}))
    + "</p>\n	<div class=\"status-container\">\n		<a class=\"start-lab btn btn-large btn-primary pull-right\">Start Lab</a>\n		<button id=\"run_tests\"  class=\"btn btn-large btn-primary pull-right\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","compatibility.running",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"compatibility.runTests",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<div class=\"progress-container\">\n			<div class=\"progress\">\n				<div class=\"bar\" style=\"width: 0%\"></div>\n				<span class=\"message\"></span>\n			</div>\n		</div>\n	</div>\n	<div class=\"accordion\" id=\"tests\"></div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('compatibility_main', t);
return t;
});

