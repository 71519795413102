/**
 * Parse the query string into an object underscore mixin.
 */
define('library/underscore/parse-query-string',['require','underscore'],function (require) {
	"use strict";
	var _  = require("underscore");

	_.mixin({
		parseQueryString: function(queryString){

			queryString = queryString || window.self.location.search;

			queryString = queryString.replace(/^\?/, "");
			var urlParams = {};
			var item;
			var paramPattern = /([^&=]+)=?([^&]*)/g;

			var replaceSpaces = function(s){
				return decodeURIComponent(s.replace(/\+/g, " "));
			};

			while(item = paramPattern.exec(queryString)){ //jshint ignore:line
				urlParams[replaceSpaces(item[1])] = replaceSpaces(item[2]);
			}
			return urlParams;
		}
	});

	return _;
});
