define('models/course',['require','exports','module','underscore','backbone','library/vlp/app','library/vlp/model','library/vlp/collection','models/lab','models/entitlement'],function (require, exports) {
	"use strict";

	//library dependencies
	var _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection"),
	    Lab            = require("models/lab"),  //jshint ignore:line
	    Entitlement    = require("models/entitlement");


	//Use exports for requirejs circular dependency resolving
	var Course = exports;

	//Add Course to the App.Models namespace
	App.Models.Course = Course;


	Course.Model = BaseModel.extend({
		urlRoot : "/courses",

		defaults : {
			tenantId    : 1,
			sku         : "",
			name        : "",
			description : ""
		},
		types : {
			description : Backbone.Types.String
		},
		relations : [
			{
				key            : "labs",
				type           : Backbone.HasMany,
				relatedModel   : "Lab.Model",
				collectionType : "Lab.Collection"
			},
			{
				key          : "entitlement",
				type         : Backbone.HasOne,
				relatedModel : "Entitlement.Model"
			}
		],
		getters : {
			enrolled : function(){
				return this.attributes.enrolled || (this.attributes.entitlement && this.attributes.entitlement.id);
			}
		},
		setters : { },
		enroll : function(options){

			options = options || {};
			var entitlement = new Entitlement.Model();

			var attributes = options.attributes || {};

			var username = App.user.get("username") || App.user.get("email");
			attributes = _.extend({
				username    : username,
				course_code : this.get("sku")
			}, attributes);

			delete options.attributes;

			options.properties = ["username", "course_code"];

			this.set("entitlement", entitlement);

			return entitlement.save(attributes, options);
		}
	});


	Course.Collection = BaseCollection.extend({
		model : Course.Model,
		comparator : function(model){
			if(model.has("orderIndex")) {
				return model.get("orderIndex");
			}
			if(model.has("name")) {
				return model.get("name");
			}
			return model.id;
		}
	});

});
