define('models/announcement',['require','exports','module','underscore','library/vlp/app','library/vlp/model','library/vlp/collection'],function (require, exports) {
	"use strict";

	var _ = require("underscore");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection");


	//Use exports for requirejs circular dependency resolving
	var Announcement = exports;

	//Add Announcement to the App.Models namespace
	App.Models.Announcement = Announcement;


	Announcement.Model = BaseModel.extend({
		urlRoot : "/announcements",

		/**
		 * Default values for empty Models (new Announcement.Model())
		 */
		defaults : {
			title   : "",
			content : ""
		},

		/**
		 * Constructor
		 */
		initialize : function(){

		},
		parse : function(){
			var result = BaseModel.prototype.parse.apply(this, arguments);
			if(result && result.content && _.isString(result.content)){
				result.content = result.content.replaceAll(".youtube.com", ".youtube-nocookie.com");
			}

			return result;
		}
	});


	Announcement.Collection = BaseCollection.extend({
		model : Announcement.Model
	});

});

