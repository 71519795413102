/**
 * EULA Backbone View
 */
define('views/console/walkthrough',['require','jquery','underscore','library/vlp/app','library/vlp/view','library/hopscotch','i18n!nls/console-walkthrough','hbs!tpls/console/walkthrough/step-0.handlebars','hbs!tpls/console/walkthrough/step-1.handlebars','hbs!tpls/console/walkthrough/step-2.handlebars','hbs!tpls/console/walkthrough/step-3.handlebars','hbs!tpls/console/walkthrough/step-4.handlebars','hbs!tpls/console/walkthrough/step-5.handlebars','hbs!tpls/console/walkthrough/step-6.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App       = require("library/vlp/app"),
	    BaseView  = require("library/vlp/view"),
	    hopscotch = require("library/hopscotch"),
	    pageTexts = require("i18n!nls/console-walkthrough");

	var stepTPLs = [
			require("hbs!tpls/console/walkthrough/step-0.handlebars"),
			require("hbs!tpls/console/walkthrough/step-1.handlebars"),
			require("hbs!tpls/console/walkthrough/step-2.handlebars"),
			require("hbs!tpls/console/walkthrough/step-3.handlebars"),
			require("hbs!tpls/console/walkthrough/step-4.handlebars"),
			require("hbs!tpls/console/walkthrough/step-5.handlebars"),
			require("hbs!tpls/console/walkthrough/step-6.handlebars")
		];

	return BaseView.extend({

		events : {
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			options = options || {};

			this.panelManager = options.panelManager;

			//merges eula specific texts to be globally accessible and available to the tpls
			App.locale.mergeI18n(pageTexts);

			this.vmPanelHidden = false;
		},
		remove : function(){
			if(this.walkthrough){
				this.walkthrough.removeCallbacks();
				this.walkthrough.endTour(false);
				delete this.walkthrough;
			}
		},
		start : function(options){
			var self = this;
			var vmPanel      = this.panelManager.getPanel("#vm_listing_panel");
			var $vmPanel     = $("#vm_listing_panel");
			var manualPanel  = this.panelManager.getPanel("#instructions_panel");
			var $manualPanel = $("#instructions_panel");
			var onSameSide   = vmPanel && manualPanel && vmPanel.state.position === manualPanel.state.position;

			this.vmPanelHidden = (!vmPanel || vmPanel.state.hidden);

			this.steps = [
				{
					target: "#launch_walkthrough",
					placement: "bottom",
					content: stepTPLs[0]({firstTime : options.firstTime})
				},
				{
					target: ".lab-progress-wrapper",
					placement: "bottom",
					content: stepTPLs[1]({stepNumber: 1})
				},
				{
					target: "#vm_above_bar",
					placement: "bottom",
					xOffset: "center",
					content: stepTPLs[2]({stepNumber: 2})
				},
				{
					target: ".settings-wrapper .fa-cog",
					placement: "left",
					yOffset: -20,
					content: stepTPLs[3]({stepNumber: 3}),
					onNext: function() {
						if(manualPanel){
							self.panelManager.expandPanels(manualPanel);
							self.panelManager.setFocus(manualPanel);
						}
					}
				},
				{
					target: "#instructions_panel",
					placement: $manualPanel.hasClass("left") ? "right" : "left",
					xOffset: $manualPanel.hasClass("left") ? 35 : -25,
					content: stepTPLs[4]({stepNumber: 4}),
					delay: 500,
					onPrev: function() {
						if(manualPanel){ self.panelManager.closePanels(manualPanel); }
					},
					onNext: function() {
						if (!onSameSide || (onSameSide && $vmPanel.hasClass("hide"))) {
							self.panelManager.closePanels(manualPanel);
						}
						if(vmPanel){
							if(self.vmPanelHidden){ vmPanel.show(); }
							self.panelManager.expandPanels(vmPanel);
							self.panelManager.setFocus(vmPanel);
						}
					}
				},
				{
					target: "#vm_listing_panel",
					placement: $vmPanel.hasClass("left") ? "right" : "left",
					xOffset: $vmPanel.hasClass("left") ? 35 : -25,
					content: stepTPLs[5]({stepNumber: 5}),
					delay: 500,
					onNext: function() {
						if(vmPanel){
							if(self.vmPanelHidden){ vmPanel.hide(); }
							self.panelManager.closePanels(vmPanel);
						}
					},
					onPrev: function() {
						if (!onSameSide || $vmPanel.hasClass("hide")) {
							if(self.vmPanelHidden){ vmPanel.hide(); }
							self.panelManager.closePanels(vmPanel);
						}
						if(manualPanel){
							self.panelManager.expandPanels(manualPanel);
							self.panelManager.setFocus(manualPanel);
						}
					}
				},
				{
					target: "#vm_console_wrapper .controls",
					placement: "left",
					xOffset: -10,
					content: stepTPLs[6]({stepNumber: 6}),
					delay: 500,
					onPrev: function() {
						if(vmPanel){
							if(self.vmPanelHidden){ vmPanel.show(); }
							self.panelManager.expandPanels(vmPanel);
						}
					},
					onNext: function() {
						if(manualPanel){ self.panelManager.expandPanels(manualPanel); }
					}
				}
			];

			options = options || {};
			options = _.defaults(options, {
				id: "console_walkthrough",
				steps: this.steps,
				bubbleWidth: "500",
				bubblePadding: "0",
				i18n: {
					nextBtn      : App.i18n("next"),
					prevBtn      : App.i18n("previous"),
					doneBtn      : App.i18n("consoleWalkthrough.actions.done"),
					skipBtn      : App.i18n("consoleWalkthrough.actions.noThanks"),
					closeTooltip : "&times;"
				},
				showNextButton  : false,
				showPrevButton  : false,
				showCloseButton : false,
				showCTAButton   : false,
				onStart: function() {
					// Make sure if starting on the panel steps, the panel shows/expands/focuses properly.
					if (hopscotch.getCurrStepNum() === 4) {
						if(manualPanel){
							self.panelManager.expandPanels(manualPanel);
							self.panelManager.setFocus(manualPanel);
						}
					} else if (hopscotch.getCurrStepNum() === 5) {
						if(vmPanel){
							if(self.vmPanelHidden){ vmPanel.show(); }
							self.panelManager.expandPanels(vmPanel);
							self.panelManager.setFocus(vmPanel);
						}
					} else {
						if(manualPanel){ self.panelManager.closePanels(manualPanel); }
						if(vmPanel){ self.panelManager.closePanels(vmPanel); }
					}
				}
			});

			this.walkthrough = hopscotch.startTour(options);

			$(document).off("click.console-walkthrough");

			$(document).on("click.console-walkthrough", ".console-walkthrough-prev", this.prevStep);
			$(document).on("click.console-walkthrough", ".console-walkthrough-next", this.nextStep);
			$(document).on("click.console-walkthrough", ".console-walkthrough-done", this.done);
			$(document).on("click.console-walkthrough", ".console-walkthrough-exit", this.exit);

			_.defer(function(){
				$(document).on("click.console-walkthrough", self.onClickOff);
			});
			this.walkthrough.listen("end", function(){
				self.trigger("end");
			});
		},

		prevStep : function() {
			if(this.walkthrough){
				this.walkthrough.prevStep();
			}
		},
		nextStep : function() {
			if(this.walkthrough){
				this.walkthrough.nextStep();
			}
		},
		done : function() {
			$(document).off("click.console-walkthrough");
			if(this.walkthrough){
				this.walkthrough.endTour(true);
			}
		},
		exit : function() {
			var vmPanel = this.panelManager.getPanel("#vm_listing_panel");
			if(vmPanel){
				if(this.vmPanelHidden){ vmPanel.hide(); }
				this.panelManager.closePanels(vmPanel);
			}
			$(document).off("click.console-walkthrough");
			if(this.walkthrough){
				this.walkthrough.endTour(false);
			}
		},
		onClickOff : function(event){
			var $target = $(event.target);
			var $hopscotch = $(".hopscotch-bubble");

			if($hopscotch.has($target).length === 0 && !$hopscotch.is($target) && $target.closest(".hopscotch-bubble-container").length === 0){
				this.exit();
			}
		}
	});

});

