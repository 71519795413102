/**
 * Landing main Backbone View
 */
define('views/landing/main',['require','underscore','library/vlp/app','views/vlp-base','views/common/layout-tpls','hbs!tpls/landing/main.handlebars','i18n!nls/landing'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//class dependencies
	var App         = require("library/vlp/app"),
	    VlpBaseView = require("views/vlp-base"),
	    LayoutTPLs  = require("views/common/layout-tpls"),
	    mainTPL     = require("hbs!tpls/landing/main.handlebars"),
	    pageTexts   = require("i18n!nls/landing");

	return VlpBaseView.extend({
		name : "LANDING",

		checkBrowserSupported : false,
		checkSystem           : false,
		checkLoggedIn         : false,
		checkTenantPath       : false,

		layoutTemplate : LayoutTPLs.layoutEmpty,
		mainTemplate   : mainTPL,
		events : {

		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){

			options = options || {};

			_.bindAll(this);

			App.locale.mergeI18n(pageTexts);

			//Checks logged in
			this.setup(options);
		},
		serialize : function(){
			var data = {};
			data.copyrightYear =  new Date().getFullYear();
			return data;
		}
	});

});

