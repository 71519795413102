
define('hbs!tpls/maintenance/system-maintenance.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var helper;

  return "<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"maintenance.unavailableMessage",{"name":"$","hash":{},"data":data}))
    + "</h4>\n<div class=\"alert maintenance-message\">"
    + this.escapeExpression(((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"message","hash":{},"data":data}) : helper)))
    + "</div>\n<h3>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"maintenance.tryAgain",{"name":"$","hash":{},"data":data}))
    + "</h3>\n<p id=\"system_status\"><a href=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"systemStatusURL",{"name":"config","hash":{},"data":data}))
    + "\" target=\"_blank\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"maintenance.systemStatusLinkMessage",{"name":"$","hash":{},"data":data}))
    + "</a></p>\n<p><a href=\"#\" onclick=\"javascript:window.history.back();\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"maintenance.goBack",{"name":"$","hash":{},"data":data}))
    + "</a></p>\n";
},"useData":true});
Handlebars.registerPartial('maintenance_system_maintenance', t);
return t;
});

