/**
 * Base Main Backbone View
 *
 * This is a Base Class for setting up the layout and header/footer for all pages.
 */
define('library/vlp/main-view',['require','underscore','library/vlp/view','library/vlp/app'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//This classes dependencies
	var BaseView = require("library/vlp/view"),
	    App      = require("library/vlp/app");

	/**
	 * BaseMainView Class
	 *
	 */
	return BaseView.extend({

		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		el : "body",

		setup : function(){

			if(!this.partialTemplates){
				throw new Error("BaseMainView child class needs templates property");
			}
			if(!this.layoutTemplate){
				throw new Error("BaseMainView child class needs to specify a layout tpl");
			}

			BaseView.prototype.setup.apply(this, arguments);
		},
		/**
		 * Render layout
		 * @param options
		 */
		layout : function(options){
			options = options || {};
			this.rendering = true;

			_.defaults(options, App.config);

			this.$el.html(this.layoutTemplate(options));

			if(this.className){
				this.$el.addClass(this.className);
			}

			this.$mainContent = this.$("#main_content");

			this.rendered = true;
			if(this.afterRender && _.isFunction(this.afterRender)){
				this.afterRender();
			}
			this.rendered = true;
			this.rendering = false;
			this.trigger("afterRender", this);
		}
	});

});
