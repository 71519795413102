
define('hbs!tpls/common/profile/general.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "autocomplete=\"on\"";
},"3":function(depth0,helpers,partials,data) {
    return "autocomplete=\"off\" action=\"https://about:blank\"";
},"5":function(depth0,helpers,partials,data) {
    return "\"given-name\"";
},"7":function(depth0,helpers,partials,data) {
    return "\"off\"";
},"9":function(depth0,helpers,partials,data) {
    return "\"family-name\"";
},"11":function(depth0,helpers,partials,data) {
    return "\"name\"";
},"13":function(depth0,helpers,partials,data) {
    return "\"organization\"";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<form class=\"form-horizontal\" method=\"post\" id=\"profile_general_form\" "
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " data-error-style=\"tooltip\">\n	<div class=\"alert alert-info privacy-prompt alert-icon alert-small\">\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.privacyPrompt",{"name":"$","hash":{},"data":data}))
    + " <a type=\"button\" href=\"#public-profile\" class=\"preview-public-profile\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.showPublicProfile",{"name":"$","hash":{},"data":data}))
    + "</a>\n	</div>\n	<fieldset>\n		<div class=\"control-group\">\n			<label class=\"control-label required\" for=\"profile_firstName\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.general.firstName",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls privacy\">\n				<input type=\"text\" id=\"profile_firstName\" maxlength=\"255\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n				<button data-property=\"firstName_public\" class=\"btn btn-no-style privacy\" type=\"button\" data-toggle=\"button\" data-container=\"body\" data-placement=\"top\"></button>\n				<span class=\"help-block\"></span>\n			</div>\n		</div>\n		<div class=\"control-group\">\n			<label class=\"control-label required\" for=\"profile_lastName\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.general.lastName",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls privacy\">\n				<input type=\"text\" id=\"profile_lastName\" maxlength=\"255\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(9, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n				<button data-property=\"lastName_public\" class=\"btn btn-no-style privacy\" type=\"button\" data-toggle=\"button\" data-container=\"body\" data-placement=\"top\"></button>\n				<span class=\"help-block\"></span>\n			</div>\n		</div>\n		<div class=\"control-group\">\n			<label class=\"control-label required\" for=\"profile_displayName\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.general.displayName",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls privacy\">\n				<input type=\"text\" id=\"profile_displayName\" maxlength=\"255\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(11, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n				<button data-property=\"displayName_public\" class=\"btn btn-no-style privacy\" type=\"button\" data-toggle=\"button\" data-container=\"body\" data-placement=\"top\"></button>\n				<div class=\"alert alert-info alert-small alert-icon\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.general.displayNameMessage",{"name":"$","hash":{},"data":data}))
    + "</div>\n				<span class=\"help-block\"></span>\n			</div>\n		</div>\n		<div class=\"control-group\">\n			<label class=\"control-label required\" for=\"profile_company\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.general.company",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls privacy\">\n				<input type=\"text\" id=\"profile_company\" maxlength=\"255\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(13, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n				<button data-property=\"company_public\" class=\"btn btn-no-style privacy\" type=\"button\" data-toggle=\"button\" data-container=\"body\" data-placement=\"top\"></button>\n				<span class=\"help-block\"></span>\n			</div>\n		</div>\n		<div class=\"control-group\">\n			<label class=\"control-label\" for=\"profile_username\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.general.username",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls\">\n				<div style=\"padding-top: 5px;\" id=\"current_username\">"
    + this.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"username","hash":{},"data":data}) : helper)))
    + "</div>\n				<span class=\"help-block\"></span>\n			</div>\n		</div>\n\n	</fieldset>\n\n	<div class=\"alert alert-error save-error-general hide\"></div>\n	<button type=\"submit\" class=\"btn btn-large btn-primary pull-right save-changes\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","saving",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.saveChanges",{"name":"$","hash":{},"data":data}))
    + "</button>\n\n	<div class=\"spinner hide pull-right save-spinner\"/>\n	<div class=\"alert alert-success hide pull-right save-success\">\n		<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"updated",{"name":"$","hash":{},"data":data}))
    + "</strong>\n	</div>\n	<div class=\"alert alert-error hide pull-right save-error\">\n		<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"error",{"name":"$","hash":{},"data":data}))
    + "</strong>\n	</div>\n</form>";
},"useData":true});
Handlebars.registerPartial('common_profile_general', t);
return t;
});

