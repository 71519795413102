define('views/compatibility/tests/bandwidth',['require','underscore','../test','views/compatibility/tests/bandwidth-tests','utilities/browser','hbs!tpls/compatibility/tests/bandwidth.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//class dependencies
	var TestBase           = require("../test"),
	    BandwidthTestsView = require("views/compatibility/tests/bandwidth-tests"),
	    Browser             = require("utilities/browser"),
	    template           = require("hbs!tpls/compatibility/tests/bandwidth.handlebars");

	return TestBase.extend({
		template : template,
		name : "bandwidth",


		initialize : function(){
			_.bindAll(this);
			this.testsView = new BandwidthTestsView();
			this.testsView.render();
			var self = this;
			if (Browser.msie && Browser.version.major < 10) {
				this.enabled = false;
			}

			this.testsView.on("progress", function(progress){
				self.trigger(TestBase.PROGRESS, progress);
			});
			this.testsView.on("complete", this.testsComplete);
		},
		beforeRender : function(){
			this.testsView.$el.detach();
		},
		afterRender : function(){
			this.$("#tests_holder").append(this.testsView.$el);
		},
		run : function(){
			if(!window.Worker){
				this.errors.webWorkers = true;
				this.setStatus("failure");
			} else {
				this.setStatus("running");
				this.testsView.startTest();
			}
		},
		testsComplete : function(results){
			var self = this;
			var failure = false;
			var warning = false;

			var modifiedResults = {
				clouds : []
			};

			_.each(results, function(result, type){
				_.each(result, function(urlResult, url){
					var saveResult = {
						destination: urlResult.destination,
						bandwidth  : urlResult.bandwidth,
						latency    : urlResult.latency
					};
					if(type === "ui" && (!modifiedResults.ui || urlResult.latency < modifiedResults.ui.latency)){
						modifiedResults.ui = saveResult;
					} else if(type === "cloud"){
						modifiedResults.clouds.push(saveResult);
					}

					if(urlResult.error){
						self.errors[type] = true;
						failure = true;
					} else if(urlResult.fail){
						self.warnings.connection = true;
						self.warnings[type] = true;
						warning = true;
					}
				});
			});


			if(failure){
				this.setStatus("failure");
			} else if(warning){
				this.setStatus("warning");
			} else {
				this.setStatus("success");
			}
			this.trigger(TestBase.COMPLETE, modifiedResults);
		}
	});

});

