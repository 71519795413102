define('library/vlp/push',['require','jquery','underscore','library/vlp/base-class','library/atmosphere'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore");

	//class dependencies
	var BaseClass  = require("library/vlp/base-class"),
	    Atmosphere = require("library/atmosphere");

	return BaseClass.extend({
		pingInterval          : 27000,
		socket                : Atmosphere,
		logging               : false,
		connected             : false,
		ack                   : false,
		registeredModels      : {},
		rootParams            : null,
		requestParams         : null,
		subscribed            : [],
		reconnectWait         : 1000,
		reconnectAttemptCount : 20,
		useResendQueue        : true,
		pingPacket            : {
			operation    : "ping"
		},
		inUse                 : false,
		_stayOpen             : true,
		_opened               : false,
		_queue                : [],
		_connectAttemptCount  : 0,
		request : {
			timeout              : 31000,
			contentType          : "application/json",
			logLevel             : "info",
			transport            : "websocket",
			shared               : false,
			fallbackTransport    : "websocket",
			maxReconnectOnClose  : 0,
			reconnectInterval    : 0x7FFFFFFF,
			enableProtocol       : true,
			enableXDR            : true,
			readResponsesHeaders : false,
			readResponseHeaders  : false,
			ackInterval          : 2000
		},
		initialize : function(requestURL, options){
			_.bindAll(this);

			if(requestURL){
				this.setup(requestURL, options);
				this.open();
			}
		},
		setup : function(requestURL, options){
			options = options || {};

			this.rootParams            = options.rootParams            || {};
			this.reconnectWait         = (options.reconnectWait && options.reconnectWait >= 33 ? options.reconnectWait    : this.reconnectWait);
			this.useResendQueue        = (options.hasOwnProperty("useResendQueue")        ? options.useResendQueue        : this.useResendQueue);
			this.pingInterval          = (options.hasOwnProperty("pingInterval")          ? options.pingInterval          : this.pingInterval);
			this.reconnectAttemptCount = (options.hasOwnProperty("reconnectAttemptCount") ? options.reconnectAttemptCount : this.reconnectAttemptCount);
			this.request.timeout       = (options.hasOwnProperty("atmosphereTimeout")     ? options.atmosphereTimeout     : this.request.timeout);
			this.request.url           = requestURL;
			this.requestParams         = _.defaults(options.requestParams || {}, this.requestParams);

			this.throttledReopen = _.throttle(this.reopen, this.reconnectWait - 33, {trailing : false});


			this.request.onOpen               = this._onOpen;
			this.request.onClose              = this._onClose;
			this.request.onClientTimeout      = this._onClose;
			this.request.onReconnect          = this._onReconnect;
			this.request.onMessage            = this._onMessage;
			this.request.onError              = this._onError;
			this.request.onFailureToReconnect = this._onError;
			this.request.onMessagePublished   = this._onMessagePublished;
		},
		open : function(requestURL){
			this.inUse                = true;
			this._opened              = false;
			this._stayOpen            = true;
			this._connectAttemptCount = 0;
			this.request.url = requestURL || this.request.url;

			this.throttledReopen();

		},
		reopen : function(){
			if(this.subSocket){
				this.subSocket = null;
			}
			this.socket.unsubscribe();

			$(window).unbind("unload.push", this.disconnect);
			$(window).unbind("beforeunload.push", this.close);

			this._log("push.opening  :", this._connectAttemptCount + 1, "of", this.reconnectAttemptCount);
			if(this._connectAttemptCount > this.reconnectAttemptCount){
				this._connectionFailed();
			} else{

				this._closeTriggered = false;
				$(window).bind("unload.push", this.disconnect);
				$(window).bind("beforeunload.push", this.close);
				this.subSocket = this.socket.subscribe(this.request);
			}
			this._connectAttemptCount++;
		},
		send : function(data){

			data._sent = true;
			if(this.rootParams){
				_.defaults(data, this.rootParams);
			}
			if(this.requestParams){
				data.requestParams = data.requestParams || {};
				_.defaults(data.requestParams, this.requestParams);
				if(_.isEmpty(data.requestParams)){
					delete data.requestParams;
				}
			}
			this._logMessage("push.send     :", data);
			if(this.useResendQueue && (!this.subSocket || !this.connected)){
				this._queue.push(data);
				this.throttledReopen();
			} else{
				this.trigger("send", data);
				this.subSocket.push(JSON.stringify(data));
			}

		},
		subscribe : function(message){
			message = message || {};
			message = _.defaults(message, {
				operation     : "subscribe"
			});

			if(message.requestParams && message.requestParams.component){
				message.component = message.requestParams.component;
			}

			if(this.connected){
				this.send(message);
			}
			this.subscribed.push(message);
		},
		unsubscribe : function(message){
			message = message || {};
			message = _.defaults(message, {
				operation     : "unsubscribe"
			});

			if(message.requestParams && message.requestParams.component){
				message.component = message.requestParams.component;
			}

			this.send(message);

			for(var j = this.subscribed.length - 1; j >= 0 ; j--){
				var suscribedMessage = this.subscribed[j];
				if(suscribedMessage.service == message.service){
					this.subscribed.splice(j, 1);
				}
			}
		},
		close : function(){
			$(window).unbind("beforeunload.push", this.close);
			$(window).unbind("unload.push", this.disconnect);

			if(this._closeTriggered) { return; } //Already called once.
			this._closeTriggered = true;

			this.trigger("closing");
			this._protocolSend({
				"data"    : "closed"
			});
			this.disconnect();
			this.trigger("closed");
		},
		disconnect : function(){
			this._stayOpen = false;

			this._stopReopen();
			this._stopPing();

			if(this.subSocket){
				this.subSocket = null;
			}
			this.socket.unsubscribe();
		},
		registerModel : function(pushService, idAttributes){
			idAttributes = _.clone(idAttributes) || [];
			this.registeredModels[pushService] = {
				idAttributes : idAttributes
			};
		},

		makePath : function(){
			return "/" + Array.prototype.slice.call(arguments).join("/");
		},

		_ack : function(){
			if(!this.ack){
				return;
			}
			this._protocolSend({
				"data" : "ACK"
			});
		},
		_ping : function(){
			this._protocolSend(this.pingPacket);
		},
		_protocolSend : function(data){

			if(this.rootParams){
				_.defaults(data, this.rootParams);
			}
			delete data.uuid;
			if(this.requestParams){
				data.requestParams = data.requestParams || {};
				_.defaults(data.requestParams, this.requestParams);
				_.each(data.requestParams, function(value, param){
					if(param.indexOf("_") === 0){
						delete data.requestParams[param];
					} else if (param == "locale"){
						delete data.requestParams[param];
					}

				});
				if(_.isEmpty(data.requestParams)){
					delete data.requestParams;
				}
			}
			if(this.subSocket && this.connected){
				this.subSocket.push(JSON.stringify(data));
			}

		},
		_onOpen : function(response){
			this.rootParams = this.rootParams || {};
			if(!this.uuid){
				this.uuid = this.subSocket && this.subSocket.request && this.subSocket.request.uuid || _.uniqueId("push_");
			}
			this.rootParams.uuid = this.uuid;
			this.request.uuid = this.uuid;

			this._log("push.onOpen   :", response);
			this.connected = true;
			this._opened   = true;
			this._connectAttemptCount = 0;
			this._stopReopen();
			this._sendQueue();
			this.trigger("opened");
			this._startPing();
		},
		_onReconnect : function(request, response){
			this._log("push.onReconnect:", request, response);

			this.connected = true;
			this._opened = true;
			this._connectAttemptCount = 0;

			this._stopReopen();
			this._sendQueue();
			this.trigger("reconnected");
			this.trigger("opened");
			this._startPing();
		},
		_onClose : function(response){
			this.connected = false;
			this._stopPing();
			this._log("push.onClose:", response);

			if(this._stayOpen){
				if(this.reconnectAttemptCount === 0 || this._connectAttemptCount >= this.reconnectAttemptCount){
					this._connectionFailed();
				} else{
					this._startReconnect();
				}
			}
		},
		_onMessagePublished : function(response){
			this._log("push.onMessagePublished:", response);
		},
		_onMessage : function(response){


			var info = null;
			try{
				info = JSON.parse(response.responseBody);
			} catch(e){
				if(response.responseBody.match(/^[a-f0-9\-]+\|\d+$/i)){
					//ignore random atmosphere id
					return;
				} else if(response.responseBody.match(/\}\{/)){
					console.warn("JOINED JSON message:", response.responseBody);
					var messageParts = response.responseBody.split("}{");
					for(var i = 0; i < messageParts.length; i++){
						var part = messageParts[i];
						if(i < messageParts.length - 1){
							part+= "}";
						}
						if(i > 0){
							part = "{" + part;
						}
						console.warn("SPLIT part: ", part);
						this._onMessage({responseBody : part });
					}
				}
			}

			if(_.isEmpty(response.responseBody) || !info) { return this._ack(); }

			if(info.data !== "ACK"){
				this._ack();
			}


			if(info.type){
				info.service = info.type;
			}

			if(!info.hasOwnProperty("requestParams")){
				info.requestParams = {};
			}


			if(!info.hasOwnProperty("data")){
				info.data = {};
			}

			var sender = (this.uuid == info.uuid ? "self:" : "other:");
			var action = info._sent ? "sent:" : "received:";

			this._logMessage("push.onMessage:", info);

			if(info.service){
				var events = [];

				events.push(info.service);
				events.push(action + info.service);
				events.push(sender + info.service);
				events.push(sender + action + info.service);
				if(info.operation){
					events.push(info.service + ":" + info.operation);
					events.push(action + info.service + ":" + info.operation);
					events.push(sender + info.service + ":" + info.operation);
					events.push(sender + action + info.service + ":" + info.operation);
				}
				var id = null;
				if(this.registeredModels[info.service]){
					for(var j in this.registeredModels[info.service].idAttributes){
						if(this.registeredModels[info.service].idAttributes.hasOwnProperty(j)){
							var attribute = this.registeredModels[info.service].idAttributes[j];
							if(info.requestParams && info.requestParams[attribute]){
								id = info.requestParams[attribute];
								break;
							} else if(info.data && info.data[attribute]){
								id = info.data[attribute];
								break;
							}
						}
					}
				}
				if(!id){
					if(info.requestParams && info.requestParams.id){
						id = info.requestParams.id;
					} else if(info.data && info.data.id){
						id = info.data.id;
					} else if(info.requestParams && info.requestParams.type){
						id = info.requestParams.type;
					} else if(info.data && info.data.type){
						id = info.data.type;
					}
				}

				if(id){
					events.push(info.service + ":" + id);
					events.push(action + info.service + ":" + id);
					events.push(sender + info.service + ":" + id);
					events.push(sender + action + info.service + ":" + id);
					if(info.operation){
						events.push(info.service + ":" + id + ":" + info.operation);
						events.push(action + info.service + ":" + id + ":" + info.operation);
						events.push(sender + info.service + ":" + id + ":" + info.operation);
						events.push(sender + action + info.service + ":" + id + ":" + info.operation);
					}
				}
				if(info.data && info.data.id){
					id = info.data.id;
					action += "id:";
					events.push(action + info.service + ":" + id);
					events.push(sender + info.service + ":" + id);
					events.push(sender + action + info.service + ":" + id);
					if(info.operation){
						events.push(action + info.service + ":" + id + ":" + info.operation);
						events.push(sender + info.service + ":" + id + ":" + info.operation);
						events.push(sender + action + info.service + ":" + id + ":" + info.operation);
					}
				}
				this._triggerMessageEvents(events, info);

			}
		},
		_onError : function(response){
			this._log("push.onError:", response);
			this.trigger("error");

			if(!this.connected && !this._opened || (response && response.status == 0)){
				this.throttledReopen();
			}
		},
		_sendQueue : function(){
			var queue = this._queue.slice();
			this._queue = [];
			for(var i = 0; i < queue.length; i++){
				console.warn("resending", queue[i]);
				this.send(queue[i]);
			}

			for(var j = 0; j < this.subscribed.length; j++){
				var message = this.subscribed[j];
				console.warn("resubscribing", message.service);
				this.send(message);
			}
		},
		_log : function(){
			if(this.logging){
				console.log.apply(console, arguments);
			}
		},
		_logMessage : function(message, info){
			if(this.logging){
				try{
					if(_.isString(info.data) || _.contains(["ping", "registered", "ACK"], info.data)) {
						return;
					}
					if(info.operation && info.operation === "ping"){
						return;
					}
					var event = "";
					event+= (info.uuid == this.uuid ? "self :" : "other:");
					event+= (info._sent ? "sent    :" : "received:");
					if(info.service){
						event += info.service + ":";
					}

					if(info.requestParams && info.requestParams.id){
						event += info.requestParams.id + ":";
					} else if(info.data && info.data.id){
						event += info.data.id + ":";
					} else if(info.requestParams && info.requestParams.type){
						event += info.requestParams.type + ":";
					} else if(info.data && info.data.type){
						event += info.data.type + ":";
					}
					if(info.operation){
						event += info.operation + ":";
					}
					if(event.length){
						message += " " + event;
					}

				} catch(e){}

				console.log.call(console, message, _.clone(info));
			}
		},
		_connectionFailed : function(){
			console.error("push.connectionFailed");
			this.disconnect();
			this.trigger("connectionFailed");
		},
		_startReconnect : function(){
			if(this._stayOpen && !this._reconnectInterval){
				this._reconnectInterval = setInterval(this.throttledReopen, this.reconnectWait);
			}
		},
		_stopReopen : function(){
			if(this._reconnectInterval){
				clearInterval(this._reconnectInterval);
				this._reconnectInterval = null;
			}
		},
		_startPing : function(){
			if(this.pingTimeout){
				this._stopPing();
			}
			if(this.pingInterval > 0){
				this.pingTimeout = setInterval(this._ping, this.pingInterval);
				this._ping();
			}
		},
		_stopPing : function(){
			if(this.pingTimeout){
				clearInterval(this.pingTimeout);
				this.pingTimeout = null;
			}
		},
		resumePing : function(){
			if(!this.pingTimeout){
				this._startPing();
			}
		},
		pausePing : function(){
			this._stopPing();
		},
		trigger : function(event){
			var parts = event.split(":");
			for(var i = 0; i < parts.length; i++){
				var tParts = _.clone(parts);
				tParts[i] = "*";
				var wildCardEvent = tParts.join(":");
				if(wildCardEvent === event) { continue; }
				var args = Array.prototype.slice.call(arguments, 0);
				args[0] = wildCardEvent;
				BaseClass.prototype.trigger.apply(this, args);
			}
			return BaseClass.prototype.trigger.apply(this, arguments);
		},
		_triggerMessageEvents : function(events, info){
			var allEvents = [];
			for(var i = 0; i < events.length; i++){
				var event = events[i];
				allEvents.push(event);

				var parts = event.split(":");
				for(var j = 0; j < parts.length; j++){
					var tParts = _.clone(parts);
					tParts[j] = "*";
					var wildCardEvent = tParts.join(":");
					allEvents.push(wildCardEvent);
				}
			}
			allEvents = _.unique(allEvents);
			for(var k = 0; k < allEvents.length; k++){
				BaseClass.prototype.trigger.call(this, allEvents[k], info);
			}
		}
	});

});

