/**
 * WaitingRoom main Backbone View
 */
define('views/waiting-room-preview/main',['require','jquery','underscore','library/vlp/app','views/vlp-base','hbs!tpls/console/waiting-room.handlebars','views/common/layout-tpls','i18n!nls/waiting-room','library/diff','library/cssbeautify'],function (require) {
	"use strict";
	//library dependencies
	var $ = require("jquery"),
		_ = require("underscore");

	var App = require("library/vlp/app"),
		VlpBaseView = require("views/vlp-base"),
		mainTPL = require("hbs!tpls/console/waiting-room.handlebars"),
		LayoutTPLs = require("views/common/layout-tpls"),
		pageTexts = require("i18n!nls/waiting-room"),
		JsDiff = require("library/diff"),
		cssbeautify = require("library/cssbeautify");

	return VlpBaseView.extend({
		name: "WAITING_ROOM_PREVIEW",
		viewport: "width=1300",

		checkBrowserSupported: false,
		checkLoggedIn: false,
		checkMaintenance: false,
		checkEula: false,
		checkTenantPath: false,
		checkSystem: false,

		layoutTemplate: LayoutTPLs.layoutEmpty,
		//Required
		mainTemplate: mainTPL,
		events: {},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize: function (options) {
			var self = this;

			options = options || {};

			_.bindAll(this);

			App.locale.mergeI18n(pageTexts);

			this.once(VlpBaseView.STARTUP_COMPLETE, function () {

				$("body")
					.addClass("catalogs logged-in main-content")
					.removeClass("logged-out");

				this.initializeText();

				if (window.less) {
					window.less.postProcessor = this.captureCss;
					window.less.modifyVars({});
				}
				self.sendMessage({
					operation: "userUiReady"
				});
			}, this);

			//Checks logged in
			this.setup(options);

			$(window).off("message.waitingRoomStyler").on("message.waitingRoomStyler", function (event) {
				try {
					var data = _.isString(event.originalEvent.data) ? JSON.parse(event.originalEvent.data) : event.originalEvent.data;
					self.receivedMessage(data);
				} catch (e) {
				}
			});
		},
		/**
		 * Display the content.
		 */
		render: function () {

			//Always return this for chaining
			return this;
		},

		receivedMessage: function (data) {
			if (data.operation) {
				switch (data.operation) {
					case "update":
						this.update(data.values);
						break;
					case "save" :
						this.save(data.values);
						break;
				}
			}
		},
		sendMessage: function (data) {

			if (window.parent) {
				window.parent.postMessage(data, "*");
			}
		},

		update: function (values) {
			if (values.header) {
				$(".waiting-room-header").text(values.header);
			}
			if (values.background) {
				$("#wrapper").css({'background-color': values.background});
			}
			if (values.subtitle) {
				const subtitle = values.subtitle.replace(/\n\r?/g, '<br />');
				$(".waiting-room-subtitle").html(subtitle);
			}
			if (values.paragraph) {
				const paragraph = values.paragraph.replace(/\n\r?/g, '<br />');
				$(".waiting-room-message").html(paragraph);
			}
			this.sendMessage({
				operation: "userUiStylesUpdated"
			});
		},
		save: function (values) {
			this.recompileLess(values);

			var newCss = this.compress();

			this.sendMessage({
				operation: "userUiSaveComplete",
				css: newCss
			});
		},

		initializeText: function () {
			$('#inline').text(App.i18n("waitingRoom.inLine"));
			$('#users-ahead').text(App.i18n("waitingRoom.usersAhead"));
			$('#estimated-waiting-time').text(App.i18n("waitingRoom.estimatedWaitTime"));
			$('#page-update').text(App.i18n("waitingRoom.pageUpdate"));
			$('#notify-me').text(App.i18n("waitingRoom.notifyMe"));
			$('#leave-queue').text(App.i18n("waitingRoom.leaveQueue"));
			$('#lose-place').text(App.i18n("waitingRoom.losePlace"));
			$('#users-ahead').text(App.i18n("waitingRoom.usersAhead"));
		}
	});
});

