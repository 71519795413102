
define('hbs!tpls/instructor/console/entitlement.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "					<button type=\"button\" class=\"btn broadcast-display\" rel=\"tooltip\" data-placement=\"bottom\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","instructor.entitlement.broadcastDisplayTip",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-broadcasting-text","instructor.entitlement.stopBroadcast",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.entitlement.broadcastDisplay",{"name":"$","hash":{},"data":data}))
    + "</button>\n";
},"3":function(depth0,helpers,partials,data) {
    return "					<div class=\"dropdown more-options\">\n						<button class=\"btn more-options\" data-toggle=\"dropdown\">\n							<i class=\"fa fa-ellipsis-h fa-lg\"></i>\n						</button>\n						<ul class=\"dropdown-menu pull-right\">\n							<li>\n								<a class=\"entitlement-reset-prompt\" data-toggle=\"modal\" data-target=\"#modal_reset\" rel=\"tooltip\" data-placement=\"bottom\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.reset.tip",{"name":"$$","hash":{},"data":data}))
    + ">\n									"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.reset.label",{"name":"$","hash":{},"data":data}))
    + "\n								</a>\n							</li>\n						</ul>\n					</div>\n";
},"5":function(depth0,helpers,partials,data) {
    return " connection-monitoring";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div id=\"lab\">\n\n	<div id=\"info_bar\">\n		<div class=\"info-bar-inner\">\n			<a class=\"btn-back-to-class\" href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"{{classId}}"},"data":data}))
    + "\"><i class=\"fa fa-arrow-left\"></i> <span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.entitlement.backToClass",{"name":"$","hash":{},"data":data}))
    + "</span></a>\n\n			<div class=\"class-name\" data-toggle=\"modal\" data-target=\"#class-info\">\n				<i class=\"fa fa-info-circle\"></i> <span>"
    + this.escapeExpression(((helper = (helper = helpers.className || (depth0 != null ? depth0.className : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"className","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n\n			<div class=\"student-info-id\">\n				<a data-username=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.username : stack1), depth0))
    + "\" class=\"public-profile\" data-id=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" data-name=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\">\n					<img class=\"student-info-images\" src=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.avatar : stack1), depth0))
    + "\" alt=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\">\n				</a>\n\n				<a data-username=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.username : stack1), depth0))
    + "\" class=\"student-info-student-name public-profile\" data-id=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" data-name=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\">"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "</a>\n			</div>\n\n			<div class=\"student-progress\">\n				<div id=\"progressbar\" class=\"student-info-progress\">\n					<div class=\"student-info-progressbar\">\n						<div class=\"progress-bar\" style=\"width: "
    + this.escapeExpression(((helper = (helper = helpers.progress || (depth0 != null ? depth0.progress : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"progress","hash":{},"data":data}) : helper)))
    + "%;\">\n							<span class=\"progress\">"
    + this.escapeExpression((helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.progress : depth0),{"name":"percent","hash":{},"data":data}))
    + "</span>\n						</div>\n					</div>\n\n					<label><span class=\"completed-steps\">"
    + this.escapeExpression(((helper = (helper = helpers.activeStepNumber || (depth0 != null ? depth0.activeStepNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"activeStepNumber","hash":{},"data":data}) : helper)))
    + "</span>/<span class=\"total-steps\">"
    + this.escapeExpression(((helper = (helper = helpers.totalSteps || (depth0 != null ? depth0.totalSteps : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"totalSteps","hash":{},"data":data}) : helper)))
    + "</span>\n					</label>\n					<i class=\"fa fa-book\"></i>\n				</div>\n				<div class=\"time-remaining-wrapper\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.timeRemaining",{"name":"$","hash":{},"data":data}))
    + " <span class=\"time-remaining\">00:00:00</span>\n				</div>\n			</div>\n\n			<div class=\"actions\">\n				<button type=\"button\" class=\"btn take-over\" rel=\"tooltip\" data-placement=\"bottom\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","instructor.entitlement.takeOverTip",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-taken-text","instructor.entitlement.stopTakeOver",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.entitlement.takeOver",{"name":"$","hash":{},"data":data}))
    + "</button>\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.broadcastDisplayEnabled : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.allowInstructorToResetLab : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "			</div>\n\n			<div class=\"settings-wrapper\">\n				<button type=\"button\" class=\"btn-no-style settings\" data-toggle=\"modal\" data-target=\"#modal_settings\" rel=\"tooltip\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.settings.title",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"left\"><span class=\"fa fa-cog\"></span></button>\n			</div>\n		</div>\n	</div>\n\n	<div id=\"info_bar_maximize\" class=\"hide"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.studentConsoleConnectionMonitoring : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n		<span class=\"time-remaining-label\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.timeRemaining",{"name":"$","hash":{},"data":data}))
    + "</span> <span class=\"time-remaining\">00:00:00</span>\n	</div>\n	<div id=\"vm\">\n		<div id=\"vm_group\" class=\"temp-loading\"><div id=\"vm_above_bar\"></div></div>\n		<div class=\"loading building-lab\">\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.building",{"name":"$","hash":{},"data":data}))
    + "\n		</div>\n	</div>\n	<div id=\"saas_console\"></div>\n</div>\n\n<div id=\"modal_reset\" class=\"modal hide fade global fluid-height\" data-backdrop=\"static\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header no-close\">\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-refresh\"></span>\n					<span class=\"reset-confirmation\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.reset.title",{"name":"$","hash":{},"data":data}))
    + "\n					</span>\n					<span class=\"reset-sent hide\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.reset.resetTitle",{"name":"$","hash":{},"data":data}))
    + "\n					</span>\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<div class=\"reset-confirmation\">\n					<div class=\"alert alert-danger alert-icon\">\n						<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.reset.warning",{"name":"$","hash":{},"data":data}))
    + "</h4>\n						<p>\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.reset.message",{"name":"$","hash":{},"data":data}))
    + "\n						</p>\n					</div>\n					<p>\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.reset.confirmation",{"name":"$","hash":{},"data":data}))
    + "\n					</p>\n					<button type=\"button\" class=\"btn cancel\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</button>\n					<button type=\"button\" class=\"btn btn-danger entitlement-reset\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","console.entitlement.reset.resetting",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.confirm",{"name":"$","hash":{},"data":data}))
    + "</button>\n					<button type=\"button\" class=\"btn done hide\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n					<div class=\"loading reset-spinner hide\"></div>\n				</div>\n\n				<div class=\"reset-sent hide\">\n					<div class=\"alert alert-info\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.reset.resetMessage",{"name":"$","hash":{},"data":data}))
    + "\n					</div>\n					<button type=\"button\" class=\"btn done entitlement-reset-confirmed\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"entitlement_settings\"></div>\n";
},"useData":true});
Handlebars.registerPartial('instructor_console_entitlement', t);
return t;
});

