
define('hbs!tpls/common/tips.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"welcome.title",{"name":"$","hash":{},"data":data}))
    + "\n";
},"3":function(depth0,helpers,partials,data) {
    return "					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"welcome.tips",{"name":"$","hash":{},"data":data}))
    + "\n";
},"5":function(depth0,helpers,partials,data) {
    return "					<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n";
},"7":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.length : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.program(16, data, 0),"data":data})) != null ? stack1 : "");
},"8":function(depth0,helpers,partials,data) {
    var stack1;

  return "\n\n						<div id=\"tip_carousel\" class=\"carousel slide\" data-interval=\"false\" data-pause=\"false\" data-loop=\"false\">\n							<!-- Carousel items -->\n							<div class=\"carousel-inner\">\n"
    + ((stack1 = helpers.each.call(depth0,depth0,{"name":"each","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "							</div>\n							<!-- Carousel nav -->\n							<a class=\"carousel-control left hide\" href=\"#tip_carousel\" data-slide=\"prev\">&lsaquo;</a>\n							<a class=\"carousel-control right\" href=\"#tip_carousel\" data-slide=\"next\">&rsaquo;</a>\n						</div>\n						<div class=\"never-show-prompt\">\n							<label>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.autoHide : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.program(14, data, 0),"data":data})) != null ? stack1 : "")
    + "							</label>\n						</div>\n";
},"9":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "									<div class=\"tip item\">\n										<h2>"
    + this.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n\n										<div>"
    + ((stack1 = ((helper = (helper = helpers.tip || (depth0 != null ? depth0.tip : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"tip","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n									</div>\n";
},"11":function(depth0,helpers,partials,data) {
    var stack1;

  return "									<input type=\"checkbox\" id=\"no_tips\" "
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.showTips : depth0),{"name":"unless","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "/>\n									"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"welcome.neverShowLabel",{"name":"$","hash":{},"data":data}))
    + "\n";
},"12":function(depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"14":function(depth0,helpers,partials,data) {
    var stack1;

  return "									<input type=\"checkbox\" id=\"show_tips\" "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.showTips : depth0),{"name":"if","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "/>\n									"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"welcome.showTipsAtLogin",{"name":"$","hash":{},"data":data}))
    + "\n";
},"16":function(depth0,helpers,partials,data) {
    return "						<div class=\"no-support-options\">\n							<div class=\"alert\">\n								"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"welcome.noTips",{"name":"$","hash":{},"data":data}))
    + "\n							</div>\n						</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"modal-dialog\">\n	<div class=\"modal-content\">\n		<div class=\"modal-header\">\n			<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n			<h3 class=\"modal-title\">\n				<span class=\"fa fa-fw fa-lightbulb-o\"></span>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.autoHide : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "			</h3>\n		</div>\n		<div class=\"modal-body\">\n			<div id=\"tips\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.loading : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('common_tips', t);
return t;
});

