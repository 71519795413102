define('views/common/layout-tpls',['require','hbs!tpls/layout/layout.handlebars','hbs!tpls/layout/layout-no-login.handlebars','hbs!tpls/layout/layout-empty.handlebars','hbs!tpls/layout/header.handlebars','hbs!tpls/layout/global-modals.handlebars','hbs!tpls/layout/global-components.handlebars'],function (require) {
	"use strict";

	//class dependencies
	var layoutTpl           = require("hbs!tpls/layout/layout.handlebars"),
	    layoutNoLoginTpl    = require("hbs!tpls/layout/layout-no-login.handlebars"),
	    layoutEmpty         = require("hbs!tpls/layout/layout-empty.handlebars"),
	    headerTpl           = require("hbs!tpls/layout/header.handlebars"),
//	    footerTpl           = require("hbs!tpls/layout/footer.handlebars"),
	    globalModalsTpl     = require("hbs!tpls/layout/global-modals.handlebars"),
	    globalComponentsTpl = require("hbs!tpls/layout/global-components.handlebars");

	return {
		layout           : layoutTpl,
		layoutNoLogin    : layoutNoLoginTpl,
		layoutEmpty      : layoutEmpty,
		header           : headerTpl,
//		footer           : footerTpl,
		globalModals     : globalModalsTpl,
		globalComponents : globalComponentsTpl
	};
});
