define('views/compatibility/tests/popups',['require','underscore','../test','hbs!tpls/compatibility/tests/popups.handlebars'],function (require) {
	"use strict";

	var _ = require("underscore");

	//class dependencies
	var TestBase = require("../test"),
	    template = require("hbs!tpls/compatibility/tests/popups.handlebars");

	return TestBase.extend({
		template : template,
		name : "popups",
		progressStep : 0,
		popupDelay : 5000,
		progressFrequency : 50,
		initialize : function(){
			_.bindAll(this);
			this.progressStep = 0;
			this.totalProgressSteps = Math.ceil(this.popupDelay / this.progressFrequency);
		},
		run : function(){
			var self = this;

			this.setStatus("running");
			this.progressStep = 0;
			this.progressInterval = setInterval(this.updateProgress, this.progressFrequency);

			setTimeout(function(){
				//Delay popup call because chrome will treat a call within a certain amount of time as still being
				//in response of the user interaction to run the tests.
				clearInterval(self.progressInterval);

				var popup = window.open("", "Popup Test", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=50,height=20,top=0,left=0");
				if(popup && popup.document){
					popup.document.body.innerHTML = "<p>Popup Test - Please Close Window</p>";
				}
				self.checkPopup(popup);
			}, this.popupDelay);
		},
		checkPopup: function(popup){
			var self = this;
			if (popup) {
				setTimeout(function () {
					self.isPopupBlocked(popup);
				}, 200);
			} else {
				this.setStatus("warning");
				this.trigger(TestBase.COMPLETE, false);
			}
		},
		isPopupBlocked: function(popup){
			if (popup.innerHeight <= 0){
				this.setStatus("warning");
				this.trigger(TestBase.COMPLETE, false);
			} else {
				this.setStatus("success");
				this.trigger(TestBase.COMPLETE, true);
			}
			if(popup && popup.close){
				popup.close();
			}
		},
		updateProgress: function(){
			this.progressStep++;
			this.trigger(TestBase.PROGRESS, this.progressStep / this.totalProgressSteps);
		}
	});

});

