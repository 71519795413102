define('library/underscore/math',['require','underscore'],function (require) {
	"use strict";
	var _  = require("underscore");

	_.mixin({
		rescale : function(value, min, max, xmin, xmax){
			if(min  === null || min  === undefined){ min  = 0; }
			if(max  === null || max  === undefined){ max  = 1; }
			if(xmin === null || xmin === undefined){ xmin = 0; }
			if(xmax === null || xmax === undefined){ xmax = 1; }

			var denom = max - min;
			return ( (value * (xmax - xmin)) / denom ) - ( (min * xmax - max *  xmin) / denom );
		},
		clamp : function(number, low, high){
			if(low  === null){ low  = 0; }
			if(high === null){ high = 1; }

			if(number > high) { return high; }
			if(number < low)  { return low; }
			return number;
		}
	});

	return _;
});
