define('widgets/widget',['require','library/vlp/app','../library/vlp/view'],function (require) {
	"use strict";

	//class dependencies
	var App      = require("library/vlp/app"),
	    BaseView = require("../library/vlp/view");

	return BaseView.extend({
		source : "",
		updateData : function(){
			console.error("Must be implemented");
		},
		subscribe : function(){
			var tenant = App.getTenant();
			var data = {
				service       : "stats",
				operation     : "subscribe",
				component     : this.sourceComponent || "core",
				data          : {},
				requestParams : {
					tenant  : tenant,
					type    : this.source
				}
			};

			App.push.off("closing", this.unsubscribe).on("closing", this.unsubscribe);

			App.push.on("received:*:" + this.source + ":update", this.pushUpdateData);
			App.push.send(data);
			return true;
		},
		unsubscribe : function(){
			var tenant = App.getTenant();
			var data = {
				service       : "stats",
				operation     : "unsubscribe",
				data          : {},
				requestParams : {
					tenant  : tenant,
					type    : this.source
				}
			};

			App.push.off("received:*:" + this.source + ":update", this.pushUpdateData);
			App.push.send(data);
		},
		pushUpdateData : function(info){
			this.updateData(info.data);
		}
	});
	
});

