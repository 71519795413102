
define('hbs!tpls/speed-test/test-results.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.test : depth0),"runTestDownload",{"name":"eq","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var helper;

  return "			<li class=\""
    + this.escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"type","hash":{},"data":data}) : helper)))
    + " not-run\">\n				<div class=\"name\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,(helpers.concat || (depth0 && depth0.concat) || helpers.helperMissing).call(depth0,"speedTest.tests.",(depth0 != null ? depth0.type : depth0),".downloadLabel",{"name":"concat","hash":{},"data":data}),{"name":"$","hash":{},"data":data}))
    + "</div>\n				<div class=\"result\">---</div>\n				<div class=\"indicator\"></div>\n			</li>\n";
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.test : depth0),"runTestLatency",{"name":"eq","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"5":function(depth0,helpers,partials,data) {
    var helper;

  return "			<li class=\""
    + this.escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"type","hash":{},"data":data}) : helper)))
    + " not-run\">\n				<div class=\"name\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,(helpers.concat || (depth0 && depth0.concat) || helpers.helperMissing).call(depth0,"speedTest.tests.",(depth0 != null ? depth0.type : depth0),".latencyLabel",{"name":"concat","hash":{},"data":data}),{"name":"$","hash":{},"data":data}))
    + "</div>\n				<div class=\"result\">---</div>\n				<div class=\"indicator\"></div>\n			</li>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<ul class=\"speed\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.tests : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n<ul class=\"latency\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.tests : depth0),{"name":"each","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n";
},"useData":true});
Handlebars.registerPartial('speed_test_test_results', t);
return t;
});

