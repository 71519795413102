
define('hbs!tpls/console/walkthrough/step-6.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var helper;

  return "<div class=\"console-walkthrough-title\">\n	<button class=\"btn btn-default console-walkthrough-exit\">&times;</button>\n	<span class=\"console-walkthrough-step-number\">"
    + this.escapeExpression(((helper = (helper = helpers.stepNumber || (depth0 != null ? depth0.stepNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"stepNumber","hash":{},"data":data}) : helper)))
    + "</span>\n	"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.consoleControls.title",{"name":"$","hash":{},"data":data}))
    + "\n</div>\n\n<div class=\"console-walkthrough-content step-"
    + this.escapeExpression(((helper = (helper = helpers.stepNumber || (depth0 != null ? depth0.stepNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"stepNumber","hash":{},"data":data}) : helper)))
    + "\">\n	<dl>\n		<dt>\n			<span class=\"fa fa-lg fa-fw fa-desktop\"></span>\n		</dt>\n		<dd>\n			<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.consoleControls.fullScreen.title",{"name":"$","hash":{},"data":data}))
    + "</strong><br/>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.consoleControls.fullScreen.description",{"name":"$","hash":{},"data":data}))
    + "\n		</dd>\n		<dt>\n			<span class=\"fa fa-lg fa-fw fa-arrows-alt\"></span>\n		</dt>\n		<dd>\n			<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.consoleControls.maximize.title",{"name":"$","hash":{},"data":data}))
    + "</strong><br/>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.consoleControls.maximize.description",{"name":"$","hash":{},"data":data}))
    + "\n		</dd>\n		<dt>\n			<span class=\"fa fa-lg fa-fw fa-caret-left\"></span>\n		</dt>\n		<dd>\n			<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.consoleControls.dockLeft.title",{"name":"$","hash":{},"data":data}))
    + "</strong><br/>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.consoleControls.dockLeft.description",{"name":"$","hash":{},"data":data}))
    + "\n		</dd>\n		<dt>\n			<span class=\"fa fa-lg fa-fw fa-caret-right\"></span>\n		</dt>\n		<dd>\n			<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.consoleControls.dockRight.title",{"name":"$","hash":{},"data":data}))
    + "</strong><br/>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.consoleControls.dockRight.description",{"name":"$","hash":{},"data":data}))
    + "\n		</dd>\n		<dt>\n			<span class=\"fa fa-lg fa-fw fa-rotate-left\"></span>\n		</dt>\n		<dd>\n			<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.consoleControls.reestablishConnection.title",{"name":"$","hash":{},"data":data}))
    + "</strong><br/>\n			<em class=\"note note-end note-start\">\n				<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.general.note",{"name":"$","hash":{},"data":data}))
    + ": </strong>\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.consoleControls.reestablishConnection.description",{"name":"$","hash":{},"data":data}))
    + "\n			</em>\n		</dd>\n		<dt>\n			<span class=\"fa fa-lg fa-fw fa-external-link\"></span>\n		</dt>\n		<dd>\n			<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.consoleControls.floatDock.title",{"name":"$","hash":{},"data":data}))
    + "</strong><br/>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.consoleControls.floatDock.description",{"name":"$","hash":{},"data":data}))
    + "\n		</dd>\n	</dl>\n</div>\n\n<div class=\"console-walkthrough-actions\">\n	<button class=\"btn btn-primary console-walkthrough-prev\"><span class=\"fa fa-va fa-lg fa-caret-left\"></span> "
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"previous",{"name":"$","hash":{},"data":data}))
    + "</button>\n	<button class=\"btn btn-primary console-walkthrough-done\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.actions.done",{"name":"$","hash":{},"data":data}))
    + "</button>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('console_walkthrough_step_6', t);
return t;
});

