/**
 * Check for date format examples: http://blog.stevenlevithan.com/archives/date-time-format
 * for dateFormatFull, timeFormat, and dayFormat
 */
define('nls/root/common',{
	"languageName" : "English",
	"header"          : {
		"title"          : "VMware Lab Platform",
		"greeting"       : "Welcome,",
		"tenant"         : "Tenant:",
		"systemMessages" : "Messages",
		"liveChat"       : "Live Chat",
		"help"           : "Help",
		"language"       : "Change Language",
		"about"          : "About",
		"supportOptions" : "Support Options",
		"tips"           : "Tips",
		"privacy"        : "Privacy",
		"privacyPolicy"  : "Privacy Policy",
		"cookieSettings" : "Cookie Settings",
		"profile"        : "My Profile",
		"changeTenant"   : "Tenant",
		"login"          : "Login/Register",
		"logOut"         : "Log Out",
		"helpRequest"    : "Raise Hand",
		"helpRequested"  : "Raise Hand - Help Requested",
		"userGuide"      : "User Guide (pdf)",
		"privacyUrl"     : "https://www.vmware.com/help/privacy.html"
	},
	"footer"          : {
		"poweredBy" : "Powered by"
	},
	"logoutPrompt"    : {
		"title"   : "Are you sure you want to log out?",
		"confirm" : "Yes, continue with log out",
		"message" : "User will be logged out from all sessions"
	},
	"supportOptions"  : {
		"title"   : "Support Options",
		"loading" : "Loading support options...",
		"empty"   : "No Support Options"
	},
	"loginPrompt"     : {
		"pageTitle"       : "Login",
		"title"           : "Enter Username and Password",
		"error"           : "Invalid Username or Password.",
		"username"        : "Username",
		"password"        : "Password",
		"login"           : "Login",
		"loggingIn"       : "Logging In...",
		"providePassword" : "I have a password",
		"copyright"       : "Copyright &copy; 2005-%s Broadcom. All Rights Reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries.",
		"privacy"         : "We collect data to deliver, analyze and improve the service, to customize your experience, and for other purposes set out in our <a href=\"https://www.vmware.com/help/privacy.html\" target=\"_blank\">Privacy Notice <i class=\"fa fa-external-link\"></i></a>.",
		"partialSupport" : {
			"title"            : "Remote Control Login",
			"description"      : "This device can act as a \"remote\" control, where you can manage your console switching, view the manual, and operate other ancillary functions while keeping the console focused on your primary screen.",
			"tokenTitle"       : "How do I get a token?",
			"tokenDescription" : "To receive a token, click on \"Split to Second Device\" on a panel in an active console session.",
			"token"            : "Token"
		},
		"errors"         : {
			"error"           : "Error",
			"general"         : "Invalid Username or Password.",
			"enterUsername"   : "Enter your username.",
			"enterPassword"   : "Enter your password.",
			"enterEmail"      : "Enter your email.",
			"enterName"       : "Enter your name.",
			"passwordConfirm" : "Passwords do not match.",
			"invalidUsername" : "Invalid username.",
			"noDirectLogin"   : "Direct login to VMware Learning Platform is disabled.",
			"noCredits"       : "You do not have enough credits or incomplete entitlements to log in.",
			"captcha"         : "Please confirm you are a human.",
			"otpUsed"         : "You cannot log in with a used One Time Password. Please log in using your permanent password. If you've forgotten your password, please choose \"Forget Password?\" above."
		},
		"forgotPassword" : {
			"button"                  : "Forgot Password?",
			"title"                   : "Forgot Password?",
			"expiredTitle"            : "Your password has expired",
			"description"             : "You can reset your password by sending a verification email or by answering your security questions",
			"buttonEmail"             : "Send Email",
			"buttonSecurityQuestions" : "Answer Questions",
			"email"                   : {
				"title"       : "Enter Username",
				"description" : "Enter your username and a link will be sent to your email address to reset your password.",
				"username"    : "Username",
				"submit"      : "Send",
				"sent"        : "If your account exists, you will receive a message with the reset instructions.",
				"sending"     : "Sending..."
			},
			"securityQuestions"       : {
				"title"       : "Answer Security Question",
				"description" : "Please answer your security question below",
				"username"    : "Answer",
				"submit"      : "Answer",
				"sent"        : "If your account exists, you will receive a message with the reset instructions.",
				"sending"     : "Sending..."
			}
		},
		"expiredPassword" : {
			"title"           : "Password Expired",
			"description"     : "Your password has expired. Please enter a new one.",
			"passwordCurrent" : "Current Password",
			"passwordNew"     : "New Password",
			"passwordConfirm" : "Confirm Password",
			"sending"         : "Sending...",
			"submit"          : "Change Password",
			"success"         : "Password has been changed."
		},
		"register" : {
			"button"          : "Register?",
			"title"           : "New User Registration",
			"description"     : "Sign up for a new account.",
			"name"            : "Full Name",
			"firstName"       : "first",
			"lastName"        : "last",
			"email"           : "Email",
			"company"         : "Company",
			"password"        : "Password",
			"passwordConfirm" : "Confirm Password",
			"submit"          : "Sign Up",
			"submitting"      : "Submitting...",
			"recaptcha" : {
				"loading"           : "Loading reCAPTCHA",
				"load"              : "Reload",
				"cannotLoad"        : "Cannot load reCAPTCHA",
				"tryAgain"          : "Try Again",
				"incorrectResponse" : "Incorrect please try again",
				"reload"            : "Get another CAPTCHA",
				"getImage"          : "Get an image CAPTCHA",
				"getAudio"          : "Get an audio CAPTCHA",
				"enterWords"        : "Enter the words above:",
				"enterNumbers"      : "Enter the numbers you hear:",
				"help"              : "Help"
			},
			"alternativeCaptcha"  : {
				"enterCharacters"   : "Enter the characters above:",
				"label" : "CAPTCHA"
			}
		},
		"registered" : {
			"title"           : "Registered",
			"description"     : "Please check your email account for the verification message."
		},
		"SSO" : {
			"or" : "Or",
			"loginWith" : "Login with %(name)s"
		}
	},
	"profile"         : {
		"title"             : "Profile",
		"confirm"           : "Loading Profile Information...",
		"avatar"            : "Avatar",
		"avatarTooltip"     : "Create or change or avatar at Gravatar.com",
		"privacyPrompt"     : "Select whether or not you want your information to be either public or private by clicking on the lock at the end of the field.",
		"privateTooltip"    : "Set as Private",
		"publicTooltip"     : "Set as Public",
		"image"             : "Profile Image",
		"change"            : "Change",
		"showPublicProfile" : "Preview Public Profile",
		"general"           : {
			"title"              : "General",
			"firstName"          : "First Name",
			"lastName"           : "Last Name",
			"displayName"        : "Display Name",
			"displayNameMessage" : "If you are tagged as the author of a lab, then your display name will always be public.",
			"company"            : "Company",
			"username"           : "Username"
		},
		"contact"           : {
			"title"              : "Contact Information",
			"email"              : "Email Address",
			"phone"              : "Phone Number",
			"streetAddress1"     : "Address",
			"streetAddress2"     : "Address Line 2",
			"city"               : "City",
			"stateAndPostalCode" : "State and Zip Code",
			"country"            : "Country",
			"confirmPassword"    : "Confirm your password"
		},
		"preferences"       : {
			"title"           : "Preferences",
			"defaultTenant"   : "Default Tenant",
			"language"        : "Language",
			"location"        : "Location",
			"timezone"        : "Time Zone",
			"detectTimezone"  : "Detect",
			"showTimezoneMap" : "Show Map / Auto Detect",
			"timezoneTitle"   : "Select Timezone",
			"showTips"        : "Show welcome tips"
		},
		"about"             : {
			"title"         : "About",
			"shortBio"      : "Short Bio"
		},
		"password"          : {
			"title"           : "Password",
			"newPassword"     : "New Password",
			"passwordConfirm" : "Re-enter New Password",
			"passwordCurrent" : "Current Password",
			"currentError"    : "Please enter your current password",
			"expired"         : "Your password has expired. You must update your password.",
			"displayPassword" : "Display password as I type",
			"save"            : "Update Password"
		},
		"securityQuestions" : {
			"title"           : "Security Questions",
			"question"        : "Question",
			"answer"          : "Answer",
			"showAnswers"     : "Display Answers",
			"confirmPassword" : "Confirm your Password",
			"custom"          : "Custom Question",
			"save"            : "Update Security Questions",
			"answerTooLong"   : "Your answer is too long. It must be less than 255 characters total. Your answer is %s characters.",
			"questionError"   : "Please answer this question",
			"emptyPrompt"     : "You do not have any security questions. You must set some now.",
			"description"     : "You may change the questions to anything you like or use the default ones."
		},
		"saveChanges"       : "Save Changes"
	},
	"announcements"   : {
		"title"   : "Announcements",
		"loading" : "Loading announcements...",
		"empty"   : "No announcements."
	},
	"offers"          : {
		"title"   : "Offers",
		"loading" : "Loading offers...",
		"empty"   : "No offers to show."
	},
	"chat"            : {
		"title"        : "Support Chat",
		"messageLabel" : "Message:",
		"sendLabel"    : "Send",
		"introMessage" : "Please wait for a support technician to connect in. Thank you!"
	},
	"systemMessage"   : {
		"title"         : "System Messages",
		"acknowledge"   : "Acknowledge",
		"acknowledging" : "Acknowledging...",
		"tooltip"       : "Once you acknowledge this message it will no longer be shown.",
		"noMessages"    : "No messages"
	},
	"welcome"         : {
		"title"           : "Welcome",
		"tips"            : "Tips",
		"neverShowLabel"  : "Never show again.",
		"showTipsAtLogin" : "Show tips after login.",
		"noTips"          : "No tips."
	},
	"share"           : {
		"title" : "Share",

		"invite" : {
			"buttonShare"   : "Share",
			"buttonInvite"  : "Invite a friend",
			"description"   : "Invite a friend to take this lab.",
			"shareText"     : "Come try the following lab. I found it to be awesome!\n%(lab_name)s\n%(user_name)s",
			"shareTitle"    : "I would like to share this lab with you",
			"directUrlTitle": "Direct link to this lab:",
			"directUrlTip"  : "Press Ctrl+C (⌘+C) to copy"
		}
	},
	"publicProfile" : {
		"title"        : "Profile Info",
		"username"     : "Username",
		"biography"    : "Biography:",
		"name"         : "Name:",
		"address"      : "Address:",
		"phone"        : "Phone Number:",
		"authoredLabs" : "Labs Authored:",
		"email"        : "Email",
		"company"      : "Company",
		"location"     : "Location",
		"pleaseLogin"  : "Please log in to view this author's profile."
	},
	"tenants"         : {
		"title"       : "Change your tenant",
		"default"     : "Default",
		"noTenants"   : "No available tenants.",
		"maintenance" : "maintenance (offline)"
	},
	"switchTenant": {
		"title":           "Switch to %(tenant)s tenant?",
		"body":            "You are currently logged into the \"%(otherTenant)s\" tenant. You will need to log out of \"%(otherTenant)s\" before you can access the \"%(tenant)s\" tenant.",
		"options":         "What would you like to do?",
		"gotoOtherTenant": "Return to the \"%(otherTenant)s\" tenant",
		"switch":          "Log out of \"%(otherTenant)s\" and access \"%(tenant)s\""
	},
	"byod"         : {
		"title"        : "Choose your section",
		"sectionFull"  : "This section is full. You will need to move to another section.",
		"wrongSection" : "Incorrect section.",
		"fullTitle"    : "BYODs full",
		"fullMessage"  : "There is currently no free BYOD space available. Please try again later."
	},
	"about"           : {
		"title" : "About",
		"body"  : "<P>Copyright &copy; 2005-%s Broadcom. All Rights Reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. This product is protected by copyright and intellectual property laws in the United States and other countries as well as by international treaties. VMware products are covered by one or more patents listed at <a href='http://www.vmware.com/go/patents' target='_blank'>http://www.vmware.com/go/patents</a>.</P> <P>VMware is a registered trademark or trademark of VMware LLC in the United States and other jurisdictions. All other marks and names mentioned herein may be trademarks of their respective companies.</P><P>The following is <a href='http://content.vmwarelearningplatform.com/files/generic?name=open_source_license_VLP.txt' target='_blank'>a list of the open source packages</a> used to run this service. VMware strives to attribute all of the open source it utilizes to its proper origins. The open source software used by VMware for this service can be found at <a href='http://download3.vmware.com/software/vmw-tools/vlp/VLP-OSS.zip' target='_blank'>http://download3.vmware.com/software/vmw-tools/vlp/VLP-OSS.zip</a>.</P>"
	},
	"securityPrompts" : {
		"answer"               : "Answer",
		"answerQuestion"       : "Please answer your security question",
		"username"             : "Username",
		"continue"             : "Continue",
		"passwordSaved"        : "Password Saved!",
		"showAnswer"           : "Show Answer",
		"nextQuestionMessage"  : "Please answer one more question.",
		"passwordSavedMessage" : "Your password has been saved. <br/> If your account is enabled then you may log back in now.",
		"fetchQuestion"        : {
			"title"       : "Retrieve Security Question",
			"description" : "Please enter your user name to retrieve your security question",
			"fetch"       : "retrieve question",
			"fetching"    : "retrieving...",
			"fetchAgain"  : "change question"
		}
	},
	"helpCenter" : {
		"title"          : "Help Center",
		"requestCaptain" : "Request Captain",
		"helpDeskTicket" : "Help Desk Issue",
		"summary"        : "Summary",
		"detail"         : "Detail",
		"sent"           : "Sent",
		"errors" :{
			"enterSummary" : "Enter Summary"
		}
	},
	"helpRequests" : {
		"proctorEnRoute"   : "A proctor is en route to help you.",
		"captainEnRoute"   : "A captain is en route to help you.",
		"userEnRoute"      : "%s is en route to help you.",
		"captainRequested" : "Captain requested.",
		"tooSoon"          : "Help requested too soon after the last help request. The help request will be automatically submitted again after a short delay. Close this popup to cancel."
	},
	"title"           : "VMware Lab Platform",
	"commonTitle"     : "VMware Lab Platform",
	"titleSeparator"  : " | ",
	"submit"          : "Submit",
	"yes"             : "Yes",
	"no"              : "No",
	"close"           : "Close",
	"cancel"          : "Cancel",
	"canceling"       : "Canceling...",
	"tryAgain"        : "Try Again",
	"refresh"         : "Refresh",
	"loading"         : "Loading...",
	"fetchMore"       : "Fetch more",
	"saving"          : "Saving...",
	"updating"        : "Updating...",
	"submitting"      : "Submitting...",
	"error"           : "Error!",
	"updated"         : "Updated!",
	"unavailable"     : "Unavailable",
	"checkingStatus"  : "Checking status...",
	"next"            : "Next",
	"previous"        : "Previous",
	"more"            : "More",
	"search"          : "Search",
	"continue"        : "Continue",
	"errors" : {
		"general"                 : "Error communicating with the server. ",
		"getSystem"               : "Could not retrieve system info. The system may be down temporarily for maintenance.",
		"tenantInvalid"           : "Invalid Tenant.",
		"tenantNotFound"          : "You have specified an invalid tenant. Please check the URL and try again.",
		"retry"                   : "There was an error communicating with the server.",
		"thirdPartyCookies"       : "To use this website, your browser must be configured to allow third party cookies.",
		"thirdPartyCookiesMobile" : "To use this website, your browser must be configured to allow third party cookies. Try opening this page in your device's regular browser.",
		"pushConnection"          : "Could not setup WebSocket connection.",
		"websockethubConnection"  : "Could not connect to websockethub.",
		"message"                 : "Message:",
		"accessDenied"            : "Access Denied",
		"accessDeniedMsg"         : "You do not have access to the requested course code",
		"codes" : {
			"9000" : "Sorry, we were not able to find a user with that username and password. Passwords are case-sensitive.",
			"9004" : "Your credentials are expired. Please contact your system administrator.",
			"9007" : "This account has elevated privileges and must authenticate with a password.",
			"9008" : "Your account is deactivated. Please contact your system administrator.",
			"9009" : "Your account is expired. Please contact your system administrator.",
			"9010" : "Your account is locked. Please contact your system administrator.",
			"9011" : "Your account is not verified. Please check your email and verify your account first.",
			"9012" : "Too many failed login attempts. Please wait %(retryAfter)02d second(s) and try again.",
			"9013" : "This account has been temporarily locked after too many failed login attempts. Please wait %(retryAfter)02d second(s) and try again."
		}
	},
	"warnings" : {
		"verifyByDate"            : "Please verify your account by %s.",
		"safariSeven"             : "There is a known incompatibility with Safari 7 and the web based virtual machine consoles.  If you have issues getting connected to the virtual machines in your lab, please try using Firefox or Chrome.",
		"safariFullscreen"        : "Safari does not allow keyboard access when in full-screen mode. If you need keyboard access, please exit full-screen mode.",
		"safariPrivateBrowsing"   : "When using a private (incognito) window, some features of this site might not operate correctly.",
		"cookiesDisabled"         : "To use this website, your browser must be configured to allow cookies. This app may not operate correctly.",
		"storageDisabled"         : "Your browser has deactivated localStorage and sessionStorage. Some features of this site might not operate correctly.",
		"localStorageDisabled"    : "Your browser has deactivated localStorage. Some features of this site might not operate correctly.",
		"sessionStorageDisabled"  : "Your browser has deactivated sessionStorage. Some features of this site might not operate correctly.",
		"localStorageFull"        : "Your browser has run out of localStorage space. Some features of this site might not operate correctly.",
		"sessionStorageFull"      : "Your browser has run out of sessionStorage space. Some features of this site might not operate correctly."
	},
	"infos" : {
		"localeLoading"            : "<span class='loading'></span> Loading new translation.",
		"stationAssigned"          : "This station is assigned to %s."
	},
	"generalMessages": {
		"retry"                  : "Trying again in: "
	},
	"verification" : {
		"warning"     : "Your account is not verified. Please verify your account by %s.",
		"resendEmail" : "Resend confirmation email",
		"success"     : "Verification email sent.",
		"error"       : "Error sending verification email."
	},
	"validationMessages" : {
		"required"      : "[{0}] is required",
		"acceptance"    : "[{0}] must be accepted",
		"min"           : "[{0}] must be greater than or equal to {1}",
		"max"           : "[{0}] must be less than or equal to {1}",
		"range"         : "[{0}] must be between {1} and {2}",
		"length"        : "[{0}] must be {1} characters",
		"minLength"     : "[{0}] must be at least {1} characters",
		"maxLength"     : "Please limit [{0}] to {1} characters or fewer.",
		"rangeLength"   : "[{0}] must be between {1} and {2} characters",
		"oneOf"         : "[{0}] must be one of: {1}",
		"equalTo"       : "[{0}] must be the same as {1}",
		"digits"        : "[{0}] must only contain digits",
		"number"        : "[{0}] must be a number",
		"email"         : "[{0}] must be a valid email",
		"url"           : "[{0}] must be a valid url",
		"inlinePattern" : "[{0}] is invalid"
	},
	"time"            : {
		"day"     : "%d day",
		"days"    : "%d days",
		"hour"    : "%d hour",
		"hours"   : "%d hours",
		"minute"  : "%d minute",
		"minutes" : "%d minutes"
	},
	"timeRemainingFormat" : "%(hours)02d:%(minutes)02d:%(seconds)02d",
	"dateFormatFull"      : "m/d/yy h:MM:ss TT",
	"timeFormat"          : "hh:MM:ss",
	"dayFormat"           : "mmm. dd, yyyy",
	"decimalSeparator"    : ".",
	"thousandsSeparator"  : ",",
	"percent"             : "%s%%",
	"dayNames"        : [
		"Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat",
		"Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
	],
	"monthNames"      : [
		"Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
		"January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
	],
	"language-chooser":{
		"title"      : "Change Language",
		"active"     : "Active",
		"change"     : "Change",
		"default"    : "Default",
		"manualOnly" : "Manual Only"
	},
	"locales" : {
		"default" : "Browser Default",
		"en-us"   : "English",
		"en"      : "English",
		"fr"      : "Français",
		"de"      : "Deutsch",
		"it"      : "Italiano",
		"es"      : "Español",
		"nl"      : "Nederlands",
		"ja"      : "日本語",
		"zh"      : "简体中文",
		"zh-tw"   : "繁體中文",
		"ru"      : "Pусский",
		"ko"      : "한국어",
		"pt-br"   : "Português (Brasil)"
	},

	"completionCriteria": {
		"label"                                                    : "Completion Criteria:",
		"notMetTitle"                                              : "Completion Criteria Not Met",
		"requiredMinimumTimeSpent"                                 : "The lab will not be marked as completed until %s have elapsed.",
		"requiredMinimumTimeSpentSingular"                         : "The lab will not be marked as completed until %s has elapsed.",
		"requiredStepsVisitedPercentage"                           : "The lab will not be marked as completed until %d%% of the manual steps have been read.",
		"requiredMinimumTimeSpentAndStepsVisitedPercentage"        : "The lab will not be marked as completed until %s have elapsed and %d%% of the manual steps have been read.",
		"requiredMinimumTimeSpentAndStepsVisitedPercentageSingular": "The lab will not be marked as completed until %s has elapsed and %d%% of the manual steps have been read.",
		"timeElapsedLabel"                                         : "Time Elapsed:",
		"manualStepsReadLabel"                                     : "Manual Steps Read:",
		"percentageNotCompleted"                                   : "Percentage of Pages Visited Not Completed",
		"minTimeNotCompleted"                                      : "Minimum Time Not Completed",
		"minTimeAndPercentageNotCompleted"                         : "Minimum Time and Percentage of Pages Visited Not Completed",
		"completed"                                                : "Completed",
		"notMet"                                                   : "Not Met",
		"checkingStatus"                                           : "Checking completion status with server.",
		"minimumTimeSpentRequiredLabel"                            : "(%s required)",
		"stepsVisitedPercentageRequiredLabel"                      : "(%s required)"
	},
	"classEnroll": {
		"header"            : "Student Enrollment",
		"enroll"            : "Do you want to enroll in class \"%s\"?",
		"enrolled"          : "Enrolled in class \"%s\".",
		"enrolling"         : "Enrolling...",
		"enrollButton"      : "Enroll",
		"enrolledNotStarted": "Enrolled in class \"%s\" but the class has not started yet."
	}
});

