/**
 * VLP Class
 *
 * Globally accessible vlp class for deeper client integrations.
 */
define('library/vlp/vlp-global',['require','jquery','underscore','backbone','library/vlp/base-class','library/underscore/extend-recursive','library/underscore/to-json-deep'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies;
	var BaseClass  = require("library/vlp/base-class");

	require("library/underscore/extend-recursive");
	require("library/underscore/to-json-deep");

	var entitlementViewPassThroughMethods = {
		sendText:          "sendText",
		showExit:          "showExit",
		showEnd:           "showEnd",
		showReset:         "showReset",
		showGrade:         "showGrade",
		switchConsole:     "switchConsole",
		switchVM:          "switchConsole",
		extendTime:        "extendTime",
		//showComplete:      "showComplete",
		//showIdleTimedOut:  "showIdleTimedOut",
		launchWalkthrough: "launchWalkthrough",
		showRemotePrompt:  "showRemotePrompt",
		closePanel:        "closePanelByName",
		openPanel:         "openPanelByName",
		togglePanel:       "togglePanelByName",
		hidePanel:         "hidePanelByName",
		showPanel:         "showPanelByName",
		closeAllPanels:    "closeAllPanels",
		openAllPanels:     "openAllPanels"
	};
	var manualViewPassThroughMethods = {
		decreaseFontSize:    "onDecreaseFont",
		increaseFontSize:    "onIncreaseFont",
		showTableOfContents: "onShowTOC",
		showNetworkTopology: "onShowNetworkTopology"
	};
	var stepsViewPassThroughMethods = {
		nextStep:     "onNextStep",
		previousStep: "onPrevStep",
		gotoStep:     "onNavToStep",
		gotoTag:      "onGotoTag"
	};
	var consolePassThroughMethods = {
		sendIframeMessage:    "sendIframeMessage",
		iSimNavigate:         "iSimNavigate",
		iSimDisplay:          "iSimDisplay",
		powerOnVM:            "powerOn",
		powerOn:              "powerOn",
		powerOffVm:           "powerOff",
		powerOff:             "powerOff",
		refreshVm:            "refreshConsole",
		refreshConsole:       "refreshConsole",
		powerResetVm:         "powerReset",
		powerReset:           "powerReset",
		sendCtrlAltDel:       "sendControlAltDelete",
		sendControlAltDelete: "sendControlAltDelete",
		showNotes:            "showNotes",
		toggleFullscreen:     "toggleFullscreen",
		maximizeConsole:      "maximize",
		maximizeVm:           "maximize",
		maximize:             "maximize"
	};

	var commandTriggers = {
		//Entitlement
		"launch-walkthrough": "launchWalkthrough",
		"switch-console":     "switchConsole",
		"send-text":          "sendText",
		"extend-time":        "extendTime",
		"exit":               "showExit",
		"end":                "showEnd",
		"reset":              "showReset",
		"grade":              "showGrade",
		"close-panel":        "closePanel",
		"open-panel":         "openPanel",
		"toggle-panel":       "togglePanel",
		"hide-panel":         "hidePanel",
		"show-panel":         "showPanel",
		"close-all-panels":   "closeAllPanels",
		"open-all-panels":    "openAllPanels",

		//Manual
		"decrease-font":     "decreaseFontSize",
		"increase-font":     "increaseFontSize",
		"table-of-contents": "showTableOfContents",
		"network-topology":  "showNetworkTopology",
		"next-step":         "nextStep",
		"previous-step":     "previousStep",
		"goto-step":         "gotoStep",
		"goto-tag":          "gotoTag",

		//Active console
		"power-on":           "powerOn",
		"power-down":         "powerOff",
		"power-reset":        "powerReset",
		"refresh-console":    "refreshConsole",
		"refresh-vm":         "refreshConsole",
		"ctrl-alt-del":       "sendControlAltDelete",
		"notes":              "showNotes",
		"fullscreen":         "toggleFullscreen",
		"maximize":           "maximize",
		"isim-navigate":      "iSimNavigate",
		"isim-display":       "iSimDisplay",

		//Misc
		"logout":            "logout",
		"reload":            "reload",
	};

	var userObjectOmit = [
		"activeTenant",
		"globalSystemMessages",
		"password",
		"passwordConfirm",
		"passwordCurrent",
		"passwordNew",
		"securityQuestions",
		"socials",
		"systemMessages",
		"tenantSystemMessages",
		"tenants",
		"visibleTenants"
	];
	var entitlementObjectOmit = [
		"account",
		"bookmarks",
		"consoleLatencyPreviousValues",
		"contentAccessKey",
		"manual",
		"neeToken",
		"studentConsoleLatencyPreviousValues"
	];
	
	var iframeOperationDenyList = ["_", "$", "storage", "cookie", "session", "store", "bind", "unbind", "listenTo", "listenOnce", "stopListening", "off", "on", "once", "trigger", "redirect", "replaceState"];
	var setConfigDenyList = [
		"tenant",
		"pages",
		"coreUrl",
		"eventUrl",
		"websockethubUrl",
		"contentUrl",
		"adminUiUrl",
		"authUrl",
		"coreApiPath",
		"contentApiPath",
		"authApiPath",
		"domainWhiteList",
		"serverBase",
		"pushBase",
		"authBase",
		"defaultTenantPath",
		"appPath",
		"mode",
		"debug",
		"userUiClientLogging",
		"senderParameter",
		"browserIdParameter",
		"version",
		"oneTrustIds",
		"oneTrustEnabled",
		"recaptchaPublicKey",
		"fallbackCaptchaMinLength",
		"fallbackCaptchaMaxLength",
		"defaultLabThumbnail",
		"zoomApiKey",
		"eulaEnabled",
		"eventMode",
		"consoleKeyboardLayouts",
		"locales",
		"localeFullMap",
		"locations",
		"countries",
		"states",
		"learnMoreUrl",
		"systemStatusURL",
		"contactUsLink"
	];

	return BaseClass.extend({
		App : null,
		publicClass: null,
		user : null,
		entitlement : null,
		entitlementView : null,
		entitlementOverrides : {},
		variableOverrides : {},

		initialize : function(app){
			_.bindAll(this);
			this.App = app;
			this.publicClass = this._makePublicObject();
			this._setupCommandListeners();
			this._setupEventListeners();
			this._setupIframeMessageListener();
		},
		setUser : function(model){
			if(this.user){
				this.stopListening(this.user);
			}
			this.user = model;

			if(this.user){
				var self = this;
				this.listenTo(this.user, "change:loggedIn", function(model, value) {
					if (value) {
						self.publicClass.trigger("userLoggedIn", value);
					} else {
						self.publicClass.trigger("userLoggedOut", value);
					}
				});
			}
		},
		setActiveEntitlement : function(model){
			if(this.entitlement){
				this.stopListening(this.entitlement);
			}
			this.entitlement = model;

			if(this.entitlement){
				var self = this;
				this.listenTo(this.entitlement, "change:activeConsoleId", function(model, value){self.publicClass.trigger("consoleChanged", value);});
				this.listenTo(this.entitlement, "change:phase", function(model, value){
					self.publicClass.trigger("entitlementPhaseChanged", value);
					self.publicClass.trigger("entitlement" + _.capitalize(value));
				});
				this.applyEntitlementOverrides(this.entitlement);
			}
		},
		setActiveEntitlementView : function(view){
			if(this.entitlementView){
				this.stopListening(this.entitlementView);
			}
			this.entitlementView = view;

			if(this.entitlementView){
				var self = this;
				this.listenTo(this.entitlementView, "started", function(){self.publicClass.trigger("entitlementStarted");});
				this.listenTo(this.entitlementView, "ready", function(){self.publicClass.trigger("entitlementReady");});
				if(this.entitlementView.model && this.entitlementView.model.get("phase") === "running"){
					self.publicClass.trigger("entitlementReady");
				}
			}
		},
		setActiveConsoleView : function(consoleView){
			if(this.activeConsoleView){
				this.stopListening(this.activeConsoleView);
			}
			this.activeConsoleView = consoleView;


			if(this.activeConsoleView){
				var self = this;
				this.listenTo(this.activeConsoleView, "isimEnd", function(name){self.publicClass.trigger("isimEnd", name);});
			}
		},
		setManualView : function(view){
			this.manualView = view;
		},
		setStepsView : function(view){
			this.stepsView = view;
		},
		applyEntitlementOverrides : function(model){
			if(this.entitlementOverrides && model){
				model.set(this.entitlementOverrides);
			}
		},
		setVariableOverrides : function(variableOverrides) {
			this.variableOverrides = _.extend(this.variableOverrides, variableOverrides);
		},
		replaceVariables : function(text, extraVariables){
			if(!text) { return text; }
			var variables = {
				//User
				email              : this.App.user && this.App.user.get("email"),
				username           : this.App.user && this.App.user.get("username"),
				firstName          : this.App.user && this.App.user.get("firstName"),
				lastName           : this.App.user && this.App.user.get("lastName"),

				//Lab
				labSku             : this.entitlement ? this.entitlement.get("sku"): null,
				labName            : this.entitlement ? this.entitlement.get("name"): null,

				//Class
				classId            : this.entitlement ? this.entitlement.get("classId"): null,
				className          : this.entitlement ? this.entitlement.get("vlpClass") && this.entitlement.get("vlpClass").name: null,
				classSku           : this.entitlement ? this.entitlement.get("vlpClass") && this.entitlement.get("vlpClass").sku: null,
				classDescription   : this.entitlement ? this.entitlement.get("vlpClass") && this.entitlement.get("vlpClass").description: null,

				//Entitlement
				entitlementId      : this.entitlement ? this.entitlement.get("id"): null,
				entitlementKey     : this.entitlement ? this.entitlement.get("entitlementKey"): null,
				endAllowed         : this.entitlement ? this.entitlement.get("endAllowed"): null,
				exitAllowed        : this.entitlement ? this.entitlement.get("exitAllowed"): null,
				resetAllowed       : this.entitlement ? this.entitlement.get("resetAllowed"): null,
				requiresGrading    : this.entitlement ? this.entitlement.get("requiresGrading"): null,

				//Progress
				progress                       : this.entitlement ? parseFloat(this.entitlement.get("progress")).toFixed(3): null,
				requiredMinimumTimeSpent       : this.entitlement ? this.entitlement.get("requiredMinimumTimeSpent"): null,
				requiredStepsVisitedPercentage : this.entitlement ? this.entitlement.get("requiredStepsVisitedPercentage"): null,
				completionStatusForLabCriteria : this.entitlement ? this.entitlement.get("completionStatusForLabCriteria"): null,

				//Manual
				activeStepNumber   : this.entitlement ? this.entitlement.get("activeStepNumber") + 1: null,
				totalSteps         : this.entitlement ? this.entitlement.get("totalSteps"): null,
				manualStepCount    : this.entitlement ? this.entitlement.get("manualStepCount"): null,
				remoteControlMode  : this.entitlement ? this.entitlement.get("remoteControlMode"): null,

				//Time
				timeAllotted       : this.entitlement ? this.entitlement.get("timeAllotted"): null,
				timeElapsed        : this.entitlement ? this.entitlement.get("timeElapsed"): null,
				timeRemaining      : this.entitlement ? this.entitlement.get("timeRemaining"): null,
				expirationDate     : this.entitlement ? this.entitlement.get("expirationDate"): null,
				time               : Date.now(),
				canExtend          : this.entitlement ? this.entitlement.get("canExtend"): null,
				extensionsRemaining: this.entitlement ? this.entitlement.get("extensionsRemaining"): null,
				extensionCount     : this.entitlement ? this.entitlement.get("extensionCount"): null,

				//Event Lab Groups
				groupName          : this.entitlement ? this.entitlement.get("groupName"): null,
				groupColor         : this.entitlement ? this.entitlement.get("groupColor"): null,

				//Misc
				location           : this.entitlement ? this.entitlement.get("location"): null,
				language           : this.entitlement ? this.entitlement.get("language"): null,
				locale             : this.App.locale.get().toLowerCase(),
				tenant             : this.App.getTenant(),
				trackingCode       : this.App.trackingCode,
				tid                : this.App.trackingCode
			};
			variables = _.extend(variables, extraVariables, this.variableOverrides);

			if(this.entitlement && this.entitlement.get("configProperties") && !_.isEmpty(this.entitlement.get("configProperties"))){
				variables = _.extend(variables, this.entitlement.get("configProperties") || {});
			}

			for(var variable in variables){
				if(variables.hasOwnProperty(variable)){
					var regex = new RegExp("\\{\\{"+ variable +"\\}\\}", "g");
					var value = variables[variable];
					text = text.replace(regex, value);
				}
			}
			text = text.replace(/\\\{\{/g, "{{");
			return text;
		},

		replaceCommands : function(text){
			if(!text) { return text; }
			var commands = text.match(/\[vlp:([a-z\-]+)\|([^\]]+)(|[^\]]+)?]/g);
			if(commands){
				for(var i = 0; i < commands.length; i++){
					var commandText = commands[i];
					var commandParts = commandText.match(/\[vlp:([a-z\-]+)\|([^\]\|]+)(\|([^\]]+))?]/);
					var displayText, data;
					var command = commandParts[1];
					if(commandParts[4]){
						displayText = commandParts[4];
						data = commandParts[2];
					} else{
						displayText = commandParts[2];
						data = null;
					}
					text = text.replace(commandText, "<button type=\"button\" class=\"btn\" data-" + command + "=\"" + data + "\">" + displayText + "</button>");
				}
			}
			return text;
		},
		disableInactiveCommands : function(context){
			this._disableInactiveCommandsWithContext(document);
			if(context){
				this._disableInactiveCommandsWithContext(context);
			}
		},
		_disableInactiveCommandsWithContext : function(context){
			if(this.entitlement){
				$("[data-extend-time]", context).prop("disabled", !this.entitlement.get("canExtend"));
				$("[data-exit]", context).prop("disabled", !this.entitlement.get("exitAllowed"));
				$("[data-end]", context).prop("disabled", !this.entitlement.get("endAllowed"));
				$("[data-reset]", context).prop("disabled", !this.entitlement.get("resetAllowed"));
				$("[data-grade]", context).prop("disabled", !this.entitlement.get("requiresGrading"));
				$("[data-network-topology]", context).prop("disabled", !this.entitlement.get("networkTopology"));
			}
		},
		_makePublicObject : function(){
			var self = this;

			var publicObject = _.extend({
				$ : $,
				_ : _,
				storage: this.App.storage,
				get user() {
					var data = self.user && _.toJSONDeep(self.user);
					if(data){
						data = _.omit(data, userObjectOmit);
					}
					return data;
				},
				get entitlement() {
					var data = self.entitlement && _.toJSONDeep(self.entitlement);
					if(data){
						data = _.omit(data, entitlementObjectOmit);
					}
					return data;
				},
				get tenant() {
					return self.App.getTenant();
				},
				get startUrl() {
					return self.App.startUrl;
				},
				get cookie() {
					return self.App.cookie;
				},
				get session() {
					return self.App.session;
				},
				get store() {
					return self.App.store;
				},
				get config() {
					return _.clone(self.App.config);
				},
				get replaceState() {
					return self.App.replaceState;
				},
				get reload() {
					return self.App.reload;
				},
				get redirect() {
					return self.App.redirect;
				},
				logout : function(){
					if($("#modal_logout").length > 0){
						$("#modal_logout").modal("show");
					} else if(self.App.mainView){
						self.App.mainView.logout();
					} else if(self.App.user){
						self.App.user.logout();
					}
				},
				setConfig : function(config, value){
					if (config && self.App && setConfigDenyList.indexOf(config) === -1 && self.App.config.hasOwnProperty(config)) {
						self.App.config[config] = value;
					}
				},
				setEntitlementOverride : function(property, value){
					if(self.entitlement){
						self.entitlement.set(property, value);
					}
					self.entitlementOverrides[property] = value;
				}
			}, Backbone.Events);
			function addPassthroughs(viewName, passthoughMethods){
				_.each(passthoughMethods, function(viewMethod, publicMethod){
					publicObject[publicMethod] = function(){
						var view = self[viewName];
						if(view && view[viewMethod]){
							return view[viewMethod].apply(view, arguments);
						}
					};
				});
			}
			addPassthroughs("entitlementView", entitlementViewPassThroughMethods);
			addPassthroughs("manualView", manualViewPassThroughMethods);
			addPassthroughs("stepsView", stepsViewPassThroughMethods);
			addPassthroughs("activeConsoleView", consolePassThroughMethods);

			return publicObject;
		},
		_setupCommandListeners : function(){
			for(var trigger in commandTriggers){
				if(commandTriggers.hasOwnProperty(trigger)){
					var dataAttribute = "[data-" + trigger + "]";
					$(document).on("click.vlp-global", dataAttribute, _.partial(this._commandClicked, trigger));
				}
			}
		},
		_setupEventListeners : function(){
			var self = this;

			if(this.App.locale){
				this.App.locale.on("changed", function(model, value){self.publicClass.trigger("localeChanged", value);});
			}
		},
		_setupIframeMessageListener : function(){
			var self = this;

			$(window).off("message.vlp").on("message.vlp", function(event){
				try{
					var data = _.isString(event.originalEvent.data) ? JSON.parse(event.originalEvent.data) : event.originalEvent.data;
					var operation = String(data.operation);
					if (data && operation && self.App.VLP && iframeOperationDenyList.indexOf(operation) === -1 && self.App.VLP.hasOwnProperty(operation)){
						self.App.VLP[operation].apply(self.App.VLP, data.params || []);
					}
				} catch(e){}
			});
		},
		_commandClicked : function(trigger, event){
			event.preventDefault();

			var command = commandTriggers[trigger];
			var data = $(event.currentTarget).data(trigger);
			if(this.publicClass[command]){
				return this.publicClass[command](data);
			}
		}
	});
});

