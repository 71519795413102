/**
 * Help Center Backbone View
 */
define('views/common/help-center',['require','underscore','library/vlp/app','library/vlp/view','models/help-request','hbs!tpls/common/help-center.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var _        = require("underscore");

	//class dependencies
	var App         = require("library/vlp/app"),
	    BaseView    = require("library/vlp/view"),
		HelpRequest = require("models/help-request"),
	    tpl         = require("hbs!tpls/common/help-center.handlebars");

	return BaseView.extend({
		template : tpl,

		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		el : "#help_center",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .request-captain"  : "onRequestCaptain",
			"click .help-desk-ticket" : "onHelpDeskTicket"
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
		},
		serialize : function(){
			var data = {};
			data.stationId = App.station && App.station.get("stationId");
			data.helpDesk = App.config.helpDesk;
			return data;
		},
		/**
		 * Display the content.
		 */
		afterRender : function(){
			this.$(".message").hide();

			this.$(".password").val("");
			this.$(".username").val("").focus();
		},
		onRequestCaptain : function(event){
			var self = this;
			this.$(".message").slideUp("fast");
			this.$(".captain-request-success").hide();
			this.$(".captain-request-spinner").addClass("hide");

			var errors = false;
			if(this.$(".password").val().length === 0){
				this.$(".password").focus();
				this.$(".error-enter-password").slideDown();
				errors = true;
			}

			if(this.$(".username").val().length === 0){
				this.$(".username").focus();
				this.$(".error-enter-username").slideDown();
				errors = true;
			}
			if(errors){
				return;
			}
			this.$(".captain-request-spinner").removeClass("hide");
			this.$(".request-captain").button("loading");


			if (!App.user.has("helpRequest")) {
				App.user.set("helpRequest", new HelpRequest.Model({stationId : App.station.get("stationId")}));
			}
			App.user.get("helpRequest").set("captainRequest", true, {silent : true});
			App.user.get("helpRequest").requestCaptain({
				data  : {
					proctorid   : this.$(".username").val(),
					proctorpass : this.$(".password").val()
				},
				success : function(){
					self.$(".request-captain").button("reset");
					self.$(".captain-request-success").show().stop().delay(3000).fadeOut("slow");
					self.$(".captain-request-spinner").addClass("hide");
					self.$(".password").val("");
					self.$(".username").val("");
				},
				error : function(model, resp){
					App.user.get("helpRequest").set("captainRequest", false, {silent : true});
					self.$(".error-captain-request").text((resp.response && _.escapeHTML(resp.response.message)) || resp.errorText);
					self.$(".error-captain-request").slideDown();
					self.$(".captain-request-success").hide();
					self.$(".captain-request-spinner").addClass("hide");
					self.$(".request-captain").button("reset");
				}
			});
		},
		onHelpDeskTicket : function(event){
			var self = this;
			this.$(".message").slideUp("fast");
			this.$(".help-desk-ticket-success").hide();
			this.$(".help-desk-ticket-spinner").addClass("hide");

			var errors = false;
			if(this.$(".password").val().length === 0){
				this.$(".password").focus();
				this.$(".error-enter-password").slideDown();
				errors = true;
			}

			if(this.$(".username").val().length === 0){
				this.$(".username").focus();
				this.$(".error-enter-username").slideDown();
				errors = true;
			}
			if(_.trim(this.$(".summary").val()).length === 0){
				this.$(".summary").focus();
				this.$(".error-enter-summary").slideDown();
				errors = true;
			}
			if(errors){
				return;
			}
			this.$(".help-desk-ticket-spinner").removeClass("hide");
			this.$(".help-desk-ticket").button("loading");

			App.station.helpDeskTicket({
				data : {
					proctorId   : this.$(".username").val(),
					proctorPass : this.$(".password").val(),
					subject     : this.$(".summary").val(),
					body        : this.$(".detail").val()
				},
				success : function(){
					self.$(".help-desk-ticket").button("reset");
					self.$(".help-desk-ticket-spinner").addClass("hide");
					self.$(".help-desk-ticket-success").show().stop().delay(3000).fadeOut("slow");
					self.$(".password").val("");
					self.$(".username").val("");
				},
				error   : function(model, resp){
					self.$(".error-help-ticket").text((resp.response && _.escapeHTML(resp.response.message)) || resp.errorText);
					self.$(".error-help-ticket").slideDown();

					self.$(".help-desk-ticket-success").hide();
					self.$(".help-desk-ticket-spinner").addClass("hide");
					self.$(".help-desk-ticket").button("reset");
				}
			});
		}
	});

});

