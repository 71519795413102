
define('hbs!tpls/instructor/main.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "true";
},"3":function(depth0,helpers,partials,data) {
    return "false";
},"5":function(depth0,helpers,partials,data) {
    return "expanded";
},"7":function(depth0,helpers,partials,data) {
    return "collapsed";
},"9":function(depth0,helpers,partials,data) {
    return "hide";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"invalid-request alert alert-error hide\">\n	"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.invalid",{"name":"$","hash":{},"data":data}))
    + "\n</div>\n\n\n<div id=\"class\" style=\"display:none\">\n	<div class=\"loading loading-class\">\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.loading",{"name":"$","hash":{},"data":data}))
    + "\n	</div>\n</div>\n\n<div id=\"active_entitlement\" style=\"display:none\">\n	<div class=\"loading loading-entitlement\">\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.loading",{"name":"$","hash":{},"data":data}))
    + "\n	</div>\n</div>\n\n<div class=\"panels\">\n\n	<div class=\"dock-left\"></div>\n	<div class=\"dock-right\"></div>\n	<div class=\"floating\"></div>\n\n	<div id=\"instructions_panel\" data-position=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"instructionsPanelLocation",{"name":"config","hash":{},"data":data}))
    + "\" data-expanded=\"false\" data-resize-mode=\"both\" class=\"panel docked right collapsed\" data-order=\"0\">\n\n		<div data-function=\"slide-or-focus\" class=\"open-close-panel\">\n			<span class=\"tab-img fa fa-book\"></span>\n			<div class=\"tab-caption\"><span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.manualTitle",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n		</div>\n\n		<div class=\"panel-interior\">\n\n			<div class=\"panel-controls\">\n				<ul>\n					<li><button data-function=\"close\" class=\"btn-no-style dock-close expanded fa fa-times\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.close",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n					<li><button data-function=\"float\" class=\"btn-no-style toggle-dock-float fa fa-external-link\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.toggleFloat",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n					<li><button data-function=\"dock-left\" class=\"btn-no-style dock-arrow-left fa fa-caret-left\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockLeft",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n					<li><button data-function=\"dock-right\" class=\"btn-no-style dock-arrow-right fa fa-caret-right\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockRight",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n					<!--<li><a data-function=\"bottom\" class=\"dock-arrow-down btn-clear btn-clear-mini\"></a></li>-->\n				</ul>\n			</div>\n\n			<div id=\"instructions\" class=\"content\">\n				<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.manualLoading",{"name":"$","hash":{},"data":data}))
    + "</div>\n			</div>\n\n		</div>\n	</div>\n\n	<div id=\"resources_panel\" data-position=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"instructionsPanelLocation",{"name":"config","hash":{},"data":data}))
    + "\" data-expanded=\"false\" data-resize-mode=\"both\" class=\"panel docked right collapsed\" data-order=\"2\">\n\n		<div data-function=\"slide-or-focus\" class=\"open-close-panel\">\n			<span class=\"tab-img fa fa-files-o\"></span>\n			<div class=\"tab-caption\"><span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.resources",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n		</div>\n\n		<div class=\"panel-interior\">\n\n			<div class=\"panel-controls\">\n				<ul>\n					<li><button data-function=\"close\" class=\"btn-no-style dock-close expanded fa fa-times\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.close",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n					<li><button data-function=\"float\" class=\"btn-no-style toggle-dock-float fa fa-external-link\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.toggleFloat",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n					<li><button data-function=\"dock-left\" class=\"btn-no-style dock-arrow-left fa fa-caret-left\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockLeft",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n					<li><button data-function=\"dock-right\" class=\"btn-no-style dock-arrow-right fa fa-caret-right\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockRight",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n				</ul>\n			</div>\n\n\n			<div id=\"resources\" class=\"content\">\n				<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n			</div>\n\n		</div>\n	</div>\n\n	<div id=\"vm_listing_panel\" data-position=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"vmListingPanelLocation",{"name":"config","hash":{},"data":data}))
    + "\" data-expanded=\"false\" class=\"panel docked right collapsed\" data-resize-mode=\"both\" data-order=\"1\">\n\n		<div data-function=\"slide-or-focus\" class=\"open-close-panel\">\n			<span class=\"tab-img fa fa-desktop\"></span>\n			<div class=\"tab-caption\"><span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.consoleTitle",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n		</div>\n\n		<div class=\"panel-interior\">\n\n			<div class=\"panel-controls\">\n				<ul>\n					<li>\n						<button data-function=\"close\" class=\"btn-no-style dock-close fa fa-times\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.close",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button>\n					</li>\n					<li>\n						<button data-function=\"float\" class=\"btn-no-style toggle-dock-float fa fa-external-link\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.toggleFloat",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button>\n					</li>\n					<li>\n						<button data-function=\"dock-left\" class=\"btn-no-style dock-arrow-left fa fa-caret-left\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockLeft",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button>\n					</li>\n					<li>\n						<button data-function=\"dock-right\" class=\"btn-no-style dock-arrow-right fa fa-caret-right\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockRight",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button>\n					</li>\n				</ul>\n			</div>\n\n			<div id=\"vm_listing\" class=\"content\">\n				<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.consolesLoading",{"name":"$","hash":{},"data":data}))
    + "</div>\n			</div>\n		</div>\n	</div>\n\n	<div id=\"help_request_instructor_panel\" data-position=\"right\" data-expanded=\"false\" data-resize-mode=\"both\" class=\"panel docked right collapsed\" data-order=\"3\">\n\n		<div data-function=\"slide-or-focus\" class=\"open-close-panel\">\n			<div id=\"raise-hand-counter\" class=\"badge badge-info all-labs-new-count hide\" style=\"display: block;\"></div>\n			<i class=\"tab-img fa fa-exclamation-triangle\"></i>\n\n			<div class=\"tab-caption\"><span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.helpRequestTitle",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n		</div>\n\n		<div class=\"panel-interior\">\n\n			<div class=\"panel-controls\">\n				<ul>\n					<li>\n						<button data-function=\"close\" class=\"btn-no-style expanded fa fa-times\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.close",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\"\n								data-container=\"body\"></button>\n					</li>\n					<li>\n						<button data-function=\"float\" class=\"btn-no-style toggle-dock-float fa fa-external-link\"\n								rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.toggleFloat",{"name":"$$","hash":{},"data":data}))
    + "\n								data-placement=\"bottom\" data-container=\"body\"></button>\n					</li>\n					<li>\n						<button data-function=\"dock-left\" class=\"btn-no-style dock-arrow-left fa fa-caret-left\"\n								rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockLeft",{"name":"$$","hash":{},"data":data}))
    + "\n								data-placement=\"bottom\" data-container=\"body\"></button>\n					</li>\n					<li>\n						<button data-function=\"dock-right\" class=\"btn-no-style dock-arrow-right fa fa-caret-right\"\n								rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockRight",{"name":"$$","hash":{},"data":data}))
    + "\n								data-placement=\"bottom\" data-container=\"body\"></button>\n					</li>\n					<li><a data-function=\"bottom\" class=\"dock-arrow-down btn-clear btn-clear-mini\"></a></li>\n				</ul>\n			</div>\n\n			<div id=\"help-requests\" class=\"content\">\n				<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.helpRequestLoading",{"name":"$","hash":{},"data":data}))
    + "</div>\n			</div>\n\n		</div>\n	</div>\n\n	<div id=\"video_conference_panel\" data-position=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"videoConferencePanelLocation",{"name":"config","hash":{},"data":data}))
    + "\" data-expanded=\""
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.remoteControl : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\" data-resize-mode=\"both\" class=\"panel docked right "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.remoteControl : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hideVideoConferencePanel : depth0),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-order=\"0\">\n\n		<div data-function=\"slide-or-focus\" class=\"open-close-panel\">\n			<span class=\"tab-img fa fa-video-camera\"></span>\n			<div class=\"tab-caption\"><span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.videoConferenceTitle",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n		</div>\n\n		<div class=\"panel-interior\">\n\n			<div class=\"panel-controls\">\n				<ul>\n					<li><button data-function=\"close\" class=\"btn-no-style dock-close expanded fa fa-times\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.close",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n					<li><button data-function=\"float\" class=\"btn-no-style toggle-dock-float fa fa-external-link\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.toggleFloat",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n					<li><button data-function=\"dock-left\" class=\"btn-no-style dock-arrow-left fa fa-caret-left\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockLeft",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n					<li><button data-function=\"dock-right\" class=\"btn-no-style dock-arrow-right fa fa-caret-right\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockRight",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n					<!--<li><a data-function=\"bottom\" class=\"dock-arrow-down btn-clear btn-clear-mini\"></a></li>-->\n				</ul>\n			</div>\n\n\n			<div id=\"video_conference\" class=\"content\">\n				<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.videoConferenceLoading",{"name":"$","hash":{},"data":data}))
    + "</div>\n			</div>\n\n		</div>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('instructor_main', t);
return t;
});

