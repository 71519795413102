
define('hbs!tpls/common/profile.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var helper;

  return "	<div class=\"img\">\n		<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.avatar",{"name":"$","hash":{},"data":data}))
    + "</h2>\n		<div rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","profile.avatarTooltip",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\">\n			<a href=\"http://gravatar.com/emails/\" target=\"_blank\">\n				<img id=\"profile_avatar\" src=\""
    + this.escapeExpression(((helper = (helper = helpers.avatar || (depth0 != null ? depth0.avatar : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"avatar","hash":{},"data":data}) : helper)))
    + "\" width=\"80\" height=\"80\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"alt","profile.image",{"name":"$$","hash":{},"data":data}))
    + " />\n			</a>\n			<br/><br/><a href=\"http://gravatar.com/emails/\" target=\"_blank\" id=\"profile_change_image\" class=\"btn\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.change",{"name":"$","hash":{},"data":data}))
    + "</a>\n		</div>\n	</div>\n";
},"3":function(depth0,helpers,partials,data) {
    return "modal-dialog";
},"5":function(depth0,helpers,partials,data) {
    return "accordion";
},"7":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<div class=\"accordion-group\">\n			<h2 class=\"accordion-heading\">\n				<a class=\"accordion-toggle "
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.activePanel : depth0),"general",{"name":"ne","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.collapsable : depth0),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " data-parent=\"#profile_info\" data-target=\"#profile_general_container\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.general.title",{"name":"$","hash":{},"data":data}))
    + "\n				</a>\n			</h2>\n			<div id=\"profile_general_container\" class=\"accordion-body collapse "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.activePanel : depth0),"general",{"name":"eq","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = this.invokePartial(partials.common_profile_general,depth0,{"name":"common_profile_general","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "			</div>\n		</div>\n\n		<div class=\"accordion-group\">\n			<h2 class=\"accordion-heading\">\n				<a class=\"accordion-toggle collapsed\" "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.collapsable : depth0),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " data-parent=\"#profile_info\" data-target=\"#profile_contact_info_container\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.contact.title",{"name":"$","hash":{},"data":data}))
    + "\n				</a>\n			</h2>\n			<div id=\"profile_contact_info_container\" class=\"accordion-body collapse\">\n"
    + ((stack1 = this.invokePartial(partials.common_profile_contact_info,depth0,{"name":"common_profile_contact_info","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "			</div>\n		</div>\n\n\n		<div class=\"accordion-group\">\n			<h2 class=\"accordion-heading\">\n				<a class=\"accordion-toggle collapsed\" "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.collapsable : depth0),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " data-parent=\"#profile_info\" data-target=\"#profile_about_container\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.about.title",{"name":"$","hash":{},"data":data}))
    + "\n				</a>\n			</h2>\n			<div id=\"profile_about_container\" class=\"accordion-body collapse\">\n"
    + ((stack1 = this.invokePartial(partials.common_profile_about,depth0,{"name":"common_profile_about","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "			</div>\n		</div>\n\n		<div class=\"accordion-group\">\n			<h2 class=\"accordion-heading\">\n				<a class=\"accordion-toggle collapsed\" "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.collapsable : depth0),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " data-parent=\"#profile_info\" data-target=\"#profile_preferences_container\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.preferences.title",{"name":"$","hash":{},"data":data}))
    + "\n				</a>\n			</h2>\n			<div id=\"profile_preferences_container\" class=\"accordion-body collapse\">\n"
    + ((stack1 = this.invokePartial(partials.common_profile_preferences,depth0,{"name":"common_profile_preferences","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "			</div>\n\n		</div>\n\n\n";
},"8":function(depth0,helpers,partials,data) {
    return "collapsed";
},"10":function(depth0,helpers,partials,data) {
    return "data-toggle=\"collapse\"";
},"12":function(depth0,helpers,partials,data) {
    return "in";
},"14":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<div class=\""
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isModal : depth0),{"name":"if","hash":{},"fn":this.program(15, data, 0),"inverse":this.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isModal : depth0),{"name":"if","hash":{},"fn":this.program(19, data, 0),"inverse":this.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + "			<div id=\"profile_password_container\" class=\""
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.isModal : depth0),{"name":"unless","hash":{},"fn":this.program(23, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n				<form class=\"form-horizontal \" id=\"profile_password_form\" method=\"post\" data-error-style=\"tooltip\" "
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(25, data, 0),"inverse":this.program(27, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n\n					<fieldset class=\""
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isModal : depth0),{"name":"if","hash":{},"fn":this.program(29, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.passwordExpired : depth0),{"name":"if","hash":{},"fn":this.program(31, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.resetPassword : depth0),{"name":"unless","hash":{},"fn":this.program(33, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "						<div class=\"control-group\">\n							<label class=\"control-label required\" for=\"profile_password\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.password.newPassword",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n							<div class=\"controls\">\n								<input type=\"password\" id=\"profile_password\" maxlength=\"255\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(38, data, 0),"inverse":this.program(36, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n								<input type=\"text\" class=\"hide\" id=\"profile_password_visible\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(38, data, 0),"inverse":this.program(36, data, 0),"data":data})) != null ? stack1 : "")
    + " maxlength=\"255\">\n								<span class=\"help-block\"></span>\n							</div>\n						</div>\n						<div class=\"control-group\">\n							<label class=\"control-label required\" for=\"profile_passwordConfirm\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.password.passwordConfirm",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n							<div class=\"controls\">\n								<input type=\"password\" id=\"profile_passwordConfirm\" maxlength=\"255\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(38, data, 0),"inverse":this.program(36, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n								<input type=\"text\" class=\"hide\" id=\"profile_passwordConfirm_visible\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(38, data, 0),"inverse":this.program(36, data, 0),"data":data})) != null ? stack1 : "")
    + " maxlength=\"255\">\n								<span class=\"help-block\"></span>\n							</div>\n						</div>\n						<div class=\"controls\">\n							<label class=\"checkbox\">\n								<input type=\"checkbox\" id=\"profile_password_show\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.password.displayPassword",{"name":"$","hash":{},"data":data}))
    + "\n							</label>\n						</div>\n						<div class=\"alert alert-error hide save-error-general password-error\">\n							<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"error",{"name":"$","hash":{},"data":data}))
    + "</strong>\n						</div>\n					</fieldset>\n					<div "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isModal : depth0),{"name":"if","hash":{},"fn":this.program(40, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.resetPassword : depth0),{"name":"if","hash":{},"fn":this.program(42, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "						<button type=\"submit\" class=\"btn btn-large btn-primary pull-right save-changes\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","updating",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.password.save",{"name":"$","hash":{},"data":data}))
    + "</button>\n\n						<div class=\"spinner hide pull-right save-spinner\"/>\n						<div class=\"alert alert-success hide pull-right save-success\">\n							<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"updated",{"name":"$","hash":{},"data":data}))
    + "</strong>\n						</div>\n						<div class=\"alert alert-error hide pull-right save-error\">\n							<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"error",{"name":"$","hash":{},"data":data}))
    + "</strong>\n						</div>\n					</div>\n				</form>\n			</div>\n		</div>\n";
},"15":function(depth0,helpers,partials,data) {
    return "modal-content";
},"17":function(depth0,helpers,partials,data) {
    return "accordion-group";
},"19":function(depth0,helpers,partials,data) {
    return "				<div class=\"modal-header\">\n					<h3 class=\"modal-title\">\n						<span class=\"fa fa-fw fa-lock\"></span>\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.password.title",{"name":"$","hash":{},"data":data}))
    + "\n					</h3>\n				</div>\n";
},"21":function(depth0,helpers,partials,data) {
    var stack1;

  return "\n				<h2 class=\"accordion-heading\">\n					<a class=\"accordion-toggle "
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.activePanel : depth0),"password",{"name":"ne","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.collapsable : depth0),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " data-parent=\"#profile_info\" data-target=\"#profile_password_container\" data-error-style=\"tooltip\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.password.title",{"name":"$","hash":{},"data":data}))
    + "\n					</a>\n				</h2>\n";
},"23":function(depth0,helpers,partials,data) {
    var stack1;

  return "accordion-body collapse "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.activePanel : depth0),"password",{"name":"eq","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"25":function(depth0,helpers,partials,data) {
    return "autocomplete=\"on\"";
},"27":function(depth0,helpers,partials,data) {
    return "autocomplete=\"off\" action=\"https://about:blank\"";
},"29":function(depth0,helpers,partials,data) {
    return "modal-body";
},"31":function(depth0,helpers,partials,data) {
    return "							<p><strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.password.expired",{"name":"$","hash":{},"data":data}))
    + "</strong></p>\n";
},"33":function(depth0,helpers,partials,data) {
    var stack1;

  return "							<div class=\"control-group\">\n								<label class=\"control-label required\" for=\"profile_passwordCurrent\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.password.passwordCurrent",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n								<div class=\"controls\">\n									<input type=\"password\" id=\"profile_passwordCurrent\" maxlength=\"255\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(34, data, 0),"inverse":this.program(36, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n									<input type=\"text\" class=\"hide\" id=\"profile_passwordCurrent_visible\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(34, data, 0),"inverse":this.program(36, data, 0),"data":data})) != null ? stack1 : "")
    + " maxlength=\"255\">\n									<span class=\"help-block\"></span>\n								</div>\n							</div>\n";
},"34":function(depth0,helpers,partials,data) {
    return "\"current-password\"";
},"36":function(depth0,helpers,partials,data) {
    return "\"off\"";
},"38":function(depth0,helpers,partials,data) {
    return "\"new-password\"";
},"40":function(depth0,helpers,partials,data) {
    return "class=\"modal-footer\"";
},"42":function(depth0,helpers,partials,data) {
    return "							<button type=\"button\" class=\"btn btn-large pull-right cancel-password\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","canceling",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</button>\n";
},"44":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "		<div class=\""
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isModal : depth0),{"name":"if","hash":{},"fn":this.program(15, data, 0, blockParams, depths),"inverse":this.program(17, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isModal : depth0),{"name":"if","hash":{},"fn":this.program(45, data, 0, blockParams, depths),"inverse":this.program(47, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "			<div id=\"profile_security_questions_container\" class=\""
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.isModal : depth0),{"name":"unless","hash":{},"fn":this.program(49, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n				<form id=\"profile_security_questions_form\" class=\"form-horizontal\" method=\"post\" "
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(25, data, 0, blockParams, depths),"inverse":this.program(27, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + " data-error-style=\"tooltip\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.setQuestions : depth0),{"name":"if","hash":{},"fn":this.program(51, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "					<p class=\"message\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.securityQuestions.description",{"name":"$","hash":{},"data":data}))
    + "</p>\n\n\n					<fieldset class=\""
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isModal : depth0),{"name":"if","hash":{},"fn":this.program(29, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.securityQuestions : depth0),{"name":"each","hash":{},"fn":this.program(53, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n						<div class=\"control-group\">\n							<div class=\"controls\">\n								<label class=\"checkbox\">\n									<input type=\"checkbox\" id=\"profile_security_answers_show_hide\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.securityQuestions.showAnswers",{"name":"$","hash":{},"data":data}))
    + "\n								</label>\n							</div>\n						</div>\n\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.verifyMode : depth0),{"name":"unless","hash":{},"fn":this.program(56, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "						<div class=\"alert alert-error hide security-questions-error save-error-general\">\n							<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"error",{"name":"$","hash":{},"data":data}))
    + "</strong>\n						</div>\n					</fieldset>\n\n\n					<div "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isModal : depth0),{"name":"if","hash":{},"fn":this.program(40, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.setQuestions : depth0),{"name":"if","hash":{},"fn":this.program(58, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n						<button type=\"submit\" class=\"btn btn-large btn-primary pull-right save-changes\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","updating",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.securityQuestions.save",{"name":"$","hash":{},"data":data}))
    + "</button>\n\n						<div class=\"spinner hide pull-right save-spinner\"/>\n						<div class=\"alert alert-success hide pull-right save-success\">\n							<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"updated",{"name":"$","hash":{},"data":data}))
    + "</strong>\n						</div>\n						<div class=\"alert alert-error hide pull-right save-error\">\n							<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"error",{"name":"$","hash":{},"data":data}))
    + "</strong>\n						</div>\n					</div>\n\n				</form>\n			</div>\n		</div>\n";
},"45":function(depth0,helpers,partials,data) {
    return "				<div class=\"modal-header\">\n					<h3 class=\"modal-title\">\n						<span class=\"fa fa-fw fa-lock\"></span>\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.securityQuestions.title",{"name":"$","hash":{},"data":data}))
    + "\n					</h3>\n				</div>\n";
},"47":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<h2 class=\"accordion-heading\">\n					<a class=\"accordion-toggle "
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.activePanel : depth0),"securityQuestions",{"name":"ne","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.collapsable : depth0),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " data-parent=\"#profile_info\" data-target=\"#profile_security_questions_container\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.securityQuestions.title",{"name":"$","hash":{},"data":data}))
    + "\n					</a>\n				</h2>\n";
},"49":function(depth0,helpers,partials,data) {
    var stack1;

  return "accordion-body collapse "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.activePanel : depth0),"securityQuestions",{"name":"eq","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"51":function(depth0,helpers,partials,data) {
    return "						<p class=\"message\"><strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.securityQuestions.emptyPrompt",{"name":"$","hash":{},"data":data}))
    + "</strong></p>\n";
},"53":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper;

  return "							<div class=\"control-group\">\n								<label class=\"control-label required\" for=\"profile_securityQuestion-"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.securityQuestions.question",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n								<div class=\"controls\">\n									<input type=\"text\" name=\"securityQuestion\" value=\""
    + this.escapeExpression(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"question","hash":{},"data":data}) : helper)))
    + "\" id=\"profile_securityQuestion-"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" maxlength=\"255\">\n									<span class=\"help-block\"></span>\n								</div>\n\n								<label class=\"control-label required\" for=\"profile_securityQuestion_answer-"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.securityQuestions.answer",{"name":"$","hash":{},"data":data}))
    + "</label>\n								<div class=\"controls\">\n									<input type=\"password\" class=\"security-answer\" name=\"securityAnswer\" id=\"profile_securityQuestion_answer-"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = helpers.unless.call(depth0,(depths[1] != null ? depths[1].setQuestions : depths[1]),{"name":"unless","hash":{},"fn":this.program(54, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " maxlength=\"255\">\n									<span class=\"help-block\"></span>\n								</div>\n							</div>\n";
},"54":function(depth0,helpers,partials,data) {
    return "placeholder=\""
    + this.escapeExpression((helpers.repeat || (depth0 && depth0.repeat) || helpers.helperMissing).call(depth0,"*",256,{"name":"repeat","hash":{},"data":data}))
    + "\"";
},"56":function(depth0,helpers,partials,data) {
    var stack1;

  return "							<div class=\"control-group\">\n								<label class=\"control-label required\" for=\"profile_passwordCurrentSecurity\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.contact.confirmPassword",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n								<div class=\"controls\">\n									<input type=\"password\" id=\"profile_passwordCurrentSecurity\" maxlength=\"255\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(34, data, 0),"inverse":this.program(36, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n									<input type=\"text\" class=\"hide\" id=\"profile_passwordCurrentSecurity_visible\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(34, data, 0),"inverse":this.program(36, data, 0),"data":data})) != null ? stack1 : "")
    + " maxlength=\"255\">\n									<span class=\"help-block\"></span>\n								</div>\n							</div>\n\n							<div class=\"controls\">\n								<label class=\"checkbox\">\n									<input type=\"checkbox\" id=\"profile_security_password_show\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.password.displayPassword",{"name":"$","hash":{},"data":data}))
    + "\n								</label>\n							</div>\n";
},"58":function(depth0,helpers,partials,data) {
    return "							<a class=\"cancel pull-right\" type=\"button\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</a>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.showGeneral : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"info "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isModal : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0, blockParams, depths),"inverse":this.program(5, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\" id=\"profile_info\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.showGeneral : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.showPassword : depth0),{"name":"if","hash":{},"fn":this.program(14, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.showSecurityQuestions : depth0),{"name":"if","hash":{},"fn":this.program(44, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.registerPartial('common_profile', t);
return t;
});

