
define('hbs!tpls/common/login-prompt.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "autocomplete=\"on\" action=\"login\"";
},"3":function(depth0,helpers,partials,data) {
    return "autocomplete=\"off\" action=\"https://about:blank\"";
},"5":function(depth0,helpers,partials,data) {
    return "							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.title",{"name":"$","hash":{},"data":data}))
    + "\n";
},"7":function(depth0,helpers,partials,data) {
    return "							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.pageTitle",{"name":"$","hash":{},"data":data}))
    + "\n";
},"9":function(depth0,helpers,partials,data) {
    var stack1;

  return "						<div class=\"authentication-providers\">\n							<ul class=\"authentication-providers-list\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.remoteAuthenticationProviders : depth0),{"name":"each","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "							</ul>\n							"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hasLocalAuthenticationProviders : depth0),{"name":"if","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n						</div>\n";
},"10":function(depth0,helpers,partials,data) {
    var helper;

  return "									<li><button type=\"button\" class=\"btn btn-primary btn-large remote-login\" data-redirect-url=\""
    + this.escapeExpression(((helper = (helper = helpers.redirectUrl || (depth0 != null ? depth0.redirectUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"redirectUrl","hash":{},"data":data}) : helper)))
    + "\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.SSO.loginWith",{"name":"$","hash":{"name":(depth0 != null ? depth0.name : depth0)},"data":data}))
    + "</button></li>\n";
},"12":function(depth0,helpers,partials,data) {
    return "<span class=\"separator\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.SSO.or",{"name":"$","hash":{},"data":data}))
    + "</span>";
},"14":function(depth0,helpers,partials,data) {
    var stack1;

  return "						<fieldset class=\"form-horizontal\">\n							<div class=\"control-group\">\n								<label class=\"control-label\" for=\"login_username\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.username",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n								<div class=\"controls\">\n									<input type=\"text\" class=\"input-xlarge\" placeholder=\"user@company.com\" id=\"login_username\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(15, data, 0),"inverse":this.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n								</div>\n							</div>\n							<div class=\"control-group clearfix\" id=\"password_container\">\n								<label class=\"control-label\" for=\"login_password\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.password",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n								<div class=\"controls\">\n									<input type=\"password\" class=\"input-xlarge\" id=\"login_password\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(19, data, 0),"inverse":this.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n								</div>\n							</div>\n\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.requirePasswordForLogin : depth0),{"name":"unless","hash":{},"fn":this.program(21, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "						</fieldset>\n";
},"15":function(depth0,helpers,partials,data) {
    return "\"email\"";
},"17":function(depth0,helpers,partials,data) {
    return "\"off\"";
},"19":function(depth0,helpers,partials,data) {
    return "\"current-password\"";
},"21":function(depth0,helpers,partials,data) {
    return "								<div class=\"control-group\" id=\"show_password_container\">\n									<div class=\"controls\">\n										<label class=\"checkbox\">\n											<input type=\"checkbox\" id=\"show_password_checkbox\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.providePassword",{"name":"$","hash":{},"data":data}))
    + "\n										</label>\n									</div>\n								</div>\n";
},"23":function(depth0,helpers,partials,data) {
    return "						<div id=\"cannot_login\" class=\"alert alert-warning\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.errors.noDirectLogin",{"name":"$","hash":{},"data":data}))
    + "\n						</div>\n";
},"25":function(depth0,helpers,partials,data) {
    return "						<div class=\"alert alert-warning\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"warnings.cookiesDisabled",{"name":"$","hash":{},"data":data}))
    + "\n						</div>\n";
},"27":function(depth0,helpers,partials,data) {
    return " hide";
},"29":function(depth0,helpers,partials,data) {
    return "btn btn-large";
},"31":function(depth0,helpers,partials,data) {
    return "autocomplete=\"on\"";
},"33":function(depth0,helpers,partials,data) {
    return "\"given-name\"";
},"35":function(depth0,helpers,partials,data) {
    return "\"family-name\"";
},"37":function(depth0,helpers,partials,data) {
    return "\"company\"";
},"39":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<form id=\"remote_token_form\" method=\"post\" "
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(31, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n			<div class=\"modal-dialog\">\n				<div class=\"modal-content\">\n					<div class=\"modal-body\">\n						<h3 class=\"modal-title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.partialSupport.title",{"name":"$","hash":{},"data":data}))
    + "</h3>\n						<fieldset class=\"form-horizontal\">\n							<p>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.partialSupport.description",{"name":"$","hash":{},"data":data}))
    + "</p>\n							<div class=\"control-group\">\n								<label class=\"control-label\" for=\"login_remote_token\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.partialSupport.token",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n								<div class=\"controls\">\n									<input type=\"text\" class=\"input-xlarge\" id=\"login_remote_token\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(40, data, 0),"inverse":this.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n								</div>\n							</div>\n\n							<button id=\"login_remote_token_submit\" class=\"btn btn-large pull-right\" type=\"submit\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","loading",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"submit",{"name":"$","hash":{},"data":data}))
    + "</button>\n							<div class=\"token-description\">\n								<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.partialSupport.tokenTitle",{"name":"$","hash":{},"data":data}))
    + "</h4>\n\n								<p>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.partialSupport.tokenDescription",{"name":"$","hash":{},"data":data}))
    + "</p>\n							</div>\n						</fieldset>\n					</div>\n				</div>\n			</div>\n		</form>\n";
},"40":function(depth0,helpers,partials,data) {
    return "\"one-time-code\"";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div id=\"login_prompt\" class=\"modal fluid fade hide scrollable\" data-backdrop=\"static\" data-keyboard=\"false\">\n	<form id=\"login_form\" method=\"post\" data-error-style=\"tooltip\" "
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n		<div class=\"modal-dialog\">\n			<div class=\"modal-content\">\n				<div class=\"modal-header\">\n					<h3 class=\"modal-title\">\n						<span class=\"fa fa-fw fa-sign-in\"></span>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hasLocalAuthenticationProviders : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "					</h3>\n				</div>\n				<div class=\"modal-body\">\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hasRemoteAuthenticationProviders : depth0),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hasLocalAuthenticationProviders : depth0),{"name":"if","hash":{},"fn":this.program(14, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.hasAuthenticationProviders : depth0),{"name":"unless","hash":{},"fn":this.program(23, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n					<a href=\"#forgot-password\" id=\"forgot_password\" class=\"no-login-hide\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.forgotPassword.button",{"name":"$","hash":{},"data":data}))
    + "</a>\n					<div class=\"self-register no-login-hide\">\n						<a href=\"#register\" id=\"register\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.register.button",{"name":"$","hash":{},"data":data}))
    + "</a>\n					</div>\n					<div class=\"no-login-hide\">\n						<a href=\"#change-language\" id=\"login_language_chooser\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","header.language",{"name":"$$","hash":{},"data":data}))
    + " rel=\"tooltip\" data-placement=\"left\">\n							<i class=\"fa fa-globe\"></i>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"languageName",{"name":"$","hash":{},"data":data}))
    + "\n						</a>\n					</div>\n\n"
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.browserSupport : depth0)) != null ? stack1.cookies : stack1),{"name":"unless","hash":{},"fn":this.program(25, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "					<div id=\"login_invalid\" class=\"message alert alert-error hide\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.errors.general",{"name":"$","hash":{},"data":data}))
    + "\n					</div>\n\n					<div id=\"login_error_response\" class=\"message alert alert-error hide\"></div>\n					<div id=\"password_sent\" class=\"message alert alert-success hide\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.forgotPassword.email.sent",{"name":"$","hash":{},"data":data}))
    + "\n					</div>\n					<div id=\"changed_expired_password\" class=\"message alert alert-success hide\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.expiredPassword.success",{"name":"$","hash":{},"data":data}))
    + "\n					</div>\n				</div>\n				<div class=\"modal-footer\">\n					<div class=\"clearfix\">\n\n						<div class=\"station-label"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.stationId : depth0),{"name":"unless","hash":{},"fn":this.program(27, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n							<button type=\"button\"><span class=\"station-id\">"
    + this.escapeExpression(((helper = (helper = helpers.stationId || (depth0 != null ? depth0.stationId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"stationId","hash":{},"data":data}) : helper)))
    + "</span></button>\n						</div>\n\n						<button id=\"continue\" class=\"btn btn-large pull-right no-login-hide\" type=\"submit\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","loading",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"continue",{"name":"$","hash":{},"data":data}))
    + "</button>\n						<button id=\"login\" class=\"btn btn-large pull-right no-login-hide\" type=\"submit\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","loginPrompt.loggingIn",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.login",{"name":"$","hash":{},"data":data}))
    + "</button>\n						<div class=\"spinner login-spinner pull-right hide\"></div>\n					</div>\n					<a id=\"login_cancel\" class=\"cancel "
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.hasLocalAuthenticationProviders : depth0),{"name":"unless","hash":{},"fn":this.program(29, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</a>\n				</div>\n			</div>\n		</div>\n	</form>\n\n	<form id=\"register_form\" class=\"hide form-horizontal\" data-error-style=\"tooltip\" method=\"post\""
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(31, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n		<div class=\"modal-dialog\">\n			<div class=\"modal-content\">\n				<div class=\"modal-header\">\n					<h3 class=\"modal-title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.register.title",{"name":"$","hash":{},"data":data}))
    + "</h3>\n				</div>\n				<div class=\"modal-body\">\n					<p class=\"description\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.register.description",{"name":"$","hash":{},"data":data}))
    + "</p>\n					<fieldset class=\"form-horizontal\">\n\n						<div class=\"control-group\">\n							<label class=\"control-label\" for=\"register_email\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.register.email",{"name":"$","hash":{},"data":data}))
    + "</label>\n							<div class=\"controls\">\n								<input type=\"text\" class=\"input-xlarge\" placeholder=\"user@company.com\" id=\"register_email\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(15, data, 0),"inverse":this.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n							</div>\n						</div>\n\n						<div class=\"control-group\">\n							<label class=\"control-label\" for=\"register_firstName\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.register.name",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n							<div class=\"controls\">\n								<input type=\"text\" class=\"input-small\" id=\"register_firstName\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"placeholder","loginPrompt.register.firstName",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-error-label","profile.general.firstName",{"name":"$$","hash":{},"data":data}))
    + " autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(33, data, 0),"inverse":this.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n								<input type=\"text\" class=\"input-small\" id=\"register_lastName\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"placeholder","loginPrompt.register.lastName",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-error-label","profile.general.lastName",{"name":"$$","hash":{},"data":data}))
    + " autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(35, data, 0),"inverse":this.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n							</div>\n						</div>\n\n						<div class=\"control-group\">\n						<label class=\"control-label\" for=\"register_company\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.register.company",{"name":"$","hash":{},"data":data}))
    + "</label>\n							<div class=\"controls\">\n								<input type=\"text\" class=\"input-xlarge\" placeholder=\"Acme\" id=\"register_company\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(37, data, 0),"inverse":this.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n							</div>\n						</div>\n\n						<div class=\"control-group\" id=\"captcha\">\n							<hr/>\n\n							<div id=\"recaptcha_widget\" class=\"loading\">\n\n								<div class=\"loading\">\n									<div>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.register.recaptcha.loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n									<a class=\"recaptcha-load\" href=\"#\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.register.recaptcha.load",{"name":"$","hash":{},"data":data}))
    + "</a>\n								</div>\n								<div class=\"cannot-load\">\n									<div>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.register.recaptcha.cannotLoad",{"name":"$","hash":{},"data":data}))
    + "</div>\n									<a class=\"recaptcha-load\" href=\"#\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.register.recaptcha.tryAgain",{"name":"$","hash":{},"data":data}))
    + "</a>\n								</div>\n								<div class=\"alternative-captcha\">\n									<div class=\"image-container\">\n										<img id=\"alternative_captcha_image\">\n									</div>\n\n									<label class=\"control-label\">\n										<span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.register.alternativeCaptcha.enterCharacters",{"name":"$","hash":{},"data":data}))
    + "</span>\n									</label>\n									<div class=\"controls\">\n										<input type=\"text\" id=\"alternative_captcha_response\" name=\"alternativeCaptchaResponse\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-error-label","loginPrompt.register.alternativeCaptcha.label",{"name":"$$","hash":{},"data":data}))
    + " minlength=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"fallbackCaptchaMinLength",{"name":"config","hash":{},"data":data}))
    + "\" maxlength=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"fallbackCaptchaMaxLength",{"name":"config","hash":{},"data":data}))
    + "\" data-placement=\"bottom\"/>\n										<a href=\"#\" id=\"alternative_captcha_reload\" class=\"fa fa-refresh\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","loginPrompt.register.recaptcha.reload",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\"></a>\n									</div>\n\n								</div>\n								<div id=\"recaptcha\"></div>\n\n								<div class=\"recaptcha-required\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.errors.captcha",{"name":"$","hash":{},"data":data}))
    + "</div>\n							</div>\n						</div>\n					</fieldset>\n					<div id=\"register_invalid\" class=\"message alert alert-error hide\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.errors.error",{"name":"$","hash":{},"data":data}))
    + ": <span class=\"error-message\"></span>\n					</div>\n				</div>\n				<div class=\"modal-footer\">\n					<div class=\"clearfix\">\n						<button id=\"register_submit\" class=\"btn btn-large pull-right\" type=\"submit\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","loginPrompt.register.submitting",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.register.submit",{"name":"$","hash":{},"data":data}))
    + "</button>\n						<div class=\"spinner register pull-right hide\"></div>\n					</div>\n					<a id=\"register_cancel\" class=\"cancel\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</a>\n				</div>\n			</div>\n		</div>\n	</form>\n\n	<div id=\"register_success\" class=\"hide\" method=\"post\" autocomplete=\"off\">\n		<div class=\"modal-dialog\">\n			<div class=\"modal-content\">\n				<div class=\"modal-header\">\n					<h3 class=\"modal-title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.registered.title",{"name":"$","hash":{},"data":data}))
    + "</h3>\n				</div>\n				<div class=\"modal-body\">\n					<p class=\"description\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.registered.description",{"name":"$","hash":{},"data":data}))
    + "</p>\n				</div>\n				<div class=\"modal-footer\">\n					<button id=\"register_login\" class=\"btn btn-large\" type=\"button\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.login",{"name":"$","hash":{},"data":data}))
    + "</button>\n				</div>\n			</div>\n		</div>\n	</div>\n\n	<form id=\"forgot_password_form\" class=\"hide\" method=\"post\" "
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(31, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n		<div class=\"modal-dialog\">\n			<div class=\"modal-content\">\n				<div class=\"modal-header\">\n					<h3 class=\"modal-title forgot-password-title\">\n						<span class=\"fa fa-fw fa-lock\"></span>\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.forgotPassword.title",{"name":"$","hash":{},"data":data}))
    + "\n					</h3>\n					<h3 class=\"modal-title expired-password-title\">\n						<span class=\"fa fa-fw fa-lock\"></span>\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.forgotPassword.expiredTitle",{"name":"$","hash":{},"data":data}))
    + "\n					</h3>\n				</div>\n				<div class=\"modal-body\">\n					<p class=\"description\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.forgotPassword.description",{"name":"$","hash":{},"data":data}))
    + "</p>\n				</div>\n				<div class=\"modal-footer\">\n					<div class=\"clearfix\">\n						<button id=\"forgot_password_security_questions\" class=\"btn btn-large pull-right\" type=\"button\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.forgotPassword.buttonSecurityQuestions",{"name":"$","hash":{},"data":data}))
    + "</button>\n						<button id=\"forgot_password_email\" class=\"btn btn-large pull-right\" type=\"button\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.forgotPassword.buttonEmail",{"name":"$","hash":{},"data":data}))
    + "</button>\n					</div>\n					<a id=\"forgot_password_cancel\" class=\"cancel\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</a>\n				</div>\n			</div>\n		</div>\n	</form>\n\n	<form id=\"forgot_password_send_email\" data-error-style=\"tooltip\" class=\"hide\" method=\"post\" "
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(31, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n		<div class=\"modal-dialog\">\n			<div class=\"modal-content\">\n				<div class=\"modal-header\">\n					<h3 class=\"modal-title\">\n						<span class=\"fa fa-fw fa-lock\"></span>\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.forgotPassword.email.title",{"name":"$","hash":{},"data":data}))
    + "\n					</h3>\n				</div>\n				<div class=\"modal-body\">\n					<p class=\"description\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.forgotPassword.email.description",{"name":"$","hash":{},"data":data}))
    + "</p>\n					<fieldset class=\"form-horizontal\">\n						<div class=\"control-group\">\n							<label class=\"control-label\" for=\"forgot_password_username\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.forgotPassword.email.username",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n							<div class=\"controls\">\n								<input type=\"text\" class=\"input-xlarge\" placeholder=\"user@company.com\" id=\"forgot_password_username\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(15, data, 0),"inverse":this.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n							</div>\n						</div>\n					</fieldset>\n					<div id=\"forgot_password_invalid\" class=\"message alert alert-error hide\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.errors.error",{"name":"$","hash":{},"data":data}))
    + ": <span class=\"error-message\"></span>\n					</div>\n					<div id=\"forgot_password_enter_username\" class=\"message alert alert-error hide\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.errors.invalidUsername",{"name":"$","hash":{},"data":data}))
    + "\n					</div>\n				</div>\n				<div class=\"modal-footer\">\n					<div class=\"clearfix\">\n						<!--<button id=\"forgot_password_send_email_cancel\" class=\"btn btn-large pull-right btn-danger cancel\" type=\"button\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</button>-->\n						<button id=\"forgot_password_send_email_send\" class=\"btn btn-large pull-right\" type=\"submit\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","loginPrompt.forgotPassword.email.sending",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.forgotPassword.email.submit",{"name":"$","hash":{},"data":data}))
    + "</button>\n						<div class=\"spinner forgot-password-spinner pull-right hide\"></div>\n					</div>\n					<a id=\"forgot_password_send_email_cancel\" class=\"cancel\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</a>\n				</div>\n			</div>\n		</div>\n	</form>\n\n	<form id=\"expired_password_form\" data-error-style=\"tooltip\" class=\"hide\" method=\"post\" "
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(31, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n		<div class=\"modal-dialog\">\n			<div class=\"modal-content\">\n				<div class=\"modal-header\">\n					<h3 class=\"modal-title\">\n						<span class=\"fa fa-fw fa-lock\"></span>\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.expiredPassword.title",{"name":"$","hash":{},"data":data}))
    + "\n					</h3>\n				</div>\n				<div class=\"modal-body\">\n					<p class=\"description\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.expiredPassword.description",{"name":"$","hash":{},"data":data}))
    + "</p>\n					<fieldset class=\"form-horizontal\">\n						<div class=\"control-group\">\n							<label class=\"control-label\" for=\"expired_password_username\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.username",{"name":"$","hash":{},"data":data}))
    + "</label>\n							<div class=\"controls\">\n								<input type=\"text\" class=\"input-xlarge\" id=\"expired_password_username\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(15, data, 0),"inverse":this.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n							</div>\n						</div>\n						<div class=\"control-group\">\n							<label class=\"control-label\" for=\"expired_password_password\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.expiredPassword.passwordCurrent",{"name":"$","hash":{},"data":data}))
    + "</label>\n							<div class=\"controls\">\n								<input type=\"password\" class=\"input-xlarge\" id=\"expired_password_password\" autocomplete=\"off\">\n							</div>\n						</div>\n						<div class=\"control-group\">\n							<label class=\"control-label\" for=\"expired_password_new\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.expiredPassword.passwordNew",{"name":"$","hash":{},"data":data}))
    + "</label>\n							<div class=\"controls\">\n								<input type=\"password\" class=\"input-xlarge\" id=\"expired_password_new\" autocomplete=\"off\">\n							</div>\n						</div>\n						<div class=\"control-group\">\n							<label class=\"control-label\" for=\"expired_password_confirm\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.expiredPassword.passwordConfirm",{"name":"$","hash":{},"data":data}))
    + "</label>\n							<div class=\"controls\">\n								<input type=\"password\" class=\"input-xlarge\" id=\"expired_password_confirm\" autocomplete=\"off\">\n							</div>\n						</div>\n					</fieldset>\n					<div id=\"expired_password_invalid\" class=\"message alert alert-error hide\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.errors.error",{"name":"$","hash":{},"data":data}))
    + ": <span class=\"error-message\"></span>\n					</div>\n				</div>\n				<div class=\"modal-footer\">\n					<div class=\"clearfix\">\n						<button id=\"expired_password_send\" class=\"btn btn-large pull-right\" type=\"submit\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","loginPrompt.expiredPassword.sending",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.expiredPassword.submit",{"name":"$","hash":{},"data":data}))
    + "</button>\n						<div class=\"spinner expired-password-spinner pull-right hide\"></div>\n					</div>\n				</div>\n			</div>\n		</div>\n	</form>\n\n	<div id=\"security_prompts_holder\"></div>\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.partialSupport : depth0),{"name":"if","hash":{},"fn":this.program(39, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n	<div class=\"footer-copyright\">\n		"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.privacy",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "\n		<div class=\"copyright\">"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.copyright",(depth0 != null ? depth0.copyrightYear : depth0),{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('common_login_prompt', t);
return t;
});

