/**
 *  This function creates a new anchor element and uses location
 *  properties (inherent) to get the desired URL data. Some String
 *  operations are used (to normalize results across browsers).
 */
define('library/underscore/parse-url',['require','underscore'],function (require) {
	"use strict";
	var _  = require("underscore");

	_.mixin({
		parseURL: function (url){
			var a = document.createElement("a");
			a.href = url;
			var file = (a.pathname.match(/\/([^\/?#]+)$/i) || [undefined, ""])[1];
			return {
				source   : url,
				protocol : (a.protocol || window.self.location.protocol).replace(":", ""),
				host     : a.hostname || window.self.location.hostname,
				port     : a.port || (!a.hostname && window.self.location.port) || "",
				query    : a.search,
				params   : (function(){
					var ret = {},
						seg = a.search.replace(/^\?/, "").split("&"),
						len = seg.length, i = 0, s;
					for(; i < len; i++){
						if(!seg[i]){
							continue;
						}
						s = seg[i].split("=");
						ret[s[0]] = s[1];
					}
					return ret;
				})(),
				file     : file,
				extension: file.split(".").pop(),
				hash     : a.hash.replace("#", ""),
				path     : a.pathname.replace(/^([^\/])/, "/$1"),
				relative : (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [undefined, ""])[1],
				segments : a.pathname.replace(/^\//, "").split("/")
			};
		}
	});

	return _;
});

