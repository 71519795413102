define('library/underscore/is-index-array',['require','underscore'],function (require) {
	"use strict";
	var _  = require("underscore");

	_.mixin({
		isIndexArray: function(obj, quick){
			if(_.isArray(obj)){
				return true;
			}
			if(!_.isObject(obj)){
				return false;
			}
			var keys = _.keys(obj);
			for(var i in keys){
				if(keys.hasOwnProperty(i)){
					var n = keys[i];
					if(typeof n === "string"){

						var t = parseFloat(n, 10);
						n = parseInt(n, 10);
						if(isNaN(n) || n !== t) { return false; }
					}
					if(quick) { return (typeof n === "number" && n % 1 === 0); }

					if(typeof n !== "number" || n % 1 !== 0) { return false; }
				}
			}
			return true;
		}
	});

	return _;
});
