
define('hbs!tpls/catalogs/lab/review.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var helper;

  return "		<div class=\"rating-wrapper pull-right\" data-class=\"popover-rating\">\n			<div class=\"rating\" data-score=\""
    + this.escapeExpression(((helper = (helper = helpers.rating || (depth0 != null ? depth0.rating : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"rating","hash":{},"data":data}) : helper)))
    + "\"></div>\n		</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"review\">\n\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"ratingEnabled",{"name":"config","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "	<div class=\"title\"><strong>"
    + this.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper)))
    + "</strong></div>\n	<div class=\"date\">"
    + this.escapeExpression((helpers.date_format || (depth0 && depth0.date_format) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.date : depth0),"dayFormat",{"name":"date_format","hash":{},"data":data}))
    + "</div>\n	<div class=\"author\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.reviews.by",{"name":"$","hash":{},"data":data}))
    + " <span class=\"name skin-text-popout\">"
    + this.escapeExpression(((helper = (helper = helpers.authorName || (depth0 != null ? depth0.authorName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"authorName","hash":{},"data":data}) : helper)))
    + "</span></div>\n	<div class=\"review-body\">"
    + ((stack1 = (helpers.nl2br || (depth0 && depth0.nl2br) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.review : depth0),{"name":"nl2br","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n</div>";
},"useData":true});
Handlebars.registerPartial('catalogs_lab_review', t);
return t;
});

