
define('hbs!tpls/catalogs/lab.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return " new-lab";
},"3":function(depth0,helpers,partials,data) {
    return "with-systems";
},"5":function(depth0,helpers,partials,data) {
    return "with-badges";
},"7":function(depth0,helpers,partials,data) {
    return "with-reviews";
},"9":function(depth0,helpers,partials,data) {
    return "<span class=\"new-lab-label\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.new",{"name":"$","hash":{},"data":data}))
    + "</span>";
},"11":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<div class=\"lab-status\">\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,((stack1 = (depth0 != null ? depth0.entitlement : depth0)) != null ? stack1.status : stack1),"completed",{"name":"eq","hash":{},"fn":this.program(12, data, 0),"inverse":this.program(14, data, 0),"data":data})) != null ? stack1 : "")
    + "				</div>\n";
},"12":function(depth0,helpers,partials,data) {
    return "						<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"transcript/lab/{{entitlement.entitlementKey}}"},"data":data}))
    + "\" class=\"enrolled completed\"><span class=\"fa fa-fw fa-check-circle\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.completed",{"name":"$","hash":{},"data":data}))
    + "</a>\n";
},"14":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,((stack1 = (depth0 != null ? depth0.entitlement : depth0)) != null ? stack1.status : stack1),"expired",{"name":"eq","hash":{},"fn":this.program(15, data, 0),"inverse":this.program(17, data, 0),"data":data})) != null ? stack1 : "");
},"15":function(depth0,helpers,partials,data) {
    return "							<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"transcript/lab/{{entitlement.entitlementKey}}"},"data":data}))
    + "\" class=\"enrolled expired\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.expired",{"name":"$","hash":{},"data":data}))
    + "</a>\n";
},"17":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,((stack1 = (depth0 != null ? depth0.entitlement : depth0)) != null ? stack1.status : stack1),"canceled",{"name":"eq","hash":{},"fn":this.program(18, data, 0),"inverse":this.program(20, data, 0),"data":data})) != null ? stack1 : "");
},"18":function(depth0,helpers,partials,data) {
    return "								<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"transcript/lab/{{entitlement.entitlementKey}}"},"data":data}))
    + "\" class=\"enrolled canceled\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.canceled",{"name":"$","hash":{},"data":data}))
    + "</a>\n";
},"20":function(depth0,helpers,partials,data) {
    return "								<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"enrollments/lab/{{entitlement.entitlementKey}}"},"data":data}))
    + "\" class=\"enrolled incomplete\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.enrolled",{"name":"$","hash":{},"data":data}))
    + "</a>\n";
},"22":function(depth0,helpers,partials,data) {
    return "					<button class=\"read-more btn a\" type=\"button\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.readMore",{"name":"$","hash":{},"data":data}))
    + "</button>\n";
},"24":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "				<div class=\"full-description user-data\">\n					"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n					<button class=\"read-less btn a\" type=\"button\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				</div>\n";
},"26":function(depth0,helpers,partials,data) {
    return "				<button type=\"button\" class=\"btn invite-friend\" data-toggle=\"popover\" data-html=\"true\" data-trigger=\"click\" data-placement=\"top\" data-class=\"popover-invite\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"share.invite.buttonInvite",{"name":"$","hash":{},"data":data}))
    + "</button>\n";
},"28":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<div class=\"enrollment\">\n"
    + ((stack1 = this.invokePartial(partials.catalogs_lab_enroll_button,depth0,{"name":"catalogs_lab_enroll_button","data":data,"indent":"\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "				</div>\n";
},"30":function(depth0,helpers,partials,data) {
    return "expanded";
},"32":function(depth0,helpers,partials,data) {
    return "closed";
},"34":function(depth0,helpers,partials,data) {
    return "style=\"display:block\"";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"lab-box catalog-lab"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.newItem : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.systems : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.badges : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"ratingEnabled",{"name":"config","hash":{},"data":data}),(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"reviewsEnabled",{"name":"config","hash":{},"data":data}),{"name":"or","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"lab-"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n	"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.newItem : depth0),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n\n	<div class=\"lab-top\">\n"
    + ((stack1 = this.invokePartial(partials.catalogs_lab_systems,(depth0 != null ? depth0.systems : depth0),{"name":"catalogs_lab_systems","data":data,"indent":"\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + ((stack1 = this.invokePartial(partials.catalogs_lab_reviews_and_ratings,depth0,{"name":"catalogs_lab_reviews_and_ratings","data":data,"indent":"\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + ((stack1 = this.invokePartial(partials.catalogs_lab_badges,(depth0 != null ? depth0.badges : depth0),{"name":"catalogs_lab_badges","data":data,"indent":"\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "	</div>\n\n	<div class=\"lab-middle\">\n		<div class=\"lab-image\">\n			<img data-property=\"thumbnail\" src=\""
    + this.escapeExpression(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"alt","catalogs.labs.thumbnailAlt",(depth0 != null ? depth0.name : depth0),{"name":"$$","hash":{},"data":data}))
    + "/>\n		</div>\n		<div class=\"lab-text\">\n			<h3 class=\"lab-name\">"
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.enrolled : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "			<p class=\"short-description user-data\">\n				"
    + this.escapeExpression((helpers.prune || (depth0 && depth0.prune) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.shortDescription : depth0),255,"...",{"name":"prune","hash":{},"data":data}))
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":this.program(22, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "			</p>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":this.program(24, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n		<div class=\"lab-buttons\">\n"
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.display : depth0)) != null ? stack1.showInvite : stack1),{"name":"if","hash":{},"fn":this.program(26, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.partOfCourse : depth0),{"name":"unless","hash":{},"fn":this.program(28, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n\n	<div class=\"info-box-header lab-detail-header "
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"expandCatalogLabDetails",{"name":"config","hash":{},"fn":this.program(30, data, 0),"inverse":this.program(32, data, 0),"data":data})) != null ? stack1 : "")
    + "\" data-target=\"#full_info-"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n		<h3>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.showDetails",{"name":"$","hash":{},"data":data}))
    + "</h3>\n	</div>\n	<div id=\"full_info-"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"info-box lab-detail\" "
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"expandCatalogLabDetails",{"name":"config","hash":{},"fn":this.program(34, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n\n\n"
    + ((stack1 = this.invokePartial(partials.catalogs_lab_common_metadata,depth0,{"name":"catalogs_lab_common_metadata","data":data,"indent":"\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + ((stack1 = this.invokePartial(partials.catalogs_lab_common_metadata_links,depth0,{"name":"catalogs_lab_common_metadata_links","data":data,"indent":"\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "	</div>\n</div>";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('catalogs_lab', t);
return t;
});

