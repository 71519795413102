define('handlebars',['require','handlebars-original'],function (require) {
	"use strict";
	var Handlebars = require("handlebars-original");

	var compile = Handlebars.compile,
	    template = Handlebars.template,
	    toString = Object.prototype.toString,
	    functionType = "[object Function]";

	Handlebars.compile = function() {
		var result = compile.apply(this, arguments);
		return function(context, options) {
			var passedContext = context;
			if (context) {
				if (context.toHandlebars) {
					passedContext = context.toHandlebars();
				} else if (context.toJSON) {
					passedContext = context.toJSON();
				}
			}
			return result.call(this, passedContext, options);
		};
	};
	Handlebars.template = function() {
		var result = template.apply(this, arguments);
		return function(context, options) {
			var passedContext = context;
			if (context) {
				if (context.toHandlebars) {
					passedContext = context.toHandlebars();
				} else if (context.toJSON) {
					passedContext = context.toJSON();
				}
			}
			return result.call(this, passedContext, options);
		};
	};

	Handlebars.registerHelper = function(name, fn, inverse) {
		var wrappedFn = function() {
			for (var i = 0; i < arguments.length; i ++) {
				var arg = arguments[i];
				if (i === arguments.length - 1 && arg.hash) {
					for (var j in arg.hash) {
						if(arg.hash.hasOwnProperty(j)){
							var hashArg = arg.hash[j];
							if (toString.call(hashArg) === functionType) {
								arg.hash[j] = hashArg.call(this);
							}
						}
					}
				} else if (toString.call(arg) === functionType) {
					arguments[i] = arg.call(this);
				}
			}
			return fn.apply(this, arguments);

		};
		if (inverse) {
			wrappedFn.not = function() {
				for (var i = 0; i < arguments.length; i ++) {
					var arg = arguments[i];
					if (i === arguments.length - 1 && arg.hash) {
						for (var j in arg.hash) {
							if(arg.hash.hasOwnProperty(j)) {
								var hashArg = arg.hash[j];
								if (toString.call(hashArg) === functionType) {
									arg.hash[j] = hashArg.call(this);
								}
							}
						}
					} else if (toString.call(arg) === functionType) {
						arguments[i] = arg.call(this);
					}
				}
				return inverse.apply(this, arguments);
			};
		}
		this.helpers[name] = wrappedFn;
	};
	return Handlebars;
});
