/**
 * Login Prompt Backbone View
 */
define('views/common/login-prompt',['require','jquery','underscore','library/vlp/app','library/vlp/view','views/common/security-prompts','hbs!tpls/common/login-prompt.handlebars','utilities/browser'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App                 = require("library/vlp/app"),
	    BaseView            = require("library/vlp/view"),
	    SecurityPromptsView = require("views/common/security-prompts"),
	    tpl                 = require("hbs!tpls/common/login-prompt.handlebars"),
	    Browser             = require("utilities/browser");

	return BaseView.extend({
		template : tpl,

		autoValidate : false,

		isOpen      : false,
		cancellable : false,

		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		el : "#login_prompt_holder",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"submit #login_form"                            : "login",
			"keyup #login_username"                         : "onUserNameChanged",
			"click #login_cancel"                           : "loginCancel",
			"click .remote-login"                           : "onRemoteLogin",
			"click #register"                               : "register",
			"click #register_cancel"                        : "cancelForgotPassword",
			"submit #register_form"                         : "registerSubmit",
			"click #register_login"                         : "cancelForgotPassword",
			"click #forgot_password"                        : "forgotPassword",
			"submit #forgot_password_send_email"            : "forgotPasswordSend",
			"click #forgot_password_cancel"                 : "cancelForgotPassword",
			"click #forgot_password_send_email_cancel"      : "cancelForgotPassword",
			"click #forgot_password_security_questions"     : "showForgotPasswordSecurityQuestions",
			"click #forgot_password_email"                  : "showForgotPasswordEmail",
			"submit #expired_password_form"                 : "changeExpiredPasswordSend",
			"click .recaptcha-load"                         : "setupCaptcha",
			"click #recaptcha_reload"                       : "setupCaptcha",
			"click #alternative_captcha_reload"             : "setupCaptcha",
			"click #recaptcha_switch_to_audio"              : "recaptchaSwitchToAudio",
			"click #recaptcha_switch_to_image"              : "recaptchaSwitchToImage",
			"click #recaptcha_show_help"                    : "recaptchaShowHelp",
			"submit #remote_token_form"                     : "goRemoteToken"
		},

		modelBindings : {
			"#login_username"               : "username",
			"#login_password"               : "password",
			"#forgot_password_username"     : "username",
			"#expired_password_username"    : "username",
			"#expired_password_password"    : "password",
			"#expired_password_confirm"     : "passwordConfirm",
			"#expired_password_new"         : "passwordNew",
			"#show_password_checkbox"       : "showPassword",
			"#register_firstName"           : "firstName",
			"#register_lastName"            : "lastName",
			"#register_email"               : "email",
			"#register_company"             : "company",
			"#alternative_captcha_response" : "alternativeCaptchaResponse"
		},
		validation : {
			username : {
				maxLength : 255,
				required  : true
			},
			password : {
				required  : true
			},
			forgot_password_username : {
				required  : true
			},
			firstName : {
				maxLength : 255,
				required  : true
			},
			lastName : {
				maxLength : 255,
				required  : true
			},
			email : {
				maxLength : 255,
				required  : true
			},
			company : {
				maxLength : 255,
				required  : true
			},
			alternativeCaptchaResponse : {
				required  : true,
				minLength : 6,
				maxLength : 8
			},
			passwordConfirm : {
				required  : true
			},
			passwordNew : {
				required  : true
			}
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			this.listenTo(this.model, "change:username", function(model){
				var previousUsername = model.previous("username") &&  model.previous("username").toLowerCase();
				if(previousUsername !== model.get("username").toLowerCase()){
					this.model.unset("securityQuestions");
					this.onUserNameChanged();
				}
			}, this);
			this.listenTo(App.captcha, {
				"error"    : this.captchaError,
				"expired"  : this.captchaExpired,
				"ready"    : this.captchaReady,
				"fallback" : this.captchaUseFallback,
				"loadStart": this.captchaLoadStart,
				"loadEnd"  : this.captchaLoadEnd
			});
			this.listenTo(this.model, "change:showPassword", this.onShowPasswordChanged, this);
			this.model.set("showPassword", App.config.requirePasswordForLogin && !this.model.get("hasRemoteAuthenticationProviders"));

			this.validation.alternativeCaptchaResponse.minLength = App.config.fallbackCaptchaMinLength;
			this.validation.alternativeCaptchaResponse.maxLength = App.config.fallbackCaptchaMaxLength;
			this.usernameChecked = null;
		},
		serialize : function(){
			var data = {};
			data.partialSupport = Browser.partialSupport;
			data.stationId = App.station && App.station.get("stationId");
			data.copyrightYear = new Date().getFullYear();
			data.browserSupport = Browser.support;

			data.remoteAuthenticationProviders = this.model.get("remoteAuthenticationProviders");

			data.hasRemoteAuthenticationProviders = this.model.get("hasRemoteAuthenticationProviders");
			data.hasLocalAuthenticationProviders  = this.model.get("hasLocalAuthenticationProviders");
			data.hasAuthenticationProviders       = this.model.get("hasAuthenticationProviders");

			data.requirePasswordForLogin          = App.config.requirePasswordForLogin;

			return data;
		},
		afterRender : function(){
			var self = this;

			this.$("#login_cancel").hide();
			if(!App.config.allowRegistration || !this.model.get("hasLocalAuthenticationProviders")){
				this.$(".self-register").hide();
			}
			if(!this.model.get("hasLocalAuthenticationProviders")){
				this.$("#forgot_password").hide();
			}

			this.$username = this.$("#login_username");
			this.$password = this.$("#login_password");
			this.$passwordContainer = this.$("#password_container");

			this.onShowPasswordChanged();

			this.$("#login").toggle(!this.model.get("hasRemoteAuthenticationProviders"));
			this.$("#continue").toggle(this.model.get("hasRemoteAuthenticationProviders") && this.model.get("hasLocalAuthenticationProviders"));

			if(!this.model.get("hasAuthenticationProviders")){
				this.$(".no-login-hide").hide();
			}

			this.$modal = this.$("#login_prompt");
			this.$modal.off("shown.login-prompt").on("shown.login-prompt", function(){
				if(App.session.get("creditLogout")){
					App.session.remove("creditLogout");
					self.$("#login_error_response")
						.text(App.i18n("loginPrompt.errors.noCredits"))
						.slideDown("fast")
						.delay(15000)
						.slideUp("fast");
				}
				self.$username.focus();
			});

			this.$loginSpinner = this.$(".login-spinner");
			this.$("[rel=tooltip]").tooltip();

			this.$("#security_prompts_holder").hide();
		},
		show : function(options){
			options = options || {};
			this.cancellable = !!options.cancellable;
			if(this.cancellable && !this.isOpen ){
				this.$("#login_cancel").show();
			}
			this.$loginSpinner.hide();
			this.$("#login").button("reset");
			this.$modal.modal("show");
			this.isOpen = true;
			App.mainView.setTitle("loginPrompt.pageTitle");
			App.captcha.preload();
		},
		hide : function(){
			this.$modal.modal("hide");
			this.isOpen = false;
			this.$loginSpinner.hide();
			this.$("#login").button("reset");
			this.$("#login_cancel").hide();
			this.$(".message").hide();
			$("#modal_language_chooser").modal("hide");
			App.mainView.setTitle();
		},
		login : function(event){
			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}
			this.$(".message").slideUp("fast");
			this.hideValidationErrors();

			if(!this.model.get("showPassword")){
				this.model.set("password", App.getTenant());
			}

			if(this.validate(["username", "password"], {displayErrors: true})){ return; }

			if(this.model.get("hasRemoteAuthenticationProviders") && this.usernameChecked === null){
				return this.checkUsername();
			}

			var self = this;

			this.$loginSpinner.show();
			this.$("#login").button("loading");
			this.submitting = true;

			this.model.login({
				noLoginPrompt : true,
				success : this.checkLoginResponse,
				error   : function(result){
					self.submitting = false;
					self.loginInvalid(result);
					if(result.status === 403){
						self.model.set("showPassword", true);
					}
				}
			});
		},
		loginCancel : function(event){
			if(event && event.preventDefault) { event.preventDefault(); }
			this.trigger("loginCanceled");
			this.hide();
		},

		onShowPasswordChanged : function(){
			this.$passwordContainer = this.$("#password_container");
			if(this.model.get("showPassword")){
				this.model.set("password", "");
				this.$passwordContainer.show();
			}else{
				this.$passwordContainer.hide();
			}
		},

		checkLoginResponse : function(user){
			this.submitting = false;

			if(this.model.get("setQuestions")){
				this.showSecurityPrompts();
			} else if(this.model.get("answerQuestions")) {
				this.showSecurityPrompts();
			} else if(this.model.get("setPassword")) {
				this.showSecurityPrompts();
			} else{
				this.loggedIn();
			}
		},
		onRemoteLogin : function(event){
			var redirectUrl = $(event.currentTarget).data("redirectUrl");
			redirectUrl+= (redirectUrl.indexOf("?") !== -1 ? "&" : "?") + "destinationUrl=" + encodeURIComponent(top.location.href);
			App.redirect(redirectUrl);
		},
		onUserNameChanged : function(){
			if(this.usernameChecked === null || this.usernameChecked !== this.$username.val()){
				this.usernameChecked = null;

				var hasRemoteAuthenticationProviders = this.model.get("hasRemoteAuthenticationProviders");
				this.$("#login").toggle(!hasRemoteAuthenticationProviders);
				this.$("#continue").toggle(hasRemoteAuthenticationProviders);
				if(hasRemoteAuthenticationProviders){
					this.model.set("showPassword", false);
				}
			}
		},
		checkUsername : function(){
			var self = this;

			var options = {};

			options.success = function(response){
				var provider = response.provider;
				if (provider.method === "GET") {
					var redirectUrl = provider.redirectUrl + (provider.redirectUrl.indexOf("?") !== -1 ? "&" : "?") + "loginHint=" + self.model.get("username");
					redirectUrl+= "&destinationUrl=" + encodeURIComponent(top.location.href);
					App.redirect(redirectUrl);
				} else {
					self.onUsernameChecked();
				}
			};

			options.error = function(){
				//console.error("Error checking username.");
				self.onUsernameChecked();
			};

			this.model.getAuthenticationProviderForUsername(this.model.get("username"), options);
		},
		onUsernameChecked : function(){
			this.usernameChecked = this.model.get("username");
			this.$("#login").toggle(true);
			this.$("#continue").toggle(false);
			if(!App.config.requirePasswordForLogin){
				//try passwordless login
				this.login();
			} else {
				this.model.set("showPassword", true);
				this.$password.focus();
			}
		},
		showSecurityPrompts : function(){
			this.$("#security_prompts_holder").show();
			this.$("#register_form").hide();
			this.$("#forgot_password_form").hide();
			this.$("#forgot_password_send_email").hide();
			this.$("#login_form").hide();
			this.$("#remote_token_form").hide();

			if(!this.securityPromptsView){
				this.securityPromptsView = new SecurityPromptsView({
					model       : this.model,
					el          : this.$("#security_prompts_holder"),
					cancellable : true,
					isModal     : true
				});

				this.listenTo(this.securityPromptsView, "securityCompleted", this.fetchUser);
				this.listenTo(this.securityPromptsView, "cancelled", this.cancelForgotPassword);
				this.listenTo(this.securityPromptsView, "afterRender", function(){
					this.$modal.modal("reflow");
				});
			}
			this.securityPromptsView.render({});
		},
		showForgotPasswordEmail : function(event){
			if(event) { event.preventDefault(); }
			this.$("#forgot_password_form").hide();
			this.$("#forgot_password_send_email").show();
			this.$("#forgot_password_username").focus();
			this.$modal.modal("reflow");
		},
		showForgotPasswordSecurityQuestions : function(event){
			this.model.set("answerQuestions", true);
			this.showSecurityPrompts();
			this.$modal.modal("reflow");
		},
		showChangeExpiredPassword : function(event){
			if(event) { event.preventDefault(); }
			this.$("#login_form").hide();
			this.$("#expired_password_form").show();
			this.$("#expired_password_new").focus();
			this.$modal.modal("reflow");
		},
		changeExpiredPasswordSend : function(event) {
			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			if(this.validate(["username", "password", "passwordNew", "passwordConfirm"], {displayErrors: true})){ return; }

			this.$(".expired-password-spinner").show();
			this.$("#expired_password_send").button("loading");

			this.model.changeExpiredPassword({
				success : this.expiredPasswordSuccess,
				error   : this.expiredPasswordError
			});
		},
		fetchUser : function(){
			this.model.checkLoggedIn({
				force   : true,
				success : this.loggedIn,
				error   : this.loginInvalid
			});
		},
		loggedIn : function(){
			this.$loginSpinner.hide();
			this.$(".message").hide();
			this.$("#login").button("reset");
			this.trigger("loggedIn", this.model);
			this.model.set("password", "");
			App.analytics.trackEvent("Account", "Logged In");
		},

		loginInvalid : function(result){
			if(result.response){
				result = result.response;
			} else if(result.responseText){
				try{
					result = JSON.parse(result.responseText);
				} catch(e){ result = {}; }
			}

			var message = _.escapeHTML(result.message);

			if(result.errorCode){
				var errorMessageKey = ("errors.codes." + result.errorCode.toString());
				var errorCodeMessage = App.i18n(errorMessageKey, result);
				if(errorCodeMessage !== errorMessageKey){
					message = errorCodeMessage;
				}
				if(result.errorCode === 9007){
					this.model.set("showPassword", true);
					this.$password.focus();
				}
			}

			this.$loginSpinner.hide();
			this.$("#login").button("reset");

			if(this.model.get("passwordExpired")){
				this.showChangeExpiredPassword();
				return;
			}
			if(this.model.get("otpUsed")){
				message = App.i18n("loginPrompt.errors.otpUsed");
			}

			if(message){
				this.$("#login_error_response")
					.text(message)
					.slideDown("fast");
			}

			this.trigger("loggedInvalid", this.model);
		},

		register : function(event){
			if(event) { event.preventDefault(); }

			this.hideValidationErrors();
			this.model.set({
				firstName : "",
				lastName : "",
				email : "",
				company: "",
				alternativeCaptchaResponse: "",
				captchaResponse: ""
			});
			this.$("#register_form").show();
			this.$("#forgot_password_form").hide();
			this.$("#forgot_password_send_email").hide();
			this.$("#security_prompts_holder").hide();
			this.$("#login_form").hide();
			this.$(".message").hide();

			this.setupCaptcha();

			this.$("#register_email").focus();

			this.$modal.modal("reflow");

		},
		registerSubmit : function(event){
			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			this.$(".message").slideUp("fast");

			if(App.captcha.useFallbackCaptcha()){
				if(this.validate(["firstName", "lastName", "email", "company", "alternativeCaptchaResponse"], {displayErrors: true})){ return; }
			} else {
				if(this.validate(["firstName", "lastName", "email", "company"], {displayErrors: true})){ return; }
			}

			if(App.captcha.useRecaptcha()){
				this.$(".recaptcha-required").hide();
				var self = this;
				App.captcha.check(function(token){
					self.model.set("captchaResponse", token);
					self.registerSend();
				});
			} else {
				this.registerSend();
			}
		},
		registerSend : function(){

			this.$(".message").slideUp("fast");

			if(App.captcha.useRecaptcha()){
				var recaptchaError = !this.model.get("captchaResponse");

				this.$(".recaptcha-required").toggle(recaptchaError);

				if(recaptchaError){ return; }
			}


			this.$("#register_cancel").show();
			this.$("#register_form .control-group.error").removeClass("error");

			this.$(".register-spinner").show();
			this.$("#register_submit").button("loading");
			this.$("#register_cancel").hide();

			this.model.set("tenant",  App.getTenant());
			this.model.register({
				success : this.registered,
				error   : this.registerInvalid
			});
		},
		registered : function(){
			this.$(".message").hide();
			this.$("#register_submit").button("reset");
			this.$(".register-spinner").hide();
			this.$("#register_invalid").hide();
			this.$("#register_form").hide();
			this.$("#register_success").show();
			this.$modal.modal("reflow");
			App.analytics.trackEvent("Account", "Self Register");
		},
		registerInvalid : function(model, jqXHR){

			this.$(".message").hide();
			this.$("#register_cancel").show();
			this.$("#register_submit").button("reset");
			this.$(".register-spinner").hide();
			this.$("#register_invalid").stop().slideDown("fast");

			if(jqXHR.response && jqXHR.response.message){
				this.$("#register_invalid .error-message").text(_.escapeHTML(jqXHR.response.message));
			}

			this.setupCaptcha();
			this.$modal.modal("reflow");
		},

		setupCaptcha : function(event){
			if(event && event.preventDefault) { event.preventDefault();}

			if(App.captcha.isEnabled()) {
				App.captcha.load();
			} else {
				this.$("#captcha").hide();
			}
		},
		captchaReady : function(){
			this.$("#recaptcha_widget .alternative-captcha").hide();
			this.$("#recaptcha_widget .cannot-load").hide();
			this.$("#recaptcha").show();
		},
		captchaUseFallback : function(){

			this.$("#recaptcha_widget .cannot-load").hide();
			this.$("#recaptcha_widget .alternative-captcha").show();
			this.$("#recaptcha_widget #alternative_captcha_image").attr("src", App.captcha.getFallbackImage());
			this.$("#recaptcha").hide();
		},
		captchaLoadStart : function(){
			this.$("#register_submit").prop("disabled", true);
			this.$("#captcha").show();
			this.$("#recaptcha").hide();
			this.$("#recaptcha_widget .loading").show();
			this.$("#recaptcha_widget .cannot-load").hide();
			this.$("#recaptcha_widget .alternative-captcha").hide();
			this.hideValidationError("#alternative_captcha_response");
		},
		captchaLoadEnd : function(){
			this.$("#register_submit").prop("disabled", false);
			this.model.set({
				alternativeCaptchaResponse : "",
				captchaResponse : ""
			});

			this.$("#recaptcha_widget .loading").hide();
			this.$("#recaptcha_widget").removeClass("loading");
		},
		captchaError : function(){
			this.model.set("captchaResponse", "");
			App.captcha.reload();
		},
		captchaExpired : function(){
			this.model.set("captchaResponse", "");
		},
		forgotPassword : function(event){

			if(event) { event.preventDefault(); }

			this.hideValidationErrors();

			this.$("#forgot_password_form").show();
			this.$("#forgot_password_send_email").hide();
			this.$("#security_prompts_holder").hide();
			this.$("#login_form").hide();
			this.$("#remote_token_form").hide();
			this.$(".message").hide();
			this.$("#forgot_password_username").focus();

			this.$(".forgot-password-title").toggle(!this.model.get("passwordExpired"));
			this.$(".expired-password-title").toggle(!!this.model.get("passwordExpired"));

			this.$modal.modal("reflow");

		},
		forgotPasswordSend : function(event){

			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			this.$(".message").slideUp("fast");
			if(this.validate(["username"], {displayErrors: true})){ return; }


			this.$(".forgot-password-spinner").show();
			this.$("#forgot_password_send_email_send").button("loading");

			this.model.forgotPassword({
				success : this.passwordSent,
				error   : this.forgotPasswordInvalid
			});
		},

		expiredPasswordSuccess : function(){
			this.model.set("username", "");
			this.model.set("password", "");

			this.$(".message").hide();
			this.$("#expired_password_send").button("reset");
			this.$(".expired-password-spinner").hide();

			this.$("#expired_password_form").hide();
			this.$("#security_prompts_holder").hide();
			this.$("#forgot_password_form").hide();
			this.$("#forgot_password_send_email").hide();
			this.$("#login_form").show();
			this.$("#login_form #changed_expired_password").show();
			this.$("#remote_token_form").show();
			this.$modal.modal("reflow");
		},

		expiredPasswordError : function(account, result){
			var message = result.responseText;
			try{
				message = JSON.parse(message);
				message = _.escapeHTML(message.message);
			} catch(e){
				message = "";
			}

			this.$(".message").hide();
			this.$("#expired_password_send").button("reset");
			this.$(".expired-password-spinner").hide();
			this.$("#expired_password_invalid").stop().slideDown("fast");
			this.$(".error-message").text(message);
			this.$modal.modal("reflow");
		},

		forgotPasswordInvalid : function(jqXHR){

			var message = jqXHR && jqXHR.responseText;
			try{
				message = JSON.parse(message);
				message = _.escapeHTML(message.message);
			} catch(e){
				message = "";
			}

			this.$(".message").hide();
			this.$("#forgot_password_send_email_send").button("reset");
			this.$(".forgot-password-spinner").hide();
			this.$("#forgot_password_invalid").stop().slideDown("fast");
			this.$(".error-message").text(message);
			this.$("#forgot_password_username").focus();
			this.$modal.modal("reflow");
		},

		passwordSent : function(){
			this.$(".message").hide();
			this.$("#forgot_password_send_email_send").button("reset");
			this.$(".forgot-password-spinner").hide();

			this.$("#register_form").hide();
			this.$("#security_prompts_holder").hide();
			this.$("#forgot_password_form").hide();
			this.$("#forgot_password_send_email").hide();
			this.$("#login_form").show();
			this.$("#login_form #password_sent").show();
			this.$("#remote_token_form").show();
			this.$modal.modal("reflow");
			App.analytics.trackEvent("Account", "Password Send");
		},
		cancelForgotPassword : function(){
			this.$loginSpinner.hide();
			this.hideValidationErrors();
			this.$("#login").button("reset");
			this.$(".message").hide();
			this.$("#security_prompts_holder").hide();
			this.$("#forgot_password_form").hide();
			this.$("#forgot_password_send_email").hide();
			this.$("#register_form").hide();
			this.$("#register_success").hide();

			this.$("#login_form").show();
			this.$("#remote_token_form").show();
			this.model.unset("setQuestions");
			this.model.unset("answerQuestions");
			this.model.unset("setPassword");
			this.model.unset("passwordExpired");

			if(this.securityPromptsView){
				this.securityPromptsView.remove();
				this.securityPromptsView = null;
			}

			this.$username.focus();
			this.$modal.modal("reflow");
		},
		goRemoteToken : function(event){
			if(event && event.preventDefault) { event.preventDefault(); }
			var remoteToken = _.trim(this.$("#login_remote_token").val());
			if(_.isEmpty(remoteToken)){
				this.$("#login_remote_token").focus();
				return;
			}
			App.remoteToken = remoteToken;
			App.redirect({page: App.config.pages.REMOTE_CONTROL, action: "remoteToken/" + remoteToken, forceHost: true});
		}

	});

});

