define('views/compatibility/tests/resolution',['require','underscore','library/vlp/app','../test','hbs!tpls/compatibility/tests/resolution.handlebars'],function (require) {
	"use strict";

	var _ = require("underscore");

	//class dependencies
	var App      = require("library/vlp/app"),
	    TestBase = require("../test"),
	    template = require("hbs!tpls/compatibility/tests/resolution.handlebars");

	return TestBase.extend({
		template : template,
		name : "resolution",
		serialize : function(){
			var data = TestBase.prototype.serialize.call(this);

			data.minWidth      = App.config.compatibilityMinWidth;
			data.minHeight     = App.config.compatibilityMinHeight;
			data.currentHeight = window.innerHeight;
			data.currentWidth  = window.innerWidth;
			data.monitorHeight = window.screen.height * window.devicePixelRatio;
			data.monitorWidth  = window.screen.width * window.devicePixelRatio;
			return data;
		},
		run : function(){

			var monitorHeight = window.screen.height * window.devicePixelRatio;
			var monitorWidth  = window.screen.width * window.devicePixelRatio;

			if(monitorHeight < App.config.compatibilityMinHeight || monitorWidth < App.config.compatibilityMinWidth){
				this.warnings.monitorResolution = true;
			} else if(window.innerHeight < App.config.compatibilityMinHeight || window.innerWidth < App.config.compatibilityMinWidth){
				this.warnings.windowSize = true;
			}

			var result = _.isEmpty(this.warnings);
			this.setStatus(result ? "success" : "warning");
			this.trigger(TestBase.COMPLETE, result);
		}
	});

});

