define('views/console/manual-toc',['require','jquery','underscore','library/vlp/view','library/vlp/app','hbs!tpls/console/manual-toc-item.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var BaseView         = require("library/vlp/view"),
	    App              = require("library/vlp/app"),
	    manualTocItemTPL = require("hbs!tpls/console/manual-toc-item.handlebars");

	return BaseView.extend({
		template    : manualTocItemTPL,

		expanded : false,

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .expand-all"       : "onExpandAllToggle",
			"click .twisty"           : "onExpandToggle",
			"click a.manual-toc-item" : "onGotoStep",
			"show"                    : "onShow",
			"shown"                   : "onShown",
			"hidden"                  : "onHidden"
		},
		/**
		 * constructor
		 */
		initialize : function(options){
			_.bindAll(this);

			options = options || {};

			this.$tocHolder = this.$("#manual_toc");

			this.entitlement = options.entitlement;
			this.listenTo(this.model, "change:activeStepNumber", this.highlightActiveStep);
		},


		remove : function(){
			this.$el.modal("hide");
			BaseView.prototype.remove.call(this);
		},


		/**
		 * Display the content.
		 */
		render : function(){
			//Handled by onShow

			//Always return this for chaining
			return this;
		},

		//Event callbacks
		onShow : function(){
			this.renderTOC(this.model.get("toc"), this.$tocHolder, 2);
			if(this.expanded){
				this.expandAll();
			}

			this.highlightActiveStep();
			var $activeItems = this.$tocHolder.find("a.active");
			if($activeItems.length !== 0){
				this.$el.scrollTop($activeItems.position().top - this.$tocHolder.position().top - this.$el.position().top);
			}

		},
		onShown : function(){
			var $activeItems = this.$tocHolder.find("a.active");
			if($activeItems.length !== 0){
				this.$el.scrollTop($activeItems.position().top - this.$tocHolder.position().top - this.$el.position().top);
			}

			if(this.entitlement){
				App.analytics.trackEvent("Entitlement: Manual", "Show: Table of Contents", this.entitlement.get("sku") + " - manual:" + this.model.get("name") + " - locale:" + this.model.get("locale"));
			}
		},
		onHidden : function(){
			this.$tocHolder.empty();
		},
		onExpandToggle : function(event){
			var self = this;
			var $item = $(event.currentTarget);

			var childrenActive = $(this).next().find(".active").length;

			// don"t toggle if switching from a sub-chapter to the main chapter
			if(childrenActive !== 0) { return; }

			$item.toggleClass("expanded")
				.next().toggleClass("hide")
				.promise().done(function(){

					// change "expand/collapse all" state if necessary
					var totalTwisties = self.$tocHolder.find(".twisty").length,
					    totalExpandedTwisties = self.$tocHolder.find(".twisty.expanded").length;


					if(totalTwisties === totalExpandedTwisties){
						self.expanded = true;
						self.$(".expand-all").button("collapse");

					} else{
						self.expanded = false;
						self.$(".expand-all").button("reset");
					}
					$("#modal_manual_toc").modal("reflow");
				});
		},
		onExpandAllToggle : function(){
			if(this.expanded){
				this.collapseAll();
			} else{
				this.expandAll();
			}
		},

		onGotoStep : function(event){

			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			//this.$el.modal("hide");
			var stepNumber = parseInt($(event.currentTarget).data("stepNumber"), 10);
			this.model.set("activeStepNumber", stepNumber - 1);

		},

		highlightActiveStep : function(){
			this.$("#manual_toc a").removeClass("active");
			var $active = this.$("#manual_toc a[data-step-number=" + (this.model.get("activeStepNumber") + 1) + "]");
			$active.addClass("active");
			//expand parents
			$active
				.parents(".toc-children")
				.removeClass("hide")
				.prev()
				.addClass("expanded");
		},
		renderTOC : function(items, $attachTo, level){
			if(!items) { return; }

			var i;
			for(i = 0; i < items.length; i++){
				var item = items[i];

				item.level = level;
				item.leaf = Boolean(!item.children || item.children.length === 0);
				var html = this.template(item);
				$attachTo.append(html);

				if(item.children){
					this.renderTOC(item.children, $attachTo.find(".toc-children:last"), level + 1);
				}

			}

		},

		expandAll : function(){
			this.$tocHolder.find(".twisty").addClass("expanded")
				.next().removeClass("hide");
			this.expanded = true;

			this.$(".expand-all").button("collapse");
			$("#modal_manual_toc").modal("reflow");
		},
		collapseAll : function(){
			this.$tocHolder.find(".twisty").removeClass("expanded")
				.next().addClass("hide");
			this.expanded = false;

			this.$(".expand-all").button("reset");
			$("#modal_manual_toc").modal("reflow");
		}

	});
});
