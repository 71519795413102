
define('hbs!tpls/speed-test/main.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    return "<div class=\"page-content\">\n	<h2 class=\"title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"speedTest.title",{"name":"$","hash":{},"data":data}))
    + "</h2>\n	<div class=\"results-container\">\n		<div id=\"results\"></div>\n		<div id=\"bandwidth_speedometer\"></div>\n	</div>\n\n	<div id=\"action_row\">\n		<div id=\"link_box\"><button type=\"button\" class=\"btn btn-primary btn-large\" id=\"trigger\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"speedTest.startTest",{"name":"$","hash":{},"data":data}))
    + "</button></div>\n	</div>\n	<p class=\"description\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"speedTest.description",{"name":"$","hash":{},"data":data}))
    + "</p>\n	<h3 class=\"recommended-title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"speedTest.recommended.title",{"name":"$","hash":{},"data":data}))
    + "</h3>\n	<ul class=\"recommended-values\">\n		<li>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"speedTest.recommended.latency",{"name":"$","hash":{},"data":data}))
    + "</li>\n		<li>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"speedTest.recommended.bandwidth",{"name":"$","hash":{},"data":data}))
    + "</li>\n	</ul>\n	<p class=\"disclaimer\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"speedTest.disclaimer",{"name":"$","hash":{},"data":data}))
    + "</p>\n	<div id=\"load_box\"><span id=\"status\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('speed_test_main', t);
return t;
});

