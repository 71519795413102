
define('hbs!tpls/catalogs/lab/systems.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "	<div class=\"systems\">\n"
    + ((stack1 = helpers.each.call(depth0,depth0,{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n";
},"2":function(depth0,helpers,partials,data) {
    return "			<span class=\"system icon fa fa-"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "\" data-system=\""
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "\"></span>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.length : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
Handlebars.registerPartial('catalogs_lab_systems', t);
return t;
});

