define('views/catalogs/transcript-lab',['require','underscore','library/vlp/app','views/catalogs/lab','hbs!tpls/catalogs/transcript-lab.handlebars','views/catalogs/lab-certificate','models/entitlement-life-cycle','views/catalogs/entitlement-life-cycle'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//class dependencies
	var App                      = require("library/vlp/app"),
		LabView                  = require("views/catalogs/lab"),
		TranscriptInfoTPL        = require("hbs!tpls/catalogs/transcript-lab.handlebars"),
		LabCertificateView       = require("views/catalogs/lab-certificate"),
		EntitlementLifeCycle     = require("models/entitlement-life-cycle"),
		EntitlementLifeCycleView = require("views/catalogs/entitlement-life-cycle");

	return LabView.extend({
		template : TranscriptInfoTPL,
		throttleRending : 100,
		forceUserRating : true,
		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		className : "lab-item transcript-lab",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */

		events : {
			"click .lab-certificate"         : "onLabCertificateShow",
			"click .email-single-transcript" : "onEmailTranscript",
			"click .show-entitlement-life-cycle-data" : "onMoreTranscriptData"
		},

		initialize : function(){
			LabView.prototype.initialize.apply(this, arguments);
			this.events = _.defaults(this.events, LabView.prototype.events);

			if(this.model.has("lab")){
				//May get related info separately from catalogs call
				this.listenTo(this.model.get("lab"), "change:badges change:catalogs", this.render);
			}
		},
		serialize : function(){

			var data = this.model.toHandlebars();
			if(data.lab) {
				data = _.defaults(data, data.lab);
			}
			data.userName       = App.user.get("displayName");

			data.display = {
				showInvite      : App.config.showInvite && App.user.get("loggedIn") && this.model.get("tenant") === App.config.tenant,
				showDescription : true
			};

			if(data.badges){
				data.badges.uuid = _.uniqueId("group_");
			}

			delete data.exitAllowed;
			delete data.endAllowed;
			delete data.validPeriod;
			delete data.timeAllotted;
			delete data.timelimit;
			delete data.networkTopology;
			delete data.authors;
			delete data.catalogs;
			return data;

		},
		afterRender : function(){
			LabView.prototype.afterRender.apply(this, arguments);
			this.setupImageErrorHandlers();
		},

		onLabCertificateShow : function() {
			var self = this;

			if (this.labCertificateView) {
				this.labCertificateView.$el.remove();
				this.labCertificateView.remove();
				this.labCertificateView = null;
			}
			this.labCertificateView = new LabCertificateView({
				model : this.model
			});

			this.labCertificateView.render();

			this.$el.append(this.labCertificateView.el);
			this.labCertificateView.show();
			this.labCertificateView.once("hidden", function() {
				self.labCertificateView.$el.remove();
				self.labCertificateView.remove();
				self.labCertificateView = null;
			});

			App.analytics.trackEvent("Transcript", "Show: Certificate", this.model.get("sku"));
		},
		onEmailTranscript : function(event){
			var self = this;

			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			if(!App.user.get("accountVerified")){
				return App.mainView && App.mainView.showAccountVerificationMessage();
			}

			this.$(".email-single-transcript .fa-spinner").show();
			this.$(".email-single-transcript .icon").hide();

			this.model.email({
				success : function(){
					self.$(".email-single-transcript").tooltip("show");
					self.$(".email-single-transcript .icon").show();
					self.$(".email-single-transcript .fa-spinner").hide();

					_.defer(function(){
						self.$(".email-single-transcript").prop("disabled", true);
					});

					_.delay(function(){
						self.$(".email-single-transcript").tooltip("hide");
					}, 5000);
					App.analytics.trackEvent("Transcript", "Email: Certificate", self.model.get("sku"));
				},
				error : function(){
					self.$(".email-single-transcript")
						.prop("disabled", false);

					self.$(".email-single-transcript .fa-spinner").hide();
					self.$(".email-single-transcript .icon").show();

				}
			});
		},

		onMoreTranscriptData: function(){
			var self = this;

			var entitlementLifeCycleModel = new EntitlementLifeCycle.Model({
				entitlementKey: this.model.get("entitlementKey")
			});

			var entitlementLifeCycle = new EntitlementLifeCycleView({ //jshint ignore:line
				el: self.$el.find(".activity-history"),
				model: entitlementLifeCycleModel
			});
			if (!this.$el.find(".show-entitlement-life-cycle-data").hasClass("expanded")) {
				entitlementLifeCycleModel.getEntitlementStatsData();
			}

			if (this.$el.find(".show-entitlement-life-cycle-data").hasClass("expanded")) {
				this.$el.find(".show-entitlement-life-cycle-data").removeClass("expanded").addClass("closed");
				this.$el.find(".activity-history").removeClass("show").addClass("hide");
			} else {
				this.$el.find(".show-entitlement-life-cycle-data").removeClass("closed").addClass("expanded");
				this.$el.find(".activity-history").removeClass("hide").addClass("show");
			}
		}

	});

});

