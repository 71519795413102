/* globals WMKS */
define('library/underscore/key-codes-to-scan-codes',['require','underscore'],function (require) {
	"use strict";
	var _  = require("underscore");

	_.mixin({
		keyCodesToScanCodes: function(keyCodes, keyboardLayoutId){
			keyboardLayoutId = keyboardLayoutId || "en-US";
			return _.map(keyCodes, function(keyCode){
				var scanCode = WMKS.keyboardUtils._jsToVScanTable[keyCode];
				if (!scanCode){
					scanCode = WMKS.CONST.KB.VScanMap[keyboardLayoutId][keyCode];
				}
				if(!scanCode){
					console.log("keyCode not found", keyCode);
				}
				return scanCode;
			});
		}
	});

	return _;
});

