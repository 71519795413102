
define('hbs!tpls/instructor/manual.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "	<div class=\"lab-chooser\">\n		<label for=\"lab_chooser\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.labChooser.label",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n		<select id=\"lab_chooser\" class=\"lab-chooser-select\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.labs : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</select>\n	</div>\n";
},"2":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper;

  return "				<option value=\""
    + this.escapeExpression(((helper = (helper = helpers.sku || (depth0 != null ? depth0.sku : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"sku","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depths[1] != null ? depths[1].id : depths[1]),(depth0 != null ? depth0.manualId : depth0),{"name":"eq","hash":{},"fn":this.program(3, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(((helper = (helper = helpers.sku || (depth0 != null ? depth0.sku : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"sku","hash":{},"data":data}) : helper)))
    + " - "
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"3":function(depth0,helpers,partials,data) {
    return "selected=\"selected\"";
},"5":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.tableOfContentsPosition : depth0),"top",{"name":"eq","hash":{},"fn":this.program(6, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.showMoreOptions : depth0),{"name":"if","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"6":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"7":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<li class=\""
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.showMoreOptions : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n					<a class=\"mc-toc\" data-toggle=\"modal\" data-target=\"#modal_manual_toc\" rel=\"popover\" data-trigger=\"manual\" data-placement=\"left\" data-class=\"popover-info\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-content","console.manual.manualChangedMessage",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","console.manual.manualChangedTitle",{"name":"$$","hash":{},"data":data}))
    + "><span class=\"fa fa-fw fa-list\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.tableOfContents",{"name":"$","hash":{},"data":data}))
    + "</a>\n				</li>\n";
},"8":function(depth0,helpers,partials,data) {
    return "top-toc";
},"10":function(depth0,helpers,partials,data) {
    return "dropdown";
},"12":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<li class=\"dropdown\">\n				<a data-toggle=\"dropdown\"><span class=\"caret caret-light\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.moreOptions",{"name":"$","hash":{},"data":data}))
    + "</a>\n				<ul class=\"dropdown-menu pull-right\">\n					"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.networkTopology : depth0),{"name":"if","hash":{},"fn":this.program(13, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.tableOfContentsPosition : depth0),"nested",{"name":"eq","hash":{},"fn":this.program(15, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "				</ul>\n			</li>\n";
},"13":function(depth0,helpers,partials,data) {
    return "<li><a class=\"topology-link\" data-toggle=\"modal\" href=\"#modal_topology\"><span class=\"fa fa-fw fa-sitemap\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.networkTopology",{"name":"$","hash":{},"data":data}))
    + "</a></li>";
},"15":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":this.program(16, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"16":function(depth0,helpers,partials,data) {
    return "							<li>\n								<a class=\"mc-toc\" data-toggle=\"modal\" data-target=\"#modal_manual_toc\" rel=\"popover\" data-trigger=\"manual\" data-placement=\"left\" data-class=\"popover-info\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-content","console.manual.manualChangedMessage",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","console.manual.manualChangedTitle",{"name":"$$","hash":{},"data":data}))
    + "><span class=\"fa fa-fw fa-list\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.tableOfContents",{"name":"$","hash":{},"data":data}))
    + "</a>\n							</li>\n";
},"18":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.pdfManualResource : depth0),{"name":"unless","hash":{},"fn":this.program(19, data, 0),"inverse":this.program(21, data, 0),"data":data})) != null ? stack1 : "");
},"19":function(depth0,helpers,partials,data) {
    return "			<div id=\"steps_wrapper\">\n				<div class=\"no-manual\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.noManual",{"name":"$","hash":{},"data":data}))
    + "\n				</div>\n			</div>\n";
},"21":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<div id=\"manual_pdf\">\n				<iframe id=\"manual_pdf_viewer\" src=\"/pdfjs-dist/web/viewer.html?file="
    + this.escapeExpression((helpers.encodeURIComponent || (depth0 && depth0.encodeURIComponent) || helpers.helperMissing).call(depth0,((stack1 = (depth0 != null ? depth0.pdfManualResource : depth0)) != null ? stack1.path : stack1),{"name":"encodeURIComponent","hash":{},"data":data}))
    + "\"></iframe>\n			</div>\n";
},"23":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.manualFilePath : depth0),{"name":"unless","hash":{},"fn":this.program(24, data, 0),"inverse":this.program(26, data, 0),"data":data})) != null ? stack1 : "");
},"24":function(depth0,helpers,partials,data) {
    return "			<div id=\"steps_wrapper\">\n				<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n			</div>\n";
},"26":function(depth0,helpers,partials,data) {
    return "			<div id=\"manual_pdf\">\n				<iframe id=\"manual_pdf_viewer\" src=\"/pdfjs-dist/web/viewer.html?file="
    + this.escapeExpression((helpers.encodeURIComponent || (depth0 && depth0.encodeURIComponent) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.manualFilePath : depth0),{"name":"encodeURIComponent","hash":{},"data":data}))
    + "\"></iframe>\n			</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper;

  return ((stack1 = (helpers.gt || (depth0 && depth0.gt) || helpers.helperMissing).call(depth0,((stack1 = (depth0 != null ? depth0.labs : depth0)) != null ? stack1.length : stack1),1,{"name":"gt","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "<ul class=\"top-buttons\">\n"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.networkTopology : depth0),(depth0 != null ? depth0.id : depth0),(depth0 != null ? depth0.showRemoteControl : depth0),{"name":"or","hash":{},"fn":this.program(5, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n\n<div id=\"step_listing\">\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.id : depth0),{"name":"unless","hash":{},"fn":this.program(18, data, 0, blockParams, depths),"inverse":this.program(23, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</div>\n\n<div id=\"modal_topology\" class=\"modal hide fade global fluid\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\"><span class=\"fa fa-fw fa-sitemap\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.networkTopology",{"name":"$","hash":{},"data":data}))
    + "</h3>\n			</div>\n			<div class=\"modal-body\">\n				<img src=\"\" alt=\"\"/>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_manual_toc\" class=\"modal hide fade global fluid-height scrollable\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\"><span class=\"fa fa-fw fa-list\"></span>"
    + this.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n			</div>\n			<div class=\"modal-body-header\">\n				<span class=\"expand-all\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-collapse-text","console.manual.toc.collapseAll",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.toc.expandAll",{"name":"$","hash":{},"data":data}))
    + "</span>\n			</div>\n			<div class=\"modal-body\">\n				<ul id=\"manual_toc\">\n				</ul>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true,"useDepths":true});
Handlebars.registerPartial('instructor_manual', t);
return t;
});

