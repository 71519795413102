/**
 * Tenant EULA Backbone View
 */
define('views/common/tenant-eula',['require','underscore','library/vlp/app','library/vlp/view','models/service-term','hbs!tpls/common/tenant-eula.handlebars','i18n!nls/eula'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//class dependencies
	var App         = require("library/vlp/app"),
	    BaseView    = require("library/vlp/view"),
	    ServiceTerm = require("models/service-term"),
	    tpl         = require("hbs!tpls/common/tenant-eula.handlebars"),
	    pageTexts   = require("i18n!nls/eula");

	return BaseView.extend({
		template : tpl,

		events : {
			"click .eula-agree"         : "agree",
			"click .deny"               : "deny"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);

			//merges eula specific texts to be globally accessible and available to the tpls
			App.locale.mergeI18n(pageTexts);


			options = options || {};
			this.serviceTermId = options.serviceTermId;
		},
		/**
		 * Display the content.
		 */
		render : function(){

			if(!this.serviceTerms){
				this.serviceTerms = new ServiceTerm.Model({id : this.serviceTermId});
				this.serviceTerms.fetch({
					success : this.render,
					error   : this.deny
				});
			}

			this.$el.html(this.template(this.serviceTerms));
			App.mainView.setTitle("eula.pageTitle");
		},
		agree : function(){
			var self = this;
			this.model.set("eulaAccepted", true);
			var params = {serviceTermId : this.serviceTermId};

			if(!App.config.tenant){
				params.tenant = this.model.getDefaultTenant();
			}
			this.$(".eula-agree").button("loading");
			var options = {
				success : function(){
					//do something after save, alert the user or whatever.
					self.$(".eula-agree").button("reset");
					self.trigger("tenantEulaAccepted");
					App.analytics.trackEvent("Account", "Eula", "Agreed");
				},
				properties : [],
				params     : params
			};
			this.model.action("acceptServiceTerms", options);

		},
		deny : function(){
			this.trigger("tenantEulaDenied");
			App.analytics.trackEvent("Account", "Eula", "Denied");
		}
	});

});
