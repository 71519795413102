
define('hbs!tpls/console/end-button.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "	<div \n		class=\"completion-popover-trigger\" \n		rel=\"popover\" \n		data-trigger=\"hover\" \n		data-placement=\"bottom\" \n		data-html=\"true\" \n		data-content-target=\"#popover_content_completion_criteria\" \n		"
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","completionCriteria.notMetTitle",{"name":"$$","hash":{},"data":data}))
    + "\n		data-class=\"lab-completion-criteria-warning\"\n		data-container=\"#lab\"\n		>\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.type : depth0),"end",{"name":"eq","hash":{},"fn":this.program(2, data, 0),"inverse":this.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "	</div>\n	<div class=\"hide\">\n		<div id=\"popover_content_completion_criteria\" class=\"lab-completion-criteria-warning\">\n			<div class=\"warning-message\"></div>\n			<div class=\"checking-status hide\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.checkingStatus",{"name":"$","hash":{},"data":data}))
    + " <span class=\"loading\"></span></div>\n			<dl class=\"dl-horizontal dl-wide\">\n				<div class=\"time-elapsed-display\">\n					<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.timeElapsedLabel",{"name":"$","hash":{},"data":data}))
    + "</dt>\n					<dd class=\"time-elapsed-value\">"
    + this.escapeExpression((helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.labTimeElapsed : depth0),{"name":"daysHoursMinutes","hash":{"milliseconds":true},"data":data}))
    + "</dd>\n				</div>\n				<div class=\"manual-steps-read\">\n					<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.manualStepsReadLabel",{"name":"$","hash":{},"data":data}))
    + "</dt>\n					<dd class=\"manual-steps-read-value\">"
    + this.escapeExpression((helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.stepVisitedPercent : depth0),{"name":"percent","hash":{},"data":data}))
    + "</dd>\n				</div>\n			</dl>\n		</div>\n	</div>\n";
},"2":function(depth0,helpers,partials,data) {
    return "			<button type=\"button\" class=\"btn entitlement-end-prompt\" data-toggle=\"modal\" data-target=\"#modal_end\" rel=\"tooltip\" data-placement=\"bottom\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.end.tip",{"name":"$$","hash":{},"data":data}))
    + ">\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.end.label",{"name":"$","hash":{},"data":data}))
    + "\n			</button>\n";
},"4":function(depth0,helpers,partials,data) {
    return "			<button type=\"button\" class=\"btn entitlement-grade-prompt\" data-toggle=\"modal\" data-target=\"#modal_grade\" rel=\"tooltip\" data-placement=\"bottom\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.grade.tip",{"name":"$$","hash":{},"data":data}))
    + ">\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.grade.label",{"name":"$","hash":{},"data":data}))
    + "\n			</button>\n";
},"6":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.type : depth0),"end",{"name":"eq","hash":{},"fn":this.program(7, data, 0),"inverse":this.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(depth0,helpers,partials,data) {
    return "		<button type=\"button\" class=\"btn entitlement-end-prompt\" data-toggle=\"modal\" data-target=\"#modal_end\" rel=\"tooltip\" data-placement=\"bottom\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.end.tip",{"name":"$$","hash":{},"data":data}))
    + ">\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.end.label",{"name":"$","hash":{},"data":data}))
    + "\n		</button>\n";
},"9":function(depth0,helpers,partials,data) {
    return "		<button type=\"button\" class=\"btn entitlement-grade-prompt\" data-toggle=\"modal\" data-target=\"#modal_grade\" rel=\"tooltip\" data-placement=\"bottom\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.grade.tip",{"name":"$$","hash":{},"data":data}))
    + ">\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.grade.label",{"name":"$","hash":{},"data":data}))
    + "\n		</button>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.completionStatusForLabCriteria : depth0),"completed",{"name":"ne","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});
Handlebars.registerPartial('console_end_button', t);
return t;
});

