define('views/common/system-messages-popover',['require','jquery','underscore','backbone','hbs!tpls/common/system-messages-popover.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var  tpl = require("hbs!tpls/common/system-messages-popover.handlebars");

	return Backbone.View.extend({
		template : tpl,

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .acknowledge" : "acknowledge",
			"click li"           : "showMessage"
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(){
			_.bindAll(this);
			this.listenTo(this.collection, "request sync error", function(object){
				if(object === this.collection){
					this.render();
				}
			}, this);
			this.listenTo(this.collection, "remove", this.render);
		},
		/**
		 * Display the content.
		 */
		render : function(){

			this.$el.html(this.template(this.collection));
			//Always return this for chaining
			return this;
		},

		showMessage : function(event){
			var id = $(event.currentTarget).data("id");
			var message = this.collection.get(id);
			if(message){
				this.trigger("showMessage", message);
			}
		},

		acknowledge : function(event){
			event.stopImmediatePropagation();
			var self = this;
			var $button = $(event.currentTarget);
			var id = $button.val();
			var message = this.collection.get(id);
			if(message){
				if(!message.get("isImmediate")){
					$button.prop("disabled", true);
					message.acknowledge({
						success:function(){
							$button.prop("disabled", false);
							self.collection.remove(message);
						}
					});
				} else {
					this.collection.remove(message);
				}
			}
		}
	});

});
