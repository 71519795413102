
define('hbs!tpls/layout/layout-no-login.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"wrapper\" class=\"no-login\">\n	<a class=\"vmware-logo\" href=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"learnMoreUrl",{"name":"config","hash":{},"data":data}))
    + "\" target=\"_blank\"></a>\n	<div id=\"container_outer\">\n\n"
    + ((stack1 = this.invokePartial(partials.header,depth0,{"name":"header","data":data,"indent":"\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + ((stack1 = this.invokePartial(partials.global_modals,depth0,{"name":"global_modals","data":data,"indent":"\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + ((stack1 = this.invokePartial(partials.global_components,depth0,{"name":"global_components","data":data,"indent":"\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "\n		<div id=\"main_content\">\n"
    + ((stack1 = this.invokePartial(partials.main,depth0,{"name":"main","data":data,"indent":"\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "		</div>\n	</div>\n</div>\n";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('layout_layout_no_login', t);
return t;
});

