
define('hbs!tpls/catalogs/lab/reviews-and-ratings.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "	<div class=\"reviews\">\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"ratingEnabled",{"name":"config","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"reviewsEnabled",{"name":"config","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n";
},"2":function(depth0,helpers,partials,data) {
    var helper;

  return "			<div class=\"rating-wrapper hide\" data-html=\"true\" data-toggle=\"popover\" data-placement=\"left\" data-container=\"body\" data-class=\"popover-rating\" data-id=\""
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n				<div class=\"rating\" data-score=\""
    + this.escapeExpression(((helper = (helper = helpers.ratingAvg || (depth0 != null ? depth0.ratingAvg : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"ratingAvg","hash":{},"data":data}) : helper)))
    + "\"></div>\n				<div class=\"spinner hide\"></div>\n			</div>\n";
},"4":function(depth0,helpers,partials,data) {
    return "			<button type=\"button\" class=\"btn a review-count\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.reviews.label",(depth0 != null ? depth0.reviewCount : depth0),{"name":"$","hash":{},"data":data}))
    + "</button>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"ratingEnabled",{"name":"config","hash":{},"data":data}),(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"reviewsEnabled",{"name":"config","hash":{},"data":data}),{"name":"or","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
Handlebars.registerPartial('catalogs_lab_reviews_and_ratings', t);
return t;
});

