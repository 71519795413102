define('models/offer',['require','exports','module','underscore','library/vlp/app','library/vlp/model','library/vlp/collection'],function (require, exports) {
	"use strict";
	var _ = require("underscore");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection");


	//Use exports for requirejs circular dependency resolving
	var Offer = exports;

	//Add Offer to the App.Models namespace
	App.Models.Offer = Offer;


	Offer.Model = BaseModel.extend({
		urlRoot : "/offers",
		defaults : {
			title   : "",
			content : ""
		},
		initialize : function(){
			this.on("change:content", this.contentChanged);
			this.contentChanged();
		},
		contentChanged : function(){
			this.set("isLink", Boolean(String(this.get("content")).match(/^https?:\/\//)));
		},
		parse : function(){
			var result = BaseModel.prototype.parse.apply(this, arguments);
			if(result && result.content && _.isString(result.content)){
				result.content = result.content.replaceAll(".youtube.com", ".youtube-nocookie.com");
			}
			return result;
		}
	});


	Offer.Collection = BaseCollection.extend({
		model : Offer.Model
	});

});

