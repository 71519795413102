
define('hbs!tpls/common/student-enroll.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"modal-dialog\">\n	<div class=\"modal-content\">\n		<div class=\"modal-header\">\n			<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n			<h3 class=\"modal-title\">\n				<span class=\"fa fa-fw fa-globe\"></span>\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"classEnroll.header",{"name":"$","hash":{},"data":data}))
    + "\n			</h3>\n		</div>\n		<form class=\"form-horizontal\">\n			<div class=\"modal-body\">\n				<div class=\"enroll-prompt\">\n					<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"classEnroll.enroll",(depth0 != null ? depth0.name : depth0),{"name":"$","hash":{},"data":data}))
    + "</h4>\n					<div class=\"class-info well well-small\">\n"
    + ((stack1 = this.invokePartial(partials.catalogs_lab_class_details,depth0,{"name":"catalogs_lab_class_details","data":data,"indent":"\t\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "						<div class=\"lab-item well\">"
    + ((stack1 = this.invokePartial(partials.catalogs_lab_full_details,(depth0 != null ? depth0.lab : depth0),{"name":"catalogs_lab_full_details","data":data,"helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "</div>\n					</div>\n				</div>\n				<div class=\"enroll-success hide\">\n					<div id=\"class_enrolled\" class=\"alert alert-success\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"classEnroll.enrolled",(depth0 != null ? depth0.name : depth0),{"name":"$","hash":{},"data":data}))
    + "</div>\n					<div id=\"class_not_started\" class=\"hide alert alert-warning\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"classEnroll.enrolledNotStarted",(depth0 != null ? depth0.name : depth0),{"name":"$","hash":{},"data":data}))
    + "</div>\n					<div class=\"class-info well well-small\">\n"
    + ((stack1 = this.invokePartial(partials.catalogs_lab_class_details,depth0,{"name":"catalogs_lab_class_details","data":data,"indent":"\t\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "					</div>\n				</div>\n				<div class=\"class-full hide\">\n					<div class=\"alert alert-warning\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.fullMessage : depth0),{"name":"$","hash":{},"data":data}))
    + "</div>\n					<div class=\"class-info well well-small\">\n"
    + ((stack1 = this.invokePartial(partials.catalogs_lab_class_details,depth0,{"name":"catalogs_lab_class_details","data":data,"indent":"\t\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "					</div>\n				</div>\n			</div>\n			<div class=\"modal-footer\">\n				<div class=\"enroll-prompt\">\n					<button class=\"btn btn-large submit enroll-button\" type=\"submit\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","classEnroll.enrolling",{"name":"$$","hash":{},"data":data}))
    + ">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"classEnroll.enrollButton",{"name":"$","hash":{},"data":data}))
    + "\n					</button>\n					<a class=\"cancel\" type=\"button\" data-dismiss=\"modal\" href=\"#\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</a>\n				</div>\n				<div class=\"enroll-success class-full hide\">\n					<a class=\"cancel\" type=\"button\" data-dismiss=\"modal\" href=\"#\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</a>\n				</div>\n			</div>\n		</form>\n	</div>\n</div>\n";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('common_student_enroll', t);
return t;
});

