define('views/console/base-console',['require','jquery','underscore','library/vlp/app','library/vlp/view','utilities/browser','library/utilities/screenfull'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App                   = require("library/vlp/app"),
	    BaseView              = require("library/vlp/view"),
	    Browser               = require("utilities/browser"),
	    Screenfull            = require("library/utilities/screenfull");

	return BaseView.extend({

		POWERED_ON     : "powered-on",
		POWERED_DOWN   : "powered-down",
		LOADING        : "loading",
		CANNOT_CONNECT : "cannotConnect",
		UNAVAILABLE    : "unavailable",

		panelPadding : 44,

		centeredWidthPadding  : 125,
		centeredHeightPadding : 160,

		floatWidthPadding   : 135,
		floatHeightPadding  : 170,
		floatPanelPadding   : 10,
		floatMaxWidth       : 1400,


		_paused : false,

		screenStatus : "normal",
		consoleType : "base",

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .maximize":            "maximize",
			"click .console-maximize":    "maximize",
			"click .console-full-screen": "toggleFullscreen",
			"click .full-screen":         "toggleFullscreen",
			"click .console-float":       "float",
			"click .console-dock-left":   "dockLeft",
			"click .console-dock-right":  "dockRight",
			"click .console-dock-center": "dockCenter",
			"click .screen-retry":        "refreshConsole",
			"click .console-refresh":     "refreshConsole"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			options = options || {};
			this.panelManager = options.panelManager;
			this.instructionsPanel = this.panelManager.getPanel("#instructions_panel");

			this.entitlement = options.entitlement;
			this.entitlementView = options.entitlementView;

			var initialPosition = (!App.config.defaultConsolePosition || App.config.defaultConsolePosition === "maximized" ? "center" : App.config.defaultConsolePosition);
			var maximized = (App.config.defaultConsolePosition === "maximized");

			//initialize state
			this.state = {
				maximize : maximized,
				resizing : false,
				position : initialPosition
			};

			var data = {};

			this.state = _.extend(this.state, data);
			this.state.resizing   = false;


			this._debounceWindowResize = _.debounce(this.windowResized, 1000);

			this.listenTo(this.entitlement, {
				"change:screenStatus change:takenOver change:supportViewing change:instructorViewing": this.onScreenStatusChanged
			});

			for(var i = 0; i < this.panelManager.panels.length; i++){
				var panel = this.panelManager.panels[i];
				this.listenTo(panel, "panel:expand panel:close panel:dock panel:float panel:show panel:hide", this.onPanelMoved);
			}

		},
		serialize : function(){
			var data = this.model.toHandlebars();
			data.allowFullscreen = Browser.support.fullscreen;
			data.showConsoleButtons = App.config.showConsoleButtons;
			data.entitlement = this.entitlement.toHandlebars();
			return data;
		},
		afterRender : function(){

			if(!Browser.safari){
				this.$("#browser_fullscreen_warning").remove();
			}
			this.$console        = this.$("#console");
			this.$consoleGroup   = this.$("#console_group");
			this.$consoleWrapper = this.$("#console_wrapper_inner");

			this.$("[rel=popover]").popover();
			this.$("[rel=tooltip]").tooltip();

			if(this.model){
				this.displayState(this.POWERED_ON);
				this.setupPosition(true);

				this.onScreenStatusChanged();
			} else{
				console.warn("No console loaded");
			}

			//Always return this for chaining
			return this;
		},
		refresh : function(){
			//do nothing
		},
		remove : function(){
			this.unload();
			BaseView.prototype.remove.call(this);
		},
		unload : function(){

			if(this.$consoleGroup){
				this.$consoleGroup.off("dragstop.console drag.console");
			}
			this.stopListening();

			$(window).off("resize.console");
		},

		powerOff : function(){
			if (!this.entitlement.get("userActionAllowed")) { return; }
			//do nothing
		},
		powerOn : function() {
			if (!this.entitlement.get("userActionAllowed")) { return; }
			//do nothing
		},
		powerReset : function(event){
			if (!this.entitlement.get("userActionAllowed")) { return; }
			//do nothing
		},
		sendControlAltDelete : function(){
			if (!this.entitlement.get("userActionAllowed")) { return; }
			//do nothing
		},
		sendText : function(text){
			//do nothing
		},
		refreshConsole : function(event) {
			//do nothing
		},

		setModel : function(model){
			if(this.model && model === this.model){
				//same model, nothing to do
				return;
			}

			if(this.model){
				//cleaning up old model
			}

			this.model = model;

			if(this.model){
				//Force resize in case window has changed.
				this.model.unset("dockedWidth");
			}
			this.render();
		},
		displayState : function(state){

			this.$consoleGroup.removeClass("state-loading state-powered-on state-powered-down state-unavailable state-cannot-connect");

			switch(state){
				case this.POWERED_ON:
					this.$consoleGroup.addClass("state-powered-on");

					var $canvas = this.$console.find("canvas");
					this._canvas = $canvas.length && $canvas[0];
					$(document).on("mousemove.console", this.onCaptureFocus);
					break;
				case this.POWERED_DOWN:
					this.$consoleGroup.addClass("state-powered-down");
					break;
				case this.LOADING:
					this.$consoleGroup.addClass("state-loading");
					break;

				case this.UNAVAILABLE:
					this.$consoleGroup.addClass("state-unavailable");
					break;
				case this.CANNOT_CONNECT:
					this.$consoleGroup.addClass("state-cannot-connect");
					break;
			}
			this.$("[rel=popover]").popover("hide");

			this._displayState = state;
			this.setupResizing();
			_.defer(this.onScreenStatusChanged);
		},

		setupPosition : function(normalizing){
			this.state.normalizing = !!normalizing;

			if(this.state.maximize){
				this.goMaximize();
			} else if(this.state.position === "center"){
				this.dockCenter();
			} else if(this.state.position === "float"){
				this.float();
			} else{
				this.dock(this.state.position);
			}
			this.state.normalizing = false;
		},
		dockCenter : function(event){
			this.state.position = "center";

			this.setDockCenterSize(true);
			this.$(".controls [rel=tooltip]").tooltip({placement : "left"});
			this.$consoleGroup.draggable({ disabled : true });
			this.$consoleGroup.off("dragstop.console drag.console");

			this.updatePositioning(false);
			this.setupResizing();
			this.$("[rel=tooltip]").tooltip("hide");
			this.$("[rel=popover]").popover("hide");
		},
		dockLeft : function(event){
			this.dock("left");
		},
		dockRight : function(event){
			this.dock("right");
		},
		dock : function(position){
			var transition = (this.state.position != "center" && !this.state.normalizing);
			this.state.position = position;

			this.setFloatSize();
			this.$consoleGroup.draggable({disabled : false, cancel : "canvas,.popover", containment : "#console_drag_containment", axis : "y"});
			this.$consoleGroup
				.off("dragstop.console drag.console")
				.on("dragstop.console drag.console", this.onConsoleDragged);

			this.$(".controls [rel=tooltip]").tooltip({placement : (this.state.position === "right" ? "right" : "left")});

			this.updatePositioning(transition);
			this.setupResizing();
			this.$("[rel=tooltip]").tooltip("hide");
			this.$("[rel=popover]").popover("hide");
		},
		float : function(event){
			var transition = (this.state.position != "center" && !this.state.normalizing);
			this.state.position = "float";
			this.$consoleGroup.draggable({disabled : false, cancel : "canvas,.popover", containment : "#console_drag_containment", axis : "false"});

			this.$consoleGroup.off("dragstop.console drag.console")
				.on("dragstop.console drag.console", this.onConsoleDragged);

			this.setFloatSize();
			this.updatePositioning(transition);
			this.setupResizing();
			this.$("[rel=tooltip]").tooltip("hide");
			this.$("[rel=popover]").popover("hide");
		},
		toggleFullscreen : function(eventOrEnabled){
			var fullscreen = !Screenfull.isFullscreen;
			if(_.isBoolean(eventOrEnabled)) {
				fullscreen = _.toBoolean(eventOrEnabled);
			} else if(eventOrEnabled && eventOrEnabled.preventDefault) {
				eventOrEnabled.preventDefault();
				eventOrEnabled.stopPropagation();
			}

			if(Screenfull.isFullscreen === fullscreen){
				return;
			}

			if(!fullscreen){
				Screenfull.exit();
				this.$(".full-screen").addClass("animated slow flash");
				this.$("#browser_fullscreen_message").hide();
				if(Browser.safari){
					this.$("#browser_fullscreen_warning")
						.stop()
						.show()
						.delay(10000)
						.fadeOut("fast");
				}

				if(App.config.maximizeOnFullscreen){
					this.goNormalScreen();
				}
			} else{
				Screenfull.request();
				this.$(".full-screen").removeClass("animated slow flash");
				if(Browser.safari){
					this.$("#browser_fullscreen_warning").hide();
				}
				if(App.config.maximizeOnFullscreen){
					this.goMaximize();
					this.$("#browser_fullscreen_message").remove();
				}
			}
		},
		maximize : function(eventOrEnabled){
			var maximize = !this.state.maximize;
			if(_.isBoolean(eventOrEnabled)) {
				maximize = _.toBoolean(eventOrEnabled);
			} else if(eventOrEnabled && eventOrEnabled.preventDefault) {
				eventOrEnabled.preventDefault();
				eventOrEnabled.stopPropagation();
			}

			if(maximize){
				this.goMaximize();
			} else{
				this.goNormalScreen();
			}

			return false;
		},
		/**
		 * Enlarge the console to really maximized
		 */
		goMaximize : function(){
			$("body").addClass("maximize");
			$("body").toggleClass("compact-maximize", App.config.maximizeCompactPanelTabs || App.config.compactPanelTabs);
			this.state.maximize = true;


			this.$(".maximize").addClass("animated slow flash");
			if(!Screenfull.isFullscreen){
				this.$("#browser_fullscreen_message")
					.stop()
					.show()
					.delay(5000)
					.fadeOut("fast", function(){
						$(this).remove();
					});
			}
			this.$console.find("canvas").focus();

			this.setupResizing();
			this.updatePositioning();
			this.resize();
			this.$("[rel=popover]").popover("hide");


		},

		/**
		 * Change the console to normal (guest OS resolution) size
		 */
		goNormalScreen : function(){
			$("body").removeClass("maximize");
			this.state.maximize = false;
			this.$(".maximize").removeClass("animated slow flash");
			this.$("#browser_fullscreen_message").hide();

			this.$console.find("canvas").focus();
			this.$("[rel=popover]").popover("hide");

			this.setupPosition(true);
		},


		setupResizing : function(){
			var self = this;

			if(this.$consoleGroup.data("ui-resizable")) {
				this.$consoleGroup.resizable("destroy");
			}

			if(this.state.maximize){
				this.$consoleGroup.removeAttr("style");
				return;
			}

			if(this._displayState !== this.POWERED_ON){
				return;
			}

			var directions;
			if(this.state.position === "float"){
				directions = "e, s, w, se, sw";
			} else if(this.state.position === "left"){
				directions = "e, s, se";
			} else if(this.state.position === "right"){
				directions = "s, w, sw";
			} else if(this.state.position === "center"){
				return;
			}


			var $consoleInner = this.$consoleWrapper;
			function resize(){
				self.$console.removeClass("transition");
				var paddingWidth  = $consoleInner.outerWidth()  - $consoleInner.width();
				var paddingHeight = $consoleInner.outerHeight() - $consoleInner.height();

				self.$console.width(self.$consoleGroup.width() - paddingWidth);
				self.$console.height(self.$consoleGroup.height() - (paddingHeight + self.$console.position().top));

				self.$el.css("min-width", self.$consoleWrapper.outerWidth() + "px");
				self.$el.css("min-height", (self.$consoleGroup.outerHeight() + parseInt(self.$consoleGroup.css("margin-top"), 10) + parseInt(self.$consoleGroup.css("margin-bottom"), 10)) + "px");

				if(self.state.position === "right"){
					self.$consoleGroup.css("left", "");
				} else if(self.state.position === "left"){
					self.$consoleGroup.css("right", "");
				}
			}
			this.$consoleGroup.resizable({
				handles : directions,
				minWidth : App.config.consoleResizeWidthMin,
				minHeight : App.config.consoleResizeHeightMin,
				containment: "#console_drag_containment",
				resize : function(){
					self.state.resizing = true;
					resize();

					self.model.set({
						floatWidth          : self.$console.width(),
						floatHeight         : self.$console.height()
					});
				},
				stop : function(){
					self.state.resizing = false;

					resize();

					self.state.top    = self.$consoleGroup.position().top;
					self.state.left   = self.$consoleGroup.position().left;
					self.state.width  = self.$consoleGroup.width();
					self.state.height = self.$consoleGroup.height();

					self.model.set({
						floatWidth    : self.$console.width(),
						floatHeight   : self.$console.height()
					});
					self.resize();
				}
			});
			//this.$consoleGroup.css("height", this.$consoleGroup.height() + "px");
			this.resize();
		},

		windowResizing : function(event){

			if(event && event.target !== window){ return; }
			if(!this.state.maximize && this.state.position !== "center"){ return; }

			this.state.resizing = true;
			this.$consoleGroup.addClass("ui-resizable-resizing");

			if(this.state.maximize){
				this.resize();
			} else if (this.state.position === "center"){
				this.setDockCenterSize(true);
			}

			this._debounceWindowResize();
		},

		windowResized : function(){
			this.state.resizing = false;
			this.$consoleGroup.removeClass("ui-resizable-resizing");

			if(this.state.maximize){
				this.resize();
			} else if (this.state.position === "center"){
				this.setDockCenterSize(true);
			}
		},
		setDockCenterSize : function(force){
			if(!this.model.get("dockedWidth") || force){

				this.model.set({
					dockedWidth  : $(window).width()  - this.centeredWidthPadding,
					dockedHeight : $(window).height() - this.centeredHeightPadding
				});
			}

			this.$console.css({
				width  : this.model.get("dockedWidth"),
				height : this.model.get("dockedHeight")
			});
			this.resize();
		},
		setFloatSize : function(force){
			if(!this.model.get("floatWidth") || force){
				//Start with window sizing
				var width  = $(window).width()  - this.floatWidthPadding;
				var height = $(window).height() - this.floatHeightPadding;

				// Subtract open panels (with some flub factor)
				width -= ($(".dock-right.expanded").length ? $(".dock-right.expanded").width() + this.floatPanelPadding : 0);
				width -= ($(".dock-left.expanded").length  ? $(".dock-left.expanded").width()  + this.floatPanelPadding : 0);
				width -= this.$(".controls").width();

				if(width > this.floatMaxWidth){
					width = this.floatMaxWidth;
				}

				this.model.set({
					floatWidth  : width,
					floatHeight : height
				});
				//this.$consoleGroup.css({height : "", width : ""});
			}
			this.$console.css({
				width  : this.model.get("floatWidth"),
				height : this.model.get("floatHeight")
			});

			this.resize();
		},

		/**
		 * Set document focus if the user mouses over the WebMKS canvas element
		 *
		 * This allows for keyboard entry when the user has their mouse over the console
		 */
		onCaptureFocus : function(event){
			if(this._canvas && event.target === this._canvas && !Browser.msie){
				var scrollTop = $(window).scrollTop();

				this._canvas.focus();
				$(window).scrollTop(scrollTop);
			}
		},

		onPanelMoved : function(event){
			if(!this.$consoleGroup){ return; }

			this.$consoleGroup.removeClass("manual-left manual-right manual-docked manual-float manual-expanded manual-closed manual-transition");
			this.$consoleGroup.toggleClass("manual-transition", !!event);
			if(this.instructionsPanel){
				this.$consoleGroup.toggleClass("manual-left", this.instructionsPanel.state.position == "left");
				this.$consoleGroup.toggleClass("manual-right", this.instructionsPanel.state.position == "right");
				this.$consoleGroup.toggleClass("manual-docked", !this.instructionsPanel.state.floating);
				this.$consoleGroup.toggleClass("manual-float", this.instructionsPanel.state.floating);
				this.$consoleGroup.toggleClass("manual-expanded", this.instructionsPanel.state.expanded);
				this.$consoleGroup.toggleClass("manual-closed", !this.instructionsPanel.state.expanded);
			}
			if(this._canvas){
				var $canvas = $(this._canvas);
				if(this.state.maximize && this.instructionsPanel && !this.instructionsPanel.state.floating && this.instructionsPanel.state.expanded){
					var cssLeft = parseInt($canvas.css("left"), 10);
					if(cssLeft < 0){  cssLeft = -cssLeft; }
					if(this.instructionsPanel.state.position == "right"){  cssLeft = -cssLeft; }

					$canvas.css("margin-left", cssLeft + "px");
				} else{
					$canvas.css("margin-left", "");
				}
			}

		},
		/**
		 * Update positioning of the console
		 */
		updatePositioning : function(transition){

			transition = !!transition;

			var self = this;

			this.stopListening(this.panelManager, "panels:dockleft");
			this.stopListening(this.panelManager, "panels:dockright");

			this.$consoleGroup.removeClass("center left right float");
			this.$consoleGroup.toggleClass(this.state.position, !this.state.maximize);

			if(this.state.maximize){

				this.$consoleGroup.removeAttr("style");
				this.$console.removeAttr("style");
				this.$el.removeAttr("style");

			} else if(this.state.position === "float"){

				//Floating
				this.$(".console-dock-right").show();
				this.$(".console-dock-left").show();
				this.$(".console-dock-center").show();
				this.$(".console-float").hide();

				if(!this.model.get("floatedPreviously")){
					this.state.left = (($(window).width() - this.model.get("floatWidth")) / 2) - this.$(".controls").width();
					this.state.top = 0;
					this.model.set("floatedPreviously", true);
				}
				this.moveConsoleGroup({
					top        : this.state.top + "px",
					left       : this.state.left + "px"
				}, transition);

				_.defer(function(){
					self.$consoleGroup.css("width", self.$consoleWrapper.outerWidth() + "px");

				});

				this.$consoleGroup.css("width", this.$consoleWrapper.outerWidth() + "px");

				this.$el.css("min-width", this.$consoleWrapper.outerWidth() + "px");
				this.$el.css("min-height", this.$consoleGroup.outerHeight() + "px");
			} else if(this.state.position === "center"){
				//Center docked
				this.$consoleGroup.removeAttr("style");
				this.$el.removeAttr("style");

				this.$(".console-dock-center").hide();
				this.$(".console-dock-left").show();
				this.$(".console-dock-right").show();
				this.$(".console-float").show();
			} else {

				if(this.instructionsPanel){
					this.instructionsPanel.$el.removeClass("resized");
				}
				//this.$consoleGroup.css("width", this.$consoleWrapper.outerWidth() + "px");
				var position = { top : "0px"};

				var $container = (this.state.position === "left" ? this.panelManager.$containerLeft : this.panelManager.$containerRight);
				var containerWidth = parseInt($container.css("width"), 10);

				if(this.state.position === "left"){

					position.right = "";
					if($container.hasClass("expanded")){
						var containerLeft  = parseInt($container.css("left"), 10);
						position.left = (containerLeft + containerWidth) + "px";
					} else{
						position.left = "0px";
					}

					this.listenTo(this.panelManager, "panels:dockleft", this.dockAttachedResized);
					this.$(".console-dock-right").show();
					this.$(".console-dock-left").hide();

				} else if(this.state.position === "right"){

					position.left = "";
					if($container.hasClass("expanded")){
						var containerRight = parseInt($container.css("right"), 10);
						position.right = (containerRight + containerWidth) + "px";
					} else{
						position.right = "0px";
					}


					this.listenTo(this.panelManager, "panels:dockright", this.dockAttachedResized);
					this.$(".console-dock-left").show();
					this.$(".console-dock-right").hide();
				}
				this.moveConsoleGroup(position, transition);
				this.$(".console-float").show();
				this.$(".console-dock-center").show();

				this.$el.css("min-width", this.$consoleWrapper.outerWidth() + "px");
				this.$el.css("min-height", (this.$consoleGroup.outerHeight() + parseInt(this.$consoleGroup.css("margin-top"), 10) + parseInt(this.$consoleGroup.css("margin-bottom"), 10)) + "px");
			}
			this.onPanelMoved();
		},
		dockAttachedResized : function(info){

			var $dockContainer = (this.state.position === "left" ? this.panelManager.$containerLeft : this.panelManager.$containerRight);
			if(!info){
				info = {
					left      : parseInt($dockContainer.css("left"), 10),
					right     : parseInt($dockContainer.css("right"), 10),
					width     : parseInt($dockContainer.css("width"), 10),
					expanded  : $dockContainer.hasClass("expanded"),
					immediate : false
				};
			}

			if(this.state.position === "left"){
				this.moveConsoleGroup({
					right      : "",
					left       : (info.expanded ? (info.left + info.width) : 0) + "px"
				}, !info.immediate);
			} else if(this.state.position === "right"){
				this.moveConsoleGroup({
					left       : "",
					right      : (info.expanded ? (info.right + info.width) : 0) + "px"
				}, !info.immediate);
			}

			if(this.instructionsPanel && this.state.position !== this.instructionsPanel.state.position){
				this.resizeInstructionsPanel();
			}

		},
		moveConsoleGroup : function(position, transition){
			var self = this;

			var $container = (this.state.position === "left" ? this.panelManager.$containerLeft : this.panelManager.$containerRight);
			if(transition && $.support.transition){
				this.$consoleGroup.removeClass("transition");
				var positionBefore = {};
				var positionTransition = {};
				var positionAfter = {};

				if(position.hasOwnProperty("right") && position.right !== ""){
					positionBefore.right      = "";
					positionBefore.left       = this.$consoleGroup.position().left + "px";
					positionBefore.margin     = this.$consoleGroup.css("margin");
					this.$consoleGroup.css({
						left  : "",
						right : position.right
					});
					this.$consoleGroup.toggleClass("with-panels", $container.children().length !== 0);

					positionTransition.margin = this.$consoleGroup.css("margin");
					positionTransition.left   = this.$consoleGroup.position().left + "px";
					positionAfter.left        = "";
					positionAfter.right       = position.right;
					positionAfter.margin      = "";
				} else if(position.hasOwnProperty("left") && position.left !== ""){
					positionBefore.right      = "";
					positionBefore.left       = this.$consoleGroup.position().left + "px";
					positionBefore.margin     = this.$consoleGroup.css("margin");
					this.$consoleGroup.toggleClass("with-panels", $container.children().length !== 0);
					positionTransition.margin = this.$consoleGroup.css("margin");
					positionTransition.left   = position.left;
					positionAfter.left        = position.left;
					positionAfter.margin      = "";
				}
				if(position.hasOwnProperty("top") && position.top !== ""){
					positionBefore.top        = this.$consoleGroup.position().top + "px";
					positionTransition.top    = position.top;
					positionAfter.top         = position.top;
					positionAfter.margin      = "";
				}

				this._targetPosition = positionTransition;
				this.$consoleGroup.css(positionBefore);
				this.$consoleGroup.css(["left", "right", "top", "margin"]); //needed to "set" the values in for the transition

				this.$consoleGroup.addClass("transition");
				this.$consoleGroup.css(positionTransition);

				this.$consoleGroup.off($.support.transition.end).on($.support.transition.end, function(event){
					if(event.target === self.$consoleGroup.get(0)){
						self.$consoleGroup.off($.support.transition.end);
						self.$consoleGroup.removeClass("transition");
						self.$consoleGroup.css(positionAfter);
						self._targetPosition = null;
					}
				});

			} else{
				this.$consoleGroup.toggleClass("with-panels", $container.children().length !== 0);
				this.$consoleGroup.css(position);
			}
		},
		resizeInstructionsPanel : function(){


			if(!this.instructionsPanel || this.instructionsPanel.state.floating) { return; }
			if(this.state.position === "center" || this.state.position === "float" || this.state.maximize) { return; }

			var width = 0;
			var controlsWidth = this.$(".controls").width();

			var groupLeft = (this._targetPosition ? parseInt(this._targetPosition.left, 10) :  this.$consoleGroup.position().left);
			var groupWidth = this.$consoleGroup.outerWidth();

			if(this.state.position === "left" && this.instructionsPanel.state.position === "right"){
				width = $(window).width() - (groupLeft + groupWidth + controlsWidth + this.panelPadding + parseInt(this.$consoleGroup.css("margin-left"), 10));

				if(width < this.instructionsPanel.minWidth){
					width = this.instructionsPanel.minWidth;
				}
			} else if(this.state.position === "right" && this.instructionsPanel.state.position === "left"){
				width = groupLeft - (controlsWidth + this.panelPadding);

				if(width < this.instructionsPanel.minWidth){
					width = this.instructionsPanel.minWidth;
				}
			} else if(this.state.position === "right" && this.instructionsPanel.state.position === "right"){
				width = ($(window).width() - (groupLeft + groupWidth)) - this.panelPadding;
				if(width >= this.instructionsPanel.$el.outerWidth()){
					return;
				}
			} else if(this.state.position === "left" && this.instructionsPanel.state.position === "left"){
				width = groupLeft - this.panelPadding;
				if(width >= this.instructionsPanel.$el.outerWidth()){
					return;
				}
			}

			if(this.instructionsPanel.state.docked.width === width || width <= 0) {
				return;
			}

			this.instructionsPanel.setWidth(width, { trigger : true });
		},
		onConsoleDragged : function(){
			this.$consoleGroup.removeClass("transition");
			this.state.top    = this.$consoleGroup.position().top;
			this.state.left   = this.$consoleGroup.position().left;
			this.state.width  = this.$consoleGroup.width();
			this.state.height = this.$consoleGroup.height();
		},
		clean : function(){
			//do nothing
		},

		resize : function(){
			if(!this.$console || this.$console.width() <= 0 || this.$console.height() <= 0){
				return;
			}

			this.onPanelMoved();
		},
		showNotes : function(){
			this.$(".note").popover("show");
			this.model.set("notesShown", true);
		},

		/**
		 * Class related Methods
		 */
		onScreenStatusChanged : function(){
			if(this.$consoleGroup){
				if(this.entitlement.get("screenStatus") === "blanked"){
					this.$consoleGroup.addClass("state-screen-blanked");
				} else if(this.entitlement.get("instructorViewing") && !this.entitlement.get("takenOver")){
					this.$consoleGroup.addClass("state-screen-instructor-viewing");
					this.$consoleGroup.removeClass("state-screen-taken-over");
				} else if(this.entitlement.get("takenOver")){
					this.$consoleGroup.addClass("state-screen-taken-over");
					this.$consoleGroup.removeClass("state-screen-instructor-viewing");
				} else if (this.entitlement.get("supportViewing")) {
					this.$consoleGroup.addClass("state-screen-support-viewing");
				} else {
					this.$consoleGroup.removeClass("state-screen-taken-over state-screen-blanked state-screen-instructor-viewing state-screen-support-viewing");
				}
			}
		},
		/**
		 * Class related Methods
		 */
		pause : function(){
			this._paused = true;
		},
		resume : function(){
			if(this._paused){
				this._paused = false;
				this.refreshConsole();
			}
		}
	});

});

