define('views/common/byod-chooser',['require','jquery','underscore','library/vlp/app','library/vlp/view','hbs!tpls/common/byod-chooser.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore");

	//class dependencies
	var App      = require("library/vlp/app"),
	    BaseView = require("library/vlp/view"),
	    tpl      = require("hbs!tpls/common/byod-chooser.handlebars");

	return BaseView.extend({
		template : tpl,
	//Required
		el : "#byod_sections",
		events : {
			"click .section" : "onSectionClicked"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){

			_.bindAll(this);
			options = options || {};
			this.sections = [];

			var activeSection = App.session.get("byodSection");

			_.each(options.sections, function(section){
				this.sections.push({
					section : section,
					active  : (activeSection === section)
				});
			}, this);

		},
		/**
		 * Display the content.
		 */
		render : function(options){

			var activeSection = App.session.get("byodSection");
			_.each(this.sections, function(section){
				section.active = (activeSection === section);
			});

			this.$el.html(this.template(this.sections));
			if(options && options.force){
				$("#modal_byod_chooser .close").hide();
			}

		},
		onSectionClicked : function(event){
			var self = this;
			var section = $(event.currentTarget).data("section");
			this.$(".sections").hide();
			this.$(".loading").show();
			this.$(".message").hide();
			this.$(".server-message").hide();

			App.station.registerSection(section, {
				success : function(model, response){
					if(response && response.data){
						if(response.data.errorCode === 5305 || (response.data.section && response.data.section != section)){
							alert(_.escapeHTML(response.data.message));
						}
						if(response.data.section){
							section = response.data.section;
						}
					}

					self.$(".loading").hide();
					self.$(".sections").show();
					self.trigger("sectionChosen", section);
					App.analytics.trackEvent("Station", "Byod: Registered", section);
				},
				error: function(model, response){
					self.$(".loading").hide();
					self.$(".sections").show();
					var errorMessage;
					if(response && response.data){
						if(response.data.errorCode == "5304"){
							errorMessage = App.i18n("byod.sectionFull");
						} else if (response.data.errorCode == "5305"){
							errorMessage = App.i18n("byod.wrongSection");
						}
					}
					if(errorMessage){
						self.$(".message").text(errorMessage).show();
					} else if(response && response.data && response.data.message){
						self.$(".server-message").text(_.escapeHTML(response.data.message)).show();
					} else{
						self.$(".message").text( App.i18n("error")).show();
					}
				}
			});

		}
	});

});

