/**
 * TenantStyler main Backbone View
 */
define('views/tenant-styler/main',['require','jquery','underscore','library/vlp/app','views/vlp-base','hbs!tpls/tenant-styler/main.handlebars','views/common/layout-tpls','i18n!nls/tenant-styler','library/diff','library/cssbeautify'],function (require) {
	"use strict";
	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore");

	var App            = require("library/vlp/app"),
	    VlpBaseView    = require("views/vlp-base"),
	    mainTPL        = require("hbs!tpls/tenant-styler/main.handlebars"),
	    LayoutTPLs     = require("views/common/layout-tpls"),
	    pageTexts      = require("i18n!nls/tenant-styler"),
	    JsDiff         = require("library/diff"),
	    cssbeautify    = require("library/cssbeautify");

	return VlpBaseView.extend({
		name : "TENANT_STYLER",
		viewport : "width=1300",

		checkBrowserSupported : false,
		checkLoggedIn         : false,
		checkMaintenance      : false,
		checkEula             : false,
		checkTenantPath       : false,
		checkSystem           : false,

		layoutTemplate : LayoutTPLs.layoutEmpty,
		//Required
		mainTemplate   : mainTPL,
		events : {

		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			var self = this;

			options = options || {};

			_.bindAll(this);

			App.locale.mergeI18n(pageTexts);

			this.once(VlpBaseView.STARTUP_COMPLETE,	function(){

				$("body")
					.addClass("catalogs logged-in main-content")
					.removeClass("logged-out");


				if(window.less){
					window.less.postProcessor = this.captureCss;
					window.less.modifyVars({});
				}
				self.sendMessage({
					operation : "userUiReady"
				});
			}, this);

			//Checks logged in
			this.setup(options);

			$(window).off("message.tenantStyler").on("message.tenantStyler", function(event){
				try{
					var data = _.isString(event.originalEvent.data) ? JSON.parse(event.originalEvent.data) : event.originalEvent.data;
					self.receivedMessage(data);
				} catch(e){}
			});
			this.$cssOverrides = $("<style id=\"css_overrides\"/>");
		},
		/**
		 * Display the content.
		 */
		render : function(){

			//Always return this for chaining
			return this;
		},

		receivedMessage : function(data){
			if(data.operation){
				switch (data.operation){
					case "update": this.update(data.values); break;
					case "save" :  this.save(data.values);   break;
				}
			}
		},
		sendMessage : function(data){

			if(window.parent){
				window.parent.postMessage(data, "*");
			}
		},
		recompileLess : function(values) {

			values = values || {};

			var modifiedVars = {};
			var varsMap = {
				bodyBackgroundColor  : "tenant-body-background",
				topHeaderColor       : "tenant-top-header-background",
				topHeaderAccentColor : "tenant-top-header-accent-background",
				sideNavColor         : "tenant-side-nav-background",
				sectionHeaderColor   : "tenant-section-header-bar-background",
				primaryButtonColor   : "tenant-primary-button-background",
				catalogMonitorColor  : "tenant-monitor-background",
				headerLogo           : "logo-header",
				logoWidth            : "tenant-logo-width",
				logoHeight           : "tenant-logo-height"
			};
			_.each(varsMap, function(lessVar, inVar){
				var value = values[inVar];
				if (value) {
					if(_.isString(value) && value.match("^data:")){
						value = "\"" + value + "\"";
					}
					modifiedVars["@" + lessVar] = value;
				}
			});

			if(window.less && _.keys(modifiedVars).length){
				window.less.modifyVars( modifiedVars );
			}
			this.$cssOverrides.html(values.userUiCssOverrides);
			if(values.userUiCssOverrides !== ""){
				$("head").append(this.$cssOverrides);
			} else{
				this.$cssOverrides.remove();
			}
		},
		update : function(values){
			if(values.tenantName){
				$(".tenant-name").text(values.tenantName);
			}
			this.recompileLess(values);
			this.sendMessage({
				operation : "userUiStylesUpdated"
			});
		},
		save : function(values) {
			this.recompileLess(values);

			var newCss = this.compress();

			this.sendMessage({
				operation : "userUiSaveComplete",
				css       : newCss
			});
		},
		compress : function(){
			var self = this;
			self.data = self.data || {};


			var beautifyOptions = {
				indent: "",
				autosemicolon: true
			};


			if(!this.originalBeautifyCSS){
				this.originalBeautifyCSS = cssbeautify(this.originalCSS, beautifyOptions);
			}

			var newBeautifyCSS = cssbeautify(this.currentCss + this.$cssOverrides.html(), beautifyOptions);
			if(this.originalBeautifyCSS === newBeautifyCSS) { return ""; }

			var diff = JsDiff.diffLines(this.originalBeautifyCSS, newBeautifyCSS);
			var result = "";

			var host = window.self.location.protocol + "//" + window.self.location.host + "/";
			_.each(diff, function(info, index){
				if(info.added){
					var before = "";
					var i, line;
					if(info.value.indexOf("{") === -1) {
						for (i = index - 1; i >= 0; i--) {
							line = diff[i].value;
							var lastOpenBracketIndex = line.lastIndexOf("{");
							if (lastOpenBracketIndex !== -1) {
								var lastCloseBracketIndex = line.lastIndexOf("}");
								if (lastCloseBracketIndex === -1) {
									before = line.substring(0, lastOpenBracketIndex) + before;
								} else {
									before = line.substring(lastCloseBracketIndex + 1, lastOpenBracketIndex + 1) + before;
									break;
								}
							}
						}
					}
					var tResult = before + info.value;

					tResult = tResult.replace(/\n|\r/g, "");

					if(info.value.indexOf("}") === -1) {
						tResult+= "}\n";
					}

					tResult = tResult.replace(/url\s*\(\s*(['"])?\//g, "url($1" + host);
					result+= tResult;
				}
			});
			if(result !== "" && result.match(/\{/g) && result.match(/\}/g)) {
				result = result + _.repeat("}", result.match(/\{/g).length - result.match(/\}/g).length);
			}
			return _.trim(result);
		},
		captureCss : function(styles){
			//Strip out media tags
			var tempStyles = styles.replace(/@media[^{]+\{[\s\S]*?\}\s*\}/gi, "");

			if(!this.originalCSS){
				this.originalCSS = tempStyles;
			}
			this.currentCss = tempStyles;
			return styles;
		}
	});

});

