define('views/catalogs/catalog-menu',['require','underscore','backbone','library/vlp/view','hbs!tpls/catalogs/catalog-menu.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var BaseView              = require("library/vlp/view"),
	    template              = require("hbs!tpls/catalogs/catalog-menu.handlebars");


	return BaseView.extend({
		template : template,
		activeId : null,
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			options = options || {};

			this.$allNewCount = options.$allNewCount || this.$(".all-labs-new-count");
			this.children = [];
			this.pageNum = 1;

			this.listenTo(this.collection, "request sync error", this.render);

		},
		serialize : function(options){
			var data = this.collection.toHandlebars();
			return data;
		},
		afterRender : function(){
			var allLabsNewCount = this.collection.reduce(function(memo, catalog){return memo + catalog.get("newLabCount");}, 0);
			this.$allNewCount.html(allLabsNewCount).toggle(allLabsNewCount > 0);
			if(this.activeId){
				this.$("li a[data-tab=\"catalog-" + this.activeId + "\"]").parent().addClass("active");
			}
		},

		setActive : function(activeId) {
			if (activeId instanceof Backbone.Model) {
				activeId = activeId.get("id");
			}
			this.activeId = activeId;
			this.render();
		}
	});

});
