/*globals requirejs,dateFormat */
define('utilities/locale',['require','jquery','underscore','library/vlp/base-class','library/utilities/i18n-get','library/underscore/extend-recursive'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var BaseClass = require("library/vlp/base-class"),
	    i18nGet   = require("library/utilities/i18n-get");

	require("library/underscore/extend-recursive");

	return BaseClass.extend({
		_locale       : null,
		i18n          : i18nGet,
		i18nStrings   : {},
		i18nTest      : false,

		storage : null,
		localeFullMap : {},

		initialize : function(storage, localeFullMap){
			_.bindAll(this);
			this.storage = storage;
			this.localeFullMap = localeFullMap;
		},
		set : function(value, source){
			var locale = this.get();

			this.storage.add("locale", value);

			if(value === "default"){
				value = (navigator.language || navigator.userLanguage).toLowerCase();
			}
			$("body")
				.removeClass("lang-" + locale)
				.addClass("lang-" + value);

			requirejs.config({locale : value});
			var changed = false;
			if(!locale){
				changed = true;
			} else if (locale !== value && (locale !== "en" || value !== "en-us")){
				changed = true;
			}

			if(changed){
				this.trigger("changed", value);
				this.reload();
			}
			this._locale = value;
		},
		get : function(){
			if(!this._locale){
				this._locale = this.storage.get("locale") || "root";
				try{
					this._locale = requirejs.s.contexts._.config.locale;
				} catch(e){ }

				if(!this._locale){
					this._locale = (navigator.language || navigator.userLanguage).toLowerCase();
				}
				if(!this._locale || this._locale === "root"){
					this._locale = "en";
				}
			}
			return this._locale;
		},
		getFull : function(){
			var locale = this.get();

			var fullLocale = this.localeFullMap[locale] ? this.localeFullMap[locale] : locale;
			if(fullLocale.indexOf("-") !== -1){
				var fullLocaleParts = fullLocale.split("-");
				fullLocaleParts[1] = fullLocaleParts[1].toUpperCase();
				fullLocale = fullLocaleParts.join("-");
			}
			return fullLocale;
		},
		reload : function(){
			var self = this;

			var localeFiles = [];
			for(var file in requirejs.s.contexts._.defined){
				if(file.match(/^i18n!/)){
					localeFiles.push(file);
					delete requirejs.s.contexts._.defined[file];
				} else if(file.match(/^nls/)){
					delete requirejs.s.contexts._.defined[file];
					delete requirejs.s.contexts._.urlFetched[requirejs.s.contexts._.nameToUrl(file)];
				}
			}

			this.trigger("loadStart");
			if(localeFiles.length){
				require(localeFiles, function(){
					for(var i = 0; i < arguments.length; i++){
						_.extendRecursive(self.i18nStrings, _.clone(arguments[i]));
					}

					self._replaceI18n();
					self.trigger("loadStop");
				});
			}
		},

		mergeI18n : function(i18n){
			_.extendRecursive(this.i18nStrings, i18n);
		},

		_replaceI18n : function(context){
			var self = this;
			//Regular i18n replacement
			$("i18n", context).each(function(){
				var $this = $(this);
				var key  = $this.data("key");
				var args = $this.data("args") || [];
				var type = $this.data("type") || null;

				if(type == "date_format"){
					if(window.dateFormat){
						var format = self.i18n.call(this, key);
						$this.html(dateFormat(args[0], format));
					}
				} else{
					$this.html(self.i18n.apply(this, [key].concat(args)));
				}
			});

			//Data property replacement
			$("[data-i18n]", context).each(function(){
				var $item = $(this);
				var data  = $item.data();
				if(data.hasOwnProperty("resetText")){
					var $resetText = $("<div/>").append(data.resetText);
					self._replaceI18n($resetText);
					data.resetText = $resetText.html();
					$item.data("resetText", data.resetText);
				}
				_.each(data, function(args, dataKey){
					if(dataKey.match(/^i18n./) && args.length > 0){
						var property = args[0];
						var i18nArgs = args.slice(1);
						var i18nValue = self.i18n.apply(this, i18nArgs);
						$item.attr(property, i18nValue);
						if(dataKey.match(/^i18nData./)){
							var dataTextKey = dataKey.charAt(8).toLowerCase() + dataKey.substr(9);
							if(data.hasOwnProperty(dataTextKey)){
								$item.data(dataTextKey, i18nValue);
							}
						}
					}
				});
			});
			$("[data-original-title]", context).not("[data-original-title=\"\"]").removeAttr("data-original-title").tooltip("fixTitle");
		}
	});

});

