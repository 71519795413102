
define('hbs!tpls/layout/global-modals.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "\n\n<div id=\"modal_logout\" class=\"modal fluid fade global hide\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-sign-out\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"logoutPrompt.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<p> "
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"logoutPrompt.message",{"name":"$","hash":{},"data":data}))
    + " </p>\n				<button type=\"button\" class=\"btn\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<button type=\"button\" id=\"logout\" class=\"btn btn-primary\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"logoutPrompt.confirm",{"name":"$","hash":{},"data":data}))
    + "</button>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_support_options\" class=\"modal fluid fade global hide\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-question-circle\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"supportOptions.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<div id=\"support_options\">\n					<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"supportOptions.loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_about\" class=\"modal fluid-height fade global hide\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-info\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"about.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"about.body",(depth0 != null ? depth0.copyrightYear : depth0),{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_profile\" class=\"modal fluid fade global hide scrollable\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-user\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<div id=\"user_profile\">\n					<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.title",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_tenant_chooser\" class=\"modal fluid-height fade global hide\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"tenants.title",{"name":"$","hash":{},"data":data}))
    + "</h3>\n			</div>\n			<div class=\"modal-body\">\n				<div id=\"tenants\">\n					<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_byod_chooser\" class=\"modal fluid-height fade global hide\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-building\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"byod.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<div id=\"byod_sections\">\n					<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_welcome\" class=\"modal fluid-height fade global hide\"></div>\n<div id=\"modal_language_chooser\" class=\"modal fluid-height fade global hide\"></div>\n<div id=\"modal_enroll_student\" class=\"modal fluid-height fade global hide\"></div>\n<div id=\"modal_public_profile\" class=\"modal fluid-height fade global hide scrollable\"></div>\n\n\n<div id=\"modal_system_message\" class=\"modal fade global hide fluid-height\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-envelope-o\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"systemMessage.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div id=\"system_messages\"></div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_help_center\" class=\"modal fluid-height fluid-width fade global hide\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-question-circle\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"helpCenter.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<div id=\"help_center\">\n					<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true});
Handlebars.registerPartial('layout_global_modals', t);
return t;
});

