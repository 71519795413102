/**
 * Maintenance Router
 *
 * Code starts running here for the /maintenance page.
 */
define('routers/maintenance',['require','library/vlp/router','views/maintenance/main'],function (require) {
	"use strict";

	//class dependencies
	var BaseRouter = require("library/vlp/router"),
	    MainView   = require("views/maintenance/main");


	return BaseRouter.extend({
		routeBase : "maintenance",
		/**
		 * Constructor
		 *
		 * This code is called when new Router([options]) is called in the example.html.
		 *
		 * @param options Map of options (mostly passed on to new MainView([options]);
		 */
		initialize : function(config){

			this.setup(config);

			this.mainView = new MainView(config);

			this.start();
		},
		/**
		 * List of possible routes
		 */
		routes : {
			":page"    : "index",
			"*actions" : "index"
		},
		index : function(page){
		}
	});
});

