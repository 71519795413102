
define('hbs!tpls/console/expired.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "					<div class=\"rate\">\n						<label>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labCompleted.pleaseRate",{"name":"$","hash":{},"data":data}))
    + ":</label>\n\n						<div class=\"rating\"></div>\n					</div>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<div class=\"survey hide\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.surveyImage : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "				</div>\n";
},"4":function(depth0,helpers,partials,data) {
    var helper;

  return "						<button type=\"button\" class=\"btn img survey-launch\" data-toggle=\"modal\" data-target=\"#modal_survey\">\n							<img src=\""
    + this.escapeExpression(((helper = (helper = helpers.surveyImage || (depth0 != null ? depth0.surveyImage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"surveyImage","hash":{},"data":data}) : helper)))
    + "\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","labCompleted.takeSurvey",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"alt","labCompleted.takeSurvey",{"name":"$$","hash":{},"data":data}))
    + "/>\n						</button>\n";
},"6":function(depth0,helpers,partials,data) {
    return "						<button type=\"button\" class=\"btn btn-large-special img survey-launch\" data-toggle=\"modal\" data-target=\"#modal_survey\">\n							<img src=\"/images/common/survey.png\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","labCompleted.takeSurvey",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"alt","labCompleted.takeSurvey",{"name":"$$","hash":{},"data":data}))
    + "/>\n						</button>\n";
},"8":function(depth0,helpers,partials,data) {
    var helper;

  return "					<a href=\""
    + this.escapeExpression(((helper = (helper = helpers.returnUrl || (depth0 != null ? depth0.returnUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"returnUrl","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-default pull-right return-to-home\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labExpired.returnRedirect",{"name":"$","hash":{},"data":data}))
    + "\n					</a>\n";
},"10":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.externalSite : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.program(13, data, 0),"data":data})) != null ? stack1 : "");
},"11":function(depth0,helpers,partials,data) {
    return "						<button class=\"btn btn-default pull-right external-return\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labExpired.returnExternal",{"name":"$","hash":{},"data":data}))
    + "\n						</button>\n";
},"13":function(depth0,helpers,partials,data) {
    return "						<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"page":"HOME"},"data":data}))
    + "\" class=\"btn btn-default pull-right return-to-home\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labExpired.returnHome",{"name":"$","hash":{},"data":data}))
    + "\n						</a>\n";
},"15":function(depth0,helpers,partials,data) {
    return "		<div class=\"msg-beta\">\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"betaMessage","http://www.vmwarelearningplatform.com/about",{"name":"$","hash":{},"data":data}))
    + "\n		</div>\n";
},"17":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "						<div class=\"survey-promo-text\">"
    + ((stack1 = ((helper = (helper = helpers.surveyPromoText || (depth0 != null ? depth0.surveyPromoText : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"surveyPromoText","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n";
},"19":function(depth0,helpers,partials,data) {
    var stack1;

  return "						<div class=\"survey-image\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.surveyImage : depth0),{"name":"if","hash":{},"fn":this.program(20, data, 0),"inverse":this.program(22, data, 0),"data":data})) != null ? stack1 : "")
    + "						</div>\n";
},"20":function(depth0,helpers,partials,data) {
    var helper;

  return "								<button type=\"button\" class=\"btn btn-no-style img survey-start\">\n									<img src=\""
    + this.escapeExpression(((helper = (helper = helpers.surveyImage || (depth0 != null ? depth0.surveyImage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"surveyImage","hash":{},"data":data}) : helper)))
    + "\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","labCompleted.takeSurvey",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"alt","labCompleted.takeSurvey",{"name":"$$","hash":{},"data":data}))
    + "/>\n								</button>\n";
},"22":function(depth0,helpers,partials,data) {
    return "								<button type=\"button\" class=\"btn btn-large img survey-start\">\n									<img src=\"/images/common/survey.png\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","labCompleted.takeSurvey",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"alt","labCompleted.takeSurvey",{"name":"$$","hash":{},"data":data}))
    + "/>\n								</button>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"lab-complete\">\n	<div class=\"top expired\">\n		<div class=\"interior\">\n\n			<h1><span class=\"fa fa-fw fa-lg fa-clock-o\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labExpired.title",{"name":"$","hash":{},"data":data}))
    + "</h1>\n\n			<div id=\"entitlement\">\n				<h2 class=\"message\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labExpired.message",{"name":"$","hash":{},"data":data}))
    + "\n				</h2>\n\n				<div class=\"time-remaining-container\">\n					<h3>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.timeRemaining",{"name":"$","hash":{},"data":data}))
    + "</h3>\n					<span class=\"time-remaining\">"
    + this.escapeExpression(((helper = (helper = helpers.labTimeRemainingDisplay || (depth0 != null ? depth0.labTimeRemainingDisplay : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"labTimeRemainingDisplay","hash":{},"data":data}) : helper)))
    + "</span>\n				</div>\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.ratingEnabled : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "			</div>\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.surveyUrl : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "			<div class=\"clearfix\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.returnUrl : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "			</div>\n		</div>\n	</div>\n\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"showVlpInBetaMsg",{"name":"config","hash":{},"fn":this.program(15, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n	<div class=\"bottom\">\n\n		<div class=\"left announcement-container\">\n			<div class=\"header-bar inverted\">\n				<h3>\n					<span class=\"fa fa-bullhorn skin-icon\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"announcements.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n\n			<div id=\"announcements\">\n				<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"announcements.loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n			</div>\n		</div>\n\n		<div class=\"right offers-container\">\n			<div class=\"header-bar inverted\">\n				<h3>\n					<span class=\"fa fa-comments skin-icon\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"offers.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n\n			<div id=\"offers\">\n				<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"offers.loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n			</div>\n		</div>\n	</div>\n\n</div>\n\n\n\n\n<div id=\"modal_survey\" class=\"modal fade global hide\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labCompleted.takeSurvey",{"name":"$","hash":{},"data":data}))
    + "</h3>\n\n			</div>\n			<div class=\"modal-body\">\n				<div class=\"survey-promo hide\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.surveyPromoText : depth0),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.program(19, data, 0),"data":data})) != null ? stack1 : "")
    + "					<div class=\"survey-image\">\n						<button type=\"button\" class=\"btn btn-large survey-start\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labCompleted.takeSurvey",{"name":"$","hash":{},"data":data}))
    + "\n						</button>\n					</div>\n\n				</div>\n\n				<div class=\"survey-frame\">\n					<iframe></iframe>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('console_expired', t);
return t;
});

