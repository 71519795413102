
define('hbs!tpls/common/byod-unavailable-modal.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    return "<div id=\"modal_byod_unavailable\" class=\"modal fluid-height fade global hide\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<h3 class=\"modal-title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"byod.fullTitle",{"name":"$","hash":{"tenant":(depth0 != null ? depth0.tenant : depth0)},"data":data}))
    + "</h3>\n			</div>\n			<div class=\"modal-body\">\n				<div class=\"alert alert-info alert-large alert-icon\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"byod.fullMessage",{"name":"$","hash":{},"data":data}))
    + "</div>\n			</div>\n			<div class=\"modal-footer\">\n				<button class=\"btn btn-large reload-page\" type=\"button\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"tryAgain",{"name":"$","hash":{},"data":data}))
    + "</button>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('common_byod_unavailable_modal', t);
return t;
});

