define('utilities/analytics',['require','underscore','library/vlp/base-class','utilities/analytics/ga','utilities/analytics/omniture','utilities/analytics/tealium','utilities/analytics/trackingCode'],function (require) {
	"use strict";

	var _ = require("underscore");

	var BaseClass       = require("library/vlp/base-class");

	var serviceClasses = {
		"google-analytics" : require("utilities/analytics/ga"),
		"omniture"         : require("utilities/analytics/omniture"),
		"tealium"          : require("utilities/analytics/tealium"),
		"trackingCode"     : require("utilities/analytics/trackingCode")
	};

	return BaseClass.extend({
		enable : true,

		advertisingCallbacks : [],
		functionalCallbacks : [],
		performanceCallbacks : [],
		socialMediaCallbacks : [],
		hasTealium : false,

		services : [],
		initializeFields : {},
		initialize : function(fields){
			this.initializeFields = _.clone(fields);
		},
		set: function(name, value){
			this.initializeFields[name] = value;
			for(var i = 0; i < this.services.length; i++){
				this.services[i].set(name, value);
			}
		},
		addService : function(info){
			if(!info){ return; }

			var services = !_.isArray(info) ? [info] : info;

			for(var i = 0; i < services.length; i++){
				var serviceInfo =  services[i];
				if(serviceClasses[serviceInfo.type]){
					if(serviceInfo.type === "tealium"){
						this.hasTealium = true;
					}

					serviceInfo.fields = _.clone(this.initializeFields);

					var ServiceClass = serviceClasses[serviceInfo.type];
					var service = new ServiceClass(serviceInfo);
					this.services.push(service);

					this.advertisingCallbacks.push(service.advertisingCookiesAllowedCallback);
					this.functionalCallbacks.push(service.functionalCookiesAllowedCallback);
					this.performanceCallbacks.push(service.performanceCookiesAllowedCallback);
					this.socialMediaCallbacks.push(service.socialMediaCookiesAllowedCallback);
				}
			}
		},
		trackEvent : function(category, action, label, value, nonInteraction){
			if(!this.enable) { return; }

			for(var i = 0; i < this.services.length; i++){
				this.services[i].trackEvent(category, action, label, value, nonInteraction);
			}
		},
		timing : function(category, variable, value, label){
			if(!this.enable) { return; }

			for(var i = 0; i < this.services.length; i++){
				this.services[i].timing(category, variable, value, label);
			}
		},
		allCookiesAllowedCallback : function(){
			this.performanceCookiesAllowedCallback();
			this.functionalCookiesAllowedCallback();
			this.advertisingCookiesAllowedCallback();
			this.socialMediaCookiesAllowedCallback();
		},
		advertisingCookiesAllowedCallback : function(){
			for(var i = 0; i < this.advertisingCallbacks.length; i++){
				this.advertisingCallbacks[i]();
			}
			this.advertisingCallbacks = [];
		},
		functionalCookiesAllowedCallback : function(){
			for(var i = 0; i < this.functionalCallbacks.length; i++){
				this.functionalCallbacks[i]();
			}
			this.functionalCallbacks = [];
		},
		performanceCookiesAllowedCallback : function(){
			for(var i = 0; i < this.performanceCallbacks.length; i++){
				this.performanceCallbacks[i]();
			}
			this.performanceCallbacks = [];
		},
		socialMediaCookiesAllowedCallback : function(){
			for(var i = 0; i < this.socialMediaCallbacks.length; i++){
				this.socialMediaCallbacks[i]();
			}
			this.socialMediaCallbacks = [];
		}
	});

});

