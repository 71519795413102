define('nls/root/support',{
	"support": {
		"console":{
			"building": "Starting student's console. This may take a few minutes."
		},
		"entitlements": {
			"empty"           : "There are no entitlements to show.",
			"allFetched"      : "All entitlements displayed.",
			"openConsole"     : "Open Console",
			"refresh"         : "Refresh",
			"search"          : "Search Name, vApp",
			"showThumbnail"   : "Thumbnail",
			"thumbnailTooltip": "Toggle Thumbnail",
			"phase" : {
				"notStarted" : "Not Started",
				"starting"   : "Starting",
				"running"    : "Running",
				"gone"       : "Gone",
				"exit"       : "Exited",
				"end"        : "Ended",
				"grade"      : "Grade",
				"logout"     : "Logged Out",
				"reset"      : "Resetting"
			}
		},
		"class"       : {
			"loading"         : "Loading...",
			"blankAllScreens" : "Blank All Screens",
			"enableAllScreens": "Enable All Screens",
			"warning"         : {
				"label"  : "Warn me when class is",
				"percent": {
					"label"  : "% Complete",
					"title"  : "Completion Progress",
					"message": "Class reached progress %s%%"
				},
				"step"   : {
					"label"  : "Step Number",
					"title"  : "Step Progress",
					"message": "Class reached step %s"
				}
			},
			"classInfo" :{
				"notes"    : "Notes",
				"seats"    : "Seats",
				"startDate": "Start Date",
				"endDate"  : "End Date",
				"empty"    : "(empty)"
			}
		},
		"entitlement": {
			"launching"          : "Launching Student's Console",
			"backToClass"        : "Back to Class",
			"takeOver"           : "Take Over",
			"takeOverTip"        : "Take Over student's console",
			"broadcastDisplay"   : "Broadcast Display",
			"broadcastDisplayTip": "Broadcast this student's display to all other students",
			"stopTakeOver"       : "Release Control",
			"stopBroadcast"      : "Stop Broadcasting",
			"errors"             : {
				"noVms"         : "Entitlement has no VMs. The entitlement may not have been started or the vApp has been powered down.",
				"cannotFetchVms": "Cannot load VM info. Entitlement may not have been started or the vApp has been powered down."
			}
		},
		"statusMessages": {
			"title"     : "Entitlement Status",
			"expired"   : "The entitlement has expired.",
			"completed" : "The entitlement is completed.",
			"incomplete": "The entitlement is incomplete.",
			"gone"      : "The user is gone.",
			"suspended" : "The entitlement is suspended."
		}
	},
	"errors"    : {
		"invalidRequest": "Invalid Request"
	},
	"title"     : "Support"
});
