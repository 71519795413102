
define('hbs!tpls/console/entitlement.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "hide";
},"3":function(depth0,helpers,partials,data) {
    return "true";
},"5":function(depth0,helpers,partials,data) {
    return "false";
},"7":function(depth0,helpers,partials,data) {
    return "expanded";
},"9":function(depth0,helpers,partials,data) {
    return "collapsed";
},"11":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "		<div class=\"alert alert-block alert-error fade in\">\n			<a class=\"close\" data-dismiss=\"alert\" href=\"#\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</a>\n			"
    + ((stack1 = ((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"message","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n		</div>\n";
},"13":function(depth0,helpers,partials,data) {
    var stack1;

  return "						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.lab",{"name":"$","hash":{},"data":data}))
    + " "
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.course : depth0)) != null ? stack1.name : stack1), depth0))
    + " - "
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.lab : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n";
},"15":function(depth0,helpers,partials,data) {
    var stack1;

  return "						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.lab",{"name":"$","hash":{},"data":data}))
    + " "
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.lab : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n";
},"17":function(depth0,helpers,partials,data) {
    return "					&nbsp;\n";
},"19":function(depth0,helpers,partials,data) {
    return "					<div class=\"progress-holder\">\n						<div class=\"progress\">\n							<div class=\"bar\" style=\"width:0;\"></div>\n						</div>\n					</div>\n";
},"21":function(depth0,helpers,partials,data) {
    return "							<li>\n								<button type=\"button\" class=\"btn entitlement-exit-prompt\" data-toggle=\"modal\" data-target=\"#modal_exit\" rel=\"tooltip\" data-placement=\"bottom\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.exit.tip",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.exit.label",{"name":"$","hash":{},"data":data}))
    + "</button>\n							</li>\n";
},"23":function(depth0,helpers,partials,data) {
    var stack1;

  return "							<li>\n"
    + ((stack1 = this.invokePartial(partials.console_end_button,depth0,{"name":"console_end_button","hash":{"type":"grade"},"data":data,"indent":"\t\t\t\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "							</li>\n";
},"25":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.endAllowed : depth0),{"name":"if","hash":{},"fn":this.program(26, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"26":function(depth0,helpers,partials,data) {
    var stack1;

  return "								<li>\n"
    + ((stack1 = this.invokePartial(partials.console_end_button,depth0,{"name":"console_end_button","hash":{"type":"end"},"data":data,"indent":"\t\t\t\t\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "								</li>\n";
},"28":function(depth0,helpers,partials,data) {
    return "							<li>\n								<button type=\"button\" class=\"btn entitlement-reset-prompt\" data-toggle=\"modal\" data-target=\"#modal_reset\" rel=\"tooltip\" data-placement=\"bottom\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.reset.tip",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.reset.label",{"name":"$","hash":{},"data":data}))
    + "</button>\n							</li>\n";
},"30":function(depth0,helpers,partials,data) {
    return "						&nbsp;\n";
},"32":function(depth0,helpers,partials,data) {
    return "						<span class=\"time-remaining-wrapper\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.timeRemaining",{"name":"$","hash":{},"data":data}))
    + " <span class=\"time-remaining time-remaining-override\">00:00:00</span>\n						</span>\n";
},"34":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.hideRemainingTime : depth0),{"name":"unless","hash":{},"fn":this.program(35, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"35":function(depth0,helpers,partials,data) {
    return "<button type=\"button\" class=\"btn extend-time\" rel=\"tooltip\" data-placement=\"bottom\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","console.entitlement.extendTime.requesting",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","console.entitlement.extendTime.message",(depth0 != null ? depth0.extensionsRemaining : depth0),{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.extendTime.label",{"name":"$","hash":{},"data":data}))
    + "</button>";
},"37":function(depth0,helpers,partials,data) {
    return "			<div class=\"compact-panel-buttons\">\n				<button type=\"button\" class=\"btn-no-style toggle-panel-left\">\n					<span class=\"fa fa-angle-double-right\"></span>\n				</button>\n				<button type=\"button\" class=\"btn-no-style toggle-panel-right\">\n					<span class=\"fa fa-angle-double-left\"></span>\n				</button>\n			</div>\n";
},"39":function(depth0,helpers,partials,data) {
    return " connection-monitoring";
},"41":function(depth0,helpers,partials,data) {
    return "			<button type=\"button\"class=\"btn extend-time\" rel=\"tooltip\" data-placement=\"bottom\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","console.entitlement.extendTime.requesting",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","console.entitlement.extendTime.message",(depth0 != null ? depth0.extensionsRemaining : depth0),{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.extendTime.label",{"name":"$","hash":{},"data":data}))
    + "</button>\n";
},"43":function(depth0,helpers,partials,data) {
    return "			<button type=\"button\" class=\"btn entitlement-exit-prompt\" data-toggle=\"modal\" data-target=\"#modal_exit\" rel=\"tooltip\" data-placement=\"bottom\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.exit.tip",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.exit.label",{"name":"$","hash":{},"data":data}))
    + "</button>\n";
},"45":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = this.invokePartial(partials.console_end_button,depth0,{"name":"console_end_button","hash":{"type":"grade"},"data":data,"indent":"\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "");
},"47":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.endAllowed : depth0),{"name":"if","hash":{},"fn":this.program(48, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"48":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = this.invokePartial(partials.console_end_button,depth0,{"name":"console_end_button","hash":{"type":"end"},"data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "");
},"50":function(depth0,helpers,partials,data) {
    return "			<button type=\"button\" class=\"btn entitlement-reset-prompt\" data-toggle=\"modal\" data-target=\"#modal_reset\" rel=\"tooltip\" data-placement=\"bottom\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.reset.tip",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.reset.label",{"name":"$","hash":{},"data":data}))
    + "</button>\n";
},"52":function(depth0,helpers,partials,data) {
    var stack1;

  return "				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.startingLab",{"name":"$","hash":{},"data":data}))
    + " <br />\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.deployTime",{"name":"$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.hoursMinutesSeconds || (depth0 && depth0.hoursMinutesSeconds) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.deployTime : depth0),{"name":"hoursMinutesSeconds","hash":{},"data":data}))
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.timerWarning : depth0),{"name":"if","hash":{},"fn":this.program(53, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "				<div id=\"deploy_progress\" class=\"progress progress-info progress-striped active\">\n					<div class=\"bar\" style=\"width: 0%;\"></div>\n				</div>\n";
},"53":function(depth0,helpers,partials,data) {
    return "					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.timerWarning",{"name":"$","hash":{},"data":data}))
    + "\n";
},"55":function(depth0,helpers,partials,data) {
    return "				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.building",{"name":"$","hash":{},"data":data}))
    + "\n";
},"57":function(depth0,helpers,partials,data) {
    return "				<div id=\"provisioning\">\n					<label class=\"notifyMe\" id=\"notify-me\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"onDemandProvisioning.notifyMe",{"name":"$","hash":{},"data":data}))
    + "</label>\n					<label class=\"notifyMe switch\">\n						<input type=\"checkbox\" id=\"subscribe-for-notification\">\n						<span class=\"slider round\"></span>\n					</label>\n				</div>\n";
},"59":function(depth0,helpers,partials,data) {
    return "						"
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labEndMessage","or",(depth0 != null ? depth0.$ : depth0),"console.entitlement.end.message",{"name":"config","hash":{},"data":data}))
    + "\n";
},"61":function(depth0,helpers,partials,data) {
    return "						"
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labEndMessageNoExit","or",(depth0 != null ? depth0.$ : depth0),"console.entitlement.end.messageNoExit",{"name":"config","hash":{},"data":data}))
    + "\n";
},"63":function(depth0,helpers,partials,data) {
    return "						<div id=\"lab_completion_criteria_warning\"></div>\n";
},"65":function(depth0,helpers,partials,data) {
    return "						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.grade.message",{"name":"$","hash":{},"data":data}))
    + "\n";
},"67":function(depth0,helpers,partials,data) {
    return "						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.grade.messageNoExit",{"name":"$","hash":{},"data":data}))
    + "\n";
},"69":function(depth0,helpers,partials,data) {
    return "						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.courseInformation",{"name":"$","hash":{},"data":data}))
    + "\n";
},"71":function(depth0,helpers,partials,data) {
    return "						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.labInformation",{"name":"$","hash":{},"data":data}))
    + "\n";
},"73":function(depth0,helpers,partials,data) {
    var stack1;

  return "					<div class=\"course-information\">\n						<h2>"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.course : depth0)) != null ? stack1.name : stack1), depth0))
    + "</h2>\n						<p>"
    + ((stack1 = this.lambda(((stack1 = (depth0 != null ? depth0.course : depth0)) != null ? stack1.description : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n					</div>\n";
},"75":function(depth0,helpers,partials,data) {
    var helper;

  return "					<button class=\"btn btn-primary btn-large extend-time\"	"
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","console.entitlement.extendTime.requesting",{"name":"$$","hash":{},"data":data}))
    + " rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","console.entitlement.extendTime.message",(depth0 != null ? depth0.extensionsRemaining : depth0),{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.extendTime.labelLarge",{"name":"$","hash":{},"data":data}))
    + "</button>\n					<br /><br />\n					<h4 class=\"extension-count-container\"><span class=\"text-success\">"
    + this.escapeExpression(((helper = (helper = helpers.extensionsRemaining || (depth0 != null ? depth0.extensionsRemaining : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"extensionsRemaining","hash":{},"data":data}) : helper)))
    + "</span> extensions remaining</h4>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"lab\">\n	<div class=\"panels\">\n\n		<div class=\"dock-left\"></div>\n		<div class=\"dock-right\"></div>\n		<div class=\"floating\"></div>\n\n		<div id=\"vm_listing_panel\" data-position=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"vmListingPanelLocation",{"name":"config","hash":{},"data":data}))
    + "\" data-expanded=\"false\" class=\"panel docked left collapsed "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hideConsoleChooserPanel : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-resize-mode=\"height\" data-order=\"1\">\n\n\n			<div data-function=\"slide-or-focus\" class=\"open-close-panel\">\n				<span class=\"tab-img fa fa-desktop\"></span>\n				<div class=\"tab-caption\"><span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.consoleTitle",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n			</div>\n\n			<div class=\"panel-interior\">\n\n				<div class=\"panel-controls\">\n					<ul>\n						<li><button data-function=\"close\" class=\"btn-no-style dock-close fa fa-times\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.close",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"float\" class=\"btn-no-style toggle-dock-float fa fa-external-link\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.toggleFloat",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"dock-left\" class=\"btn-no-style dock-arrow-left fa fa-caret-left\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockLeft",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"dock-right\" class=\"btn-no-style dock-arrow-right fa fa-caret-right\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockRight",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n					</ul>\n				</div>\n\n				<div id=\"vm_listing\" class=\"content\">\n					<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.consolesLoading",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n			</div>\n		</div>\n\n		<div id=\"instructions_panel\" data-position=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"instructionsPanelLocation",{"name":"config","hash":{},"data":data}))
    + "\" data-expanded=\""
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.remoteControl : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\" data-resize-mode=\"both\" class=\"panel docked right "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.remoteControl : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hideInstructionsPanel : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-order=\"0\">\n\n			<div data-function=\"slide-or-focus\" class=\"open-close-panel\">\n				<span class=\"tab-img fa fa-book\"></span>\n				<div class=\"tab-caption\"><span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.manualTitle",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n			</div>\n\n			<div class=\"panel-interior\">\n\n				<div class=\"panel-controls\">\n					<ul>\n						<li><button data-function=\"close\" class=\"btn-no-style dock-close expanded fa fa-times\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.close",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"float\" class=\"btn-no-style toggle-dock-float fa fa-external-link\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.toggleFloat",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"dock-left\" class=\"btn-no-style dock-arrow-left fa fa-caret-left\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockLeft",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"dock-right\" class=\"btn-no-style dock-arrow-right fa fa-caret-right\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockRight",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<!--<li><a data-function=\"bottom\" class=\"dock-arrow-down btn-clear btn-clear-mini\"></a></li>-->\n					</ul>\n				</div>\n\n\n				<div id=\"instructions\" class=\"content\">\n					<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.manualLoading",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n\n			</div>\n		</div>\n\n		<div id=\"resources_panel\" data-position=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"instructionsPanelLocation",{"name":"config","hash":{},"data":data}))
    + "\" data-expanded=\""
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.remoteControl : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\" data-resize-mode=\"both\" class=\"panel docked right "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.remoteControl : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hideResourcesPanel : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-order=\"2\">\n\n			<div data-function=\"slide-or-focus\" class=\"open-close-panel\">\n				<span class=\"tab-img fa fa-files-o\"></span>\n				<div class=\"tab-caption\"><span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.resources",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n			</div>\n\n			<div class=\"panel-interior\">\n\n				<div class=\"panel-controls\">\n					<ul>\n						<li><button data-function=\"close\" class=\"btn-no-style dock-close expanded fa fa-times\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.close",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"float\" class=\"btn-no-style toggle-dock-float fa fa-external-link\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.toggleFloat",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"dock-left\" class=\"btn-no-style dock-arrow-left fa fa-caret-left\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockLeft",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"dock-right\" class=\"btn-no-style dock-arrow-right fa fa-caret-right\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockRight",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<!--<li><a data-function=\"bottom\" class=\"dock-arrow-down btn-clear btn-clear-mini\"></a></li>-->\n					</ul>\n				</div>\n\n\n				<div id=\"resources\" class=\"content\">\n					<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n\n			</div>\n		</div>\n\n		<div id=\"video_conference_panel\" data-position=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"videoConferencePanelLocation",{"name":"config","hash":{},"data":data}))
    + "\" data-expanded=\""
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.remoteControl : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\" data-resize-mode=\"both\" class=\"panel docked right "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.remoteControl : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hideVideoConferencePanel : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-order=\"3\">\n\n			<div data-function=\"slide-or-focus\" class=\"open-close-panel\">\n				<span class=\"tab-img fa fa-video-camera\"></span>\n				<div class=\"tab-caption\"><span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.videoConferenceTitle",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n			</div>\n\n			<div class=\"panel-interior\">\n\n				<div class=\"panel-controls\">\n					<ul>\n						<li><button data-function=\"close\" class=\"btn-no-style dock-close expanded fa fa-times\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.close",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"float\" class=\"btn-no-style toggle-dock-float fa fa-external-link\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.toggleFloat",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"dock-left\" class=\"btn-no-style dock-arrow-left fa fa-caret-left\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockLeft",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<li><button data-function=\"dock-right\" class=\"btn-no-style dock-arrow-right fa fa-caret-right\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockRight",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\" data-container=\"body\"></button></li>\n						<!--<li><a data-function=\"bottom\" class=\"dock-arrow-down btn-clear btn-clear-mini\"></a></li>-->\n					</ul>\n				</div>\n\n\n				<div id=\"video_conference\" class=\"content\">\n					<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.videoConferenceLoading",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n\n			</div>\n		</div>\n	</div>\n\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.message : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n	<div id=\"info_bar\" class=\"row-fluid\">\n		<div class=\"span6 lab-title-wrapper\">\n			<h3 class=\"lab-title\">\n				<button type=\"button\" class=\"detailed-information\" data-toggle=\"modal\" data-target=\"#modal_detailed_information\" rel=\"tooltip\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.detailedInformation",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\">\n					<span class=\"fa fa-info-circle\"></span>\n				</button>\n				<span rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title",((stack1 = (depth0 != null ? depth0.lab : depth0)) != null ? stack1.name : stack1),{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.course : depth0),{"name":"if","hash":{},"fn":this.program(13, data, 0),"inverse":this.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "				</span>\n			</h3>\n			<button id=\"launch_walkthrough\" type=\"button\" class=\"btn-no-style\" disabled rel=\"tooltip\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.guideTooltip",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"right\">\n				<span class=\"fa fa-fw fa-road\"></span> "
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.guide",{"name":"$","hash":{},"data":data}))
    + "\n			</button>\n		</div>\n		<div class=\"span6 lab-progress-wrapper\">\n			<div class=\"row row-progress-bar\">\n				<div class=\"span6 progress-bar-wrapper\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hideRemainingTime : depth0),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.program(19, data, 0),"data":data})) != null ? stack1 : "")
    + "				</div>\n				<div class=\"span5 progress-bar-actions\">\n					<ul>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.exitAllowed : depth0),{"name":"if","hash":{},"fn":this.program(21, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.requiresGrading : depth0),{"name":"if","hash":{},"fn":this.program(23, data, 0),"inverse":this.program(25, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.resetAllowed : depth0),{"name":"if","hash":{},"fn":this.program(28, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "					</ul>\n				</div>\n				<div class=\"span1 settings-wrapper\">\n					<button type=\"button\" class=\"btn-no-style settings\" data-toggle=\"modal\" data-target=\"#modal_settings\" rel=\"tooltip\" data-container=\"#lab\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.settings.title",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"left\"><span class=\"fa fa-cog\"></span></button>\n				</div>\n			</div>\n			<div class=\"row row-time-remaining\">\n				<div class=\"span12\">\n					<h5 class=\"text-uppercase\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hideRemainingTime : depth0),{"name":"if","hash":{},"fn":this.program(30, data, 0),"inverse":this.program(32, data, 0),"data":data})) != null ? stack1 : "")
    + "						"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.canExtend : depth0),{"name":"if","hash":{},"fn":this.program(34, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n					</h5>\n				</div>\n			</div>\n		</div>\n\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"compactPanelTabs",{"name":"config","hash":{},"fn":this.program(37, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n\n	<div id=\"info_bar_maximize\" class=\"hide"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.showConsoleConnectionStrength : depth0),{"name":"if","hash":{},"fn":this.program(39, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n		<span class=\"time-remaining-label\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.timeRemaining",{"name":"$","hash":{},"data":data}))
    + "</span> <span class=\"time-remaining\">00:00:00</span>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.canExtend : depth0),{"name":"if","hash":{},"fn":this.program(41, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.exitAllowed : depth0),{"name":"if","hash":{},"fn":this.program(43, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.requiresGrading : depth0),{"name":"if","hash":{},"fn":this.program(45, data, 0),"inverse":this.program(47, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.resetAllowed : depth0),{"name":"if","hash":{},"fn":this.program(50, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n	<div id=\"vm\">\n		<div id=\"vm_group\" class=\"temp-loading\"><div id=\"vm_above_bar\"></div></div>\n		<div class=\"loading building-lab\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.deployTime : depth0),{"name":"if","hash":{},"fn":this.program(52, data, 0),"inverse":this.program(55, data, 0),"data":data})) != null ? stack1 : "")
    + "			<div class=\"time-elapsed\"></div>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.onDemandProvisioning : depth0),{"name":"if","hash":{},"fn":this.program(57, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n	<div id=\"broadcast_vm\" style=\"display:none\">\n		<div class=\"loading\"></div>\n	</div>\n	<div id=\"saas_console\"></div>\n</div>\n\n<div id=\"modal_exit\" class=\"modal hide fade global fluid-height\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header no-close\">\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-pause\"></span>\n					"
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labExitTitle","or",(depth0 != null ? depth0.$ : depth0),"console.entitlement.exit.title",{"name":"config","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<p id=\"exit-message-power-off\">"
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labExitMessage","or",(depth0 != null ? depth0.$ : depth0),"console.entitlement.exit.message",{"name":"config","hash":{},"data":data}))
    + "</p>\n				<p id=\"exit-message-no-power-off\">"
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labExitMessageNoPoweroff","or",(depth0 != null ? depth0.$ : depth0),"console.entitlement.exit.messageNoPoweroff",{"name":"config","hash":{},"data":data}))
    + "</p>\n				<button type=\"button\" class=\"btn cancel\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<button type=\"button\" class=\"btn btn-primary entitlement-exit\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","saving",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.confirm",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<div class=\"loading exit-spinner hide\"></div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_end\" class=\"modal hide fade global fluid-height\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header no-close\">\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-check-circle-o\"></span>\n					"
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labEndTitle","or",(depth0 != null ? depth0.$ : depth0),"console.entitlement.end.title",{"name":"config","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<p>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.exitAllowed : depth0),{"name":"if","hash":{},"fn":this.program(59, data, 0),"inverse":this.program(61, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiredMinimumTimeSpent : depth0),(depth0 != null ? depth0.requiredStepsVisitedPercentage : depth0),{"name":"or","hash":{},"fn":this.program(63, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "				</p>\n				<button type=\"button\" class=\"btn cancel\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<button type=\"button\" class=\"btn btn-primary entitlement-end\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","console.entitlement.end.ending",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.confirm",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<div class=\"loading end-spinner hide\"></div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_grade\" class=\"modal hide fade global fluid-height\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header no-close\">\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-check-circle-o\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.grade.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<p>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.exitAllowed : depth0),{"name":"if","hash":{},"fn":this.program(65, data, 0),"inverse":this.program(67, data, 0),"data":data})) != null ? stack1 : "")
    + "				</p>\n				<button type=\"button\" class=\"btn cancel\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<button type=\"button\" class=\"btn btn-primary entitlement-grade\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","console.entitlement.grade.submitting",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.confirm",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<div class=\"loading grade-spinner hide\"></div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_reset\" class=\"modal hide fade global fluid-height\" data-backdrop=\"static\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header no-close\">\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-refresh\"></span>\n					"
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labResetTitle","or",(depth0 != null ? depth0.$ : depth0),"console.entitlement.reset.title",{"name":"config","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<div class=\"alert alert-danger alert-icon\">\n					<h4>"
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labResetWarning","or",(depth0 != null ? depth0.$ : depth0),"console.entitlement.reset.warning",{"name":"config","hash":{},"data":data}))
    + "</h4>\n					<p>\n						"
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labResetMessage","or",(depth0 != null ? depth0.$ : depth0),"console.entitlement.reset.message",{"name":"config","hash":{},"data":data}))
    + "\n					</p>\n				</div>\n				<p>\n					"
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labResetConfirmation","or",(depth0 != null ? depth0.$ : depth0),"console.entitlement.reset.confirmation",{"name":"config","hash":{},"data":data}))
    + "\n				</p>\n				<button type=\"button\" class=\"btn cancel\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<button type=\"button\" class=\"btn btn-danger entitlement-reset\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","console.entitlement.reset.resetting",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.confirm",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<div class=\"loading reset-spinner hide\"></div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_return\" class=\"modal hide fade global fluid-height\" data-backdrop=\"static\" data-keyboard=\"false\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header no-close\">\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-reply\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.exit.label",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<div class=\"alert alert-info\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.externalReturn",{"name":"$","hash":{},"data":data}))
    + "</div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_time_remaining_diff_refresh\" class=\"modal hide fade global fluid-height\" data-backdrop=\"static\" data-keyboard=\"false\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header no-close\">\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-clock-o\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.timeRemainingDiffRefresh.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<div class=\"alert alert-info\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.timeRemainingDiffRefresh.message",{"name":"$","hash":{},"data":data}))
    + " <br/>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.timeRemainingDiffRefresh.countdown",{"name":"$","hash":{},"data":data}))
    + " <span class=\"time-remaining-diff-countdown\"></span>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_detailed_information\" class=\"modal hide fade global fluid-height\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-info-circle\"></span>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.course : depth0),{"name":"if","hash":{},"fn":this.program(69, data, 0),"inverse":this.program(71, data, 0),"data":data})) != null ? stack1 : "")
    + "				</h3>\n			</div>\n			<div class=\"modal-body user-data\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.course : depth0),{"name":"if","hash":{},"fn":this.program(73, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "				<div class=\"lab-information\">\n					<h3>"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.lab : depth0)) != null ? stack1.name : stack1), depth0))
    + "</h3>\n					<p>"
    + ((stack1 = this.lambda(((stack1 = (depth0 != null ? depth0.lab : depth0)) != null ? stack1.description : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"idle_warning_popup\" class=\"modal hide fluid-height\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header no-close\">\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-clock-o\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.idleTitle",(depth0 != null ? depth0.userIdleDelayMinutes : depth0),{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<h3>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.idleWarning",(depth0 != null ? depth0.userIdleDelayMinutes : depth0),{"name":"$","hash":{},"data":data}))
    + " <span class=\"count-down-time danger\">00:00</span></h3>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"time_warning_popup\" class=\"modal hide fade fluid\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-clock-o\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.timeWarning.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<p class=\"time-remaining-container\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.timeRemaining",{"name":"$","hash":{},"data":data}))
    + " <span class=\"time-remaining\">00:00:00</span></p>\n				<p>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.timeWarning.message",{"name":"$","hash":{},"data":data}))
    + "</p>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.canExtend : depth0),{"name":"if","hash":{},"fn":this.program(75, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "			</div>\n			<div class=\"modal-footer\">\n				<button type=\"button\" class=\"btn btn-large btn-default\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.ok",{"name":"$","hash":{},"data":data}))
    + "</button>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_exiting\" class=\"modal hide fade global fluid-height\" data-backdrop=\"static\" data-keyboard=\"false\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header no-close\">\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-pause\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.exit.exiting",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.exit.exiting",{"name":"$","hash":{},"data":data}))
    + "</div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_split_screen\" class=\"modal hide fade fluid\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-columns\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.remoteToken.prompt.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"entitlement_settings\"></div>\n";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('console_entitlement', t);
return t;
});

