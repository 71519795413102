
define('hbs!tpls/unsupported/main.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<div class=\"sub-content\">\n				<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.mobile.title",{"name":"$","hash":{},"data":data}))
    + "</h2>\n				<h3>"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.mobile.instructions",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</h3>\n			</div>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.msie : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.chrome : depth0),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.safari : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.firefox : depth0),{"name":"if","hash":{},"fn":this.program(13, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.other : depth0),{"name":"if","hash":{},"fn":this.program(15, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,((stack1 = (depth0 != null ? depth0.version : depth0)) != null ? stack1.major : stack1),10,{"name":"eq","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"5":function(depth0,helpers,partials,data) {
    var stack1;

  return "					<div class=\"sub-content\">\n						<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.msie10.title",{"name":"$","hash":{},"data":data}))
    + "</h2>\n						<p>"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.msie10.instructions",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</p>\n					</div>\n";
},"7":function(depth0,helpers,partials,data) {
    var stack1;

  return "					<div class=\"sub-content\">\n						<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.msie.title",{"name":"$","hash":{},"data":data}))
    + "</h2>\n\n						<h3>"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.msie.instructions",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</h3>\n					</div>\n";
},"9":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<div class=\"sub-content\">\n					<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.chrome.title",{"name":"$","hash":{},"data":data}))
    + "</h2>\n\n					<h3>"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.chrome.instructions",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</h3>\n				</div>\n";
},"11":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<div class=\"sub-content\">\n					<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.safari.title",{"name":"$","hash":{},"data":data}))
    + "</h2>\n\n					<h3>"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.safari.instructions",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</h3>\n				</div>\n";
},"13":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<div class=\"sub-content\">\n					<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.firefox.title",{"name":"$","hash":{},"data":data}))
    + "</h2>\n\n					<h3>"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.firefox.instructions",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</h3>\n				</div>\n";
},"15":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<div class=\"sub-content\">\n					<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.other.title",{"name":"$","hash":{},"data":data}))
    + "</h2>\n\n					<h3>"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.other.instructions",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</h3>\n				</div>\n";
},"17":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.supportedDevices",{"name":"$","hash":{},"data":data}))
    + "</h2>\n			<ul class=\"supported-devices\">\n				<li>"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.devices.ipad",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</li>\n			</ul>\n";
},"19":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.supported",{"name":"$","hash":{},"data":data}))
    + "</h2>\n			<ul class=\"supported-browsers\">\n				<li><a href=\"http://www.mozilla.org/en-US/firefox/new/\">"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.browsers.firefox",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</a></li>\n				<li><a href=\"https://www.google.com/chrome\">"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.browsers.chrome",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</a></li>\n				<li><a href=\"http://support.apple.com/downloads/#internet\">"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.browsers.safari",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</a></li>\n				<li><a href=\"http://windows.microsoft.com/en-us/internet-explorer/download-ie\">"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.browsers.msie",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</a></li>\n				<li><a href=\"https://www.microsoft.com/en-us/windows/microsoft-edge\">"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.browsers.edge",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</a></li>\n			</ul>\n";
},"21":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<div class=\"sub-content\">\n			<h2>"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.msie10.tls12.title",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</h2>\n			<ol>\n				<li>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.msie10.tls12.step1",{"name":"$","hash":{},"data":data}))
    + "<br/>\n					<img src=\"/images/unsupported/ie10-internet-options.jpg\" alt=\"IE 10 Internet Options\" width=\"430\"/>\n				</li>\n				<li>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.msie10.tls12.step2",{"name":"$","hash":{},"data":data}))
    + "</li>\n				<li>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.msie10.tls12.step3",{"name":"$","hash":{},"data":data}))
    + "</li>\n				<li>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.msie10.tls12.step4",{"name":"$","hash":{},"data":data}))
    + "<br/>\n					<img src=\"/images/unsupported/ie10-tls12.jpg\" alt=\"IE 10 Enable TLS 1.2\" width=\"316\"/>\n				</li>\n				<li>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.msie10.tls12.step5",{"name":"$","hash":{},"data":data}))
    + "</li>\n			</ol>\n		</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"page-content\">\n\n	<div class=\"help\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.mobile : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "	</div>\n\n	<div class=\"sub-content\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.mobile : depth0),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.program(19, data, 0),"data":data})) != null ? stack1 : "")
    + "	</div>\n\n"
    + ((stack1 = (helpers.and || (depth0 && depth0.and) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.msie : depth0),(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,((stack1 = (depth0 != null ? depth0.version : depth0)) != null ? stack1.major : stack1),10,{"name":"eq","hash":{},"data":data}),{"name":"and","hash":{},"fn":this.program(21, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});
Handlebars.registerPartial('unsupported_main', t);
return t;
});

