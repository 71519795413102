/*globals unescape */
define('models/station',['require','exports','module','jquery','library/vlp/app','library/vlp/model'],function (require, exports) {
	"use strict";

	//library dependencies
	var $        = require("jquery");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model");


	//Use exports for requirejs circular dependency resolving
	var Station = exports;

	//Add Station to the App.Models namespace
	App.Models.Station = Station;


	Station.Model = BaseModel.extend({
		urlRoot : "events",
		pushService : "station",

		/**
		 * Default values for empty Models (new Station.Model())
		 */
		defaults  : {
			username  : "",
			password  : "",
			state     : "OPEN"
		},
		setters : {
			stationKey : function(value){
				if(this.has("stationId")){
					App.store.set("stationKey", value, 0);
				} else{
					App.session.set("stationKey", value, 0);
				}
				return value;
			},
			assignedName : function(value){
				if(value === null){
					App.store.remove("stationAssignedName");
				} else{
					App.store.set("stationAssignedName", value, 0);
				}
				return value;
			},
			assignedUsername : function(value){
				if(value === null){
					App.store.remove("stationAssignedUsername");
				} else{
					App.store.set("stationAssignedUsername", value, 0);
				}
				return value;
			}
		},
		/**
		 * Constructor
		 */
		initialize : function(){

		},
		setupStation : function(){
			if(!this.isRegistered()){
				return;
			}

			if(App.session.get("byodSection")){
				App.setGlobalParam("stationId", App.session.get("byodSection"));
				App.setGlobalParam("section", App.session.get("byodSection"));
				this.set("byodSection", App.session.get("byodSection"));
				this.set("stationId",   App.session.get("byodSection"));
				this.set("stationKey",  App.session.get("stationKey"));
			} else{
				App.setGlobalParam("stationId", App.store.get("stationId"));
				this.set("stationId",  App.store.get("stationId"));
				this.set("stationKey", App.store.get("stationKey"));
			}
			this.assign({
				displayName : App.store.get("stationAssignedName"),
				username    : App.store.get("stationAssignedUsername")
			});
		},
		isRegistered : function(){
			return (!!App.store.get("stationId") || !!App.session.get("byodSection")) && (String(App.store.get("stationTenant")).toLowerCase() === String(App.getTenant()).toLowerCase());
		},
		clearRegistration : function(){
			App.store.remove("stationId");
			App.store.remove("stationTenant");
			App.store.remove("stationKey");
			App.session.remove("byodSection");
			App.session.remove("stationKey");
			App.cookie.remove("nee-station");
			this.set("assignedName",     null);
			this.set("assignedUsername", null);
			this.trigger("unregistered");
		},
		unregister : function(options){
			options = options || {};
			options.method = "read";

			this.clearRegistration();

			return this.action("unregisterStation", options);
		},
		assign : function(data){
			data = data || {};

			if(!data.displayName && (data.firstName || data.lastName)){
				data.displayName = data.firstName + (data.lastName ? " " + data.lastName : "");
			}
			this.set("assignedName",     data.displayName ? data.displayName : null);
			this.set("assignedUsername", data.username    ? data.username    : null);
			if(App.user){
				App.user.set("username",  data.username ? data.username : "");
			}
		},
		unAssign : function(){
			this.set("assignedName",     null);
			this.set("assignedUsername", null);
			if(!App.user.get("loggedIn")){
				App.user.set("username",     "");
			}
		},
		ping : function(options){
			options = options || {};
			options.params = options.params || {};
			options.pushSync = true;
			options.params.returnPath = "none";
			options.params.stationId = this.get("stationId");
			if(this.has("stationKey")){
				options.params.stationKey = this.get("stationKey");
			}
			options.params.tenant = App.getTenant();

			options.properties = ["stationId"];
			return this.action("stationGone", options);
		},
		sections : function(options){
			var self = this;
			options = options || {};

			options.type = "GET";
			options.url = this.eventUrl + "byod";

			options.params = options.params || {};
			options.params.tenant = App.getTenant();
			options.url = this._makeURL(options);

			var success = options.success;
			options.success = function(resp) {
				self.set("sections", resp.data);
				if(success){
					success(self, resp);
				}
			};

			return $.ajax(options);
		},
		register : function(options){
			var self = this;
			options = options || {};

			options.type = "GET";
			options.url = this.serverBase + "events/registerStation";
			options.params = options.params || {};

			var tenant = App.getTenant();
			if(tenant){
				options.params.tenant = tenant;
			} else{
				options.params.tenant = "null";
			}

			options.params.stationId = this.get("stationId");

			if(options.force){
				options.params.force = "true";
			}
			options.url = this._makeURL(options);

			var AuthToken = unescape(encodeURIComponent(this.get("username") + ":" + this.get("password")));
			var Auth = "Basic " + btoa(AuthToken);
			options.headers = {
				Authorization : Auth
			};


			var success = options.success;
			options.success = function(resp){
				if(!self.set(self.parse(resp, options), options)) {
					return false;
				}

				App.store.set("stationId", self.get("stationId"), 0);
				App.store.set("stationTenant", tenant, 0);
				self.setupStation();
				if(success){
					success(self, resp);
				}
			};
			return $.ajax(options);
		},
		registerSection : function(section, options){
			var self = this;
			var tenant = App.getTenant();

			options = options || {};

			this.set("byodSection", section);

			options.type = "POST";
			options.url = this.eventUrl + "byod";

			options.params = options.params || {};
			options.params.section = section;
			options.params.tenant = App.getTenant();
			options.url = this._makeURL(options);

			var success = options.success;
			options.success = function(model, resp){

				if(resp.data && resp.data.section){
					section = resp.data.section;
				}
				App.session.set("byodSection", section, 0);
				App.store.set("stationTenant", tenant, 0);
				App.setGlobalParam("section", section);

				if(success){
					success(self, resp);
				}
			};

			return $.ajax(options);
		},

		helpDeskTicket : function(options){
			options = options || {};
			options.params = options.params || {};
			options.params.station = this.get("stationId");
			options.params.tenant = App.getTenant();

			if(App.entitlementId){ options.params.entitlement = App.entitlementId; }
			if(App.labSku)       { options.params.labSku      = App.labSku; }
			if(App.labName)      { options.params.labName     = App.labName; }

			options.url = this.serverBase + "helpDesk/createTicket";

			options.properties = [];
			return this.action("createIssue", options);
		}
	});

});
