
define('hbs!tpls/manual-preview/main.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    return "<div id=\"instructions_panel\" class=\"panel docked right expanded\">\n\n	<div class=\"panel-interior\">\n\n		<div id=\"instructions\" class=\"content\">\n			<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.entitlement.panels.manualLoading",{"name":"$","hash":{},"data":data}))
    + "</div>\n		</div>\n		<div id=\"fetch_error\" class=\"alert alert-error hide\">\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"manualPreview.errorFetching",{"name":"$","hash":{},"data":data}))
    + "\n		</div>\n\n	</div>\n</div>";
},"useData":true});
Handlebars.registerPartial('manual_preview_main', t);
return t;
});

