
define('hbs!tpls/common/profile/contact-info.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "autocomplete=\"on\"";
},"3":function(depth0,helpers,partials,data) {
    return "autocomplete=\"off\" action=\"https://about:blank\"";
},"5":function(depth0,helpers,partials,data) {
    return "\"email\"";
},"7":function(depth0,helpers,partials,data) {
    return "\"off\"";
},"9":function(depth0,helpers,partials,data) {
    return "\"current-password\"";
},"11":function(depth0,helpers,partials,data) {
    return "\"tel\"";
},"13":function(depth0,helpers,partials,data) {
    return "\"address-line1\"";
},"15":function(depth0,helpers,partials,data) {
    return "\"address-line2\"";
},"17":function(depth0,helpers,partials,data) {
    return "\"address-level2\"";
},"19":function(depth0,helpers,partials,data) {
    return "\"address-level1\"";
},"21":function(depth0,helpers,partials,data) {
    var helper;

  return "						<option value=\""
    + this.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"value","hash":{},"data":data}) : helper)))
    + "\">"
    + this.escapeExpression(((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"label","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"23":function(depth0,helpers,partials,data) {
    return "\"postal-code\"";
},"25":function(depth0,helpers,partials,data) {
    return "\"country\"";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<form class=\"form-horizontal\" id=\"profile_contact_info_form\" method=\"post\" "
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " data-error-style=\"tooltip\">\n	<div class=\"alert alert-info privacy-prompt alert-icon alert-small\">\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.privacyPrompt",{"name":"$","hash":{},"data":data}))
    + " <a type=\"button\" href=\"#public-profile\" class=\"preview-public-profile\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.showPublicProfile",{"name":"$","hash":{},"data":data}))
    + "</a>\n	</div>\n	<fieldset>\n		<div class=\"control-group\">\n			<label class=\"control-label required\" for=\"profile_email\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.contact.email",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls privacy\">\n				<input type=\"text\" id=\"profile_email\" maxlength=\"255\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n				<button data-property=\"email_public\" class=\"btn btn-no-style privacy\" type=\"button\" data-toggle=\"button\" data-container=\"body\" data-placement=\"top\"></button>\n			</div>\n		</div>\n		<div class=\"control-group hide confirmPassword\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-error-label","profile.password.title",{"name":"$$","hash":{},"data":data}))
    + ">\n			<label class=\"control-label required\" for=\"profile_confirmPassword\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.contact.confirmPassword",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls\">\n				<input type=\"password\" id=\"profile_emailChangePasswordConfirm\" maxlength=\"255\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(9, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n				<input type=\"text\" class=\"hide\" id=\"profile_emailChangePasswordConfirm_visible\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(9, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + " maxlength=\"255\">\n			</div>\n			<div class=\"controls\">\n				<label class=\"checkbox\">\n					<input type=\"checkbox\" id=\"profile_email_password_show\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.password.displayPassword",{"name":"$","hash":{},"data":data}))
    + "\n				</label>\n			</div>\n		</div>\n		<div class=\"control-group\">\n			<label class=\"control-label\" for=\"profile_phone\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.contact.phone",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls privacy\">\n				<input type=\"text\" id=\"profile_phone\" maxlength=\"255\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(11, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n				<button data-property=\"phone_public\" class=\"btn btn-no-style privacy\" type=\"button\" data-toggle=\"button\" data-container=\"body\" data-placement=\"top\"></button>\n			</div>\n		</div>\n		<div class=\"control-group\">\n			<label class=\"control-label\" for=\"profile_streetAddress1\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.contact.streetAddress1",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls privacy\">\n				<input type=\"text\" id=\"profile_streetAddress1\" maxlength=\"255\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(13, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n				<button data-property=\"streetAddress1_public\" class=\"btn btn-no-style privacy\" type=\"button\" data-toggle=\"button\" data-container=\"body\" data-placement=\"top\"></button>\n			</div>\n		</div>\n		<div class=\"control-group\">\n			<label class=\"control-label\" for=\"profile_streetAddress2\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.contact.streetAddress2",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls privacy\">\n				<input type=\"text\" id=\"profile_streetAddress2\" maxlength=\"255\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(15, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n				<button data-property=\"streetAddress2_public\" class=\"btn btn-no-style privacy\" type=\"button\" data-toggle=\"button\" data-container=\"body\" data-placement=\"top\"></button>\n			</div>\n		</div>\n		<div class=\"control-group\">\n			<label class=\"control-label\" for=\"profile_city\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.contact.city",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls privacy\">\n				<input type=\"text\" id=\"profile_city\" maxlength=\"255\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(17, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n				<button data-property=\"city_public\" class=\"btn btn-no-style privacy\" type=\"button\" data-toggle=\"button\" data-container=\"body\" data-placement=\"top\"></button>\n			</div>\n		</div>\n		<div class=\"control-group\">\n			<label class=\"control-label\" for=\"profile_state\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.contact.stateAndPostalCode",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls privacy\">\n\n				<select id=\"profile_state\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(19, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n					<option></option>\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.states : depth0),{"name":"each","hash":{},"fn":this.program(21, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "				</select>\n				<button data-property=\"state_public\" class=\"btn btn-no-style privacy inline\" type=\"button\" data-toggle=\"button\" data-container=\"body\" data-placement=\"top\"></button>\n				<input type=\"text\" id=\"profile_zip\" maxlength=\"255\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(23, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n				<button data-property=\"postalCode_public\" class=\"btn btn-no-style privacy\" type=\"button\" data-toggle=\"button\" data-container=\"body\" data-placement=\"top\"></button>\n			</div>\n		</div>\n\n		<div class=\"control-group\">\n			<label class=\"control-label\" for=\"profile_country\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.contact.country",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls privacy\">\n\n				<select id=\"profile_country\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(25, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n					<option></option>\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.countries : depth0),{"name":"each","hash":{},"fn":this.program(21, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "				</select>\n				<button data-property=\"country_public\" class=\"btn btn-no-style privacy\" type=\"button\" data-toggle=\"button\" data-container=\"body\" data-placement=\"top\"></button>\n			</div>\n		</div>\n	</fieldset>\n\n	<div class=\"alert alert-error save-error-general hide\"></div>\n	<button type=\"submit\" class=\"btn btn-large btn-primary pull-right save-changes\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","saving",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.saveChanges",{"name":"$","hash":{},"data":data}))
    + "</button>\n\n\n	<div class=\"spinner hide pull-right save-spinner\"/>\n	<div class=\"alert alert-success hide pull-right save-success\">\n		<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"updated",{"name":"$","hash":{},"data":data}))
    + "</strong>\n	</div>\n	<div class=\"alert alert-error hide pull-right save-error\">\n		<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"error",{"name":"$","hash":{},"data":data}))
    + "</strong>\n	</div>\n</form>";
},"useData":true});
Handlebars.registerPartial('common_profile_contact_info', t);
return t;
});

