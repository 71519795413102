define('views/catalogs/lab',['require','jquery','underscore','backbone','library/vlp/app','library/vlp/view','views/catalogs/lab-rating','views/catalogs/lab-reviews','hbs!tpls/catalogs/lab.handlebars','hbs!tpls/catalogs/lab/invite-popover.handlebars','hbs!tpls/catalogs/lab/systems.handlebars','hbs!tpls/catalogs/lab/reviews-and-ratings.handlebars','hbs!tpls/catalogs/lab/badges.handlebars','hbs!tpls/catalogs/lab/common-metadata.handlebars','hbs!tpls/catalogs/lab/common-metadata-links.handlebars','hbs!tpls/catalogs/lab/enroll-button.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseView       = require("library/vlp/view"),
	    LabRatingView  = require("views/catalogs/lab-rating"),
	    LabReviewsView = require("views/catalogs/lab-reviews"),
	    LabInfoTPL     = require("hbs!tpls/catalogs/lab.handlebars"),
	    labInviteTPL   = require("hbs!tpls/catalogs/lab/invite-popover.handlebars");

	//Used as partial
	require("hbs!tpls/catalogs/lab/systems.handlebars");
	require("hbs!tpls/catalogs/lab/reviews-and-ratings.handlebars");
	require("hbs!tpls/catalogs/lab/badges.handlebars");
	require("hbs!tpls/catalogs/lab/common-metadata.handlebars");
	require("hbs!tpls/catalogs/lab/common-metadata-links.handlebars");
	require("hbs!tpls/catalogs/lab/enroll-button.handlebars");

	return BaseView.extend({

		template : LabInfoTPL,
		throttleRending : 100,

		forceUserRating : false,

		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		className : "lab-item",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .lab-detail-header"      : "showDetails",
			"click .show-details"           : "showDetails",
			"click .read-more"              : "showFullDescription",
			"click .read-less"              : "hideFullDescription",
			"click button.enroll"           : "enroll",
			"click .review-count"           : "showReviewsModal",
			"show .modal-network-topology"  : "shownTopology"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);

			this.catalogId = options.catalogId;
			this.model.set("partOfCourse", options.partOfCourse || false);

			this.listenTo(this.model, "change", this.render);
		},
		serialize : function(){
			var self = this;

			var data = this.model.toHandlebars();
			data.userName       = App.user.get("displayName");

			if(this.catalogId && data.catalogs){
				data.catalogs = _.filter(data.catalogs, function(catalog){
					return	catalog.id !== self.catalogId;
				} );
			}

			data.display = {
				showInvite      : App.config.showInvite && App.user.get("loggedIn"),
				showDescription : false
			};

			if(data.badges){
				data.badges.uuid = _.uniqueId("group_");
			}

			data.enrollAgain = App.config.maxConcurrentUserEntitlementsPerLab > 1 && data.enrollmentCount < App.config.maxConcurrentUserEntitlementsPerLab;
			return data;

		},
		/**
		 * Display the content.
		 */
		render : function(){
			this._ratingsVisible = false;
			var ratingsPopoverData = this.$(".rating-wrapper").data("popover");

			if(ratingsPopoverData && ratingsPopoverData.$tip && ratingsPopoverData.$tip.hasClass("in")){
				this._ratingsVisible = true;
			}
			this.$(".rating-wrapper").popover("destroy");

			this.$el.html(this.template(this.serialize()));

			this.setupImageErrorHandlers();

			this.$(".login-error").hide();

			this.$startSpinner = this.$(".enroll-spinner").hide();
			this.$enrollButton = this.$("button.enroll");
			this.$enrollButton.button("reset");


			if(!App.user.get("loggedIn")){
				this.stopListening(App.user, "change:loggedIn", this.render);
				this.listenToOnce(App.user, "change:loggedIn", this.render);
			}
			//Always return this for chaining
			return this;
		},
		afterRender : function(){
			var self = this;

			this.$(".invite-friend").popover({
				content : function(){
					return labInviteTPL(self.serialize());
				}
			});

			if(App.config.ratingEnabled) {
				if(!this.labRatingView){
					var lab = (this.model.get("lab") && this.model.get("lab") instanceof Backbone.Model ?  this.model.get("lab") : this.model);

					this.labRatingView = new LabRatingView({
						el              : this.el,
						model           : lab,
						forceUserRating : this.forceUserRating
					});
				}
				this.labRatingView.setElement(this.el);
				this.labRatingView.render({
					showPopover : this._ratingsVisible
				});
			}
			this.$("[rel=tooltip]").tooltip();
			this.$("[rel=popover]")
				.popover()
				.on("shown", function(){
					self.$("[rel=tooltip]").tooltip();
				});
		},
		remove : function(){
			if(this.labRatingView) {
				this.labRatingView.remove();
			}
			if(this.labReviewsView) {
				this.labReviewsView.remove();
			}
			this.$(".lab-image img").off("error.lab-thumbnail load.lab-thumbnail");
			this.$(".screenshot").off("error.lab-screenshot load.lab-screenshot");
			BaseView.prototype.remove.apply(this, arguments);
		},
		showDetails : function(event){
			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			var self = this;
			var $this = $(event.currentTarget);

			if ($this.hasClass("expanded")) {
				$this.removeClass("expanded").addClass("closed");
			} else {
				$this.removeClass("closed").addClass("expanded");
			}

			var $fullDescription = this.$(".lab-detail");
			$fullDescription.slideToggle("slow", "easeInOutQuint", function(){
				self.$el.redraw();
			});
			App.analytics.trackEvent("Lab", "Show: Details", this.model.get("sku"));
		},
		showFullDescription : function(){
			var self = this;

			this.$(".short-description").fadeOut("fast", function(){
				self.$(".full-description").fadeIn("fast");
			});
			App.analytics.trackEvent("Lab", "Show: Description", this.model.get("sku"));
		},
		hideFullDescription : function(){
			var self = this;

			this.$(".full-description").fadeOut("fast", function(){
				self.$(".short-description").fadeIn("fast");
			});

		},
		enroll : function(event){

			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			var self = this;
			this.model.enroll({
				loginCancelable : true,
				debug : ["views.catalogs.lab:enroll"],
				success : function(model, response){
					self.$enrollButton.button("reset");
					self.$startSpinner.hide();
					self.$(".login-error").show();
					self.render();
					App.analytics.trackEvent("Lab", "Enroll", self.model.get("sku"));
					if(self.model.has("entitlement")){
						App.router.navigate("enrollments/lab/" + self.model.get("entitlement").id, {trigger : true});
					}
				},
				error : function(model, jqXHR){
					if(jqXHR.statusText === "loginCanceled"){
						self.$(".login-error").show();
						self.listenToOnce(App.user, "change:loggedIn", function(){
							self.$(".login-error").hide();
						});
					} else{
						alert(App.i18n("errors.enrollLab"));
						return false;
					}
				},
				beforeSend : function(){
					self.$startSpinner.show();
					self.$enrollButton.button("loading");
					self.$(".login-error").hide();
				},
				complete : function(){
					self.$enrollButton.button("reset");
					self.$startSpinner.hide();
				}
			});
		},
		showReviewsModal : function(){

			if(!App.config.reviewsEnabled){
				return;
			}
			var lab = (this.model.get("lab") && this.model.get("lab") instanceof Backbone.Model ?  this.model.get("lab") : this.model);

			if(!this.labReviewsView){
				this.labReviewsView = new LabReviewsView({model : lab});
			}

			this.labReviewsView.render();

			this.$el.append(this.labReviewsView.el);
			this.labReviewsView.show();

		},
		shownTopology : function(){
			App.analytics.trackEvent("Lab", "Show: Topology", this.model.get("sku"));
		},

		setupImageErrorHandlers : function(){
			var imgProperties = ["thumbnail", "screenshot", "networkTopology"];
			for(var i in imgProperties){
				if(imgProperties.hasOwnProperty(i)) {
					var property = imgProperties[i];

					var events = "error.lab-" + property + " load.lab-" + property;
					var $images = this.$("img[data-property=\"" + property + "\"]");
					$images.off(events);

					if ($images.length && this.model.get(property) && this.model.get("property") !== App.config.defaultLabThumbnail) {
						$images.one(events, this.errorLoadingImage);
					}
				}
			}
		},

		errorLoadingImage : function(event){
			if(event.type !== "error") {
				return;
			}


			var $target = $(event.currentTarget);
			var property = $target.data("property");
			var events = "error.lab-" + property + " load.lab-" + property;

			$target.off(events);

			if(property === "thumbnail"){
				$target.attr("src", App.config.defaultLabThumbnail);
				this.model.set(property, App.config.defaultLabThumbnail, {silent : true});
			} else {
				$target.attr("src", "");
				this.model.set(property, "");
			}

		}
	});

});

