
define('hbs!tpls/console/walkthrough/step-0.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "		<p class=\"console-walkthrough-welcome-message\">\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.welcome.firstTime",{"name":"$","hash":{},"data":data}))
    + "\n		</p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<h4 class=\"console-walkthrough-title\">\n	<button class=\"btn btn-default console-walkthrough-exit\">&times;</button>\n	"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.welcome.title",{"name":"$","hash":{},"data":data}))
    + "\n</h4>\n\n<div class=\"console-walkthrough-content\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.firstTime : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "	<p>\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.welcome.tour",{"name":"$","hash":{},"data":data}))
    + "\n	</p>\n</div>\n\n<div class=\"console-walkthrough-actions console-walkthrough-actions-left\">\n	<button class=\"btn btn-primary console-walkthrough-next\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.actions.takeTour",{"name":"$","hash":{},"data":data}))
    + "</button>\n	<button class=\"btn btn-no-style console-walkthrough-exit\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.actions.noThanks",{"name":"$","hash":{},"data":data}))
    + "</button>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('console_walkthrough_step_0', t);
return t;
});

