define('models/instructor-help-request',['require','exports','module','underscore','library/vlp/app','library/vlp/model','library/vlp/collection'],function (require, exports) {
	"use strict";

	// library dependencies
	var _ = require("underscore");

	// class dependencies
	var App = require("library/vlp/app"),
		BaseModel = require("library/vlp/model"),
		BaseCollection = require("library/vlp/collection");

	// Use exports for requirejs circular dependency resolving
	var InstructorHelpRequest = exports;

	// Add InstructorHelpRequest to the App.Models namespace
	App.Models.InstructorHelpRequest = InstructorHelpRequest;

	InstructorHelpRequest.Model = BaseModel.extend({
		urlRoot: "instructorHelpRequest",
		pushService: "instructorHelpRequest",

		/**
		 * Default values for empty Models (new HelpRequest.Model())
		 */
		defaults: {
			classId      : null,
			status       : null,
			entitlementId: null,
			displayName  : null,
			userId       : null
		},

		/**
		 * Constructor
		 */
		initialize: function () {

		},

		/**
		 * Create url
		 * @returns {*}
		 */
		url: function () {
			var urlRoot = _.result(this, "basePath");
			return this.serverBase + urlRoot;
		},

		/**
		 * Create help request
		 * @param options
		 */
		createHelpRequest: function (options) {
			options                      = options || {};
			options.params               = options.params || {};
			options.params.classId       = options.params.classId || this.get("classId");
			options.params.status        = "on";
			options.params.entitlementId = options.params.entitlementId || this.get("entitlementId");
			options.method               = "create";
			return this.action("helpRequest", options);
		},

		/**
		 * Get help request
		 * @param options
		 */
		getHelpRequest: function (options) {
			options                      = options || {};
			options.params               = options.params || {};
			options.params.classId       = options.params.classId || this.get("classId");
			options.params.entitlementId = options.params.entitlementId || this.get("entitlementId");
			options.method               = "read";
			return this.action("helpRequest", options);
		},

		/**
		 * Delete a help request
		 * @param options
		 */
		deleteHelpRequest: function (options) {
			options                      = options || {};
			options.params               = options.params || {};
			options.params.classId       = options.params.classId || this.get("classId");
			options.params.status        = "off";
			options.params.entitlementId = options.params.entitlementId || this.get("entitlementId");
			options.method               = "delete";

			return this.action("helpRequest", options);
		}
	});

	/**
	 * Create a collection of instructor help requests
	 */
	InstructorHelpRequest.Collection = BaseCollection.extend({
		model      : InstructorHelpRequest.Model,
		urlRoot    : "instructorHelpRequest",
		action     : "helpRequests",
		pushService: "instructorHelpRequestInstructor",
		classId    : 0,

		initialize: function (model, options) {
		},

		/**
		 * Create url
		 * @returns {*}
		 */
		url: function () {
			var urlRoot = this.basePath();
			return this.serverBase + urlRoot + this.action;
		},

		/**
		 * Get help request
		 * @param options
		 */
		getHelpRequests: function (classId) {
			var options            = {};
			options.params         = {};
			options.params.classId = classId;

			options.success = this.fetchSuccess;

			options.error = this.fetchError;

			return this.fetch(options);
		},

		fetchSuccess: function (collection, response) {
			console.debug("Collection fetch success", response);
		},

		fetchError: function (collection, response) {
			throw new Error("Books fetch error");
		}
	});
});

