
define('hbs!tpls/compatibility/supported-browsers.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"supported-browsers well\">\n	<h3>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.supported",{"name":"$","hash":{},"data":data}))
    + "</h3>\n	<ul class=\"supported-desktop\">\n		<li><a href=\"http://www.mozilla.org/en-US/firefox/new/\">"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.browsers.firefox",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</a></li>\n		<li><a href=\"https://www.google.com/chrome\">"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.browsers.chrome",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</a></li>\n		<li><a href=\"http://support.apple.com/downloads/#internet\">"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.browsers.safari",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</a></li>\n		<li><a href=\"http://windows.microsoft.com/en-us/internet-explorer/download-ie\">"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.browsers.msie",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</a></li>\n		<li><a href=\"https://www.microsoft.com/en-us/windows/microsoft-edge\">"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.browsers.edge",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</a></li>\n	</ul>\n\n	<h3>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.supportedDevices",{"name":"$","hash":{},"data":data}))
    + "</h3>\n	<ul class=\"supported-devices\">\n		<li>"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"unsupported.devices.ipad",{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</li>\n	</ul>\n</div>";
},"useData":true});
Handlebars.registerPartial('compatibility_supported_browsers', t);
return t;
});

