define('routers/not-found',['require','library/vlp/router','views/not-found/main'],function (require) {
	"use strict";

	var BaseRouter = require("library/vlp/router"),
	    MainView   = require("views/not-found/main");

	return BaseRouter.extend({
		routeBase : "not-found",
		initialize : function(config){
			this.setup(config);
			this.mainView = new MainView(config);
			this.start();
		},
		routes : {
			"*actions" : "index"
		},
		index : function(){
		}
	});
});

