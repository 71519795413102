
define('hbs!tpls/common/profile/preferences.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "autocomplete=\"on\"";
},"3":function(depth0,helpers,partials,data) {
    return "autocomplete=\"off\" action=\"https://about:blank\"";
},"5":function(depth0,helpers,partials,data) {
    return "\"language\"";
},"7":function(depth0,helpers,partials,data) {
    return "\"off\"";
},"9":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "						<option value=\""
    + this.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"value","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.label : depth0),{"name":"$","hash":{},"data":data}))
    + "\n							"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.manualOnly : depth0),{"name":"if","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n						</option>\n";
},"10":function(depth0,helpers,partials,data) {
    return "selected=\"selected\"";
},"12":function(depth0,helpers,partials,data) {
    return " <b>("
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"language-chooser.manualOnly",{"name":"$","hash":{},"data":data}))
    + ")</b>";
},"14":function(depth0,helpers,partials,data) {
    var helper;

  return "						<option value=\""
    + this.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"value","hash":{},"data":data}) : helper)))
    + "\">"
    + this.escapeExpression(((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"label","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<form class=\"form-horizontal\" id=\"profile_preferences_form\" method=\"post\" "
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " data-error-style=\"tooltip\">\n\n	<fieldset class=\"preferences\">\n		<div class=\"control-group\">\n			<label class=\"control-label\" for=\"profile_language\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.preferences.language",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls\">\n				<select id=\"profile_language\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.languages : depth0),{"name":"each","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "				</select>\n			</div>\n		</div>\n		<div class=\"control-group\">\n			<label class=\"control-label\" for=\"profile_location\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.preferences.location",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls\">\n				<select id=\"profile_location\">\n					<option></option>\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.locations : depth0),{"name":"each","hash":{},"fn":this.program(14, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "				</select>\n			</div>\n		</div>\n		<div class=\"control-group\">\n			<label class=\"control-label\" for=\"profile_location\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.preferences.timezone",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls\">\n				<div class=\"timezone\">\n"
    + ((stack1 = this.invokePartial(partials.common_profile_timezones,depth0,{"name":"common_profile_timezones","data":data,"indent":"\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "				</div>\n				<button type=\"button\" class=\"btn\" data-target=\"#modal_timezone_map\" data-toggle=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.preferences.showTimezoneMap",{"name":"$","hash":{},"data":data}))
    + "</button>\n			</div>\n\n\n			<div id=\"modal_timezone_map\" class=\"modal global fluid hide\">\n				<div class=\"modal-dialog\">\n					<div class=\"modal-content\">\n"
    + ((stack1 = this.invokePartial(partials.common_profile_timezone_map,depth0,{"name":"common_profile_timezone_map","data":data,"indent":"\t\t\t\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "					</div>\n				</div>\n			</div>\n		</div>\n		<div class=\"control-group\">\n			<label class=\"control-label\" for=\"profile_showTips\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.preferences.showTips",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls\">\n				<input type=\"checkbox\" id=\"profile_showTips\" />\n			</div>\n		</div>\n	</fieldset>\n\n	<div class=\"alert alert-error save-error-general hide\"></div>\n\n	<button type=\"submit\" class=\"btn btn-large btn-primary pull-right save-changes\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","saving",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.saveChanges",{"name":"$","hash":{},"data":data}))
    + "</button>\n\n	<div class=\"spinner hide pull-right save-spinner\"/>\n	<div class=\"alert alert-success hide pull-right save-success\">\n		<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"updated",{"name":"$","hash":{},"data":data}))
    + "</strong>\n	</div>\n	<div class=\"alert alert-error hide pull-right save-error\">\n		<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"error",{"name":"$","hash":{},"data":data}))
    + "</strong>\n	</div>\n</form>";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('common_profile_preferences', t);
return t;
});

