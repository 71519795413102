define('views/console/resources',['require','underscore','library/vlp/app','library/vlp/view','hbs!tpls/console/resources.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var _        = require("underscore");

	//class dependencies
	var App          = require("library/vlp/app"),
	    BaseView     = require("library/vlp/view"),
	    resourcesTPL = require("hbs!tpls/console/resources.handlebars");


	return BaseView.extend({
		template : resourcesTPL,
		removeKeepEl    : true,

		throttleRending : 100,


		events : {
		},
		initialize : function(options){
			_.bindAll(this);
			options = options || {};

			this.listenTo(this.collection, "change", this.render);
			this.panel = options.panel;

		},
		serialize : function(){
			var data = this.collection.toHandlebars();
			return data;
		},
		afterRender : function(){
			if(this.collection.length === 0 || !App.config.showPanels || !App.config.showResourcesPanel){
				this.panel.hide();
			} else{
				this.panel.show();
			}
		}
	});

});

