/**
 * Idle Timed Out Backbone View
 */
define('views/console/idle-timed-out',['require','underscore','backbone','library/vlp/app','hbs!tpls/console/idle-timed-out.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App = require("library/vlp/app"),
	    tpl = require("hbs!tpls/console/idle-timed-out.handlebars");

	return Backbone.View.extend({
		template : tpl,

		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		//el : "",

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .restart"             : "restartLab"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			this.model.on("change:progress change:status", this.render);
		},
		/**
		 * Display the content.
		 */
		render : function(){

			this.$el.html(this.template(this.model));

			//Always return this for chaining
			return this;
		},

		restartLab : function(event){
			this.model.refresh();
			_.defer(function(){
				App.reload();
			});
		}
	});

});
