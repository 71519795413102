/**
 * Base Backbone Router
 *
 * This base router adds the router and config values to the Global
 * App object (defined in app.js).
 *
 * It also assigns the serverBase for models/collections.
 */
define('library/vlp/router',['require','backbone','library/vlp/app'],function (require) {
	"use strict";

	//library dependencies
	var Backbone = require("backbone");
	
	//class dependencies
	var App      = require("library/vlp/app");

	/**
	 * Base Router Class
	 */
	return Backbone.Router.extend({
		setup : function(config){
			App.init(this, config);
		},
		start : function(){

			if(Backbone.history.history.replaceState && window.self.location.pathname.substr(0, App.urlRoot.length) !== App.urlRoot){
				var hash = window.self.location.hash;
				if(hash){
					hash = hash.replace(/%2F/g, "/");
				}
				Backbone.history.history.replaceState({}, document.title, App.urlRoot + hash + window.self.location.search);
			}

			Backbone.history.start({pushState : true, root : App.urlRoot});
			App.setState("starting");
		}
	});

});
