
define('hbs!tpls/compatibility/status-indicator.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "	<div class=\"status-indicator success\">\n		<span class=\"fa-stack\">\n			<i class=\"fa fa-circle fa-stack-2x\"></i>\n			<i class=\"fa fa-circle fa-stack-2x fa-inverse\"></i>\n			<i class=\"fa fa-check fa-stack-1x\"></i>\n		</span>\n	</div>\n";
},"3":function(depth0,helpers,partials,data) {
    return "	<div class=\"status-indicator failure\">\n		<span class=\"fa-stack\">\n			<i class=\"fa fa-circle fa-stack-2x\"></i>\n			<i class=\"fa fa-info fa-stack-1x fa-inverse\"></i>\n		</span>\n	</div>\n";
},"5":function(depth0,helpers,partials,data) {
    return "	<div class=\"status-indicator warning\">\n		<span class=\"fa-stack\">\n			<i class=\"fa fa-circle fa-stack-2x\"></i>\n			<i class=\"fa fa-info fa-stack-1x fa-inverse\"></i>\n		</span>\n	</div>\n";
},"7":function(depth0,helpers,partials,data) {
    return "	<div class=\"status-indicator running\">\n		<span class=\"fa-stack\">\n			<i class=\"fa fa-spinner fa-spin fa-2x\"></i>\n		</span>\n	</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"success",{"name":"eq","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"failure",{"name":"eq","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"warning",{"name":"eq","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"running",{"name":"eq","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
Handlebars.registerPartial('compatibility_status_indicator', t);
return t;
});

