/**
 * Add Native String object functions as handlebar utilities.
 */
define('library/handlebars/string-helpers',['require','handlebars','underscore'],function (require) {
	"use strict";

	//library dependencies
	var Handlebars = require("handlebars"),
	    _          = require("underscore");

	var funcs = [
		"charAt",
		"charCodeAt",
		"concat",
		"indexOf",
		"lastIndexOf",
		"localeCompare",
		"match",
		"replace",
		"search",
		"slice",
		"split",
		"substr",
		"substring",
		"toLocaleLowerCase",
		"toLocaleUpperCase",
		"toLowerCase",
		"toSource",
		"toUpperCase"
	];

	_.each(funcs, function(func){
		if(_.isFunction(String.prototype[func])){
			Handlebars.registerHelper(func, function(){
				var args = _.toArray(arguments);
				args.pop(arguments);
				var string = String(args.shift());
				return String.prototype[func].apply(string, args);
			});
		}
	});

	var htmlFuncs = [
		"anchor",
		"big",
		"blink",
		"bold",
		"fixed",
		"fontcolor",
		"fontsize",
		"italics",
		"link",
		"small",
		"strike",
		"sub",
		"sup"
	];

	_.each(htmlFuncs, function(func){
		if(_.isFunction(String.prototype[func])){
			Handlebars.registerHelper(func, function(){
				var args = _.toArray(arguments);
				args.pop(arguments);
				var string = String(args.shift());
				return new Handlebars.SafeString(String.prototype[func].apply(string, args));
			});
		}
	});

	Handlebars.registerHelper("upper", function(string){
		return String(string).toUpperCase();
	});
	Handlebars.registerHelper("lower", function(string){
		return String(string).toLowerCase();
	});
	Handlebars.registerHelper("encodeURIComponent", function(string){
		return encodeURIComponent(string);
	});
});

