define('models/support-option',['require','exports','module','library/vlp/app','library/vlp/model','library/vlp/collection'],function (require, exports) {
	"use strict";

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection");

	//Use exports for requirejs circular dependency resolving
	var SupportOption = exports;

	//Add SupportOption to the App.Models namespace
	App.Models.SupportOption = SupportOption;


	SupportOption.Model = BaseModel.extend({
		urlRoot : "/supportOptions",

		/**
		 * Default values for empty Models (new SupportOption.Model())
		 */
		defaults : {
			type        : "url",
			value       : "",
			displayText : "",
			name        : ""
		},

		/**
		 * Constructor
		 */
		initialize : function(){

		},
		getters : {
			urlValue : function(){
				if(!this.attributes.value){
					return "";
				}
				if(this.get("type") === "url" && !this.attributes.value.match(/:\/\//)){
					return App.VlpGlobal.replaceVariables("http://" + this.attributes.value);
				}
				return App.VlpGlobal.replaceVariables(this.attributes.value);
			},
			value : function(){
				if(!this.attributes.value){
					return "";
				}
				return App.VlpGlobal.replaceVariables(this.attributes.value);
			}
		}
	});


	SupportOption.Collection = BaseCollection.extend({
		model : SupportOption.Model
	});

});

