define('views/catalogs/lab-landing',['require','jquery','underscore','library/vlp/app','library/vlp/view','models/lab','views/catalogs/lab-rating','views/catalogs/lab-reviews','hbs!tpls/catalogs/lab-landing.handlebars','hbs!tpls/catalogs/lab/invite-popover.handlebars','hbs!tpls/catalogs/ratings-popover.handlebars','hbs!tpls/catalogs/lab/badges.handlebars','hbs!tpls/catalogs/lab/reviews-and-ratings.handlebars','hbs!tpls/catalogs/lab/systems.handlebars','hbs!tpls/catalogs/lab/common-metadata.handlebars','hbs!tpls/catalogs/lab/common-metadata-links.handlebars','hbs!tpls/catalogs/lab/review.handlebars','hbs!tpls/catalogs/lab/small.handlebars','hbs!tpls/catalogs/lab/enroll-button.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App              = require("library/vlp/app"),
	    BaseView         = require("library/vlp/view"),
	    Lab              = require("models/lab"),
	    LabRatingView    = require("views/catalogs/lab-rating"), //jshint ignore:line
	    LabReviewsView   = require("views/catalogs/lab-reviews"),
	    labLandingTPL    = require("hbs!tpls/catalogs/lab-landing.handlebars"),
	    labInviteTPL     = require("hbs!tpls/catalogs/lab/invite-popover.handlebars"),
	    reviewPopoverTPL = require("hbs!tpls/catalogs/ratings-popover.handlebars");


	//Just accessed as a partial
	require("hbs!tpls/catalogs/lab/badges.handlebars");
	require("hbs!tpls/catalogs/lab/reviews-and-ratings.handlebars");
	require("hbs!tpls/catalogs/lab/systems.handlebars");
	require("hbs!tpls/catalogs/lab/common-metadata.handlebars");
	require("hbs!tpls/catalogs/lab/common-metadata-links.handlebars");
	require("hbs!tpls/catalogs/lab/review.handlebars");
	require("hbs!tpls/catalogs/lab/small.handlebars");
	require("hbs!tpls/catalogs/lab/enroll-button.handlebars");


	return BaseView.extend({
		template : labLandingTPL,
		removeKeepEl : true,
		labId : null,
		throttleRending : 100,

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .lab-detail-header"   : "showDetails",
			"click button.enroll"        : "enroll",
			"click .review-count"        : "showReviewsModal"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);

		},
		serialize : function(){
			var data = this.model.toHandlebars();
			data.userName = App.user.get("displayName");

			data.loggedIn = App.user.get("loggedIn");

			data.display = {
				showInvite      : App.config.showInvite && App.user.get("loggedIn"),
				showDescription : false
			};

			if(data.badges){
				data.badges.uuid = _.uniqueId("group_");
			}

			data.enrollAgain = App.config.maxConcurrentUserEntitlementsPerLab > 1 && data.enrollmentCount < App.config.maxConcurrentUserEntitlementsPerLab;
			return data;
		},
		afterRender : function(){
			var self = this;
			this.setupImageErrorHandlers();

			this.$(".login-error").hide();
			this.$(".invite-friend").popover({
				content : function(){
					return labInviteTPL(self.serialize());
				}
			});

			this.$startSpinner = this.$(".enroll-spinner").hide();
			this.$enrollButton = this.$("button.enroll");
			this.$enrollButton.button("reset");

			if(App.config.ratingEnabled) {
				if(!this.labRatingView){
					this.labRatingView = new LabRatingView({
						model : this.model,
						el : this.el
					});
				} else{
					this.labRatingView.setElement(this.el);
				}
				this.labRatingView.render();



				this.$(".lab-reviews .rating, .other-labs .rating").raty({
					readOnly : true,
					score: function() {
						return $(this).data("score");
					}
				});
				this.$(".rating[data-score=''],.rating[data-score='']").hide();

				this.$(".lab-reviews .rating-wrapper, .other-labs .rating-wrapper").popover({
					content : function(){
						var id = $(this).data("id");
						var model = {};
						if(id){
							model = Lab.Model.find(id);
						}
						return reviewPopoverTPL(model);
					},
					title : function(){
						var id = $(this).data("id");
						var model = {};
						if(id){
							model = Lab.Model.find(id);
							if(model){
								return App.i18n("catalogs.labs.ratings.label", model.get("ratingNum"));
							}
						}
						return App.i18n("catalogs.labs.ratings.label", "0");
					},
					container : this.$(".labs")
				});
			}
			if(this.model && this.model.get("name")){
				App.mainView.setTitle(
					"title",
					(App.config.tenantName ? App.config.tenantName : App.getTenant()),
					this.model.get("name"),
					"catalogs.labLanding.title"
				);
			}

			this.$("[rel=tooltip]").tooltip();
			this.$("[rel=popover]")
				.popover()
				.on("shown", function(){
					self.$("[rel=tooltip]").tooltip();
				});
		},

		show : function(labId){
			var self = this;

			if(labId){
				this.labId = labId;
			}

			if(!this.model || this.model.get("id") != this.labId){
				if(this.model){
					this.stopListening(this.model);
				}
				this.model = Lab.Model.findOrCreate({
					id : this.labId
				});
				this.labRatingView = null;
				this.listenTo(this.model, "request sync error", this.render);
				this.listenTo(this.model, "change change:badges change:catalogs", this.render);
				this.model.fetch({
					params : {
						"with" : [
							"authors", "badges", "catalogs", "entitlement", "networkTopology", "products",
							"rating", "relatedLabs", "tags", "thumbnail", "vms"
						]
					},
					loginCancelable : true,
					success : function(){
						App.analytics.trackEvent("Lab", "View", self.model.get("sku"));
					},
					error : function(model, jqXHR){
						if(jqXHR.statusText === "loginCanceled"){
							self.render();
						} else{
							return false;
						}
					}
				});
			}

			if(!App.user.get("loggedIn")){
				this.stopListening(App.user, "change:loggedIn");
				this.listenToOnce(App.user, "change:loggedIn", function(){
					self.show();
				});
			}
		},
		remove : function(){
			this.$(".lab-image img").off("error.lab-thumbnail load.lab-thumbnail");
			this.$(".screenshot").off("error.lab-screenshot load.lab-screenshot");
			if(this.labReviewsView){
				this.labReviewsView.remove();
			}
			BaseView.prototype.remove.apply(this, arguments);
		},

		showDetails : function(e){
			e.preventDefault();
			var self = this;
			var $this = $(e.currentTarget);

			if ($this.hasClass("expanded")) {
				$this.removeClass("expanded").addClass("closed");
			} else {
				$this.removeClass("closed").addClass("expanded");
			}

			var $fullDescription = this.$(".lab-detail");
			$fullDescription.slideToggle("slow", "easeInOutQuint", function(){
				self.$el.redraw();
				self.trigger("afterRender");
			});
		},
		enroll : function(event){

			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			var self = this;
			this.$startSpinner.show();
			this.$enrollButton.button("loading");
			this.$(".login-error").hide();

			this.model.enroll({
				loginCancelable : true,
				debug : ["views.catalogs.lab:enroll"],
				success : function(){
					self.$startSpinner.hide();
					self.$enrollButton.button("reset");
					self.$(".login-error").hide();
					self.render();
					if(self.model.has("entitlement")){
						App.router.navigate("enrollments/lab/" + self.model.get("entitlement").id, {trigger : true});
					}
				},
				error : function(model, jqXHR){

					self.$enrollButton.button("reset");
					self.$startSpinner.hide();

					if(jqXHR.statusText === "loginCanceled"){
						self.$(".login-error").show();
						self.listenToOnce(App.user, "change:loggedIn", function(){
							self.$(".login-error").hide();
						});
					} else{
						$("#modal_course_no_access").modal("show");
						return false;
					}
				}
			});
		},
		showReviewsModal : function(){

			if(!App.config.reviewsEnabled){
				return;
			}
			if(!this.labReviewsView){
				this.labReviewsView = new LabReviewsView({model : this.model});
			}

			this.labReviewsView.render();

			this.$el.append(this.labReviewsView.el);
			this.labReviewsView.show();

		},

		setupImageErrorHandlers : function(){
			var imgProperties = ["thumbnail", "screenshot", "networkTopology"];
			for(var i in imgProperties){
				if(imgProperties.hasOwnProperty(i)){
					var property = imgProperties[i];

					var events = "error.lab-" + property + " load.lab-" + property;
					var $images = this.$("img[data-property=\"" + property + "\"]");
					$images.off(events);

					if($images.length &&  this.model.get(property) && this.model.get("property") !== App.config.defaultLabThumbnail){
						$images.one(events, this.errorLoadingImage);
					}
				}
			}
		},

		errorLoadingImage : function(event){
			if(event.type !== "error") {
				return;
			}

			var $target = $(event.currentTarget);
			var property = $target.data("property");
			var events = "error.lab-" + property + " load.lab-" + property;

			$target.off(events);

			if(property === "thumbnail"){
				$target.attr("src", App.config.defaultLabThumbnail);
				this.model.set(property, App.config.defaultLabThumbnail, {silent : true});
			} else {
				$target.attr("src", "");
				this.model.set(property, "");
			}

		}
	});

});

