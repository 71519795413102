define('models/survey',['require','exports','module','backbone','library/vlp/app','library/vlp/model','utilities/browser'],function (require, exports) {
	"use strict";

	//library dependencies
	var Backbone = require("backbone");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    Browser = require("utilities/browser");

	//Use exports for requirejs circular dependency resolving
	var Survey = exports;

	//Add Survey to the App.Models namespace
	App.Models.Survey = Survey;


	Survey.Model = BaseModel.extend({
		urlRoot : "survey",
		pushService : "survey",

		/**
		 * Default values for empty Models (new Survey.Model())
		 */
		defaults : {
			entitlementId : null,
			url           : null,
			image         : "",
			autoLaunch    : false,
			showPromoPage : false,
			promoText     : ""
		},
		types : {
			autoLaunch       : Backbone.Types.Boolean,
			showPromoPage    : Backbone.Types.Boolean
		},
		getters : {
			url: function(){
				return App.VlpGlobal.replaceVariables(this.attributes.url);
			}
		},
		/**
		 * Constructor
		 */
		initialize : function(){

		},
		fetch : function(options){
			options = options || {};
			options.pushFetch = true;
			options.params = options.params || {};
			options.params.id = this.get("entitlementId");
			options.params.entitlementId = this.get("entitlementId");
			options.params.station   = App.station.isRegistered();
			options.params.stationId = App.station.get("stationId");
			options.params.browser   = Browser.name + Browser.version.major + "-" + Browser.osType;
			options.pushReturnEvent = "received:" + this.pushService + ":" + this.get("entitlementId");
			options.properties = [];
			return this.action("getSurvey", options);
		}
	});

});

