
define('hbs!tpls/catalogs/transcript-lab.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var helper;

  return "		<div class=\"rating-wrapper hide\" data-html=\"true\" data-toggle=\"popover\" data-placement=\"left\" data-container=\"body\" data-class=\"popover-rating\" data-id=\""
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n			<div class=\"rating\" data-score=\""
    + this.escapeExpression(((helper = (helper = helpers.ratingAvg || (depth0 != null ? depth0.ratingAvg : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"ratingAvg","hash":{},"data":data}) : helper)))
    + "\"></div>\n			<div class=\"spinner hide\"></div>\n		</div>\n";
},"3":function(depth0,helpers,partials,data) {
    return "			<button class=\"btn btn-no-style lab-certificate\" type=\"button\">\n				<span class=\"fa fa-2x fa-eye\" data-container=\"body\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","catalogs.transcript.certificate.view",{"name":"$$","hash":{},"data":data}))
    + " rel=\"tooltip\"></span>\n			</button>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "\n<div class=\"lab-box\" data-lab-id=\""
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n\n	<button class=\"show-details btn-no-style\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","catalogs.labs.showDetails",{"name":"$$","hash":{},"data":data}))
    + " rel=\"tooltip\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.showDetails",{"name":"$","hash":{},"data":data}))
    + "</button>\n	<div class=\"lab-image\">\n		<img data-property=\"thumbnail\" src=\""
    + this.escapeExpression(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"alt","catalogs.labs.thumbnailAlt",(depth0 != null ? depth0.name : depth0),{"name":"$$","hash":{},"data":data}))
    + "/>\n	</div>\n	<div class=\"lab-text\">\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.transcript.date",{"name":"$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.date_format || (depth0 && depth0.date_format) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.completionDateTime : depth0),"dateFormatFull",{"name":"date_format","hash":{},"data":data}))
    + "\n		<h3 class=\"lab-name user-data skin-text-popout\">"
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n\n		<div class=\"lab-detail hide\">\n"
    + ((stack1 = this.invokePartial(partials.catalogs_lab_common_metadata,depth0,{"name":"catalogs_lab_common_metadata","data":data,"indent":"\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + ((stack1 = this.invokePartial(partials.catalogs_lab_common_metadata_links,depth0,{"name":"catalogs_lab_common_metadata_links","data":data,"indent":"\t\t\t","helpers":helpers,"partials":partials})) != null ? stack1 : "")
    + "\n			<button class=\"btn show-entitlement-life-cycle-data btn-no-style\" rel=\"tooltip\"></button> Activity History\n			<div class=\"activity-history\"></div>\n		</div>\n	</div>\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"ratingEnabled",{"name":"config","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "	<div class=\"lab-buttons\">\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"showCertificate",{"name":"config","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		<button class=\"btn btn-no-style email-single-transcript\" type=\"button\" rel=\"tooltip\" data-trigger=\"manual\" data-placement=\"bottom\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","catalogs.transcript.emailMessage",{"name":"$$","hash":{},"data":data}))
    + ">\n			<span class=\"fa fa-2x fa-envelope-o icon\" data-container=\"body\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","catalogs.transcript.emailSingle",{"name":"$$","hash":{},"data":data}))
    + " rel=\"tooltip\"></span>\n			<span class=\"fa fa-2x fa-spinner fa-spin hide\"></span>\n		</button>\n	</div>\n</div>\n";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('catalogs_transcript_lab', t);
return t;
});

