
define('hbs!tpls/console/remote-prompt.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var helper;

  return "<div id=\"qrcode\" class=\"pull-right\"></div>\n\n<p><a href=\""
    + this.escapeExpression(((helper = (helper = helpers.remoteUrl || (depth0 != null ? depth0.remoteUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"remoteUrl","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.remoteToken.prompt.newWindow",{"name":"$","hash":{},"data":data}))
    + "</a></p>\n\n<div class=\"email-split-screen-link\">\n	<a href=\""
    + this.escapeExpression(((helper = (helper = helpers.remoteMailToUrl || (depth0 != null ? depth0.remoteMailToUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"remoteMailToUrl","hash":{},"data":data}) : helper)))
    + "\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.remoteToken.prompt.email",{"name":"$","hash":{},"data":data}))
    + "</a>\n</div>\n\n<p>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.remoteToken.prompt.otherDevice",{"name":"$","hash":{},"data":data}))
    + "</p>\n\n<div class=\"split-screen-links\">\n	<div class=\"split-screen-token\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.remoteToken.prompt.tokenLabel",{"name":"$","hash":{},"data":data}))
    + " <span>"
    + this.escapeExpression(((helper = (helper = helpers.remoteToken || (depth0 != null ? depth0.remoteToken : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"remoteToken","hash":{},"data":data}) : helper)))
    + "</span></div>\n	<span class=\"split-screen-link\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.remoteToken.prompt.urlLabel",{"name":"$","hash":{},"data":data}))
    + " "
    + this.escapeExpression(((helper = (helper = helpers.remoteUrl || (depth0 != null ? depth0.remoteUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"remoteUrl","hash":{},"data":data}) : helper)))
    + "</span>\n</div>\n\n<p class=\"tablet-message well well-small\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.remoteToken.prompt.tabletMessage",{"name":"$","hash":{},"data":data}))
    + "</p>\n";
},"useData":true});
Handlebars.registerPartial('console_remote_prompt', t);
return t;
});

