define('views/instructor/manual',['require','jquery','underscore','library/vlp/app','library/vlp/view','views/console/manual-toc','views/console/manual-step-listing','models/manual','hbs!tpls/instructor/manual.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App              = require("library/vlp/app"),
	    BaseView         = require("library/vlp/view"),
	    ManualTocView    = require("views/console/manual-toc"),
	    StepListingView  = require("views/console/manual-step-listing"),
	    Manual           = require("models/manual"),
	    manualTPL        = require("hbs!tpls/instructor/manual.handlebars");

	return BaseView.extend({
		template : manualTPL,

		manualUpdated : false,

		fontSizes : [
			"font-size-smallest",
			"font-size-smaller",
			"font-size-small",
			"font-size-normal",
			"font-size-large",
			"font-size-larger",
			"font-size-largest"
		],
		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		el : "#instructions",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"change .lab-chooser-select": "onLabChanged",
			"click .make-text-smaller"  : "onDecreaseFont",
			"click .make-text-larger"   : "onIncreaseFont"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);

			App.config.fetchManualsFromContent = false;

			$(document).on("keydown.manual", this.onKeyDown);
			if($.mobile.support.touch){
				this.$el.on("swipeleft",  this.onSwipeLeft);
				this.$el.on("swiperight", this.onSwipeRight);
			}

			this.fontSizeIndex = App.store.get("fontSizeIndex") || (this.fontSizes.length  - 1) / 2;
			this.manualId            = options.manualId;
			this.class               = options.class;
			this.labs                = options.labs;
			this.classId             = options.classId;
			this.entitlement         = options.entitlement;
			this.networkTopology     = options.networkTopology;
			this.panel               = options.panel;
			this.consoleListingPanel = options.consoleListingPanel;
			this.title               = options.title;
			this.manualFilePath      = options.manualFilePath;

			if(this.panel){
				this.listenTo(this.panel, "panel:float",  this.panelConvertedToFloat);
				this.listenTo(this.panel, "panel:beforeexpand", this.panelExpanding);
				this.listenTo(this.panel, "panel:resized", this.onPanelResized);
				this.listenTo(this.panel, "panel:resizing", this.onPanelResizing);
			}


			if(this.labs && this.class.get("activeLabSku")){
				this.activeLab = this.labs.findWhere({sku: this.class.get("activeLabSku")});
				if(this.activeLab){
					this.manualId = this.activeLab.get("manualId");
					this.networkTopology = this.activeLab.get("networkTopology");
				}
			}

			if(this.class){
				this.listenTo(this.class, "change:activeLabSku", this.onActiveLabSkuChanged);
			}

			var classId = this.class ? this.class.get("id") : this.classId;
			this.listenTo(App.push, "other:instructorClass:" + classId + ":updateManual", this.refreshManual);
			this.listenTo(App.locale, "loadStop", this.refreshManual);

		},

		remove : function(){
			$(document).off("keydown.manual");
			this.$el.off("swipeleft", this.onSwipeLeft);
			this.$el.off("swiperight", this.onSwipeRight);
			BaseView.prototype.remove.call(this);

		},
		/**
		 * Display the content.
		 */
		render : function(){
			var self = this;
			var pdfManualResource;
			if(this.class){
				this.networkTopology = this.class.get("networkTopology");
				pdfManualResource = this.class.get("pdfManualResource");
			} else if (this.entitlement){
				this.networkTopology = this.entitlement.get("networkTopology");
				pdfManualResource = this.entitlement.get("pdfManualResource");
			}

			if(!this.manualId){
				this.model = new Manual.Model({
					id                  : this.manualId,
					networkTopology     : this.networkTopology,
					showRemoteControl   : App.config.showRemoteControl && !App.remoteControl,
					title               : this.title,
					locale              : App.locale.get(),
					pdfManualResource   : pdfManualResource,
					labs                : this.labs,
					manualFilePath      : this.manualFilePath
				});
				this.$el.html(this.template(this.model));

				if(this.class){
					this.class.set("manual", this.model);
				}
			} else if(!this.model){
				this.model = new Manual.Model({
					id                  : this.manualId,
					networkTopology     : this.networkTopology,
					title               : this.title,
					locale              : App.locale.get(),
					pdfManualResource   : pdfManualResource,
					labs                : this.labs,
					manualFilePath      : this.manualFilePath
				});
				this.fetch({
					error : function(model, response){
						self.handleFetchManualFail(self, model, response);
					},
					success : function(model, response){
						self.handleFetchManualSuccess(self, model);
					}
				});
			} else{
				this.model.set("networkTopology", this.networkTopology);
				this.model.set("manualFilePath", this.manualFilePath);
				if(this.class){
					this.class.set("manual", this.model);
				}

				var data = this.model.toHandlebars();
				this.$el.html(this.template(data));
				if (!this.manualFilePath) {
					if(!this.steps){
						this.steps = new StepListingView({
							el              : this.$("#step_listing"),
							collection      : this.model.get("steps"),
							manual          : this.model
						});
						App.VlpGlobal.setStepsView(this.steps);
					}
					if(!this.manualToc) {
						this.manualToc = new ManualTocView({
							el    : this.$("#modal_manual_toc"),
							model : this.model
						});
					}
					_.defer(function(){
						if(self.steps){
							self.steps.render();
						}
						self.setFontSize();

						if(self.manualUpdated){
							self.manualUpdated = false;
							self.showManualChangedMessage();
						}
					});
				}

			}
			return this;
		},
		afterRender : function(){
			this.$el.toggleClass("has-lab-chooser", this.labs && this.labs.length > 1);
			$("#modal_topology img").attr("src", this.networkTopology);

			this.$("[rel=tooltip]").tooltip();
			this.setFontSize();
		},
		refreshManual : function(){
			this.manualUpdated = true;
			this.reloadManual();
		},
		reloadManual : function(){
			var self = this;
			this.$el.html("<div class=\"loading\">" + App.i18n("console.entitlement.panels.manualLoading") + "</div>");
			if(this.manualToc){
				this.manualToc.remove();
				this.manualToc = null;
			}

			if(this.steps){
				this.steps.remove();
				this.steps = null;
			}
			if(this.model.get("steps")){
				this.model.get("steps").reset();
			}
			this.model.set("locale", App.locale.get());
			this.fetch({
				error : function(model, response){
					self.handleFetchManualFail(self, model, response);
				},
				success : function(model, response){
					self.handleFetchManualSuccess(self, model);
				}
			});
		},

		panelConvertedToFloat : function(){
			this.stopListening(this.panel, "panel:float", this.panelConvertedToFloat);
			this.panel.$el.height($(".dock-right").height() - 20);
			this.panel.update();

		},
		panelExpanding : function(){
			var dockedPosition = this.panel.state.position;
			if($("#vm_group").hasClass("docked") && $("#vm_group").hasClass(dockedPosition == "left" ? "right" : "left")){
				var width;
				if(dockedPosition === "right"){
					width = $(window).width() - ($("#vm_group").offset().left + $("#vm_group").outerWidth(true)) - 44;
				} else{
					width = $(window).width() - ($("#vm_group").offset().left + $("#vm_group").outerWidth(true)) - 44;
				}
				this.panel.$el.width(width);
				this.panel.update();
				this.panel.trigger("panel:resizing", this.panel);
			}
		},
		onPanelResized : function(){
			this.fixResourceResizing(false);
		},
		onPanelResizing : function(){
			this.fixResourceResizing(true);
		},
		setFontSize : function(){
			var self = this;
			if(this.fontSizeIndex < 0){
				this.fontSizeIndex = 0;
			}
			if(this.fontSizeIndex >= this.fontSizes.length){
				this.fontSizeIndex = this.fontSizes.length - 1;
			}
			_.each(this.fontSizes, function(fontSize){
				self.$("#steps_wrapper").removeClass(fontSize);
			});
			this.$("#steps_wrapper").addClass(this.fontSizes[this.fontSizeIndex]);
			App.store.set("fontSizeIndex", this.fontSizeIndex, 1);
		},

		fixResourceResizing: function (start) {
			if (this.class && (this.class.get("manualFilePath") || this.class.get("pdfManualResource"))) {
				var pdfManual = $("#manual_pdf_viewer");
				if (pdfManual) {
					if (start) {
						pdfManual.css("pointer-events", "none");
					} else {
						pdfManual.css("pointer-events", "unset");
					}
				}
			}
		},

		showManualChangedMessage : function(){
			var self = this;

			if((this.panel && this.panel.state.expanded) || App.remoteControl) {
				this.$(".top-buttons > .dropdown:not(.open) > a").dropdown("toggle");
				var $popover = this.$(".mc-toc").popover({
					placement : function(popover, element) {
						if (!self.panel) {
							return "right";
						} else if (self.panel.$el.hasClass("right")) {
							return "left";
						} else if (self.panel.$el.hasClass("left")) {
							return "right";
						} else if (self.panel.$el.offset().left + (self.panel.$el.outerWidth() / 2) > ($(document).width() / 2)) {
							return "left";
						} else {
							return "right";
						}
					}
				});
				$popover.popover("show");
				_.delay(function() {
					$popover.popover("hide");
				}, 7000);
			}
		},
		changeManual : function(sku){
			if(this.activeLab){
				this.activeLab.set("activeStepNumber", this.model.get("activeStepNumber"));
			}
			this.activeLab = this.labs.findWhere({sku: sku});
			if(this.activeLab){
				this.model.set("activeStepNumber", this.activeLab.get("activeStepNumber") || 0);
				this.networkTopology = this.activeLab.get("networkTopology");
				this.manualId = this.activeLab.get("manualId");
			}

			this.model.set({
				id                  : this.manualId,
				networkTopology     : this.networkTopology,
				showRemoteControl   : App.config.showRemoteControl && !App.remoteControl,
				title               : this.title,
				locale              : App.locale.get(),
				pdfManualResource   : this.class.get("pdfManualResource")
			});
			this.reloadManual();
		},
		onLabChanged : function(event){
			var labSku = $(event.currentTarget).val();
			this.class.set("activeLabSku", labSku);
		},
		onActiveLabSkuChanged : function(){
			if(!this.activeLab || this.activeLab.get("sku") !== this.class.get("activeLabSku")){
				var lab = this.labs.findWhere({sku: this.class.get("activeLabSku")});
				if(lab){
					this.changeManual(lab.get("sku"));
				}
			}
		},
		onDecreaseFont : function(event){
			if(event && event.preventDefault) { event.preventDefault(); }
			this.fontSizeIndex--;
			this.setFontSize();
		},
		onIncreaseFont : function(event){
			if(event && event.preventDefault) { event.preventDefault(); }
			this.fontSizeIndex++;
			this.setFontSize();
		},

		onShowTOC : function(){
			$("#modal_manual_toc").modal("show");
		},
		onShowNetworkTopology : function(){
			$("#modal_topology").modal("show");
		},
		onKeyDown : function(event){
			if(this.panel && !this.panel.state.floating && !this.panel.state.expanded) { return; }

			var targetingConsole = (event.target && event.target.parentNode && event.target.parentNode.id === "vm_console");


			if(!targetingConsole){
				if(event.which === 37 || (event.which === 188 && (event.metaKey || event.ctrlKey))){
					event.preventDefault();
					this.model.set("activeStepNumber", this.model.get("activeStepNumber") - 1);
				} else if(event.which === 39 || (event.which === 190 && (event.metaKey || event.ctrlKey) )) {
					event.preventDefault();
					this.model.set("activeStepNumber", this.model.get("activeStepNumber") + 1);
				}
			} else {
				//prevent default browser behaviour
				if(event.which === 188 && (event.metaKey || event.ctrlKey)){
					event.preventDefault();
				} else if(event.which === 190 && (event.metaKey || event.ctrlKey)){
					event.preventDefault();
				}
			}
		},
		onSwipeLeft : function(event){
			if(!$.mobile.support.touch){ return; }
			this.model.set("activeStepNumber", this.model.get("activeStepNumber") - 1);
		},
		onSwipeRight : function(event){
			if(!$.mobile.support.touch){ return; }
			this.model.set("activeStepNumber", this.model.get("activeStepNumber") + 1);
		},
		handleFetchManualSuccess: function (context, model) {
			if (model.get("manualFilePath")) {
				context.manualFilePath = model.get("manualFilePath");
				context.render();
			} else {
				context.manualFilePath = null;
				context.render();
			}
		},
		handleFetchManualFail: function (context, model, response) {
			if (response.response.errorCode === 404) {
				context.manualFilePath = null;
			}
			if(response.response.errorCode === 5304){
				model.set("alternativeLocales", response.response.data.tocs);
			}
			context.render();
		}
	});
});

