/**
 * Instructor Router
 *
 * Code starts running here for the /instructor page.
 */
define('routers/instructor',['require','library/vlp/router','views/instructor/main'],function (require) {
	"use strict";

	//library dependencies

	//class dependencies
	var BaseRouter = require("library/vlp/router"),
	    MainView   = require("views/instructor/main");


	return BaseRouter.extend({
		routeBase : "instructor",
		/**
		 * Constructor
		 *
		 * This code is called when new Router([options]) is called in the example.html.
		 *
		 * @param options Map of options (mostly passed on to new MainView([options]);
		 */
		initialize : function(config){

			this.setup(config);

			this.mainView = new MainView(config);

			this.start();
		},
		/**
		 * List of possible routes
		 */
		routes: {
			":classId"                         : "showClass",
			":classId/"                        : "showClass",
			":classId/console/:entitlementId"  : "showConsole",
			":classId/console/:entitlementId/" : "showConsole"
		},
		showClass : function(classId){
			this.mainView.showClass(classId);
		},
		showConsole : function(classId, entitlementId){
			this.mainView.showConsole(classId, entitlementId);
		}
	});
});

