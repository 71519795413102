
define('hbs!tpls/console/manual.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.tableOfContentsPosition : depth0),"top",{"name":"eq","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.showMoreOptions : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<li class=\""
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.showMoreOptions : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n					<a class=\"mc-toc\" data-toggle=\"modal\" data-target=\"#modal_manual_toc\" rel=\"popover\" data-trigger=\"manual\" data-placement=\"left\" data-class=\"popover-info\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-content","console.manual.manualChangedMessage",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","console.manual.manualChangedTitle",{"name":"$$","hash":{},"data":data}))
    + "><span class=\"fa fa-fw fa-list\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.tableOfContents",{"name":"$","hash":{},"data":data}))
    + "</a>\n				</li>\n";
},"4":function(depth0,helpers,partials,data) {
    return "top-toc";
},"6":function(depth0,helpers,partials,data) {
    return "dropdown";
},"8":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<li class=\"dropdown\">\n				<a data-toggle=\"dropdown\"><span class=\"caret caret-light\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.moreOptions",{"name":"$","hash":{},"data":data}))
    + "</a>\n				<ul class=\"dropdown-menu pull-right\">\n					"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.networkTopology : depth0),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.tableOfContentsPosition : depth0),"nested",{"name":"eq","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "					"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.showRemoteControl : depth0),{"name":"if","hash":{},"fn":this.program(14, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n				</ul>\n			</li>\n";
},"9":function(depth0,helpers,partials,data) {
    return "<li><a class=\"topology-link\" data-toggle=\"modal\" href=\"#modal_topology\"><span class=\"fa fa-fw fa-sitemap\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.networkTopology",{"name":"$","hash":{},"data":data}))
    + "</a></li>";
},"11":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"12":function(depth0,helpers,partials,data) {
    return "							<li>\n								<a class=\"mc-toc\" data-toggle=\"modal\" data-target=\"#modal_manual_toc\" rel=\"popover\" data-trigger=\"manual\" data-placement=\"left\" data-class=\"popover-info\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-content","console.manual.manualChangedMessage",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","console.manual.manualChangedTitle",{"name":"$$","hash":{},"data":data}))
    + "><span class=\"fa fa-fw fa-list\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.tableOfContents",{"name":"$","hash":{},"data":data}))
    + "</a>\n							</li>\n";
},"14":function(depth0,helpers,partials,data) {
    return "<li><a class=\"mc-split-screen\"><span class=\"fa fa-fw fa-columns\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.remoteControl",{"name":"$","hash":{},"data":data}))
    + "</a></li>";
},"16":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.pdfManualResource : depth0),{"name":"unless","hash":{},"fn":this.program(17, data, 0),"inverse":this.program(19, data, 0),"data":data})) != null ? stack1 : "");
},"17":function(depth0,helpers,partials,data) {
    return "			<div id=\"steps_wrapper\">\n				<div class=\"no-manual\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.noManual",{"name":"$","hash":{},"data":data}))
    + "\n				</div>\n			</div>\n";
},"19":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<div id=\"manual_pdf\">\n				<iframe id=\"manual_pdf_viewer\" src=\"/pdfjs-dist/web/viewer.html?file="
    + this.escapeExpression((helpers.encodeURIComponent || (depth0 && depth0.encodeURIComponent) || helpers.helperMissing).call(depth0,((stack1 = (depth0 != null ? depth0.pdfManualResource : depth0)) != null ? stack1.path : stack1),{"name":"encodeURIComponent","hash":{},"data":data}))
    + "#page="
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.pdfManualResource : depth0)) != null ? stack1.page : stack1), depth0))
    + "\"></iframe>\n			</div>\n";
},"21":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.manualFilePath : depth0),{"name":"unless","hash":{},"fn":this.program(22, data, 0),"inverse":this.program(24, data, 0),"data":data})) != null ? stack1 : "");
},"22":function(depth0,helpers,partials,data) {
    return "			<div id=\"steps_wrapper\">\n				<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n			</div>\n";
},"24":function(depth0,helpers,partials,data) {
    var helper;

  return "			<div id=\"manual_pdf\">\n				<iframe id=\"manual_pdf_viewer\" src=\"/pdfjs-dist/web/viewer.html?file="
    + this.escapeExpression((helpers.encodeURIComponent || (depth0 && depth0.encodeURIComponent) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.manualFilePath : depth0),{"name":"encodeURIComponent","hash":{},"data":data}))
    + "#page="
    + this.escapeExpression(((helper = (helper = helpers.manualFilePage || (depth0 != null ? depth0.manualFilePage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"manualFilePage","hash":{},"data":data}) : helper)))
    + "\"></iframe>\n			</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<h2 class=\"title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.title",{"name":"$","hash":{},"data":data}))
    + "</h2>\n<ul class=\"top-buttons\">\n"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.networkTopology : depth0),(depth0 != null ? depth0.id : depth0),(depth0 != null ? depth0.showRemoteControl : depth0),{"name":"or","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n\n\n<div id=\"step_listing\">\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.id : depth0),{"name":"unless","hash":{},"fn":this.program(16, data, 0),"inverse":this.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n\n<div id=\"modal_topology\" class=\"modal hide fade global fluid\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\"><span class=\"fa fa-fw fa-sitemap\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.networkTopology",{"name":"$","hash":{},"data":data}))
    + "</h3>\n			</div>\n			<div class=\"modal-body\">\n				<img src=\"\" alt=\"\"/>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_manual_toc\" class=\"modal hide fade global fluid-height scrollable\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\"><span class=\"fa fa-fw fa-list\"></span>"
    + this.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n			</div>\n			<div class=\"modal-body-header\">\n				<span class=\"expand-all\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-collapse-text","console.manual.toc.collapseAll",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.manual.toc.expandAll",{"name":"$","hash":{},"data":data}))
    + "</span>\n			</div>\n			<div class=\"modal-body\">\n				<ul id=\"manual_toc\">\n				</ul>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('console_manual', t);
return t;
});

