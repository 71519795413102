
define('hbs!tpls/console/complete.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<h1 id=\"lbl-lab-completed-completion-criteria-not-met-subtitle\">\n					<span class=\"fa fa-fw fa-exclamation-triangle text-warning\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.notMetTitle",{"name":"$","hash":{},"data":data}))
    + "\n				</h1>\n\n				<h3 id=\"lbl-lab-completion-criteria-not-met-name\" class=\"lab-name lab-completion-criteria-not-met\">\n					<span class=\"fa fa-fw fa-times-circle text-error\"></span>\n					"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.lab : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n				</h3>\n\n\n"
    + ((stack1 = (helpers.and || (depth0 && depth0.and) || helpers.helperMissing).call(depth0,(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiresGrading : depth0),false,{"name":"eq","hash":{},"data":data}),(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labCompletionCriteriaNotMet",{"name":"config","hash":{},"data":data}),{"name":"and","hash":{},"fn":this.program(2, data, 0),"inverse":this.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n				<div class=\"well completion-criteria-info\">\n					<h4 id=\"lbl-lab-completed-completion-criteria-not-met-title\" class=\"completion-criteria-not-met-description\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,(helpers.concat || (depth0 && depth0.concat) || helpers.helperMissing).call(depth0,"completionCriteria.",(depth0 != null ? depth0.completionStatusForLabCriteria : depth0),{"name":"concat","hash":{},"data":data}),{"name":"$","hash":{},"data":data}))
    + "\n					</h4>\n\n					<dl class=\"dl-horizontal dl-wide completion-criteria-detials\">\n"
    + ((stack1 = (helpers.gt || (depth0 && depth0.gt) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiredMinimumTimeSpent : depth0),0,{"name":"gt","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.gt || (depth0 && depth0.gt) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiredStepsVisitedPercentage : depth0),0,{"name":"gt","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "					</dl>\n				</div>\n";
},"2":function(depth0,helpers,partials,data) {
    var stack1;

  return "					<p id=\"lbl-lab-completed-completion-criteria-not-met-requires-grading-message\" class=\"lab-completion-criteria-not-met-tenant-message\">\n						"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labCompletionCriteriaNotMet",{"name":"config","hash":{},"data":data})) != null ? stack1 : "")
    + "\n					</p>\n";
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(helpers.and || (depth0 && depth0.and) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiresGrading : depth0),(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labCompletionCriteriaNotMetGraded",{"name":"config","hash":{},"data":data}),{"name":"and","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"5":function(depth0,helpers,partials,data) {
    var stack1;

  return "					<p id=\"lbl-lab-completed-completion-criteria-not-met-requires-grading-message\" class=\"lab-completion-criteria-not-met-tenant-message\">\n						"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labCompletionCriteriaNotMetGraded",{"name":"config","hash":{},"data":data})) != null ? stack1 : "")
    + "\n					</p>\n				";
},"7":function(depth0,helpers,partials,data) {
    var stack1;

  return "							<div class=\"time-elapsed-display\">\n								<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.timeElapsedLabel",{"name":"$","hash":{},"data":data}))
    + "</dt>\n								<dd class=\"time-elapsed-value\">\n									"
    + this.escapeExpression((helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.labTimeElapsed : depth0),{"name":"daysHoursMinutes","hash":{"milliseconds":true},"data":data}))
    + "\n									<span "
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.requiredMinimumTimeSpentMet : depth0),{"name":"unless","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n										"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.minimumTimeSpentRequiredLabel",(helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiredMinimumTimeSpent : depth0),{"name":"daysHoursMinutes","hash":{},"data":data}),{"name":"$","hash":{},"data":data}))
    + "\n									</span>\n								</dd>\n							</div>\n";
},"8":function(depth0,helpers,partials,data) {
    return "class=\"text-warning\"";
},"10":function(depth0,helpers,partials,data) {
    var stack1;

  return "							<div class=\"manual-steps-read\">\n								<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.manualStepsReadLabel",{"name":"$","hash":{},"data":data}))
    + "</dt>\n								<dd class=\"manual-steps-read-value\">\n									"
    + this.escapeExpression((helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.stepVisitedPercent : depth0),{"name":"percent","hash":{},"data":data}))
    + "\n									<span "
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.requiredStepsVisitedPercentageMet : depth0),{"name":"unless","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n										"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.stepsVisitedPercentageRequiredLabel",(helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiredStepsVisitedPercentage : depth0),{"name":"percent","hash":{},"data":data}),{"name":"$","hash":{},"data":data}))
    + "\n									</span>\n								</dd>\n							</div>\n";
},"12":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<h1 id=\"lbl-lab-completed-subtitle\">\n					<span class=\"fa fa-fw fa-2x fa-thumbs-o-up\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labCompleted.subtitle",{"name":"$","hash":{},"data":data}))
    + "\n				</h1>\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.requiresGrading : depth0),{"name":"if","hash":{},"fn":this.program(13, data, 0),"inverse":this.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "\n				<h3 id=\"lbl-lab-name\" class=\"lab-name\">\n					<span class=\"fa fa-fw fa-check-circle\"></span>\n					"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.lab : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n				</h3>\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.requiresGrading : depth0),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.program(19, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hasVideoConference : depth0),{"name":"if","hash":{},"fn":this.program(21, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"13":function(depth0,helpers,partials,data) {
    return "					<h2 id=\"lbl-lab-completed-title-grading\">\n						"
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labCompletedTitleGrading","or",(depth0 != null ? depth0.$ : depth0),"labCompleted.titleGrading",{"name":"config","hash":{},"data":data}))
    + "\n					</h2>\n";
},"15":function(depth0,helpers,partials,data) {
    return "					<h2 id=\"lbl-lab-completed-title\">\n						"
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labCompletedTitle","or",(depth0 != null ? depth0.$ : depth0),"labCompleted.title",{"name":"config","hash":{},"data":data}))
    + "\n					</h2>\n";
},"17":function(depth0,helpers,partials,data) {
    var stack1;

  return "					<p id=\"lbl-lab-completed-requires-grading\">\n						"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labCompletedRequiresGrading","or",(depth0 != null ? depth0.$ : depth0),"labCompleted.requiresGrading",{"name":"config","hash":{},"data":data})) != null ? stack1 : "")
    + "\n					</p>\n";
},"19":function(depth0,helpers,partials,data) {
    var stack1;

  return "					<p id=\"lbl-lab-completed-thank-you\">\n						"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"labCompletedThankYou","or",(depth0 != null ? depth0.$ : depth0),"labCompleted.thankYou",{"name":"config","hash":{},"data":data})) != null ? stack1 : "")
    + "\n					</p>\n";
},"21":function(depth0,helpers,partials,data) {
    return "					<div id=\"complete_video_conference\"></div>\n";
},"23":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.lab : depth0)) != null ? stack1.relatedLabs : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":this.program(24, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"24":function(depth0,helpers,partials,data) {
    var stack1;

  return "					<div class=\"related-content\">\n						<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labCompleted.relatedContent",{"name":"$","hash":{},"data":data}))
    + "</h4>\n						<div class=\"skin-text-popout user-data\">\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.lab : depth0)) != null ? stack1.relatedLabs : stack1),{"name":"each","hash":{},"fn":this.program(25, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "						</div>\n					</div>\n";
},"25":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "								<a href=\"#relatedLab/"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-related-lab=\""
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</a>"
    + ((stack1 = helpers.unless.call(depth0,(data && data.last),{"name":"unless","hash":{},"fn":this.program(26, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"26":function(depth0,helpers,partials,data) {
    return "<br> ";
},"28":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<div id=\"transcript\">\n\n					<div>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.ratingEnabled : depth0),{"name":"if","hash":{},"fn":this.program(29, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.showInvite : depth0),{"name":"if","hash":{},"fn":this.program(31, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "					</div>\n\n				</div>\n";
},"29":function(depth0,helpers,partials,data) {
    return "							<div class=\"rate\">\n								<label class=\"text-uppercase\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labCompleted.pleaseRate",{"name":"$","hash":{},"data":data}))
    + ":</label>\n								<div class=\"rating\"></div>\n							</div>\n							<hr/>\n";
},"31":function(depth0,helpers,partials,data) {
    return "							<div class=\"invite\">\n								<button class=\"btn a invite-friend\" data-html=\"true\" data-toggle=\"popover\" data-trigger=\"click\" data-placement=\"top\" data-class=\"popover-invite\">\n									<span class=\"fa fa-4x fa-share-alt-square\"></span><br/>\n									"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labCompleted.shareLab",{"name":"$","hash":{},"data":data}))
    + "\n								</button>\n							</div>\n";
},"33":function(depth0,helpers,partials,data) {
    var stack1;

  return "				<div class=\"survey hide\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.surveyImage : depth0),{"name":"if","hash":{},"fn":this.program(34, data, 0),"inverse":this.program(36, data, 0),"data":data})) != null ? stack1 : "")
    + "				</div>\n";
},"34":function(depth0,helpers,partials,data) {
    var helper;

  return "						<button type=\"button\" class=\"btn btn-no-style img survey-launch\">\n							<img src=\""
    + this.escapeExpression(((helper = (helper = helpers.surveyImage || (depth0 != null ? depth0.surveyImage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"surveyImage","hash":{},"data":data}) : helper)))
    + "\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","labCompleted.takeSurvey",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"alt","labCompleted.takeSurvey",{"name":"$$","hash":{},"data":data}))
    + "/>\n						</button>\n";
},"36":function(depth0,helpers,partials,data) {
    return "						<button type=\"button\" class=\"btn btn-large img survey-launch\">\n							<img src=\"/images/common/survey.png\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","labCompleted.takeSurvey",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"alt","labCompleted.takeSurvey",{"name":"$$","hash":{},"data":data}))
    + "/>\n						</button>\n";
},"38":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.returnUrl : depth0),{"name":"if","hash":{},"fn":this.program(39, data, 0),"inverse":this.program(41, data, 0),"data":data})) != null ? stack1 : "");
},"39":function(depth0,helpers,partials,data) {
    var helper;

  return "					<a href=\""
    + this.escapeExpression(((helper = (helper = helpers.returnUrl || (depth0 != null ? depth0.returnUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"returnUrl","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-large return-to-home\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labExpired.returnRedirect",{"name":"$","hash":{},"data":data}))
    + "\n					</a>\n";
},"41":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.externalSite : depth0),{"name":"if","hash":{},"fn":this.program(42, data, 0),"inverse":this.program(44, data, 0),"data":data})) != null ? stack1 : "");
},"42":function(depth0,helpers,partials,data) {
    return "						<button class=\"btn btn-large external-return external\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labExpired.returnExternal",{"name":"$","hash":{},"data":data}))
    + "\n						</button>\n";
},"44":function(depth0,helpers,partials,data) {
    return "						<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"page":"HOME"},"data":data}))
    + "\" class=\"btn btn-large return-to-home\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labExpired.returnHome",{"name":"$","hash":{},"data":data}))
    + "\n						</a>\n";
},"46":function(depth0,helpers,partials,data) {
    return "				<button type=\"button\" class=\"btn btn-large logout\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.logOut",{"name":"$","hash":{},"data":data}))
    + "\n				</button>\n";
},"48":function(depth0,helpers,partials,data) {
    return "		<div class=\"msg-beta\">\n			<p>\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"betaMessage","http://www.vmwarelearningplatform.com/about",{"name":"$","hash":{},"data":data}))
    + "\n			</p>\n		</div>\n";
},"50":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "						<div class=\"survey-promo-text\">"
    + ((stack1 = ((helper = (helper = helpers.surveyPromoText || (depth0 != null ? depth0.surveyPromoText : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"surveyPromoText","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n";
},"52":function(depth0,helpers,partials,data) {
    var stack1;

  return "						<div class=\"survey-image\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.surveyImage : depth0),{"name":"if","hash":{},"fn":this.program(53, data, 0),"inverse":this.program(55, data, 0),"data":data})) != null ? stack1 : "")
    + "						</div>\n";
},"53":function(depth0,helpers,partials,data) {
    var helper;

  return "								<button type=\"button\" class=\"btn btn-no-style img survey-start\">\n									<img src=\""
    + this.escapeExpression(((helper = (helper = helpers.surveyImage || (depth0 != null ? depth0.surveyImage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"surveyImage","hash":{},"data":data}) : helper)))
    + "\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","labCompleted.takeSurvey",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"alt","labCompleted.takeSurvey",{"name":"$$","hash":{},"data":data}))
    + "/>\n								</button>\n";
},"55":function(depth0,helpers,partials,data) {
    return "								<button type=\"button\" class=\"btn btn-large img survey-start\">\n									<img src=\"/images/common/survey.png\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","labCompleted.takeSurvey",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"alt","labCompleted.takeSurvey",{"name":"$$","hash":{},"data":data}))
    + "/>\n								</button>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"lab-complete\">\n\n	<div class=\"top success clearfix\">\n		<div class=\"interior\">\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.completionCriteriaMet : depth0),{"name":"unless","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "\n\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hasCredits : depth0),{"name":"if","hash":{},"fn":this.program(23, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.showOtherSection : depth0),{"name":"if","hash":{},"fn":this.program(28, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.surveyUrl : depth0),{"name":"if","hash":{},"fn":this.program(33, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hasCredits : depth0),{"name":"if","hash":{},"fn":this.program(38, data, 0),"inverse":this.program(46, data, 0),"data":data})) != null ? stack1 : "")
    + "\n		</div>\n	</div>\n\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"showVlpInBetaMsg",{"name":"config","hash":{},"fn":this.program(48, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n	<div class=\"bottom\">\n\n		<div class=\"left announcement-container\">\n			<div class=\"header-bar inverted\">\n				<h3>\n					<span class=\"fa fa-bullhorn skin-icon\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"announcements.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n\n			<div id=\"announcements\">\n				<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"announcements.loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n			</div>\n		</div>\n\n		<div class=\"right offers-container\">\n			<div class=\"header-bar inverted\">\n				<h3>\n					<span class=\"fa fa-comments skin-icon\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"offers.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n\n			<div id=\"offers\">\n				<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"offers.loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n			</div>\n		</div>\n	</div>\n\n</div>\n\n\n<div id=\"modal_survey\" class=\"modal fade global hide\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labCompleted.takeSurvey",{"name":"$","hash":{},"data":data}))
    + "</h3>\n			</div>\n			<div class=\"modal-body\">\n				<div class=\"survey-promo hide\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.surveyPromoText : depth0),{"name":"if","hash":{},"fn":this.program(50, data, 0),"inverse":this.program(52, data, 0),"data":data})) != null ? stack1 : "")
    + "					<div class=\"survey-image\">\n						<button type=\"button\" class=\"btn btn-large survey-start\">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labCompleted.takeSurvey",{"name":"$","hash":{},"data":data}))
    + "\n						</button>\n					</div>\n\n				</div>\n\n				<div class=\"survey-frame\">\n					<iframe></iframe>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div id=\"modal_lab_landing\" class=\"modal fade global hide fluid-height\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"labCompleted.relatedLab",{"name":"$","hash":{},"data":data}))
    + "</h3>\n\n			</div>\n			<div class=\"modal-body\">\n				<div id=\"lab_landing\" class=\"clearfix\"></div>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('console_complete', t);
return t;
});

