
define('hbs!tpls/console/walkthrough/step-4.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var helper;

  return "<div class=\"console-walkthrough-title\">\n	<button class=\"btn btn-default console-walkthrough-exit\">&times;</button>\n	<span class=\"console-walkthrough-step-number\">"
    + this.escapeExpression(((helper = (helper = helpers.stepNumber || (depth0 != null ? depth0.stepNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"stepNumber","hash":{},"data":data}) : helper)))
    + "</span>\n	"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labManual.title",{"name":"$","hash":{},"data":data}))
    + "\n</div>\n\n<div class=\"console-walkthrough-content step-"
    + this.escapeExpression(((helper = (helper = helpers.stepNumber || (depth0 != null ? depth0.stepNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"stepNumber","hash":{},"data":data}) : helper)))
    + "\">\n	<dl>\n		<dt>\n			<span class=\"fa fa-lg fa-fw fa-book\"></span>\n		</dt>\n		<dd>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labManual.manual",{"name":"$","hash":{},"data":data}))
    + "\n		</dd>\n		<dt>\n			<span class=\"fa fa-lg fa-fw fa-times\"></span>\n		</dt>\n		<dd>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labManual.manualClose",{"name":"$","hash":{},"data":data}))
    + "\n		</dd>\n		<dt>\n			<span class=\"fa fa-lg fa-fw fa-external-link\"></span>\n		</dt>\n		<dd>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labManual.dockFloat",{"name":"$","hash":{},"data":data}))
    + "\n		</dd>\n		<dt class=\"carets\">\n			<span class=\"fa fa-lg fa-fw fa-angle-left\"></span>\n			<span class=\"fa fa-lg fa-fw fa-angle-right\"></span>\n		</dt>\n		<dd>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labManual.paging",{"name":"$","hash":{},"data":data}))
    + "\n		</dd>\n		<dt>\n			<span class=\"fa fa-lg fa-fw fa-font\"></span>\n		</dt>\n		<dd>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labManual.fontSize",{"name":"$","hash":{},"data":data}))
    + "\n		</dd>\n	</dl>\n\n	<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labManual.moreOptions",{"name":"$","hash":{},"data":data}))
    + "</h4>\n\n	<dl>\n		<dt>\n			<span class=\"fa fa-lg fa-fw fa-sitemap\"></span>\n		</dt>\n		<dd>\n			<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labManual.networkTopology.title",{"name":"$","hash":{},"data":data}))
    + ": </strong>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labManual.networkTopology.description",{"name":"$","hash":{},"data":data}))
    + "\n		</dd>\n		<dt>\n			<span class=\"fa fa-lg fa-fw fa-list\"></span>\n		</dt>\n		<dd>\n			<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labManual.tableOfContents.title",{"name":"$","hash":{},"data":data}))
    + ": </strong>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labManual.tableOfContents.description",{"name":"$","hash":{},"data":data}))
    + "\n		</dd>\n		<dt>\n			<span class=\"fa fa-lg fa-fw fa-columns\"></span>\n		</dt>\n		<dd>\n			<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labManual.splitScreen.title",{"name":"$","hash":{},"data":data}))
    + ": </strong>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"consoleWalkthrough.steps.labManual.splitScreen.description",{"name":"$","hash":{},"data":data}))
    + "\n		</dd>\n	</dl>\n</div>\n\n<div class=\"console-walkthrough-actions\">\n	<button class=\"btn btn-primary console-walkthrough-prev\"><span class=\"fa fa-va fa-lg fa-caret-left\"></span> "
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"previous",{"name":"$","hash":{},"data":data}))
    + "</button>\n	<button class=\"btn btn-primary console-walkthrough-next\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"next",{"name":"$","hash":{},"data":data}))
    + " <span class=\"fa fa-va fa-lg fa-caret-right\"></span></button>\n</div>";
},"useData":true});
Handlebars.registerPartial('console_walkthrough_step_4', t);
return t;
});

