define('views/catalogs/entitlement-life-cycle',['require','underscore','library/vlp/view','hbs!tpls/catalogs/entitlement-life-cycle.handlebars'],function (require) {
	"use strict";

	var _ = require("underscore");

	var BaseView = require("library/vlp/view"),
		tpl      = require("hbs!tpls/catalogs/entitlement-life-cycle.handlebars");

	return BaseView.extend({
		name     : "ENTITLEMENT-LIFE-CYCLE",
		template : tpl,
		className: "entitlement-life-cycle",
		events   : {},

		initialize: function (options) {
			_.bindAll(this);
			this.options = options || {};

			this.listenTo(this.model, "change", this.valuesChanged);

			this.render();
		},

		valuesChanged: function(){
			this.render();
		}

	});

});

