
define('hbs!tpls/instructor/entitlement.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var helper;

  return "		<button type=\"button\" class=\"student-connection-strength level-0\" rel=\"popover\" data-trigger=\"click\" data-placement=\"bottom\" data-html=\"true\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","instructor.entitlement.studentConnection.title",{"name":"$$","hash":{},"data":data}))
    + " data-content-target=\"#popover_content_connection_stats_"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-class=\"connection-stats-popover\">\n			<span class=\"indicator\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","instructor.entitlement.studentConnection.strength",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"top\">\n				<span class=\"bar bar-1\"></span>\n				<span class=\"bar bar-2\"></span>\n				<span class=\"bar bar-3\"></span>\n				<span class=\"bar bar-4\"></span>\n				<span class=\"bar bar-5\"></span>\n			</span>\n		</button>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"entitlement-actions\">\n	<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"{{classId}}/console/{{id}}"},"data":data}))
    + "\" class=\"open-console btn btn-no-style\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","instructor.entitlements.openConsole",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"top\" data-trigger=\"hover\">\n		<i class=\"fa fa-desktop\"></i>\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.entitlements.openConsole",{"name":"$","hash":{},"data":data}))
    + "\n	</a>\n	<span "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","instructor.entitlements.thumbnailTooltip",{"name":"$$","hash":{},"data":data}))
    + " rel=\"tooltip\">\n		<button type=\"button\" class=\"btn btn-no-style show-thumbnail\">\n			<i class=\"fa fa-th-large\"></i>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.entitlements.showThumbnail",{"name":"$","hash":{},"data":data}))
    + "\n		</button>\n	</span>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.studentConsoleConnectionMonitoring : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n	<span class=\"phase\"></span>\n</div>\n\n<div class=\"student-info\">\n	<div class=\"student-info-tile vlp-col-md-12 vlp-col-sm-12 vlp-col-lg-12 vlp-col-xlg-12 vlp-col-xs-12\">\n		<label class=\"lab-name\">"
    + this.escapeExpression(((helper = (helper = helpers.labName || (depth0 != null ? depth0.labName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"labName","hash":{},"data":data}) : helper)))
    + "</label>\n		<div class=\"student-info-id\">\n			<a data-username=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.username : stack1), depth0))
    + "\" class=\"public-profile\" data-id=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" data-name=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\">\n				<img class=\"student-info-images\" src=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.avatar : stack1), depth0))
    + "\" alt=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\">\n			</a>\n\n			<a data-username=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.username : stack1), depth0))
    + "\" class=\"student-info-student-name public-profile\" data-id=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" data-name=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\">"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.account : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "</a>\n		</div>\n\n		<div id=\"progressbar\" class=\"student-info-progress\">\n			<div class=\"student-info-progressbar\">\n				<div class=\"progress-bar\" style=\"width: "
    + this.escapeExpression(((helper = (helper = helpers.progress || (depth0 != null ? depth0.progress : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"progress","hash":{},"data":data}) : helper)))
    + "%;\">\n					<span class=\"progress\">"
    + this.escapeExpression((helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.progress : depth0),{"name":"percent","hash":{},"data":data}))
    + "</span>\n				</div>\n			</div>\n\n			<label><span class=\"completed-steps\">"
    + this.escapeExpression(((helper = (helper = helpers.activeStepNumber || (depth0 != null ? depth0.activeStepNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"activeStepNumber","hash":{},"data":data}) : helper)))
    + "</span>/<span class=\"total-steps\">"
    + this.escapeExpression(((helper = (helper = helpers.totalSteps || (depth0 != null ? depth0.totalSteps : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"totalSteps","hash":{},"data":data}) : helper)))
    + "</span></label>\n			<i class=\"fa fa-book\"></i>\n		</div>\n	</div>\n</div>\n\n<div class=\"hide\">\n	<div id=\"popover_content_connection_stats_"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n		<div class=\"hide student-latency-unstable alert alert-warning alert-small alert-icon\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.entitlement.studentConnection.unstable",{"name":"$","hash":{},"data":data}))
    + "</div>\n		<div class=\"hide student-connection-lost alert alert-error alert-small alert-icon\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.entitlement.studentConnection.connectionLost",{"name":"$","hash":{},"data":data}))
    + "</div>\n		<ul>\n			<li>\n				<span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.current",{"name":"$","hash":{},"data":data}))
    + ":</span>\n				<span class=\"value\"><span class=\"student-latency-current\">0</span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.milliseconds",{"name":"$","hash":{},"data":data}))
    + "</span>\n			</li>\n			<li>\n				<span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.average",{"name":"$","hash":{},"data":data}))
    + ":</span>\n				<span class=\"value\"><span class=\"student-latency-average\">0</span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.milliseconds",{"name":"$","hash":{},"data":data}))
    + "</span>\n			</li>\n			<li>\n				<span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.high",{"name":"$","hash":{},"data":data}))
    + ":</span>\n				<span class=\"value\"><span class=\"student-latency-high\">0</span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.milliseconds",{"name":"$","hash":{},"data":data}))
    + "</span>\n			</li>\n			<li>\n				<span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.low",{"name":"$","hash":{},"data":data}))
    + ":</span>\n				<span class=\"value\"><span class=\"student-latency-low\">0</span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.latency.milliseconds",{"name":"$","hash":{},"data":data}))
    + "</span>\n			</li>\n		</ul>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('instructor_entitlement', t);
return t;
});

