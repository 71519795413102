
define('hbs!tpls/console/vm-shortcut-keys-popup.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    return "<div id=\"popover_content_shortcut_keys\">\n	<div class=\"shortcuts\">\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[17, 18, 46]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.ctrlAltDel",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[17, 115]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.ctrlF4",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[18, 115]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.altF4",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[17, 9]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.ctrlTab",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[18, 9]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.altTab",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[18, 27]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.altEsc",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[17, 78]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.ctrlN",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[17, 84]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.ctrlT",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[17, 87]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.ctrlW",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[91, 68]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.winD",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[42]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.printScreen",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[19]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.pause",{"name":"$","hash":{},"data":data}))
    + "</button>\n	</div>\n	<div class=\"f-keys\">\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[112]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.f1",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[113]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.f2",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[114]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.f3",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[115]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.f4",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[116]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.f5",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[117]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.f6",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[118]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.f7",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[119]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.f8",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[120]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.f9",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[121]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.f10",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[122]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.f11",{"name":"$","hash":{},"data":data}))
    + "</button>\n		<button class=\"btn btn-mini send-shortcut-keys\" data-key-codes=\"[123]\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.shortcutKeys.shortcuts.f12",{"name":"$","hash":{},"data":data}))
    + "</button>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('console_vm_shortcut_keys_popup', t);
return t;
});

