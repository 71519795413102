define('views/catalogs/catalog',['require','underscore','library/vlp/view','views/catalogs/lab-listing','hbs!tpls/catalogs/catalog.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//class dependencies
	var BaseView          = require("library/vlp/view"),
	    LabListingView    = require("views/catalogs/lab-listing"),
	    CatalogInfoTPL    = require("hbs!tpls/catalogs/catalog.handlebars");


	return BaseView.extend({
		template : CatalogInfoTPL,
		unwrap   : true,

		labPageNum    : 1,

		searchValue : "",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"submit .form-search"               : "submitSearch",
			"search .form-search [name=search]" : "submitSearch",
			"keyup  .form-search [name=search]" : "search"
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			options = options || {};

		},
		serialize : function(){
			var data = this.model.toHandlebars();
			return data;
		},
		beforeRender : function(){
			this.$("[rel=tooltip]").tooltip("hide");
		},
		afterRender : function(){
			this.$("[rel=tooltip]").tooltip();
			if(this.labListingView){
				this.labListingView.setElement(this.$(".labs-holder"));
			}
		},

		show : function(labPageNum){
			if(labPageNum !== undefined){
				this.labPageNum = labPageNum;
			}

			if(!this.rendered){
				this.render();
			}

			if(!this.labListingView){
				this.labListingView = new LabListingView({
					el         : this.$(".labs-holder"),
					catalog    : this.model,
					collection : this.model.get("labs")
				});
			} else{
				this.labListingView.setElement(this.$(".labs-holder"));
			}

			this.labListingView.show(this.labPageNum);
		},

		submitSearch : function(event){


			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			this.searchValue = this.$("[name=search]").val();
			this.labListingView.search(this.searchValue || "");
		},
		search : function(event){


			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			var self = this;
			if(!this._throttledSearch){
				this._throttledSearch = _.debounce(function(){
					self.$(".nav-bar").toggle(self.searchValue === "");
					if(self.labListingView){
						self.labListingView.search(self.searchValue || "");
					}
				}, 1000);
			}

			var searchValue = this.$("[name=search]").val();
			if(self.searchValue != searchValue){
				this.searchValue = searchValue;
				this._throttledSearch();
			}
		},

		applySearch : function(search){
			var count = 0;
			if(this.labListingView){
				count+= this.labListingView.applySearch(search);
			}
			return count;
		},
		remove : function(){
			if(this.labListingView){
				this.labListingView.remove();
				this.labListingView = null;
			}
			BaseView.prototype.remove.call(this);
		}

	});

});
