/* globals WMKS */
define('views/console/vm-console',['require','jquery','underscore','library/vlp/app','library/vlp/view','models/vm','views/console/console-commands','views/console/vm-settings-prompts','hbs!tpls/console/vm-console.handlebars','utilities/browser','library/utilities/screenfull','hbs!tpls/console/vm-shortcut-keys-popup.handlebars','library/wmks'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App                   = require("library/vlp/app"),
	    BaseView              = require("library/vlp/view"),
	    Vm                    = require("models/vm"),
	    ConsoleCommandsView   = require("views/console/console-commands"),
	    VmSettingsPromptsView = require("views/console/vm-settings-prompts"),
	    vmTPL                 = require("hbs!tpls/console/vm-console.handlebars"),
	    Browser               = require("utilities/browser"),
	    Screenfull            = require("library/utilities/screenfull");

	require("hbs!tpls/console/vm-shortcut-keys-popup.handlebars");
	require("library/wmks");


	//This is our PING send
	WMKS.widgetProto.ping = function(){
		this._vncDecoder._sendClientEncodingsMsg();
	};

	WMKS.widgetProto.getVNCDecoder = function(){
		return this._vncDecoder;
	};
	WMKS.widgetProto.requestResolution = function(width, height){
		this._vncDecoder.onRequestResolution(width, height);
	};
	WMKS.widgetProto.sendKey = function(key, isUp, isUnicode ,isForceScancode){
		this._keyboardManager.sendKey(key, isUp, isUnicode ,isForceScancode);
	};
	WMKS.widgetProto.clearKeyboardState = function(){
		this._keyboardManager.clearState();
	};
	$.widget("wmks.wmks", WMKS.widgetProto);

	return BaseView.extend({
		template : vmTPL,

		POWERED_ON     : "powered-on",
		POWERED_DOWN   : "powered-down",
		LOADING        : "loading",
		CANNOT_CONNECT : "cannotConnect",
		UNAVAILABLE    : "unavailable",

		STATE_KEY    : "vmState",
		panelPadding : 44,

		centeredWidthPadding  : 125,
		centeredHeightPadding : 160,

		floatWidthPadding   : 135,
		floatHeightPadding  : 170,
		floatPanelPadding   : 10,
		floatMaxWidth       : 1400,

		testConsoleWidth  : 640,
		testConsoleHeight : 480,
		testConsole       : false,
		useUint8Utf8      : false,

		expectingPong   : false,
		reschedulePing  : false,
		pingStart       : null,
		lastWmksPacket  : null,
		lastDelayedPings: [],

		_paused : false,

		screenStatus : "normal",
		consoleType : "vm",
		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		//el : "#vm",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .vm-power-down"           : "powerOff",
			"click .power-on, .powered-down" : "powerOn",
			"click .vm-power-reset"          : "powerReset",
			"click .maximize"                : "maximize",
			"click .vm-maximize"             : "maximize",
			"click .vm-full-screen"          : "toggleFullscreen",
			"click .full-screen"             : "toggleFullscreen",
			"click .control-alt-del"         : "sendControlAltDelete",
			"click .send-shortcut-keys"      : "sendShortcutKeys",
			"click .vm-float"                : "float",
			"click .vm-dock-left"            : "dockLeft",
			"click .vm-dock-right"           : "dockRight",
			"click .vm-dock-center"          : "dockCenter",
			"click .screen-retry"            : "refreshConsole",
			"click .quick-send-text"         : "onQuickSendText",
			"click .vm-refresh"              : "refreshConsole",
			"click .page-refresh"            : "refreshPage",
			"dragenter canvas"               : "onDragEnter",
			"dragleave canvas"               : "onDragLeave",
			"dragover canvas"                : "onDragOver",
			"drop canvas"                    : "onDropText",

			"show .connection-strength"         : "onConnectionStrengthShow",
			"show .student-connection-strength" : "onConnectionStrengthShow",
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			options = options || {};
			this.panelManager = options.panelManager;
			this.instructionsPanel = this.panelManager.getPanel("#instructions_panel");

			this.entitlement = options.entitlement;
			this.entitlementView = options.entitlementView;

			var initialPosition = (!App.config.defaultConsolePosition || App.config.defaultConsolePosition === "maximized" ? "center" : App.config.defaultConsolePosition);
			var maximized = (App.config.defaultConsolePosition === "maximized");

			//initialize state
			this.state = {
				maximize : maximized,
				resizing : false,
				position : initialPosition
			};

			//this.saveStateKey = this.STATE_KEY + "-" + this.entitlement.get("id");

			var data = {};
			/*
			if(App.config.saveConsolePanelPlacement){
				data = App.store.get(this.saveStateKey);
			}
			*/
			this.state = _.extend(this.state, data);
			this.state.resizing   = false;


			this.ticketCounter = 0;
			this._throttledRefresh     = _.throttle(this.refreshConsole, App.config.screenTicketDelay, {trailing : false});
			this._throttledShowNotes   = _.debounce(this.showNotes, 1000);
			this._debounceWindowResize = _.debounce(this.windowResized, 1000);

			this.listenTo(this.entitlement, {
				"change:screenStatus change:takenOver change:supportViewing change:instructorViewing": this.onScreenStatusChanged,
				"change:fixANSI"                   : this.onFixANSIChanged,
				"change:keyboardLayoutId"          : this.onKeyboardLayoutIdChanged,
				"change:reverseScrollY"            : this.onReverseMouseChanged,
				"change:macOsRemapCommandToControl": this.onMacOsRemapCommandToControlChanged,
				"change:consoleLatencyCurrent"     : this.onConsoleLatencyCurrentChanged,
				"change:consoleLatencyAverage"     : this.onConsoleLatencyAverageChanged,
				"change:consoleLatencyLow"         : this.onConsoleLatencyLowChanged,
				"change:consoleLatencyHigh"        : this.onConsoleLatencyHighChanged,
				"change:consoleLatencyWarning"     : this.onConsoleLatencyWarningChanged,
				"change:consoleLatencyUnstable"    : this.onConsoleLatencyUnstableChanged,
				"change:consoleConnectionLost"     : this.onConsoleConnectionLostChanged
			});

			for(var i = 0; i < this.panelManager.panels.length; i++){
				var panel = this.panelManager.panels[i];
				this.listenTo(panel, "panel:expand panel:close panel:dock panel:float panel:show panel:hide", this.onPanelMoved);
			}

			WMKS.LOGGER.setLogLevel(WMKS.LOGGER.LEVEL.INFO);
		},
		serialize : function(){
			var data = this.model.toHandlebars();
			data.allowFullscreen = Browser.support.fullscreen;
			data.showConsoleButtons = App.config.showConsoleButtons;
			data.entitlement = this.entitlement.toHandlebars();
			return data;
		},
		/**
		 * Display the content.
		 */
		render : function(options){
			options = options || {};

			var self = this;
			if(this.$("#vm_console").length){
				//previously rendered
				this.stopVM();
			}

			if(this.model){
				this.model
					.off("change:status", this.statusChanged)
					.on("change:status",   this.statusChanged);

				this.$el.html(this.template(this.serialize()));
				if(!Browser.safari){
					this.$("#browser_fullscreen_warning").remove();
				}
				this.$vmConsole     = this.$("#vm_console").empty();

				this.$vmGroup          = this.$("#vm_group");
				this.$vmConsoleWrapper = this.$("#vm_console_wrapper_inner");

				this.$pasteCatcher = this.$("#paste_catcher");

				this.displayState(this.LOADING);
				this.setupPosition(true);

				this.statusChanged();

				this.ticketCounter = 0;

				this.consoleCommandsView = new ConsoleCommandsView({
					vmConsoleView : this,
					entitlement : this.entitlement,
					vm : this.model,
					el : this.$("#console_commands")
				});
				this.consoleCommandsView.render();

				this.vmSettingsPromptsView = new VmSettingsPromptsView({
					vmConsoleView : this,
					model : this.entitlement,
					vm : this.model,
					el : this.$("#vm_settings_prompts")
				});
				this.vmSettingsPromptsView.render();

				if(this.model.get("status") === Vm.POWERED_ON){
					this.setupVM(true);
				}
				this.$("[rel=popover]").popover();
				this.$("[rel=tooltip]").tooltip();

				this.$(".toggle-panel-left").tooltip({
					placement : "bottom",
					container : "body",
					title     : function(){ return self.entitlementView.getTogglePanelTitle("left"); },
					trigger   : "hover"
				});
				this.$(".toggle-panel-right").tooltip({
					placement : "left",
					container : "body",
					title     : function(){ return self.entitlementView.getTogglePanelTitle("right"); },
					trigger   : "hover"
				});

				this.$(".note").off("shown.vm").on("shown.vm", function(){
					self.$("[rel=tooltip]").tooltip();
				});

				this.onScreenStatusChanged();
			} else{
				console.warn("No console loaded");
			}
			//Always return this for chaining
			return this;
		},
		refresh : function(){
			if(this.consoleCommandsView){
				this.consoleCommandsView.hideModals();
			}

			if(this.model) {
				this.model.unset("ticket");
				this.setupVM(true);
			}
		},
		remove : function(){
			this.unload();
			if(this.consoleCommandsView){
				this.consoleCommandsView.remove();
			}
			if(this.vmSettingsPromptsView) {
				this.vmSettingsPromptsView.remove();
			}
			BaseView.prototype.remove.call(this);
		},
		unload : function(){
			clearTimeout(this.powerOnTimeout);
			clearTimeout(this.wmksTimeout);
			clearTimeout(this.resetResizeRequestTimeout);

			if(this.$vmGroup){
				this.$vmGroup.off("dragstop.vm drag.vm");
			}
			this.stopListening();
			this.stopVM();

			$(window).off("resize.vm");
			if(this.$pasteCatcher){
				this.$pasteCatcher.off("paste.vm");
			}
			if(this.consoleCommandsView) {
				this.consoleCommandsView.unload();
			}
		},
		powerOff : function(event){
			if(!this.entitlement.get("userActionAllowed")) { return; }

			if(this.consoleCommandsView){
				this.consoleCommandsView.hideModals();
			}
			var self = this;

			this._poweringOn = false;

			this.stopVM();
			this.displayState(this.LOADING);

			this.model.powerOff({
				success : function(){
					self.model.unset("ticket");
					self.displayState(self.POWERED_DOWN);
					self.render();
				},
				error : function(model, response){
					alert(App.i18n("errors.powerDownVM"));
				},
				beforeSend : function(){
					self.$(".power-down").button("loading");
				},
				complete : function(){
					self.$(".power-down").button("reset");
				}
			});
		},
		powerOn : function(event){
			if(!this.entitlement.get("userActionAllowed")) { return; }

			var self = this;

			this.$("#modal_vm_power_down").modal("hide");
			this.displayState(this.LOADING);


			if(self.powerOnTimeout){
				clearTimeout(self.powerOnTimeout);
			}
			this._poweringOn = true;
			this.ticketCounter = 0;
			var model = this.model;
			this.model.powerOn({
				success : function(){
					self.model.unset("ticket");

					self.displayState(self.LOADING);
					_.delay(function(){
						// Check that the VM hasn't changed since the powerOn was called.
						if(model == self.model){
							self.setupVM();
						}
					}, App.config.screenTicketDelay);
				},
				error : function(model, response){
					self.displayState(self.POWERED_DOWN);
					alert(App.i18n("errors.powerUpVM"));
				},
				beforeSend : function(){
					self.$(".power-on").button("loading");
				},
				complete : function(){
					self.$(".power-on").button("reset");
				}
			});
		},
		powerReset : function(event){
			if(!this.entitlement.get("userActionAllowed")) { return; }

			var self = this;

			if(this.consoleCommandsView){
				this.consoleCommandsView.hideModals();
			}

			this.$("#modal_vm_power_reset").modal("hide");
			this.ticketCounter = 0;

			this.stopVM();
			this.displayState(this.LOADING);

			var model = this.model;
			this._poweringOn = true;
			this.model.powerReset({
				success : function(){
					self.model.unset("ticket");

					self.displayState(self.LOADING);
					_.delay(function(){
						// Check that the VM hasn't changed since the reset was called.
						if(model == self.model){
							self.setupVM();
						}
					}, App.config.screenTicketDelay);
				},
				error : function(model, response){
					alert(App.i18n("errors.resetVM"));
				},
				beforeSend : function(){
					self.$(".power-reset").button("loading");
				},
				complete : function(){
					self.$(".power-reset").button("reset");
				}
			});
		},
		refreshConsole : function(event) {
			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			if(this.consoleCommandsView) {
				this.consoleCommandsView.ready(false);
			}

			this.ticketCounter = 0;
			if(this.model){
				this.model.unset("ticket");
				if(this.model.get("status") !== Vm.POWERED_OFF){
					this.setupVM();
				}
			}
		},
		refreshPage : function() {
			App.reload();
		},
		setupVM : function(now){

			this.displayState(this.LOADING);

			if(this.model.get("host") && this.model.get("ticket")){
				this.startVM();
			} else{
				if(now || this.ticketCounter === 0 || this.ticketCounter < App.config.screenTicketMaxTries){
					this.getScreenTicket();
				} else {
					this.displayState(this.UNAVAILABLE);
				}
			}
		},

		setModel : function(model){
			if(this.model && model === this.model){
				//same model, nothing to do
				return;
			}

			if(this.model){
				//cleaning up old model
				this.stopVM();
				//if screenTicketPending for the old model, connectionInfoChange will still be for the result of
				//getScreenTicket and will update the info, set screenTicketPending to false, and clear the listener.
			}

			this.model = model;

			if(this.model){
				//if screenTicketPending for new model, the previous getScreenTicket call will complete
				//and call connectionInfoChange preventing the redundant call

				//Reset ticket to force screenTicket call each change.
				this.model.unset("ticket");

				//Force resize in case window has changed.
				this.model.unset("dockedWidth");
			}
			this.render();

		},
		getScreenTicket : function(){
			var self = this;


			if(this.model.get("screenTicketPending")){
				//Already waiting on previous screenTicket call. Simple locking mechanism.
				return;
			}

			this.displayState(this.LOADING);

			this.model.set("screenTicketPending", true);

			this.stopListening(this.model, null, this.connectionInfoChange);
			this.listenTo(this.model, "change:host change:vmx change:port change:ticket", this.connectionInfoChange);

			this.model.unset("ticket");

			//no success call, connectionInfoChange will pickup changes
			this.model.screenTicket({
				//success : this.startVM,
				error : function(model, response){

					self.stopListening(self.model, null, self.connectionInfoChange);
					model.set("screenTicketPending", false);

					//Normalized from push or REST
					response = response.response || response;

					if((response && response.errorCode === 1440) || self._poweringOn){
						//Will call getScreenTicket when powerOn succeeds.
						self.powerOnTimeout = setTimeout(self.powerOn, 5000);
					} else if(response && response.errorCode === 3402) {
						self.model.set("status", Vm.POWERED_OFF);
					} else{

						if(response){
							var message = response.message;
							if(response.data && response.data.exceptionMessage){
								message = response.data.exceptionMessage;
							}
							App.mainView.generalMessage({
								message       : _.escapeHTML(message) || App.i18n("errors.general"),
								type          : "error",
								autoRemove    : true
							});
						}
						self.model.fetch({
							success : function(){
								self.model.unset("ticket");
								if(self.model.get("status") === Vm.POWERED_ON){
									_.delay(self.setupVM, App.config.screenTicketDelay);
								} else{
									self.statusChanged();
								}
							}
						});
					}
				}
			});
			this.ticketCounter++;
		},

		/**
		 * "Start" the VM: connect with WebMKS
		 */
		startVM : function(){
			var self = this;

			if(!this.model.get("host") || !this.model.get("ticket")){
				return;
			}

			if(this._connectionUrl && this._connectionUrl === this.model.get("connectionUrl")){
				//Don't reconnect if already connected to this ticket
				return;
			}

			if(this._paused){ return; }

			this._connectionUrl = this.model.get("connectionUrl");

			this.displayState(this.LOADING);
			this.stopVM();

			if(this.model.get("ticket") === "test"){
				return this.showTestConsole();
			} else if(this.testConsole){
				this.hideTestConsole();
			}

			if(!this._wmksConnectAttempt){
				this._wmksConnectAttempt = 1;
			}

			if(this.ticketTimeout){
				clearTimeout(this.ticketTimeout);
				this.ticketTimeout = null;
			}
			if(this.wmksTimeout){
				clearTimeout(this.wmksTimeout);
				this.wmksTimeout = null;
			}

			this.wmksTimeout = setTimeout(this.connectionFailure, App.config.vmConnectionWait);


			var url = "wss://" + self.model.get("host") + "/"+ self.model.get("port") +";" + self.model.get("ticket");
			var vmx = self.model.get("vmx");

			this.entitlement.set({
				activeScreenTicket            : url,
				activeVmx                     : vmx,
				activeScreenTicketUpdatedTime : Date.now()
			});

			this.$vmConsole.empty();
			this.$vmConsole.wmks({
				VCDProxyHandshakeVmxPath : vmx,
				fixANSIEquivalentKeys    : this.entitlement.get("fixANSI"),
				keyboardLayoutId         : this.entitlement.get("keyboardLayoutId"),
				useUnicodeKeyboardInput  : App.config.consoleKeyboardUseUnicodeInput,
				disableVscanKeyboard     : !App.config.consoleKeyboardUseAltManager,
				enableWindowsKey         : App.config.consoleKeyboardEnableWindowsKey,
				useNativePixels          : App.config.consoleUseNativePixels,
				allowMobileKeyboardInput : App.config.consoleKeyboardAllowMobileInput,
				enableNextGenerationMouse: true,
				enableUint8Utf8          : this.useUint8Utf8,
				enableVVC                : App.config.consoleEnableVVC,
				reverseScrollY           : this.entitlement.get("reverseScrollY"),
				mapMetaToCtrlForKeys     : (this.entitlement.get("macOsRemapCommandToControl") && this.model.get("osType") !== "apple" ? App.config.macOsRemapCommandToControlKeys : []),
				mapMetaToCtrlForVScans   : (this.entitlement.get("macOsRemapCommandToControl") && this.model.get("osType") !== "apple" ? _.keyCodesToScanCodes(App.config.macOsRemapCommandToControlKeys) : []),
				locale                   : App.locale.get().toLowerCase(),
				sessionName              : this.model.get("name")
			});

			this.wmksPatch();

			try{
				this.$vmConsole.wmks("connect", url);

				this.$vmConsole.on("wmkserror.vm", function(){
					if(self.useUint8Utf8){
						console.log("WMKS.error", "previously tried with Uint8Utf8 support");
						self.useUint8Utf8 = false;
						self.connectionFailure();
					} else {
						console.log("WMKS.error", "retrying with Uint8Utf8 support");
						self.retryWithUtf8Uint8();
					}
				});
				this.$vmConsole.on("wmksconnected.vm", function(){
					self.$vmConsole.off("wmkserror.vm");
					self._poweringOn = false;
					clearTimeout(self.wmksTimeout);
					self.wmksTimeout = null;
					self._wmksConnectAttempt = null;
					self.displayState(self.POWERED_ON);

					$(window).off("resize.vm").on("resize.vm", self.windowResizing);
					//Listen for disconnects and refresh screenTicket/webmks connection
					self.$vmConsole.on("wmksdisconnected.vm", self.wmksDisconnected);

					self.wmksStartPing();
					self.wmksSetupEvents();
					if(self.consoleCommandsView) {
						self.consoleCommandsView.ready(true);
					}

					if(self.state.maximize){
						self.wmksResize();
					}
				});
				this.$vmConsole.on("wmksresolutionchanged.vm wmksconnected.vm", function(){
					_.defer(self.onResolutionChanged);
				});
			} catch(e){
				//Some error connecting to VM. Let the reconnect wmksTimeout call (above) try it again.
				console.warn("Cannot connect with wmks:", e);
				this.stopVM();
			}


			if(this.state.maximize){
				this.goMaximize();
			}
		},
		connectionFailure : function(){
			console.warn("WMKS connection failure", {attempt: this._wmksConnectAttempt, maxTries: App.config.screenTicketMaxTries});
			if(this._wmksConnectAttempt > App.config.screenTicketMaxTries){
				this.displayState(this.CANNOT_CONNECT);
				clearTimeout(this.wmksTimeout);
				this.wmksTimeout = null;
			} else{
				this._wmksConnectAttempt++;
				this.getScreenTicket();
			}
		},
		retryWithUtf8Uint8 : function(){
			this.useUint8Utf8 = true;
			this._connectionUrl = null;
			this.stopVM();
			this.startVM();
		},
		/**
		 * Stop the VM connection (disconnect WebMKS).
		 */
		stopVM : function(){
			$(document).off("mousemove.vm");
			this._canvas = null;
			this._poweringOn = false;


			if(this.consoleCommandsView){
				this.consoleCommandsView.ready(false);
			}
			this.wmksStopPing();

			this.$(".connection-strength,.latency-current,.latency-average,.latency-high,.latency-low")
				.removeClass("level-1 level-2 level-3 level-4 level-5 level-6 unstable");
			this.$(".latency-unstable").hide();

			App.mainView.removeGeneralMessage("latency_unstable");
			App.mainView.removeGeneralMessage("latency_warning");

			if(this.$vmGroup){
				this.$vmGroup.removeClass("state-connection-lost");
			}

			if(this.$vmConsole){
				try{
					this.$vmConsole.off("wmksconnected.vm");
					this.$vmConsole.off("wmkserror.vm");
					this.$vmConsole.off("wmksresolutionchanged.vm wmksconnected.vm");
					this.$vmConsole.off("wmksdisconnected.vm", this.wmksDisconnected);

					this.$vmConsole.wmks("destroy");
				} catch (e){}
			}
		},
		wmksDisconnected : function(){
			this.wmksStopPing();
			_.delay(this._throttledRefresh, 2000);
		},
		showTestConsole : function(){
			this.testConsole = true;
			this._connectionUrl = null;

			this.$vmConsole.find("#test_console").remove();

			//Draw test console grid
			this.$vmConsole.append("<canvas id='test_console' width='"+this.testConsoleWidth+"' height='"+this.testConsoleHeight+"'></canvas>");
			var $canvas = this.$("#test_console");
			var context = $canvas[0].getContext("2d");

			var flip = false;
			for(var x = 0; x < this.testConsoleWidth; x+=32){
				for(var y = 0; y < this.testConsoleHeight; y+= 32){
					if(flip){
						context.fillStyle = "rgb(127, 0, 0)";
					} else{
						context.fillStyle = "rgb(0, 0, 127)";
					}
					context.fillRect(x, y, 32, 32);
					flip = !flip;
				}
			}
			context.fillStyle = "rgb(255, 255, 255)";
			context.font = "48px sans-serif";
			context.textAlign = "center";
			context.fillText("TEST CONSOLE", this.testConsoleWidth / 2, this.testConsoleHeight / 2);

			this.resizeTestConsole();
			this.onResolutionChanged();
			this.displayState(this.POWERED_ON);
		},
		hideTestConsole : function(){
			this.testConsole = false;
			this.$vmConsole.find("#test_console").remove();
		},
		resizeTestConsole : function(){
			var $canvas = this.$("#test_console");

			//Scale console
			var parentWidth  = this.$vmConsole.width();
			var parentHeight = this.$vmConsole.height();

			var horizScale = parentWidth / this.testConsoleWidth;
			var vertScale = parentHeight / this.testConsoleHeight;

			var newScale = Math.max(0.1, Math.min(horizScale, vertScale));

			$canvas.css("transform", "scale(" + newScale + ")");
			$canvas.css({
				left: (parentWidth - this.testConsoleWidth) / 2,
				top: (parentHeight - this.testConsoleHeight) / 2,
				width: this.testConsoleWidth,
				height: this.testConsoleHeight
			});
		},
		rebindEvents : function(){
			this.$vmConsole.find("canvas")
				.off("keydown.vm").on("keydown.vm", this.onKeyDown)
				.off("keypress.vm").on("keypress.vm", this.onKeyPress);
			this.$pasteCatcher.off("paste.vm").on("paste.vm", this.onPaste);
		},
		connectionInfoChange : function(model){

			if(!model.get("screenTicketPending")){
				//not waiting on screenTicket any more. Happens if multiple are made and one has already completed
				this.stopListening(model, null, this.connectionInfoChange);
				return;
			}
			if(model.get("host") && model.get("ticket")){
				this.stopListening(model, null, this.connectionInfoChange);
				model.set("screenTicketPending", false);
			}


			if(model !== this.model){
				//active VM has switched
				return;
			}
			if(this.model.get("host") && this.model.get("ticket")){
				this.startVM();
			}
		},
		statusChanged : function(model, status){
			if(this.model.get("status") === Vm.POWERED_ON){
				this.displayState(this.POWERED_ON);
			} else if(this.model.get("status") === Vm.POWERED_OFF){
				this.displayState(this.POWERED_DOWN);
			}
		},
		displayState : function(state){

			this.$vmGroup.removeClass("state-loading state-powered-on state-powered-down state-unavailable state-cannot-connect");
			this.$(".power-down").button("reset");
			this.$(".power-on").button("reset");
			this.$(".power-reset").button("reset");

			switch(state){
				case this.POWERED_ON:
					this.$vmGroup.addClass("state-powered-on");

					var $canvas = this.$vmConsole.find("canvas");
					this._canvas = $canvas.length && $canvas[0];
					$(document).on("mousemove.vm", this.onCaptureFocus);
					this.rebindEvents();
					break;
				case this.POWERED_DOWN:
					this.$vmGroup.addClass("state-powered-down");
					break;
				case this.LOADING:
					this.$vmGroup.addClass("state-loading");
					break;

				case this.UNAVAILABLE:
					this.$vmGroup.addClass("state-unavailable");
					break;
				case this.CANNOT_CONNECT:
					this.$vmGroup.addClass("state-cannot-connect");
					break;
			}
			this.$("[rel=popover]").popover("hide");

			this._displayState = state;
			this.setupResizing();
			_.defer(this.onScreenStatusChanged);
		},

		/**
		 * Send Control + Alt + Delete keyCodes
		 */
		sendControlAltDelete : function(event){
			if(!this.entitlement.get("userActionAllowed")) { return; }

			if(event) { event.preventDefault(); }

			if(this.$vmConsole && this.$vmConsole.data("wmks-wmks")) {
				var keyCodes = [17, 18, 46];
				this.$vmConsole.wmks("sendKeyCodes", keyCodes, _.keyCodesToScanCodes(keyCodes));
			}
		},
		sendShortcutKeys : function(event){
			if(!this.entitlement.get("userActionAllowed")) { return; }

			if(event) { event.preventDefault(); }

			var keyCodes = $(event.currentTarget).data("keyCodes");
			if(this.$vmConsole && this.$vmConsole.data("wmks-wmks")) {
				this.$vmConsole.wmks("sendKeyCodes", keyCodes, _.keyCodesToScanCodes(keyCodes));
			}
		},
		setupPosition : function(normalizing){
			this.state.normalizing = !!normalizing;

			if(this.state.maximize){
				this.goMaximize();
			} else if(this.state.position === "center"){
				this.dockCenter();
			} else if(this.state.position === "float"){
				this.float();
			} else{
				this.dock(this.state.position);
			}
			this.state.normalizing = false;
		},
		dockCenter : function(event){
			this.state.position = "center";

			this.setDockCenterSize(true);
			this.$(".controls [rel=tooltip]").tooltip({placement : "left"});
			this.$vmGroup.draggable({ disabled : true });
			this.$vmGroup.off("dragstop.vm drag.vm");

			this.updatePositioning(false);
			this.setupResizing();
			this.$("[rel=tooltip]").tooltip("hide");
			this.$("[rel=popover]").popover("hide");
		},
		dockLeft : function(event){
			this.dock("left");
		},
		dockRight : function(event){
			this.dock("right");
		},
		dock : function(position){
			var transition = (this.state.position != "center" && !this.state.normalizing);
			this.state.position = position;

			this.setFloatSize();
			this.$vmGroup.draggable({disabled : false, cancel : "canvas,.popover", containment : "#console_drag_containment", axis : "y"});
			this.$vmGroup
				.off("dragstop.vm drag.vm")
				.on("dragstop.vm drag.vm", this.onConsoleDragged);

			this.$(".controls [rel=tooltip]").tooltip({placement : (this.state.position === "right" ? "right" : "left")});

			this.updatePositioning(transition);
			this.setupResizing();
			this.$("[rel=tooltip]").tooltip("hide");
			this.$("[rel=popover]").popover("hide");
		},
		float : function(event){
			var transition = (this.state.position != "center" && !this.state.normalizing);
			this.state.position = "float";
			this.$vmGroup.draggable({disabled : false, cancel : "canvas,.popover", containment : "#console_drag_containment", axis : "false"});

			this.$vmGroup.off("dragstop.vm drag.vm")
				.on("dragstop.vm drag.vm", this.onConsoleDragged);

			this.setFloatSize();
			this.updatePositioning(transition);
			this.setupResizing();
			this.$("[rel=tooltip]").tooltip("hide");
			this.$("[rel=popover]").popover("hide");
		},
		toggleFullscreen : function(eventOrEnabled){
			var fullscreen = !Screenfull.isFullscreen;
			if(_.isBoolean(eventOrEnabled)) {
				fullscreen = _.toBoolean(eventOrEnabled);
			} else if(eventOrEnabled && eventOrEnabled.preventDefault) {
				eventOrEnabled.preventDefault();
				eventOrEnabled.stopPropagation();
			}

			if(Screenfull.isFullscreen === fullscreen){
				return;
			}

			if(!fullscreen){
				Screenfull.exit();
				this.$(".full-screen").addClass("animated slow flash");
				this.$("#browser_fullscreen_message").hide();
				if(Browser.safari){
					this.$("#browser_fullscreen_warning")
						.stop()
						.show()
						.delay(10000)
						.fadeOut("fast");
				}

				if(App.config.maximizeOnFullscreen){
					this.goNormalScreen();
				}
			} else{
				Screenfull.request();
				this.$(".full-screen").removeClass("animated slow flash");
				if(Browser.safari){
					this.$("#browser_fullscreen_warning").hide();
				}
				if(App.config.maximizeOnFullscreen){
					this.goMaximize();
					this.$("#browser_fullscreen_message").remove();
				}
			}
		},
		maximize : function(eventOrEnabled){
			var maximize = !this.state.maximize;
			if(_.isBoolean(eventOrEnabled)) {
				maximize = _.toBoolean(eventOrEnabled);
			} else if(eventOrEnabled && eventOrEnabled.preventDefault) {
				eventOrEnabled.preventDefault();
				eventOrEnabled.stopPropagation();
			}

			if(maximize){
				this.goMaximize();
			} else{
				this.goNormalScreen();
			}

			return false;
		},
		/**
		 * Enlarge the console to really maximized
		 */
		goMaximize : function(){
			$("body").addClass("maximize");
			$("body").toggleClass("compact-maximize", App.config.maximizeCompactPanelTabs || App.config.compactPanelTabs);
			this.state.maximize = true;


			this.$(".maximize").addClass("animated slow flash");
			if(!Screenfull.isFullscreen){
				this.$("#browser_fullscreen_message")
					.stop()
					.show()
					.delay(5000)
					.fadeOut("fast", function(){
						$(this).remove();
					});
			}
			this.$vmConsole.find("canvas").focus();

			this.setupResizing();
			this.updatePositioning();
			this.wmksResize();
			this.$("[rel=popover]").popover("hide");


		},

		/**
		 * Change the console to normal (guest OS resolution) size
		 */
		goNormalScreen : function(){
			$("body").removeClass("maximize");
			this.state.maximize = false;
			this.$(".maximize").removeClass("animated slow flash");
			this.$("#browser_fullscreen_message").hide();

			this.$vmConsole.find("canvas").focus();
			this.$("[rel=popover]").popover("hide");

			this.setupPosition(true);
		},

		setupResizing : function(){
			var self = this;

			if(this.$vmGroup.data("ui-resizable")) {
				this.$vmGroup.resizable("destroy");
			}

			if(this.state.maximize){
				this.$vmGroup.removeAttr("style");
				return;
			}

			// Deactive resizing if the allowResize = false
			if(!App.config.allowResize || !this.model.get("allowResize")){
				return;
			}

			if(this._displayState !== this.POWERED_ON){
				return;
			}

			var directions;
			if(this.state.position === "float"){
				directions = "e, s, w, se, sw";
			} else if(this.state.position === "left"){
				directions = "e, s, se";
			} else if(this.state.position === "right"){
				directions = "s, w, sw";
			} else if(this.state.position === "center"){
				return;
			}


			var $consoleInner = this.$vmConsoleWrapper;
			function resize(){
				self.$vmConsole.removeClass("transition");
				var paddingWidth  = $consoleInner.outerWidth()  - $consoleInner.width();
				var paddingHeight = $consoleInner.outerHeight() - $consoleInner.height();

				self.$vmConsole.width(self.$vmGroup.width() - paddingWidth);
				self.$vmConsole.height(self.$vmGroup.height() - (paddingHeight + self.$vmConsole.position().top));

				$("#vm").css("min-width", self.$vmConsoleWrapper.outerWidth() + "px");
				$("#vm").css("min-height", (self.$vmGroup.outerHeight() + parseInt(self.$vmGroup.css("margin-top"), 10) + parseInt(self.$vmGroup.css("margin-bottom"), 10)) + "px");

				if(self.state.position === "right"){
					self.$vmGroup.css("left", "");
				} else if(self.state.position === "left"){
					self.$vmGroup.css("right", "");
				}
			}
			this.$vmGroup.resizable({
				handles : directions,
				minWidth : App.config.consoleResizeWidthMin,
				minHeight : App.config.consoleResizeHeightMin,
				containment: "#console_drag_containment",
				resize : function(){
					self.state.resizing = true;
					resize();

					self.setWmksOption("fitGuest", false);
					self.setWmksOption("fitToParent", true);

					self.model.set({
						userResizeRequested : true,
						floatWidth          : self.$vmConsole.width(),
						floatHeight         : self.$vmConsole.height()
					});
				},
				stop : function(){
					self.state.resizing = false;

					resize();

					self.state.top    = self.$vmGroup.position().top;
					self.state.left   = self.$vmGroup.position().left;
					self.state.width  = self.$vmGroup.width();
					self.state.height = self.$vmGroup.height();

					self.model.set({
						userResizeRequested : true,
						floatWidth          : self.$vmConsole.width(),
						floatHeight         : self.$vmConsole.height()
					});
					self.wmksResize();
				}
			});

			this.$vmGroup.css("height", this.$vmGroup.height() + "px");
			this.wmksResize();
		},

		windowResizing : function(event){

			if(event && event.target !== window){ return; }
			if(!this.state.maximize && this.state.position !== "center"){ return; }

			this.state.resizing = true;
			this.$vmGroup.addClass("ui-resizable-resizing");

			if(this.state.maximize){
				this.wmksResize();
			} else if (this.state.position === "center"){
				this.setDockCenterSize(true);
			}

			this._debounceWindowResize();
		},

		windowResized : function(){
			this.state.resizing = false;
			this.$vmGroup.removeClass("ui-resizable-resizing");

			if(this.state.maximize){
				this.wmksResize();
			} else if (this.state.position === "center"){
				this.setDockCenterSize(true);
			}
		},
		setDockCenterSize : function(force){
			if(!this.model.get("dockedWidth") || force){

				this.model.set({
					dockedWidth  : $(window).width()  - this.centeredWidthPadding,
					dockedHeight : $(window).height() - this.centeredHeightPadding
				});
			}

			this.$vmConsole.css({
				width  : this.model.get("dockedWidth"),
				height : this.model.get("dockedHeight")
			});
			this.wmksResize();
		},
		setFloatSize : function(force){
			if(!this.model.get("floatWidth") || force){
				//Start with window sizing
				var width  = $(window).width()  - this.floatWidthPadding;
				var height = $(window).height() - this.floatHeightPadding;

				// Subtract open panels (with some flub factor)
				width -= ($(".dock-right.expanded").length ? $(".dock-right.expanded").width() + this.floatPanelPadding : 0);
				width -= ($(".dock-left.expanded").length  ? $(".dock-left.expanded").width()  + this.floatPanelPadding : 0);
				width -= this.$(".controls").width();

				if(width > this.floatMaxWidth){
					width = this.floatMaxWidth;
				}

				var consoleResolution = this.getConsoleResolution();
				if(consoleResolution && consoleResolution.scale != 1 && consoleResolution.width < width) {
					if(consoleResolution.width < width){
						width  = consoleResolution.width;
						height = consoleResolution.height;
					}
				}
				this.model.set({
					floatWidth  : width,
					floatHeight : height
				});
				this.$vmGroup.css({height : "", width : ""});
			}
			this.$vmConsole.css({
				width  : this.model.get("floatWidth"),
				height : this.model.get("floatHeight")
			});

			this.wmksResize();
		},
		getConsoleResolution : function(){
			var result = {};
			var canvas = this.$vmConsole.find("canvas").get(0);
			if(!canvas) { return null; }

			if(canvas){
				result.width  = canvas.width;
				result.height = canvas.height;

				if(canvas.getBoundingClientRect) {
					var rect = canvas.getBoundingClientRect();
					result.scaledWidth  = Math.ceil(rect.width);
					result.scaledHeight = Math.ceil(rect.height);
					result.scale = rect.width / result.width;
				}
			}

			return result;
		},
		/**
		 * Guest OS resolution changed
		 */
		onResolutionChanged : function(){
			if(!this.model.get("resolutionChangeRequested") || !this.model.get("originalResolutionWidth")){
				//Keep track of the original resolution to restore original size
				var consoleResolution = this.getConsoleResolution();
				if(consoleResolution){
					this.model.set("originalResolutionWidth",  consoleResolution.width);
					this.model.set("originalResolutionHeight", consoleResolution.height);
				}
			}

			clearTimeout(this.resetResizeRequestTimeout);
			this.model.set("resolutionChangeRequested", false);
			this.model.set("userResizeRequested", false);

			this.updatePositioning();

			if(this.model.get("openNotesOnStart") && !this.model.get("notesShown") && this._throttledShowNotes){
				this._throttledShowNotes();
			}

			if(this.state.position === "center") {
				this.setDockCenterSize();
			}

			this.resizeInstructionsPanel();
			this.rebindEvents();
		},

		/**
		 * Set document focus if the user mouses over the WebMKS canvas element
		 *
		 * This allows for keyboard entry when the user has their mouse over the console
		 */
		onCaptureFocus : function(event){
			if(this._canvas && event.target === this._canvas && !Browser.msie){
				var scrollTop = $(window).scrollTop();
				$(this._canvas).toggleClass("empty-cursor", (this.consoleCommandsView && !this.consoleCommandsView.running) && this._canvas.style.cursor && this._canvas.style.cursor.indexOf("none") !== -1);

				this._canvas.focus();
				$(window).scrollTop(scrollTop);
			}
		},

		onPanelMoved : function(event){
			if(!this.$vmGroup){ return; }

			this.$vmGroup.removeClass("manual-left manual-right manual-docked manual-float manual-expanded manual-closed manual-transition");
			this.$vmGroup.toggleClass("manual-transition", !!event);
			if(this.instructionsPanel){
				this.$vmGroup.toggleClass("manual-left", this.instructionsPanel.state.position == "left");
				this.$vmGroup.toggleClass("manual-right", this.instructionsPanel.state.position == "right");
				this.$vmGroup.toggleClass("manual-docked", !this.instructionsPanel.state.floating);
				this.$vmGroup.toggleClass("manual-float", this.instructionsPanel.state.floating);
				this.$vmGroup.toggleClass("manual-expanded", this.instructionsPanel.state.expanded);
				this.$vmGroup.toggleClass("manual-closed", !this.instructionsPanel.state.expanded);
			}
			if(this._canvas){
				var $canvas = $(this._canvas);
				if(this.state.maximize && this.instructionsPanel && !this.instructionsPanel.state.floating && this.instructionsPanel.state.expanded){
					var cssLeft = parseInt($canvas.css("left"), 10);
					if(cssLeft < 0){  cssLeft = -cssLeft; }
					if(this.instructionsPanel.state.position == "right"){  cssLeft = -cssLeft; }

					$canvas.css("margin-left", cssLeft + "px");
				} else{
					$canvas.css("margin-left", "");
				}
			}

		},
		/**
		 * Update positioning of the console
		 */
		updatePositioning : function(transition){

			transition = !!transition;

			var self = this;

			this.stopListening(this.panelManager, "panels:dockleft");
			this.stopListening(this.panelManager, "panels:dockright");

			this.$vmGroup.removeClass("center left right float");
			this.$vmGroup.toggleClass(this.state.position, !this.state.maximize);

			if(this.state.maximize){

				this.$vmGroup.removeAttr("style");
				this.$vmConsole.removeAttr("style");
				$("#vm").removeAttr("style");

			} else if(this.state.position === "float"){

				//Floating
				this.$(".vm-dock-right").show();
				this.$(".vm-dock-left").show();
				this.$(".vm-dock-center").show();
				this.$(".vm-float").hide();

				if(!this.model.get("floatedPreviously")){
					this.state.left = (($(window).width() - this.model.get("floatWidth")) / 2) - this.$(".controls").width();
					this.state.top = 0;
					this.model.set("floatedPreviously", true);
				}
				this.moveVmGroup({
					top        : this.state.top + "px",
					left       : this.state.left + "px"
				}, transition);

				_.defer(function(){
					self.$vmGroup.css("width", self.$vmConsoleWrapper.outerWidth() + "px");

				});

				this.$vmGroup.css("width", this.$vmConsoleWrapper.outerWidth() + "px");

				$("#vm").css("min-width", this.$vmConsoleWrapper.outerWidth() + "px");
				$("#vm").css("min-height", this.$vmGroup.outerHeight() + "px");
			} else if(this.state.position === "center"){
				//Center docked
				this.$vmGroup.removeAttr("style");
				$("#vm").removeAttr("style");

				this.$(".vm-dock-center").hide();
				this.$(".vm-dock-left").show();
				this.$(".vm-dock-right").show();
				this.$(".vm-float").show();
			} else {

				if(this.instructionsPanel){
					this.instructionsPanel.$el.removeClass("resized");
				}
				this.$vmGroup.css("width", this.$vmConsoleWrapper.outerWidth() + "px");
				var position = { top : "0px"};

				var $container = (this.state.position === "left" ? this.panelManager.$containerLeft : this.panelManager.$containerRight);
				var containerWidth = parseInt($container.css("width"), 10);

				if(this.state.position === "left"){

					position.right = "";
					if($container.hasClass("expanded")){
						var containerLeft  = parseInt($container.css("left"), 10);
						position.left = (containerLeft + containerWidth) + "px";
					} else{
						position.left = "0px";
					}

					this.listenTo(this.panelManager, "panels:dockleft", this.dockAttachedResized);
					this.$(".vm-dock-right").show();
					this.$(".vm-dock-left").hide();

				} else if(this.state.position === "right"){

					position.left = "";
					if($container.hasClass("expanded")){
						var containerRight = parseInt($container.css("right"), 10);
						position.right = (containerRight + containerWidth) + "px";
					} else{
						position.right = "0px";
					}


					this.listenTo(this.panelManager, "panels:dockright", this.dockAttachedResized);
					this.$(".vm-dock-left").show();
					this.$(".vm-dock-right").hide();
				}
				this.moveVmGroup(position, transition);
				this.$(".vm-float").show();
				this.$(".vm-dock-center").show();

				$("#vm").css("min-width", this.$vmConsoleWrapper.outerWidth() + "px");
				$("#vm").css("min-height", (this.$vmGroup.outerHeight() + parseInt(this.$vmGroup.css("margin-top"), 10) + parseInt(this.$vmGroup.css("margin-bottom"), 10)) + "px");
			}
			this.onPanelMoved();
		},
		dockAttachedResized : function(info){

			var $dockContainer = (this.state.position === "left" ? this.panelManager.$containerLeft : this.panelManager.$containerRight);
			if(!info){
				info = {
					left      : parseInt($dockContainer.css("left"), 10),
					right     : parseInt($dockContainer.css("right"), 10),
					width     : parseInt($dockContainer.css("width"), 10),
					expanded  : $dockContainer.hasClass("expanded"),
					immediate : false
				};
			}

			if(this.state.position === "left"){
				this.moveVmGroup({
					right      : "",
					left       : (info.expanded ? (info.left + info.width) : 0) + "px"
				}, !info.immediate);
			} else if(this.state.position === "right"){
				this.moveVmGroup({
					left       : "",
					right      : (info.expanded ? (info.right + info.width) : 0) + "px"
				}, !info.immediate);
			}

			if(this.instructionsPanel && this.state.position !== this.instructionsPanel.state.position){
				this.resizeInstructionsPanel();
			}

		},
		moveVmGroup : function(position, transition){
			var self = this;

			var $container = (this.state.position === "left" ? this.panelManager.$containerLeft : this.panelManager.$containerRight);
			if(transition && $.support.transition){
				this.$vmGroup.removeClass("transition");
				var positionBefore = {};
				var positionTransition = {};
				var positionAfter = {};

				if(position.hasOwnProperty("right") && position.right !== ""){
					positionBefore.right      = "";
					positionBefore.left       = this.$vmGroup.position().left + "px";
					positionBefore.margin     = this.$vmGroup.css("margin");
					this.$vmGroup.css({
						left  : "",
						right : position.right
					});
					this.$vmGroup.toggleClass("with-panels", $container.children().length !== 0);

					positionTransition.margin = this.$vmGroup.css("margin");
					positionTransition.left   = this.$vmGroup.position().left + "px";
					positionAfter.left        = "";
					positionAfter.right       = position.right;
					positionAfter.margin      = "";
				} else if(position.hasOwnProperty("left") && position.left !== ""){
					positionBefore.right      = "";
					positionBefore.left       = this.$vmGroup.position().left + "px";
					positionBefore.margin     = this.$vmGroup.css("margin");
					this.$vmGroup.toggleClass("with-panels", $container.children().length !== 0);
					positionTransition.margin = this.$vmGroup.css("margin");
					positionTransition.left   = position.left;
					positionAfter.left        = position.left;
					positionAfter.margin      = "";
				}
				if(position.hasOwnProperty("top") && position.top !== ""){
					positionBefore.top        = this.$vmGroup.position().top + "px";
					positionTransition.top    = position.top;
					positionAfter.top         = position.top;
					positionAfter.margin      = "";
				}

				this._targetPosition = positionTransition;
				this.$vmGroup.css(positionBefore);
				this.$vmGroup.css(["left", "right", "top", "margin"]); //needed to "set" the values in for the transition

				this.$vmGroup.addClass("transition");
				this.$vmGroup.css(positionTransition);

				this.$vmGroup.off($.support.transition.end).on($.support.transition.end, function(event){
					if(event.target === self.$vmGroup.get(0)){
						self.$vmGroup.off($.support.transition.end);
						self.$vmGroup.removeClass("transition");
						self.$vmGroup.css(positionAfter);
						self._targetPosition = null;
					}
				});

			} else{
				this.$vmGroup.toggleClass("with-panels", $container.children().length !== 0);
				this.$vmGroup.css(position);
			}
		},
		resizeInstructionsPanel : function(){


			if(!this.instructionsPanel || this.instructionsPanel.state.floating) { return; }
			if(this.state.position === "center" || this.state.position === "float" || this.state.maximize) { return; }

			var width = 0;
			var controlsWidth = this.$(".controls").width();

			var groupLeft = (this._targetPosition ? parseInt(this._targetPosition.left, 10) :  this.$vmGroup.position().left);
			var groupWidth = this.$vmGroup.outerWidth();

			if(this.state.position === "left" && this.instructionsPanel.state.position === "right"){
				width = $(window).width() - (groupLeft + groupWidth + controlsWidth + this.panelPadding + parseInt(this.$vmGroup.css("margin-left"), 10));

				if(width < this.instructionsPanel.minWidth){
					width = this.instructionsPanel.minWidth;
				}
			} else if(this.state.position === "right" && this.instructionsPanel.state.position === "left"){
				width = groupLeft - (controlsWidth + this.panelPadding);

				if(width < this.instructionsPanel.minWidth){
					width = this.instructionsPanel.minWidth;
				}
			} else if(this.state.position === "right" && this.instructionsPanel.state.position === "right"){
				width = ($(window).width() - (groupLeft + groupWidth)) - this.panelPadding;
				if(width >= this.instructionsPanel.$el.outerWidth()){
					return;
				}
			} else if(this.state.position === "left" && this.instructionsPanel.state.position === "left"){
				width = groupLeft - this.panelPadding;
				if(width >= this.instructionsPanel.$el.outerWidth()){
					return;
				}
			}

			if(this.instructionsPanel.state.docked.width === width || width <= 0) {
				return;
			}

			this.instructionsPanel.setWidth(width, { trigger : true });
		},
		onConsoleDragged : function(){
			this.$vmGroup.removeClass("transition");
			this.state.top    = this.$vmGroup.position().top;
			this.state.left   = this.$vmGroup.position().left;
			this.state.width  = this.$vmGroup.width();
			this.state.height = this.$vmGroup.height();

			//App.store.set(this.saveStateKey, this.state, 2);
		},
		clean : function(){
			//App.store.remove(this.saveStateKey);
		},

		/**
		 * Safely set an WebMKS option
		 *
		 * If the console is not connected, it will wait until is connected to set the option.
		 * Useful if the user sets an option before the WMKS console finishes connecting.
		 */
		setWmksOption : function(option, value){
			if(this.$vmConsole && this.$vmConsole.data("wmks-wmks")){
				if(value !== undefined && option !== "updateFitGuestSize"){
					this.$vmConsole.wmks("option", option, value);
				} else{
					this.$vmConsole.wmks(option, value);
				}
			} else if(this.$vmConsole){
				var self = this;

				this.$vmConsole.one("wmksconnected", function(){

					if(value !== undefined && option !== "updateFitGuestSize"){
						self.$vmConsole.wmks("option", option, value);
					} else{
						self.$vmConsole.wmks(option, value);
					}
				});
			}
		},
		wmksResize : function(){
			if(this.testConsole){
				return this.resizeTestConsole();
			}
			if(!this.$vmConsole || this.$vmConsole.width() <= 0 || this.$vmConsole.height() <= 0){
				return;
			}

			this.setWmksOption("fitToParent", true);
			if(this.model.get("userResizeRequested") || this.model.get("automaticResize")) {
				this.setWmksOption("fitGuest", App.config.allowResize && this.model.get("allowResize") && this.entitlement.get("userActionAllowed"));
			}
			this.setWmksOption("updateFitGuestSize", true);


			if(!this.state.resizing && this.state.position === "center"){
				var consoleResolution = this.getConsoleResolution();
				if(consoleResolution && consoleResolution.scaledWidth > 0 && consoleResolution.scaledHeight > 0){
					this.$vmConsole.width(consoleResolution.scaledWidth);
					this.$vmConsole.height(consoleResolution.scaledHeight);
					this.setWmksOption("fitToParent", true);
				}
			}

			this.model.set("resolutionChangeRequested", true);
			clearTimeout(this.resetResizeRequestTimeout);
			this.resetResizeRequestTimeout = setTimeout(this.resetResizeRequest, 5000);
			this.onPanelMoved();
		},
		resetResizeRequest : function(){
			this.model.set("resolutionChangeRequested", false);
		},
		showNotes : function(){
			this.$(".note").popover("show");
			this.model.set("notesShown", true);
		},

		/**
		 * Sent Text to the console.
		 *
		 * Used by a floating textarea box to provide a place for users to mass enter text (copy and paste) and
		 * other short cut text sending.
		 *
		 * The text sending is throttled so it does not overwhelm the connection.
		 */
		sendText : function(text){
			if(this.consoleCommandsView){
				this.consoleCommandsView.sendInput(text);
			}
		},
		onQuickSendText : function(event){
			if(!this.entitlement.get("userActionAllowed")) { return; }

			var text = $(event.currentTarget).data("text");
			this.sendText(text);
		},
		onDragEnter : function(event){
			if(!this.entitlement.get("userActionAllowed")) { return; }

			event.preventDefault();
			$(event.target).addClass("drop-target");
		},
		onDragLeave : function(event){
			if(!this.entitlement.get("userActionAllowed")) { return; }

			event.preventDefault();
			$(event.target).removeClass("drop-target");
		},
		onDragOver : function(event){
			if(!this.entitlement.get("userActionAllowed")) { return; }

			event.preventDefault();
			var pos = this.$vmConsole.wmks("getEventPosition", event.originalEvent);
			this.$vmConsole.wmks("sendMouseMoveMessage", pos);
		},
		onDropText : function(event){
			if(!this.entitlement.get("userActionAllowed")) { return; }

			event.preventDefault();

			//click where the user drops before sending text so it types in the place they expect it to.
			var pos = this.$vmConsole.wmks("getEventPosition", event.originalEvent);
			this.$vmConsole.wmks("sendMouseButtonMessage", pos, true, WMKS.CONST.CLICK.left);
			this.$vmConsole.wmks("sendMouseButtonMessage", pos, false, WMKS.CONST.CLICK.left);

			if(event && event.originalEvent.dataTransfer && event.originalEvent.dataTransfer.getData){
				var text = "";
				try{
					text = event.originalEvent.dataTransfer.getData("text/plain");
				} catch (e) {
					text = event.originalEvent.dataTransfer.getData("text");
				}
				if(text){
					var self = this;
					setTimeout(function(){
						//small delay so the previous above clicks are completed before sending the text
						//to prevent weird text dropping behaviour.
						self.sendText(text);
					}, 100);
				}
			}
		},
		onPaste : function(event){
			this.$pasteCatcher.empty();
			if(!this.entitlement.get("userActionAllowed")) { return; }

			event.preventDefault();
			event.stopPropagation();

			var text = (event.originalEvent.clipboardData || window.clipboardData).getData("text");

			if(!this.entitlement.get("interceptPaste") && !this.entitlement.get("interceptPastePromptShown")){
				this.$vmConsole.find("canvas").blur();
				this.$pasteCatcher.blur();
				if(this.vmSettingsPromptsView){
					this.vmSettingsPromptsView.showInterceptPasteModal(text);
				}
			} else{
				this.$vmConsole.find("canvas").focus();
				this.sendText(text);
			}
		},

		onKeyDown : function(event) {
			if (event.originalEvent && event.originalEvent.keyIdentifier) {
				this.keyDownIdentifier = event.originalEvent.keyIdentifier;
			}

			if(this.onKeyDownCheckForPaste(event)){ return; }
			if(this.onKeyDownCheckMacShortcuts(event)){ return; }
			if(this.onKeyDownCheckManualShortcuts(event)){ return; }
		},
		onKeyDownCheckForPaste : function(event){
			//86 is v key
			if(event.which != 86){
				return false;
			}

			var isPasteShortCut = false;
			if (Browser.osType === "Mac"){
				isPasteShortCut = event.metaKey && !event.ctrlKey && !event.altKey && !event.shiftKey;
			} else{
				isPasteShortCut = event.ctrlKey && !event.metaKey && !event.altKey && !event.shiftKey;
			}


			if(isPasteShortCut){
				//paste shortcut key pressed
				if(this.entitlement.get("interceptPaste") || (!this.entitlement.get("interceptPaste") && !this.entitlement.get("interceptPastePromptShown"))){
					event.stopPropagation();

					this.$pasteCatcher.focus();
					return true;
				}
			}
			return false;
		},
		onKeyDownCheckMacShortcuts : function(event){
			if(Browser.osType === "Mac" && this.model.get("osType") !== "apple" &&
				!this.entitlement.get("macOsRemapCommandToControlPromptShown") &&
				event.metaKey && App.config.macOsRemapCommandToControlKeys.indexOf(event.which) !== -1){

				//prevents windows menu from popping up
				var keyCodes = [17];
				this.$vmConsole.wmks("sendKeyCodes", keyCodes, _.keyCodesToScanCodes(keyCodes));
				this.$vmConsole.blur();

				if(this.vmSettingsPromptsView){
					this.vmSettingsPromptsView.showMacOSRemapShortcutsModal();
				}

				event.stopPropagation();
				event.preventDefault();
				return true;
			}

			return false;
		},
		onKeyDownCheckManualShortcuts : function(event){
			if (!this.entitlement.get("manualShortcuts")) {
				return false;
			}

			//Allow retriggering of the event from canvas to the body for the "global manual" navigation
			var newEvent = $.Event("keydown", { which : event.which, ctrlKey : event.ctrlKey, metaKey : event.metaKey });

			var isShortcutKey = (event.which === App.config.manualPreviousPageShortcutKey || event.which === App.config.manualNextPageShortcutKey);
			var isControlKey = (Browser.osType === "Mac" && event.metaKey) || (Browser.osType !== "Mac" && event.ctrlKey);

			if (isShortcutKey && isControlKey){
				$("body").trigger(newEvent);
				event.preventDefault();
				event.stopImmediatePropagation();
				return true;
			}
			return false;
		},
		onKeyPress : function(event){

			if(!event.originalEvent) {
				return;
			}
			if(
				this.entitlement.get("fixANSIPromptShown") ||
				this.entitlement.get("keyboardLayoutId") !== App.config.consoleKeyboardDefaultLayout ||
				(!event.originalEvent.key && Browser.chrome && Browser.osType === "Windows")
			) {
				return;
			}

			var shiftMismatch   = false,
			    noShiftMismatch = false,
			    keyCodeMismatch = false,
			    isSpecialSymbol = false,
			    key;

			if(event.originalEvent.key){
				key = event.originalEvent.key;
			} else if(event.originalEvent.keyIdentifier === ""){
				//Obtained from keydown (safari)
				key = String.fromCharCode(parseInt(this.keyDownIdentifier.replace("U+", ""), 16));
			} else if(event.originalEvent.keyIdentifier) {
				key = String.fromCharCode(parseInt(event.originalEvent.keyIdentifier.replace("U+", ""), 16));
			} else if (event.originalEvent.charCode) {
				key = String.fromCharCode(event.originalEvent.charCode);
			}
			if (key) {

				keyCodeMismatch = (key.charCodeAt(0) !== event.which);
				shiftMismatch   = (WMKS.CONST.KB.ANSIShiftSymbols.indexOf(key)   !== -1 && !event.shiftKey);
				noShiftMismatch = (WMKS.CONST.KB.ANSINoShiftSymbols.indexOf(key) !== -1 &&  event.shiftKey);
				isSpecialSymbol = (WMKS.CONST.KB.ANSISpecialSymbols.indexOf(key) !== -1);

				this.keyDownIdentifier = null;

				if (key && isSpecialSymbol && (keyCodeMismatch || shiftMismatch || noShiftMismatch)) {
					if(this.vmSettingsPromptsView){
						this.vmSettingsPromptsView.showKeyboardLayoutMismatchModal();
					}
				}
			}
		},
		onFixANSIChanged : function(){
			this.setWmksOption("fixANSIEquivalentKeys", this.entitlement.get("fixANSI"));
		},
		onKeyboardLayoutIdChanged : function(){
			this.setWmksOption("keyboardLayoutId", this.entitlement.get("keyboardLayoutId"));
		},
		onReverseMouseChanged : function(){
			this.setWmksOption("reverseScrollY", this.entitlement.get("reverseScrollY"));
		},
		onMacOsRemapCommandToControlChanged : function(){
			this.setWmksOption("mapMetaToCtrlForKeys", this.entitlement.get("macOsRemapCommandToControl")  && this.model.get("osType") !== "apple" ? App.config.macOsRemapCommandToControlKeys : []);
		},
		/**
		 * Class related Methods
		 */
		onScreenStatusChanged : function(){
			if(this.$vmGroup){
				if(this.entitlement.get("screenStatus") === "blanked"){
					this.$vmGroup.addClass("state-screen-blanked");
					this.$vmGroup.addClass("state-screen-blanked-vm");
				} else if(this.entitlement.get("instructorViewing") && !this.entitlement.get("takenOver")){
					this.$vmGroup.addClass("state-screen-instructor-viewing");
					this.$vmGroup.removeClass("state-screen-taken-over");
				} else if(this.entitlement.get("takenOver")){
					this.$vmGroup.addClass("state-screen-taken-over");
					this.$vmGroup.removeClass("state-screen-instructor-viewing");
				} else if (this.entitlement.get("supportViewing")) {
					this.$vmGroup.addClass("state-screen-support-viewing");
				} else {
					this.$vmGroup.removeClass("state-screen-taken-over state-screen-blanked state-screen-instructor-viewing state-screen-support-viewing state-screen-blanked-vm");
				}
			}

			this.$(".power-on,.powered-down,.power-down,.power-reset,.vm-power-down,.vm-power-reset").prop("disabled", !this.entitlement.get("userActionAllowed"));
			this.$(".control-alt-del,#send_text,.quick-send-text,.show-send-text-modal").prop("disabled", !this.entitlement.get("userActionAllowed"));

			this.wmksSetupEvents();
		},
		/**
		 * Class related Methods
		 */
		pause : function(){
			this._paused = true;
			this.stopVM();
		},
		resume : function(){
			if(this._paused){
				this._paused = false;
				this.refreshConsole();
			}
		},


		wmksStartPing: function(){
			if(!this.entitlement.get("consoleConnectionMonitoring")){
				return;
			}

			this.pingStart = null;
			this.expectingPong = false;
			this.reschedulePing = false;
			this.lastDelayedPings = [];
			this.lastWmksPacket = null;

			clearTimeout(this._wmksNoPacketCheckTimeout);
			clearTimeout(this._wmksNoPacketVerifyTimeout);
			clearTimeout(this._wmksNoPacketRefreshTimeout);
			clearTimeout(this._wmksPingUnavailableTimeout);
			clearTimeout(this._wmksPingTimeout);
			clearInterval(this._wmksPingInterval);

			this._wmksPingInterval = setInterval(this.wmksSendPing, App.config.consoleConnectionMonitoringFrequency);
		},
		wmksStopPing : function(){

			this.pingStart = null;
			this.expectingPong = false;
			this.reschedulePing = false;
			this.lastDelayedPings = [];
			this.lastWmksPacket = null;

			clearTimeout(this._wmksNoPacketCheckTimeout);
			clearTimeout(this._wmksNoPacketVerifyTimeout);
			clearTimeout(this._wmksNoPacketRefreshTimeout);
			clearTimeout(this._wmksPingUnavailableTimeout);
			clearTimeout(this._wmksPingTimeout);
			clearInterval(this._wmksPingInterval);
		},
		wmksPatch : function(){

			var self = this;
			var vncDecoder =  this.$vmConsole.wmks("getVNCDecoder");

			var originalHandleServerMsg = vncDecoder._handleServerMsg;
			vncDecoder._handleServerMsg = function(){

				self.wmksGotPacket();

				var messageType = new Uint8Array(this._receiveQueue[0].data, this._receiveQueueIndex, 1)[0];
				if(messageType == this.msgVMWSrvMessage){
					var serverMessageType = new Uint8Array(this._receiveQueue[0].data, this._receiveQueueIndex + 1, 1)[0];
					if(serverMessageType === this.msgVMWSrvMessage_ServerCaps && self.expectingPong){
						//PONG received
						clearTimeout(self._wmksPingUnavailableTimeout);
						if (self.reschedulePing && self.entitlement.get("consoleConnectionMonitoring")) {
							self.wmksReschedulePing();
						} else {
							self.wmksPingResponse(self.pingStart ? Date.now() - self.pingStart : 0);
						}

						this._setReadCB(8, this._gobble, function(){ vncDecoder._getNextServerMessage.call(vncDecoder); });
						return;
					}
				}

				originalHandleServerMsg.apply(this, arguments);
			};
			var wmksWidget = this.$vmConsole.data("wmks-wmks");
			if(wmksWidget){
				//Monkey patch onKeyDown to force send key up when it is a matching remapped macOs shortcut key.
				var originalOnKeyDown = wmksWidget._keyboardManager.onKeyDown;
				wmksWidget._keyboardManager.onKeyDown = function(event){
					var result = originalOnKeyDown.call(this, event);
					if (this.keyDownKeyTimer && event.metaKey === true && this.mapMetaToCtrlForKeys.indexOf(event.keyCode) !== -1) {
						clearTimeout(this.keyDownKeyTimer);

						var keyboardManager = this;

						var timeoutDelay = WMKS.BROWSER.isFirefox() ? 100 : 0;
						this.keyDownKeyTimer = setTimeout(function() {
							keyboardManager.sendKey(keyboardManager.pendingKey, false, false);
							keyboardManager.sendKey(keyboardManager.pendingKey, true, false);
							keyboardManager.keyDownKeyTimer = null;
							keyboardManager.pendingKey = null;
						}, timeoutDelay);
					}
					return result;
				};
			}
		},
		wmksSendPing : function(){
			if(!document.hidden){
				if(this.expectingPong){
					this.wmksSetReschedulePing();
				}
				if(App.config.consoleConnectionLatencyUnstableTimeout){
					clearTimeout(this._wmksPingUnavailableTimeout);
					this._wmksPingUnavailableTimeout = setTimeout(this.wmksPingUnavailable, App.config.consoleConnectionLatencyUnstableTimeout);
				}

				this.expectingPong = true;
				this.pingStart = Date.now();
				this.$vmConsole.wmks("ping");
			}
		},
		wmksPingResponse : function(value) {
			this.expectingPong = false;
			this.reschedulePing = false;

			if(!document.hidden && value > 0){

				var delayedMin = Math.min.apply(Math, this.lastDelayedPings);
				this.entitlement.set("consoleLatencyCurrent", Math.min(delayedMin, value));


				var current = Math.min(delayedMin, value);
				var count = this.entitlement.get("consoleLatencyCount") + 1;
				var average = ((this.entitlement.get("consoleLatencyAverage") * (count - 1)) + current) / count;
				var low  = Math.min(current, this.entitlement.get("consoleLatencyLow"));
				var high = Math.max(current, this.entitlement.get("consoleLatencyHigh"));

				var previousValues = this.entitlement.get("consoleLatencyPreviousValues");
				if(previousValues.length > App.config.consoleConnectionLatencyWarningCheckCount){
					previousValues.shift();
				}
				previousValues.push(current);

				var previousValuesSum = _.reduce(previousValues, function(memo, num){ return memo + num; }, 0);
				var previousValuesAverage = previousValuesSum / previousValues.length;
				var consoleLatencyWarning = previousValues.length > App.config.consoleConnectionLatencyWarningCheckCount && previousValuesAverage >= App.config.consoleConnectionLatencyWarningThreshold;


				this.entitlement.set({
					consoleLatencyCount         : count,
					consoleLatencyCurrent       : current,
					consoleLatencyAverage       : average,
					consoleLatencyLow           : low,
					consoleLatencyHigh          : high,
					consoleLatencyPreviousValues: previousValues,
					consoleLatencyWarning       : consoleLatencyWarning,
					consoleLatencyUnstable      : false,
					consoleConnectionLost       : false
				});
			}
			this.lastDelayedPings = [];
			this.pingStart = null;
		},
		wmksReschedulePing : function(delay){
			if(!delay){
				delay = App.config.consoleConnectionLatencyRescheduleTimeout;
			}

			if(this.pingStart){
				this.lastDelayedPings.push(Date.now() - this.pingStart);
			}

			if(this.lastDelayedPings.length > App.config.consoleConnectionLatencyRescheduleMaxCount){
				this.wmksPingResponse(Math.min.apply(Math, this.lastDelayedPings));
				return;
			}

			this.reschedulePing = false;
			this.pingStart = null;
			this.expectingPong = false;

			clearTimeout(this._wmksPingTimeout);
			clearInterval(this._wmksPingInterval);

			var self = this;
			this._wmksPingTimeout = setTimeout(function(){
				self.wmksSendPing();
				clearInterval(self._wmksPingInterval);
				self._wmksPingInterval = setInterval(self.wmksSendPing, App.config.consoleConnectionMonitoringFrequency);
			}, delay);
		},
		wmksSetReschedulePing : function(){
			//reschedule pong for after incoming buffer clears
			if(this.expectingPong){
				this.reschedulePing = true;
			}
		},
		wmksGotPacket : function(){
			this.lastWmksPacket = Date.now();
			if(App.config.consoleConnectionDisconnectTimeout > 0) {
				clearTimeout(this._wmksNoPacketCheckTimeout);
				clearTimeout(this._wmksNoPacketVerifyTimeout);
				clearTimeout(this._wmksNoPacketRefreshTimeout);
				if(this._wmksNoPacketRefreshTimeout){
					this.entitlement.set("consoleConnectionLost", false);
					this._wmksNoPacketRefreshTimeout = null;
				}
				this._wmksNoPacketCheckTimeout = setTimeout(this.wmksNoPacketCheck, App.config.consoleConnectionDisconnectTimeout);
			}
		},
		wmksNoPacketCheck : function(){
			this.$vmConsole.wmks("ping");
			clearTimeout(this._wmksNoPacketCheckTimeout);
			clearTimeout(this._wmksNoPacketVerifyTimeout);
			clearTimeout(this._wmksNoPacketRefreshTimeout);
			this._wmksNoPacketVerifyTimeout = setTimeout(this.wmksNoPacketPingVerify, App.config.consoleConnectionDisconnectVerifyTimeout);
		},
		wmksNoPacketPingVerify : function(){
			clearTimeout(this._wmksNoPacketCheckTimeout);
			clearTimeout(this._wmksNoPacketVerifyTimeout);
			clearTimeout(this._wmksNoPacketRefreshTimeout);
			this.entitlement.set("consoleConnectionLost", true);
			this._wmksNoPacketRefreshTimeout = setTimeout(this.refreshConsole, App.config.consoleConnectionDisconnectRefreshTimeout);

		},
		wmksPingUnavailable : function(){
			this.wmksSetReschedulePing();
			this.entitlement.set("consoleLatencyUnstable", true);
		},
		onConsoleLatencyCurrentChanged : function(model, value){
			this.$(".connection-strength,.latency-current").removeClass("level-1 level-2 level-3 level-4 level-5 level-6");
			this.$(".connection-strength").addClass(this.entitlement.getLatencyLevel(value));
			this.$(".latency-current")
				.addClass(this.entitlement.getLatencyLevel(value))
				.text(this.entitlement.getLatencyDisplayValue(value));
		},
		onConsoleLatencyAverageChanged : function(model, value){
			this.$(".latency-average")
				.removeClass("level-1 level-2 level-3 level-4 level-5 level-6")
				.addClass(this.entitlement.getLatencyLevel(value))
				.text(this.entitlement.getLatencyDisplayValue(value));
		},
		onConsoleLatencyLowChanged : function(model, value){
			this.$(".latency-low")
				.removeClass("level-1 level-2 level-3 level-4 level-5 level-6")
				.addClass(this.entitlement.getLatencyLevel(value))
				.text(this.entitlement.getLatencyDisplayValue(value));
		},
		onConsoleLatencyHighChanged : function(model, value){
			this.$(".latency-high")
				.removeClass("level-1 level-2 level-3 level-4 level-5 level-6")
				.addClass(this.entitlement.getLatencyLevel(value))
				.text(this.entitlement.getLatencyDisplayValue(value));
		},
		onConsoleLatencyWarningChanged : function(model, value){
			if(value){
				App.mainView.generalMessage({
					id         : "latency_warning",
					message    : App.i18n("console.vm.latency.warning"),
					type       : "warning"
				});
			} else {
				App.mainView.removeGeneralMessage("latency_warning");
			}
		},
		onConsoleLatencyUnstableChanged : function(model, value){
			if(value){
				this.$(".latency-current").text("-");
			}
			this.$(".latency-current").toggleClass("unstable", value);
			this.$(".latency-unstable").toggle(value);
			this.$(".connection-strength").toggleClass("unstable", value);

			if(value){
				App.mainView.generalMessage({
					id         : "latency_unstable",
					message    : App.i18n("console.vm.unstable"),
					type       : "warning"
				});
			} else {
				App.mainView.removeGeneralMessage("latency_unstable");
			}
		},
		onConsoleConnectionLostChanged : function(model, value){
			if(this.$vmGroup){
				this.$vmGroup.toggleClass("state-connection-lost", value);
			}
			if(value){
				this.$(".latency-current").text("--");
				if(this.consoleCommandsView) {
					this.consoleCommandsView.ready(false);
				}
			}
			this.$(".latency-current").toggleClass("lost", value);
			this.$(".latency-connection-lost").toggle(value);
			this.$(".connection-strength").toggleClass("lost", value);
		},
		onConnectionStrengthShow : function(){
			this.$(".indicator").tooltip("hide");
		},
		wmksDisconnectEvents : function(){
			if(this.$vmConsole && this.$vmConsole.data("wmks-wmks")){
				this.$vmConsole.find(".feedback-container").remove();
				this.$vmConsole.wmks("disconnectEvents");
			}
		},
		wmksSetupEvents : function(){
			if(this.$vmConsole && this.$vmConsole.data("wmks-wmks")){
				this.wmksDisconnectEvents();
				if(this.entitlement.get("userActionAllowed")){
					this.$vmConsole.wmks("connectEvents");
				}
			}
		}
	});

});

