define('models/catalog',['require','exports','module','backbone','library/vlp/app','library/vlp/model','library/vlp/collection','models/course','models/lab'],function (require, exports) {
	"use strict";

	//library dependencies
	var Backbone = require("backbone");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection"),
	    Course         = require("models/course"),  //jshint ignore:line
	    Lab            = require("models/lab");  //jshint ignore:line

	//Use exports for requirejs circular dependency resolving
	var Catalog = exports;

	//Add Catalog to the App.Models namespace
	App.Models.Catalog = Catalog;


	Catalog.Model = BaseModel.extend({
		urlRoot : "/catalogs",

		/**
		 * Default values for empty Models (new Catalog.Model())
		 */
		defaults :{
			labs        : []
		},

		getters : {
		},
		relations : [
			{
				key            : "labs",
				type           : Backbone.HasMany,
				relatedModel   : "Lab.Model",
				collectionType : "Lab.Collection"
			},
			{
				key            : "courses",
				type           : Backbone.HasMany,
				relatedModel   : "Course.Model",
				collectionType : "Course.Collection"
			}
		]
	});


	Catalog.Collection = BaseCollection.extend({
		active : null,

		//A collection of Catalog
		model : Catalog.Model
	});

});
