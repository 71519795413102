define('views/instructor/help-request-instructor',['require','jquery','underscore','library/vlp/app','library/vlp/view','views/instructor/raise-hand','models/instructor-help-request','hbs!tpls/instructor/help-request-instructor.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App                   = require("library/vlp/app"),
	    BaseView              = require("library/vlp/view"),
	    RaiseHandView         = require("views/instructor/raise-hand"),
	    InstructorHelpRequest = require("models/instructor-help-request"),
	    tpl                   = require("hbs!tpls/instructor/help-request-instructor.handlebars");

	return BaseView.extend({
		template: tpl,
		children: {},

		manualUpdated: false,

		el: "#help-requests",

		events: {},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize: function (options) {
			_.bindAll(this);

			options = options || {};
			options.fetchDefaults = options.fetchDefaults || {};
			this.classId = options.classId;

			this.fetchDefaults = _.extend({}, options.fetchDefaults);

			this.panel = options.panel;
			this.title = options.title;

			this.collection = new InstructorHelpRequest.Collection();

			this.collection.getHelpRequests(this.classId);

			this.listenTo(this.collection, "add", this.render);
			this.listenTo(this.collection, "remove", this.updateCounter);
			App.push.on("other:received:instructorHelpRequestInstructor:update", this.updateReceived, this);
		},

		updateCounter: function () {
			$("#raise-hand-counter").text(this.collection.length);
		},

		updateReceived: function (info) {
			var item;
			if (info.data.status == "on") {

				item = new InstructorHelpRequest.Model({
					classId      : info.data.classId,
					displayName  : info.data.displayName,
					entitlementId: info.data.entitlementId,
					id           : info.data.id,
					status       : info.data.status,
					userId       : info.data.userId,
					username     : info.data.username
				});

				this.collection.add(item);
			} else if (info.data.status == "off") {
				item = this.collection.findWhere({id: info.data.id});
				if (item) {
					item.set("status", info.data.status);
					this.collection.remove(item);
				}
			}
		},

		serialize: function () {
			var data = this.collection.toHandlebars();
			return data;
		},

		afterRender: function () {
			var self = this;
			this.$itemHolder = this.$(".messages-wrapper");

			$("#raise-hand-counter").text(this.collection.length);

			if (this.$itemHolder.length && this.collection.length) {
				this.collection.each(function (model) {
					if (model.get("id") && model.get("status") === "on") {
						var itemView = self.children[model.get("id")];

						if (!itemView) {
							itemView = new RaiseHandView({
								model: model,
								classId: self.classId
							});
							self.children[model.get("id")] = itemView;
						}

						self.$itemHolder.append(itemView.el);
						itemView.setElement(itemView.el);
						//This is hacky, but otherwise dom events seem to get detached.
						if (!itemView.rendered) {
							itemView.render();
						} else {
							itemView.afterRender();
						}
					}
				});
			}
		},

		onItemAdded: function (item) {
			var raiseHandView = new RaiseHandView({
				model: item,
				classId: this.classId
			});

			this.children[item.get("id")] = raiseHandView;

			this.$(".messages-wrapper").append(raiseHandView.render().el);
		}
	});
});

