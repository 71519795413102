define('views/compatibility/tests/storage',['require','../test','utilities/browser','hbs!tpls/compatibility/tests/storage.handlebars'],function (require) {
	"use strict";

	//class dependencies
	var TestBase = require("../test"),
		Browser  = require("utilities/browser"),
		template = require("hbs!tpls/compatibility/tests/storage.handlebars");

	return TestBase.extend({
		template : template,
		name : "storage",
		run : function(){
			var result = Browser.support.localStorage || Browser.support.sessionStorage;

			if(Browser.support.localStorage && !Browser.support.sessionStorage){
				this.warnings.sessionStorage = true;
			}
			if(!Browser.support.localStorage && Browser.support.sessionStorage){
				this.warnings.localStorage = true;
			}

			this.setStatus(result ? "success" : "warning");
			this.trigger(TestBase.COMPLETE, result);
		}
	});

});

