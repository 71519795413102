/**
 * Help Center Backbone View
 */
define('views/common/help-request',['require','underscore','jquery','library/vlp/app','library/vlp/view','models/help-request','models/entitlement'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore"),
		$ = require("jquery");

	//class dependencies
	var App         = require("library/vlp/app"),
	    BaseView    = require("library/vlp/view"),
	    HelpRequest = require("models/help-request"),
	    Entitlement = require("models/entitlement");

	return BaseView.extend({

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
		},
		update : function(){

			if(App.user.has("helpRequest")){
				this.stopListening(App.user.get("helpRequest"), "change", this.onHelpRequestChanged);
				this.listenTo(App.user.get("helpRequest"), "change", this.onHelpRequestChanged);
			}

			this.onHelpRequestChanged(App.user.get("helpRequest"));
		},
		toggle : function(){
			if (App.user.has("helpRequest")) {
				this.close();
			} else {
				this.open();
			}
		},
		open : function(){
			if(App.session.get("nextHelpRequestTime") && App.session.get("nextHelpRequestTime") > Date.now()){
				App.generalMessage({
					id           : "help_request_warning",
					type         : "warning",
					message      : App.i18n("helpRequests.tooSoon"),
					retryCallback: this.open,
					retryDelay   : (App.session.get("nextHelpRequestTime") - Date.now()) + 1
				});
				return;
			}
			var helpRequest = new HelpRequest.Model({stationId : App.station.get("stationId")});
			helpRequest.toggleOn();
			App.user.set("helpRequest", helpRequest);
			this._opened();
			if(App.config.helpRequestSubmissionDelay > 0){
				App.session.set("nextHelpRequestTime", Date.now() + App.config.helpRequestSubmissionDelay);
			}
			App.analytics.trackEvent("Support", "Raise Hand", "Open");
		},
		close : function(){
			if (App.user.has("helpRequest")) {
				App.user.get("helpRequest").toggleOff();
			}
			this._closed();
			App.analytics.trackEvent("Support", "Raise Hand", "Close");
		},
		onHelpRequestChanged : function(helpRequest){
			if(!helpRequest || helpRequest.get("state") === "closed" || helpRequest.get("state") === "resolved"){
				this._closed();
				return;
			}

			var messages = [];

			if(helpRequest.get("state") === "enroute"){
				if(helpRequest.get("proctorName")){
					messages.push(App.i18n("helpRequests.userEnRoute", helpRequest.get("proctorName")));
				} else if(helpRequest.get("captainRequest")){
					messages.push(App.i18n("helpRequests.captainEnRoute"));
				} else{
					messages.push(App.i18n("helpRequests.proctorEnRoute"));
				}
			} else if(helpRequest.get("captainRequest") === true){
				messages.push(App.i18n("helpRequests.captainRequested"));
			}


			if(helpRequest.get("state")){
				this._opened();
			}

			if(messages.length){
				$("#help_request_indicator").addClass("flash");
				_.delay(function(){
					$("#help_request_indicator").removeClass("flash");
				}, 600);
				App.generalMessage({
					id          : "help_request_message",
					type        : "info",
					message     : messages.join("\n"),
					"class"     : "alert-large pulse animated infinite slow",
					dismissible : false
				});
			} else if(helpRequest.get("state") === "new"){
				$("#help_request_indicator").removeClass("flash");
				if(App.mainView && App.mainView.generalMessages){
					App.mainView.generalMessages.removeMessage("help_request_message");
				}
			}
		},
		_opened : function(){
			this.$("#help_request").addClass("active").button("requested");
			if($("#help_request_indicator").length === 0){
				$("#wrapper").prepend("<div id=\"help_request_indicator\"><span></span><span></span><span></span><span></span></div>");
			}

			$("body").addClass("help-request");
			if(App.user.has("helpRequest")){
				this.stopListening(App.user.get("helpRequest"), "change", this.onHelpRequestChanged);
				this.listenTo(     App.user.get("helpRequest"), "change", this.onHelpRequestChanged);

				var groupColor = App.user.get("helpRequest").get("groupColor");
				if(!groupColor && App.entitlementId){
					var entitlement = Entitlement.Model && Entitlement.Model.find && Entitlement.Model.find(App.entitlementId);
					groupColor = entitlement && entitlement.get("lab") && entitlement.get("lab").get("groupColor");
				}
				if(groupColor){
					$("#help_request_indicator > span").css("background-color", groupColor);
				}
			}
		},
		_closed : function(){
			if (App.user.has("helpRequest")) {
				this.stopListening(App.user.get("helpRequest"), "change", this.onHelpRequestChanged);
			}

			$("#help_request_indicator").remove();
			this.$("#help_request").removeClass("active").button("reset");
			App.user.unset("helpRequest");
			App.session.remove("helpRequest");
			$("body").removeClass("help-request");
			if(App.mainView && App.mainView.generalMessages){
				App.mainView.generalMessages.removeMessage("help_request_message");
			}
		}
	});

});

