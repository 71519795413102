define('views/console/manual-step-listing',['require','jquery','underscore','backbone','library/vlp/app','library/vlp/view','views/console/manual-step','hbs!tpls/console/manual-step-listing.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseView       = require("library/vlp/view"),
	    StepInfoView   = require("views/console/manual-step"),
	    stepListingTPL = require("hbs!tpls/console/manual-step-listing.handlebars");

	return BaseView.extend({
		template        : stepListingTPL,
		bubbleStepCount : 7,
		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		//el : "#step_listing",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events          : {
			"click .steps-button-previous" : "onPrevStep",
			"click .steps-button-next"     : "onNextStep",
			"click #steps_navigation a"    : "onNavToStep",
			"click .step a[href^=#tag]"    : "onGotoTag",
			"click .step a[href^=#]"       : "onJumpToAnchor",
			"click .change-locale"         : "onLocaleChanged"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function (options) {
			_.bindAll(this);

			options = options || {};
			this.manual = options.manual;
			if (this.manual.get("id") && this.collection) {
				this.collection.manualId = this.manual.get("id");
			}

			this.entitlement = options.entitlement;
			this.startStepId = options.startStepId;

			this.listenTo(App.push, "received:manuals:" + this.manual.get("id") + ":node", function (info) {
				var model = this.collection.get(info.data.id);
				if (model) {
					model.set(info.data);
				}
				if (info.requestParams.entitlementId && info.requestParams[App.config.senderParameter] !== App.id) {
					this.gotoStepId(info.data.id, false);
				}
			}, this);

			this.listenTo(App.push, "sent:manuals:" + this.manual.get("id") + ":switchPage", function (info) {
				if (info.requestParams.entitlementId && info.requestParams[App.config.senderParameter] !== App.id) {

					var model = this.collection.get(info.data.id);
					if (model) {
						model.set(info.data);
					}
					this.gotoStepId(info.data.id, false);
				}
			}, this);

			if(this.entitlement){
				this.listenTo(App.push, "received:entitlements:" + this.entitlement.get("id") + ":changeStep", function(info){
					if(info.data.previous){
						this.manual.set("activeStepNumber", this.manual.get("activeStepNumber") - 1, {bookmark: true});
					} else if(info.data.next){
						this.manual.set("activeStepNumber", this.manual.get("activeStepNumber") + 1, {bookmark: true});
					} else if(info.data.stepNumber){
						this.manual.set("activeStepNumber", info.data.stepNumber - 1, {bookmark: true});
					}
				}, this);
			}

			this.listenTo(this.manual, "change:activeStepNumber", function (model, value, options) {
				var bookmark = options.bookmark;
				this.gotoStep(this.manual.get("activeStepNumber"), bookmark);
			}, this);
		},
		remove : function () {
			if (this.currentView){
				this.currentView.remove();
			}
			Backbone.View.prototype.remove.call(this);
		},
		serialize : function(){
			var data = this.collection.toHandlebars();
			var startLabelingStep = this.manual.get("startLabelingStep") ? this.manual.get("startLabelingStep") : 0;
			if(startLabelingStep > 0){
				startLabelingStep = startLabelingStep - 1;
			}

			for(var i in data){
				var step = data[i];
				if(step && step.hasOwnProperty("stepNo")){
					if(step.stepNo > startLabelingStep){
						step.stepLabel = step.stepNo - startLabelingStep;
					} else{
						step.stepLabel = "&bullet;";
					}
				}
			}

			if(this.entitlement) {
				data.remoteControlMode = this.entitlement.get("remoteControlMode");
			}
			data.alternativeLocales = this.manual.get("alternativeLocales");
			return data;
		},
		/**
		 * Display the content.
		 */
		afterRender : function () {

			this.$nextButton = this.$(".steps-button-next");
			this.$prevButton = this.$(".steps-button-previous");

			this.$stepHolder = this.$("#manuals");


			this.$stepHolder.empty();

			var currentStep = null;

			if(this.startStepId){
				var startStep = this.collection.get(this.startStepId);
				if(startStep){
					currentStep = this.collection.indexOf(startStep);
				}
			}

			if (currentStep === null) {
				currentStep = this.manual.get("activeStepNumber");
			}


			this.$bubbles = this.$("#steps_navigation ul li");
			this.$bubbles.detach();
			if(this.manual.get("activeStepNumber") === currentStep){
				this.gotoStep(currentStep);
			} else{
				this.manual.set("activeStepNumber", currentStep);
			}

			//Always return this for chaining
			return this;
		},
		gotoStepId : function (id, bookmark) {
			var step = this.collection.get(id);
			if(step){
				this.manual.set("activeStepNumber", this.collection.indexOf(step), {bookmark : bookmark});
			}

		},
		gotoStep : function (stepNumber, bookmark) {

			var self = this;
			bookmark = _.isUndefined(bookmark) ? true : bookmark;

			this.$("[rel=tooltip],.content img,.content .mediatag-thumb").tooltip("hide");
			this.$("[rel=popover]").popover("hide");
			if (stepNumber < 0) {
				stepNumber = 0;
			}
			if (stepNumber >= this.collection.length) {
				stepNumber = this.collection.length - 1;
			}

			$("#manuals .step")
				.stop(true, true)
				.removeClass("previous next");


			var step = this.collection.at(stepNumber);
			if(!step){ return; }


			var nextView = new StepInfoView({
				model         : step,
				entitlement   : this.entitlement,
				firstStep     : stepNumber === 0,
				lastStep      : stepNumber >= this.collection.length - 1
			});

			nextView.$el.removeClass("next current previous");
			if(this.$stepHolder){
				this.$stepHolder.append(nextView.render().el);
			}

			var previousView = this.currentView;
			if (previousView) {


				var previousClass, nextClass;
				if (stepNumber > this.currentStep) {
					previousClass = "previous";
					nextClass     = "next";
				} else {
					previousClass = "next";
					nextClass     = "previous";
				}

				previousView.$el
					.removeClass("previous next")
					.addClass("current transitioning")
					.switchClass("current", previousClass, {duration : App.config.stepTransition, complete : previousView.remove});

				nextView.$el
					.addClass("transitioning")
					.addClass(nextClass)
					.switchClass(nextClass, "current", {duration : App.config.stepTransition, complete : function () {
						$(this).removeClass("transitioning");
					}});
			} else {
				nextView.$el.addClass("current");
			}

			this.currentStep = stepNumber;
			this.currentView = nextView;

			if (this.currentView && bookmark) {
				this.currentView.activate({bookmark : true});
			}

			if (bookmark) {
				if(this.currentStep < this.collection.length - 1){
					this.preloadStep(this.collection.at(this.currentStep + 1));
				}

				if(this.currentStep > 0){
					this.preloadStep(this.collection.at(this.currentStep - 1));
				}
			}

			if(this.$nextButton){
				if (stepNumber >= this.collection.length - 1) {
					this.$nextButton.css("visibility", "hidden");
				} else {
					this.$nextButton.css("visibility", "visible");
				}
			}
			if(this.$prevButton) {
				if (stepNumber <= 0) {
					this.$prevButton.css("visibility", "hidden");
				} else {
					this.$prevButton.css("visibility", "visible");
				}
			}

			var stepNo = stepNumber + 1;

			if(this.$bubbles) {
				var bubblePadding = Math.round((this.bubbleStepCount - 1) / 2);

				var bubbleMin = 1;
				var bubbleMax = this.collection.length;
				if (stepNo + bubblePadding > this.collection.length) {
					bubbleMin = this.collection.length - this.bubbleStepCount + 1;
				} else if (stepNo - bubblePadding <= 0) {
					bubbleMax = this.bubbleStepCount;
				} else {
					bubbleMin = stepNo - bubblePadding;
					bubbleMax = stepNo + bubblePadding;
				}

				if (bubbleMin <= 0) {
					bubbleMin = 0;
				}
				if (bubbleMax > this.collection.length) {
					bubbleMax = this.collection.length;
				}

				var bubbles = _.range(bubbleMin, bubbleMax + 1);
				if (bubbleMin > 0) {
					bubbles.shift();
					bubbles.unshift(1);
				}
				if (bubbleMax < this.collection.length) {
					bubbles.pop();
					bubbles.push(this.collection.length);
				}


				this.$bubbles.removeClass("active").detach();
				_.each(bubbles, function(step) {
					var $bubbleItem = self.$bubbles.filter("[data-step-number=" + step + "]");

					self.$("#steps_navigation ul").append($bubbleItem);
					if (step == stepNo) {
						$bubbleItem.addClass("active");
					}

				});
			}

			this.$(".step-number").text(stepNo);
			if(this.entitlement){
				App.analytics.trackEvent("Entitlement: Manual", "Step", this.entitlement.get("sku") + " - manual:" + this.manual.get("name") + " - locale:" + this.manual.get("locale"), stepNo);
			}
		},

		onGotoTag : function(eventOrTag){
			var tag;
			if(eventOrTag && eventOrTag.preventDefault) {
				eventOrTag.preventDefault();
				if (eventOrTag.handled) { return; }
				eventOrTag.handled = true;

				if($(eventOrTag.currentTarget).data("gotoTag") !== undefined){
					tag = $(eventOrTag.currentTarget).data("gotoTag");
				} else if($(eventOrTag.currentTarget).attr("href") ){
					tag = $(eventOrTag.currentTarget).attr("href").replace(/^#(tag\/)/, "");
				}
			} else if(eventOrTag){
				tag = String(eventOrTag);
			}

			var stepId = this.manual.get("tags")[tag];
			if(stepId){
				this.gotoStepId(stepId, true);
				if(eventOrTag && eventOrTag.stopImmediatePropagation){
					eventOrTag.stopImmediatePropagation();
				}
			}
		},
		onJumpToAnchor : function(event){
			var tag = String($(event.currentTarget).attr("href")).replace(/^#/, "");
			var stepId = this.manual.get("tags")[tag];
			if(stepId){
				this.gotoStepId(stepId, true);
				if(event && event.stopImmediatePropagation){
					event.stopImmediatePropagation();
				}
			}
		},

		onNextStep : function (event) {

			if (event && event.preventDefault) {
				event.preventDefault();
				if (event.handled) { return; }
				event.handled = true;
			}
			this.manual.set("activeStepNumber", this.manual.get("activeStepNumber") + 1);
		},
		onPrevStep : function (event) {

			if (event && event.preventDefault) {
				event.preventDefault();
				if (event.handled) { return; }
				event.handled = true;
			}

			this.manual.set("activeStepNumber", this.manual.get("activeStepNumber") - 1);
		},

		onNavToStep : function (eventOrStep) {
			var stepNumber, stepTag;
			if(eventOrStep && eventOrStep.preventDefault) {
				eventOrStep.preventDefault();
				if (eventOrStep.handled) { return; }
				eventOrStep.handled = true;

				if($(eventOrStep.target).data("gotoStep") !== undefined){
					stepNumber = parseInt($(eventOrStep.target).data("gotoStep"), 10);
					if(isNaN(stepNumber)){
						stepTag = $(eventOrStep.target).data("gotoStep");
					}
				} else{
					stepNumber = parseInt($(eventOrStep.currentTarget).parent().data("stepNumber"), 10);
				}
			} else if(_.isNumber(eventOrStep)){
				stepNumber = eventOrStep;
			} else if(eventOrStep){
				stepTag = String(eventOrStep);
			}

			if(stepNumber !== undefined && !isNaN(stepNumber)){
				this.manual.set("activeStepNumber", stepNumber - 1);
			} else if(stepTag){
				this.onGotoTag(stepTag);
			}
		},
		preloadStep : function(step){
			if (!step || !step.get("partial")) { return; }

			step.set("fetchError", false);
			step.fetch({
				pushReturn : true,
				data : {
					entitlementId : this.entitlement ? this.entitlement.get("id") : null,
					languagecode : App.locale.get().toLowerCase(),
					stepNo       : step.get("stepNo")
				}
			});
		},
		onLocaleChanged : function(event){
			var locale = $(event.currentTarget).data("locale");

			App.user.set("language", locale);
			App.locale.set(App.user.get("locale"));

			App.user.save({}, {
				properties : ["language"],
				success : function(){
					delete App.tempLocale;
					App.session.remove("tempLocale");
					App.analytics.trackEvent("Account", "Language Changed", App.locale.get());
				},
				loginCancelable : true
			});
		}
	});
});

