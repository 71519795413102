/**
 * Waiting-Room Backbone View
 */
define('views/console/waiting-room',['require','jquery','underscore','library/vlp/app','views/vlp-base','hbs!tpls/console/waiting-room.handlebars','i18n!nls/waiting-room'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
		_ = require("underscore");

	//class dependencies
	var App = require("library/vlp/app"),
		VlpBaseView = require("views/vlp-base"),
		mainTPL = require("hbs!tpls/console/waiting-room.handlebars"),
		pageTexts = require("i18n!nls/waiting-room");

	return VlpBaseView.extend({
		name: "waiting-room",
		className: "general-page",
		events: {
			"click .button-lab-ready": "goToLab",
			"click .button-lab-catalog": "goToCatalog",
			"click #subscribe-for-notification": "subscribeForNotification",
			"click #leave-queue": "leaveQueue"
		},

		//Required
		template: mainTPL,

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize: function (options) {
			_.bindAll(this);
			App.locale.mergeI18n(pageTexts);

			App.push.subscribe({
				service: "entitlements",
				channel: "/entitlement/" + this.model.id
			});

			App.push.on("other:received:entitlements:" + this.model.id + ":statusChange", this.handleWaitingRoomStatus);
			App.push.on("other:received:entitlements:" + this.model.id + ":usersAheadUpdate", this.updateUsersAhead);
		},

		startTimer : function(timeRemaining){
			this.trigger("started");
			if(this.timerInterval){
				this.stopTimer();
			}
			this.startTimeRemaining = timeRemaining * 60;
			this.model.set("expirationTimeRemaining", this.startTimeRemaining);

			this.timer();
			this.timerInterval = setInterval(this.timer, 500);
		},

		stopTimer : function(){
			if(this.timerInterval){
				clearTimeout(this.timerInterval);
			}
			this.timerInterval = null;
		},

		timer: function () {
			var display;

			if (!this.startTime) {
				this.startTime = Date.now();
			}
			var now = Date.now();
			var timeDiff = (now - this.startTime) / 1000; //in seconds

			var timeRemaining = this.startTimeRemaining - timeDiff;

			if (timeRemaining < 0) {
				display = "00 : 00 : 00";
				this.model.deleteEntitlementFromQueue();
				this.stopTimer();
			} else {
				var remainingHours = Math.floor(timeRemaining / 3600);
				if (remainingHours > 24) {
					var daysRemaining = Math.floor(timeRemaining / 86400);
					display = App.i18n(daysRemaining === 1 ? "time.day" : "time.days", daysRemaining);
				} else {
					var remainingMinutes = Math.floor((timeRemaining - (remainingHours * 3600)) / 60);
					var remainingSeconds = Math.floor((timeRemaining - (remainingHours * 3600) - (remainingMinutes * 60)));
					display = _.lpad(remainingHours, 2, "0") + " : " + _.lpad(remainingMinutes, 2, "0") + " : " + _.lpad(remainingSeconds, 2, "0");
				}
			}

			this.model.set("expirationTimeRemaining", Math.max(0, timeRemaining));
			this.$(".time-remaining").text(display);

			timeRemaining *= 1000;
			if (!this.dangerShown && timeRemaining < App.config.timeRemainingDanger) {
				this.$(".time-remaining").removeClass("warning").addClass("danger");
				this.dangerShown = true;
			} else if (!this.warningShown && timeRemaining < App.config.timeRemainingWarning) {
				this.$(".time-remaining").addClass("warning");
				this.warningShown = true;
			} else if (this.warningShown && timeRemaining > App.config.timeRemainingWarning) {
				this.dangerShown = false;
				this.warningShown = false;
				this.$(".time-remaining").removeClass("warning danger");
			} else if (this.dangerShown && timeRemaining > App.config.timeRemainingDanger) {
				this.dangerShown = false;
				this.$(".time-remaining").removeClass("danger");
			} else if (this.warningShown && (timeRemaining < App.config.timeRemainingWarning) && (timeRemaining > App.config.timeRemainingDanger) && !this.$(".time-remaining").hasClass("warning")) {
				this.$(".time-remaining").addClass("warning");
			}
		},

		/**
		 * Display the content.
		 */
		render: function () {
			//Always return this for chaining
			$("body").addClass("waiting-room");
			var data = this.model.toHandlebars();
			this.$el.html(this.template(data));
			return this;
		},

		handleWaitingRoomStatus: function (info) {
			if (info.data && info.data.status === 'pending' && info.data.expirationTime > -1) {
				this.model.set('waitingRoomLabPending', true);
				this.model.get('waitingRoomData').expirationTime = info.data.expirationTime;
				this.startTimer(info.data.expirationTime);
				this.render();
			}
			if (info.data && info.data.status === 'expired') {
				this.model.set('waitingRoomLabPending', false);
				this.model.set('waitingRoomLabExpired', true);
				this.render();
			}
		},

		updateUsersAhead: function (info) {
			if (info.data && info.data.usersAhead !== -1) {
				if (this.model.has('waitingRoomData')) {
					this.model.get('waitingRoomData').usersAhead = info.data.usersAhead;
					this.render();
				}
			}
		},

		goToLab: function() {
			App.redirect({page : App.config.pages.CONSOLE, action: this.model.id});
		},

		goToCatalog: function () {
			App.redirect({page: App.config.pages.HOME});
		},

		subscribeForNotification: function (e) {
			const $target = $(e.target);
			const selected = $target.is(':checked');
			if (selected) {
				this.model.updateNotification(true);
			} else {
				this.model.updateNotification(false);
			}
		},

		leaveQueue: function () {
			this.model.leaveQueue({
				async: false
			});
			this.goToCatalog();
		}
	});
});

