/**
 * Add underscore.string functions as handlebar utilities.
 */
define('library/handlebars/underscore-string-helpers',['require','handlebars','underscore','library/underscore/underscore.string'],function (require) {
	"use strict";

	//library dependencies
	var Handlebars = require("handlebars"),
	    _          = require("underscore");

	require("library/underscore/underscore.string");

	_.each(_.keys(_.str), function(func){
		if(_.isFunction(_.str[func]) && !Handlebars.helpers[func]){
			Handlebars.registerHelper(func, function(){
				var args = _.toArray(arguments);
				args.pop(arguments);
				return _.str[func].apply(_, args);
			});
		}
	});
});
