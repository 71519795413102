define('utilities/captcha',['require','underscore','library/vlp/base-class'],function (require) {
	"use strict";

	var _ = require("underscore");

	var BaseClass       = require("library/vlp/base-class");

	return BaseClass.extend({

		mainRecaptchaUrl:        "https://www.google.com/recaptcha/api.js",
		alternativeRecaptchaUrl: "https://www.recaptcha.net/recaptcha/api.js",

		fallbackImageUrl: "captcha/image",
		fallbackSoundUrl: "captcha/sound",

		recaptchaParams : "?render=explicit&onload=recaptchaInitCallback",

		config : {},
		recaptchaLoaded : false,
		loading : false,
		initialize: function(app){
			_.bindAll(this);
			window.recaptchaInitCallback = this._recaptchaInitCallback;
			this.config = app.config;
		},
		preload : function(){
			if(!this.config.allowRegistration) { return; }

			if(this.useRecaptcha()){
				this.load();
			}
		},
		reload : function(){
			this.recaptchaLoaded = false;
			this.load();
		},
		load : function() {
			if(this.loading){
				this.trigger("loadStart");
				return;
			}


			if(!this.useRecaptcha() || this.useFallbackCaptcha()){
				return this._recaptchaLoadFallback();
			}

			if (this.recaptchaLoaded && window.grecaptcha) {
				return this._recaptchaLoadSuccess();
			}

			this.loading = true;
			this.trigger("loadStart");
			this._recaptchaLoadMainUrl();
		},
		refresh : function(){
			this.load();
		},
		reset : function(){
			if(window.grecaptcha){
				try{
					window.grecaptcha.reset();
					this.trigger("reset");
				} catch(ignore){ }
			}
		},
		render : function(){
			this.reset();
			if(window.grecaptcha){
				try{
					window.grecaptcha.render("recaptcha", {
						sitekey           : this.config.recaptchaPublicKey,
						callback          : this._success,
						size              : "invisible",
						badge             : "inline",
						"expired-callback": this._expired,
						"error-callback"  : this._error
					});
					this.trigger("render");
				} catch(ignore){ }
			}
		},
		check : function(callback){
			if(!this.isEnabled() || this.useFallbackCaptcha() || !window.grecaptcha){
				if(callback){
					callback();
				}
				return;
			}

			var response = this.getResponse();
			if(response){
				if(callback){
					callback(response);
				}
				return;
			}

			var self = this;

			window.grecaptcha.execute().then(function(){
				var token = self.getResponse();
				if(token){
					if(callback){
						callback(token);
					}
				} else {
					self.once("success", function(token){
						if(!token){
							token = self.getResponse();
						}
						if(callback){
							callback(token);
						}
					});
				}
			});
		},
		getFallbackImage : function(){
			return this.config.serverBase + this.fallbackImageUrl + "?_=" + Date.now();
		},
		getFallbackSound : function(){
			return this.config.serverBase + this.fallbackSoundUrl + "?_=" + Date.now();
		},
		isEnabled : function(){
			return this.config.useCaptcha;
		},
		useRecaptcha : function(){
			return this.isEnabled() && this.config.recaptchaEnabled && this.config.recaptchaPublicKey && !this.config.useFallbackCaptcha;
		},
		useFallbackCaptcha : function(){
			return this.isEnabled() && this.config.useFallbackCaptcha;
		},
		getResponse : function(){
			return window.grecaptcha && window.grecaptcha.getResponse();
		},
		_recaptchaLoadMainUrl : function(){
			require([this._getRecaptchaUrl(this.mainRecaptchaUrl)], this._recaptchaLoaded, this._recaptchaLoadAlternativeUrl);
		},
		_recaptchaLoadAlternativeUrl : function(){
			require([this._getRecaptchaUrl(this.alternativeRecaptchaUrl)], this._recaptchaLoaded, this._recaptchaLoadFallback);
		},
		_recaptchaLoaded : function(){
			// do nothing
		},
		_recaptchaLoadSuccess : function(){
			if(!window.grecaptcha){
				return this._recaptchaLoadFallback();
			}
			this.render();

			this.loading = false;
			this.trigger("loadEnd");
			this.trigger("ready");
		},
		_recaptchaLoadFallback : function(){
			this.config.useFallbackCaptcha = true;
			this.loading = false;

			this.trigger("loadEnd");
			this.trigger("fallback");
		},
		_recaptchaInitCallback : function(){
			this.config.useFallbackCaptcha = false;
			this.recaptchaLoaded = true;

			this._recaptchaLoadSuccess();
		},
		_getRecaptchaUrl : function(baseUrl){
			return baseUrl + this.recaptchaParams + "&_" + Date.now();
		},
		_success : function(token){
			this.trigger("success", token);
		},
		_expired : function(){
			this.trigger("expired");
		},
		_error : function(token){
			this.trigger("error", token);
		}
	});

});
