define('views/not-found/main',['require','underscore','library/vlp/app','views/vlp-base','views/common/layout-tpls','hbs!tpls/not-found/main.handlebars','i18n!nls/not-found'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//class dependencies
	var App         = require("library/vlp/app"),
	    VlpBaseView = require("views/vlp-base"),
	    LayoutTPLs  = require("views/common/layout-tpls"),
	    mainTPL     = require("hbs!tpls/not-found/main.handlebars"),
	    pageTexts   = require("i18n!nls/not-found");

	return VlpBaseView.extend({
		name : "NOT_FOUND",
		className : "general-page",

		checkLoggedIn         : false,
		checkBrowserSupported : false,
		checkBrowserRejected  : false,
		checkMaintenance      : false,
		checkEula             : false,
		checkTenantPath       : false,
		checkSystem           : false,

		layoutTemplate : LayoutTPLs.layoutEmpty,
		//Required
		mainTemplate   : mainTPL,
		events : {
		},
		initialize : function(options){
			_.bindAll(this);
			App.locale.mergeI18n(pageTexts);
			this.setup(options);
		},
		render : function(){

			//Always return this for chaining
			return this;
		}
	});

});

