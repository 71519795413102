define('views/common/general-messages',['require','jquery','underscore','backbone','library/vlp/app','hbs!tpls/common/general-messages.handlebars','utilities/gravatar'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	//class dependencies
	var App      = require("library/vlp/app"),
	    tpl      = require("hbs!tpls/common/general-messages.handlebars"),
	    gravatar = require("utilities/gravatar");

	return Backbone.View.extend({
		template : tpl,

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .retry"   : "onRetry",
			"click .confirm" : "onConfirm",
			"click .close"   : "onRemoveClick"
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(){
			_.bindAll(this);
			this.collection = new Backbone.Collection();
			this.listenTo(this.collection, "remove", this.onItemRemoved);
			this.listenTo(this.collection, "add",    this.onItemAdded);
		},
		serialize : function(){
			var data = this.collection.toJSON();
			return data;
		},
		/**
		 * Display the content.
		 */
		render : function(){

			this.$el.html(this.template(this.serialize()));

			if(this.collection.length){
				this.$el.show();
			} else{
				this.$el.hide();
			}

			//Always return this for chaining
			return this;
		},
		addMessage : function(info){
			var self = this;

			if(info.oneTime && info.key){
				if(App.session.get(info.key)){
					return;
				} else{
					App.session.set(info.key, 1);
				}
			}
			if(info.type === undefined){
				if(info.key && info.key.match(/infos?/)){
					info.type = "info";
				} else if(info.key && info.key.match(/warnings?/)){
					info.type = "warning";
				} else if(info.key && info.key.match(/errors?/)){
					info.type = "error";
				} else {
					info.type = "error";
				}
			}

			info.id = info.id || _.uniqueId("message_");
			info.hasRetryCallback = !!info.retryCallback;
			if(info.hasRetryCallback && !info.hasOwnProperty("retryCount")){
				info.retryCount = 1;
			}
			if(info.hasRetryCallback && !info.hasOwnProperty("retryDelay") || info.retryDelay < 0){
				info.retryDelay = App.config.apiRetryDelay;
			}

			if(info.autoRemove){
				info.removeDelay = 6000;
			}

			if(info.username){
				info.avatar = gravatar(info.username, {size : 40, "default" : "mm"});
			}

			if(!info.hasOwnProperty("dismissible")){
				info.dismissible = true;
			}

			var item = this.collection.get(info.id);
			if(!item){
				item = new Backbone.Model(info);
			} else{
				this.collection.remove(item);
				item.set(info);
			}
			this.collection.add(item);


			if(item.get("hasRetryCallback")){

				clearInterval(item.get("countdownInterval"));
				item.set("countdownInterval", null);

				var retryTime = Date.now() + item.get("retryDelay");
				var countdownFunction = function(){
					var time = Date.now();
					self.$("[data-id=\"" + item.get("id") + "\"] .time").text(Math.max(0, Math.round((retryTime - time) / 1000)));
					if(retryTime - (time + 1) <= 0){
						clearInterval(item.get("countdownInterval"));
						item.set("countdownInterval", null);
						self.collection.remove(item);
						if(item.get("retryCallback")){
							item.get("retryCallback")();
						}
						if(item.get("callback")){
							item.get("callback")();
						}
					}
				};
				item.set("countdownInterval", setInterval(countdownFunction, 1000));
				countdownFunction();
			}

			if(item.get("removeDelay")){
				var removeTimeout = setTimeout(function(){
					self.collection.remove(item);
				}, item.get("removeDelay"));
				item.set("removeTimeout", removeTimeout);
			}

			return item;
		},
		removeMessage : function(item){
			if(_.isString(item)){
				item = this.collection.get(item);
			}
			if(item && item instanceof Backbone.Model){
				this.collection.remove(item);
				if(item.get("callback")){
					item.get("callback")();
				}
			}
		},
		onConfirm : function(event){
			var $button = $(event.currentTarget);
			var id = $button.data("id");
			var model = this.collection.get(id);
			if(model && model.get("confirmCallback")){
				model.get("confirmCallback")();
			}
			this.removeMessage(model);
		},
		onRemoveClick : function(event){

			var $close = $(event.currentTarget);
			var id = $close.data("id");
			this.removeMessage(this.collection.get(id));
		},
		onItemAdded : function(item){

			this.render();
			var $item = this.$("[data-id=\"" + item.get("id") + "\"]");
			$item.hide().fadeIn("fast");

		},
		onItemRemoved : function(item){

			var $item = this.$("[data-id=\"" + item.get("id") + "\"]");
			$item.fadeOut("fast", function(){
				$item.remove();
			});

			if(item.get("removeTimeout")){
				clearTimeout(item.get("removeTimeout"));
				item.unset("removeTimeout");
			}

			if(item.get("countdownInterval")){
				clearInterval(item.get("countdownInterval"));
				item.set("countdownInterval", null);
			}
		}
	});

});

