define('views/catalogs/main',['require','jquery','underscore','library/vlp/app','views/vlp-base','models/account','views/catalogs/catalog-listing','views/catalogs/enrollment-listing','views/catalogs/transcript-listing','views/common/offers','views/common/announcements','views/catalogs/lab-landing','views/catalogs/lab-listing-modal','views/common/public-profile','hbs!tpls/catalogs/main.handlebars','i18n!nls/catalogs'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App                   = require("library/vlp/app"),
	    VlpBaseView           = require("views/vlp-base"),
	    Account               = require("models/account"),
	    CatalogListingView    = require("views/catalogs/catalog-listing"),
	    EnrollmentListingView = require("views/catalogs/enrollment-listing"),
	    TranscriptListingView = require("views/catalogs/transcript-listing"),
	    OffersView            = require("views/common/offers"),
	    AnnouncementsView     = require("views/common/announcements"),

	    LabLandingView        = require("views/catalogs/lab-landing"),
	    LabListingModalView   = require("views/catalogs/lab-listing-modal"),

	    PublicProfileView     = require("views/common/public-profile"),

	    mainTPL               = require("hbs!tpls/catalogs/main.handlebars"),
	    pageTexts             = require("i18n!nls/catalogs");


	return VlpBaseView.extend({
		name : "CATALOGS",

		viewport : "width=660",
		anonymousAccess        : true,
		anonymousPush          : false,
		stationPush            : true,
		checkByodSection       : true,
		showWelcomeTips        : true,

		ENROLLMENTS : "enrollments",
		TRANSCRIPT  : "transcript",
		CATALOGS    : "catalogs",
		LAB_LANDING : "lab_landing",

		mainTemplate : mainTPL,

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .lab-badges .lab-badge"                   : "showLabsByBadge",
			"click .tag"                                     : "showLabsByTag",
			"click .system"                                  : "showLabsBySystem",
			"click .product"                                 : "showLabsByProduct",
			"click .new-lab-label"                           : "showLabsByNew",
			"click .author"                                  : "showPublicProfile"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);

			App.locale.mergeI18n(pageTexts);


			this.pageNum = 1;
			this.search = options.search || "";
			//Checks logged in
			this.once(VlpBaseView.STARTUP_COMPLETE, this.startUpComplete);
			this.setup(options);
		},
		startUpComplete : function(){

			this.$(".invalid-request").hide();
			this.$(".page-content").show();
			if(!App.config.showCatalog){
				this.$("#section_select > li a[data-tab=\"catalogs\"]").parent().hide();
			}
		},

		showCatalog : function(catalogId, section, pageNum){

			if(pageNum === null || pageNum === undefined){
				pageNum = 1;
			}
			this.pageNum = parseInt(pageNum, 10);
			this.setPage(this.CATALOGS, catalogId, section);
		},
		showLab : function(labId){
			this.labId = labId;
			this.setPage(this.LAB_LANDING);
		},
		showEnrolled : function(pageNum){
			this.courseId = null;
			this.labId = null;
			if(pageNum === null || pageNum === undefined){
				pageNum = 1;
			}
			this.pageNum = parseInt(pageNum, 10);
			this.setPage(this.ENROLLMENTS);
		},
		showEnrolledLab : function(labId){
			this.pageNum = 1;
			this.labId = labId;
			this.courseId = null;
			this.setPage(this.ENROLLMENTS);
		},
		showEnrolledCourse : function(courseId){
			this.pageNum = 1;
			this.courseId = courseId;
			this.labId = null;
			this.setPage(this.ENROLLMENTS);
		},
		showTranscript : function(pageNum, search){
			if(pageNum === null || pageNum === undefined){
				pageNum = 1;
			}
			this.pageNum = parseInt(pageNum, 10);
			this.courseId = null;
			this.labId = null;
			this.search = search;
			this.setPage(this.TRANSCRIPT);
		},
		showTranscriptLab : function(labId){
			this.pageNum = 1;
			this.labId = labId;
			this.courseId = null;
			this.setPage(this.TRANSCRIPT);
		},
		showTranscriptCourse : function(courseId){
			this.pageNum = 1;
			this.courseId = courseId;
			this.labId = null;
			this.setPage(this.TRANSCRIPT);
		},
		setPage : function(page, subItemId, section){

			if(!this.isStartupComplete()){
				//Recall if not fully started up
				this.once(VlpBaseView.STARTUP_COMPLETE, function(){ this.setPage(page, subItemId, section);}, this);
				return;
			}
			if(!this.rendered){
				//Recall if not rendered yet
				this.once("afterRender", function(){ this.setPage(page, subItemId, section);}, this);
				return;
			}

			if(page === this.CATALOGS && !App.config.showCatalog){
				App.router.navigate("enrollments", {trigger : true});
				return;
			}

			$("[data-toggle=\"popover\"]").popover("hide");

			//Set main active nav
			this.$("#section_select > li")
				.removeClass("active")
				.find("a[data-tab=\"" + page + "\"]").parent().addClass("active");

			this.$("#nav_sub > ul > li,#catalog_nav > li").removeClass("active");

			if(this.currentPage && this.currentPage !== page){
				$(".modal").removeClass("fade").modal("hide");
				if(this.labListingModalView){ this.labListingModalView.remove(); }
				$("html, body").scrollTop(0);
			}

			this.currentPage = page;
			this.subItemId   = subItemId;
			this.section     = section;



			this.$(".content").not("#" + this.currentPage).hide();
			this.$("#" + this.currentPage).show();
			this.$("#main_content").toggleClass("sub-nav-hidden", this.currentPage !== this.CATALOGS);
			this.$("#section_announcements").toggle(this.currentPage !== this.LAB_LANDING);
			this.$("#section_content_wrapper").toggle(this.currentPage !== this.LAB_LANDING);


			this.setPageTitle();

			switch(this.currentPage){
				case this.CATALOGS:

					if(!this.catalogsListingView){
						this.catalogsListingView = new CatalogListingView({
							el         : this.$("#main_content")
						});
					}
					this.catalogsListingView.show(this.subItemId, this.pageNum);

					break;
				case this.ENROLLMENTS:
					if(!this.enrollmentListing){
						this.enrollmentListing = new EnrollmentListingView({
							el : "#enrollments"
						});
					}
					this.enrollmentListing.show(this.pageNum, this.labId);
					break;
				case this.TRANSCRIPT:

					if(!this.transcriptListing){
						this.transcriptListing = new TranscriptListingView({
							el : "#transcript"
						});
					}

					this.transcriptListing.show(this.pageNum, this.labId);
					break;
				case this.LAB_LANDING:
					if(!this.labLandingView){
						this.labLandingView = new LabLandingView({
							el : "#lab_landing"
						});
					}
					this.labLandingView.show(this.labId);
					break;
			}

			if(this.currentPage !== this.CATALOGS && this.catalogsListingView){
				this.catalogsListingView.hide();
			}
			if(this.currentPage !== this.LAB_LANDING && this.labLandingView){
				this.labLandingView.remove();
				this.labLandingView = null;
			}
			if(this.currentPage !== this.ENROLLMENTS && this.enrollmentListing){
				this.enrollmentListing.remove();
				this.enrollmentListing = null;
			}
			if(this.currentPage !== this.TRANSCRIPT && this.transcriptListing){
				this.transcriptListing.remove();
				this.transcriptListing = null;
			}


			if(this.currentPage !== this.LAB_LANDING){
				this.showOffersAndAnnouncements();
			}
		},
		setPageTitle : function(){

			if (this.currentPage === this.CATALOGS) {
				App.mainView.setTitle(
					"title",
					(App.config.tenantName ? App.config.tenantName : App.getTenant()),
					"catalogs.catalogs.labs.title"
				);
			} else if(this.currentPage === this.ENROLLMENTS){
				App.mainView.setTitle(
					"title",
					(App.config.tenantName ? App.config.tenantName : App.getTenant()),
					"catalogs.enrollments.title"
				);
			} else if(this.currentPage === this.TRANSCRIPT){
				App.mainView.setTitle(
					"title",
					(App.config.tenantName ? App.config.tenantName : App.getTenant()),
					"catalogs.transcript.title"
				);
			} else if(this.currentPage === this.LAB_LANDING){
				App.mainView.setTitle(
					"title",
					(App.config.tenantName ? App.config.tenantName : App.getTenant()),
					"catalogs.labLanding.title"
				);
			} else{
				App.mainView.setTitle(
					"title",
					(App.config.tenantName ? App.config.tenantName : App.getTenant())
				);
			}
		},
		showOffersAndAnnouncements: function(){
			if(App.config.offers && !this.offersView){
				this.offersView = new OffersView();
				this.offersView.fetch();
				this.off(VlpBaseView.LOGGED_IN, this.offersView.fetch).once(VlpBaseView.LOGGED_IN, this.offersView.fetch);
			}

			if(App.config.announcements && !this.announcementsView){
				this.announcementsView = new AnnouncementsView();
				this.announcementsView.fetch();
				this.off(VlpBaseView.LOGGED_IN, this.announcementsView.fetch).once(VlpBaseView.LOGGED_IN, this.announcementsView.fetch);
			}
		},

		showLabsByBadge : function(event){
			var $item = $(event.currentTarget);
			$(".modal").removeClass("fade").modal("hide");
			this.labListingModalView = new LabListingModalView({
				badge   : $item.data("badge"),
				name    : $item.data("name"),
				icon    : $item.data("icon"),
				badgeId : $item.data("id")
			});

			this.labListingModalView.show();
			App.analytics.trackEvent("Catalog", "Show: Labs by Badge", $item.data("badge"));
		},
		showLabsByTag : function(event){
			var tagId = $(event.currentTarget).data("tagId");
			var tagName = $(event.currentTarget).data("tagName");
			$(".modal").removeClass("fade").modal("hide");
			this.labListingModalView = new LabListingModalView({
				tag   : tagName,
				tagId : tagId
			});

			this.labListingModalView.show();
			App.analytics.trackEvent("Catalog", "Show: Labs by Tag", tagName);
		},
		showLabsBySystem : function(event){
			var systemId   = $(event.currentTarget).data("systemId");
			var systemName = $(event.currentTarget).data("systemName");
			$(".modal").removeClass("fade").modal("hide");
			this.labListingModalView = new LabListingModalView({
				system   : systemName,
				systemId : systemId
			});

			this.labListingModalView.show();
			App.analytics.trackEvent("Catalog", "Show: Labs by System", systemName);
		},
		showLabsByProduct : function(event){
			var productId   = $(event.currentTarget).data("productId");
			var productName = $(event.currentTarget).data("productName");
			$(".modal").removeClass("fade").modal("hide");
			this.labListingModalView = new LabListingModalView({
				productId : productId,
				product   : productName
			});

			this.labListingModalView.show();
			App.analytics.trackEvent("Catalog", "Show: Labs by Product", productName);
		},
		showLabsByNew : function(event){
			$(".modal").removeClass("fade").modal("hide");
			this.labListingModalView = new LabListingModalView({
				newLabs    : true
			});

			this.labListingModalView.show();
			App.analytics.trackEvent("Catalog", "Show: New Labs");
		},
		showPublicProfile : function(event){
			var $item = $(event.currentTarget);

			$(".modal").removeClass("fade").modal("hide");

			var author = Account.Model.findOrCreate({
				id       : $item.data("id"),
				username : $item.data("username")
			});

			if(this.publicProfileView){
				this.publicProfileView.remove();
				this.publicProfileView = null;
			}

			this.publicProfileView = new PublicProfileView({
				model : author
			});
			this.publicProfileView.show();
		}
	});

});

