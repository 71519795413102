/*globals googleAnalytics */
define('utilities/analytics/ga',['require','underscore','utilities/analytics/base'],function (require) {
	"use strict";

	var _ = require("underscore");

	var BaseClass = require("utilities/analytics/base");

	var scriptAdded = false;
	var gaInitialized = false;

	var variableMapping = {
		userId      : "userId",
		locale      : "language",
		version     : "appVersion",
		appName     : "appName",
		tenant      : "dimension1",
		trackingCode: "dimension2",
		mode        : null
	};
	return BaseClass.extend({
		mode : "production",

		initialize : function(info){
			BaseClass.prototype.initialize.apply(this, arguments);
			if(!this.enable){ return; }

			info.fields = _.defaults(info.fields || {}, {
				mode : "production"
			});

			this._initializeService(info.fields.mode);

			this.trackingId = info.apiKey;

			googleAnalytics("create", this.trackingId, "auto", this.name);

			if(info.fields){
				for(var field in info.fields){
					if(info.fields.hasOwnProperty(field)){
						this.set(field, info.fields[field]);
					}
				}
			}

			googleAnalytics(this.name + ".send", "pageview");

		},
		set : function(name, value){
			var gaName = variableMapping.hasOwnProperty(name) ? variableMapping[name] : name;
			if(gaName){
				googleAnalytics(this.name + ".set", gaName, value);
			}
		},
		_doTrackEvent : function(category, action, label, value, nonInteraction){
			googleAnalytics(this.name + ".send", {
				hitType       : "event",
				eventCategory : category,
				eventAction   : action,
				eventLabel    : label,
				eventValue    : value,
				nonInteraction: nonInteraction
			});
		},
		_doTiming : function(category, variable, value, label){
			googleAnalytics(this.name + ".send", {
				hitType        : "timing",
				timingCategory : category,
				timingVar      : variable,
				timingValue    : value,
				timingLabel    : label
			});
		},
		_initializeService : function(mode){
			if(!window.googleAnalytics){
				window.googleAnalytics = window.googleAnalytics || function(){(googleAnalytics.q=googleAnalytics.q||[]).push(arguments);};
				googleAnalytics.l = +new Date();
			}

			googleAnalytics(function(tracker) {
				// Logs the tracker created above to the console.
				gaInitialized = true;
			});
			this.mode = mode;
		},
		performanceCookiesAllowedCallback : function(){
			if(!scriptAdded){
				try{
					window.GoogleAnalyticsObject = "googleAnalytics";
					var scriptName = "analytics.js";
					if(this.mode == "development" && this.debug){
						window.ga_debug = {trace: true};
						scriptName = "analytics_debug.js";
					}
					scriptAdded = true;
					var gaScript = document.createElement("script");
					gaScript.type = "text/javascript";
					gaScript.async = true;
					gaScript.src = ("https:" === document.location.protocol ? "https://" : "http://") + "www.google-analytics.com/" + scriptName;
					var scriptTag = document.getElementsByTagName("script")[0];
					scriptTag.parentNode.insertBefore(gaScript, scriptTag);
				} catch(e){
					console.warn(e);
				}
			}
		}
	});

});

