
define('hbs!tpls/catalogs/catalog-invalid.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    return "<div class=\"catalog-box\">\n	<div class=\"alert alert-error\">\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.catalogs.invalid",{"name":"$","hash":{},"data":data}))
    + "\n	</div>\n</div>";
},"useData":true});
Handlebars.registerPartial('catalogs_catalog_invalid', t);
return t;
});

