define('views/console/manual',['require','jquery','underscore','library/vlp/app','library/vlp/view','views/console/manual-toc','views/console/manual-step-listing','models/manual','hbs!tpls/console/manual.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App              = require("library/vlp/app"),
	    BaseView         = require("library/vlp/view"),
	    ManualTocView    = require("views/console/manual-toc"),
	    StepListingView  = require("views/console/manual-step-listing"),
	    Manual           = require("models/manual"),
	    manualTPL        = require("hbs!tpls/console/manual.handlebars");

	return BaseView.extend({
		template : manualTPL,

		manualUpdated : false,

		fontSizes : [
			"font-size-smallest",
			"font-size-smaller",
			"font-size-small",
			"font-size-normal",
			"font-size-large",
			"font-size-larger",
			"font-size-largest"
		],
		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		el : "#instructions",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .make-text-smaller"       : "onDecreaseFont",
			"click .make-text-larger"        : "onIncreaseFont",
			"click .expand-all-sections"     : "onExpandAllSections",
			"click .collapse-all-sections"   : "onCollapseAllSections"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);

			$(document).on("keydown.manual", this.onKeyDown);
			if($.mobile.support.touch){
				this.$el.on("swipeleft",  this.onSwipeLeft);
				this.$el.on("swiperight", this.onSwipeRight);
			}

			this.fontSizeIndex = App.store.get("fontSizeIndex") || (this.fontSizes.length  - 1) / 2;
			this.manualId             = options.manualId;
			this.entitlement          = options.entitlement;
			this.currentStepId        = options.currentStepId;
			this.networkTopology      = options.networkTopology;
			this.panel                = options.panel;
			this.consoleListingPanel  = options.consoleListingPanel;
			this.videoConferencePanel = options.videoConferencePanel;
			this.title                = options.title;
			this.manualFilePage       = 0;
			this.manualFilePath       = options.manualFilePath;

			if(this.panel){
				this.listenTo(this.panel, "panel:float",  this.panelConvertedToFloat);
				this.listenTo(this.panel, "panel:beforeexpand", this.panelExpanding);

				this.listenTo(this.panel, "panel:dock",     this.onPanelDocked);
				this.listenTo(this.panel, "panel:float",    this.onPanelFloated);
				this.listenTo(this.panel, "panel:resized",  this.onPanelResized);
				this.listenTo(this.panel, "panel:resizing", this.onPanelResizing);
				this.listenTo(this.panel, "panel:expand",   this.onPanelExpanded);
				this.listenTo(this.panel, "panel:close",    this.onPanelClosed);
			}

			if(App.remoteControl){
				if(this.consoleListingPanel){
					this.listenTo(this.consoleListingPanel, "panel:dock",    this.checkExtraRemotePanels);
					this.listenTo(this.consoleListingPanel, "panel:float",   this.checkExtraRemotePanels);
					this.listenTo(this.consoleListingPanel, "panel:focus",   this.checkExtraRemotePanels);
					this.listenTo(this.consoleListingPanel, "panel:unfocus", this.checkExtraRemotePanels);
					this.listenTo(this.consoleListingPanel, "panel:resized", this.checkExtraRemotePanels);
					this.listenTo(this.consoleListingPanel, "panel:expand",  this.checkExtraRemotePanels);
					this.listenTo(this.consoleListingPanel, "panel:close",   this.checkExtraRemotePanels);
				}
				if(this.videoConferencePanel){
					this.listenTo(this.videoConferencePanel, "panel:dock",    this.checkExtraRemotePanels);
					this.listenTo(this.videoConferencePanel, "panel:float",   this.checkExtraRemotePanels);
					this.listenTo(this.videoConferencePanel, "panel:focus",   this.checkExtraRemotePanels);
					this.listenTo(this.videoConferencePanel, "panel:unfocus", this.checkExtraRemotePanels);
					this.listenTo(this.videoConferencePanel, "panel:resized", this.checkExtraRemotePanels);
					this.listenTo(this.videoConferencePanel, "panel:expand",  this.checkExtraRemotePanels);
					this.listenTo(this.videoConferencePanel, "panel:close",   this.checkExtraRemotePanels);
				}
				this.checkExtraRemotePanels();
			}

			this.listenTo(App.push, "other:entitlement:" + this.entitlement.get("id") + ":updateManual", this.refreshManual);
			this.listenTo(App.locale, "loadStop", this.refreshManual);

			this._setResourceBookmarkThrottled = _.debounce(this.resourcePageChanged, 300);
			window.document.addEventListener("resourcePageChanged", this._setResourceBookmarkThrottled, false);
		},

		remove : function(){
			$(document).off("keydown.manual");
			this.$el.off("swipeleft", this.onSwipeLeft);
			this.$el.off("swiperight", this.onSwipeRight);
			BaseView.prototype.remove.call(this);

		},
		/**
		 * Display the content.
		 */
		render : function(){
			var self = this;

			if(!this.manualId){
				this.model = new Manual.Model({
					id                  : this.manualId,
					networkTopology     : this.networkTopology,
					showRemoteControl   : App.config.showRemoteControl && !App.remoteControl,
					remoteControlMode   : this.entitlement.get("remoteControlMode"),
					title               : this.title,
					locale              : App.locale.get(),
					pdfManualResource   : this.entitlement.get("pdfManualResource"),
					manualFilePath      : this.manualFilePath
				});
				this.entitlement.set("manual", this.model);
				this.$el.html(this.template(this.model));
				$("#modal_topology img").attr("src", this.networkTopology);
				if((this.manualFilePath || this.entitlement.get("pdfManualResource")) && this.panel && !this.panel.previouslyExpanded && App.config.showPanels && App.config.showManualPanel){
					this.panel.expand();
					this.panel.setFocus();
				}
			} else if(!this.model){
				this.model = new Manual.Model({
					id                  : this.manualId,
					networkTopology     : this.networkTopology,
					remoteControlMode   : this.entitlement.get("remoteControlMode"),
					title               : this.title,
					locale              : App.locale.get(),
					pdfManualResource   : this.entitlement.get("pdfManualResource"),
					manualFilePath      : this.manualFilePath
				});
				this.entitlement.set("manual", this.model);
				this.fetch({
					error : function(model, response){
						self.handleFetchManualFail(self, model, response);
					},
					success : function(model, response){
						self.handleFetchManualSuccess(self, model);
					}
				});
				if((this.manualFilePath || this.entitlement.get("pdfManualResource")) && this.panel && !this.panel.previouslyExpanded && App.config.showPanels && App.config.showManualPanel){
					this.panel.expand({immediate: true});
					this.panel.setFocus();
				}
			} else{
				this.model.set("pdfManualResource", this.entitlement.get("pdfManualResource"));
				this.model.set("manualFilePath", this.manualFilePath);
				this.model.set("manualFilePage", this.manualFilePage);
				this.model.set("networkTopology", this.networkTopology);
				this.model.set("showRemoteControl", App.config.showRemoteControl && !App.remoteControl);
				this.model.set("remoteControlMode", this.entitlement.get("remoteControlMode"));
				var data = this.model.toHandlebars();
				this.$el.html(this.template(data));

				if (!this.manualFilePath) {
					if(!this.steps){
						this.steps = new StepListingView({
							el              : this.$("#step_listing"),
							collection      : this.model.get("steps"),
							manual          : this.model,
							entitlement     : this.entitlement,
							startStepId     : this.currentStepId
						});
						App.VlpGlobal.setStepsView(this.steps);
					}
					if(!this.manualToc && !this.entitlement.get("remoteControlMode")) {
						this.manualToc = new ManualTocView({
							el          : this.$("#modal_manual_toc"),
							model       : this.model,
							entitlement : this.entitlement
						});
					}

					_.defer(function(){
						if(self.steps){
							self.steps.render();
						}
						self.setFontSize();

						if(self.manualUpdated){
							self.manualUpdated = false;
							self.showManualChangedMessage();
						}
					});

					this.updateEntitlement();
					this.listenTo(this.model, "change:activeStepNumber", this.updateEntitlement);
				}

				$("#modal_topology img").attr("src", this.networkTopology);

				if(this.panel && !this.panel.previouslyExpanded && App.config.showPanels && App.config.showManualPanel){
					this.panel.expand({immediate: true});
					this.panel.setFocus();
				}

			}

			this.$("[rel=tooltip]").tooltip();
			this.setFontSize();
			//Always return this for chaining
			return this;
		},
		refreshManual : function(){
			var self = this;

			this.manualUpdated = true;
			this.$el.html("<div class=\"loading\">" + App.i18n("console.entitlement.panels.manualLoading") + "</div>");
			if(this.manualToc){
				this.manualToc.remove();
				this.manualToc = null;
			}

			if(this.steps){
				this.steps.remove();
				this.steps = null;
			}
			if(this.model.get("steps")){
				this.model.get("steps").reset();
			}
			this.model.set("locale", App.locale.get());
			this.model.set("alternativeLocales", []);
			this.fetch({
				error : function(model, response){
					self.handleFetchManualFail(self, model, response);
				},
				success : function(model, response){
					self.handleFetchManualSuccess(self, model);
				}
			});
		},

		panelConvertedToFloat : function(){
			this.stopListening(this.panel, "panel:float", this.panelConvertedToFloat);
			this.panel.$el.height($(".dock-right").height() - 20);
			this.panel.update();

		},
		panelExpanding : function(){
			var dockedPosition = this.panel.state.position;
			if($("#vm_group").hasClass("docked") && $("#vm_group").hasClass(dockedPosition == "left" ? "right" : "left")){
				var width;
				if(dockedPosition === "right"){
					width = $(window).width() - ($("#vm_group").offset().left + $("#vm_group").outerWidth(true)) - 44;
				} else{
					width = $(window).width() - ($("#vm_group").offset().left + $("#vm_group").outerWidth(true)) - 44;
				}
				this.panel.$el.width(width);
				this.panel.update();
				this.panel.trigger("panel:resizing", this.panel);
			}
		},
		checkExtraRemotePanels : function(){
			$("#instructions_panel")
				.removeClass("console-listing-panel-expanded-left")
				.removeClass("video-conference-panel-expanded-left")
				.removeClass("console-listing-panel-expanded-right")
				.removeClass("video-conference-panel-expanded-right");

			if(this.videoConferencePanel && this.videoConferencePanel.state.position === "left" && !this.videoConferencePanel.state.hidden &&  !this.videoConferencePanel.state.floating){
				$("#instructions_panel").toggleClass("video-conference-panel-expanded-left", this.videoConferencePanel.state.expanded);
			} else if(this.consoleListingPanel && this.consoleListingPanel.state.position === "left" && !this.consoleListingPanel.state.hidden && !this.consoleListingPanel.state.floating){
				$("#instructions_panel").toggleClass("console-listing-panel-expanded-left", this.consoleListingPanel.state.expanded);
			}
			if(this.videoConferencePanel && this.videoConferencePanel.state.position === "right" && !this.videoConferencePanel.state.hidden &&  !this.videoConferencePanel.state.floating){
				$("#instructions_panel").toggleClass("video-conference-panel-expanded-right", this.videoConferencePanel.state.expanded);
			} else if(this.consoleListingPanel && this.consoleListingPanel.state.position === "right" && !this.consoleListingPanel.state.hidden && !this.consoleListingPanel.state.floating){
				$("#instructions_panel").toggleClass("console-listing-panel-expanded-right", this.consoleListingPanel.state.expanded);
			}
		},
		setFontSize : function(){
			var self = this;
			if(this.fontSizeIndex < 0){
				this.fontSizeIndex = 0;
			}
			if(this.fontSizeIndex >= this.fontSizes.length){
				this.fontSizeIndex = this.fontSizes.length - 1;
			}
			_.each(this.fontSizes, function(fontSize){
				self.$("#steps_wrapper").removeClass(fontSize);
			});
			this.$("#steps_wrapper").addClass(this.fontSizes[this.fontSizeIndex]);
			App.store.set("fontSizeIndex", this.fontSizeIndex, 1);
		},


		fixResourceResizing: function (start) {
			if (this.model.get("manualFilePath") || this.model.get("pdfManualResource")) {
				var pdfManual = $("#manual_pdf_viewer");
				if (pdfManual) {
					if (start) {
						pdfManual.css("pointer-events", "none");
					} else {
						pdfManual.css("pointer-events", "unset");
					}
				}
			}
		},

		showManualChangedMessage : function(){
			var self = this;

			if((this.panel && this.panel.state.expanded) || App.remoteControl) {
				this.$(".top-buttons > .dropdown:not(.open) > a").dropdown("toggle");
				var $popover = this.$(".mc-toc").popover({
					placement : function(popover, element) {
						if (!self.panel) {
							return "right";
						} else if (self.panel.$el.hasClass("right")) {
							return "left";
						} else if (self.panel.$el.hasClass("left")) {
							return "right";
						} else if (self.panel.$el.offset().left + (self.panel.$el.outerWidth() / 2) > ($(document).width() / 2)) {
							return "left";
						} else {
							return "right";
						}
					}
				});
				$popover.popover("show");
				_.delay(function() {
					$popover.popover("hide");
				}, 7000);
			}
		},
		onDecreaseFont : function(event){
			if(event && event.preventDefault) { event.preventDefault(); }
			this.fontSizeIndex--;
			this.setFontSize();
		},
		onIncreaseFont : function(event){
			if(event && event.preventDefault) { event.preventDefault(); }
			this.fontSizeIndex++;
			this.setFontSize();
		},
		onExpandAllSections : function(event){
			if(event && event.preventDefault) { event.preventDefault(); }
			if(this.steps && this.steps.currentView){
				var stepView = this.steps.currentView;

				stepView.$(".collapsable-section").each(function(){
					var $section = $(this);
					stepView.toggleCollapsableSection($section, true, true);
				});
			}
		},
		onCollapseAllSections : function(event){
			if(event && event.preventDefault) { event.preventDefault(); }
			if(this.steps && this.steps.currentView){
				var stepView = this.steps.currentView;

				stepView.$(".collapsable-section").each(function(){
					var $section = $(this);
					stepView.toggleCollapsableSection($section, false, true);
				});
			}
		},
		onKeyDown : function(event){
			if(this.entitlement.get("remoteControlMode")) { return; }
			if(this.panel && !this.panel.state.floating && !this.panel.state.expanded) { return; }

			var targetingConsole = (event.target && event.target.parentNode && event.target.parentNode.id === "vm_console");

			if(!targetingConsole){
				if(event.which === 37 || (event.which === App.config.manualPreviousPageShortcutKey && (event.metaKey || event.ctrlKey))){
					event.preventDefault();
					this.model.set("activeStepNumber", this.model.get("activeStepNumber") - 1);
				} else if(event.which === 39 || (event.which === App.config.manualNextPageShortcutKey && (event.metaKey || event.ctrlKey) )) {
					event.preventDefault();
					this.model.set("activeStepNumber", this.model.get("activeStepNumber") + 1);
				}
			} else if(this.entitlement.get("manualShortcuts")){
				if(event.which === App.config.manualPreviousPageShortcutKey && (event.metaKey || event.ctrlKey)){
					event.preventDefault();
					event.stopImmediatePropagation();
					this.model.set("activeStepNumber", this.model.get("activeStepNumber") - 1);
				} else if(event.which === App.config.manualNextPageShortcutKey && (event.metaKey || event.ctrlKey)){
					event.preventDefault();
					event.stopImmediatePropagation();
					this.model.set("activeStepNumber", this.model.get("activeStepNumber") + 1);
				}
			} else {
				//prevent default browser behaviour
				if(event.which === App.config.manualPreviousPageShortcutKey && (event.metaKey || event.ctrlKey)){
					event.preventDefault();
				} else if(event.which === App.config.manualNextPageShortcutKey && (event.metaKey || event.ctrlKey)){
					event.preventDefault();
				}
			}
		},
		onSwipeLeft : function(event){
			if(!$.mobile.support.touch){ return; }
			if(this.entitlement.get("remoteControlMode")) { return; }
			this.model.set("activeStepNumber", this.model.get("activeStepNumber") - 1);
		},
		onSwipeRight : function(event){
			if(!$.mobile.support.touch){ return; }
			if(this.entitlement.get("remoteControlMode")) { return; }
			this.model.set("activeStepNumber", this.model.get("activeStepNumber") + 1);
		},

		onPanelDocked : function(){
			// var position = _.capitalize(this.panel.state.position);
		},
		onPanelFloated : function(){
		},
		onPanelResized : function(){
			this.fixResourceResizing(false);
		},
		onPanelResizing : function(){
			this.fixResourceResizing(true);
		},
		onPanelExpanded : function(){
		},
		onPanelClosed : function(){
		},
		updateEntitlement : function(){
			this.entitlement.set("activeStepNumber", this.model.get("activeStepNumber"));
			this.entitlement.set("totalSteps", this.model.get("steps") && this.model.get("steps").length);
		},
		onShowTOC : function(){
			$("#modal_manual_toc").modal("show");
		},
		onShowNetworkTopology : function(){
			$("#modal_topology").modal("show");
		},
		resourcePageChanged: function (event) {
			var pageNo = event.detail.page;
			var resourceId = this.manualId;
			var resourcePage = this.manualFilePage;
			var resourcePath = this.manualFilePath;
			if (!resourceId) {
				var pdfManualResource = this.entitlement.get("pdfManualResource");
				if (pdfManualResource) {
					resourceId = pdfManualResource.get("id");
					resourcePage = pdfManualResource.get("page");
					resourcePath = pdfManualResource.get("path");
				}
			}

			if (resourcePath && resourcePage !== pageNo) {
				if (this.manualFilePath) {
					this.manualFilePage = pageNo;
				} else {
					this.entitlement.get("pdfManualResource").page = pageNo;
				}
				this.entitlement.setResourceBookmarkPage(resourceId, pageNo,
					{
						success: function (result) {},
						error: function (error) { console.warn("resource bookmark update error!", error); }
					}
				);
			}
		},
		handleFetchManualSuccess: function (context, model) {
			if (model.get("manualFilePath")) {
				context.manualFilePath = model.get("manualFilePath");
				context.manualFilePage = context.entitlement.getResourceBookmarkPage(model.id);
				context.render();
			} else {
				context.manualFilePath = null;
				context.manualFilePage = 0;
				context.render();
			}
		},
		handleFetchManualFail: function (context, model, response) {
			if (response.response.errorCode === 404) {
				context.manualFilePath = null;
				context.manualFilePage = 0;
			}
			if(response.response.errorCode === 5304){
				model.set("alternativeLocales", response.response.data.tocs);
			}
			context.render();
		}
	});
});

