// adapted from https://github.com/braintree/sanitize-url/blob/main/src/index.ts
define('library/underscore/sanitize-url',['require','underscore'],function (require) {
	"use strict";
	var _  = require("underscore");
	var invalidProtocolRegex = /^([^\w]*)(javascript|data|vbscript|file)/im;
	var htmlEntitiesRegex = /&#(\w+)(^\w|;)?/g;
	var htmlCtrlEntityRegex = /&(newline|tab);/gi;
	var ctrlCharactersRegex =
		/[\u0000-\u001F\u007F-\u009F\u2000-\u200D\uFEFF]/gim;
	var urlSchemeRegex = /^.+(:|&colon;)/gim;
	var relativeFirstCharacters = [".", "/"];

	function isRelativeUrlWithoutProtocol(url){
		return relativeFirstCharacters.indexOf(url[0]) > -1;
	}

// adapted from https://stackoverflow.com/a/29824550/2601552
	function decodeHtmlCharacters(str) {
		return str.replace(htmlEntitiesRegex, function(match, dec){
			return String.fromCharCode(dec);
		});
	}

	_.mixin({
		sanitizeURL: function (url) {
			var sanitizedUrl = decodeHtmlCharacters(url || "")
				.replace(htmlCtrlEntityRegex, "")
				.replace(ctrlCharactersRegex, "")
				.trim();
	
			if (!sanitizedUrl) {
				return "";
			}
	
			if (isRelativeUrlWithoutProtocol(sanitizedUrl)) {
				return sanitizedUrl;
			}
	
			var urlSchemeParseResults = sanitizedUrl.match(urlSchemeRegex);
	
			if (!urlSchemeParseResults) {
				return sanitizedUrl;
			}
	
			var urlScheme = urlSchemeParseResults[0];
	
			if (invalidProtocolRegex.test(urlScheme)) {
				return "";
			}
	
			return sanitizedUrl;
		}
	});

	return _;
});

