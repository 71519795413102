
define('hbs!tpls/not-found/main.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    return "<div id=\"not_found\">\n	<div class=\"tenant-not-found text-error\">\n		<div class=\"error-code\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"notFound.errorCode",{"name":"$","hash":{},"data":data}))
    + "</div>\n\n		<div class=\"error-msg\">\n			<div class=\"title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"notFound.title",{"name":"$","hash":{},"data":data}))
    + "</div>\n			<div class=\"status\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"notFound.status",{"name":"$","hash":{},"data":data}))
    + "</div>\n			<div class=\"message\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"notFound.message",{"name":"$","hash":{},"data":data}))
    + "</div>\n			<button type=\"button\" class=\"btn btn-primary btn-large enroll\" onclick=\"javascript:window.history.back();\" class=\"button\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"notFound.back",{"name":"$","hash":{},"data":data}))
    + "</button>\n		</div>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('not_found_main', t);
return t;
});

