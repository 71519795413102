
define('hbs!tpls/common/system-messages-popover.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "		<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.length : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(14, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<ul>\n"
    + ((stack1 = helpers.each.call(depth0,depth0,{"name":"each","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "			</ul>\n			<a class=\"show-system-messages\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"more",{"name":"$","hash":{},"data":data}))
    + "</a>\n";
},"5":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "					<li class=\""
    + this.escapeExpression(((helper = (helper = helpers.severityClass || (depth0 != null ? depth0.severityClass : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"severityClass","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n						<div class=\"message-icon\">\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.severityClass : depth0),"green",{"name":"eq","hash":{},"fn":this.program(6, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.severityClass : depth0),"yellow",{"name":"eq","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.severityClass : depth0),"red",{"name":"eq","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "						</div>\n						<div class=\"message-content\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "							"
    + this.escapeExpression((helpers.nl2br || (depth0 && depth0.nl2br) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.contentShort : depth0),{"name":"nl2br","hash":{},"data":data}))
    + "\n						</div>\n						<hr/>\n					</li>\n";
},"6":function(depth0,helpers,partials,data) {
    return "								<span class=\"fa fa-fw fa-lg fa-info-circle\"></span>\n";
},"8":function(depth0,helpers,partials,data) {
    return "								<span class=\"fa fa-fw fa-lg fa-exclamation-triangle\"></span>\n";
},"10":function(depth0,helpers,partials,data) {
    return "								<span class=\"fa fa-fw fa-lg fa-times-circle\"></span>\n";
},"12":function(depth0,helpers,partials,data) {
    var helper;

  return "								<span class=\"message-title\">"
    + this.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper)))
    + ": </span>\n";
},"14":function(depth0,helpers,partials,data) {
    return "			<div class=\"no-messages\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"systemMessage.noMessages",{"name":"$","hash":{},"data":data}))
    + "</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"system-messages-short\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.loading : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});
Handlebars.registerPartial('common_system_messages_popover', t);
return t;
});

