define('utilities/js-logger',['require','library/vlp/base-class'],function (require) {
	"use strict";

	var BaseClass    = require("library/vlp/base-class");

	return BaseClass.extend({
		enable  : true,
		mode    : "development",
		version : "HEAD",
		user     : {},
		metaData : {},
		initialize : function(options){
			options = options || {};
			options.mode    = options.mode    || "development";
			options.version = options.version || "HEAD";
			options.enabled = options.hasOwnProperty("enabled") ? options.enabled : true;

			this.setMode(options.mode);
			this.setVersion(options.version);
			this.setEnabled(options.enabled);
			this._setupLoggers();

		},
		_setupLoggers : function(){
			this._syncLoggers();
		},
		setMode : function(value){
			this.mode = value;
			this._syncLoggers();
		},
		setVersion : function(value){
			this.version = value;
			this._syncLoggers();
		},
		setEnabled : function(value){
			this.enabled = value;

			this._syncLoggers();
		},
		setUserInfo : function(name, email){
			this.user.name  = name;
			this.user.email = email;

			this._syncLoggers();
		},
		addMetaData : function(key, value){
			this.metaData[key] = value;

		},

		_syncLoggers : function(){

		}
	});

});
