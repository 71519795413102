define('views/speed-test/speedometer',['require','underscore','library/vlp/view','hbs!tpls/speed-test/speedometer.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");
	
	var BaseView = require("library/vlp/view"),
	    tpl      = require("hbs!tpls/speed-test/speedometer.handlebars");

	return BaseView.extend({
		template    : tpl,
		
		degree0     : -118,
		degree20M   : 118,
		maxBarWidth : 44,

		speed      : 0,
		latency    : 0,
		maxSpeed   : 20000,
		maxLatency : 100,
		
		initialize : function(){
			_.bindAll(this);
			this.render();
		},
		render : function(){
			this.$el.html(this.template());
			this.setSpeed();
			this.setLatency();
		},
		reset : function(){
			this.setSpeed(0);
			this.setLatency(0);
		},
		setSpeed : function(value){
			if (value === undefined){ value = this.speed; }
			if (value > this.maxSpeed) { value = this.maxSpeed; }
			
			var ratio = value / this.maxSpeed;
			var angle = ratio * (this.degree20M - this.degree0) + this.degree0;
			this.$(".gauge-hand").css("transform", "rotate(" + angle + "deg)");
			
			this.speed = value;
		},
		setLatency : function(value){
			if (value === undefined) { value = this.speed; }
			if (value > this.maxLatency) { value = this.maxLatency; }
			
			var barWidth = (value / this.maxLatency) * this.maxBarWidth;
			this.$(".progress-bar").css("width", barWidth + "%");
			
			this.latency = value;
		}
	});
});
