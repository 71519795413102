
define('hbs!tpls/instructor/class.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<label class=\"lab-name\">"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.labs : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</label>\n";
},"2":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers.unless.call(depth0,(data && data.last),{"name":"unless","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"3":function(depth0,helpers,partials,data) {
    return ", ";
},"5":function(depth0,helpers,partials,data) {
    var helper;

  return "		<div id=\"instructor-search\">\n			<form class=\"form-search vlp-col-md-3 vlp-col-lg-3 vlp-col-xlg-3\">\n				<input type=\"search\" name=\"search\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"placeholder","instructor.entitlements.search",{"name":"$$","hash":{},"data":data}))
    + " value=\""
    + this.escapeExpression(((helper = (helper = helpers.searchVal || (depth0 != null ? depth0.searchVal : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"searchVal","hash":{},"data":data}) : helper)))
    + "\">\n				<span class=\"fa fa-search\"></span>\n			</form>\n		</div>\n	\n		<div id=\"instructors_container\" class=\"instructors_container\"></div>\n";
},"7":function(depth0,helpers,partials,data) {
    var helper;

  return this.escapeExpression(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"description","hash":{},"data":data}) : helper)));
},"9":function(depth0,helpers,partials,data) {
    return "<em>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.classInfo.empty",{"name":"$","hash":{},"data":data}))
    + "</em>";
},"11":function(depth0,helpers,partials,data) {
    var helper;

  return this.escapeExpression(((helper = (helper = helpers.notes || (depth0 != null ? depth0.notes : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"notes","hash":{},"data":data}) : helper)));
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"title\">\n	<label class=\"class-name\" data-toggle=\"modal\" data-target=\"#class-info\"><i class=\"fa fa-info-circle class-info\"></i> "
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + ":</label>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.labs : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "	<hr>\n</div>\n\n<div class=\"header\">\n"
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.instructors : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n\n<div class=\"filter\">\n	<div id=\"warn-me\" class=\"warn-me vlp-col-xs-12 vlp-col-md-8 vlp-col-sm-8 vlp-col-lg-10 vlp-col-xlg-10\">\n		<label>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.warning.label",{"name":"$","hash":{},"data":data}))
    + "\n			<input id=\"warning_progress_value\" type=\"number\" size=\"3\" min=\"0\">\n		</label>\n		<div class=\"select-wrapper\">\n			<select id=\"warning_progress_type\">\n				<option value=\"percent\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.warning.percent.label",{"name":"$","hash":{},"data":data}))
    + "</option>\n				<option value=\"step\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.warning.step.label",{"name":"$","hash":{},"data":data}))
    + "</option>\n			</select>\n			<span class=\"fa fa-angle-down\"></span>\n		</div>\n	</div>\n\n	<button type=\"button\" class=\"btn btn-default blank-all-screens vlp-col-xs-12 vlp-col-md-3 vlp-col-sm-4 vlp-col-lg-2 vlp-col-xlg-2\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-enable-text","instructor.class.enableAllScreens",{"name":"$$","hash":{},"data":data}))
    + ">\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.blankAllScreens",{"name":"$","hash":{},"data":data}))
    + "\n	</button>\n</div>\n\n<div id=\"entitlement_listing\" class=\"student-entitlements\"></div>\n\n<!-- Class Info Modal -->\n<div id=\"class-info\" class=\"modal fluid-height fade global hide\" role=\"dialog\">\n	<div class=\"modal-dialog\">\n\n		<!-- Modal content-->\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\">&times;</button>\n				<h4 class=\"modal-title\"><i class=\"fa fa-info-circle\"></i> "
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h4>\n			</div>\n			<div class=\"modal-body\">\n				<dl class=\"dl-horizontal\">\n					<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.classInfo.description",{"name":"$","hash":{},"data":data}))
    + "</dt>\n					<dd>"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "</dd>\n\n					<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.classInfo.notes",{"name":"$","hash":{},"data":data}))
    + "</dt>\n					<dd>"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.notes : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "</dd>\n\n					<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.classInfo.seats",{"name":"$","hash":{},"data":data}))
    + "</dt>\n					<dd>"
    + this.escapeExpression(((helper = (helper = helpers.numberOfSeats || (depth0 != null ? depth0.numberOfSeats : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"numberOfSeats","hash":{},"data":data}) : helper)))
    + "</dd>\n\n					<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.classInfo.startDate",{"name":"$","hash":{},"data":data}))
    + "</dt>\n					<dd>"
    + this.escapeExpression((helpers.date_format || (depth0 && depth0.date_format) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.startDate : depth0),"dateFormatFull",{"name":"date_format","hash":{},"data":data}))
    + "</dd>\n\n					<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.class.classInfo.endDate",{"name":"$","hash":{},"data":data}))
    + "</dt>\n					<dd>"
    + this.escapeExpression((helpers.date_format || (depth0 && depth0.date_format) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.endDate : depth0),"dateFormatFull",{"name":"date_format","hash":{},"data":data}))
    + "</dd>\n\n				</dl>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('instructor_class', t);
return t;
});

