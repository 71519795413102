
define('hbs!tpls/catalogs/certificate-modal.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var helper;

  return "						<p class=\"short-description user-data\">\n							"
    + this.escapeExpression(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "\n						</p>\n";
},"3":function(depth0,helpers,partials,data) {
    return "							<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.progress",{"name":"$","hash":{},"data":data}))
    + "</dt>\n							<dd class=\"skin-text-popout\">"
    + this.escapeExpression((helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.progress : depth0),{"name":"percent","hash":{},"data":data}))
    + "</dd>\n";
},"5":function(depth0,helpers,partials,data) {
    return "							<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.timeElapsed",{"name":"$","hash":{},"data":data}))
    + "</dt>\n							<dd class=\"skin-text-popout\">\n								"
    + this.escapeExpression((helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.timeElapsed : depth0),{"name":"daysHoursMinutes","hash":{},"data":data}))
    + "\n							</dd>\n";
},"7":function(depth0,helpers,partials,data) {
    return "							<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.language",{"name":"$","hash":{},"data":data}))
    + "</dt>\n							<dd class=\"skin-text-popout\">"
    + this.escapeExpression((helpers.capitalize || (depth0 && depth0.capitalize) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.language : depth0),{"name":"capitalize","hash":{},"data":data}))
    + "</dd>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"modal fade global fluid-height hide lab-certificate\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-thumbs-o-up\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.transcript.certificate.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n				<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.transcript.certificate.completion",{"name":"$","hash":{},"data":data}))
    + "</h2>\n				<img src=\""
    + this.escapeExpression(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"alt","catalogs.labs.thumbnailAlt",(depth0 != null ? depth0.name : depth0),{"name":"$$","hash":{},"data":data}))
    + "/>\n\n				<h2>"
    + this.escapeExpression(((helper = (helper = helpers.userDisplayName || (depth0 != null ? depth0.userDisplayName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"userDisplayName","hash":{},"data":data}) : helper)))
    + "</h2>\n				<h3>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.transcript.date",{"name":"$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.date_format || (depth0 && depth0.date_format) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.completionDateTime : depth0),"dateFormatFull",{"name":"date_format","hash":{},"data":data}))
    + "</h3>\n				<hr />\n				<h3 class=\"skin-text-popout user-data\">"
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n				<div class=\"clearfix common-metadata\">\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.shortDescription : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n					<dl class=\"dl-horizontal\">\n\n\n"
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.progress : depth0),undefined,{"name":"ne","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.timeElapsed : depth0),undefined,{"name":"ne","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.language : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n\n					</dl>\n				</div>\n\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('catalogs_certificate_modal', t);
return t;
});

