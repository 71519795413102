
define('hbs!tpls/catalogs/main.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    return "<div class=\"invalid-request alert alert-error hide\">\n	"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"errors.invalidRequest",{"name":"$","hash":{},"data":data}))
    + "\n</div>\n<div class=\"page-content clearfix\">\n	<div id=\"menus\">\n\n		<nav id=\"nav_main\">\n			<ul id=\"section_select\">\n				<li>\n					<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"enrollments"},"data":data}))
    + "\" data-tab=\"enrollments\">\n						<div><span class=\"fa fa-2x fa-pencil\"></span></div>\n						<span class=\"title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.enrollments.title",{"name":"$","hash":{},"data":data}))
    + "</span>\n					</a>\n				</li>\n				<li>\n					<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"catalog"},"data":data}))
    + "\" data-tab=\"catalogs\">\n						<div class=\"badge badge-info all-labs-new-count hide\"></div>\n						<div><span class=\"fa fa-2x fa-flask\"></span></div>\n						<span class=\"title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.catalogs.title",{"name":"$","hash":{},"data":data}))
    + "</span>\n					</a>\n				</li>\n				<li>\n					<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"transcript"},"data":data}))
    + "\" data-tab=\"transcript\">\n						<div><span class=\"fa fa-2x fa-clipboard\"></span></div>\n						<span class=\"title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.transcript.title",{"name":"$","hash":{},"data":data}))
    + "</span>\n					</a>\n				</li>\n			</ul>\n\n			<a class=\"vmware-logo\" href=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"learnMoreUrl",{"name":"config","hash":{},"data":data}))
    + "\" target=\"_blank\"></a>\n		</nav>\n		<nav id=\"nav_sub\">\n			<ul id=\"catalog_nav\">\n				<li><div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div></li>\n			</ul>\n		</nav>\n\n\n	</div>\n\n\n	<div id=\"catalog_sections\" class=\"clearfix\">\n		<div id=\"lab_landing\" class=\"content\">\n			<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labLanding.loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n		</div>\n\n\n		<div id=\"section_content_wrapper\">\n			<div id=\"section_content\">\n				<div id=\"enrollments\" class=\"content hide\">\n					<div class=\"loading well\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.enrollments.loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n				<div id=\"catalogs\" class=\"content hide\">\n					<div class=\"loading well\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.catalogs.loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n				<div id=\"transcript\" class=\"content hide\">\n					<div class=\"loading well\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.enrollments.loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n			</div>\n		</div>\n\n		<div id=\"section_announcements\">\n			<div class=\"announcements\">\n				<div class=\"header-bar inverted\">\n					<h3>\n						<span class=\"fa fa-bullhorn skin-icon\"></span>\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"announcements.title",{"name":"$","hash":{},"data":data}))
    + "\n					</h3>\n				</div>\n\n				<div id=\"announcements\">\n					<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"announcements.loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n			</div>\n			<div class=\"offers\">\n				<div class=\"header-bar inverted\">\n					<h3>\n						<span class=\"fa fa-comments skin-icon\"></span>\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"offers.title",{"name":"$","hash":{},"data":data}))
    + "\n					</h3>\n				</div>\n\n				<div id=\"offers\">\n\n					<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"offers.loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n<div id=\"modal_lab_listing\" class=\"modal fade global lab-listing-modal multi-item fluid-height\"></div>\n";
},"useData":true});
Handlebars.registerPartial('catalogs_main', t);
return t;
});

