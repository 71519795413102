
define('hbs!tpls/layout/global-components.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    return "<div id=\"checking_status\">\n	<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"checkingStatus",{"name":"$","hash":{},"data":data}))
    + "</div>\n	<div class=\"system-error hide\">\n		<div class=\"error-msg\">\n			<div class=\"title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"error",{"name":"$","hash":{},"data":data}))
    + "</div>\n			<div class=\"status\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"errors.general",{"name":"$","hash":{},"data":data}))
    + "</div>\n			<div class=\"message\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"errors.getSystem",{"name":"$","hash":{},"data":data}))
    + "</div>\n			<button type=\"button\" class=\"btn btn-primary btn-large enroll\"onclick=\"javascript:window.self.location.reload();\" class=\"button\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"refresh",{"name":"$","hash":{},"data":data}))
    + "</button>\n		</div>\n	</div>\n	<div class=\"tenant-not-found hide\">\n		<div class=\"error-code\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"notFound.errorCode",{"name":"$","hash":{},"data":data}))
    + "</div>\n\n		<div class=\"error-msg\">\n			<div class=\"title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"notFound.title",{"name":"$","hash":{},"data":data}))
    + "</div>\n			<div class=\"status\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"errors.tenantInvalid",{"name":"$","hash":{},"data":data}))
    + "</div>\n			<div class=\"message\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"errors.tenantNotFound",{"name":"$","hash":{},"data":data}))
    + "</div>\n			<button type=\"button\" class=\"btn btn-primary btn-large enroll\"onclick=\"javascript:window.history.back();\" class=\"button\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"notFound.back",{"name":"$","hash":{},"data":data}))
    + "</button>\n		</div>\n	</div>\n</div>\n\n<div id=\"tenant_eula\" class=\"hide\"></div>\n<div id=\"general_messages\" class=\"hide\"></div>\n";
},"useData":true});
Handlebars.registerPartial('layout_global_components', t);
return t;
});

