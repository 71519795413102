
define('hbs!tpls/catalogs/lab/invite-popover.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var helper;

  return "		<div>\n			<a class=\"small-share\" data-share-url=\""
    + this.escapeExpression(((helper = (helper = helpers.shareUrl || (depth0 != null ? depth0.shareUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"shareUrl","hash":{},"data":data}) : helper)))
    + "\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-share-text","share.invite.shareText",{"name":"$$","hash":{"user_name":(depth0 != null ? depth0.userName : depth0),"lab_name":(depth0 != null ? depth0.name : depth0)},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-share-title","share.invite.shareTitle",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-description","share.invite.description",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","share.invite.buttonShare",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","share.invite.buttonShare",{"name":"$$","hash":{},"data":data}))
    + ">\n				<span class=\"fa-stack fa-lg\">\n					<i class=\"fa fa-circle fa-stack-2x\"></i>\n					<i class=\"fa fa-share-alt fa-stack-1x fa-inverse\"></i>\n				</span>\n				<span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"share.invite.buttonShare",{"name":"$","hash":{},"data":data}))
    + "</span>\n			</a>\n		</div>\n		<hr />\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"share-options\">\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"browserShare",{"name":"config","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "	<div class=\"share-url copy-value\">\n		<label>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"share.invite.directUrlTitle",{"name":"$","hash":{},"data":data}))
    + "</label>\n		<input type=\"url\" readonly value=\""
    + this.escapeExpression(((helper = (helper = helpers.shareUrl || (depth0 != null ? depth0.shareUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"shareUrl","hash":{},"data":data}) : helper)))
    + "\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","share.invite.directUrlTip",{"name":"$$","hash":{},"data":data}))
    + " />\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('catalogs_lab_invite_popover', t);
return t;
});

