define('library/jquery/redraw',["jquery"], function($){
	"use strict";
		
	var wait = 250;
	var redraw = function(){
		$("<style></style>").appendTo(document.body).remove();
	};
	var timeout = null;
	var previous = 0;
	var later = function(){
		previous = new Date();
		timeout = null;
		redraw();
	};

	$.fn.redraw = function(){
		if(this.is(":visible") && this[0]){
			var now = new Date();
			var remaining = wait - (now - previous);
			if(remaining <= 0){
				clearTimeout(timeout);
				timeout = null;
				previous = now;
				redraw();
			} else if(!timeout){
				timeout = setTimeout(later, remaining);
			}
		}

		return this;
	};
});
