define('library/utilities/log',['require','underscore','backbone','library/vlp/base-class','utilities/browser'],function (require) {
	"use strict";

	var _        = require("underscore"),
	    Backbone = require("backbone");

	var BaseClass    = require("library/vlp/base-class"),
	    Browser      = require("utilities/browser");


	var consoleMethodsToOverride = ["debug", "log", "info", "warn", "error", "trace", "group", "groupCollapsed", "groupEnd", "assert"];
	var originalConsoleMethods = null;

	var emptyConsoleMethod = function(){};

	var validCssStyles = [
		"background", "border(\-[a-z\-]*)?", "box-shadow",
		"clear", "color", "cursor", "display", "font(\-[a-z-]*)?", "line-height", "margin(\-[a-z-]*)?", "outline(\-[a-z-]*)?", "padding(\-[a-z-]*)?",
		"text\-[a-z-]+", "white-space", "word-break", "word-spacing", "writing-mode"
	];

	return BaseClass.extend({
		maxExpandObjectLength : 100,
		groups: [],
		initialize : function(app){
			_.bindAll(this);

			this.session = app.session;

			if(window.console){
				if(!originalConsoleMethods){
					originalConsoleMethods = {};
					_.each(consoleMethodsToOverride, function (method) {
						if(!console[method]){
							console[method] = console.log || emptyConsoleMethod;
						}
						originalConsoleMethods[method] = console[method];
						console["_" + method] = console[method];
					});
				}
				console.debugForce = this.debugForce;
				console.infoForce  = this.infoForce;
				console.logForce   = this.logForce;
				console.warnForce  = this.warnForce;
				console.errorForce = this.errorForce;
				console.traceForce = this.traceForce;
				console.setLevel   = this.setLogLevel;

				console.setMaxExpandObjectLength = this.setMaxExpandObjectLength;
			}

			if(this.session && this.session.get("appLogLevel")){
				this._doSetLogLevel(this.session.get("appLogLevel"), false);
			} else if(app.mode === "production"){
				//suppress console logs in production
				this._doSetLogLevel("warn", false);
			} else{
				this._doSetLogLevel("log", false);
			}
			if(this.session && this.session.get("maxExpandObjectLength") !== null){
				this.maxExpandObjectLength = this.session.get("maxExpandObjectLength");
			}

			app.setLogLevel = this.setLogLevel;


			this.cssStyleMatcher = new RegExp("^" + validCssStyles.join("$|^") + "$");
		},
		debug: function(message){
			try{
				this._doLog("debug", false, _.toArray(arguments));
			} catch(e){
				try{
					originalConsoleMethods.debug.apply(console, arguments);
				} catch(doNothing){}
			}
		},
		debugForce: function(message){
			try{
				this._doLog("debug", true, _.toArray(arguments));
			} catch(e){
				try{
					originalConsoleMethods.debug.apply(console, arguments);
				} catch(doNothing){}
			}
		},
		info: function(message){
			try{
				this._doLog("info", false, _.toArray(arguments));
			} catch(e){
				try{
					originalConsoleMethods.info.apply(console, arguments);
				} catch(doNothing){}
			}
		},
		infoForce: function(message){
			try{
				this._doLog("info", true, _.toArray(arguments));
			} catch(e){
				try{
					originalConsoleMethods.info.apply(console, arguments);
				} catch(doNothing){}
			}
		},
		log: function(message){
			try{
				this._doLog("log", false, _.toArray(arguments));
			} catch(e){
				try{
					originalConsoleMethods.log.apply(console, arguments);
				} catch(doNothing){}
			}
		},
		logForce: function(message){
			try{
				this._doLog("log", true, _.toArray(arguments));
			} catch(e){
				try{
					originalConsoleMethods.log.apply(console, arguments);
				} catch(doNothing){}
			}
		},
		warn: function(message){
			try{
				this._doLog("warn", false, _.toArray(arguments));
			} catch(e){
				try{
					originalConsoleMethods.warn.apply(console, arguments);
				} catch(doNothing){}
			}
		},
		warnForce: function(message){
			try{
				this._doLog("warn", true, _.toArray(arguments));
			} catch(e){
				try{
					originalConsoleMethods.warn.apply(console, arguments);
				} catch(doNothing){}
			}
		},
		error: function(message){
			try{
				this._doLog("error", false, _.toArray(arguments));
			} catch(e){
				try{
					originalConsoleMethods.error.apply(console, arguments);
				} catch(doNothing){}
			}
		},
		errorForce: function(message){
			try{
				this._doLog("error", true, _.toArray(arguments));
			} catch(e){
				try{
					originalConsoleMethods.error.apply(console, arguments);
				} catch(doNothing){}
			}
		},
		trace: function(message){
			try{
				this._doLog("trace", false, _.toArray(arguments));
			} catch(e){
				try{
					originalConsoleMethods.trace.apply(console, arguments);
				} catch(doNothing){}
			}
		},
		traceForce: function(message){
			try{
				this._doLog("trace", true, _.toArray(arguments));
			} catch(e){
				try{
					originalConsoleMethods.trace.apply(console, arguments);
				} catch(doNothing){}
			}
		},
		group: function(groupTitle){
			try{
				this._doLog("group", false, _.toArray(arguments));
			} catch(e){
				try{
					originalConsoleMethods.group.apply(console, arguments);
				} catch(doNothing){}
			}
		},
		groupCollapsed: function(groupTitle){
			try{
				this._doLog("groupCollapsed", false, _.toArray(arguments));
			} catch(e){
				try{
					originalConsoleMethods.groupCollapsed.apply(console, arguments);
				} catch(doNothing){}
			}
		},
		groupEnd: function(groupTitle){
			try{
				this._doLog("groupEnd", false, _.toArray(arguments));
			} catch(e){
				try{
					originalConsoleMethods.groupEnd.apply(console, arguments);
				} catch(doNothing){}
			}
		},
		assert: function(assertion){
			try{
				this._doLog("assert", false, _.toArray(arguments));
			} catch(e){
				try{
					originalConsoleMethods.assert.apply(console, arguments);
				} catch(doNothing){}
			}
		},
		setLogLevel : function(level){
			this._doSetLogLevel(level, true);
		},

		setMaxExpandObjectLength : function(maxLength, saveToSession){
			this.maxExpandObjectLength = maxLength;
			if((saveToSession || saveToSession === undefined) && this.session){
				this.session.set("maxExpandObjectLength", maxLength, 2);
			}
		},

		_doLog : function(logLevel, force, args){
			if(!window.console){ return; }

			if(!console.hasOwnProperty(logLevel)){
				logLevel = "log";
			}

			if(!force && console[logLevel] === emptyConsoleMethod){ return; }

			if(!args) { args = []; }

			var consoleArgs = [];

			if(logLevel === "assert" && args.length > 0){
				consoleArgs.push(args.shift());
			}


			var message = null;
			if(args.length > 0 && _.isString(args[0])){
				message = args.shift();
			}

			var styles = "";
			if(message && message.indexOf("%c") === -1){
				if(args.length >= 2 && _.isColor(args[args.length - 2]) && _.isNumber(args[args.length - 1])){
					styles+= "font-size:" + args.pop() + "px;";
					styles+= "color:" + args.pop() + ";";
				} else if(args.length >= 1 && _.isColor(args[args.length - 1])){
					styles+= "color:" + args.pop() + ";";
				} else if(args.length >= 1 && this._hasCssStyles(args[args.length - 1])){
					styles+= this._getCssStyles(args.pop());
				}
			}

			var messageParts = [];
			var dataArgs = [];
			_.each(args, function(data){
				var logData = this._logGetData(data, args.length === 1);
				if(logData.message !== null){
					messageParts = messageParts.concat(logData.message);
				}
				dataArgs = dataArgs.concat(logData.args);
			}, this);

			message = (messageParts.length ? (message ? message + ": " : "") + messageParts.join(", ") : message);

			if(message !== null){
				if(styles && !Browser.msie){
					consoleArgs.push("%c" + message, styles);
				} else {
					consoleArgs.push(message);
				}
			}
			consoleArgs = consoleArgs.concat(dataArgs);

			if(logLevel === "group" || logLevel === "groupCollapse"){
				this.groups.push({type : logLevel, args: consoleArgs});
			} else if(logLevel === "groupEnd") {
				this.groups.pop();
				originalConsoleMethods[logLevel].apply(console, consoleArgs);
			} else {
				while(this.groups.length){
					var group = this.groups.shift();
					originalConsoleMethods[group.type].apply(console, group.args);
				}
				originalConsoleMethods[logLevel].apply(console, consoleArgs);
			}
		},
		_logGetData : function(data, expand){
			var result = {message: null, args: []};

			if(data instanceof Error){
				result.args.push(this._logConvertData(data));
			} else if(_.isPlainObject(data) && expand && String(JSON.stringify(data)).length <= this.maxExpandObjectLength){
				var messageProps = [];
				for(var prop in data){
					var value = data[prop];
					var formatType = (_.isNumber(value) || _.isString(value) ? "%s" : "%O");
					messageProps.push(prop + "=" + formatType);
					result.args.push(this._logConvertData(value));
				}
				result.message = messageProps.join(", ");
			} else {
				result.args.push(this._logConvertData(data));
			}
			return result;
		},
		_logConvertData : function(data){
			if(data instanceof Backbone.Model){
				return data.toJSON();
			} else {
				return data;
			}
		},
		_hasCssStyles : function(object){
			var style;
			if(_.isPlainObject(object)){
				for(style in object){
					if(this.cssStyleMatcher.exec(_.dasherize(style))){
						return true;
					}
				}
			} else if(_.isString(object)){
				var styles = object.split(";");
				for(var i = 0; i < styles.length; i++){
					style = styles[i].split(":")[0];
					if(style && this.cssStyleMatcher.exec(_.dasherize(style.trim()))){
						return true;
					}
				}
			}
			return false;
		},
		_getCssStyles : function(object){
			var result = "";
			if(_.isPlainObject(object)){
				var stylesObj = object;
				_.each(stylesObj, function(value, style){
					value = (_.isNumber(value) ? String(value) + "px" : String(value));
					result+= _.dasherize(style) + ":" + value + ";";
				});
			} else if(_.isString(object)){
				result = object;
			}
			return result;
		},
		_doSetLogLevel : function(level, saveToSession){
			try{
				if(!level) { level = "log"; }
				level = level.toLowerCase();

				if(level === "trace"){
					level = "debug";
				} else if(level === "info"){
					level = "log";
				} else if(level === "warning"){
					level = "warn";
				}

				if(saveToSession && this.session){
					this.session.set("appLogLevel", level, 2);
				}

				if(!window.console || !originalConsoleMethods) { return; }

				var consoleMethods = [];
				// switch statement deliberately falls through.
				/* jshint ignore:start */
				switch(level){
					case "debug":
						consoleMethods.push("debug");
					case "log": case "info": default:
						consoleMethods.push("log", "info", "trace");
					case "warn": case "warning":
						consoleMethods.push("warn");
					case "error":
						consoleMethods.push("error", "assert", "group", "groupCollapsed", "groupEnd");
					case "none":
						//do nothing
						break;
				}
				/* jshint ignore:end */

				//disable console methods
				_.each(_.keys(originalConsoleMethods), function(consoleMethod){
					console[consoleMethod] = emptyConsoleMethod;
				});

				var self = this;
				//restore console methods
				_.each(consoleMethods, function(consoleMethod){
					console[consoleMethod] = self[consoleMethod];
				});

			} catch(e){
				//ignore
			}
		}
	});

});

