/**
 * This is a general application bootstrap.
 * Define App.run before including this script.
 * <code>
 *  App = {};
 *  App.run = function(){
 *      require(["config", "routers/explorer"], function(config, Explorer){
 *          //Start running the Backbone code (with options)
 *          var explore = new Explorer(config);
 *      });
 *  };
 * </code>
 */
/*globals Backbone,$,_,App,require*/
require.config({
	baseUrl : "/scripts/",
	wrapShim: true,
	waitSeconds : 60,
	paths : {
		text                  : "library/require/text",
		i18n                  : "library/require/i18n",
		hbs                   : "library/require/hbs",
		jquery                : "library/jquery/jquery",
		"jquery-ui"           : "library/jquery/jquery-ui-fix",
		underscore            : "library/underscore/underscore",
		backbone              : "library/backbone/backbone",
		"handlebars"          : "library/handlebars/backbone-integration",
		"handlebars-original" : "library/handlebars/handlebars"
	},
	shim : {
		"library/underscore/underscore.string" : {
			deps : ["underscore"],
			exports : "_",
			init : function(_){
				"use strict";
				var underscoreStringExports = _.str.exports();
				delete underscoreStringExports.toNumber;
				_.mixin(underscoreStringExports);
			}
		},

		"library/jquery/jquery.raty" : {
			deps : ["jquery"],
			init : function($){
				"use strict";
				$.fn.raty.defaults.path       = "/images/common/ratings/";
				$.fn.raty.defaults.halfShow   = true;
				$.fn.raty.defaults.number     = 5;
				$.fn.raty.defaults.hints      = [null];
				$.fn.raty.defaults.noRatedMsg = "";
			}
		},

		"library/wmks"                           : {deps : ["jquery"]},
		"library/jquery/jquery-ui.custom"        : {deps : ["jquery"]},
		"library/jquery/jquery.maphilight"       : {deps : ["jquery"]},
		"library/jquery/jquery.timezone-picker"  : {deps : ["jquery", "library/jquery/jquery.maphilight"]},
		"library/jquery/jquery.qrcode.min"       : {deps : ["jquery"]},

		"library/bootstrap/bootstrap-transition" : {deps : ["jquery"]},
		"library/bootstrap/bootstrap-affix"      : {deps : ["jquery"]},
		"library/bootstrap/bootstrap-dropdown"   : {deps : ["jquery"]},
		"library/bootstrap/bootstrap-tab"        : {deps : ["jquery"]},
		"library/bootstrap/bootstrap-modal"      : {deps : ["jquery"]},
		"library/bootstrap/bootstrap-alert"      : {deps : ["jquery"]},
		"library/bootstrap/bootstrap-collapse"   : {deps : ["jquery", "library/bootstrap/bootstrap-transition"]},
		"library/bootstrap/bootstrap-button"     : {deps : ["jquery", "jquery-ui"]},
		"library/bootstrap/bootstrap-tooltip"    : {deps : ["jquery"]},
		"library/bootstrap/bootstrap-popover"    : {deps : ["jquery", "library/bootstrap/bootstrap-tooltip"]},
		"library/bootstrap/bootstrap-carousel"   : {deps : ["jquery"]},

		"library/highcharts/highcharts" : {exports: "Highcharts"},
		"library/highcharts/highcharts-more" : {deps : ["library/highcharts/highcharts"]},
		"library/highcharts/no-data-to-display" : {deps : ["library/highcharts/highcharts"]},

		"library/utilities/screenfull" : {exports: "screenfull"},
		"library/utilities/mdetect" : {exports: "MobileEsp"},
		"library/cssbeautify"       : {exports : "cssbeautify"},
		"library/diff"              : {exports : "JsDiff"},

		"library/hopscotch" : {exports : "hopscotch"}
	},
	deps    :[
		"library/polyfills/ResizeObserver.global",
		"jquery",
		"jquery-ui",
		"library/jquery/jquery.mobile",
		"library/jquery/jquery.maphilight",
		"library/jquery/jquery.timezone-picker",
		"library/jquery/jquery.qrcode.min",
		"library/jquery/jquery.raty",
		"library/jquery/redraw",


		"underscore",
		"library/underscore/underscore.string",

		"backbone",
		"library/backbone/backbone-relational",

		"handlebars",
		"library/handlebars/misc-helpers",
		"library/handlebars/string-helpers",
		"library/handlebars/underscore-string-helpers",
		"library/handlebars/date-format",

		"library/underscore/bind-all",

		"library/underscore/parse-query-string",
		"library/underscore/parse-url",
		"library/underscore/sanitize-url",
		"library/underscore/is-index-array",
		"library/underscore/is-plain-object",
		"library/underscore/is-color",
		"library/underscore/data-convert",
		"library/underscore/math",
		"library/underscore/key-codes-to-scan-codes",


		"library/bootstrap/bootstrap-transition",
		"library/bootstrap/bootstrap-affix",
		"library/bootstrap/bootstrap-dropdown",
		"library/bootstrap/bootstrap-tab",
		"library/bootstrap/bootstrap-modal",
		"library/bootstrap/bootstrap-alert",
		"library/bootstrap/bootstrap-collapse",
		"library/bootstrap/bootstrap-button",
		"library/bootstrap/bootstrap-tooltip",
		"library/bootstrap/bootstrap-popover",
		"library/bootstrap/bootstrap-carousel"
	],
	callback : function(){
		"use strict";

		$.noConflict();
		Backbone.noConflict();
		_.noConflict();


		//Setup locale
		try{
			if(window.localStorage && localStorage.getItem("locale")){
				var locale = JSON.parse(localStorage.getItem("locale"));
				locale = (locale === Object(locale) && locale.hasOwnProperty("_d") ? locale._d : locale);
				if(locale === "default"){ locale = null; }

				require.config(
					{locale : locale}
				);
			}
		} catch(e){}

		App.run();
	}
});

define("bootstrap", function(){});

