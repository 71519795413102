define('views/compatibility/tests/cookies',['require','jquery','underscore','library/vlp/app','../test','utilities/browser','hbs!tpls/compatibility/tests/cookies.handlebars'],function (require) {
	"use strict";

	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App      = require("library/vlp/app"),
	    TestBase = require("../test"),
	    Browser  = require("utilities/browser"),
	    template = require("hbs!tpls/compatibility/tests/cookies.handlebars");

	return TestBase.extend({
		template : template,
		name : "cookies",
		cookieApiPath : "compatibility/cookie",

		serialize : function(){
			var data = TestBase.prototype.serialize.call(this);
			data.apiDomain = _.parseURL(App.config.serverBase).host;
			return data;
		},
		run : function(){
			this.cookieApiUrl = App.config.serverBase + this.cookieApiPath + "?tenant=" + (App.getTenant() || App.config.defaultTenantPath);
			this.setStatus("running");

			this.testCookie();
		},
		testCookie : function(){
			if(!Browser.support.cookies){
				this.errors.cookies = true;
				this.setStatus("failure");
			}
			this.trigger(TestBase.PROGRESS, 0.25);
			this.testApiCookie();
		},

		testApiCookie : function(){
			this.deleteApiCookie();
		},
		deleteApiCookie : function(){
			var self = this;

			$.ajax({
				url : this.cookieApiUrl,
				type: "DELETE",
				complete : function(){
					self.trigger(TestBase.PROGRESS, 0.50);
					self.setApiCookie();
				}
			});
		},
		setApiCookie : function(){
			var self = this;

			$.ajax({
				url : this.cookieApiUrl,
				type: "POST",
				success : function(response){
					self.trigger(TestBase.PROGRESS, 0.75);
					self.getApiCookie();
				},
				error : function(jqXHR){
					self.errors.thirdPartyCookies = true;
					self.testsComplete();
				}
			});
		},
		getApiCookie : function(){
			var self = this;

			$.ajax({
				url : this.cookieApiUrl,
				type: "GET",
				error : function(jqXHR){
					self.errors.thirdPartyCookies = true;
				},
				complete : function(){
					self.testsComplete();
				}
			});
		},
		testsComplete : function(){
			this.trigger(TestBase.PROGRESS, 1);

			var result = _.isEmpty(this.errors);
			this.setStatus(result ? "success" : "failure");
			this.trigger(TestBase.COMPLETE, result);
		}
	});

});

