define('nls/root/console',{
	"console"      : {
		"building"     : "Starting lab. This may take a few minutes.",
		"startingLab"  : "Starting lab.",
		"deployTime"   : "Based on average deploy time this should take: ",
		"timerWarning" : "Resources for this lab are still deploying in the cloud, so actual deployment time could be longer than average.",
		"invalid"      : "Invalid Lab!",
		"entitlement" : {
			"lab"           : "Lab:",
			"timeRemaining" : "Time Remaining:",
			"idleTitle"     : "Idle time out",
			"idleWarning"   : "We have noticed that you have been idle for more than %d minutes. We will automatically end your session in ",
			"exit"          : {
				"title"   : "Are you sure you want to exit this Lab?",
				"label"   : "Exit",
				"tip"     : "Exit your lab and return later.",
				"message" : "Caution! Your servers will be powered down when you exit the lab. Any unsaved work will be lost. You can return to finish the lab at a later time with any saved work. Are you sure you want to exit this lab?",
				"messageNoPoweroff": "You are about to leave this lab.  You can return to finish at a later time, your VMs will remain in a running state, but you should save any unsaved work. Are you sure you want to exit this lab?",
				"exiting" : "Exiting"
			},
			"end": {
				"title"        : "Are you sure you want to end this Lab?",
				"label"        : "End",
				"tip"          : "Complete this lab.",
				"ending"       : "Ending...",
				"message"      : "Caution! Once you end the lab, you will not be able to return. If you would like to exit so you can return to the lab at a later time, please choose to Exit the lab instead. Are you sure you want to end this lab?",
				"messageNoExit": "Caution! Once you end the lab, you will not be able to return. Are you sure you want to end this lab ?"
			},
			"grade"           : {
				"title"         : "Are you sure you want to finish your Lab?",
				"label"         : "Finish",
				"tip"           : "Submit for grading.",
				"submitting"    : "Submitting...",
				"message"       : "Caution! Once you end the lab, you will not be able to return. If you would like to exit so you can return to the lab at a later time, please choose to Exit the lab instead. Are you sure you want to finish this lab and submit for grading?",
				"messageNoExit" : "Caution! Once you end the lab, you will not be able to return.  Are you sure you want to finish this lab and submit for grading?"
			},
			"reset"           : {
				"title"         : "Are you sure you want to reset?",
				"label"         : "Reset",
				"tip"           : "Reset this lab.",
				"resetting"     : "Resetting...",
				"warning"       : "Warning!",
				"message"       : "If you reset this lab, all VMs will be restored to their original state. You will not be able to recover any work you have done, so you will need repeat it. Only reset this lab if you are having unrecoverable issues.",
				"confirmation"  : "Are you sure you want to reset this lab?",
				"resetTitle"    : "Lab reset",
				"resetMessage"  : "The lab reset started. This student's console will be unavailable for a few minutes."
			},

			"externalReturn"      : "Please close this window.",
			"detailedInformation" : "Detailed Lab Information",
			"courseInformation"   : "Course and Lab Information",
			"labInformation"      : "Lab Information",
			"confirm"             : "Confirm",
			"cancel"              : "Cancel",
			"ok"                  : "OK",
			"guide"               : "Guide",
			"guideTooltip"        : "Launch guided console walk-through.",
			"extendTime" : {
				"label"       : "Extend",
				"labelLarge"  : "Extend Time",
				"message"     : "If you are running low on time, <br/> you can extend your lab time %d time(s).",
				"noRemaining" : "There are no more time extensions remaining.",
				"requesting"  : "Requesting..."
			},
			"timeWarning"         : {
				"title"   : "You are almost out of time",
				"message" : "Your lab will be ending soon. Please wrap up your work now."
			},
			"timeRemainingDiffRefresh" : {
				"title"     : "Local time change detected",
				"message"   : "A significant local time change has been detected. This page will refresh to sync up with the server.",
				"countdown" : "Refreshing in: "
			},
			"keyboardMismatch" : {
				"title"        : "Non-ANSI US Keyboard Detected",
				"message"      : "It appears as though you are using a Non-ANSI US keyboard. If the console's operating system does not have the same keyboard language as your keyboard, some keys typed may appear incorrect.",
				"prompt"       : "Do you want to re-map your typing to match a ANSI US keyboard layout?",
				"settingsNote" : "You can change this option in the settings."
			},
			"interceptPaste": {
				"title"       : "Intercept Paste",
				"message"     : "Intercept the paste shortcut keys and send the text copied from your browser (or elsewhere) to the console. This option could interfere with copy and paste commands executed within the console.",
				"prompt"      : "Do you want to intercept the paste shortcut keys?",
				"settingsNote": "You can change this option in the settings."
			},
			"macOsRemapCommandToControl": {
				"title"       : "Remap macOS ⌘ to ctrl Key",
				"message"     : "Remap the <span class='key'>⌘</span> (command) key to the <span class='key'>ctrl</span> key for non-macOS operating system consoles. This is useful to allow macOS shortcuts for commands like \"copy\" (<span class='key'>⌘</span>+<span class='key'>c</span>) to function as the equivalent short cut (<span class='key'>ctrl</span>+<span class='key'>c</span>) in other operating systems.",
				"prompt"      : "Do you want to remap <span class='key'>⌘</span> to the <span class='key'>ctrl</span> key?",
				"settingsNote": "You can change this option in the settings."
			},
			"panels"              : {
				"consoleTitle":           "Consoles",
				"consolesLoading":        "Loading VMs...",
				"manualTitle":            "Manual",
				"manualLoading":          "Loading manual...",
				"messagesTitle":          "Messages",
				"messagesLoading":        "Loading Messages...",
				"helpRequestTitle":       "Help",
				"helpRequestLoading":     "Loading Help Requests...",
				"videoConferenceTitle":   "Zoom",
				"videoConferenceLoading": "Loading Zoom...",
				"close":                  "Close Panel",
				"dockRight":              "Dock to right side",
				"dockCenter":             "Place in center page",
				"dockLeft":               "Dock to left side",
				"toggleFloat":            "Floating window",
				"refresh":                "Refresh"
			},
			"cloneVapp" :{
				"started" : "Vapp clone started. Your console will be disconnected.",
				"finished" : "Vapp clone finished. Please refresh."
			},
			"vmsError" : "This lab has been misconfigured and has no visible VMs. Please contact your administrator for support"
		},
		"vm"          : {
			"userName"              : "Username:",
			"password"              : "Password:",
			"noNotes"               : "(No notes)",
			"empty"                 : "(empty)",
			"notes"                 : "notes",
			"fullScreenMessage"     : "If you want even more full screen, put your browser in full-screen mode.",
			"loading"               : "Loading Console...",
			"poweredDown"           : "Powered Down",
			"poweredDownTooltip"    : "Click to power on",
			"unavailable"           : "Console Unavailable",
			"cannotConnect"         : "Connectivity Issues",
			"unstable"              : "Your connection is unstable.",
			"connectionLost"        : "Your network connection to console is lost. Refreshing console...",
			"networkName"           : "Network name",
			"networkIp"             : "Network ip" ,
			"latency" : {
				"title"       : "Connection to console",
				"current"     : "Current",
				"average"     : "Average",
				"high"        : "High",
				"low"         : "Low",
				"milliseconds": "ms",
				"unstable"    : "Your network connection is unstable.",
				"lost"        : "Your network connection to the console is lost.",
				"warning"     : "Your network connection is degraded. Console experience may slow."
			},
			"sendText" : {
				"label"         : "Send Text",
				"toolTip"       : "Send text to console",
				"title"         : "Send text to console",
				"send"          : "Send",
				"sending"       : "Sending...",
				"username"      : "Send the username to the console. <br />Select the username field in the console <strong>before sending</strong>.",
				"password"      : "Send the password to the console. <br />Select the password field in the console <strong>before sending</strong>.",
			    "networkName"   : "Send the network name to the console. <br />Select the network name field in the console <strong>before sending</strong>.",
				"networkIp"     : "Send the network ip to the console. <br />Select the network ip field in the console <strong>before sending</strong>.",
			},
			"sendToConsole" : {
				"confirmTitle"   : "Send commands to console",
				"confirmMessage" : "The system would like to take control of the console temporarily.",
				"confirmAllow"   : "Allow",
				"running" : {
					"message" : "Controlling console.",
					"cancelMessage"  : "Press escape to cancel.",
					"userInputDisabled"  : "User input disabled."
				}
			},
			"shortcutKeys" : {
				"title"  : "Keyboard Shortcuts",
				"shortcuts" : {
					"ctrlAltDel":  "Ctrl+Alt+Del",
					"ctrlTab":     "Ctrl+Tab",
					"ctrlF4":      "Ctrl+F4",
					"ctrlN":       "Ctrl+N",
					"ctrlT":       "Ctrl+T",
					"ctrlW":       "Ctrl+W",
					"altTab":      "Alt+Tab",
					"altEsc":      "Alt+Esc",
					"altF4":       "Alt+F4",
					"winD":        "Win+D",
					"f1":          "F1",
					"f2":          "F2",
					"f3":          "F3",
					"f4":          "F4",
					"f5":          "F5",
					"f6":          "F6",
					"f7":          "F7",
					"f8":          "F8",
					"f9":          "F9",
					"f10":         "F10",
					"f11":         "F11",
					"f12":         "F12",
					"pause":       "Pause",
					"printScreen": "PrntScrn"
				}
			},
			"buttons" : {
				"media"                   : "CD Library",
				"fullScreen"              : "Toggle Full-screen",
				"fullScreenAndMaximize"   : "Toggle Full-screen and Maximize",
				"connectionStrength"      : "Connection Strength",
				"exitFullScreen"          : "Exit Full-screen",
				"powerDown"               : "Power Down",
				"poweringDown"            : "Powering Down...",
				"powerOn"                 : "Power On",
				"poweringOn"              : "Powering On...",
				"reset"                   : "Power Reset",
				"resetting"               : "Resetting...",
				"retrying"                : "Retrying...",
				"screenRetry"             : "Try Again",
				"refresh"                 : "Refresh Page",
				"controlAltDelete"        : "Ctrl+Alt+Del",
				"shortcutKeys"            : "Shortcut Keys",
				"notes"                   : "Notes",
				"maximize"                : "Maximize",
				"exitMaximize"            : "Exit maximized",
				"powerDownTooltip"        : "Power down the console.",
				"powerOnTooltip"          : "Power on the console.",
				"resetTooltip"            : "Reset the console.",
				"controlAltDeleteTooltip" : "Send the Ctrl+Alt+Del key presses to the console.",
				"notesTooltip"            : "See the username, password, and other notes about this console.",
				"shortcutKeysTooltip"     : "Send Ctrl+Alt+Del and other keyboard shortcuts the console."
			},
			"powerDown" : {
				"title"   : "Are you sure you want to shut down?",
				"message" : "This will issue a shut down command to the current VM. Any unsaved work in this VM will be lost.",
				"confirm" : "Power Down"
			},
			"powerReset" : {
				"title"   : "Are you sure you want to reset?",
				"message" : "This will issue a reset command to the current VM. Any unsaved work in this VM will be lost.",
				"confirm" : "Reset"
			},
			"togglePanel" : {
				"showPanel"                        : "Show panel.",
				"hidePanel"                        : "Hide panel.",
				"showHelpRequestPanel"             : "Show help panel.",
				"hideHelpRequestPanel"             : "Hide help panel.",
				"showManualPanel"                  : "Show instruction manual panel.",
				"hideManualPanel"                  : "Hide instruction manual panel.",
				"showVmChooserPanel"               : "Show console chooser panel.",
				"hideVmChooserPanel"               : "Hide console chooser panel.",
				"showManualAndVmChooserPanel"      : "Show instruction manual and console chooser panels.",
				"hideManualAndVmChooserPanel"      : "Hide instruction manual and console chooser panels.",
				"showManualAndHelpRequestPanel"    : "Show instruction manual and help request panels.",
				"hideManualAndHelpRequestPanel"    : "Hide instruction manual and help request panels.",
				"showVmChooserAndHelpRequestPanel" : "Show console chooser and help request panels.",
				"hideVmChooserAndHelpRequestPanel" : "Hide console chooser and help request panels.",
				"showAllPanel"                     : "Show instruction manual, console chooser, and help request panels.",
				"hideAllPanel"                     : "Hide instruction manual, console chooser, and help request panels.",
			}
		},
		"vms"         : {
			"loading"     : "Loading thumbnail...",
			"poweredDown" : "Powered down",
			"empty"       : "There are no VMs to show."
		},
		"manual" : {
			"toc"                  : {
				"title"       : "VMware Lab Platform",
				"expandAll"   : "expand all",
				"collapseAll" : "collapse all",
				"gotoStep"    : "Go to step %d"

			},
			"moreOptions"          : "More Options",
			"title"                : "Instructions",
			"fetchError"           : "There was a problem retrieving this step.",
			"noManual"             : "No Manual",
			"print"                : "Print",
			"tableOfContents"      : "Table of Contents",
			"remoteControl"        : "Split Screen",
			"networkTopology"      : "Network Topology",
			"resources"            : "Resources",
			"timeRemaining"        : "Time:",
			"enlarge"              : "Click to enlarge",
			"enlarged"             : "Full image",
			"enlargedTable"        : "Full table",
			"playVideo"            : "Play video",
			"jumpStep"             : "Jump to page %s",
			"dragIntoConsole"      : "Drag into console",
			"manualChangedTitle"   : "Instructions Changed",
			"manualChangedMessage" : "The lab&#39;s instructions have been updated. Open the Table of Contents to find the page you were viewing if it has changed.",
			"alternativeLocales"   : "The manual is not available in your language. You can choose one of the alternative manuals instead.",

			"expandAllSectionsTooltip"   : "Expand All Subsections", 
			"collapseAllSectionsTooltip" : "Collapse All Subsections", 
		},
		"remoteToken" : {
			"email"  : {
				"title" : "Entitlement: %s Remote Control link"
			},
			"prompt" : {
				"title"        : "Use a Second Screen or Device",
				"email"        : "Email me a link to open with another device.",
				"tabletMessage": "For best results on tablets, please use the portrait orientation.",
				"newWindow"    : "Open the Manual and VM Consoles panel in a new window.",
				"otherDevice"  : "Or use the following token or link from another device:",
				"tokenLabel"   : "token:",
				"urlLabel"     : "link:"
			}
		},
		"partiallySupported" : {
			"title"             : "Partially supported device",
			"message"           : "Your device is only partially supported to run this application.",
			"openRemoteControl" : "Open the remote control.",
			"fullySupported"    : "Open this application on a desktop computer to run the lab."
		},
		"settings" : {
			"title"                            : "Settings",
			"enableManualShortcutsTitle"       : "Manual Shortcuts",
			"enableManualShortcutsDescription" : "If enabled, the following keyboard shortcuts will change the page in the manual even when the console has focus:",
			"enableManualShortcutsOnMac"       : "on Mac",
			"enableManualShortcutsPrevious"    : "Previous Page",
			"enableManualShortcutsNext"        : "Next Page",

			"macOsRemapCommandToControl" : {
				"title"      : "Remap ⌘ to ctrl Key",
				"description": "Remap the <span class='key'>⌘</span> (command) key to the <span class='key'>ctrl</span> key for non-macOS operating system consoles. This is useful to allow macOS shortcuts for commands like \"Copy\" (<span class='key'>⌘</span>+<span class='key'>c</span>) to function as the equivalent short cut (<span class='key'>ctrl</span>+<span class='key'>c</span>) in other operating systems.",
				"shortcuts"  : "Remapped Shortcuts",
				"copy"       : "Copy",
				"cut"        : "Cut",
				"paste"      : "Paste",
				"save"       : "Save",
				"selectAll"  : "Select All",
				"undo"       : "Undo"
			},
			"keyboardLayoutNames": {
				"en-US"        : "English (US)",
				"ja-JP_106/109": "日本語106/109",
				"de-DE"        : "Deutsch",
				"it-IT"        : "Italiano",
				"es-ES"        : "Español",
				"pt-PT"        : "Português (Portugal)",
				"pt-BR"        : "Português (Brasil)",
				"fr-FR"        : "Français",
				"fr-CH"        : "Français (Switzerland)",
				"de-CH"        : "Deutsch (Schweiz)"
			},
			"keyBoardLayoutTitle"              : "Keyboard Layout",
			"keyBoardLayoutDescription"        : "Select your keyboard layout to adjust any incorrect keys being sent to the console.",

			"interceptPasteTitle"              : "Intercept Paste",
			"interceptPasteDescription"        : "Intercept the paste shortcut keys and send the text copied from your browser (or elsewhere) to the console. This option could interfere with the console's own copy and paste commands.",
			"fixANSITitle"                     : "Fix non-ANSI US keyboard layouts",
			"fixANSIDescription"               : "Remap the keys pressed on your keyboard to send US ANSI key code equivalents to the console. If you are using an non-US keyboard or have the keyboard set a non-US language in your operating system, enabling this option could help send the correct keys to the console.",
			"fixANSIDisabled"                  : "Not supported by your browser.",
			"reverseScrollYTitle"              : "Reverse Vertical Scroll Direction",
			"reverseScrollYDescription"        : "Change the mouse scrolling direction sent to the console. This setting can help with mice or trackpads that have the scroll direction reversed (sometimes called \"natural\").",
			"savePanelPositionsTitle"          : "Save Panel Positions",
			"savePanelPositionsDescription"    : "Save the console, manual, and console chooser locations locally to your computer."
		},
		"videoConference" : {
			"consoleRemote" : "Open in another window/tab",
			"zoom" : {
				"reload" : "Reload Zoom session",
				"openInApp" : "Open In Zoom App",
				"join" : "Join Zoom Session",
				"helpMessageStudent" : "Connect to the Zoom session for this class. For best results, <strong>use the link above to launch the Zoom application</strong> installed on your device.",
				"helpMessageInstructor" : "Connect to the Zoom session for this class. For best results, <strong>use the link above to launch the Zoom application</strong> installed on your device. <em>You must use the external Zoom application to be the host and manage the session.</em>",
				"openedExternally" : "External Zoom launch link opened. Use the Zoom application and close this panel.",
				"stillActiveMessage" : "Zoom session is still active in the background."
			}
		}
	},
	"labExpired"   : {
		"title"            : "Time Up!",
		"returnHome"       : "Return to Home Page",
		"returnRedirect"   : "Exit",
		"returnExternal"   : "Please close this window.",
		"progress"         : "It is %(progress)s%% completed.",
		"message"          : "You have run out of time with the lab.",
		"purchaseMoreTime" : "Purchase Additional Time"
	},
	"labCompleted" : {
		"title"            : "Lab Successfully Completed!",
		"titleGrading"     : "Lab submitted for grading!",
		"subtitle"         : "Congratulations",
		"subtitle2"        : "on your successful completion of",
		"returnHome"       : "Return to Home Page",
		"returnRedirect"   : "Exit",
		"returnExternal"   : "Please close this window.",
		"thankYou"         : "Thank you for taking this lab. We hope you enjoyed your experience.<br/> Please go back to the home page to select another course or lab to take.",
		"requiresGrading"  : "Thank you for taking this lab. An instructor will grade your lab.",
		"continueNext"     : "Continue with Lab: ",
		"labInfo"          : "Lab Info",
		"startLab"         : "Start This Lab",
		"courseComplete"   : "All labs completed in course.",
		"shareUnavailable" : "Unavailable",
		"shareLab"         : "Invite a Friend",
		"login"            : "Log In",
		"takeSurvey"       : "Take Survey",
		"pleaseRate"       : "Please rate this lab",
		"alreadyRated"     : "You have already rated this lab.",
		"email"            : "Email",
		"relatedContent"   : "Related Labs",
		"relatedLab"       : "Related Lab"
	},
	"labTimedOut" : {
		"title"                   : "Idle time out",
		"messageLabEnded"         : "Your lab session was ended automatically because you were idle for too long.",
		"messageProgressSaved"    : "Your progress was saved.",
		"restartLab"              : "Return to your lab",
		"percentComplete"         : "Percent complete"
	},
	"errors"       : {
		"getLab"      : "There was an error retrieving this lab's info",
		"labExpired"  : "This lab is expired.",
		"ejectMedia"  : "Could not eject media. Please try again.",
		"mountMedia"  : "Could not mount media. Please try again.",
		"powerUpVM"   : "Could not power up console. Please try again.",
		"powerDownVM" : "Could not power console down. Please try again.",
		"resetVM"     : "Could not reset console. Please try again."
	},
	"class" : {
		"instructorBlankedScreen"  : "Instructor has blanked your console.",
		"instructorViewing"        : "Instructor is viewing your console.",
		"broadcastingDisplay"      : "Instructor is broadcasting your console to all other students.",
		"takenOver"                : "Instructor has taken over your console.",
		"takenOverAndBroadcasting" : "Instructor has taken over your console and is broadcasting your console to all other students.",
		"showingBroadcastDisplay"  : "Instructor is broadcasting another student's console.",
		"reset"                    : "Instructor has reset your lab. Please wait until your page refreshes automatically."
	},
	"support": {
		"supportViewing": "Support person is viewing your console."
	},
	"onDemandProvisioning": {
		"notifyMe"     : "Notify me when the lab is ready:"
	},
	"betaMessage"  : "VMware Lab Platform is currently accepting beta clients. Interested? Click <a href=\"%s\" target=\"_blank\">here</a> for more information.",
	"title"        : "Lab Console"
});

