define('nls/manifest',{
	"root"  : true, //English
	//other locales available
	"de"    : true, //German
	"es"    : true, //Spanish
	"it"    : true, //Italian
	"fr"    : true, //French
	"nl"    : true, //Dutch
	"ja"    : true, //Japanese
	"zh-tw" : true, //Chinese (Traditional)
	"zh"    : true, //Chinese
	"ru"    : true, //Russian
	"pt-br" : true, //Brazilian Portuguese
	"ko"    : true  //Korean
});
