define('nls/root/catalogs',{
	"catalogs" : {
		"enrollments" : {
			"title"                 : "Enrollments",
			"description"           : "Click here to see the Courses and Labs in which you are currently enrolled.",
			"loading"               : "Loading Enrollments...",
			"empty"                 : "There are no enrollments to show.",
			"pleaseLogin"           : "Please log in to see your enrollments",
			"courseLabsRemaining"   : "%(labCount)s labs remaining.",
			"courseOneLabRemaining" : "%(labCount)s lab remaining.",
			"allFetched"            : "All enrollments displayed."
		},
		"catalogs"    : {
			"title"           : "Labs",
			"loading"         : "Loading Catalogs...",
			"description"     : "Click here to see the Courses and Labs in which you are currently enrolled.",
			"empty"           : "There are no catalogs to show.",
			"invalid"         : "Invalid catalog.",
			"courses"         : {
				"title" : "Courses",
				"tip"   : "This contains a list of course objects or collections of labs."
			},
			"labs"            : {
				"title" : "Standalone Labs",
				"tip"   : "This contains a list of individual labs."
			},
			"noCoursesOrLabs" : "No Courses or Labs",
			"search"          : "Search this catalog"
		},
		"transcript"  : {
			"title"              : "Transcript",
			"description"        : "Click here to see the Courses and Labs that you have completed.",
			"loading"            : "Loading Transcript...",
			"allFetched"         : "All transcripts displayed.",
			"empty"              : "There are no enrollments to show.",
			"pleaseLogin"        : "Please log in to see your transcript.",
			"date"               : "Date:",
			"pointsEarned"       : "Points Earned",
			"statsTitle"         : "Personal Stats",
			"labsCompletedTitle" : "Labs Completed",
			"achievementsTitle"  : "Achievements",
			"timeSpentTitle"     : "Time Spent",
			"recordsTitle"       : "Set Records",
			"labsCompleted"      : "Labs Completed",
			"email"              : "Email Full Transcript",
			"emailSingle"        : "Email Transcript",
			"emailRequesting"    : "Requesting...",
			"emailRequested"     : "Email Requested",
			"emailMessage"       : "You will receive an email with your transcript shortly.",
			"certificate" : {
				"title"      : "Lab Completed",
				"completion" : "Certificate of Completion",
				"view"       : "View Certificate",
				"email"      : "Email Certificate",
				"print"      : "Print Certificate"
			}
		},
		"labLanding" : {
			"title"       : "Lab",
			"loading"     : "Loading Lab Information...",
			"notFound"    : "Could not find this lab's information.",
			"related"     : "Related labs",
			"alsoTook"    : "People who took this lab also took:",
			"noRelated"   : "No related Labs",
			"pleaseLogin" : "Please log in to see this lab's information."
		},
		"labs"        : {
			"labs"              : "Labs",
			"empty"             : "There are no labs to show.",
			"completed"         : "Completed",
			"inProgress"        : "In Progress",
			"active"            : "Active",
			"notStarted"        : "Not Started",
			"canceled"          : "Canceled",
			"expired"           : "Expired",
			"awaitingGrade"     : "Submitted",
			"submittedForGrade" : "Submitted for grading.",
			"suspended"         : "Suspended",
			"enrolled"          : "Enrolled",
			"enrolledAgain"     : "Enroll Again",
			"enroll"            : "Enroll",
			"enrolling"         : "Enrolling...",
			"hideDetails"       : "Hide Lab Details",
			"showDetails"       : "Lab Details",
			"readMore"          : "Read more",
			"resumeLab"         : "Resume this lab ",
			"resetLab"          : "Reset this lab",
			"startLab"          : "Start this lab",
			"getMoreTime"       : "Get more time",
			"pleaseLogin"       : "Please log in to enroll in a lab.",
			"viewTopology"      : "View",
			"networkTopology"   : "Network Topology",
			"thumbnailAlt"      : "%s Thumbnail",
			"new"               : "new",
			"allFetched"        : "All labs displayed.",
			"badges"            : "Badges",
			"progressMessage"   : "This lab is in progress with %(progress)s%% completed.",

			"percentageRequired"             : "%s of the manual",

			"labels": {
				"description"                   : "Description:",
				"timeRemaining"                 : "Time Remaining:",
				"expiration"                    : "Expiration:",
				"expirationFromNow"             : "from now",
				"timeLimit"                     : "Time Limit:",
				"timeAllotted"                  : "Time Allotted:",
				"timeElapsed"                   : "Time Spent:",
				"locales"                       : "Manual Languages:",
				"exitAllowed"                   : "Exit Allowed:",
				"endAllowed"                    : "End Allowed:",
				"requiresGrading"               : "Graded Lab:",
				"networkTopology"               : "Topology:",
				"validPeriod"                   : "Valid Period:",
				"language"                      : "Lab Language:",
				"status"                        : "Status:",
				"progress"                      : "Progress:",
				"dateCompleted"                 : "Date Completed:",
				"price"                         : "Cost:",
				"catalogs"                      : "Catalogs:",
				"catalogsAlsoExists"            : "This lab also exists in:",
				"catalogsExists"                : "This lab is in:",
				"tags"                          : "Tags:",
				"products"                      : "Products:",
				"labVms"                        : "VMs:",
				"relatedContent"                : "Related Content:",
				"authors"                       : "Authors:",
				"className"                     : "Class Name:",
				"manualStepsVisitedCount"       : "Manual Steps Read:",
				"requiredMinimumTimeSpent"      : "Minimum Time Spent:",
				"requiredStepsVisitedPercentage": "Must Read:",
				"requiredForCompletion"         : "Required For Completion"
			},
			"ratings" : {
				"label"        : "User Ratings",
				"fiveStar"     : "5 star:",
				"fourStar"     : "4 star:",
				"threeStar"    : "3 star:",
				"twoStar"      : "2 star:",
				"oneStar"      : "1 star:",
				"alreadyRated" : "You have already rated this lab."
			},
			"reviews" : {
				"title"     : "Reviews (%d)",
				"label"     : "%d review(s)",
				"reviewFor" : "Review for",
				"by"        : "by",
				"noReviews" : "No Reviews"
			},
			"unenroll" : {
				"unenroll"          : "Unenroll",
				"unenrolling"       : "Unenrolling...",
				"unenrollTitle"     : "Unenroll lab \"%s\"",
				"unenrollMessage"   : "Are you sure you want to unenroll from \"%s\"?",
				"end"               : "End Lab",
				"ending"            : "Ending...",
				"endTitle"          : "End lab \"%s\"",
				"endMessage"        : "Are you sure you want to end the lab \"%s\"?"
			},
			"prepops": {
				"availability"                              : "Availability:",
				"availabilityInstant"                       : "Available Now",
				"availabilityOnDemandUnknown"               : "Deployed On Demand",
				"availabilityOnDemandDeployTime"            : "Deployed On Demand (%s)",
				"availabilityOnDemandDeployTimeUnderAMinute": "Deployed On Demand (< 1 minute)",
				"availableToolTip"                          : "Resources for this lab have been pre-deployed for faster start times.",
				"warningTitle"                              : "Resource Availability",
				"warningUnknownTime"                        : "There are no immediately available resources deployed for this lab. Deployment times could be significantly longer than normal.",
				"warningDeployTime"                         : "There are no immediately available resources deployed for this lab. It will take approximately %s to deploy this lab.",
				"warningUnderAMinute"                       : "There are no immediately available resources deployed for this lab. It should take less than a minute to deploy this lab."
			}
		},
		"courses"     : {
			"empty"         : "There are no courses to show.",
			"completed"     : "Completed",
			"enrolled"      : "Enrolled",
			"enrolledAgain" : "Enroll Again",
			"enroll"        : "Enroll",
			"enrolling"     : "Enrolling...",
			"hideLabs"      : "Hide Labs",
			"showLabs"      : "Show Labs",
			"pleaseLogin"   : "Please log in to enroll in a course."
		},
		"allCatalogs" : {
			"search"      : "Search all catalogs",
			"title"       : "All Labs",
			"description" : "Labs from all catalogs"
		}
	},
	"class" :{
		"name"        : "Class Name",
		"description" : "Description",
		"startDate"   : "Start Date",
		"endDate"     : "End Date",
		"status"      : "Status",
		"instructors" : "Instructors",
		"statuses"      : {
			"PENDING"  : "Pending",
			"COMPLETE" : "Complete",
			"ACTIVE"   : "Active"
		}
	},
	"errors"   : {
		"invalidRequest"   : "Invalid Request",
		"enrollCourse"     : "Could not enroll in course. Please try again.",
		"enrollLab"        : "Could not enroll in lab. Please try again.",
		"resetEntitlement" : "Could not reset entitlement. Please try again."
	},
	"title"    : "Catalogs"
});

