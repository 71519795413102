define('views/instructor/entitlement-listing',['require','jquery','underscore','library/vlp/app','library/vlp/view','views/instructor/entitlement','models/class-entitlement','hbs!tpls/instructor/entitlement-listing.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App              = require("library/vlp/app"),
	    BaseView         = require("library/vlp/view"),
	    EntitlementView  = require("views/instructor/entitlement"),
	    ClassEntitlement = require("models/class-entitlement"),
	    tpl              = require("hbs!tpls/instructor/entitlement-listing.handlebars");

	return BaseView.extend({
		template: tpl,

		children : {},
		pageNum  : 1,
		scrollTop: 0,
		searchValue : "",

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events: {
			"click .fetch-more-trigger": "onLoadMore"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize: function (options) {

			_.bindAll(this);
			options = options || {};
			options.fetchDefaults = options.fetchDefaults || {};
			this.classId = options.classId;

			this.fetchDefaults = _.extend({}, options.fetchDefaults);

			this.collection = new ClassEntitlement.Collection(null, {classId : this.classId});

			this.listenTo(this.collection, "request", this.onFetching);
			this.listenTo(this.collection, "sync",    this.onFetched);
			this.listenTo(this.collection, "add",     this.onItemAdded);
			this.listenTo(this.collection, "error",   this.fetchError);
			this.listenTo(this.collection, "reset",   this.onReset);

			this.listenTo(this.collection, "change:thumbnailOpen",  this.onThumbnailOpened);

			if (App.config.instructorInfiniteScrolling) {
				$(window).on("scroll", this.onScroll);
			}
		},

		serialize: function () {
			var data = this.collection.toHandlebars();
			return data;
		},

		beforeRender: function () {
			_.each(this.children, function (view) {
				view.$el.detach();
			});
		},

		afterRender: function () {

			var self = this;
			this.$itemHolder = this.$(".items-holder");
			if (this.$itemHolder.length && this.collection.length) {
				this.collection.each(function (model) {
					var itemView = self.children[model.get("id")];

					if (!itemView) {
						itemView = new EntitlementView({
							model  : model,
							classId: self.classId
						});
						self.children[model.get("id")] = itemView;
					}

					self.$itemHolder.append(itemView.el);
					itemView.setElement(itemView.el);
					//This is hacky, but otherwise dom events seem to get detached.
					if (!itemView.rendered) {
						itemView.render();
					} else{
						itemView.afterRender();
					}
				});
			}
			$("html, body").scrollTop(this.scrollTop);
		},

		onReset : function(){
			this.scrollTop = 0;
			this.removeChildren();
			this.$(".items-holder").empty();
		},
		onFetching: function () {
			if (App.config.instructorInfiniteScrolling) {
				if (!this.rendered) {
					this.render();
				}
				this.$(".fetch-more .btn").button("loading");
			} else {
				this.render();
			}
		},
		onFetched: function () {
			this.render();
			this.onScroll();
			this.$(".fetch-more-trigger").button("reset");
		},

		onItemAdded: function (item) {

			var entitlementView = new EntitlementView({
				model  : item,
				classId: this.classId
			});

			this.children[item.get("id")] = entitlementView;

			this.$(".items-holder").append(entitlementView.render().el);
		},

		onLoadMore: function (event) {
			if (this.collection.length <= this.collection.totalCount && !this.collection.loading) {
				this.fetch({add: true, remove: false});
				this.$(".fetch-more-trigger").button("loading");
			}
		},

		onScroll: function (event) {
			this.scrollTop = $(window).scrollTop();
			var $trigger = this.$(".fetch-more-trigger");
			if ($trigger.length === 0) {
				return;
			}

			if ($(document).height() < ($trigger.outerHeight() + $(window).height() + this.scrollTop)){
				this.onLoadMore();
			}
		},

		show: function (pageNum) {

			if (App.config.instructorInfiniteScrolling || !pageNum) {
				this.pageNum = 1;
			} else {
				this.pageNum = pageNum;
			}

			if (!this.collection.loading) {
				this.collection.reset();
				this.fetchPage(this.pageNum);
			} else {
				this.render();
			}

			this.scrollTop = 0;
			$("html, body").scrollTop(0);
		},

		fetchError: function (collection, jqXHR) {
			if (jqXHR.statusText === "loginCanceled") {
				this.render();
			} else {
				return false;
			}
		},

		search : function(searchValue){
			if(searchValue != this.searchValue){
				this.searchValue = searchValue;
				this.fetchDefaults.params = this.fetchDefaults.params || {};
				this.fetchDefaults.params.search = this.searchValue;
				this.collection.reset();
				this.pageNum = 1;
				this.fetchPage(this.pageNum);
			}

		},
		remove : function(){
			$(window).off("scroll", this.onScroll);
			this.removeChildren();
			BaseView.prototype.remove.call(this);
		},
		removeChildren : function(){
			_.each(this.children, function(view){
				view.remove();
			});
			this.children = {};
		},

		onThumbnailOpened : function(model, value){
			if(value){
				var openThumbnails = _.without(this.collection.where({thumbnailOpen : true}), model);
				if(openThumbnails.length >= App.config.instructorThumbnailMaxOpen){
					var closingThumbnails = _.last(openThumbnails, (openThumbnails.length + 1) - App.config.instructorThumbnailMaxOpen);
					for(var i in closingThumbnails){
						if(closingThumbnails.hasOwnProperty(i)){
							closingThumbnails[i].set("thumbnailOpen", false);
						}
					}
				}
			}
		}
	});

});
