/**
 * Console Remote Router
 *
 * Code starts running here for the /console-remote page.
 */
define('routers/console-remote',['require','library/vlp/app','routers/console'],function (require) {
	"use strict";

	//class dependencies
	var App           = require("library/vlp/app"),
	    ConsoleRouter = require("routers/console");


	return ConsoleRouter.extend({
		routeBase : "console-remote",
		initialize : function(){
			App.remoteControl = true;
			App.setGlobalParam("_remote", 1);
			ConsoleRouter.prototype.initialize.apply(this, arguments);
		}
	});
});

