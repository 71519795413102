define('views/catalogs/lab-listing-modal',['require','jquery','underscore','library/vlp/app','library/vlp/view','models/lab','hbs!tpls/catalogs/lab-listing-modal.handlebars','hbs!tpls/catalogs/ratings-popover.handlebars','hbs!tpls/catalogs/lab/badges.handlebars','hbs!tpls/catalogs/lab/reviews-and-ratings.handlebars','hbs!tpls/catalogs/lab/small.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App              = require("library/vlp/app"),
	    BaseView         = require("library/vlp/view"),
	    Lab              = require("models/lab"),
	    labListingTPL    = require("hbs!tpls/catalogs/lab-listing-modal.handlebars"),
	    reviewPopoverTPL = require("hbs!tpls/catalogs/ratings-popover.handlebars");


	//Just accessed as a partial
	require("hbs!tpls/catalogs/lab/badges.handlebars");
	require("hbs!tpls/catalogs/lab/reviews-and-ratings.handlebars");
	require("hbs!tpls/catalogs/lab/small.handlebars");

	return BaseView.extend({

		template : labListingTPL,

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"hidden"                          : "onHidden",
			"click .lab-listing-pager button" : "onLoadPage"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){

			_.bindAll(this);
			options = options || {};
			options.fetchDefaults = options.fetchDefaults || {};

			this.tag       = options.tag;
			this.tagId     = options.tagId;
			this.system    = options.system;
			this.systemId  = options.systemId;
			this.product   = options.product;
			this.productId = options.productId;
			this.newLabs   = options.newLabs;

			this.badge   = options.badge;
			this.icon    = options.icon;
			this.name    = options.name;
			this.badgeId = options.badgeId;


			this.collection = new Lab.Collection(null, {
				orderBy  : "name",
				orderDir : "asc"
			});


			this.fetchDefaults = _.extend({
				params : {
					"with" : [
						"authors", "badges", "catalogs", "entitlement", "networkTopology", "products",
						"ratings", "relatedLabs", "tags", "thumbnail", "vms"
					]
				},
				perPage  : App.config.itemsPerPage
			}, options.fetchDefaults);

			this.listenTo(this.collection, "request sync error reset", this.render);


			$("#modal_lab_listing").on("hidden", this.onHidden);
		},
		serialize : function(){
			var data = this.collection.toHandlebars();
			data.tag     = this.tag;
			data.system  = this.system;
			data.product = this.product;
			data.author  = this.author;
			data.newLabs = this.newLabs;

			data.badge = this.badge;
			data.icon  = this.icon;
			data.name  = this.name || this.badge || this.tag || this.product || this.system;
			if(data.icon && data.icon.match(/^\s*https?:/i)){
				data.image = data.icon;
			}
			return data;
		},

		afterRender : function(){
			var self = this;

			this.$(".rating[data-score!='']").raty({
				readOnly : true,
				score: function() {
					return $(this).data("score");
				}
			});
			this.$(".rating[data-score='']").hide();

			this.$(".rating-wrapper").popover({
				content : function(){
					var id = $(this).data("id");
					var model = {};
					if(id){
						model = Lab.Model.find(id);
					}
					return reviewPopoverTPL(model);
				},
				title : function(){
					var id = $(this).data("id");
					var model = {};
					if(id){
						model = Lab.Model.find(id);
						if(model){
							return App.i18n("catalogs.labs.ratings.label", model.get("ratingNum"));
						}
					}
					return App.i18n("catalogs.labs.ratings.label", "0");
				},
				container : this.$(".labs")
			});

			this.$("[rel=popover]")
				.popover()
				.on("shown", function(){
					self.$("[rel=tooltip]").tooltip();
				});

			$("#modal_lab_listing").modal("reflow");
		},

		show : function(){


			$("#modal_lab_listing").append(this.el);
			$("#modal_lab_listing").modal("show");

			this.render();
			this.fetchDefaults.params = this.fetchDefaults.params || {};

			if(this.newLabs){
				this.fetchDefaults.params.newItem = this.newLabs;
			} else{
				delete this.fetchDefaults.params.newItem;
			}
			if(this.tagId){
				this.fetchDefaults.params.tagIds = this.tagId;
			} else{
				delete this.fetchDefaults.params.tagIds;
			}
			if(this.productId){
				this.fetchDefaults.params.productIds = this.productId;
			} else{
				delete this.fetchDefaults.params.productIds;
			}
			if(this.badgeId){
				this.fetchDefaults.params.badgeIds = this.badgeId;
			} else{
				delete this.fetchDefaults.params.badgeIds;
			}

			this.fetchPage(1);
		},
		onHidden : function(event){
			if(event && event.target){
				if($("#modal_lab_listing").is(event.target)){
					this.remove();
				}
			} else{
				this.remove();
			}
		},
		onLoadPage : function(event){
			event.preventDefault();
			var page = $(event.currentTarget).val();
			this.fetchPage(page);
		},
		remove : function(){
			$("#modal_lab_listing").off("hidden", this.onHidden);
			$("#modal_lab_listing").modal("hide");
			BaseView.prototype.remove.apply(this, arguments);
		}

	});

});
