/**
 * Speed-test Backbone View
 */
define('views/compatibility/main',['require','underscore','jquery','library/vlp/app','views/vlp-base','models/system','hbs!tpls/compatibility/main.handlebars','i18n!nls/compatibility','i18n!nls/unsupported','i18n!nls/speed-test','views/compatibility/test','views/compatibility/tests/cookies','views/compatibility/tests/storage','views/compatibility/tests/canvas','views/compatibility/tests/websockets','views/compatibility/tests/proxy','views/compatibility/tests/browser','views/compatibility/tests/resolution','views/compatibility/tests/popups','views/compatibility/tests/bandwidth'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore"),
	    $ = require("jquery");

	//class dependencies
	var App                 = require("library/vlp/app"),
	    VlpBaseView         = require("views/vlp-base"),
	    System              = require("models/system"),
	    mainTPL             = require("hbs!tpls/compatibility/main.handlebars"),
	    pageTexts           = require("i18n!nls/compatibility"),
	    unsupportedTexts    = require("i18n!nls/unsupported"),
	    speedTestTexts      = require("i18n!nls/speed-test"),
	    TestBase            = require("views/compatibility/test");

	var testViews = {
		cookies    : require("views/compatibility/tests/cookies"),
		storage    : require("views/compatibility/tests/storage"),
		canvas     : require("views/compatibility/tests/canvas"),
		websockets : require("views/compatibility/tests/websockets"),
		proxy      : require("views/compatibility/tests/proxy"),
		browser    : require("views/compatibility/tests/browser"),
		resolution : require("views/compatibility/tests/resolution"),
		popups     : require("views/compatibility/tests/popups"),
		bandwidth  : require("views/compatibility/tests/bandwidth")
	};


	return VlpBaseView.extend({
		name : "COMPATIBILITY",
		className : "general-page",

		anonymousAccess       : true,
		checkLoggedIn         : true,
		checkSystemMessages   : false,
		checkCompatibility    : false,
		checkMaintenance      : true,
		checkBrowserSupported : false,
		checkTenantPath       : false,

		tests    : [],
		progress : 0,
		testDelay : 400,
		results: {},

	//Required
		mainTemplate : mainTPL,
		events : {
			"click #run_tests" : "onRunTests",
			"click .start-lab" : "startLab"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){

			_.bindAll(this);
			App.locale.mergeI18n(speedTestTexts);
			App.locale.mergeI18n(unsupportedTexts);
			App.locale.mergeI18n(pageTexts);
			//Checks logged in
			this.on(VlpBaseView.STARTUP_COMPLETE, this.render);
			this.pageStartupOperations.push("fetchCloudURLBase");

			this.setup(options);
		},

		startLab: function(event){
			App.redirect({page : App.config.pages.CONSOLE, action: this.consoleId});
		},

		_op_fetchCloudURLBase : function(){

			var system = new System.Model();

			system.fetchCloudURLBase({
				success : this.nextOperation,
				error   : this.nextOperation
			});
		},
		/**
		 * Display the content.
		 */
		render : function(){
			var self = this;

			this.tests = [];
			for(var test in testViews){
				if(testViews.hasOwnProperty(test)){
					var configKey = "compatibilityCheckerCheck" + _.capitalize(test);
					if(!App.config.hasOwnProperty(configKey) || App.config[configKey]){
						this.tests.push(new testViews[test]());
					}
				}
			}

			this.$tests = this.$("#tests");
			_.each(this.tests, function(test){
				self.$tests.append(test.render().el);
			});

			this.$runTestButton = this.$("#run_tests");
			this.$progressBar = this.$(".progress-container .bar");
			this.$progressBar.css("width", "0%");
			this.$progressMessage = this.$(".progress-container .message");
			this.$progressMessage.text("");
			this.$progressMessage.removeClass("animated flash");
			this.$(".progress").removeClass("progress-danger progress-warning");

			//Always return this for chaining
			return this;
		},
		onRunTests : function(){

			this.$runTestButton.button("loading");

			this.testNum = 0;

			this.$progressBar.css("width", "0%");
			this.$progressMessage.text("");
			this.$progressMessage.removeClass("animated flash");
			this.$(".progress").removeClass("progress-danger progress-warning");

			_.each(this.tests, function(test){
				test.reset();
			});
			this.runNext();
		},
		runNext : function(){
			if(this.testNum >= this.tests.length){
				this.complete();
			} else{
				var self = this;

				var activeTest = this.tests[this.testNum];
				if(!activeTest.enabled){
					self.setProgress(self.testNum, 1);
					self.testNum++;
					self.runNext();
				} else{
					this.setProgress(this.testNum, 0);

					activeTest.off(TestBase.COMPLETE);
					activeTest.off(TestBase.PROGRESS);
					activeTest.on(TestBase.COMPLETE, function(result){
						if(activeTest.status === "failure"){
							self.$(".progress").addClass("progress-danger").removeClass("progress-warning");
						} else if(activeTest.status === "warning" && !self.$(".progress").hasClass("progress-danger")){
							self.$(".progress").addClass("progress-warning");
						}
						self.results[activeTest.name] = result;
						self.setProgress(self.testNum, 1);
						self.testNum++;
						setTimeout(self.runNext, self.testDelay);
					});
					activeTest.on(TestBase.PROGRESS, function(percent){
						self.setProgress(self.testNum, percent);
					});

					activeTest.run();
				}
			}
		},
		complete : function(){
			this.$(".progress").removeClass("progress-danger progress-warning");
			this.$progressMessage.text("");
			this.$progressMessage.removeClass("animated flash");

			this.$runTestButton.button("reset");

			if (App.config.persistAnonymousCompatibilityTest) {
				$.ajax({
					type  : "POST",
					url   : App.config.serverBase + "compatibility?tenant=" + (App.getTenant() || App.config.defaultTenantPath),
					contentType : "application/json",
					data  : JSON.stringify(this.results),
					error : function(){
						//empty function just to suppress error message
					}
				});
			}

			var pass = true;
			var failureCount = 0;
			var warningCount = 0;
			_.each(this.tests, function(test){
				pass = pass && (test.status === "success");
				if(test.status === "failure"){
					failureCount++;
				} else if(test.status === "warning"){
					warningCount++;
				}
			});
			App.analytics.trackEvent("Support", "Test Compatibility", pass ? "Passed" : "Failed");

			if(failureCount > 0) {
				this.$(".progress").addClass("progress-danger");
				this.$progressMessage.addClass("animated flash");
				this.$progressMessage.text(App.i18n("compatibility.testFailures", failureCount + warningCount));
			} else if(warningCount > 0){
				this.$(".progress").addClass("progress-warning");
				this.$progressMessage.addClass("animated flash");
				this.$progressMessage.text(App.i18n("compatibility.testFailures", failureCount + warningCount));
			} else {
				this.$progressMessage.text(App.i18n("compatibility.testsPassed"));
			}
			if(this.$(".accordion-group.failure,.accordion-group.warning").length > 0){
				var offset = this.$(".accordion-group.failure,.accordion-group.warning").offset();
				offset.top -= $("header").height() || 30;
				$("html, body").animate({
					scrollTop: offset.top
				});
			}
		},
		setProgress : function(testNum, progressPercent){
			var startProgress = (testNum / this.tests.length) * 100;
			var progress = startProgress + (progressPercent * (100 / this.tests.length));
			this.$progressBar.css("width", progress + "%");
			this.$progressMessage.text(App.i18n("compatibility.progressMessage", {hash: {currentNumber: testNum + 1, totalTestsCount: this.tests.length}}));
		},
		showTest : function(id){
			this.consoleId = id;

			if(id === null || typeof id === "undefined" || _.isEmpty(_.trim(id))) {
				$(".start-lab").hide();
			}
		}
	});

});

