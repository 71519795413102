define('views/catalogs/enrollment-lab',['require','jquery','underscore','library/vlp/app','views/catalogs/lab','hbs!tpls/catalogs/enrollment-lab.handlebars','library/utilities/days-hours-minutes-label','hbs!tpls/catalogs/enrollment-unenroll.handlebars','hbs!tpls/catalogs/enrollment-start-button.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App                = require("library/vlp/app"),
	    LabView            = require("views/catalogs/lab"),
	    EntitlementInfoTPL = require("hbs!tpls/catalogs/enrollment-lab.handlebars");

	var daysHoursMinutesLabel = require("library/utilities/days-hours-minutes-label");

	require("hbs!tpls/catalogs/enrollment-unenroll.handlebars");
	require("hbs!tpls/catalogs/enrollment-start-button.handlebars");

	return LabView.extend({
		template : EntitlementInfoTPL,
		throttleRending : 100,

		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		className : "enrollment-lab",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events: {
		},
		modelBindings : {
			".time-remaining-label"  : "timeRemainingLabel",
			".expiration-time-label" : "expirationTimeLabel"
		},
		initialize : function(){
			LabView.prototype.initialize.apply(this, arguments);
			this.events = _.defaults(this.events, LabView.prototype.events);

			if(this.model.has("lab")){
				//May get related info separately from catalogs call
				this.listenTo(this.model.get("lab"), "change:badges change:catalogs", this.render);
			}
		},

		unenrollLab: function(event){


			var self = this;

			var $modal = $("#modal_unenroll_" + this.model.get("id"));
			$modal.find(".unenroll-spinner").removeClass("hide");
			$modal.find("button.unenroll-lab").button("loading");
			$modal.find("button.cancel").hide();

			this.model.end({
				success : function(){
					App.trigger("catalogsReset");
					$modal.modal("hide").remove();
					self.$el.slideUp("fast", function(){
						self.remove();
						self.model.collection.remove(self.model);
					});
				},
				error   : function(){
					$modal.find(".unenroll-spinner").addClass("hide");
					$modal.find("button.unenroll-lab").button("reset");
					$modal.find("button.cancel").show();
				}
			});
		},

		serialize : function(){

			var data = this.model.toHandlebars();
			if(data.lab) {
				data = _.defaults(data, data.lab);
				if(data.lab.vms){
					data.vms = data.lab.vms;
				}
			}
			data.userName = App.user.get("displayName");

			data.display = {
				showInvite      : App.config.showInvite && App.user.get("loggedIn") && this.model.get("tenant") === App.config.tenant,
				showDescription : true
			};

			if(data.badges){
				data.badges.uuid = _.uniqueId("group_");
			}

			return data;

		},
		beforeRender : function(){
			this.stopTimeRemainingUpdate();
		},
		afterRender : function(){
			LabView.prototype.afterRender.apply(this, arguments);
			if(this.model.get("expirationTime") > 0 && this.model.get("status") !== "expired" && this.model.get("status") !== "awaitingGrade"){
				this.startTimeRemainingUpdate();
			}
			this.setupImageErrorHandlers();
			$(document).off("click.unenroll", "#modal_unenroll_" + this.model.get("id") + " .unenroll-lab",  this.unenrollLab);
			$(document).on("click.unenroll", "#modal_unenroll_" + this.model.get("id") + " .unenroll-lab",   this.unenrollLab);

			if(App.config.disableEndButtonUntilComplete && this.model.get("completionStatusForLabCriteria") !== "completed"){
				this.$(".unenroll-end").prop("disabled", true);
			}
			var warningText = "";
			var dhms = _.secondsToDHMS(this.model.get("requiredMinimumTimeSpent") * 60);
			var singular = (dhms.minutes === 1) || (dhms.hours === 1 && dhms.minutes === 0) || (dhms.days === 1 && dhms.hours === 0 && dhms.minutes === 0);
			if(this.model.get("completionStatusForLabCriteria") == "minTimeAndPercentageNotCompleted"){
				warningText = App.i18n(
					(singular ? "completionCriteria.requiredMinimumTimeSpentAndStepsVisitedPercentageSingular" :"completionCriteria.requiredMinimumTimeSpentAndStepsVisitedPercentage"),
					daysHoursMinutesLabel(this.model.get("requiredMinimumTimeSpent")),
					this.model.get("requiredStepsVisitedPercentage")
				);
			} else if(this.model.get("completionStatusForLabCriteria") == "minTimeNotCompleted"){
				warningText = App.i18n(
					(singular ? "completionCriteria.requiredMinimumTimeSpentSingular" :"completionCriteria.requiredMinimumTimeSpent"),
					daysHoursMinutesLabel(this.model.get("requiredMinimumTimeSpent"))
				);
			} else if(this.model.get("completionStatusForLabCriteria") == "percentageNotCompleted"){
				warningText = App.i18n("completionCriteria.requiredStepsVisitedPercentage", this.model.get("requiredStepsVisitedPercentage"));
			}
			var criteriaWarningClass = ".lab-completion-criteria-warning." + this.model.get("entitlementKey");
			$(criteriaWarningClass).toggle(this.model.get("completionStatusForLabCriteria") != "completed");
			$(criteriaWarningClass).find(".warning-message").text(warningText);
			$(criteriaWarningClass).find(".time-elapsed")
				.toggle(this.model.get("completionStatusForLabCriteria") == "minTimeAndPercentageNotCompleted" || this.model.get("completionStatusForLabCriteria") == "minTimeNotCompleted");
			$(criteriaWarningClass).find(".time-elapsed-value")
				.text(daysHoursMinutesLabel(this.model.get("timeElapsed")));
			$(criteriaWarningClass).find(".manual-steps-read")
				.toggle(this.model.get("completionStatusForLabCriteria") == "minTimeAndPercentageNotCompleted" || this.model.get("completionStatusForLabCriteria") == "percentageNotCompleted");
			$(criteriaWarningClass).find(".manual-steps-read-value")
				.text(App.i18n("percent", Math.floor(this.model.get("stepVisitedPercent"))));
			this.$("[rel=popover]").popover();
		},

		remove : function(){
			this.stopTimeRemainingUpdate();
			$(document).off("click.unenroll", "#modal_unenroll_" + this.model.get("id") + " .unenroll-lab",  this.unenrollLab);
			LabView.prototype.remove.call(this);

		},

		startTimeRemainingUpdate : function(){
			this.timeRemainingTimer = setInterval(this.updateTimeRemaining, 30000);
			this.updateTimeRemaining();
			App.locale.off("loadStop", this.updateTimeRemaining);
			App.locale.on("loadStop", this.updateTimeRemaining);
		},
		updateTimeRemaining : function(){
			if(this.model.get("expirationTime") <= 0){
				this.stopTimeRemainingUpdate();
				this.model.set("status", "expired");
				this.render();
			} else{
				if(this.model.get("expirationTime") * 60000 < App.config.expiresWarning){
					this.$(".time-remaining-label,.expiration-time-label").addClass("warning");
					this.$(".progress-warning-icon").show();

				}
				this.model.trigger("change:timeRemainingLabel");
				this.model.trigger("change:expirationTimeLabel");
			}
		},
		stopTimeRemainingUpdate : function(){
			App.locale.off("loadStop", this.updateTimeRemaining);
			if(this.timeRemainingTimer){
				clearInterval(this.timeRemainingTimer);
			}
		}
	});

});

