
define('hbs!tpls/maintenance/main.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    return "<div class=\"page-content\">\n\n\n	<div id=\"maintenance_info\">\n		<span id=\"status_indicator\" class=\"fa fa-5x fa-cog\"></span>\n		<div class=\"checking-status-message\">\n			<div class=\"loading\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"checkingStatus",{"name":"$","hash":{},"data":data}))
    + "</div>\n		</div>\n\n\n		<div id=\"maintenance_message\">\n			<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"maintenance.generalMessage",{"name":"$","hash":{},"data":data}))
    + "</h4>\n		</div>\n	</div>\n\n	<div id=\"ok_info\" class=\"hide\">\n		<h2>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"maintenance.available",{"name":"$","hash":{},"data":data}))
    + "</h2>\n\n		<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"maintenance.availableMessage",{"name":"$","hash":{},"data":data}))
    + "</h4>\n\n		<p><a href=\"#\" onclick=\"javascript:window.history.back();\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"maintenance.goBack",{"name":"$","hash":{},"data":data}))
    + "</a></p>\n	</div>\n\n</div>\n";
},"useData":true});
Handlebars.registerPartial('maintenance_main', t);
return t;
});

