define('views/catalogs/transcript-listing',['require','jquery','underscore','library/vlp/app','library/vlp/view','models/transcript','views/catalogs/transcript-lab','hbs!tpls/catalogs/transcript-listing.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App                  = require("library/vlp/app"),
	    BaseView             = require("library/vlp/view"),
	    Transcript           = require("models/transcript"),
	    TranscriptInfoView   = require("views/catalogs/transcript-lab"),
//	    TranscriptCourseView = require("views/catalogs/transcript-course"),
	    enrollmentListingTPL = require("hbs!tpls/catalogs/transcript-listing.handlebars");

	return BaseView.extend({
		template : enrollmentListingTPL,
		removeKeepEl : true,

		activeItemId : null,
		children : {},

		pageNum    : 1,
		scrollTop  : 0,

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"submit .form-search"      : "onSearch",
			"click  .email-transcript" : "onEmailTranscript",
			"click .fetch-more .btn"   : "onLoadMore"
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			options = options || {};
			options.fetchDefaults = options.fetchDefaults || {};

			this.children = [];

			this.fetchDefaults = _.extend({
				params  : {
					"with"     : ["labs", "labs.entitlement", "courses", "courses.labs", "courses.labs.entitlements"]
				},
				perPage : App.config.itemsPerPage,
				loginCancelable : true
			}, options.fetchDefaults);

			this.collection = new Transcript.Collection();

			this.listenTo(this.collection, "request", this.onFetching);
			this.listenTo(this.collection, "sync",    this.onFetched);
			this.listenTo(this.collection, "add",     this.onItemAdded);
			this.listenTo(this.collection, "reset",   this.onReset);
			this.listenTo(this.collection, "error",   this.fetchError);

			if(App.config.catalogInfiniteScrolling){
				$(window).on("scroll", this.onScroll);
			}

			this.search = options.search;

		},

		serialize : function(){
			var data = this.collection.toHandlebars();
			data.loggedIn = App.user.get("loggedIn");
			return data;
		},

		beforeRender : function(){
			this.$("[rel=tooltip]").tooltip("hide");

			_.each(this.children, function(view){
				view.$el.detach();
			});
		},
		afterRender : function(){

			var self = this;
			this.$itemHolder = this.$(".items-holder");
			if(this.$itemHolder.length && this.collection.length){
				this.collection.each(function(model){
					var itemView = self.children[model.get("id")];

					if(!itemView){
						itemView = new TranscriptInfoView({
							model : model
						});
						self.children[model.get("id")] = itemView;
					}

					self.$itemHolder.append(itemView.el);
					itemView.setElement(itemView.el);
					//This is hacky, but otherwise dom events seem to get detached.
					if(!itemView.rendered){
						itemView.render();
					} else{
						itemView.afterRender();
					}
				});

				if(this.activeItemId){
					if(this.$(".lab-box[data-lab-id=\"" + this.activeItemId + "\"]").length){
						this.$(".lab-box[data-lab-id=\"" + this.activeItemId + "\"]").addClass("active");
						_.defer(function(){
							$("html, body").scrollTop(self.$(".lab-box[data-lab-id=\"" + self.activeItemId + "\"]").offset().top - 70);
						});
					} else {
						// Fetch recursively while it shows up
						this.onLoadMore();
					}
				}
			}
			$("html, body").scrollTop(this.scrollTop);
			this.$("[rel=tooltip]").tooltip();
			this.$("[name=search]").val(this.search);
		},

		onReset : function(){
			this.removeChildren();
			this.$(".items-holder").empty();
		},
		onFetching : function(){
			if(App.config.catalogInfiniteScrolling){
				if(!this.rendered){
					this.render();
				}
				this.$(".fetch-more .btn").button("loading");
			} else{
				this.render();
			}

			App.analytics.trackEvent("Transcript", "View", null, this.collection.page || 1);
		},
		onFetched : function(){
			this.render();
			this.onScroll();
			this.$(".fetch-more-trigger").button("reset");
		},
		onItemAdded : function(item){

			var itemView = new TranscriptInfoView({
				model     : item
			});
			this.children[item.get("id")] = itemView;

			this.$(".items-holder").append(itemView.render().el);
		},
		onLoadMore : function(event){
			if(this.collection.length <= this.collection.totalCount && !this.collection.loading){
				this.fetch({add : true, remove : false});
				this.$(".fetch-more .btn").button("loading");
			}
		},
		onScroll : function(event){
			var $trigger = this.$(".fetch-more-trigger");
			if($trigger.length === 0) { return; }

			this.scrollTop = $(window).scrollTop();
			if(this.scrollTop > ($trigger.offset().top + $trigger.outerHeight())- $(window).height()) {
				this.onLoadMore();
			}
		},
		show : function(pageNum, activeItemId){

			if(activeItemId){
				this.activeItemId = activeItemId;
			}

			if(App.config.catalogInfiniteScrolling || !pageNum){
				this.pageNum = 1;
			} else {
				this.pageNum = pageNum;
			}

			if(!this.collection.loading){
				this.collection.reset();
				this.fetchPage(this.pageNum);
			} else{
				this.render();
			}

			this.scrollTop = 0;
			$("html, body").scrollTop(0);
		},
		onSearch : function(event){

			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}
			this.search = this.$("[name=search]").val();

			this.collection.reset();
			this.collection = null;

			this.fetchDefaults.params.search = this.search;
			this.render();
		},
		onEmailTranscript : function(event){
			var self = this;

			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			if(!App.user.get("accountVerified")){
				return App.mainView && App.mainView.showAccountVerificationMessage();
			}

			this.$(".email-transcript").button("loading");
			if(!this.collection) {
				this.collection = new Transcript.Collection();
			}
			this.collection.email({
				success : function(){
					self.$(".email-transcript")
						.button("requested")
						.tooltip("show");

					_.defer(function(){
						self.$(".email-transcript").prop("disabled", true);
					});

					_.delay(function(){
						self.$(".email-transcript").tooltip("hide");
					}, 5000);
					App.analytics.trackEvent("Transcript", "Email: Transcript");
				},
				error : function(){
					self.$(".email-transcript")
						.prop("disabled", false).button("reset");

				}
			});

		},
		fetchError : function(collection, jqXHR){
			if(jqXHR.statusText === "loginCanceled"){
				this.render();
			} else{
				return false;
			}
		},
		remove : function(){
			$(window).off("scroll", this.onScroll);
			this.removeChildren();
			BaseView.prototype.remove.call(this);
		},
		removeChildren : function(){
			_.each(this.children, function(view){
				view.remove();
			});
			this.children = {};
		}
	});

});

