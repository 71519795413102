define('views/catalogs/lab-rating',['require','underscore','library/vlp/app','library/vlp/view','hbs!tpls/catalogs/ratings-popover.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//class dependencies
	var App        = require("library/vlp/app"),
	    BaseView   = require("library/vlp/view"),
	    PopoverTPL = require("hbs!tpls/catalogs/ratings-popover.handlebars");


	return BaseView.extend({
		template : PopoverTPL,

		forceUserRating : false,
		throttleRending : 100,

		initialize : function(options){
			_.bindAll(this);
			this.entitlement = options.entitlement || this.model.get("entitlement");
			this.forceUserRating = options.forceUserRating;
			this.listenTo(this.model, "change", this.render);
		},
		render : function(options){
			options = options || {};

			var self = this;
			this.$(".rating-wrapper .spinner").hide();

			var hideRatings = !this.model || !this.model.get("id") || (!this.model.get("hasRating") && !this.forceUserRating);
			this.$(".rating-wrapper").toggleClass("hide", hideRatings);
			this.$el.toggleClass("with-ratings", !hideRatings);

			if(this.model && !hideRatings){
				var ratyOptions = {
					readOnly : true
				};

				var previouslyCompleted = (this.entitlement && this.entitlement.get("status") === "completed") || this.forceUserRating;

				if(previouslyCompleted){
					ratyOptions.click =  function(score){
						self.$(".rating-wrapper .spinner").show();

						self.model.rate(score, {
							success : function() {
								App.analytics.trackEvent("Lab", "Rate", self.model.get("sku"), score);
								self.render();
							},
							error   : self.error
						});
					};
					ratyOptions.readOnly = false;
				}
				if(this.model.get("userRated")){
					ratyOptions.score = this.model.get("userRating");
					ratyOptions.starOn = "star-rated.png";
				} else{
					ratyOptions.score = this.model.get("ratingAvg") || 0;
				}


				this.$(".rating")
					.raty("destroy")
					.empty()
					.raty(ratyOptions);

				if(this.model.get("hasRating")){
					this.$(".rating-wrapper").popover({
						content : function(){
							return self.template(self.model);
						},
						title : function(){
							return App.i18n("catalogs.labs.ratings.label");
						}
					});
					if(options.showPopover){
						this.$(".rating-wrapper").data("popover").options.animation = false;
						this.$(".rating-wrapper").popover("show");
						this.$(".rating-wrapper").data("popover").options.animation = true;
					} else{
						this.$(".rating-wrapper").popover("hide");
					}
				}
			}
		},
		error : function(model, resp){
			var self = this;
			this.render();
			var response = resp.response || resp;
			var message = ((response && response.errorCode === 5301) ? App.i18n("catalogs.labs.ratings.alreadyRated") : _.escapeHTML(response.message));
			this.$(".rating-wrapper .rating").tooltip({
				placement : "left",
				title     : message,
				trigger   : "manual",
				"class"   : "error"
			}).tooltip("show");

			// Deactivate the ratings popover when the already rated tooltip shows up.
			this.$(".rating-wrapper").popover("hide").popover("disable");

			_.delay(function(){
				// Enable the ratings popover.
				self.$(".rating-wrapper").popover("enable");

				// Destroy the ratings tooltip after 5 secs.
				self.$(".rating-wrapper .rating").tooltip("destroy");
			}, 5000);



		},
		onLabChanged : function(model, changed){
			if(
				changed.hasOwnProperty("ratingAvg") ||
				changed.hasOwnProperty("ratingNum") ||
				changed.hasOwnProperty("oneStar")   ||
				changed.hasOwnProperty("twoStar")   ||
				changed.hasOwnProperty("threeStar") ||
				changed.hasOwnProperty("fourStar")  ||
				changed.hasOwnProperty("fiveStar")
			){
				this.render();
			}
		}

	});

});

