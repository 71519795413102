define('models/step',['require','exports','module','underscore','library/vlp/app','library/vlp/model','library/vlp/collection'],function (require, exports) {
	"use strict";

	//library dependencies
	var _        = require("underscore");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection");


	//Use exports for requirejs circular dependency resolving
	var Step = exports;

	//Add Step to the App.Models namespace
	App.Models.Step = Step;


	Step.Model = BaseModel.extend({
		urlRoot     : "/manuals",
		pushService : "manuals",

		defaults : {
			stepNo  : 0,
			title   : null,
			content : null
		},
		getters : {
			partial : function(){
				return !this.has("title") || !this.has("content");
			}
		},
		/**
		 * Constructor
		 */
		initialize : function(){
		},
		url : function(){
			if(App.config.fetchManualsFromContent){
				return App.config.contentBase + "steps/" + this.id;
			} else {
				var urlRoot = this.basePath();
				urlRoot += this.get("manualId") + "/node?nodeId=" + this.id;
				return this.serverBase + urlRoot;
			}
		},
		pushReturnEvent : function(){
			var pushEvent = "received:id:" + _.result(this, "pushService");
			pushEvent += ":" + this.get("id");
			return pushEvent;
		},
		sync : function(method, model, options){
			options = options || {};
			if(method === "read"){
				options.operation = options.operation || "node";
			}
			if(App.config.fetchManualsFromContent){
				delete options.returnPath;
				delete options.pushReturn;
				options.params = options.params || {};
				options.params.preview = true;
				options.params.manualId = this.get("manualId");
			}
			return BaseModel.prototype.sync.call(this, method, model, options);
		},

		switchPage : function( options){

			options = options || {};
			options.data = options.data || {};
			options.data.entitlementId = options.params.entitlementId;
			options.data = _.defaults(options.data, this.toJSON());
			options.pushSync = true;
			options.pushBroadcast = true;
			options.local = true;
			options.params = options.params || {};
			options.params.id = this.get("manualId");
			return this.action("switchPage", options);
		}
	});


	Step.Collection = BaseCollection.extend({
		model : Step.Model,
		manualId : 0,
		initialize : function(models, options){
			options = options || {};
			if(options.manualId){
				this.manualId = options.manualId;
			}
		}
	});

});

