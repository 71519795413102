define('models/entitlement-life-cycle',['require','exports','module','library/vlp/app','library/vlp/model','library/vlp/collection'],function (require, exports) {
	"use strict";

	//class dependencies
	var App            = require("library/vlp/app"),
		BaseModel      = require("library/vlp/model"),
		BaseCollection = require("library/vlp/collection");

	//Use exports for requirejs circular dependency resolving
	var EntitlementLifeCycle = exports;

	//Add Transcript to the App.Models namespace
	App.Models.EntitlementLifeCycle = EntitlementLifeCycle;

	EntitlementLifeCycle.Model = BaseModel.extend({
		urlRoot: "/entitlementStats",

		defaults: {
			enrollTime      : "",
			entitlementKey  : "",
			totalTime       : "",
			activeSessions  : [],
			awaitingResponse: true
		},

		getEntitlementStatsData: function (options) {
			options        = options || {};
			options.params = options.params || {};
			options.params.entitlementKey = this.get("entitlementKey");
			options.method = "read";

			var success = options.success;
			options.success = function(model, response){
				model.set("awaitingResponse", false);
				if(success) {
					success(response);
				}
			};

			return this.action("getEntitlementStatsData", options);
		}
	});

	EntitlementLifeCycle.Collection = BaseCollection.extend({
		model: EntitlementLifeCycle.Model
	});

});

