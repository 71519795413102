define('library/underscore/to-json-deep',['require','underscore','backbone'],function (require) {
	"use strict";
	var _        = require("underscore"),
	    Backbone = require("backbone");

	_.mixin({
		toJSONDeep: function(obj){
			var processed = {};
			var processValue = function(value){
				var returnValue;
				if (value instanceof Backbone.Collection) {
					returnValue = [];

					value.each(function(item) {
						returnValue.push(process(item));
					});
				} else if (value instanceof Backbone.Model) {
					returnValue = process(value);
				} else if (_.isArray(value)) {
					returnValue = [];
					for (var arrayItem in value) {
						if (value.hasOwnProperty(arrayItem)) {
							var arrayValue = value[arrayItem];
							if (_.isArray(arrayValue) || arrayValue === Object(arrayValue)) {
								returnValue.push(process(arrayValue));
							} else {
								returnValue.push(arrayValue);
							}
						}
					}
				} else if (_.isDate(value)) {
					returnValue = value;
				} else if (value === Object(value)) {
					returnValue = {};
					for (var objectItem in value) {
						if (value.hasOwnProperty(objectItem)) {
							var objectValue = value[objectItem];
							if (_.isArray(objectValue) || objectValue === Object(objectValue)) {
								returnValue[objectItem] = process(objectValue);
							} else {
								returnValue[objectItem] = objectValue;
							}
						}
					}
				} else {
					returnValue = value;
				}
				return returnValue;
			};
			var process = function (model) { // jshint ignore:line

				if(model === null || model === undefined){
					return model;
				}
				//Prevents too many recursive calls and creates appropriate circular dependencies.
				var data = {};
				if (model.hasOwnProperty("cid")) {
					if (processed[model.cid]) {
						return processed[model.cid];
					}
					processed[model.cid] = data; //Stash data for future calls on this same object.
				}

				var attributes = model.attributes || model;

				for (var attr in attributes) {
					if (attributes.hasOwnProperty(attr)) {
						data[attr] = processValue(attributes[attr]);
					}
				}
				if (model.getters) {
					for (var getterAttr in model.getters) {
						if (model.getters.hasOwnProperty(getterAttr)) {
							data[getterAttr] = processValue(model.get(getterAttr));
						}
					}
				}

				return data;
			};

			return processValue(obj);
		}
	});

	return _;
});

