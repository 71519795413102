define('views/catalogs/lab-reviews',['require','jquery','underscore','library/vlp/view','models/lab-review','hbs!tpls/catalogs/reviews-modal.handlebars','hbs!tpls/catalogs/lab/review.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var BaseView  = require("library/vlp/view"),
	    LabReview = require("models/lab-review"),
	    Template  = require("hbs!tpls/catalogs/reviews-modal.handlebars");

	require("hbs!tpls/catalogs/lab/review.handlebars");

	return BaseView.extend({
		template : Template,
		events : {
			"hidden .modal" : "onHidden"
		},
		initialize : function(){
			_.bindAll(this);
		},
		render : function() {

			this.$el.html(this.template(this.serialize()));


			var ratyOptions = {
				readOnly : true,
				starOn   : "star-rated.png",
				score: function() {
					return $(this).data("score");
				}
			};

			this.$(".rating").raty(ratyOptions);
		},
		show : function(){
			this.$(".modal").modal("show");
			if(!this.model.has("reviews") || (!this.model.get("reviews").fetched && this.model.get("reviews").length === 0)){
				var labReviews = new LabReview.Collection();
				labReviews.labId = this.model.get("id");
				labReviews.fetch({
					success : this.render,
					error   : this.render
				});
				this.model.set("reviews", labReviews);
			}
		},
		onHidden : function(){
			this.$(".rating").raty("destroy");
			this.$el.remove();
		}

	});
});
