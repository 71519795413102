
define('hbs!tpls/common/language-chooser.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "\"language\"";
},"3":function(depth0,helpers,partials,data) {
    return "\"off\"";
},"5":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "									<option value=\""
    + this.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"value","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n										"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.labelKey : depth0),{"name":"$","hash":{},"data":data}))
    + "\n										"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n										"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.manualOnly : depth0),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n									</option>\n";
},"6":function(depth0,helpers,partials,data) {
    return "selected=\"selected\"";
},"8":function(depth0,helpers,partials,data) {
    return " <i>("
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"language-chooser.active",{"name":"$","hash":{},"data":data}))
    + ")</i>";
},"10":function(depth0,helpers,partials,data) {
    return " <b>("
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"language-chooser.manualOnly",{"name":"$","hash":{},"data":data}))
    + ")</b>";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"modal-dialog\">\n	<div class=\"modal-content\">\n		<div class=\"modal-header\">\n			<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n			<h3 class=\"modal-title\">\n				<span class=\"fa fa-fw fa-globe\"></span>\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"header.language",{"name":"$","hash":{},"data":data}))
    + "\n			</h3>\n		</div>\n		<form class=\"form-horizontal\">\n			<div class=\"modal-body\">\n\n				<fieldset class=\"preferences\">\n					<div class=\"control-group\">\n						<label class=\"control-label\" for=\"profile_language\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.preferences.language",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n						<div class=\"controls\">\n							<select id=\"language\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.languages : depth0),{"name":"each","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "							</select>\n						</div>\n					</div>\n				</fieldset>\n			</div>\n\n			<div class=\"modal-footer\">\n				<button class=\"btn btn-large submit\" type=\"submit\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"language-chooser.change",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<a class=\"cancel\" type=\"button\" data-dismiss=\"modal\" href=\"#\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</a>\n			</div>\n		</form>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('common_language_chooser', t);
return t;
});

