
define('hbs!tpls/console/saas-console.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "				<button type=\"button\" class=\"btn-no-style toggle-panel-left maximize-compact-panel-button\">\n					<span class=\"fa fa-angle-double-right\"></span>\n				</button>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.notes : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"4":function(depth0,helpers,partials,data) {
    var helper;

  return "					<button type=\"button\" class=\"btn-no-style note\" rel=\"popover\" data-trigger=\"click\" data-placement=\"bottom\" data-html=\"true\" data-title=\""
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" data-content-target=\"#popover_content_console_notes\" data-class=\"popover-console-password\">\n						<span rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.notesTooltip",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"bottom\">\n							<span class=\"fa fa-fw fa-comment-o\"></span>\n							<span class=\"btn-label\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.buttons.notes",{"name":"$","hash":{},"data":data}))
    + "</span>\n						</span>\n					</button>\n";
},"6":function(depth0,helpers,partials,data) {
    return "				<button type=\"button\" class=\"btn-no-style full-screen maximized-inline\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.fullScreen",{"name":"$$","hash":{},"data":data}))
    + " data-container=\"body\" data-placement=\"bottom\">\n					<span class=\"fa fa-fw fa-desktop\"></span>\n				</button>\n";
},"8":function(depth0,helpers,partials,data) {
    return "				<button type=\"button\" class=\"btn-no-style toggle-panel-right maximize-compact-panel-button\">\n					<span class=\"fa fa-angle-double-left\"></span>\n				</button>\n";
},"10":function(depth0,helpers,partials,data) {
    var stack1;

  return "						<button\n							type=\"button\"\n							class=\"console-full-screen\"\n							rel=\"tooltip\"\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"maximizeOnFullscreen",{"name":"config","hash":{},"fn":this.program(11, data, 0),"inverse":this.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "							data-placement=\"left\">\n							<span class=\"fa fa-fw fa-desktop\"></span>\n						</button>\n";
},"11":function(depth0,helpers,partials,data) {
    return "								"
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.fullScreenAndMaximize",{"name":"$$","hash":{},"data":data}))
    + "\n";
},"13":function(depth0,helpers,partials,data) {
    return "								"
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.fullScreen",{"name":"$$","hash":{},"data":data}))
    + "\n";
},"15":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.type : depth0),"iframe",{"name":"eq","hash":{},"data":data}),(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.type : depth0),"isim",{"name":"eq","hash":{},"data":data}),{"name":"or","hash":{},"fn":this.program(16, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.type : depth0),"test",{"name":"eq","hash":{},"fn":this.program(18, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.integrationCode : depth0),{"name":"if","hash":{},"fn":this.program(20, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"16":function(depth0,helpers,partials,data) {
    var helper;

  return "							<iframe src=\""
    + this.escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" id=\"saas_console_iframe\"></iframe>\n";
},"18":function(depth0,helpers,partials,data) {
    var helper;

  return "							<canvas id=\"test_console\" width=\""
    + this.escapeExpression(((helper = (helper = helpers.testConsoleWidth || (depth0 != null ? depth0.testConsoleWidth : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"testConsoleWidth","hash":{},"data":data}) : helper)))
    + "\" height=\""
    + this.escapeExpression(((helper = (helper = helpers.testConsoleHeight || (depth0 != null ? depth0.testConsoleHeight : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"testConsoleHeight","hash":{},"data":data}) : helper)))
    + "\"></canvas>\n";
},"20":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "							"
    + ((stack1 = ((helper = (helper = helpers.integrationCode || (depth0 != null ? depth0.integrationCode : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"integrationCode","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n";
},"22":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "					"
    + ((stack1 = ((helper = (helper = helpers.notes || (depth0 != null ? depth0.notes : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"notes","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n";
},"24":function(depth0,helpers,partials,data) {
    return "					<em>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.noNotes",{"name":"$","hash":{},"data":data}))
    + "</em>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"console_group\">\n\n	<div id=\"console_above_bar\">\n\n		<div class=\"buttons\">\n\n"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"maximizeCompactPanelTabs",{"name":"config","hash":{},"data":data}),(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"compactPanelTabs",{"name":"config","hash":{},"data":data}),{"name":"or","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"showConsoleButtons",{"name":"config","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n		<div class=\"buttons maximized-block\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.allowFullscreen : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "			<button type=\"button\" class=\"btn-no-style maximize maximized-inline\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.exitMaximize",{"name":"$$","hash":{},"data":data}))
    + " data-container=\"body\" data-placement=\"left\">\n				<span class=\"fa fa-fw fa-times\"></span>\n			</button>\n\n"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"maximizeCompactPanelTabs",{"name":"config","hash":{},"data":data}),(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"compactPanelTabs",{"name":"config","hash":{},"data":data}),{"name":"or","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n\n	<div class=\"console-center\">\n		<div class=\"console-loading console-icon-frame\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n\n		<div id=\"console_wrapper\" class=\"docked\">\n			<div id=\"console_wrapper_inner\">\n				<div class=\"screen-blanked\"><span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"class.instructorBlankedScreen",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n				<div class=\"screen-taken-over\"><span class=\"animated infinite flash slower\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"class.takenOver",{"name":"$","hash":{},"data":data}))
    + "</span></div>\n\n\n				<div class=\"controls\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.allowFullscreen : depth0),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "					<button type=\"button\" class=\"console-maximize\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-maximize-text","console.vm.buttons.exitMaximize",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.vm.buttons.maximize",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"left\">\n						<span class=\"fa fa-fw fa-arrows-alt\"></span>\n					</button>\n					<button type=\"button\" class=\"console-dock-left\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockLeft",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"left\">\n						<span class=\"fa fa-fw fa-caret-left\"></span>\n					</button>\n					<button type=\"button\" class=\"console-dock-right\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockRight",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"left\">\n						<span class=\"fa fa-fw fa-caret-right\"></span>\n					</button>\n					<button type=\"button\" class=\"console-dock-center\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.dockCenter",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"left\">\n						<span class=\"fa fa-fw fa-expand\"></span>\n					</button>\n					<button type=\"button\" class=\"console-float\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.toggleFloat",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"left\">\n						<span class=\"fa fa-fw fa-external-link\"></span>\n					</button>\n					<button type=\"button\" class=\"console-refresh\" rel=\"tooltip\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","console.entitlement.panels.refresh",{"name":"$$","hash":{},"data":data}))
    + " data-placement=\"left\">\n						<span class=\"fa fa-fw fa-rotate-left\"></span>\n					</button>\n				</div>\n				<div id=\"console\">\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.loading : depth0),{"name":"unless","hash":{},"fn":this.program(15, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "				</div>\n			</div>\n		</div>\n	</div>\n\n\n	<div id=\"browser_fullscreen_message\" class=\"hide alert alert-info alert-icon\">\n		<a class=\"close\" data-dismiss=\"alert\">&times;</a>\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.vm.fullScreenMessage",{"name":"$","hash":{},"data":data}))
    + "\n	</div>\n	<div id=\"browser_fullscreen_warning\" class=\"hide alert alert-warning alert-icon\">\n		<a class=\"close\" data-dismiss=\"alert\">&times;</a>\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"warnings.safariFullscreen",{"name":"$","hash":{},"data":data}))
    + "\n	</div>\n</div>\n\n<div class=\"hide\">\n\n	<div id=\"popover_content_console_notes\">\n		<div class=\"user-data\">\n			<p>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.notes : depth0),{"name":"if","hash":{},"fn":this.program(22, data, 0),"inverse":this.program(24, data, 0),"data":data})) != null ? stack1 : "")
    + "			</p>\n		</div>\n	</div>\n\n</div>\n\n\n<div id=\"console_drag_containment\"></div>\n";
},"useData":true});
Handlebars.registerPartial('console_saas_console', t);
return t;
});

