
define('hbs!tpls/console/entitlement-settings.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "							<option value=\""
    + this.escapeExpression(((helper = (helper = helpers.key || (data && data.key)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"key","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.keyboardLayoutId : depth0),(data && data.key),{"name":"eq","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</option>\n";
},"2":function(depth0,helpers,partials,data) {
    return "selected=\"selected\"";
},"4":function(depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"6":function(depth0,helpers,partials,data) {
    var stack1;

  return "					<div class=\"setting\">\n						<label class=\"checkbox\">\n							<input type=\"checkbox\" id=\"macos_remap_command_to_control\" "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.macOsRemapCommandToControl : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.title",{"name":"$","hash":{},"data":data}))
    + "\n						</label>\n						<span class=\"fa fa-4x fa-apple pull-right\"></span>\n						<p>\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.description",{"name":"$","hash":{},"data":data}))
    + "\n						</p>\n						<dl>\n							<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.shortcuts",{"name":"$","hash":{},"data":data}))
    + "</dt>\n\n							<dd>\n								<span class=\"type\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.copy",{"name":"$","hash":{},"data":data}))
    + "</span>\n								<span class=\"key\">⌘</span> + <span class=\"key\">c</span>\n								<span class=\"fa fa-long-arrow-right\"></span>\n								<span class=\"key\">ctrl</span> + <span class=\"key\">c</span>\n							</dd>\n							<dd>\n								<span class=\"type\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.cut",{"name":"$","hash":{},"data":data}))
    + "</span>\n								<span class=\"key\">⌘</span> + <span class=\"key\">x</span>\n								<span class=\"fa fa-long-arrow-right\"></span>\n								<span class=\"key\">ctrl</span> + <span class=\"key\">x</span>\n							</dd>\n							<dd>\n								<span class=\"type\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.paste",{"name":"$","hash":{},"data":data}))
    + "</span>\n								<span class=\"key\">⌘</span> + <span class=\"key\">v</span>\n								<span class=\"fa fa-long-arrow-right\"></span>\n								<span class=\"key\">ctrl</span> + <span class=\"key\">v</span>\n							</dd>\n							<dd>\n								<span class=\"type\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.save",{"name":"$","hash":{},"data":data}))
    + "</span>\n								<span class=\"key\">⌘</span> + <span class=\"key\">s</span>\n								<span class=\"fa fa-long-arrow-right\"></span>\n								<span class=\"key\">ctrl</span> + <span class=\"key\">s</span>\n							</dd>\n							<dd>\n								<span class=\"type\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.selectAll",{"name":"$","hash":{},"data":data}))
    + "</span>\n								<span class=\"key\">⌘</span> + <span class=\"key\">a</span>\n								<span class=\"fa fa-long-arrow-right\"></span>\n								<span class=\"key\">ctrl</span> + <span class=\"key\">a</span>\n							</dd>\n							<dd>\n								<span class=\"type\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.macOsRemapCommandToControl.undo",{"name":"$","hash":{},"data":data}))
    + "</span>\n								<span class=\"key\">⌘</span> + <span class=\"key\">z</span>\n								<span class=\"fa fa-long-arrow-right\"></span>\n								<span class=\"key\">ctrl</span> + <span class=\"key\">z</span>\n							</dd>\n						</dl>\n					</div>\n";
},"8":function(depth0,helpers,partials,data) {
    return "							<dd><span class=\"key\">⌘</span> + <span class=\"key\">,</span></dd>\n							<dd><span class=\"key\">⌘</span> + <span class=\"key\">&lt;</span></dd>\n";
},"10":function(depth0,helpers,partials,data) {
    return "							<dd><span class=\"key\">ctrl</span> + <span class=\"key\">,</span></dd>\n							<dd><span class=\"key\">ctrl</span> + <span class=\"key\">&lt;</span></dd>\n";
},"12":function(depth0,helpers,partials,data) {
    return "							<dd><span class=\"key\">⌘</span> + <span class=\"key\">.</span></dd>\n							<dd><span class=\"key\">⌘</span> + <span class=\"key\">&gt;</span></dd>\n";
},"14":function(depth0,helpers,partials,data) {
    return "							<dd><span class=\"key\">ctrl</span> + <span class=\"key\">.</span></dd>\n							<dd><span class=\"key\">ctrl</span> + <span class=\"key\">&gt;</span></dd>\n";
},"16":function(depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"modal_settings\" class=\"modal hide fluid-height fade\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<h3 class=\"modal-title\">\n					<span class=\"fa fa-fw fa-cog\"></span>\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.title",{"name":"$","hash":{},"data":data}))
    + "\n				</h3>\n			</div>\n			<div class=\"modal-body\">\n\n				<div class=\"setting\">\n					<label class=\"checkbox\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.keyBoardLayoutTitle",{"name":"$","hash":{},"data":data}))
    + "\n					</label>\n					<select id=\"keyboard_layout_id\">\n"
    + ((stack1 = helpers.each.call(depth0,(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"consoleKeyboardLayouts",{"name":"config","hash":{},"data":data}),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "					</select>\n					<span class=\"fa fa-4x fa-keyboard-o pull-right\"></span>\n					<p>\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.keyBoardLayoutDescription",{"name":"$","hash":{},"data":data}))
    + "\n					</p>\n				</div>\n\n				<div class=\"setting\">\n					<label class=\"checkbox\">\n						<input type=\"checkbox\" id=\"intercept_paste\" "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.interceptPaste : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.interceptPasteTitle",{"name":"$","hash":{},"data":data}))
    + "\n					</label>\n					<span class=\"fa fa-4x fa-clipboard pull-right\"></span>\n					<p>\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.interceptPasteDescription",{"name":"$","hash":{},"data":data}))
    + "\n					</p>\n				</div>\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isMacOs : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n				<div class=\"setting\">\n					<label class=\"checkbox manual-shortcuts\">\n						<input type=\"checkbox\" id=\"enable_manual_shortcuts\" "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.manualShortcuts : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.enableManualShortcutsTitle",{"name":"$","hash":{},"data":data}))
    + "\n					</label>\n					<span class=\"fa fa-4x fa-book pull-right\"></span>\n					<p>\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.enableManualShortcutsDescription",{"name":"$","hash":{},"data":data}))
    + "\n					</p>\n					<dl>\n						<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.enableManualShortcutsPrevious",{"name":"$","hash":{},"data":data}))
    + "</dt>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isMacOs : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "					</dl>\n					<dl>\n						<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.enableManualShortcutsNext",{"name":"$","hash":{},"data":data}))
    + "</dt>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.isMacOs : depth0),{"name":"if","hash":{},"fn":this.program(12, data, 0),"inverse":this.program(14, data, 0),"data":data})) != null ? stack1 : "")
    + "					</dl>\n				</div>\n\n				<div class=\"setting "
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.fixANSISupported : depth0),{"name":"unless","hash":{},"fn":this.program(16, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n					<label class=\"checkbox\">\n						<input type=\"checkbox\" id=\"fix_ansi\" "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.fixANSI : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.fixANSITitle",{"name":"$","hash":{},"data":data}))
    + "\n					</label>\n					<span class=\"fa fa-4x fa-language pull-right\"></span>\n					<p class=\"alert alert-warning disabled-message\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.fixANSIDisabled",{"name":"$","hash":{},"data":data}))
    + "</p>\n					<p>\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.fixANSIDescription",{"name":"$","hash":{},"data":data}))
    + "\n					</p>\n				</div>\n\n				<div class=\"setting\">\n					<label class=\"checkbox\">\n						<input type=\"checkbox\" id=\"reverse_mouse_y\" "
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.reverseScrollY : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.reverseScrollYTitle",{"name":"$","hash":{},"data":data}))
    + "\n					</label>\n					<span class=\"fa fa-4x fa-arrows-v pull-right\"></span>\n					<p>\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.settings.reverseScrollYDescription",{"name":"$","hash":{},"data":data}))
    + "\n					</p>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('console_entitlement_settings', t);
return t;
});

