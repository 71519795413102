define('nls/root/waiting-room',{
	"waitingRoom": {
		"messageUpdated": "Message last updated 14:53 GMT ",
		"notifyMe": "Nofity me when it's my turn:",
		"notifyMeByEmail": "Notify me by email",
		"leaveQueue": "Leave queue",
		"losePlace": "(You will lose your place)",
		"usersAhead": "Number of users in queue ahead of you: ",
		"expiredLab": "You lab has expired"
	},
	"waitingRoomLabState": {
		"yourLab": "Your lab",
		"isReady": "is ready",
		"hasExpired": "has expired",
		"labExpiredSubtext": "If you start the lab again, we will try to assign you a lab or place you in a queue",
		"waitingToStartLab" : "We are waiting for you to start the lab.",
		"expirationTimeWarning": "Your lab access will expire in:",
		"goToLab": "Start Lab",
		"goToCatalog": "Go to catalog",
	},
	"errors"      : {
	},
	"title"       : "Waiting Room"
});

