define('views/console/video-conference',['require','jquery','underscore','library/vlp/app','library/vlp/view','hbs!tpls/console/video-conference.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore");

	//class dependencies
	var App                = require("library/vlp/app"),
	    BaseView           = require("library/vlp/view"),
	    videoConferenceTPL = require("hbs!tpls/console/video-conference.handlebars");


	return BaseView.extend({
		template : videoConferenceTPL,
		removeKeepEl : true,

		videoConferenceFrame : null,
		videoConferenceFrameReady : false,
		connectionInfo : {},
		connected : false,
		joined : false,
		successfullyJoined : false,
		openedExternally : false,

		events: {
			"click .join-conference": "onJoinConference",
			"click .reload":          "onReload",
			"click .open-external":   "onOpenExternal"
		},
		initialize : function(options){
			_.bindAll(this);
			var self = this;
			options = options || {};

			this.entitlement = options.entitlement;
			this.setPanel(options.panel);
			this.mode = options.mode;
			this.joined = !!App.autoConnectVideoConference;
			if(this.joined && this.panel){
				this.panel.setFocus();
			}

			$(window).off("message.videoConference").on("message.videoConference", function(event){
				try{
					var data = _.isString(event.originalEvent.data) ? JSON.parse(event.originalEvent.data) : event.originalEvent.data;
					self.onReceivedMessage(data);
				} catch(e){}
			});
		},
		serialize : function(){
			var data = this.entitlement.toHandlebars();
			data.videoConference = data.videoConference || {};
			data.inviteUrl = this.generateInvitationUrl(data.videoConference);
			data.remoteUrl = this.generateRemoteUrl();
			data.joined = this.joined;
			data.mode = this.mode;
			data.openedExternally = this.openedExternally;
			data.showRemoteControl = App.config.showRemoteControl && !App.remoteControl && this.mode === "student";
			return data;
		},
		afterRender : function(){
			this.videoConferenceFrame = this.$("#video_conference_frame").get(0);
			if(this.entitlement.has("videoConference") && this.entitlement.get("videoConference").type === "zoom" && App.config.showPanels && App.config.showVideoConferencePanel){
				if(this.panel){
					this.panel.show();
					this.panel.expand({immediate: true});
					this.panel.setFocus();
				}
				$("body").addClass("has-video-conference");
			} else{
				if(this.panel){
					this.panel.hide();
				}
				$("body").removeClass("has-video-conference");
			}
			if(this.joined){
				this.setupVideoConferencing();
			}
			this.toggleStatusClasses();
		},
		toggleStatusClasses : function(){
			this.$el.toggleClass("connected", this.connected);
			this.$el.toggleClass("joined", this.joined);
			this.$el.toggleClass("successfully-joined", this.successfullyJoined);
			$("body").toggleClass("video-conference-connected", this.connected);
		},
		remove : function(){
			BaseView.prototype.remove.call(this);
		},

		setupVideoConferencing : function(){
			if(this.entitlement.get("videoConference").type === "zoom"){
				this.setupZoom();
			}
		},

		setupZoom : function(){
			var self = this;

			var sessionKey = this.entitlement.get("id") + "_video_signature";
			if(App.session.get(sessionKey) && 0){ //disabled temporarily until further testing.
				console.warn("reusing previous signature");
				this.connect(App.session.get(sessionKey));
			} else {

				$.ajax({
					type  : "GET",
					url   : App.config.serverBase + "videoConference?tenant=" + (App.getTenant() || App.config.defaultTenantPath),
					contentType : "application/json",
					data  : {
						meetingId : this.entitlement.get("videoConference").meetingId,
						//role      : this.mode === "instructor" ? 1 : 0,
						role      : 0
					},
					success: function(response){
						App.session.set(sessionKey, response.data.signature);
						self.connect(response.data.signature);
					},
					error : function(error){
						//empty function just to suppress error message
						console.log("videoConference.error", error);
					}
				});
			}
		},

		setPanel : function(panel){
			if(!panel){
				panel = null;
				if(this.panel){
					this.stopListening(this.panel);
				}
				this.panel = null;
				App.mainView.removeGeneralMessage("video_conference_still_active_message");
			} else{
				this.panel = panel;
				this.listenTo(this.panel, "panel:dock",    this.onPanelDocked);
				this.listenTo(this.panel, "panel:float",   this.onPanelFloated);
				this.listenTo(this.panel, "panel:focus",   this.onPanelFocus);
				this.listenTo(this.panel, "panel:unfocus", this.onPanelUnfocus);
				this.listenTo(this.panel, "panel:resized", this.onPanelResized);
				this.listenTo(this.panel, "panel:expand",  this.onPanelExpanded);
				this.listenTo(this.panel, "panel:close",   this.onPanelClosed);
			}
		},
		onPanelDocked : function(){
			this.checkVisibility();
		},
		onPanelFloated : function(){
			this.checkVisibility();
		},
		onPanelFocus : function(){
			this.checkVisibility();
		},
		onPanelUnfocus : function(){
			this.checkVisibility();
		},
		onPanelResized : function(){
			this.checkVisibility();
		},
		onPanelExpanded : function(){
			this.checkVisibility();
		},
		onPanelClosed : function(){
			this.checkVisibility();
		},
		checkVisibility : function(){
			if(!this.connected) { return; }

			if(this.panel && !this.panel.state.visible){
				App.mainView.generalMessage({
					id          : "video_conference_still_active_message",
					type        : "info",
					key         : "console.videoConference.zoom.stillActiveMessage",
					"class"     : "alert-large pulse animated infinite slow",
					dismissible : false
				});
			} else {
				App.mainView.removeGeneralMessage("video_conference_still_active_message");
			}
		},
		connect : function(signature){
			this.connectionInfo = this.entitlement.get("videoConference");
			this.connectionInfo.signature = signature;
			this.connectionInfo.sdkKey = App.config.zoomApiKey;
			this.connectionInfo.operation = "connect";
			this.connectionInfo.email = App.user.get("username");
			this.connectionInfo.displayName = App.user.get("displayName");
			this.connectionInfo.role = 0;
			this.connectionInfo.china = false;
			this.connectionInfo.lang = App.locale.getFull();
			this.connected = true;
			this.toggleStatusClasses();
			this.sendMessage(this.connectionInfo);
		},
		onReceivedMessage : function(data){
			// console.log("onReceivedMessage", data);
			var self = this;
			if(data.operation === "ready"){
				this.videoConferenceFrameReady = true;
				if(this.connected){
					clearTimeout(this.connectTimeout);
					this.connectTimeout = setTimeout(function(){
						self.sendMessage(self.connectionInfo);
					}, 2000);
				}
			} else if(data.operation === "joined"){
				this.successfullyJoined = true;
				this.toggleStatusClasses();
			} else if(data.operation === "leave"){
				this.reset();
				this.render();
			}
		},

		sendMessage : function(data){
			if(!this.videoConferenceFrameReady){
				return;
			}

			data.successfullyJoined = this.successfullyJoined;
			this.videoConferenceFrame.contentWindow.postMessage(data, "*");
		},
		generateInvitationUrl: function(videoConference) {
			var meetingId = videoConference.meetingId;
			var password = videoConference.password;

			if (videoConference.type === "zoom") {
				if (meetingId && password) {
					return "https://zoom.us/j/" + meetingId + "?pwd="+ password;
				} else if (meetingId) {
					return "https://zoom.us/j/" + meetingId;
				}
			}

			return "";
		},
		generateRemoteUrl: function() {
			var urlAction = (this.entitlement.get("sku") ? "lab/" + this.entitlement.get("sku") + "/" : "") + this.entitlement.id + "/";
			return App.makeUrl({page : App.config.pages.REMOTE_CONTROL, action: urlAction, forceHost : true}) + "?connectVideoConference=true";
		},
		onJoinConference : function(){
			this.joined = true;
			this.render();
		},
		onReload : function(){
			this.sendMessage({operation : "reload"});
		},
		onOpenExternal : function(){
			this.leave();
			var self = this;
			setTimeout(function(){
				self.reset();
				self.openedExternally = true;
				self.render();
			}, 1000);
		},
		leave : function(){
			this.sendMessage({operation : "leave"});
		},
		reset : function(){
			this.openedExternally = false;
			this.successfullyJoined = false;
			this.videoConferenceFrameReady = false;
			this.connected = false;
			this.joined = false;
			this.connectionInfo = {};
			this.toggleStatusClasses();
		}
	});

});

