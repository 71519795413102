define('views/instructor/messages',['require','jquery','underscore','library/vlp/view','hbs!tpls/instructor/messages.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var BaseView = require("library/vlp/view"),
	    messagesTPL = require("hbs!tpls/instructor/messages.handlebars");

	return BaseView.extend({
		template: messagesTPL,

		manualUpdated: false,

		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		el: "#messages",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events: {},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize: function (options) {
			_.bindAll(this);

			this.panel = options.panel;
			this.title = options.title;

			if (this.panel) {
				this.listenTo(this.panel, "panel:float", this.panelConvertedToFloat);
				this.listenTo(this.panel, "panel:beforeexpand", this.panelExpanding);
			}
		},

		/**
		 * Display the content.
		 */
		render: function () {

			this.$el.html(this.template);

			this.$("[rel=tooltip]").tooltip();

			//Always return this for chaining
			return this;
		},

		panelConvertedToFloat: function () {
			this.stopListening(this.panel, "panel:float", this.panelConvertedToFloat);
			this.panel.$el.height($(".dock-right").height() - 20);
			this.panel.update();

		},
		panelExpanding: function () {
			var dockedPosition = this.panel.state.position;
			if ($("#vm_group").hasClass("docked") && $("#vm_group").hasClass(dockedPosition == "left" ? "right" : "left")) {
				var width;
				if (dockedPosition === "right") {
					width = $(window).width() - ($("#vm_group").offset().left + $("#vm_group").outerWidth(true)) - 44;
				} else {
					width = $(window).width() - ($("#vm_group").offset().left + $("#vm_group").outerWidth(true)) - 44;
				}
				this.panel.$el.width(width);
				this.panel.update();
				this.panel.trigger("panel:resizing", this.panel);
			}
		}
	});
});
