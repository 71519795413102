
define('hbs!tpls/common/profile/about.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "autocomplete=\"on\"";
},"3":function(depth0,helpers,partials,data) {
    return "autocomplete=\"off\" action=\"https://about:blank\"";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<form class=\"form-horizontal\" id=\"profile_about_form\" method=\"post\" "
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " data-error-style=\"tooltip\">\n	<div class=\"alert alert-info privacy-prompt alert-icon alert-small\">\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.privacyPrompt",{"name":"$","hash":{},"data":data}))
    + " <a type=\"button\" href=\"#public-profile\" class=\"preview-public-profile\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.showPublicProfile",{"name":"$","hash":{},"data":data}))
    + "</a>\n	</div>\n	<fieldset class=\"about\">\n		<div class=\"control-group\">\n			<label class=\"control-label\" for=\"profile_shortBio\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.about.shortBio",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"controls privacy\">\n				<textarea id=\"profile_shortBio\" rows=\"5\" maxlength=\"1024\"></textarea>\n				<button data-property=\"shortBio_public\" class=\"btn btn-no-style privacy\" type=\"button\" data-toggle=\"button\" data-container=\"body\" data-placement=\"top\"></button>\n			</div>\n		</div>\n	</fieldset>\n\n	<div class=\"alert alert-error save-error-general hide\"></div>\n\n	<button type=\"submit\" class=\"btn btn-large btn-primary pull-right save-changes\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","saving",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"profile.saveChanges",{"name":"$","hash":{},"data":data}))
    + "</button>\n\n	<div class=\"spinner hide pull-right save-spinner\"/>\n	<div class=\"alert alert-success hide pull-right save-success\">\n		<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"updated",{"name":"$","hash":{},"data":data}))
    + "</strong>\n	</div>\n	<div class=\"alert alert-error hide pull-right save-error\">\n		<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"error",{"name":"$","hash":{},"data":data}))
    + "</strong>\n	</div>\n</form>";
},"useData":true});
Handlebars.registerPartial('common_profile_about', t);
return t;
});

