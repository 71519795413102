define('hbs',['require','text','handlebars'],function (require) {
	"use strict";

	//library dependencies
	var text       = require("text"),
	    Handlebars = require("handlebars");

	var buildMap = {};

	//API
	return {

		load : function(name, req, onLoad, config){
			if(!name.match(/.handlebars$/)){
				name += ".handlebars";
			}
			text.get(req.toUrl(name), function(data){
				if(config.isBuild){
					buildMap[name] = data;
					onLoad(data);
				} else{
					var compiledData = Handlebars.compile(data),
						partialName = name.replace(/[\/\-]/g, "_").replace(/(^tpls_|.handlebars$)/g, "");

					Handlebars.registerPartial(partialName, compiledData);
					onLoad(compiledData);
				}
			});
		},
		write : function(pluginName, moduleName, write){
			//The text plugin keeps a map of strings it fetched
			//during the build process, in a buildMap object.

			if(moduleName in buildMap){
				var partialName = moduleName.replace(/[\/\-]/g, "_").replace(/(^tpls_|.handlebars$)/g, "");
				write("define('" + pluginName + "!" + moduleName +
					"', [\"handlebars\"], function (Handlebars) { var t = Handlebars.template(" + Handlebars.precompile(buildMap[moduleName]) + ");\n" +
					"Handlebars.registerPartial('" + partialName + "', t);\n" +
					"return t;\n" +
					"});\n");
			}
		}

	};
});

