/**
 * Vm Backbone View
 */
define('views/console/vm-settings-prompts',['require','jquery','underscore','library/vlp/view','utilities/browser','hbs!tpls/console/vm-settings-prompts.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var BaseView           = require("library/vlp/view"),
	    Browser            = require("utilities/browser"),
	    tpl                = require("hbs!tpls/console/vm-settings-prompts.handlebars");

	return BaseView.extend({
		template : tpl,

		initialize : function(options){
			_.bindAll(this);
			options = options || {};

			this.vmConsoleView      = options.vmConsoleView;
			this.vm          = options.vm;
		},
		remove : function(){
			BaseView.prototype.remove.call(this);
		},
		serialize : function(){
			var data = {};
			data.fixANSISupported = Browser.fixANSISupported;
			return data;
		},
		afterRender : function(){
			this.$macOSRemapShortcutsModal = this.$("#modal_macos_remap_shortcuts");
			this.$interceptPasteModal      = this.$("#modal_intercept_paste");
			this.$keyboardMismatchModal    = this.$("#modal_keyboard_mismatch_prompt");
		},
		showMacOSRemapShortcutsModal : function(){
			this.$macOSRemapShortcutsModal.modal("show");
			$(".settings").removeClass("flash animated slow");

			this.$macOSRemapShortcutsModal.one("hidden", function(){
				$(".settings").addClass("flash animated slow").tooltip("show");
			});

			this.$macOSRemapShortcutsModal.find("#macos_remap_command_to_control_confirm")
				.off("click.vm-settings-prompts")
				.one("click.vm-settings-prompts", this.onMacOsRemapCommandToControlConfirm);

			this.$macOSRemapShortcutsModal.find("#macos_remap_command_to_control_cancel")
				.off("click.vm-settings-prompts")
				.one("click.vm-settings-prompts", this.onMacOsRemapCommandToControlCancel);

			this.model.set("macOsRemapCommandToControlPromptShown", true);
		},
		onMacOsRemapCommandToControlConfirm : function(){
			this.model.set("macOsRemapCommandToControl", true);
		},
		onMacOsRemapCommandToControlCancel : function(){
			this.model.set("macOsRemapCommandToControl", false);
		},

		showInterceptPasteModal : function(text){
			var self = this;

			this.$interceptPasteModal.modal("show");
			$(".settings").removeClass("flash animated slow");

			this.$interceptPasteModal.one("hidden", function(){
				$(".settings").addClass("flash animated slow").tooltip("show");
			});

			this.$interceptPasteModal.find("#intercept_paste_confirm")
				.off("click.vm-settings-prompts")
				.one("click.vm-settings-prompts", function(){self.onInterceptPasteConfirm(text);});

			this.$interceptPasteModal.find("#intercept_paste_cancel")
				.off("click.vm-settings-prompts")
				.one("click.vm-settings-prompts", this.onInterceptPasteCancel);

			this.model.set("interceptPastePromptShown", true);
		},
		onInterceptPasteConfirm : function(text){
			this.model.set("interceptPaste", true);
			if(this.vmConsoleView){
				this.vmConsoleView.sendText(text);
			}
		},
		onInterceptPasteCancel : function(){
			this.model.set("interceptPaste", false);
		},

		showKeyboardLayoutMismatchModal : function(){
			this.$keyboardMismatchModal.modal("show");
			$(".settings").removeClass("flash animated slow");

			this.$keyboardMismatchModal.one("hidden", function(){
				$(".settings").addClass("flash animated slow").tooltip("show");
			});

			var self = this;

			$("#prompt_keyboard_layout_id")
				.off("change.vm-setting-prompts")
				.on("change.vm-setting-prompts", function(){
					self.model.set("keyboardLayoutId", $(this).val());
				});
			$("#prompt_fix_ansi")
				.off("change.vm-setting-prompts")
				.on("change.vm-setting-prompts", function(){
					self.model.set("fixANSI", $(this).prop("checked"));
				});
			this.model.set("fixANSIPromptShown", true);
		}
	});

});

