
define('hbs!tpls/catalogs/lab/common-metadata.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var helper;

  return "					<img data-property=\"screenshot\" class=\"monitor-screenshot\" src=\""
    + this.escapeExpression(((helper = (helper = helpers.screenshot || (depth0 != null ? depth0.screenshot : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"screenshot","hash":{},"data":data}) : helper)))
    + "\"/>\n";
},"3":function(depth0,helpers,partials,data) {
    var helper;

  return "					<span class=\"monitor-text\">"
    + this.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"5":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.shortDescription : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"6":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<p class=\"short-description user-data\">\n				"
    + this.escapeExpression((helpers.prune || (depth0 && depth0.prune) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.shortDescription : depth0),255,"...",{"name":"prune","hash":{},"data":data}))
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "			</p>\n";
},"7":function(depth0,helpers,partials,data) {
    return "					<button class=\"read-more btn a\" type=\"button\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.readMore",{"name":"$","hash":{},"data":data}))
    + "</button>\n";
},"9":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "			<div class=\"full-description user-data\">\n				"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n				<button class=\"read-less btn a\" type=\"button\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n			</div>\n";
},"11":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.label",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd class=\"skin-text-popout\">\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.completionStatusForLabCriteria : depth0),"completed",{"name":"eq","hash":{},"fn":this.program(12, data, 0),"inverse":this.program(14, data, 0),"data":data})) != null ? stack1 : "")
    + "			</dd>\n";
},"12":function(depth0,helpers,partials,data) {
    return "					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.completed",{"name":"$","hash":{},"data":data}))
    + "\n";
},"14":function(depth0,helpers,partials,data) {
    return "					<span class=\"text-warning\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.notMet",{"name":"$","hash":{},"data":data}))
    + "\n					</span>\n";
},"16":function(depth0,helpers,partials,data) {
    return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.requiresGrading",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd class=\"skin-text-popout\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"yes",{"name":"$","hash":{},"data":data}))
    + "</dd>\n";
},"18":function(depth0,helpers,partials,data) {
    return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.dateCompleted",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd class=\"skin-text-popout\">"
    + this.escapeExpression((helpers.date_format || (depth0 && depth0.date_format) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.completionDateTime : depth0),"dateFormatFull",{"name":"date_format","hash":{},"data":data}))
    + "</dd>\n";
},"20":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.expirationDate : depth0),{"name":"if","hash":{},"fn":this.program(21, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"21":function(depth0,helpers,partials,data) {
    var helper;

  return "				<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.expiration",{"name":"$","hash":{},"data":data}))
    + "</dt>\n				<dd class=\"skin-text-popout\">\n					<span class=\"\">"
    + this.escapeExpression((helpers.date_format || (depth0 && depth0.date_format) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.expirationDate : depth0),"dateFormatFull",{"name":"date_format","hash":{},"data":data}))
    + "</span>\n					<span class=\"expiration-from-now\">(<span class=\"expiration-time-label\">"
    + this.escapeExpression(((helper = (helper = helpers.expirationTimeLabel || (depth0 != null ? depth0.expirationTimeLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"expirationTimeLabel","hash":{},"data":data}) : helper)))
    + "</span> "
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.expirationFromNow",{"name":"$","hash":{},"data":data}))
    + ")</span>\n				</dd>\n";
},"23":function(depth0,helpers,partials,data) {
    var helper;

  return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.className",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd class=\"skin-text-popout\">"
    + this.escapeExpression(((helper = (helper = helpers.className || (depth0 != null ? depth0.className : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"className","hash":{},"data":data}) : helper)))
    + "</dd>\n";
},"25":function(depth0,helpers,partials,data) {
    return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.progress",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd class=\"skin-text-popout\">"
    + this.escapeExpression((helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.progress : depth0),{"name":"percent","hash":{},"data":data}))
    + "</dd>\n";
},"27":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.timeElapsed",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd class=\"skin-text-popout\">\n				"
    + this.escapeExpression((helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.timeElapsed : depth0),{"name":"daysHoursMinutes","hash":{},"data":data}))
    + "\n"
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiredMinimumTimeSpent : depth0),0,{"name":"ne","hash":{},"fn":this.program(28, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "			</dd>\n";
},"28":function(depth0,helpers,partials,data) {
    var stack1;

  return "					<span "
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.requiredMinimumTimeSpentMet : depth0),{"name":"unless","hash":{},"fn":this.program(29, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.minimumTimeSpentRequiredLabel",(helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiredMinimumTimeSpent : depth0),{"name":"daysHoursMinutes","hash":{},"data":data}),{"name":"$","hash":{},"data":data}))
    + "\n					</span>\n";
},"29":function(depth0,helpers,partials,data) {
    return "class=\"text-warning\"";
},"31":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.manualStepsVisitedCount",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd class=\"skin-text-popout\">\n				"
    + this.escapeExpression((helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.stepVisitedPercent : depth0),{"name":"percent","hash":{},"data":data}))
    + "\n				<span "
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.requiredStepsVisitedPercentageMet : depth0),{"name":"unless","hash":{},"fn":this.program(29, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"completionCriteria.stepsVisitedPercentageRequiredLabel",(helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiredStepsVisitedPercentage : depth0),{"name":"percent","hash":{},"data":data}),{"name":"$","hash":{},"data":data}))
    + "\n				</span>\n			</dd>\n";
},"33":function(depth0,helpers,partials,data) {
    return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.timeAllotted",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd class=\"skin-text-popout\">\n				"
    + this.escapeExpression((helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.timeAllotted : depth0),{"name":"daysHoursMinutes","hash":{"roundToDays":true},"data":data}))
    + "\n			</dd>\n";
},"35":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.timelimit : depth0),{"name":"if","hash":{},"fn":this.program(36, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"36":function(depth0,helpers,partials,data) {
    return "				<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.timeLimit",{"name":"$","hash":{},"data":data}))
    + "</dt>\n				<dd class=\"skin-text-popout\">\n					"
    + this.escapeExpression((helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.timelimit : depth0),{"name":"daysHoursMinutes","hash":{"roundToDays":true},"data":data}))
    + "\n				</dd>\n";
},"38":function(depth0,helpers,partials,data) {
    return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.validPeriod",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd class=\"skin-text-popout\">\n				"
    + this.escapeExpression((helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.validPeriod : depth0),{"name":"daysHoursMinutes","hash":{"milliseconds":true,"roundToDays":true},"data":data}))
    + "\n			</dd>\n";
},"40":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.language",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd class=\"skin-text-popout\">\n"
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(helpers.l10n || (depth0 && depth0.l10n) || helpers.helperMissing).call(depth0,(helpers.concat || (depth0 && depth0.concat) || helpers.helperMissing).call(depth0,"locales.",(depth0 != null ? depth0.language : depth0),{"name":"concat","hash":{},"data":data}),{"name":"l10n","hash":{},"data":data}),(helpers.concat || (depth0 && depth0.concat) || helpers.helperMissing).call(depth0,"locales.",(depth0 != null ? depth0.language : depth0),{"name":"concat","hash":{},"data":data}),{"name":"ne","hash":{},"fn":this.program(41, data, 0),"inverse":this.program(43, data, 0),"data":data})) != null ? stack1 : "")
    + "			</dd>\n";
},"41":function(depth0,helpers,partials,data) {
    return "					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,(helpers.concat || (depth0 && depth0.concat) || helpers.helperMissing).call(depth0,"locales.",(depth0 != null ? depth0.language : depth0),{"name":"concat","hash":{},"data":data}),{"name":"$","hash":{},"data":data}))
    + "\n";
},"43":function(depth0,helpers,partials,data) {
    return "					"
    + this.escapeExpression((helpers.capitalize || (depth0 && depth0.capitalize) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.language : depth0),{"name":"capitalize","hash":{},"data":data}))
    + "\n";
},"45":function(depth0,helpers,partials,data) {
    var helper;

  return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.networkTopology",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd class=\"skin-text-popout\">\n				<button type=\"button\" class=\"btn a view-topology\" data-toggle=\"modal\" data-target=\".modal-network-topology[data-id="
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "]:first\"><span class=\"fa fa-fw fa-sitemap\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.viewTopology",{"name":"$","hash":{},"data":data}))
    + "</button>\n\n				<div class=\"modal modal-network-topology hide fade global fluid\" data-id=\""
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n					<div class=\"modal-dialog\">\n						<div class=\"modal-content\">\n							<div class=\"modal-header\">\n								<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n								<h3 class=\"modal-title\">\n									<span class=\"fa fa-fw fa-sitemap\"></span>\n									"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.networkTopology",{"name":"$","hash":{},"data":data}))
    + "\n								</h3>\n							</div>\n							<div class=\"modal-body\">\n								<img src=\""
    + this.escapeExpression(((helper = (helper = helpers.networkTopology || (depth0 != null ? depth0.networkTopology : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"networkTopology","hash":{},"data":data}) : helper)))
    + "\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"alt","catalogs.labs.networkTopology",{"name":"$$","hash":{},"data":data}))
    + " data-property=\"networkTopology\" />\n							</div>\n						</div>\n					</div>\n				</div>\n			</dd>\n";
},"47":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.exitAllowed",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd class=\"skin-text-popout\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.exitAllowed : depth0),{"name":"if","hash":{},"fn":this.program(48, data, 0),"inverse":this.program(50, data, 0),"data":data})) != null ? stack1 : "")
    + "			</dd>\n";
},"48":function(depth0,helpers,partials,data) {
    return "					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"yes",{"name":"$","hash":{},"data":data}))
    + "\n";
},"50":function(depth0,helpers,partials,data) {
    return "					<strong>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"no",{"name":"$","hash":{},"data":data}))
    + "</strong>\n";
},"52":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.endAllowed",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd class=\"skin-text-popout\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.endAllowed : depth0),{"name":"if","hash":{},"fn":this.program(48, data, 0),"inverse":this.program(50, data, 0),"data":data})) != null ? stack1 : "")
    + "			</dd>\n";
},"54":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.prepops.availability",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd class=\"skin-text-popout\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.prepopsAvailable : depth0),{"name":"if","hash":{},"fn":this.program(55, data, 0),"inverse":this.program(57, data, 0),"data":data})) != null ? stack1 : "")
    + "			</dd>\n";
},"55":function(depth0,helpers,partials,data) {
    return "					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.prepops.availabilityInstant",{"name":"$","hash":{},"data":data}))
    + "\n";
},"57":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.averageDeployTime : depth0),0,{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(58, data, 0),"inverse":this.program(60, data, 0),"data":data})) != null ? stack1 : "");
},"58":function(depth0,helpers,partials,data) {
    return "					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.prepops.availabilityOnDemandUnknown",{"name":"$","hash":{},"data":data}))
    + "\n";
},"60":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(helpers.lt || (depth0 && depth0.lt) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.averageDeployTime : depth0),60,{"name":"lt","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(61, data, 0),"inverse":this.program(63, data, 0),"data":data})) != null ? stack1 : "");
},"61":function(depth0,helpers,partials,data) {
    return "					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.prepops.availabilityOnDemandDeployTimeUnderAMinute",{"name":"$","hash":{},"data":data}))
    + "\n";
},"63":function(depth0,helpers,partials,data) {
    return "					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.prepops.availabilityOnDemandDeployTime",(helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.averageDeployTime : depth0),{"name":"daysHoursMinutes","hash":{"seconds":true},"data":data}),{"name":"$","hash":{},"data":data}))
    + "\n				";
},"65":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.locales",{"name":"$","hash":{},"data":data}))
    + "</dt>\n			<dd class=\"skin-text-popout\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.locales : depth0),{"name":"each","hash":{},"fn":this.program(66, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "			</dd>\n";
},"66":function(depth0,helpers,partials,data) {
    var stack1;

  return "					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,(helpers.concat || (depth0 && depth0.concat) || helpers.helperMissing).call(depth0,"locales.",depth0,{"name":"concat","hash":{},"data":data}),{"name":"$","hash":{},"data":data}))
    + ((stack1 = helpers.unless.call(depth0,(data && data.last),{"name":"unless","hash":{},"fn":this.program(67, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"67":function(depth0,helpers,partials,data) {
    return ", ";
},"69":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<dl class=\"dl-horizontal dl-wide\">\n			<h5>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.requiredForCompletion",{"name":"$","hash":{},"data":data}))
    + "</h5>\n"
    + ((stack1 = (helpers.gt || (depth0 && depth0.gt) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiredMinimumTimeSpent : depth0),0,{"name":"gt","hash":{},"fn":this.program(70, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.gt || (depth0 && depth0.gt) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiredStepsVisitedPercentage : depth0),0,{"name":"gt","hash":{},"fn":this.program(72, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</dl>\n";
},"70":function(depth0,helpers,partials,data) {
    return "				<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.requiredMinimumTimeSpent",{"name":"$","hash":{},"data":data}))
    + "</dt>\n				<dd class=\"skin-text-popout\">\n					"
    + this.escapeExpression((helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiredMinimumTimeSpent : depth0),{"name":"daysHoursMinutes","hash":{},"data":data}))
    + "\n				</dd>\n";
},"72":function(depth0,helpers,partials,data) {
    return "				<dt>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.labels.requiredStepsVisitedPercentage",{"name":"$","hash":{},"data":data}))
    + "</dt>\n				<dd class=\"skin-text-popout\">\n					"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.percentageRequired",(helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiredStepsVisitedPercentage : depth0),{"name":"percent","hash":{},"data":data}),{"name":"$","hash":{},"data":data}))
    + "\n				</dd>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"clearfix common-metadata\">\n	<div class=\"monitor\">\n		<div class=\"monitor-content-wrapper\">\n			<div class=\"monitor-content\">\n				<span class=\"va-helper\"></span>\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.screenshot : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "			</div>\n		</div>\n	</div>\n\n"
    + ((stack1 = helpers['if'].call(depth0,((stack1 = (depth0 != null ? depth0.display : depth0)) != null ? stack1.showDescription : stack1),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n	<dl class=\"dl-horizontal dl-wide\">\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.hasLabCompletionCriteria : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.requiresGrading : depth0),{"name":"if","hash":{},"fn":this.program(16, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.completionDateTime : depth0),{"name":"if","hash":{},"fn":this.program(18, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.status : depth0),"expired",{"name":"ne","hash":{},"fn":this.program(20, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.className : depth0),{"name":"if","hash":{},"fn":this.program(23, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.progress : depth0),undefined,{"name":"ne","hash":{},"fn":this.program(25, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.timeElapsed : depth0),undefined,{"name":"ne","hash":{},"fn":this.program(27, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.and || (depth0 && depth0.and) || helpers.helperMissing).call(depth0,(helpers.gt || (depth0 && depth0.gt) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiredStepsVisitedPercentage : depth0),0,{"name":"gt","hash":{},"data":data}),(helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.manualStepsVisitedCount : depth0),undefined,{"name":"ne","hash":{},"data":data}),{"name":"and","hash":{},"fn":this.program(31, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.timeAllotted : depth0),{"name":"if","hash":{},"fn":this.program(33, data, 0),"inverse":this.program(35, data, 0),"data":data})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.validPeriod : depth0),{"name":"if","hash":{},"fn":this.program(38, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.language : depth0),{"name":"if","hash":{},"fn":this.program(40, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.networkTopology : depth0),{"name":"if","hash":{},"fn":this.program(45, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.exitAllowed : depth0),undefined,{"name":"ne","hash":{},"fn":this.program(47, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.endAllowed : depth0),undefined,{"name":"ne","hash":{},"fn":this.program(52, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"showPrepopBadge",{"name":"config","hash":{},"data":data}),(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"showPrepopWarning",{"name":"config","hash":{},"data":data}),{"name":"or","hash":{},"fn":this.program(54, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.and || (depth0 && depth0.and) || helpers.helperMissing).call(depth0,(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.language : depth0),undefined,{"name":"eq","hash":{},"data":data}),(helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.locales : depth0),undefined,{"name":"ne","hash":{},"data":data}),(helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,((stack1 = (depth0 != null ? depth0.locales : depth0)) != null ? stack1.length : stack1),0,{"name":"ne","hash":{},"data":data}),{"name":"and","hash":{},"fn":this.program(65, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n	</dl>\n\n"
    + ((stack1 = (helpers.or || (depth0 && depth0.or) || helpers.helperMissing).call(depth0,(helpers.and || (depth0 && depth0.and) || helpers.helperMissing).call(depth0,(helpers.gt || (depth0 && depth0.gt) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiredStepsVisitedPercentage : depth0),0,{"name":"gt","hash":{},"data":data}),(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.manualStepsVisitedCount : depth0),undefined,{"name":"eq","hash":{},"data":data}),{"name":"and","hash":{},"data":data}),(helpers.and || (depth0 && depth0.and) || helpers.helperMissing).call(depth0,(helpers.gt || (depth0 && depth0.gt) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.requiredMinimumTimeSpent : depth0),0,{"name":"gt","hash":{},"data":data}),(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.timeElapsed : depth0),undefined,{"name":"eq","hash":{},"data":data}),{"name":"and","hash":{},"data":data}),{"name":"or","hash":{},"fn":this.program(69, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n</div>";
},"useData":true});
Handlebars.registerPartial('catalogs_lab_common_metadata', t);
return t;
});

