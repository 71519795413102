
define('hbs!tpls/maintenance/system-error.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"maintenance.errorCodeLabel",(depth0 != null ? depth0.errorCode : depth0),{"name":"$","hash":{},"data":data}))
    + "</p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "<h4>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"errors.getSystem",{"name":"$","hash":{},"data":data}))
    + "</h4>\n<div class=\"alert alert-error maintenance-message\">\n	<p>\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"maintenance.statusLabel",(depth0 != null ? depth0.status : depth0),{"name":"$","hash":{},"data":data}))
    + "\n		"
    + this.escapeExpression(((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"message","hash":{},"data":data}) : helper)))
    + "\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.errorCode : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "	</p>\n</div>\n<h3>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"maintenance.tryAgain",{"name":"$","hash":{},"data":data}))
    + "</h3>\n<p id=\"system_status\"><a href=\""
    + this.escapeExpression((helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"systemStatusURL",{"name":"config","hash":{},"data":data}))
    + "\" target=\"_blank\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"maintenance.systemStatusLinkMessage",{"name":"$","hash":{},"data":data}))
    + "</a></p>\n<p><a href=\"#\" onclick=\"javascript:window.history.back();\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"maintenance.goBack",{"name":"$","hash":{},"data":data}))
    + "</a></p>\n";
},"useData":true});
Handlebars.registerPartial('maintenance_system_error', t);
return t;
});

