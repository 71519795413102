/**
 * Tips Backbone View
 */
define('views/common/tips',['require','jquery','underscore','library/vlp/app','library/vlp/view','models/tip','hbs!tpls/common/tips.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App      = require("library/vlp/app"),
	    BaseView = require("library/vlp/view"),
	    Tip      = require("models/tip"),
	    tpl      = require("hbs!tpls/common/tips.handlebars");

	return BaseView.extend({

		autoHide : true,
		template : tpl,
		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		el : "#modal_welcome",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
//			"click .previous" : "previous",
			"change #no_tips"     : "toggleHideTips",
			"change #show_tips"   : "toggleShowTips"
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 */
		initialize : function(){
			_.bindAll(this);
			this.collection = new Tip.Collection();

			this.listenTo(this.collection, "request sync error", this.render);
			this.listenTo(this.collection, "sync", this._autoHide);

		},
		serialize : function(options){
			var data = this.collection.toHandlebars();
			data.autoHide = this.autoHide;
			data.showTips = this.model.get("showTips");

			return data;
		},
		afterRender : function(){
			if(this.collection.fetched){
				this.$(".previous").hide();
				if(this.collection.length <= 1){
					this.$(".right").hide();
				}

				this.$("#tip_carousel").off("slide.tips slid.tips").on("slide.tips slid.tips", function(){
					$("#modal_welcome").modal("reflow");
				});

				$("#modal_welcome").off("hide.tips").on("hide.tips", this.closed);

				//Find links and images.
				this.$(".item").each(function(){
					var html = $(this).html();
					html = html.replace(/(^|[^\/])(www\.[-A-Z0-9+&@#\/%?=~_|!:,.;]+[A-Z0-9+&@#/%=~_|])/gi, "$1<a href='http://$2'>$2</a>");
					html = html.replace(/(^|[^'"=])(https?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*\.(png|gif|jpe?g)(\?[-A-Z0-9+&@#\/%?=~_|!:,.;]*[A-Z0-9+&@#/%=~_|])?)/gi, "$1<img src='$2' />");
					html = html.replace(/(^|[^'"=])(https?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[A-Z0-9+&@#/%=~_|])/gi, "$1<a href='$2'>$2</a>");
					$(this).html(html);
				});

				this.$(".item").first().addClass("active");
				$("#modal_welcome").modal("reflow");
				this.$(".item img").unbind("load.tips").bind("load.tips", function(){
					$("#modal_welcome").modal("reflow");
				});

				this.$(".never-show-prompt").toggle(App.user.get("loggedIn"));
			}
		},

		show : function(options){

			options = options || {};

			this.originalShowTips = this.model.get("showTips");


			this.autoHide = (options.hasOwnProperty("autoHide") ? options.autoHide : this.autoHide);

			if(!this.collection.fetched && !this.collection.loading){
				this.collection.fetch();
			} else{
				this.render();
			}
			$("#modal_welcome").modal("show");
			if(!this.autoHide){
				App.analytics.trackEvent("Support", "Show: Tips");
			}
		},
		hide : function(){
			$("#modal_welcome").modal("hide");
			this.trigger("closed");
		},
		_autoHide : function(){
			if(this.collection.length === 0 && this.autoHide){
				this.hide();
			}
		},
		closed : function(){
			var self = this;

			App.session.set("welcomeShown", true, 0);

			if(this.originalShowTips !== this.model.get("showTips")){
				this.saved = true;
				this.model.save({}, {
					properties : ["showTips"],
					success : function(){
						self.originalShowTips = self.model.get("showTips");
					}
				});
			}

			this.$("#tip_carousel").off("slide slid");
			$("#modal_welcome").off("hide.tips", this.closed);
			this.$(".item img").unbind("load");
		},
		toggleHideTips : function(event){
			this.model.set("showTips", !$(event.currentTarget).prop("checked"));
		},
		toggleShowTips : function(event){
			this.model.set("showTips", $(event.currentTarget).prop("checked"));
		}

	});

});
