define('models/dashboard-content',['require','exports','module','library/vlp/app','library/vlp/model','library/vlp/collection'],function (require, exports) {
	"use strict";

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseModel      = require("library/vlp/model"),
	    BaseCollection = require("library/vlp/collection");


	//Use exports for requirejs circular dependency resolving
	var DashboardContent = exports;

	//Add DashboardContent to the App.Models namespace
	App.Models.DashboardContent = DashboardContent;


	DashboardContent.Model = BaseModel.extend({
		urlRoot : "events/dashboardContent",

		/**
		 * Default values for empty Models (new DashboardContent.Model())
		 */
		defaults  : {
		},

		/**
		 * Constructor
		 */
		initialize : function(){

		},

		getters : {
			isURL : function(){
				return this.get("content") && this.get("content").match(/^\s*https?:/i);
			}
		}
	});


	DashboardContent.Collection = BaseCollection.extend({
		model : DashboardContent.Model
	});

});

