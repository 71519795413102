define('library/vlp/base-class',['require','underscore','backbone'],function (require) {
	"use strict";

	//library dependencies
	var _        = require("underscore"),
	    Backbone = require("backbone");

	var BaseClass = function(){
		this.initialize.apply(this, arguments);
	};

	_.extend(BaseClass.prototype, Backbone.Events, {
		initialize : function(){}
	});

	BaseClass.extend = Backbone.Model.extend;

	return BaseClass;
});
