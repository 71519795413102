/* globals window */
define('utilities/window-communication',['require','jquery','underscore','library/vlp/app','library/vlp/base-class','utilities/browser'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore");

	//class dependencies
	var App       = require("library/vlp/app"),
	    BaseClass = require("library/vlp/base-class"),
	    Browser   = require("utilities/browser");

	return BaseClass.extend({
		messageKey : "_wc_",
		openPages : {},
		pageCount : 0,

		initialize : function(options){
			_.bindAll(this);
			options = options || {};
			this.messageKey = options.messageKey || this.messageKey;
			if(Browser.support.localStorage || Browser.support.sessionStorage){
				this.clean();
				$(window).unbind("storage.wc").bind("storage.wc", this._handleStorageEvent);
			}
		},
		clean : function(){
			App.store.remove(this.messageKey);
		},
		send : function(key, value){
			this._send("receive", key, value);
		},
		call : function(key, value){
			this._send("called", key, value);
		},
		respond : function(key, value){
			this._send("response", key, value);
		},

		initPage : function(name, info){
			var self = this;

			var pageInfo = {
				name : name,
				info : info,
				id   : App.id
			};

			this.on("called:openPages", function(callingPage){
				this._addPage(callingPage);
				this.respond("openPages", pageInfo);
			}, this);

			this.on("response:openPages", this._addPage);
			this.on("called:closePage", this._removePage);

			$(window).unload(function(){
				self.call("closePage", pageInfo);
			});
			this.pageCount = 1;

			this.call("openPages", pageInfo);
		},
		_send : function(type, key, value){
			var message = {
				type  : type,
				key   : key,
				value : value,
				rand  : Math.random()
			};
			if(Browser.support.localStorage || Browser.support.sessionStorage) {
				App.store.set(this.messageKey, message, 2);
			}
		},

		_handleStorageEvent : function(event){
			var storageEvent = event.originalEvent;
			if(storageEvent.key !== this.messageKey){
				return;
			}

			try{
				var info = JSON.parse(storageEvent.newValue);
				if(!info) { return; }

				if(info._d) {
					info = info._d;
				}

				this.trigger(info.type + ":" + info.key, info.value, storageEvent);
			} catch(e){}
		},
		_addPage : function(page){
			this.openPages[page.name] = this.openPages[page.name] || {};
			if(!this.openPages[page.name][page.id]){
				this.openPages[page.name][page.id] = page;
				this.pageCount++;
			}
			this.trigger("added:page", page);
		},
		_removePage : function(page){
			this.openPages[page.name] = this.openPages[page.name] || {};
			if(this.openPages[page.name][page.id]){
				delete this.openPages[page.name][page.id];
				this.pageCount--;
			}
			if(_.isEmpty(this.openPages[page.name])){
				delete this.openPages[page.name];
			}
			this.trigger("removed:page", page);
		}
	});

});
