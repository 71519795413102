define('views/console/console-listing',['require','jquery','underscore','library/vlp/app','library/vlp/view','models/vm','hbs!tpls/console/console-listing.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore");

	//class dependencies
	var App          = require("library/vlp/app"),
	    BaseView     = require("library/vlp/view"),
	    Vm           = require("models/vm"),
	    consoleListingTPL = require("hbs!tpls/console/console-listing.handlebars");


	return BaseView.extend({
		template : consoleListingTPL,
		removeKeepEl       : true,

		thumbnailsUpdating : false,
		thumbWidth         : 64,
		thumbHeight        : 48,
		/**
		 * The root DOM item for this view.
		 * All bindable actions must live under this item.
		 */
		el : "#vm_listing",
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click a.thumbnail-holder"       : "onVmLinkClicked",
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			options = options || {};

			this.entitlement = options.entitlement;
			this.panel       = options.panel;
			this.classMode   = !!options.classMode;
			this.listenTo(this.panel, "panel:float",   this.onPanelConvertedToFloat);

			this.listenTo(this.collection, "change:thumbnail", this.onThumbnailChanged);
			this.listenTo(this.collection, "change:status",    this.onStatusChanged);

			this.listenTo(this.entitlement, "change:fetchThumbnails", this.onFetchThumbnailsChanged);
			this.listenTo(this.entitlement, "change:activeConsoleId", this.onActiveConsoleIdChanged);
		},
		serialize : function(){
			var data = this.collection.toHandlebars();
			data.thumbWidth = this.thumbWidth;
			data.thumbHeight = this.thumbHeight;

			var urlAction = (this.entitlement.get("sku") ? "lab/" + this.entitlement.get("sku") + "/" : "") + this.entitlement.id + "/";
			data.vmUrlBase = App.makeUrl({page : App.config.pages.CONSOLE, action: urlAction, forceHost : true});

			data.isPrimaryStudentConsole = !App.remoteControl && !this.classMode;

			return data;
		},
		afterRender : function(options){
			var self = this;

			this.$(".thumbnail").popover({placement : function(popover, element){
				var $panel = $(element).closest(".panel");
				if(!$panel.length) {
					return "right";
				}
				if($panel.hasClass("right")){
					return "left";
				} else if($panel.hasClass("left")){
					return "right";
				} else if($panel.offset().left + ($panel.outerWidth() / 2) > ($(document).width() / 2)){
					return "left";
				} else{
					return "right";
				}

			}});

			if(this.collection.length <= 1 || !App.config.showPanels || !App.config.showConsoleChooserPanel){
				this.panel.hide();
				$("body").removeClass("has-console-chooser");
			} else{
				this.panel.show();
				$("body").addClass("has-console-chooser");
			}
			if(!this.entitlement.get("fetchThumbnails")){
				this.$(".vm .loading").hide();
			}

			this.collection.each(function(console){
				self.onStatusChanged(console);
			});

			this.onActiveConsoleIdChanged();
		},
		remove : function(){
			this.stopThumbnailUpdate();
			this.stopActiveThumbnailUpdate();
			BaseView.prototype.remove.call(this);
		},
		startThumbnailUpdate : function(){
			if(this.thumbnailUpdateInterval){
				this.stopThumbnailUpdate();
			}
			if(this.collection.length <= 1) { return; }
			this.thumbnailsUpdating = true;

			this.thumbnailUpdateInterval = setInterval(this.thumbnailUpdate, App.config.thumbnailFrequency || 5000);
			this.thumbnailUpdate();
		},
		stopThumbnailUpdate : function(){
			if(this.thumbnailUpdateInterval){
				clearTimeout(this.thumbnailUpdateInterval);
			}
			this.thumbnailUpdateInterval = null;
			this.thumbnailsUpdating = false;
			this.$("[data-vm] .loading").hide();
		},

		startActiveThumbnailUpdate : function(){
			if(this.activeVmThumbnailUpdateInterval){
				this.stopActiveThumbnailUpdate();
			}
			this.activeVmThumbnailUpdateInterval = setInterval(this.updateActiveVmThumbnail, 1000);
			this.updateActiveVmThumbnail();
		},
		stopActiveThumbnailUpdate : function(){

			if(this.activeVmThumbnailUpdateInterval){
				clearInterval(this.activeVmThumbnailUpdateInterval);
			}
			this.activeVmThumbnailUpdateInterval = null;

		},
		thumbnailUpdate : function(){

			var self = this;
			this.collection.each(function(vm){
				if(vm.get("consoleType") === "vm" && vm.get("status") === Vm.POWERED_ON && vm.get("id") != self.entitlement.get("activeConsoleId")){
					self.rateLimitedGetThumbnail(vm);
				}
			}, this);
		},
		rateLimitedGetThumbnail : function(vm){
			var self = this;

			if(!this.thumbnailsUpdating) { return; }

			//build rateLimited call
			if(!vm._rateLimitedGetThumbnail){
				vm._rateLimitedGetThumbnail = _.throttle(function(){
					if(!self.thumbnailUpdateInterval) { return; }
					if(!self.entitlement.get("fetchThumbnails")) { return; }

					return vm.getThumbnail({
						error   : function(){}
					});
				}, App.config.thumbnailFrequency || 5000, {trailing : false});
			}

			//call rate limited thumbnail
			vm._rateLimitedGetThumbnail({
				success : this.onThumbnailChanged
			});
		},
		onThumbnailChanged : function(vm){
			if(!vm || vm.get("consoleType") !== "vm"){ return; }

			var $vm = this.$("[data-vm=" + vm.id + "]");
			if(vm.get("thumbnail")){
				$vm.find("img").attr("src", vm.get("thumbnail")).show();
				$vm.find(".loading").hide();
			} else if(_.isEmpty(vm.previous("thumbnail"))){
				$vm.find("img").hide();
				$vm.find(".loading").show();
			}
		},
		onStatusChanged : function(vm){
			if(!vm || vm.get("consoleType") !== "vm"){ return; }
			var $vm = this.$("[data-vm=" + vm.id + "]");

			if(vm.get("status") === Vm.POWERED_ON){
				$vm.find(".powered-down").hide();
				this.rateLimitedGetThumbnail(vm);
			} else{
				$vm.find(".powered-down").show();
			}
		},
		updateActiveVmThumbnail : function(){
			if(!this.entitlement.get("activeConsoleId")){ return; }

			if(this.entitlement.get("activeConsoleIdType") !== "vm"){ return; }

			var vm = this.collection.findWhere({consoleType : this.entitlement.get("activeConsoleIdType"), id : this.entitlement.get("activeConsoleIdNumber")});

			if(!vm) { return; }
			if(vm.get("status") !== Vm.POWERED_ON) { return; }

			var vmCanvas = $("#vm #vm_console canvas").get(0);
			if(!vmCanvas) { return; }

			var tCanvas = document.createElement("canvas");
			var tCanvasContext = tCanvas.getContext("2d");
			tCanvas.width  = this.thumbWidth;
			tCanvas.height = this.thumbHeight;

			tCanvasContext.drawImage(vmCanvas, 0, 0, vmCanvas.width, vmCanvas.height, 0, 0, this.thumbWidth, this.thumbHeight);
			vm.set("thumbnail", tCanvas.toDataURL());
		},

		onActiveConsoleIdChanged : function(){
			this.$(".thumbnail-holder").removeClass("active");
			this.$(".thumbnail-holder[data-switch-console=" + this.entitlement.get("activeConsoleId") + "]").addClass("active");
			this.startActiveThumbnailUpdate();
		},
		onFetchThumbnailsChanged : function(){
			if(this.entitlement.get("fetchThumbnails")){
				this.startThumbnailUpdate();
			} else{
				this.stopThumbnailUpdate();
			}
		},

		onPanelConvertedToFloat : function(){
			this.stopListening(this.panel, "panel:float", this.onPanelConvertedToFloat);

			var panelFloatHeight = (this.panel.$el.height() - this.$el.height()) + this.$(".thumbnail-holder:last-child").position().top + this.$(".thumbnail-holder:last-child").outerHeight(true) + 2;
			if(panelFloatHeight > $(".dock-left").height() ){
				panelFloatHeight = $(".dock-left").height();
			}
			this.panel.$el.height(panelFloatHeight);
			this.panel.update();
		},

		onVmLinkClicked : function(event){
			event.preventDefault();
		}
	});

});

