/* globals WMKS */
/**
 * Vm Backbone View
 */
define('views/console/console-commands',['require','jquery','underscore','library/vlp/app','library/vlp/view','utilities/browser','hbs!tpls/console/console-commands.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App                = require("library/vlp/app"),
	    BaseView           = require("library/vlp/view"),
	    Browser            = require("utilities/browser"),
	    tpl                = require("hbs!tpls/console/console-commands.handlebars");

	return BaseView.extend({
		template : tpl,

		masterRunner : false,
		findMasterRunnerTimeout : null,
		commandQueue : [],
		commandQueueSize : 0,
		running : false,
		activeCommand : null,
		queueProgress : 0,
		commandProgress : 0,
		pendingCommands : [],

		runnerRunningUpdateInterval : null,
		runnerRunningCheckWaitTimeout : null,
		otherRunnerRunning : false,

		isReady : false,
		events : {
			"click #send_text"  : "onSendText"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			options = options || {};

			this.vmConsoleView      = options.vmConsoleView;
			this.entitlement = options.entitlement;
			this.vm          = options.vm;

			this.listenTo(App.push, "received:entitlement:" + this.entitlement.get("id") + ":sendToConsole",    this.onPushSendToConsoleReceived);
			this.listenTo(App.push, "other:sent:entitlement:" + this.entitlement.get("id") + ":findMasterRunner", this.onFindMasterRunnerRequestReceived);
			this.listenTo(App.push, "other:sent:entitlement:" + this.entitlement.get("id") + ":foundMasterRunner", this.onFoundMasterRunnerRequestReceived);
			this.listenTo(App.push, "other:sent:entitlement:" + this.entitlement.get("id") + ":runnerLeft", this.onRunnerLeftReceived);
			this.listenTo(App.push, "other:sent:entitlement:" + this.entitlement.get("id") + ":runnerRunning", this.onRunnerRunningReceived);
			this.listenTo(App.push, "closing", this.sendRunnerLeft);

			this.findMasterRunner();
			window.runner = this;
		},
		remove : function(){
			this.unload();
			this.sendRunnerLeft();
			BaseView.prototype.remove.call(this);
		},
		unload : function(){
			$(window).off("keydown.vm-window");
			this.hideModals();
		},

		afterRender : function(){
			this.$sendTextBox   = this.$("#send_text_text_box");

			var self = this;
			this.$sendTextModal = this.$("#modal_send_text");
			this.$sendTextModal.off("show.vm").on("show.vm", function(){
				self.$sendTextModal.draggable();
				self.$sendTextModal.find("#send_text").button("reset");
				self.$sendTextBox.val("");
			});

			this.$sendToConsoleConfirmModal = this.$("#modal_send_to_console_confirm");
		},
		hideModals : function(){
			if(this.$sendTextModal){
				this.$sendTextModal.modal("hide");
			}
		},
		onKeyDown : function(event){
			if(!event.originalEvent) {
				return;
			}
			if(event.which === 27 && !event.altKey && !event.metaKey && !event.ctrlKey && !event.shiftKey){
				this.cancelCommand();
			}
		},

		findMasterRunner : function(){
			if(this.findMasterRunnerTimeout){
				return;
			}

			this.findMasterRunnerTimeout = setTimeout(this.noMasterRunnerFound, App.config.consoleFindMasterRunnerWaitTime);
			var message = {
				service      : "entitlement",
				channel      : "/entitlement/" + this.entitlement.get("id"),
				operation    : "findMasterRunner",
				data : {
					startTime : App.startTime
				},
				requestParams: {
					id : this.entitlement.get("id")
				}
			};

			App.push.send(message);
		},
		onFindMasterRunnerRequestReceived : function(info){
			if(info.data.startTime < App.startTime){
				this.masterRunnerFound();
			}
			var message = {
				service      : "entitlement",
				channel      : "/entitlement/" + this.entitlement.get("id"),
				operation    : "foundMasterRunner",
				data         : {
					startTime : App.startTime
				},
				requestParams: {
					id : this.entitlement.get("id")
				}
			};
			App.push.send(message);
		},
		onFoundMasterRunnerRequestReceived : function(info){
			if (info.data.startTime < App.startTime){
				this.masterRunnerFound();
			}
		},

		sendRunnerLeft : function(){
			var message = {
				service      : "entitlement",
				channel      : "/entitlement/" + this.entitlement.get("id"),
				operation    : "runnerLeft",
				data : {
					masterRunner : this.masterRunner
				},
				requestParams: {
					id : this.entitlement.get("id")
				}
			};
			this.pendingCommands = [];
			this.masterRunner = false;
			App.push.send(message);
		},
		onRunnerLeftReceived : function(info){
			if(!this.masterRunner || info.data.masterRunner){
				this.findMasterRunner();
			}
		},

		masterRunnerFound : function(){
			clearTimeout(this.findMasterRunnerTimeout);
			this.findMasterRunnerTimeout = null;
			this.pendingCommands = [];
			this.masterRunner = false;
		},
		noMasterRunnerFound : function(){
			clearTimeout(this.findMasterRunnerTimeout);
			this.findMasterRunnerTimeout = null;
			for(var i = 0; i < this.pendingCommands.length; i++){
				var pendingCommand = this.pendingCommands[i];
				this.addCommands(pendingCommand.commands, pendingCommand);
			}
			this.pendingCommands = [];

			this.masterRunner = true;
			this.run();
		},

		runnerRunning : function(){
			//console.trace(String(App.startTime), "runnerRunning", this.running, "pink");
			var message = {
				service      : "entitlement",
				channel      : "/entitlement/" + this.entitlement.get("id"),
				operation    : "runnerRunning",
				data : {
					queueProgress : this.queueProgress,
					running : this.running
				},
				requestParams: {
					id : this.entitlement.get("id")
				}
			};
			App.push.send(message);
			clearInterval(this.runnerRunningUpdateInterval);
			if(this.running){
				this.runnerRunningUpdateInterval = setInterval(this.runnerRunning, App.config.consoleRunnerRunningUpdateInterval);
			}
		},
		onRunnerRunningReceived : function(info){
			clearTimeout(this.runnerRunningCheckWaitTimeout);
			this.setQueueProgress(info.data.queueProgress);
			this.otherRunnerRunning = info.data.running;
			if(this.otherRunnerRunning){
				this.vmConsoleView.$vmGroup.addClass("sending-commands");
				this.vmConsoleView.wmksDisconnectEvents();

				var self = this;
				this.runnerRunningCheckWaitTimeout = setTimeout(function(){
					self.onRunnerRunningReceived({data: {running : false}});
				}, App.config.consoleRunnerRunningCheckWaitTimeout);
			} else {
				this.vmConsoleView.$vmGroup.removeClass("sending-commands");
				this.vmConsoleView.wmksSetupEvents();
				this.run();
			}
		},

		onPushSendToConsoleReceived : function(info){
			if(info.data && info.data.commands && _.isArray(info.data.commands) && info.data.commands.length > 0){
				if(!this.masterRunner){
					this.pendingCommands.push(info.data);
					this.findMasterRunner();
				} else {
					this.addCommands(info.data.commands, info.data);
					this.run();
				}
			}
		},
		ready : function(isReady){
			if(!this.isReady && isReady){
				this.isReady = true;
				this.run();
			} else if(this.isReady && isReady){
				//do nothing, already running
			} else if(!this.isReady && !isReady){
				//do nothing, already not running
			} else if(this.isReady && !isReady){
				this.isReady = false;
				this.stop();
			}
		},
		run : function(){
			if(!this.running && this.isReady && !this.otherRunnerRunning){
				this.setQueueProgress(0);
				this.setCommandProgress(0);

				if(this.commandQueue && this.commandQueue.length > 0){
					this.running = true;
					$(window).off("keydown.vm-send-to-console-stop").on("keydown.vm-send-to-console-stop", this.onKeyDown);

					this.vmConsoleView.$vmGroup.addClass("sending-commands");
					this.vmConsoleView.wmksDisconnectEvents();

					this.runnerRunning();
					this.doNextCommand();
				}
			}
		},
		stop : function(){
			this.commandQueue = [];
			this.commandQueueSize = 0;

			if(this.running){
				this.running = false;
				this.runnerRunning();
			}

			this.setQueueProgress(0);
			this.setCommandProgress(0);
			$(window).off("keydown.vm-send-to-console-stop");
			if(this.activeCommand && this.activeCommand.deferred){
				this.activeCommand.deferred.reject();
			}
			this.activeCommand = null;
			this.vmConsoleView.$vmGroup.removeClass("sending-commands");
			this.vmConsoleView.wmksSetupEvents();
		},
		addCommands : function(commands, commonData){
			if(!this.commandQueue){
				this.commandQueue = [];
				this.commandQueueSize = 0;
			}

			commonData.groupId = _.uniqueId("consoleCommands-");

			if(commonData.hasOwnProperty("userConfirm")){
				commonData.userConfirm = _.toBoolean(commonData.userConfirm, true);
			}
			if(commonData.userConfirm === undefined || commonData.userConfirm === null){
				commonData.userConfirm = true;
			}

			if(commonData.hasOwnProperty("userCanCancel")){
				commonData.userCanCancel = _.toBoolean(commonData.userCanCancel, true);
			}
			if(commonData.userCanCancel === undefined || commonData.userCanCancel === null){
				commonData.userCanCancel = true;
			}

			for(var i = 0; i < commands.length; i++) {
				this.setupCommand(commands[i], commonData);
			}

			if(commonData.userConfirm){
				return this.addCommand("userConfirm", commands, commonData);
			}

			this.commandQueue = this.commandQueue.concat(commands);
			this.commandQueueSize += commands.length;
			this.addWaitsToQueue();
		},
		addCommand : function(type, data, commonData){
			var command = this.newCommand(type, data, commonData);
			this.commandQueue.push(command);
			this.commandQueueSize++;
			this.addWaitsToQueue();
		},
		newCommand : function(type, data, commonData){
			var command;
			if(_.isString(type)){
				command = {
					command : type,
					data    : data
				};
			} else {
				command = type;
			}
			this.setupCommand(command, commonData);
			return command;
		},
		addWaitsToQueue : function(){
			var newCommands = [];

			var previousCommand = null;
			for(var i = 0; i < this.commandQueue.length; i++){
				var command = this.commandQueue[i];
				if(
					previousCommand &&
					previousCommand.command !== "wait" && previousCommand.command !== "userConfirm" &&
					command.command !== "wait" && command.command !== "userConfirm"
				){
					newCommands.push(this.newCommand("wait", App.config.consoleSendToConsoleDefaultWait, command));
					this.commandQueueSize++;
				}
				newCommands.push(command);
				previousCommand = command;
			}
			this.commandQueue = newCommands;
		},
		setupCommand : function(command, commonData){
			commonData = commonData || {};
			if(command.hasOwnProperty("userCanCancel")){
				command.userCanCancel = _.toBoolean(command.userCanCancel, true);
			} else if (commonData.hasOwnProperty("userCanCancel")){
				command.userCanCancel = commonData.userCanCancel;
			}

			if(command.userCanCancel === undefined || command.userCanCancel === null){
				command.userCanCancel = true;
			}

			if(command.hasOwnProperty("userConfirm")){
				command.userConfirm = _.toBoolean(command.userConfirm, true);
			}
			if(command.userConfirm === undefined || command.userConfirm === null){
				command.userConfirm = false;
			}
			command.description = (command.description ? command.description : commonData.description);
			command.groupId = (commonData.groupId ? commonData.groupId : _.uniqueId("consoleCommands-"));
		},


		doNextCommand : function(){
			if(!this.isReady){
				console.warn("doNextCommand isReady is false", "red");
				return;
			}

			if(!this.commandQueue || this.commandQueue.length === 0){
				return this.stop();
			}

			var queueProgress = (this.commandQueueSize - this.commandQueue.length) / this.commandQueueSize;
			this.setQueueProgress(queueProgress);

			var command = this.commandQueue.shift();

			if(!command.command){
				console.warn("no command specified, ignoring", "red");
				return this.doNextCommand();
			}

			var commandMethod = "_commandHandler_" + command.command.toString();
			if(!this.hasOwnProperty(commandMethod)){
				console.warn("invalid command specified, ignoring", command.command);
				return this.doNextCommand();
			}

			this.setStateClass("cancelable", command.userCanCancel);
			this.setCommandProgress(0);

			//console.debug("run command", command.command, "lightgreen");
			var deferred = this[commandMethod](command);

			command.deferred = deferred;
			this.activeCommand = command;

			var self = this;
			deferred.always(function(){
				self.setCommandProgress(1);
				self.setStateClass("cancelable", false);
				self.activeCommand = null;
				self.doNextCommand();
			});
		},
		cancelCommand : function(){
			if(this.activeCommand && this.activeCommand.userCanCancel){
				var activeCommand = this.activeCommand;
				this.commandQueue = _.reject(this.commandQueue, function(command){ return command.groupId === activeCommand.groupId; });
				if(activeCommand.deferred){
					activeCommand.deferred.reject();
				}
			}
		},

		setStateClass : function(className, state){
			if(this.vmConsoleView && this.vmConsoleView.$vmGroup){
				this.vmConsoleView.$vmGroup.toggleClass(className, state);
			}
		},
		setQueueProgress : function(progress){
			this.queueProgress = progress;
			this.updateProgress();
		},
		setCommandProgress : function(progress){
			this.commandProgress = progress;
			this.updateProgress();
		},
		updateProgress : function(){
			var progress = this.queueProgress;

			if(this.commandQueueSize > 0){
				progress += (this.commandProgress / this.commandQueueSize);
			}

			var $progressBar = this.vmConsoleView.$(".sending-commands-message .command-progress");
			$progressBar.css("width", Math.min(progress * 100, 100) + "%");
		},
		wmksCommand : function(command, arg1, arg2, arg3){
			if(this.vmConsoleView && this.vmConsoleView.$vmConsole){
				return this.vmConsoleView.$vmConsole.wmks(command, arg1, arg2, arg3);
			}
			return null;
		},
		getMouseActionPosition : function(x, y){
			if(!this.vmConsoleView.$vmConsole || !this.vmConsoleView.$vmConsole.data("wmks-wmks")) { return; }

			if(_.isObject(x)){
				y = x.y;
				x = x.x;
			}

			var wmksWidget = this.vmConsoleView.$vmConsole.data("wmks-wmks");

			if (isNaN(x) || isNaN(y)) {
				return { x: 0, y: 0 };
			}

			var offset = wmksWidget._canvas.offset();
			var scalePxRatio = wmksWidget._scale / wmksWidget._pixelRatio;

			return {
				x: x * scalePxRatio + offset.left,
				y: y * scalePxRatio + offset.top
			};
		},
		showMouseAction : function(type, x, y){
			var mousePosition = this.getMouseActionPosition(x, y);
			var timeout;
			if(type.indexOf("scroll") === 0){
				$(".mouse-event.scroll-up,.mouse-event.scroll-down,.mouse-event.scroll").remove();
			}
			var $div = $("<div class='mouse-event "+type+"'><div class='pointer'></div><div class='highlight'></div></div>")
				.appendTo("body")
				.css({left: mousePosition.x, top: mousePosition.y})
				.one("animationend", function(){
					$(this).remove();
					clearTimeout(timeout);
				});
			timeout = setTimeout(function(){
				$div.remove();
			}, 1000);
		},


		/*
		Command handlers.
		These are the supported commands available.
		 */
		_commandHandler_userConfirm : function(command){
			var self = this;
			var description = command.description && String(command.description).trim();

			var $confirmButton = this.$sendToConsoleConfirmModal.find(".confirm");

			this.$sendToConsoleConfirmModal.find(".description")
				.toggle(!!(description))
				.text(description);


			var deferred = $.Deferred();

			$confirmButton.off("click.user-confirm").one("click.user-confirm", function(){
				if(self.activeCommand && self.activeCommand.command === "userConfirm"){
					var commands = _.isArray(self.activeCommand.data) ? self.activeCommand.data : [];
					self.commandQueue = commands.concat(self.commandQueue);
					self.commandQueueSize+= commands.length;
					self.addWaitsToQueue();
				}
				self.$sendToConsoleConfirmModal.modal("hide");
			});

			this.$sendToConsoleConfirmModal.off("hidden").one("hidden", function(){
				console.debug("hidden", command.groupId);
				$confirmButton.off("click.user-confirm");
				deferred.resolve();
			});

			deferred.always(function(){
				console.debug("always", command.groupId);
				self.$sendToConsoleConfirmModal.modal("hide");
			});

			console.debug("show", command.groupId);
			this.$sendToConsoleConfirmModal.modal("show");

			return deferred;
		},
		_commandHandler_wait : function(command){
			var deferred = $.Deferred();

			var waitTime = parseInt(command.data, 10);
			if(isNaN(waitTime) || waitTime < 0){
				console.warn("wait time is NaN or negative, changing to default wait time");
				waitTime = App.config.consoleSendToConsoleDefaultWait;
			}

			var self = this;


			var startTime = Date.now();

			//Update progress bar
			var progressAnimation;
			var progressAnimationCallback = function () {
				self.setCommandProgress((Date.now() - startTime) / waitTime);
				progressAnimation = window.requestAnimationFrame(progressAnimationCallback);
			};

			progressAnimationCallback();

			//Actual wait
			var waitTimeout = setTimeout(deferred.resolve, waitTime);

			deferred.fail(function(){
				clearTimeout(waitTimeout);
			});

			deferred.always(function(){
				window.cancelAnimationFrame(progressAnimation);
			});

			return deferred;
		},
		_commandHandler_input : function(command){
			var deferred = $.Deferred();
			var self = this;

			var text = _.isString(command.data) ? command.data : "";


			if(!text){
				console.warn("no text to send skipping");
				deferred.resolve();
				return deferred;
			}

			var totalTextLength   = text.length;

			var sendInputIntervalCallback = function(){
				if(text.length === 0){
					deferred.resolve();
					return;
				}

				var textChunk = text.substr(0, App.config.consoleSendInputChunkSize);
				text = text.substr(App.config.consoleSendInputChunkSize);

				self.wmksCommand("sendInputString", textChunk);

				self.setCommandProgress(1 - (text.length / totalTextLength));

				if(text.length === 0){
					deferred.resolve();
				}
			};

			var sendInputInterval = setInterval(sendInputIntervalCallback, App.config.consoleSendInputInterval);

			this.$sendTextModal.find("#send_text").button("loading");
			sendInputIntervalCallback();

			deferred.always(function(){
				clearInterval(sendInputInterval);
				self.$sendTextModal.find("#send_text").button("reset");
			});

			return deferred;
		},
		_commandHandler_mouseMove : function(command){
			var deferred = $.Deferred();
			var self = this;

			var mouseMoves = _.isPlainObject(command.data) ? [command.data] : command.data;

			if(!_.isArray(mouseMoves) || mouseMoves.length === 0){
				console.warn("no mouseMoves to send skipping");
				deferred.resolve();
				return deferred;
			}

			var totalMouseMovesLength   = mouseMoves.length;

			var sendMouseMoveIntervalCallback = function(){
				if(mouseMoves.length === 0){
					deferred.resolve();
					return;
				}

				var mouseMove = mouseMoves.shift();
				self.wmksCommand("sendMouseMoveMessage", mouseMove, "Mouse");
				self.showMouseAction("move", mouseMove);
				self.setCommandProgress(1 - (mouseMoves.length / totalMouseMovesLength));

				if(mouseMoves.length === 0){
					deferred.resolve();
				}
			};

			var sendMouseMoveInterval = setInterval(sendMouseMoveIntervalCallback, App.config.consoleSendMouseInterval);
			sendMouseMoveIntervalCallback();

			deferred.always(function(){
				clearInterval(sendMouseMoveInterval);
			});

			return deferred;
		},
		_commandHandler_mouseButton : function(command){
			var deferred = $.Deferred();
			var self = this;

			var mouseButtonActions = _.isPlainObject(command.data) ? [command.data] : command.data;

			if(!_.isArray(mouseButtonActions) || mouseButtonActions.length === 0){
				console.warn("no mouse button actions to send, skipping1");
				deferred.resolve();
				return deferred;
			}

			var mouseButtons = [];
			for(var i = 0; i < mouseButtonActions.length; i++){
				var buttonAction = mouseButtonActions[i];

				if(!buttonAction.button) {
					buttonAction.button = "left";
				}

				if(_.isString(buttonAction.button)){
					if(!WMKS.CONST.CLICK.hasOwnProperty(buttonAction.button)){
						continue;
					}
				} else {
					if(!_.contains(_.values(WMKS.CONST.CLICK), buttonAction.button)){
						continue;
					}
				}

				if(!buttonAction.hasOwnProperty("x") || !_.isNumber(buttonAction.x) || buttonAction.x < 0) { continue; }
				if(!buttonAction.hasOwnProperty("y") || !_.isNumber(buttonAction.y) || buttonAction.y < 0) { continue; }

				buttonAction.button = _.isString(buttonAction.button) ? WMKS.CONST.CLICK[buttonAction.button] : buttonAction.button;
				if(!buttonAction.action){ buttonAction.action = "click"; }

				mouseButtons.push(buttonAction);
			}

			if(mouseButtons.length === 0){
				console.warn("no valid button actions to send, skipping2");
				deferred.resolve();
				return deferred;
			}

			var totalMouseButtonsLength   = mouseButtons.length;

			var sendMouseButtonIntervalCallback = function(){
				if(mouseButtons.length === 0){
					deferred.resolve();
					return;
				}

				var buttonAction = mouseButtons.shift();
				if(buttonAction.action == "mousedown" || buttonAction.action == "click"){
					self.wmksCommand("sendMouseButtonMessage", buttonAction, true, buttonAction.button);
				}
				if(buttonAction.action == "mouseup" || buttonAction.action == "click"){
					self.wmksCommand("sendMouseButtonMessage", buttonAction, false, buttonAction.button);
				}

				self.showMouseAction(buttonAction.action, buttonAction);

				self.setCommandProgress(1 - (mouseButtons.length / totalMouseButtonsLength));

				if(mouseButtons.length === 0){
					deferred.resolve();
				}
			};

			var sendMouseButtonInterval = setInterval(sendMouseButtonIntervalCallback, App.config.consoleSendMouseInterval);
			sendMouseButtonIntervalCallback();

			deferred.always(function(){
				clearInterval(sendMouseButtonInterval);
			});

			return deferred;
		},
		_commandHandler_ctrlAltDel : function(command){
			var deferred = $.Deferred();
			var keyCodes = [17, 18, 46];
			this.wmksCommand("sendKeyCodes", keyCodes, _.keyCodesToScanCodes(keyCodes));
			setTimeout(deferred.resolve, 10);

			return deferred;
		},
		_commandHandler_requestResolution : function(command){
			var deferred = $.Deferred();
			command.data.width = (command.data.hasOwnProperty("width") ? _.toNumber(command.data.width) : 0);
			command.data.height = (command.data.hasOwnProperty("height") ? _.toNumber(command.data.height) : 0);

			if(command.data.width <= 0 || command.data.height <= 0){
				console.warn("invalid resolution request, skipping");
				deferred.resolve();
				return deferred;
			}

			//this.vm.set("resolutionChangeRequested", true);
			this.wmksCommand("requestResolution", command.data.width, command.data.height);
			setTimeout(deferred.resolve, 250);

			return deferred;
		},
		_commandHandler_keyCodes : function(command){
			var deferred = $.Deferred();

			var codes = command.data;
			if(!_.isArray(codes)){
				codes = [codes];
			}
			if(codes.length === 0){
				deferred.resolve();
				return deferred;
			}
			codes = _.map(codes, _.toNumber);

			this.wmksCommand("sendKeyCodes", codes, _.keyCodesToScanCodes(codes));
			setTimeout(deferred.resolve, 10);

			return deferred;
		},
		_commandHandler_scroll : function(command){
			var deferred = $.Deferred();
			var self = this;

			var scrolls = _.isPlainObject(command.data) ? [command.data] : command.data;

			if(!_.isArray(scrolls) || scrolls.length === 0){
				console.warn("no scrolls to send skipping");
				deferred.resolve();
				return deferred;
			}

			var totalScrollsLength   = scrolls.length;

			var sendScrollIntervalCallback = function(){
				if(scrolls.length === 0){
					deferred.resolve();
					return;
				}

				var scrollCommand = scrolls.shift();
				scrollCommand.dx = _.toNumber(scrollCommand.dx || 0);
				scrollCommand.dy = _.toNumber(scrollCommand.dy || 0);
				self.wmksCommand("sendScrollMessage", scrollCommand, scrollCommand.dx, scrollCommand.dy);
				var scrollClass = "scroll";
				if(scrollCommand.dy < 0){
					scrollClass = "scroll-down";
				} else if(scrollCommand.dy > 0){
					scrollClass = "scroll-up";
				}
				self.showMouseAction(scrollCommand.dy < 0 ? "scroll-down" : "scroll-up", scrollCommand);
				self.setCommandProgress(1 - (scrolls.length / totalScrollsLength));

				if(scrolls.length === 0){
					deferred.resolve();
				}
			};

			var sendScrollInterval = setInterval(sendScrollIntervalCallback, App.config.consoleSendMouseInterval);
			sendScrollIntervalCallback();

			deferred.always(function(){
				clearInterval(sendScrollInterval);
			});

			return deferred;
		},
		_commandHandler_keys : function(command){
			var deferred = $.Deferred();
			var self = this;

			var keys = _.isPlainObject(command.data) ? [command.data] : command.data;

			if(!_.isArray(keys) || keys.length === 0){
				console.warn("no keys to send skipping");
				deferred.resolve();
				return deferred;
			}

			var totalKeysLength   = keys.length;

			var sendKeyIntervalCallback = function(){
				if(keys.length === 0){
					deferred.resolve();
					return;
				}

				var keyCommand = keys.shift();
				keyCommand.key = _.toNumber(keyCommand.key || 0);
				keyCommand.action = (keyCommand.action ? keyCommand.action : "keyPress");

				if(keyCommand.action == "keyDown" || keyCommand.action == "keyPress"){
					self.wmksCommand("sendKey", keyCommand.key, false, false);
				}
				if(keyCommand.action == "keyUp" || keyCommand.action == "keyPress"){
					self.wmksCommand("sendKey", keyCommand.key, true, false);
				}

				self.setCommandProgress(1 - (keys.length / totalKeysLength));

				if(keys.length === 0){
					deferred.resolve();
				}
			};

			var sendKeyInterval = setInterval(sendKeyIntervalCallback, App.config.consoleSendMouseInterval);
			sendKeyIntervalCallback();

			deferred.always(function(){
				clearInterval(sendKeyInterval);
			});

			return deferred;
		},


		/**
		 * Sent Text to the console.
		 *
		 * Used by a floating textarea box to provide a place for users to mass enter text (copy and paste) and
		 * other short cut text sending.
		 *
		 * The text sending is throttled so it does not overwhelm the connection.
		 */
		sendInput : function(text){
			if(text.length === 0 || !this.vmConsoleView.$vmConsole || !this.vmConsoleView.$vmConsole.data("wmks-wmks")) { return; }

			this.vmConsoleView.$vmConsole.wmks("clearKeyboardState");
			if(Browser.osType === "Mac"){
				//prevents windows menu from popping up
				var keyCodes = [17];
				this.vmConsoleView.$vmConsole.wmks("sendKeyCodes", keyCodes, _.keyCodesToScanCodes(keyCodes));
			}
			//replace non-breaking space
			text = text.replace(/\u00a0/g, " ");

			this.addCommand("input", text, {groupId: "userInput"});
			this.run();
		},

		onSendText : function(){
			if(!this.entitlement.get("userActionAllowed")) { return; }

			var text = this.$sendTextBox.val();
			this.sendInput(text);
			App.analytics.trackEvent("Entitlement: Console", "Send Text", this.entitlement.get("sku") + " - VM:" + this.vm.get("name"));
		}
	});

});

