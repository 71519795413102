define('utilities/browser',['require','library/utilities/mdetect','library/utilities/screenfull'],function (require) {
	"use strict";

	var MobileEsp = require("library/utilities/mdetect");
	var Screenfull = require("library/utilities/screenfull");

	var Browser = {};
	try {
		Browser.edge    = Boolean(navigator.userAgent.match(/Edge\//i));
		Browser.chrome  = Boolean(navigator.userAgent.match(/Chrome/i) && !navigator.userAgent.match(/Chromeframe/i)) && !Browser.edge;
		Browser.safari  = Boolean(navigator.userAgent.match(/Safari/i) && navigator.userAgent.match(/Version/i));
		Browser.firefox = Boolean(navigator.userAgent.match(/Firefox/i));
		Browser.msie    = Boolean(navigator.userAgent.match(/(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/));
		Browser.webkit  = Boolean(navigator.userAgent.match(/(webkit)[ \/]([\w.]+)/i));
		Browser.mozilla = Boolean(navigator.userAgent.match(/(mozilla)(?:.*? rv:([\w.]+))?/i));
		Browser.opera   = Boolean(navigator.userAgent.match(/(opera)(?:.*version)?[ \/]([\w.]+)/i));
		Browser.webview = Boolean(navigator.userAgent.match(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i));
		Browser.pearson = Boolean(navigator.userAgent.match(/(IBTTestDriverAdapter)/i));


		Browser.version = {};
		var version = "", osVersion;

		Browser.trackingTag = "";

		if (Browser.safari) {
			version = navigator.userAgent.match(/Version\/([0-9\.]+)/i)[1];
			Browser.name = "safari";
			Browser.trackingTag+= "s" + parseFloat(version);
		} else if (Browser.chrome) {
			version = navigator.userAgent.match(/Chrome\/([0-9\.]+)/i)[1];
			Browser.name = "chrome";
			Browser.trackingTag+= "c" + parseFloat(version);
		} else if (Browser.firefox) {
			version = navigator.userAgent.match(/Firefox\/([0-9\.]+)/i)[1];
			Browser.name = "firefox";
			Browser.trackingTag+= "f" + parseFloat(version);
		} else if (Browser.edge) {
			version = navigator.userAgent.match(/Edge\/([0-9\.]+)/i)[1];
			Browser.name = "edge";
			Browser.trackingTag+= "e" + parseFloat(version);
		} else if (Browser.mozilla && !Browser.msie) {
			Browser.name = "mozilla";
			version = navigator.userAgent.match(/MSIE ([0-9\.]+)/i)[1];
			Browser.trackingTag+= "m" + parseFloat(version);
		} else if (Browser.opera) {
			Browser.name = "opera";
			version = navigator.userAgent.match(/MSIE ([0-9\.]+)/i)[1];
			Browser.trackingTag+= "o" + parseFloat(version);
		} else if (Browser.msie) {
			Browser.name = "msie";
			version = navigator.userAgent.match(/(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/)[2];
			Browser.trackingTag+= "i" + parseFloat(version);
		} else if (Browser.pearson) {
			Browser.name = "pearson";
			version = navigator.userAgent.match(/IBTTestDriverAdapter\/([0-9\.]+)/i)[1];
			Browser.trackingTag+= "p" + parseFloat(version);
		}

		if (navigator.userAgent.match(/NT 5.0/i)) {
			Browser.os = "Windows 2000";
			Browser.osType = "Windows";
			Browser.trackingTag+= "-w2k";
		} else if (navigator.userAgent.match(/NT 5.[12]/i)) {
			Browser.os = "Windows XP";
			Browser.osType = "Windows";
			Browser.trackingTag+= "-wxp";
		} else if (navigator.userAgent.match(/NT 6.0/i)) {
			Browser.os = "Windows Vista";
			Browser.osType = "Windows";
			Browser.trackingTag+= "-wv";
		} else if (navigator.userAgent.match(/NT 6.1/i)) {
			Browser.os = "Windows 7";
			Browser.osType = "Windows";
			Browser.trackingTag+= "-w7";
		} else if (navigator.userAgent.match(/NT 6.2/i)) {
			Browser.os = "Windows 8";
			Browser.osType = "Windows";
			Browser.trackingTag+= "-w8";
		} else if (navigator.userAgent.match(/iPad/i)) {
			osVersion = navigator.userAgent.match(/CPU .?OS ([\d_.]+)/i);
			osVersion = (osVersion && osVersion.length && osVersion[1]) || navigator.platform;
			osVersion = osVersion.replace(/_/g, ".");
			Browser.os = "iPad iOS " + osVersion;
			Browser.osType = "iPad";
			Browser.trackingTag+= "-ipad"  + osVersion;
		} else if (navigator.userAgent.match(/Android/i)) {
			osVersion = navigator.userAgent.match(/Android ([\d_.]+)/i);
			osVersion = (osVersion && osVersion.length && osVersion[1]) || navigator.platform;
			osVersion = osVersion.replace(/_/g, ".");
			Browser.os = "Android " + osVersion;
			Browser.osType = "Android";
			Browser.trackingTag+= "-and" + osVersion;
		} else if (navigator.userAgent.match(/iPhone/i)) {
			osVersion = navigator.userAgent.match(/CPU .?OS ([\d_.]+)/i);
			osVersion = (osVersion && osVersion.length && osVersion[1]) || navigator.platform;
			osVersion = osVersion.replace(/_/g, ".");
			Browser.os = "iPhone iOS " + osVersion;
			Browser.osType = "iOS";
			Browser.trackingTag+= "-iph" + osVersion;
		} else if (navigator.userAgent.match(/OS X/i)) {
			osVersion = navigator.userAgent.match(/OS X ([\d_.]+)/i);
			osVersion = (osVersion && osVersion.length && osVersion[1]) || navigator.platform;
			osVersion = osVersion.replace(/_/g, ".");
			Browser.os = "Mac OS X " + osVersion;
			Browser.osType = "Mac";
			Browser.trackingTag+= "-mac" + osVersion;
		} else {
			Browser.os = navigator.platform;
			Browser.osType = navigator.platform;
		}
		var versionParts = version.split(".");

		Browser.version.full = version;
		Browser.version.major = parseInt(versionParts.length > 0 ? versionParts[0] : 0, 10);
		Browser.version.minor = parseInt(versionParts.length > 1 ? versionParts[1] : 0, 10);
		Browser.version.float = parseFloat(version);
		Browser.other = !(Browser.chrome || Browser.safari || Browser.firefox || Browser.msie || Browser.edge);
	} catch (e) {}


	Browser.mobile = MobileEsp.isTierIphone;
	Browser.tablet = MobileEsp.isTierTablet;


	Browser.support = {};

	var storageTestKey = "_storage_test_";

	//Some code taken from Modenizr.js
	var tests = {};

	tests.canvas = function() {
		var elem = document.createElement("canvas");
		return !!(elem.getContext && elem.getContext("2d"));
	};
	tests.history = function() {
		return !!(window.history && history.pushState);
	};
	tests.postMessage = function() {
		return !!window.postMessage;
	};
	tests.webSockets = function() {
		return "WebSocket" in window || "MozWebSocket" in window;
	};
	tests.localStorage = function(){

		try {
			localStorage.setItem(storageTestKey, storageTestKey);
			localStorage.removeItem(storageTestKey);
			return true;
		} catch(e) {
			if(e.toString().match(/quota/i) || e.name.match(/quota/i)){

				try{
					//Supported, but full.
					return localStorage.length > 0;
				} catch(e2){
					return false;
				}
			}
			//Not supported
			return false;
		}
	};
	tests.sessionStorage = function(){
		try {
			sessionStorage.setItem(storageTestKey, storageTestKey);
			sessionStorage.removeItem(storageTestKey);
			return true;
		} catch(e) {
			if(e.toString().match(/quota/i) || e.name.match(/quota/i)){
				try{
					//Supported, but full.
					return sessionStorage.length > 0;
				} catch(e2){
					return false;
				}
			}
			//Not supported
			return false;
		}
	};
	tests.cookies = function(){
		if (typeof navigator.cookieEnabled === "undefined"){
			document.cookie = storageTestKey + "=test; path=/";
			var enabled = (document.cookie.indexOf(storageTestKey) !== -1);
			document.cookie = storageTestKey + "=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";

			return enabled;
		} else {
			return navigator.cookieEnabled;
		}
	};

	tests.browserVersion = function(){

		if(Browser.safari){
			return Browser.version.float >= 6.0;
		}
		if(Browser.chrome){
			return Browser.version.float >= 18;
		}
		if(Browser.firefox){
			return Browser.version.float >= 13;
		}
		if(Browser.msie){
			return Browser.version.float >= 10;
		}
		if(Browser.edge){
			return true;
		}
		if(Browser.pearson){
			return true;
		}
		return false;
	};

	tests.privateBrowsing = function(){
		return !Browser.support.localStorage && !Browser.support.sessionStorage && Browser.safari && Browser.version.major <= 8;
	};

	tests.fullscreenKeyboard = function(){
		if (!Screenfull.enabled) { return false; }

		if (Browser.chrome && Browser.version.major >= 17) { return true; }
		if (Browser.firefox && Browser.version.major >= 10) { return true; }
		if (Browser.msie && Browser.version.major >= 11) { return true; }
		if (Browser.edge) { return true; }

		//Safari currently does not allow keyboard events
		if (Browser.safari) { return false; }
		return false;
	};



	for(var test in tests){
		if(tests.hasOwnProperty(test)) {
			var result;
			try {
				result = tests[test]();
			} catch (e) {
				result = false;
			}

			Browser.support[test] = result;
		}
	}

	Browser.supported = (function(){

		if(!Browser.support.canvas || !Browser.support.webSockets){
			return false;
		}
		//if(Browser.mobile) return false;
		if(Browser.tablet) { return true; }
		if(Browser.support.browserVersion) { return true; }
		return false;
	})();

	Browser.reject = (function(){

		if(Browser.safari && (Browser.mobile || Browser.tablet)){
			return Browser.version.float < 5.1;
		}
		if(Browser.msie){
			return Browser.version.float < 8;
		}
		return false;
	})();

	Browser.partialSupport = (Browser.mobile || Browser.tablet);

	Browser.fixANSISupported = (function(){

		if(Browser.firefox && Browser.version.major < 23){
			return false;
		}
		if(Browser.chrome && Browser.version.major < 40 && Browser.osType === "Windows"){
			return false;
		}
		return true;
	})();

	Browser.support.fullscreen = Screenfull.enabled;
	Browser.canShare = !!navigator.canShare;

	window.Browser = Browser;
	return Browser;
});

