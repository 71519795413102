define('nls/root/unsupported',{
	"unsupported" : {
		"title"            : "Unsupported Browser",
		"supported"        : "Supported Browsers",
		"supportedDevices" : "Supported Devices",
		"message"          : "Your browser is unsupported. Try again with a different browser.",
		"browsers"         : {
			"firefox" : "FireFox 13+",
			"chrome"  : "Chrome 18+",
			"safari"  : "Safari 6+",
			"msie"    : "Internet Explorer 11",
			"edge"    : "Microsoft Edge"
		},
		"devices"          : {
			"ipad"    : "Apple iPad",
			"android" : "Android Tablets"
		},

		"msie"    : {
			"title"        : "This version of Internet Explorer is not supported.",
			"instructions" : "Install or use one of the supported browsers mentioned below."
		},
		"msie10"    : {
			"title"        : "Internet Explorer 10 is partially supported.",
			"instructions" : "This app requires the Transport Layer Security (TLS) 1.2 protocol to secure communications to our servers. Internet Explorer 10 does not enable TLS 1.2 by default. Enable TLS 1.2 in Internet Explore 10 or install or use one of the supported browsers mentioned below.",
			"tls12" : {
				"title" : "To enable TLS 1.2 in Internet Explore 10:",
				"step1" : "In the Tools menu, which is displayed by clicking on the gear icon near the top-right corner of an Internet Explorer 10 window, select the \"Internet options\" menu item, as depicted below:",
				"step2" : "In the Internet Options box, select the Advanced tab.",
				"step3" : "In the Security category, check Use TLS 1.2, as depicted below:",
				"step4" : "Click OK.",
				"step5" : "Exit and restart Internet Explorer.",
			}
		},
		"chrome"  : {
			"title"        : "This version of Chrome is not supported.",
			"instructions" : "Upgrade your version of Chrome: <a href=\"https://www.google.com/chrome\">here</a>"
		},
		"safari"  : {
			"title"        : "This version of Safari is not supported.",
			"instructions" : "Upgrade your version of Safari: <a href=\"http://support.apple.com/downloads/#internet\">here</a>"
		},
		"firefox" : {
			"title"        : "This version of Firefox is not supported.",
			"instructions" : "Upgrade your version of Firefox: <a href=\"http://www.mozilla.org/en-US/firefox/new/\">here</a>"
		},
		"other"   : {
			"title"        : "Your browser is not supported.",
			"instructions" : "Install one of the supported browsers mentioned above. "
		},
		"mobile"  : {
			"title"        : "Your mobile device is not supported",
			"instructions" : "Please visit VMware Lab Platform with a desktop computer or supported tablet."
		}
	},
	"errors"      : {
	},
	"title"       : "Browser Unsupported"
});

