/*globals App */
define('utilities/analytics/trackingCode',['require','utilities/analytics/base'],function (require) {
	"use strict";

	var BaseClass = require("utilities/analytics/base");

	return BaseClass.extend({

		trackingCodeCookieName : "",
		initialize : function(info){
			BaseClass.prototype.initialize.apply(this, arguments);

			if(App.getTenant()){
				this.trackingCodeCookieName = App.getTenant() + "-trackingCode";
				if(!App.trackingCode && App.cookie.get(this.trackingCodeCookieName)){
					App.trackingCode = App.cookie.get(this.trackingCodeCookieName);
				}
			}

		},
		advertisingCookiesAllowedCallback : function(){
			if(this.trackingCodeCookieName && App.trackingCode){
				App.cookie.set(this.trackingCodeCookieName, App.trackingCode);
			}
		},
		_doTrackEvent : function(category, action, label, value, nonInteraction){
			return;
		},
		_doTiming : function(category, variable, value, label){
			return;
		},
	});

});

