define('views/compatibility/tests/browser',['require','../test','utilities/browser','hbs!tpls/compatibility/tests/browser.handlebars'],function (require) {
	"use strict";

	//class dependencies
	var TestBase = require("../test"),
		Browser  = require("utilities/browser"),
		template = require("hbs!tpls/compatibility/tests/browser.handlebars");

	return TestBase.extend({
		template : template,
		name : "browser",
		run : function(){
			var result = Browser.support.browserVersion;
			if(Browser.msie && Browser.version.major === 10){
				this.warnings.msie10 = true;
				this.setStatus("warning");
			} else if(!Browser.support.browserVersion){
				this.setStatus("failure");
			} else {
				this.setStatus("success");
			}

			this.trigger(TestBase.COMPLETE, result);

		}
	});

});

