define('library/underscore/bind-all',['require','underscore'],function (require) {
	"use strict";
	var _  = require("underscore");

	_.mixin({
		bindAll: function(obj){
			var funcs = Array.prototype.slice.call(arguments, 1);
			if(funcs.length === 0) { funcs = _.functions(obj); }
			_.each(funcs, function(f){ obj[f] = _.bind(obj[f], obj); });
			return obj;
		}
	});

	return _;
});
