
define('hbs!tpls/catalogs/transcript-listing.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "		<div class=\"loading well\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.length : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(depth0,helpers,partials,data) {
    var helper;

  return "			<div class=\"email-transcript-container\">\n				<div class=\"email-transcript-inner clearfix\">\n					<span><span class=\"lab-count\">"
    + this.escapeExpression(((helper = (helper = helpers.totalCount || (depth0 != null ? depth0.totalCount : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"totalCount","hash":{},"data":data}) : helper)))
    + "</span> "
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.transcript.labsCompleted",{"name":"$","hash":{},"data":data}))
    + "</span>\n					<button class=\"btn btn-large btn-primary pull-right email-transcript\" type=\"button\" rel=\"tooltip\" data-trigger=\"manual\" data-placement=\"bottom\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-title","catalogs.transcript.emailMessage",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","catalogs.transcript.emailRequesting",{"name":"$$","hash":{},"data":data}))
    + " "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-requested-text","catalogs.transcript.emailRequested",{"name":"$$","hash":{},"data":data}))
    + ">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.transcript.email",{"name":"$","hash":{},"data":data}))
    + "\n					</button>\n				</div>\n			</div>\n			<div class=\"items-holder\">\n			</div>\n";
},"6":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<div class=\"lab-item no-transcripts\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.loggedIn : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "			</div>\n";
},"7":function(depth0,helpers,partials,data) {
    return "					<div class=\"alert\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.transcript.empty",{"name":"$","hash":{},"data":data}))
    + "\n					</div>\n";
},"9":function(depth0,helpers,partials,data) {
    return "					<div class=\"alert alert-danger\">\n						"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.transcript.pleaseLogin",{"name":"$","hash":{},"data":data}))
    + "\n					</div>\n";
},"11":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"catalogInfiniteScrolling",{"name":"config","hash":{},"fn":this.program(12, data, 0),"inverse":this.program(17, data, 0),"data":data})) != null ? stack1 : "");
},"12":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<div class=\"fetch-more\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.nextPage : depth0),{"name":"if","hash":{},"fn":this.program(13, data, 0),"inverse":this.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "		</div>\n";
},"13":function(depth0,helpers,partials,data) {
    var helper;

  return "				<button type=\"button\" value=\""
    + this.escapeExpression(((helper = (helper = helpers.nextPage || (depth0 != null ? depth0.nextPage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"nextPage","hash":{},"data":data}) : helper)))
    + "\" class=\"fetch-more-trigger btn\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"fetchMore",{"name":"$","hash":{},"data":data}))
    + "<span class=\"fa fa-fw fa-ellipsis-v\" /></button>\n";
},"15":function(depth0,helpers,partials,data) {
    return "				<span class=\"all-fetched\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.transcript.allFetched",{"name":"$","hash":{},"data":data}))
    + "</span>\n";
},"17":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<ul class=\"transcript-pager\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.prevPage : depth0),{"name":"if","hash":{},"fn":this.program(18, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.nextPage : depth0),{"name":"if","hash":{},"fn":this.program(20, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "		</ul>\n";
},"18":function(depth0,helpers,partials,data) {
    return "				<li class=\"previous pull-left\">\n					<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"transcript/{{prevPage}}"},"data":data}))
    + "\" class=\"btn\"><span class=\"fa fa-fw fa-arrow-left\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"previous",{"name":"$","hash":{},"data":data}))
    + "</a>\n				</li>\n";
},"20":function(depth0,helpers,partials,data) {
    return "				<li class=\"next pull-right\">\n					<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"transcript/{{nextPage}}"},"data":data}))
    + "\" class=\"btn\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"next",{"name":"$","hash":{},"data":data}))
    + "<span class=\"fa fa-fw fa-arrow-right\"></span></a>\n				</li>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"header-bar\">\n	<h3>\n		<span class=\"fa fa-clipboard skin-icon\"></span>\n		"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.transcript.title",{"name":"$","hash":{},"data":data}))
    + "\n	</h3>\n</div>\n<div class=\"content\">\n\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.loading : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.loading : depth0),{"name":"unless","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
Handlebars.registerPartial('catalogs_transcript_listing', t);
return t;
});

