define('views/manual-preview/main',['require','jquery','underscore','library/vlp/app','views/vlp-base','views/common/layout-tpls','views/manual-preview/manual','models/manual','models/entitlement','hbs!tpls/manual-preview/main.handlebars','i18n!nls/console','i18n!nls/manual-preview'],function (require) {
	"use strict";
	//library dependencies
	var $ = require("jquery"),
		_ = require("underscore");

	var App              = require("library/vlp/app"),
		VlpBaseView      = require("views/vlp-base"),
		LayoutTPLs       = require("views/common/layout-tpls"),
		ManualView       = require("views/manual-preview/manual"),
		Manual           = require("models/manual"),
		Entitlement      = require("models/entitlement"),
		mainTPL          = require("hbs!tpls/manual-preview/main.handlebars"),
		pageTextsConsole = require("i18n!nls/console"),
		pageTextsPreview = require("i18n!nls/manual-preview");

	return VlpBaseView.extend({
		name: "MANUAL_PREVIEW",
		viewport: "width=device-width, initial-scale=1",

		checkBrowserSupported: false,
		checkLoggedIn:         false,
		checkMaintenance:      false,
		checkEula:             false,
		checkTenantPath:       false,
		checkSystem:           false,

		layoutTemplate: LayoutTPLs.layoutEmpty,
		//Required
		mainTemplate: mainTPL,

		events: {},

		initialize: function (options) {
			_.bindAll(this);

			options = options || {};

			$("body").addClass("console-common");

			App.locale.mergeI18n(pageTextsConsole);
			App.locale.mergeI18n(pageTextsPreview);

			App.VlpGlobal.setVariableOverrides({
				email:     "email@address.com",
				username:  "username",
				firstName: "firstName",
				lastName:  "lastName",

				labSku:  "lab-sku",
				labName: "lab name",

				//Class
				classId:          "class-id",
				className:        "class name",
				classSku:         "class-sku",
				classDescription: "class description",

				entitlementId:   "entitlement-id",
				entitlementKey:  "entitlement-key",
				endAllowed:      true,
				exitAllowed:     true,
				resetAllowed:    false,
				requiresGrading: false,

				progress:                       "50.000",
				requiredMinimumTimeSpent:       0,
				requiredStepsVisitedPercentage: 0,
				completionStatusForLabCriteria: "completed",

				//Manual
				remoteControlMode: false,

				//Time
				timeAllotted:        60,
				timeElapsed:         0,
				timeRemaining:       60,
				expirationDate:      "2050-01-01T01:11:11Z",
				canExtend:           true,
				extensionsRemaining: 1,
				extensionCount:      1,

				//Event Lab Groups
				groupName:  "groupName",
				groupColor: "#FF0000",

				//Misc
				location:     "location",
				trackingCode: "tracking-code",
				tid:          "tid"
			});

			//Empty entitlement used by manual view
			this.entitlement = new Entitlement.Model({
				remoteControlMode : false
			});

			App.VlpGlobal.setActiveEntitlement(this.entitlement);

			this.setup(options);
		},

		showPreview : function(manualId){

			if(!this.isStartupComplete()){
				//Recall if not fully started up
				this.once(VlpBaseView.STARTUP_COMPLETE, function(){ this.showPreview(manualId);}, this);
				return;
			}


			App.config.showRemoteControl = false;
			App.config.fetchManualsFromContent = true;
			App.config.consoleRemoteManualSyncingEnabled = false;
			App.config.saveManualBookmark = false;

			this.model = new Manual.Model({
				id: manualId,
			});

			this.model.fetch({
				url : App.config.contentBase + "tocs/" + manualId,
				error: this.onManualFetchError,
				success: this.onManualFetched
			});
		},
		onManualFetched: function () {
			var manualFilePath = null;
			var manualFilePage = 0;
			
			if (this.model.get("importDataFormat") && this.model.get("importDataFormat") === "pdf" && this.model.get("importOptions") && this.model.get("importOptions").path) {
				manualFilePath = this.model.get("importOptions").path;
				manualFilePage = 1;
			}
			
			var title = this.model.get("name") + " - " + this.model.get("language") + " - " + this.model.get("type");

			this.model.set("title", title);
			
			App.VlpGlobal.setVariableOverrides({
				language : this.model.get("language")
			});

			if(!this.manualView){
				this.manualView = new ManualView({
					el                   : this.$("#instructions"),
					model                : this.model,
					manualId             : this.model.get("id"),
					entitlement          : this.entitlement,
					manualFilePath       : manualFilePath,
					manualFilePage       : manualFilePage
				});
				App.VlpGlobal.setManualView(this.manualView);
				this.manualView.render();
			}

			this.setTitle(
				title,
				"title"
			);
		},
		onManualFetchError : function(model, response){
			this.$("#instructions").hide();
			this.$("#fetch_error").show();
			console.warn("Error fetching manual", response);
		}
	});
});

