define('views/support/main',['require','jquery','underscore','backbone','library/vlp/app','views/vlp-base','models/account','views/support/entitlement','models/entitlement','views/common/public-profile','hbs!tpls/support/main.handlebars','i18n!nls/console','i18n!nls/instructor','i18n!nls/support'],function (require) {
	"use strict";

	var $ = require("jquery"),
	    _ = require("underscore"),
	    Backbone = require("backbone");

	var App                 = require("library/vlp/app"),
	    VlpBaseView         = require("views/vlp-base"),
	    Account             = require("models/account"),
	    EntitlementView     = require("views/support/entitlement"),
	    Entitlement         = require("models/entitlement"),
	    PublicProfileView   = require("views/common/public-profile"),
	    tpl                 = require("hbs!tpls/support/main.handlebars"),
	    pageTextsConsole    = require("i18n!nls/console"),
	    pageTextsInstructor = require("i18n!nls/instructor"),
	    pageTextsSupport    = require("i18n!nls/support");

	return VlpBaseView.extend({
		className: "general-page",
		entitlementId: null,
		mainTemplate: tpl,
		events: {
			"click .public-profile"   : "showPublicProfile"
		},
		initialize: function (options) {
			_.bindAll(this);
			$("body").addClass("console-common");
			this.viewport = "width=device-width,initial-scale=1";

			App.locale.mergeI18n(pageTextsConsole);
			App.locale.mergeI18n(pageTextsInstructor);
			App.locale.mergeI18n(pageTextsSupport);

			this.setup(options);
		},

		onEntitlementError: function (model, response) {
			if (this._exiting) {
				return;
			}
			if (!(model instanceof Backbone.Model)) {
				response = model;
			}
			this._exiting = true;
			var message = App.i18n("errors.getLab");

			if (response && response.data && response.data.message) {
				message = _.escapeHTML(response.data.message);
			} else if (!response || !response.response) {
				message = App.i18n("errors.getLab");
			} else if (response.response.errorCode === 1310) {
				message = App.i18n("errors.labExpired");
			} else if (response.response.message && response.response.message.length > 0) {
				message = _.escapeHTML(response.response.message);
			}

			alert(message);
		},

		showEntitlement: function (entitlementId, consoleId) {
			if (this.isLoggedIn()) {
				if (!entitlementId) {
					entitlementId = App.entitlementId;
				}

				if (entitlementId === null || entitlementId === undefined || entitlementId === 0 || entitlementId === "") {
					this.$(".invalid-request").show();
					this.$("#entitlement").hide();
				} else {
					this.$(".invalid-request").hide();
					this.$("#entitlement").show();

					this.entitlementId = entitlementId;
					App.entitlementId = entitlementId;
					this.consoleId = consoleId;

					if (!this.model && entitlementId) {
						this.model = new Entitlement.Model({
							id: this.entitlementId,
							activeConsoleId : this.consoleId
						});
						App.VlpGlobal.setActiveEntitlement(this.model);
					}

					if (this.model) {
						this.model.fetch({
							params  : {
								"with" : ["account", "lab", "class"]
							},
							success: this.renderEntitlement,
							error: this.onEntitlementError
						});
					}

					this.render();
				}
			} else {
				var self = this;
				this.once(VlpBaseView.LOGGED_IN, function () {
					self.showEntitlement(entitlementId, consoleId);
				});
			}
		},

		renderEntitlement: function () {
			this.model.set("activeConsoleId", this.consoleId);
			if (!this.entitlementView) {
				this.entitlementView = new EntitlementView({
					model: this.model,
					el: this.$("#entitlement")
				});
				App.VlpGlobal.setActiveEntitlementView(this.entitlementView);
				this.entitlementView.render();
			}
		},

		showPublicProfile: function (event) {
			var $item = $(event.currentTarget);

			$(".modal").removeClass("fade").modal("hide");

			var account = Account.Model.findOrCreate({
				id: $item.data("id"),
				username: $item.data("username")
			});

			if (this.publicProfileView) {
				this.publicProfileView.remove();
				this.publicProfileView = null;
			}

			this.publicProfileView = new PublicProfileView({
				model: account
			});
			this.publicProfileView.show(1);
		}
	});

});

