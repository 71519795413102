
define('hbs!tpls/instructor/entitlement-listing.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "	<div class=\"loading well\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loading",{"name":"$","hash":{},"data":data}))
    + "</div>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.length : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(15, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<div class=\"items-holder clearfix\"></div>\n\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"instructorInfiniteScrolling",{"name":"config","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(10, data, 0),"data":data})) != null ? stack1 : "");
},"5":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<div class=\"fetch-more\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.nextPage : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0),"inverse":this.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "			</div>\n";
},"6":function(depth0,helpers,partials,data) {
    var helper;

  return "					<button type=\"button\" value=\""
    + this.escapeExpression(((helper = (helper = helpers.nextPage || (depth0 != null ? depth0.nextPage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"nextPage","hash":{},"data":data}) : helper)))
    + "\" class=\"fetch-more-trigger btn\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"fetchMore",{"name":"$","hash":{},"data":data}))
    + "<span class=\"fa fa-fw fa-ellipsis-v\"/></button>\n";
},"8":function(depth0,helpers,partials,data) {
    return "					<span class=\"all-fetched\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.entitlements.allFetched",{"name":"$","hash":{},"data":data}))
    + "</span>\n";
},"10":function(depth0,helpers,partials,data) {
    var stack1;

  return "			<ul class=\"lab-pager\">\n"
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.prevPage : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.nextPage : depth0),{"name":"if","hash":{},"fn":this.program(13, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "			</ul>\n";
},"11":function(depth0,helpers,partials,data) {
    return "					<li class=\"previous pull-left\">\n						<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"{{prevPage}}"},"data":data}))
    + "\" class=\"btn\"><span class=\"fa fa-fw fa-arrow-left\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"previous",{"name":"$","hash":{},"data":data}))
    + "</a>\n					</li>\n";
},"13":function(depth0,helpers,partials,data) {
    return "					<li class=\"next pull-right\">\n						<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":"{{nextPage}}"},"data":data}))
    + "\" class=\"btn\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"next",{"name":"$","hash":{},"data":data}))
    + "<span class=\"fa fa-fw fa-arrow-right\"></span></a>\n					</li>\n";
},"15":function(depth0,helpers,partials,data) {
    return "		<!-- no entitlements -->\n		<div class=\"lab-item no-labs\">\n			<div class=\"alert\">\n				"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"instructor.entitlements.empty",{"name":"$","hash":{},"data":data}))
    + "\n			</div>\n		</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.loading : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n<div id=\"modal_alert_msg\" class=\"modal fluid-height fade global hide\"></div>\n";
},"useData":true});
Handlebars.registerPartial('instructor_entitlement_listing', t);
return t;
});

