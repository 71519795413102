define('views/catalogs/lab-listing',['require','jquery','underscore','library/vlp/app','library/vlp/view','views/catalogs/lab','hbs!tpls/catalogs/lab-listing.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $ = require("jquery"),
	    _ = require("underscore");

	//class dependencies
	var App           = require("library/vlp/app"),
	    BaseView      = require("library/vlp/view"),
	    LabInfoView   = require("views/catalogs/lab"),
	    labListingTPL = require("hbs!tpls/catalogs/lab-listing.handlebars");


	return BaseView.extend({

		template : labListingTPL,
		searchValue : "",
		children : {},

		pageNum    : 1,
		scrollTop  : 0,

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .fetch-more-trigger"   : "onLoadMore"
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){

			_.bindAll(this);
			options = options || {};
			options.fetchDefaults = options.fetchDefaults || {};


			this.catalog      = options.catalog;

			this.fetchDefaults = _.extend({
				params  : {
					"with" : [
						"authors", "badges", "catalogs", "entitlement", "locales", "networkTopology", "products",
						"ratings", "relatedLabs", "tags", "thumbnail", "vms"
					]
				},
				perPage : App.config.itemsPerPage
			}, options.fetchDefaults);

			if(!this.catalog.get("allCatalog")){
				this.fetchDefaults.params.catalogId = this.catalog.get("id");
			}
			this.listenTo(this.collection, "request", this.onFetching);
			this.listenTo(this.collection, "sync",    this.onFetched);
			this.listenTo(this.collection, "add",     this.onItemAdded);
			this.listenTo(this.collection, "reset",   this.onReset);
			this.listenTo(this.collection, "error",   this.render);

			if(App.config.catalogInfiniteScrolling){
				$(window).on("scroll", this.onScroll);
			}
		},
		serialize : function(){
			var data = this.collection.toHandlebars();
			data.catalogId = this.catalog.get("allCatalog") ? "all" : this.catalog.get("id");
			return data;
		},
		beforeRender : function(){
			this.$("[rel=tooltip]").tooltip("hide");
			_.each(this.children, function(view){
				view.$el.detach();
			});
		},
		afterRender : function(){

			var self = this;
			this.$itemHolder = this.$(".items-holder");
			if(this.$itemHolder.length && this.collection.length){
				this.collection.each(function(model){
					var itemView = self.children[model.get("id")];

					if(!itemView){
						itemView = new LabInfoView({
							model        : model,
							catalogId    : self.catalog.get("id")
						});
						self.children[model.get("id")] = itemView;
					}

					self.$itemHolder.append(itemView.el);
					itemView.setElement(itemView.el);
					//This is hacky, but otherwise dom events seem to get detached.
					if(!itemView.rendered){
						itemView.render();
					} else{
						itemView.afterRender();
					}
				});
			}
			$("html, body").scrollTop(this.scrollTop);
			this.$("[rel=tooltip]").tooltip();
		},

		onReset : function(){
			this.scrollTop = 0;
			this.removeChildren();
			this.$(".items-holder").empty();
		},
		onFetching : function(){
			if(App.config.catalogInfiniteScrolling){
				if(!this.rendered){
					this.render();
				}
				this.$(".fetch-more-trigger").button("loading");
			} else{
				this.render();
			}
			App.analytics.trackEvent("Catalog", "View Labs", this.catalog.get("allCatalog") ? "all" : this.catalog.get("name"), this.collection.page || 1);
		},
		onFetched : function(){
			this.render();

			this.onScroll();
			this.$(".fetch-more-trigger").button("reset");
		},
		onItemAdded : function(item){

			var itemView = this.children[item.get("id")];
			if(!itemView){
				itemView = new LabInfoView({
					model        : item,
					catalogId    : this.catalog.get("id")
				});
			}
			this.children[item.get("id")] = itemView;

			this.$(".items-holder").append(itemView.render().el);
		},
		onLoadMore : function(event){
			if(this.collection.length <= this.collection.totalCount && !this.collection.loading){
				this.fetch({add : true, remove : false});
				this.$(".fetch-more-trigger").button("loading");
			}
		},
		onScroll : function(event){
			this.scrollTop = $(window).scrollTop();
			var $trigger = this.$(".fetch-more-trigger");
			if($trigger.length === 0) { return; }

			if(this.scrollTop > ($trigger.offset().top + $trigger.outerHeight())- $(window).height()) {
				this.onLoadMore();
			}
		},

		show : function(pageNum){
			if(pageNum !== undefined){
				this.pageNum = pageNum;
			}
			if(App.config.catalogInfiniteScrolling){
				this.pageNum = 1;
			}

			if(!this.collection.loading){
				if(this.pageNum != this.collection.page){
					this.collection.reset();
					this.fetchPage(this.pageNum);
				} else{
					this.render();
					App.analytics.trackEvent("Catalog", "View Labs", this.catalog.get("allCatalog") ? "all" : this.catalog.get("name"), this.collection.page || 1);
				}
			} else{
				this.render();
			}

			this.scrollTop = 0;
			$("html, body").scrollTop(this.scrollTop);
		},

		search : function(searchValue){
			if(searchValue != this.searchValue){
				this.searchValue = searchValue;
				this.fetchDefaults.params = this.fetchDefaults.params || {};
				this.fetchDefaults.params.search = this.searchValue;
				if(this.searchValue == "" && !this.catalog.get("allCatalog")){
					this.fetchDefaults.params.catalogId = this.catalog.get("id");
				} else{
					//causes all labs to be searched, not just the current catalog.
					delete this.fetchDefaults.params.catalogId;
				}
				this.collection.reset();
				this.pageNum = 1;
				this.fetchPage(this.pageNum);
				App.analytics.trackEvent("Catalog", "Search", searchValue);
			}

		},
		remove : function(){
			$(window).off("scroll", this.onScroll);
			this.removeChildren();
			BaseView.prototype.remove.call(this);
		},
		removeChildren : function(){
			_.each(this.children, function(view){
				view.remove();
			});
			this.children = {};
		}
	});

});
