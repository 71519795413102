define('models/transcript',['require','exports','module','jquery','underscore','library/vlp/app','library/vlp/collection','models/entitlement'],function (require, exports) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore");

	//class dependencies
	var App            = require("library/vlp/app"),
	    BaseCollection = require("library/vlp/collection"),
	    Entitlement    = require("models/entitlement");


	//Use exports for requirejs circular dependency resolving
	var Transcript = exports;

	//Add Transcript to the App.Models namespace
	App.Models.Transcript = Transcript;


	Transcript.Model = Entitlement.Model.extend({
		urlRoot : "/transcripts",

		email : function(options){
			options = options ? _.clone(options) : {};

			options.type = "POST";
			options.url = this.serverBase + "stats/emailTranscript";

			options.params = options.params || {};
			options.params.transcriptId = this.get("id");
			options.params.tenant       = App.getTenant();
			options.url = this._makeURL(options);

			return $.ajax(options);
		},

		emailCertificate : function(options){
			options = options ? _.clone(options) : {};
			if(!options.url){
				options.url = this.url() + "email";
			}

			options.type = "POST";
			options.url = this.serverBase + "stats/emailCertificate";

			options.params = options.params || {};
			options.params.transcriptId = this.get("id");
			options.params.tenant       = App.getTenant();
			options.url = this._makeURL(options);

			return $.ajax(options);
		}
	});


	Transcript.Collection = BaseCollection.extend({
		model : Transcript.Model,
		parse : function(){
			var parentResults = BaseCollection.prototype.parse.apply(this, arguments);

			var results = {};
			_.each(parentResults, function(item){

				if(item.itemId && !item.id){
					item.id = item.itemId;
					delete item.itemId;
				}

				if(item.entitlement_id && !item.id){
					item.id = item.entitlement_id;
					delete item.entitlement_id;
				}

				if(item.course && !item.lab){
					item.type = "course";
				} else{
					item.type = "lab";
				}
				results[item.id] = item;
			});

			_.each(results, function(item, index){
				if(index.match(/:\d+$/)){
					var parentId = index.split(":")[0];
					if(results[parentId]){
						results[parentId].labs = results[parentId].labs || [];
						results[parentId].labs.push(item);
						delete results[index];
					}
				}
			});
			return _.toArray(results);
		},
		email : function(options){
			options = options ? _.clone(options) : {};
			if(!options.url){
				options.url = this.url() + "email";
			}

			options.type = "POST";
			options.url = this.serverBase + "stats/emailTranscript";

			options.params = options.params || {};
			options.params.tenant = App.getTenant();
			options.url = this._makeURL(options);

			return $.ajax(options);
		}
	});

});

