define('nls/root/maintenance',{
	"maintenance" : {
		"available"                : "Available",
		"availableMessage"         : "The system is now available.",
		"goBack"                   : "Click here to go back to your last page.",
		"tryAgain"                 : "Please try again later",
		"generalMessage"           : "Maintenance Mode",
		"statusLabel"              : "HTTP %s: ",
		"errorCodeLabel"           : "(Error Code: %s)",
		"unavailableMessage"       : "The site is currently in maintenance mode.",
		"tenantUnavailableMessage" : "%s is down for maintenance.",
		"systemStatusLinkMessage"  : "Check the VMware Lab Platform system status page.",
		"supportOptions"           : "Support Options"
	},
	"errors"      : {
	},
	"title"       : "System Maintenance"
});

