define('nls/root/landing',{
	"landing"   : {
		"vmware"           : "VMware",
		"labPlatform"      : "Lab Platform",
		"title"            : "VMware Lab Platform",
		"contactUs"        : "Contact Us",
		"loginPrompt"      : "Existing Users: Login by going to your tenant URL.",
		"description"      : "VMware Lab Platform is a new commercial service offering, providing a richly featured, powerful online learning and lab environment delivered from the cloud to any device, anywhere, anytime.",
		"learnMore"        : "Learn More",
		"info1" : {
			"title" : "Rich learning environment",
			"point1" : "Integrates lesson materials, labs, video*, live chat*, social, in a unified web-based interface."
		},
		"info2" : {
			"title" : "Universal access",
			"point1" : "Accessible from anywhere, anytime, on (almost) any device with a modern browser.",
			"point2" : "Got HTML5? That's all you need. No installers. No plugins. No headaches!"
		},
		"info3" : {
			"title" : "Powerful virtual lab environment",
			"point1" : "Supports complex multi-VM configurations.",
			"point2" : "Supports almost all guest operating systems.",
			"point3" : "Provides true VM \"console\" for advanced uses."
		},
		"footer": {
			"copyright": "&copy; %s Broadcom",
			"termsOfUse": "Terms of Use",
			"privacy": "Privacy",
			"cookieSettings": "Cookie Settings"
		}
	},
	"errors" : {
	}
});

