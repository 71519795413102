define('library/utilities/file-info',['require','underscore'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");


	return function(path){
		var info = {
			path : path,
			extension : _.parseURL(path).extension.toLowerCase(),
			type : "other",
			icon : "fa-file-o"
		};
		switch(info.extension){
			case "pdf":
				info.type = "pdf";
				info.icon = "fa-file-pdf-o";
				break;
			case "doc": case "docx":
				info.type = "word";
				info.icon = "fa-file-word-o";
				break;
			case "ppt": case "pptx":
				info.type = "powerpoint";
				info.icon = "fa-file-powerpoint-o";
				break;
			case "jpeg": case "jpg": case "png": case "gif": case "svg":
				info.type = "image";
				info.icon = "fa-file-image-o";
				break;
			case "zip": case "tar": case "gz": case "tgz": case "tar.bz2": case ".tar.gz":
				info.type = "archive";
				info.icon = "fa-file-archive-o";
				break;
			case "txt": case "md": case "me":
				info.type = "text";
				info.icon = "fa-file-text-o";
				break;
			case "avi": case "mp4": case "mkv": case "qt": case "wmv":
				info.type = "video";
				info.icon = "fa-file-video-o";
				break;
			default:
				info.icon = "fa-file-o";
				info.type = "other";
		}
		return info;
	};

});

