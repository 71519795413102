define('views/instructor/instructor-listing',['require','underscore','library/vlp/view','hbs!tpls/instructor/instructor-listing.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var _ = require("underscore");

	//class dependencies
	var BaseView       = require("library/vlp/view"),
	    tpl            = require("hbs!tpls/instructor/instructor-listing.handlebars");

	return BaseView.extend({
		template: tpl,

		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events: {
		},
		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize: function (options) {
			_.bindAll(this);
			this.listenTo(this.collection, "change", this.render);
		},
		serialize: function () {
			var data = this.collection.toHandlebars();
			return data;
		},
		afterRender: function () {
			this.$("[rel=tooltip]").tooltip();
		}
	});

});
