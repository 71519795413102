
define('hbs!tpls/event/register.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "autocomplete=\"on\"";
},"3":function(depth0,helpers,partials,data) {
    return "autocomplete=\"off\" action=\"https://about:blank\"";
},"5":function(depth0,helpers,partials,data) {
    return "\"email\"";
},"7":function(depth0,helpers,partials,data) {
    return "\"off\"";
},"9":function(depth0,helpers,partials,data) {
    return "\"current-password\"";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"page-content\">\n\n	<form id=\"register_form\" data-error-style=\"tooltip\" "
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n\n		<h1 class=\"title\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"event.register.title",{"name":"$","hash":{},"data":data}))
    + "</h1>\n\n		<fieldset class=\"form-horizontal\">\n			<div class=\"control-group\">\n				<label class=\"control-label required\" for=\"register_username\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.username",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n				<div class=\"controls\">\n					<input type=\"text\" class=\"input-xlarge\" placeholder=\"user@company.com\" id=\"register_username\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n				</div>\n			</div>\n			<div class=\"control-group\">\n				<label class=\"control-label required\" for=\"register_password\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.password",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n				<div class=\"controls\">\n					<input type=\"password\" class=\"input-xlarge\" id=\"register_password\" autocomplete="
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"enableAutocomplete",{"name":"config","hash":{},"fn":this.program(9, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n				</div>\n			</div>\n			<div class=\"control-group\">\n				<label class=\"control-label required\" for=\"register_stationId\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"event.stationId",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n				<div class=\"controls\">\n					<input type=\"text\" class=\"input-xlarge\" id=\"register_stationId\">\n				</div>\n			</div>\n			<div class=\"control-group force-register hide\">\n				<label class=\"checkbox\">\n					<input type=\"checkbox\" class=\"input-small\" id=\"register_force\"> "
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"event.register.forceRegister",{"name":"$","hash":{},"data":data}))
    + "\n				</label>\n			</div>\n\n			<div class=\"alert alert-error save-error-general hide\"></div>\n\n			<button id=\"register\" class=\"btn btn-large btn-primary\" type=\"submit\" "
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"data-loading-text","event.register.registering",{"name":"$$","hash":{},"data":data}))
    + ">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"event.register.register",{"name":"$","hash":{},"data":data}))
    + "</button>\n\n			<div class=\"spinner register-spinner\"></div>\n		</fieldset>\n\n		<div><a href=\"https://www.vmware.com/help/privacy.html\">Privacy <i class=\"fa fa-external-link\"></i></a></div>\n		<div>"
    + ((stack1 = (helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"loginPrompt.copyright",(depth0 != null ? depth0.copyrightYear : depth0),{"name":"$","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n	</form>\n\n</div>\n";
},"useData":true});
Handlebars.registerPartial('event_register', t);
return t;
});

