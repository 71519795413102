define('library/underscore/extend-recursive',['require','underscore'],function (require) {
	"use strict";
	var _  = require("underscore");

	_.mixin({
		extendRecursive: function(target, source){

			for(var prop in source){
				if(source.hasOwnProperty(prop)){
					if(!(prop in target)){
						target[prop] = source[prop];
					} else if(source[prop] !== null && source[prop] !== undefined && typeof(source[prop]) === "object"){
						//recursively mixin for "groups"
						this.extendRecursive(target[prop], source[prop]);
					} else{
						target[prop] = source[prop];
					}
				}
			}
		}
	});

	return _;
});
