/**
 * Screen-saver Router
 *
 * Code starts running here for the /screen-saver page.
 */
define('routers/screen-saver',['require','library/vlp/router','views/screen-saver/main'],function (require) {
	"use strict";

	//class dependencies
	var BaseRouter = require("library/vlp/router"),
	    MainView   = require("views/screen-saver/main");


	return BaseRouter.extend({
		routeBase : "screen-saver",
		/**
		 * Constructor
		 *
		 * This code is called when new Router([options]) is called in the example.html.
		 *
		 * @param options Map of options (mostly passed on to new MainView([options]);
		 */
		initialize : function(config){

			this.setup(config);

			this.mainView = new MainView(config);

			this.start();
		},
		/**
		 * List of possible routes
		 */
		routes : {
			":page"    : "index",
			"*actions" : "index"
		},
		/**
		 * Default (initial) route. Loads first step.
		 */
		index : function(){

		}
	});
});

