
define('hbs!tpls/catalogs/ratings-popover.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var helper;

  return "<div class=\"rating-popover-content\">\n	<div class=\"rating-count\">\n		<div class=\"star-info\">\n			<label>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.ratings.fiveStar",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"progress\">\n				<div class=\"bar\" style=\"width: "
    + this.escapeExpression(((helper = (helper = helpers.fiveStarPercent || (depth0 != null ? depth0.fiveStarPercent : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"fiveStarPercent","hash":{},"data":data}) : helper)))
    + "%;\" title=\""
    + this.escapeExpression((helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(helpers.toNumber || (depth0 && depth0.toNumber) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.fiveStarPercent : depth0),{"name":"toNumber","hash":{},"data":data}),{"name":"percent","hash":{},"data":data}))
    + "\"></div>\n			</div>\n			<span class=\"count\">("
    + this.escapeExpression(((helper = (helper = helpers.fiveStar || (depth0 != null ? depth0.fiveStar : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"fiveStar","hash":{},"data":data}) : helper)))
    + ")</span>\n		</div>\n		<div class=\"star-info\">\n			<label>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.ratings.fourStar",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"progress\">\n				<div class=\"bar\" style=\"width: "
    + this.escapeExpression(((helper = (helper = helpers.fourStarPercent || (depth0 != null ? depth0.fourStarPercent : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"fourStarPercent","hash":{},"data":data}) : helper)))
    + "%;\" title=\""
    + this.escapeExpression((helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(helpers.toNumber || (depth0 && depth0.toNumber) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.fourStarPercent : depth0),{"name":"toNumber","hash":{},"data":data}),{"name":"percent","hash":{},"data":data}))
    + "\"></div>\n			</div>\n			<span class=\"count\">("
    + this.escapeExpression(((helper = (helper = helpers.fourStar || (depth0 != null ? depth0.fourStar : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"fourStar","hash":{},"data":data}) : helper)))
    + ")</span>\n		</div>\n		<div class=\"star-info\">\n			<label>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.ratings.threeStar",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"progress\">\n				<div class=\"bar\" style=\"width: "
    + this.escapeExpression(((helper = (helper = helpers.threeStarPercent || (depth0 != null ? depth0.threeStarPercent : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"threeStarPercent","hash":{},"data":data}) : helper)))
    + "%;\" title=\""
    + this.escapeExpression((helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(helpers.toNumber || (depth0 && depth0.toNumber) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.threeStarPercent : depth0),{"name":"toNumber","hash":{},"data":data}),{"name":"percent","hash":{},"data":data}))
    + "\"></div>\n			</div>\n			<span class=\"count\">("
    + this.escapeExpression(((helper = (helper = helpers.threeStar || (depth0 != null ? depth0.threeStar : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"threeStar","hash":{},"data":data}) : helper)))
    + ")</span>\n		</div>\n		<div class=\"star-info\">\n			<label>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.ratings.twoStar",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"progress\">\n				<div class=\"bar\" style=\"width: "
    + this.escapeExpression(((helper = (helper = helpers.twoStarPercent || (depth0 != null ? depth0.twoStarPercent : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"twoStarPercent","hash":{},"data":data}) : helper)))
    + "%;\" title=\""
    + this.escapeExpression((helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(helpers.toNumber || (depth0 && depth0.toNumber) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.twoStarPercent : depth0),{"name":"toNumber","hash":{},"data":data}),{"name":"percent","hash":{},"data":data}))
    + "\"></div>\n			</div>\n			<span class=\"count\">("
    + this.escapeExpression(((helper = (helper = helpers.twoStar || (depth0 != null ? depth0.twoStar : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"twoStar","hash":{},"data":data}) : helper)))
    + ")</span>\n		</div>\n		<div class=\"star-info\">\n			<label>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.ratings.oneStar",{"name":"$","hash":{},"data":data}))
    + "</label>\n\n			<div class=\"progress\">\n				<div class=\"bar\" style=\"width: "
    + this.escapeExpression(((helper = (helper = helpers.oneStarPercent || (depth0 != null ? depth0.oneStarPercent : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"oneStarPercent","hash":{},"data":data}) : helper)))
    + "%;\" title=\""
    + this.escapeExpression((helpers.percent || (depth0 && depth0.percent) || helpers.helperMissing).call(depth0,(helpers.toNumber || (depth0 && depth0.toNumber) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.oneStarPercent : depth0),{"name":"toNumber","hash":{},"data":data}),{"name":"percent","hash":{},"data":data}))
    + "\"></div>\n			</div>\n			<span class=\"count\">("
    + this.escapeExpression(((helper = (helper = helpers.oneStar || (depth0 != null ? depth0.oneStar : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"oneStar","hash":{},"data":data}) : helper)))
    + ")</span>\n		</div>\n	</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('catalogs_ratings_popover', t);
return t;
});

