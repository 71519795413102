define('models/class',['require','exports','module','backbone','underscore','library/utilities/file-info','library/vlp/app','library/vlp/model','library/vlp/collection','models/class-entitlement','models/entitlement','models/instructor'],function (require, exports) {
	"use strict";

	//library dependencies
	var Backbone = require("backbone"),
	    _        = require("underscore"),
	    fileInfo = require("library/utilities/file-info");

	//class dependencies
	var App                        = require("library/vlp/app"),
	    BaseModel                  = require("library/vlp/model"),
	    BaseCollection             = require("library/vlp/collection"),
	    ClassEntitlement           = require("models/class-entitlement"),        //jshint ignore:line
	    Entitlement                = require("models/entitlement"),              //jshint ignore:line
	    Instructor                 = require("models/instructor");               //jshint ignore:line


	//Use exports for requirejs circular dependency resolving
	var Class = exports;

	//Add Tip to the App.Models namespace
	App.Models.Class = Class;

	Class.Model = BaseModel.extend({
		urlRoot       : "/classes",

		syncPropertyTriggers : [
			"screenStatus", "broadcastingDisplay", "broadcastingEntitlement", "activeScreenTicket"
		],

		/**
		 * Default values for empty Models (new Class.Model())
		 */
		defaults : {
			screenStatus       : "normal",
			broadcastingDisplay: false,
			warningType        : "step",
			warningValue       : 0,
			warned             : false,
			searchVal          : ""
		},

		relations : [
			{
				key             : "entitlements",
				type            : Backbone.HasMany,
				relatedModel    : "ClassEntitlement.Model",
				collectionType  : "ClassEntitlement.Collection",
				parse           : true
			},
			{
				key             : "entitlement",
				type            : Backbone.HasOne,
				relatedModel    : "Entitlement.Model",
				collectionType  : "Entitlement.Collection"
			},
			{
				key             : "lab",
				type            : Backbone.HasOne,
				relatedModel    : "Lab.Model"
			},
			{
				key             : "labs",
				type            : Backbone.HasMany,
				relatedModel    : "Lab.Model",
				collectionType  : "Lab.Collection"
			},
			{
				key             : "instructors",
				type            : Backbone.HasMany,
				relatedModel    : "Instructor.Model",
				collectionType  : "Instructor.Collection"
			}
		],
		setters : {
			resources : function(value){
				if(!value){
					return new BaseCollection();
				}

				var tempValue =_.clone(value);
				for (var i = 0; i < tempValue.length; i++) {
					var resource = tempValue[i];
					var resourceInfo = fileInfo(resource.path);
					resource.type = resourceInfo.type;
					resource.icon = resourceInfo.icon;

					if(resource.path){
						resource.path = resource.path + (resource.path.indexOf("?") !== -1 ? "&" : "?") + "classId=" + this.get("id");
					}
				}
				return new BaseCollection(tempValue);
			},
			activeLabSku : function(value){
				var activeLab = this.get("labs") && this.get("labs").findWhere({sku: value});
				if(activeLab){
					this.set("manualId", activeLab.get("manualId"));
					this.set("networkTopology", activeLab.get("networkTopology"));
					this.set("resources", activeLab.get("resources"));
				}

				return value;
			}
		},
		getters: {
			hasManual: function () {
				return this.get("manualId") || !!this.get("pdfManualResource");
			},
			pdfManualResource: function () {
				if (!this.get("resources") || this.get("resources").length === 0 || this.get("manualId")) {
					return null;
				}

				for (var i = 0; i < this.get("resources").length; i++) {
					var resource = this.get("resources").at(i);
					if (resource.get("type") === "pdf") {
						return resource;
					}
				}
				return null;
			}
		},
		/**
		 * Constructor
		 */
		initialize : function(){
			_.bindAll(this, "pushUpdate", "entitlementSubscribe", "entitlementUnsubscribe");
			this._throttledPushUpdate = _.throttle(this.pushUpdate, 1000);
		},
		parse : function(){
			var result = BaseModel.prototype.parse.apply(this, arguments);
			result.labs = result.lab;
			result.lab = result.labs && result.labs[0];
			return result;
		},

		pushSubscribe : function(){
			if (!this.get("id")) { return; }
			var data = {
				service      : "instructorClass",
				channel      : "/instructorClass/" + this.get("id"),
				requestParams : {
					id : this.get("id")
				}
			};

			App.push.subscribe(data);

			this.get("entitlements").each(this.entitlementSubscribe);

			this.get("entitlements").on("add", this.entitlementSubscribe);
			this.get("entitlements").on("remove", this.entitlementUnsubscribe);

			App.push.on("other:instructorClass:" + this.get("id") + ":studentSync", this.studentSync, this);
			App.push.on("other:received:instructorClass:" + this.get("id") + ":update", this.classUpdated, this);
			App.push.on("other:classEntitlement:*:studentUpdate", this.entitlementUpdated, this);

			var triggers = "change:" + this.syncPropertyTriggers.join(" change:");
			this.on(triggers, this._throttledPushUpdate, this);
		},

		entitlementSubscribe : function(entitlement){
			var data = {
				service      : "classEntitlement",
				channel      : "/classEntitlement/" + entitlement.get("id"),
				requestParams: {
					id: entitlement.get("id")
				}
			};

			App.push.subscribe(data);
		},
		entitlementUnsubscribe : function(entitlement){
			var data = {
				service      : "classEntitlement",
				channel      : "/classEntitlement/" + entitlement.get("id"),
				requestParams: {
					id: entitlement.get("id")
				}
			};

			App.push.unsubscribe(data);

		},
		studentSync : function(message){
			this.pushUpdate();

			if (message && message.data && message.data.id) {
				var entitlement = this.get("entitlements").get(message.data.id);
				if (entitlement) {
					entitlement.classPushUpdate();
				}
			}
		},
		pushUpdate : function(){
			if(!App.push) { return; }

			if (!this.get("id")) { return; }

			var message = {
				service      : "instructorClass",
				operation    : "instructorUpdate",
				channel      : "/instructorClass/" + this.get("id"),
				data         : this.pushData(),
				requestParams: {
					id: this.get("id")
				}
			};

			App.push.send(message);
		},
		classUpdated : function(message){
			if (message && message.data) {
				this.set(message.data);
			}
		},
		pushData : function(){
			return {
				screenStatus           : this.get("screenStatus"),
				broadcastingDisplay    : this.get("broadcastingDisplay"),
				broadcastingEntitlement: (this.get("broadcastingDisplay") ? this.get("broadcastingEntitlement") : null),
				activeScreenTicket     : (this.get("broadcastingDisplay") ? this.get("activeScreenTicket") : null),
				activeVmx              : (this.get("broadcastingDisplay") ? this.get("activeVmx") : null)
			};
		},
		entitlementUpdated : function(message){
			if (message && message.data && message.data.id) {
				var entitlement = this.get("entitlements").get(message.data.id);
				if (entitlement) {
					entitlement.set(message.data);
				}
			}
		},
		toggleBlankAllScreens : function(){
			if(this.get("screenStatus") == "blanked"){
				this.set("screenStatus", "normal");
			} else{
				this.set("screenStatus", "blanked");
			}
		},

		verify : function(options){
			options = options || {};
			options.method = "read";
			options.url = this.serverBase + "classes/" + this.get("studentToken") + "/verify";
			return this.action("verify", options);
		},

		enroll : function(options){
			options = options || {};
			options.url = this.serverBase + "classes/" + this.get("studentToken") + "/enroll";
			return this.action("enroll", options);
		}
	});

	Class.Collection = BaseCollection.extend({
		model : Class.Model
	});

});

