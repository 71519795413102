/**
 * Lab Backbone View
 */
define('views/console/entitlement',['require','jquery','underscore','backbone','library/utilities/days-hours-minutes-label','library/vlp/app','library/vlp/view','views/console/vm-console','views/console/saas-console','views/console/broadcast-vm','views/console/console-listing','views/console/entitlement-settings','views/console/manual','views/console/video-conference','views/console/resources','views/console/expired','views/console/complete','views/console/walkthrough','views/console/idle-timed-out','views/console/panel-manager','models/entitlement','hbs!tpls/console/entitlement.handlebars','hbs!tpls/console/remote-prompt.handlebars','models/instructor-help-request','utilities/browser','hbs!tpls/console/end-button.handlebars'],function (require) {
	"use strict";

	//library dependencies
	var $        = require("jquery"),
	    _        = require("underscore"),
	    Backbone = require("backbone");

	var daysHoursMinutesLabel = require("library/utilities/days-hours-minutes-label");

	//class dependencies
	var App                     = require("library/vlp/app"),
	    BaseView                = require("library/vlp/view"),
	    VmConsoleView           = require("views/console/vm-console"),
	    SaasConsoleView         = require("views/console/saas-console"),
	    BroadcastVmView         = require("views/console/broadcast-vm"),
	    ConsoleListingView      = require("views/console/console-listing"),
	    EntitlementSettingsView = require("views/console/entitlement-settings"),
	    ManualView              = require("views/console/manual"),
	    VideoConferenceView     = require("views/console/video-conference"),
	    ResourcesView           = require("views/console/resources"),
	    ExpiredView             = require("views/console/expired"),
	    CompleteView            = require("views/console/complete"),
	    WalkthroughView         = require("views/console/walkthrough"),
	    IdleTimedOutView        = require("views/console/idle-timed-out"),
	    PanelManager            = require("views/console/panel-manager"),
	    Entitlement             = require("models/entitlement"),
	    entitlementTPL          = require("hbs!tpls/console/entitlement.handlebars"),
	    remotePromptTPL         = require("hbs!tpls/console/remote-prompt.handlebars"),
	    InstructorHelpRequest   = require("models/instructor-help-request"),
	    Browser                 = require("utilities/browser");

	require("hbs!tpls/console/end-button.handlebars");

	return BaseView.extend({
		template : entitlementTPL,

		checkCompleteCriteriaCallFrequency : 30000,
		checkServerCompleteCriteriaFrequency : 5000,
		minimumTimeCompleted : false,
		percentagePagesVisitedCompleted : false,
		/**
		 * Events are bound to objects contained in/children of this.el
		 * Events will still work on objects added to the DOM later after the initialization as well.
		 */
		events : {
			"click .mc-split-screen"            : "showRemotePrompt",
			"click .extend-time"                : "extendTime",
			"click button.return-to-home"       : "closeWindow",

			"click .send-text"                  : "onSendText",
			"click #subscribe-for-notification" : "subscribeForNotification",

			"click .toggle-panel-left"          : "togglePanelLeft",
			"click .toggle-panel-right"         : "togglePanelRight",

			"click #launch_walkthrough"         : "launchWalkthrough"
		},

		/**
		 * constructor
		 *
		 * Main View initializer/constructor.
		 *
		 * @param options Map of options
		 */
		initialize : function(options){
			_.bindAll(this);
			this.exitCheckStarted = false;
			this.sendGone = true;
			this.endCheck = false;
			this.consolesReady = false;
			this.warningShown = false;
			this.dangerShown = false;

			this._throttledUserActiveSync               = _.throttle(this.userActiveSync, App.config.syncActivityTimeout || 6000, {trailing : false});
			this._throttledCheckServerCompleteCriteria  = _.throttle(this.checkServerCompleteCriteria, this.checkServerCompleteCriteriaFrequency);

			this.model.on("change:activeStepNumber", this.onActiveStepNumberChanged);
			this.model.on("change:completionStatusForLabCriteria", this.onCompletionStatusForLabCriteriaChanged);
			this.model.on("change:progress", this.progressChanged);

			this.activateAppLab();

			var self = this;
			if(App.push.inUse){
				App.push.on("received:entitlements:" + this.model.id + ":ready", this.ready);
				App.push.on("received:entitlements:" + this.model.id + ":error", this.onError);
				App.push.on("received:labError", this.onError);
				App.push.on("received:vAppReset", this.refreshVApp);
				App.push.on("received:entitlements:" + this.model.id + ":saasReset", this.refreshVApp);
				App.push.on("received:entitlements:" + this.model.id + ":exit", this.exited);
				App.push.on("received:entitlements:" + this.model.id + ":exit", this.exited);
				App.push.on("received:entitlements:" + this.model.id + ":end",  this.showComplete);
				App.push.on("received:entitlements:" + this.model.id + ":progress", this.updateEntitlementInfo);

				App.push.on("sent:entitlements:" + this.model.id + ":refresh", function(info){
					if(info && (info.requestParams && info.requestParams._s !== App.id)){
						App.reload();
					}
				}, this);
				App.push.on("sent:syncData:" + this.model.id + ":activity", this.pushActivity);
				App.push.on("sent:syncData:" + this.model.id + ":sync",     this.receivedSync);

				App.push.on("manuals:" + this.model.get("manualId") + ":node", this.pushActivity);

				if(App.remoteControl && App.station.isRegistered()){
					App.push.on("received:entitlements:" + this.model.id, function(info){
						if(info.requestParams && info.requestParams[App.config.senderParameter] !== App.id){
							self.stopThumbnails();
						}
					});
					App.windowCommunication.on("receive:activeLab",       this.onActiveLabChanged);
					App.windowCommunication.on("response:checkActiveLab", this.onActiveLabChanged);

					App.windowCommunication.call("checkActiveLab");
				}
			} else{
				this.model.on("change:vms", this.vmsChanged);
				this.model.on("change:remoteToken", this.activateAppLab);
				if(!App.remoteControl){
					this.startProgress();
				}
			}

			if(this.model.get("sku")){
				App.push.pingPacket.sku = this.model.get("sku");
				App.labSku = this.model.get("sku");
				App.labName = this.model.has("lab") && this.model.get("lab").get("name");
			}

			//Add content access key to ajax headers
			var headers = $.ajaxSetup().headers || {};
			headers["x-content-access-key"] = this.model.get("contentAccessKey");
			$.ajaxSetup({ headers : headers });

			this.model.on("change:status", this.statusChanged);
			this.model.on("change:canExtend change:extensionsRemaining", this.extensionsChange);
			this.model.on("change:timeAllotted", this.timeAllottedChanged);

			this.model.on("change:supportViewing", this.supportStatusChanged);
			this.model.on("change:instructorViewing change:takenOver change:broadcastingDisplay", this.instructorConsoleStatusChanged);
			this.model.on("change:classBroadcastingDisplay change:classBroadcastingEntitlement change:classActiveScreenTicket change:classActiveVmx", this.onInstructorBroadcastingDisplayChanged);
			this.listenTo(this.model, "change:activeConsoleId", this.onActiveConsoleIdChanged);

			this._throttledStudentClassPushThumbnail = _.throttle(this.studentClassPushThumbnail, 5000);
			App.push.on("other:classEntitlement:" + this.model.get("id") + ":getThumbnail", this._throttledStudentClassPushThumbnail, this);
			App.push.on("other:classEntitlement:" + this.model.get("id") + ":reset", this.onResetByInstructor, this);


			App.windowCommunication.on("called:checkActiveLab", this.activeLabCheck);
			$(window).on("beforeunload", this.cleanupConnections);
			App.user.once("loggingOut", this.logoutExit);

			this.model.set(App.session.get("consoleSettings") || {});

			this.model.pushSubscribe();
			this.model.studentSupportPushSubscribe();
			this.model.studentSupportRequestSync();

			if(!App.remoteControl){
				this.model.remoteSetupMainConsole();
			} else {
				this.model.remoteSetupRemoteConsole();
			}
		},

		supportStatusChanged: function () {
			if (this.model.get("supportViewing")) {

				App.mainView.generalMessage({
					id: "support_viewing_message",
					message: App.i18n("support.supportViewing"),
					type: "info",
					dismissible: false
				});
			}else{
				App.mainView.removeGeneralMessage("support_viewing_message");
			}
		},

		render : function(){
			var self = this;
			var data = this.model.toHandlebars();

			data.userIdleDelayMinutes   = Math.max(Math.round(App.config.userIdleDelay / 60000), 1);
			data.remoteControl          = App.remoteControl;

			var hideInstructionsPanel = !this.model.get("hasManual") && App.config.hidePanelIfNoManual;
			data.hideInstructionsPanel    = !App.config.showPanels || !App.config.showManualPanel || hideInstructionsPanel;
			data.hideConsoleChooserPanel  = !App.config.showPanels || !App.config.showConsoleChooserPanel;
			data.hideVideoConferencePanel = !App.config.showPanels || !App.config.showVideoConferencePanel;
			data.hideResourcesPanel       = !App.config.showPanels || !App.config.showResourcesPanel;

			this.$el.html(this.template(data));

			$("body").addClass("has-vm-console");

			if(
				this.model.get("status") === Entitlement.EXPIRED ||
				this.model.get("status") === Entitlement.COMPLETED ||
				this.model.get("status") === Entitlement.CANCELED ||
				this.model.get("status") === Entitlement.AWAITING_GRADE
			){
				this.statusChanged();
				return this;
			}

			$(document).off("click", ".entitlement-end",   this.end);
			$(document).off("click", ".entitlement-reset", this.reset);
			$(document).off("click", ".entitlement-exit",  this.exit);
			$(document).off("click", ".entitlement-grade", this.grade);

			$(document).on("click", ".entitlement-end",   this.end);
			$(document).on("click", ".entitlement-reset", this.reset);
			$(document).on("click", ".entitlement-exit",  this.exit);
			$(document).on("click", ".entitlement-grade", this.grade);

			$(document).off("click", "a.home", this.confirmExit);
			$(document).on("click",  "a.home", this.confirmExit);


			if(!this.panelManager){
				this.panelManager = new PanelManager({
					el : this.$(".panels")
				});

				this.$(".panels > .panel").each(function(){
					if(!App.remoteControl || $(this).attr("id") !== "instructions_panel"){
						self.panelManager.addPanel(this);
					}
				});
				this.panelManager.updateContainers(true);
				var consoleListingPanel = this.panelManager.getPanel("#vm_listing_panel");
				if(consoleListingPanel){
					consoleListingPanel.hide();
				}
			}

			if(!this.resources){
				this.resources = new ResourcesView({
					el                  : this.$("#resources"),
					collection          : this.model.get("resources"),
					panel               : this.panelManager.getPanel("#resources_panel"),
				});
				this.resources.render();
			}

			if(!this.videoConference){
				this.videoConference = new VideoConferenceView({
					el                  : this.$("#video_conference"),
					entitlement         : this.model,
					mode                : "student",
					panel               : this.panelManager.getPanel("#video_conference_panel")
				});
				this.videoConference.render();
			}

			if(!this.manualListing && !hideInstructionsPanel){
				this.manualListing = new ManualView({
					el                   : this.$("#instructions"),
					model                : this.model.get("manual"),
					manualId             : this.model.get("manualId"),
					networkTopology      : this.model.get("networkTopology"),
					entitlement          : this.model,
					currentStepId        : this.model.getBookmarkId(),
					title                : this.model.get("name"),
					panel                : this.panelManager.getPanel("#instructions_panel"),
					consoleListingPanel  : this.panelManager.getPanel("#vm_listing_panel"),
					videoConferencePanel : this.panelManager.getPanel("#video_conference_panel")
				});
				App.VlpGlobal.setManualView(this.manualListing);
				this.manualListing.render();
				if(!App.remoteControl && !hideInstructionsPanel){
					this.listenTo(this.panelManager.getPanel("#instructions_panel"), "panel:expand panel:close panel:dock panel:float panel:resizing panel:resized", this.repositionLoadingArea);
				}
			}

			if(!this.entitlementSettingsView){
				this.entitlementSettingsView = new EntitlementSettingsView({
					el   : this.$("#entitlement_settings"),
					model: this.model
				});
				this.entitlementSettingsView.render();
			}

			if(!this.exitCheckStarted){
				App.push.off("closing", this.userExiting).on("closing", this.userExiting);
				this.exitCheckStarted = true;
			}

			this.$("[rel=tooltip]").tooltip();

			this.$(".toggle-panel-left").tooltip({
				placement : "bottom",
				container : "body",
				title     : function(){ return self.getTogglePanelTitle("left"); },
				trigger   : "hover"
			});
			this.$(".toggle-panel-right").tooltip({
				placement : "left",
				container : "body",
				title     : function(){ return self.getTogglePanelTitle("right"); },
				trigger   : "hover"
			});

			if(App.remoteControl && this.model.has("_returnedVMs") && this.model.get("_returnedVMs").length > 0){
				this.vmsChanged();
			}

			this.statusChanged();

			if(!App.remoteControl){
				this.startProgress();
			}


			if((this.model.has("vms") && this.model.get("vms").length > 0) || this.model.get("saasConsoleOnly")){
				//Ready came in before start call completed
				this.ready();
			}

			if(this.model.get("name")){
				App.mainView.setTitle(this.model.get("name"), "title");
			}

			if(this.model.has("lab") && this.model.get("lab").has("vAppExitPowerOff")) {
				if(this.model.get("lab").get("vAppExitPowerOff")) {
					this.$("#exit-message-power-off").show();
					this.$("#exit-message-no-power-off").hide();
				} else {
					this.$("#exit-message-power-off").hide();
					this.$("#exit-message-no-power-off").show();
				}
			}

			this.updateHelpRequest();
			this.getInstructorHelpRequest();
			this.onCompletionStatusForLabCriteriaChanged();
			this.onActiveStepNumberChanged();
			this.onLabTimeElapsedChanged();
			//Always return this for chaining
			return this;
		},

		renderConsoles : function(){
			if(!this.rendered){
				this.once("afterRender", this.renderConsoles);
				return;
			}

			this.model.get("vms").sort();

			if(this.panelManager.getPanel("#instructions_panel")) {
				this.stopListening(this.panelManager.getPanel("#instructions_panel"), null, this.repositionLoadingArea);
			}

			if(!this.consoleListing){
				this.consoleListing = new ConsoleListingView({
					el            : this.$("#vm_listing"),
					collection    : this.model.get("consoles"),
					entitlement   : this.model,
					panel         : this.panelManager.getPanel("#vm_listing_panel")
				});
			}

			this.consoleListing.render();

			this.consolesReady = true;
			this.startTimer();
			if(!App.remoteControl){
				_.delay(this.startThumbnails, App.config.thumbnailCheckDelay);
				this.startProgress();
				this.showConsole();
			}
			this.instructorConsoleStatusChanged();
			this.onInstructorBroadcastingDisplayChanged();
		},

		refreshVApp : function() {
			this.exitCheckStarted = false;
			this.sendGone = true;
			this.endCheck = false;
			this.consolesReady = false;
			this.warningShown = false;
			this.dangerShown = false;
			App.reload();
		},

		onActiveConsoleIdChanged : function(){
			if(!App.remoteControl){
				this.showConsole();
			} else {
				this.updateConsoleNavigation();
			}
		},
		updateConsoleNavigation : function(){
			App.router.navigate((this.model.get("sku") ? "lab/" + this.model.get("sku") + "/" : "") + this.model.id + "/" + this.model.get("activeConsoleId"), {trigger : false});
		},

		showConsole : function(){
			if(!this.rendered){
				this.once("afterRender", this.showConsole);
				return;
			}

			if(!this.consolesReady){ return; }

			var activeConsole;
			if(this.model.get("activeConsoleIdNumber")){
				if(this.model.get("activeConsoleIdType") === "saas"){
					activeConsole = this.model.get("saasConsoles").get(this.model.get("activeConsoleIdNumber"));
					if(activeConsole){
						this.showSaasConsole(activeConsole);
					}
				} else if(this.model.get("activeConsoleIdType") === "vm"){
					activeConsole = this.model.get("vms").get(this.model.get("activeConsoleIdNumber"));
					if(activeConsole){
						this.showVmConsole(activeConsole);
					}
				}
			}

			if(!activeConsole){
				var initialConsoleId = this.getInitialConsoleId();
				if(initialConsoleId){
					console.log("No console id specified or id not found. Setting to default:", initialConsoleId);
					this.model.set("activeConsoleId", initialConsoleId);
				} else{
					console.warn("no consoles to show");
				}
			} else {
				App.VlpGlobal.setActiveConsoleView(this.activeConsoleView);
				this.updateConsoleNavigation();
				if(this.activeConsoleView){
					$("body").toggleClass("has-vm-console", this.activeConsoleView.consoleType === "vm");
					$("body").toggleClass("has-saas-console", this.activeConsoleView.consoleType === "saas");
				}
			}
		},
		showVmConsole : function(vmConsole){
			if(!vmConsole){ return; }

			if(!this.vmConsoleView){
				this.vmConsoleView = new VmConsoleView({
					el : this.$("#vm"),
					entitlement : this.model,
					entitlementView : this,
					panelManager : this.panelManager
				});
			}
			this.detachConsoleView(this.saasConsoleView);

			this.activeConsoleView = this.vmConsoleView;
			this.vmConsoleView.setModel(vmConsole);
			this.attachConsoleView(this.vmConsoleView);
		},
		showSaasConsole : function(saasConsole){
			if(!saasConsole){ return; }

			if(!this.saasConsoleView){
				this.saasConsoleView = new SaasConsoleView({
					el : this.$("#saas_console"),
					entitlement : this.model,
					entitlementView : this,
					panelManager : this.panelManager
				});
			}
			this.detachConsoleView(this.vmConsoleView);

			this.activeConsoleView = this.saasConsoleView;
			this.saasConsoleView.setModel(saasConsole);
			this.attachConsoleView(this.saasConsoleView);
		},

		getInitialConsoleId : function(){
			var initialConsole;
			if(this.model.get("vms") && this.model.get("vms").length > 0){
				initialConsole = this.model.get("vms").findWhere({initial : true});
				if(!initialConsole){
					initialConsole = this.model.get("vms").at(0);
				}
			} else if(this.model.get("saasConsoles") && this.model.get("saasConsoles").length > 0){
				initialConsole = this.model.get("saasConsoles").at(0);
			}

			if(initialConsole){
				return initialConsole.get("consoleType") + "-" + initialConsole.get("id");
			} else {
				return null;
			}
		},

		switchConsole : function(eventOrConsoleId){
			var consoleIdentifier;
			if(eventOrConsoleId && eventOrConsoleId.currentTarget){
				consoleIdentifier = $(eventOrConsoleId.currentTarget).data("switchConsole");
			} else{
				consoleIdentifier = eventOrConsoleId;
			}
			if(!consoleIdentifier){ return; }


			var saasConsole, vm, id;
			if(consoleIdentifier.toString().indexOf("saas-") === 0){
				consoleIdentifier = consoleIdentifier.replace(/^saas-/, "");
				saasConsole = this.model.get("saasConsoles").findWhere({name: consoleIdentifier});
				id = parseInt(consoleIdentifier, 10);
				if(!saasConsole && !isNaN(id)){
					saasConsole = this.model.get("saasConsoles").get(id);
				}
			} else if(consoleIdentifier.toString().indexOf("vm-") === 0){
				consoleIdentifier = consoleIdentifier.replace(/^vm-/, "");
				vm = this.model.get("vms").findWhere({name: consoleIdentifier});
				id = parseInt(consoleIdentifier, 10);
				if(!vm && !isNaN(id)){
					vm = this.model.get("vms").get(id);
				}
			} else {
				saasConsole = this.model.get("saasConsoles").findWhere({name: consoleIdentifier});
				vm = this.model.get("vms").findWhere({name: consoleIdentifier});
				id = parseInt(consoleIdentifier, 10);
				if(!saasConsole && !vm && !isNaN(id)){
					saasConsole = this.model.get("saasConsoles").get(id);
					vm = this.model.get("vms").get(id);
				}
			}

			var newActiveConsoleId;
			if(saasConsole){
				newActiveConsoleId = "saas-" + saasConsole.get("id");
			} else if (vm){
				newActiveConsoleId = "vm-" + vm.get("id");
			}

			if(newActiveConsoleId === this.model.get("activeConsoleId")){
				if(this.activeConsoleView){
					this.activeConsoleView.refreshConsole();
				}
			} else if(newActiveConsoleId){
				this.model.set("activeConsoleId", newActiveConsoleId);
			}
		},


		extendTime : function(event){
			var self = this;
			this.$(".extend-time")
				.button("loading")
				.tooltip("hide");

			this.model.extendTime({
				success : function(){
					self.$(".extend-time").button("reset");
					_.defer(function(){self.extensionsChange();});
				},
				error : function(model, response){
					self.$(".extend-time").button("reset");

					if(response.response.errorCode === 5100){
						self.model.set("canExtend", false);
						_.delay(function(){
							self.$(".extend-time").tooltip("show");
						}, 500);
						_.delay(function(){
							self.$(".extend-time").tooltip("hide");
						}, 7000);
					}
					_.defer(function(){self.extensionsChange();});
				}
			});
		},

		end : function(event){

			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}


			this.model.set("phase", "end");

			$("#modal_end .end-spinner").removeClass("hide");
			$("#modal_end button.entitlement-end").button("loading");
			$("#modal_end button.cancel").hide();
			this.sendGone = false;
			this.cleanupConnections();

			this.model.consoleLatency("end");
			this.model.end({
				//success : this.showComplete,
				error   : this.showComplete
			});
		},
		grade : function(event){

			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			this.model.set("phase", "grade");

			$("#modal_grade .grade-spinner").removeClass("hide");
			$("#modal_grade button.entitlement-grade").button("loading");
			$("#modal_grade button.cancel").hide();
			this.sendGone = false;
			this.cleanupConnections();

			this.model.consoleLatency("end");

			this.model.end({
				//success : this.showComplete,
				error   : this.showComplete
			});
		},

		reset : function(event){

			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			this.model.set("phase", "reset");

			$("#modal_reset .reset-spinner").removeClass("hide");
			$("#modal_reset button.entitlement-reset").button("loading");
			$("#modal_reset button.cancel").hide();
			this.sendGone = false;
			this.cleanupConnections();

			this.model.reset({
				//Do nothing, should receive vAppReset push message
				//success : this.refreshVApp
			});
		},

		exit : function(event){

			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}

			this.model.set("phase", "exit");

			$("#modal_exit .exit-spinner").removeClass("hide");
			$("#modal_exit button.entitlement-exit").button("loading");
			$("#modal_exit button.cancel").hide();

			this.sendGone = false;
			this.cleanupConnections();
			this.model.consoleLatency("exit");

			this.model.exit({
				//success : this.exited,
				error : this.exited
			});
		},

		exited : function(){
			this.sendGone = false;
			this.stop();
			this.leaveOrShowExternal();
		},

		logoutExit : function(account, loggingOutDeferreds){
			this.model.set("phase", "logout");
			this.sendGone = false;
			this.stop();
			if(!this.ended){
				if(loggingOutDeferreds && _.isArray(loggingOutDeferreds)) {
					loggingOutDeferreds.push(this.model.exit());
				} else{
					this.model.exit({
						async : false
					});
				}
			}
		},

		forceLogout : function(){
			if(App.user.get("loggedIn")){
				App.user.logout({
					async : false
				});
			}
		},

		userExiting : function(){
			if(this.userExited){ return; } //Already called once
			this.userExited = true;

			if(this.sendGone && !App.remoteControl && !this.ended){
				this.model.set("phase", "gone");
				this.model.gone({
					async   : false,
					success : function(){
					}
				});

				this.model.consoleLatency("gone", {async   : false});
			}

			this.deleteHelpRequest();
		},

		showRemotePrompt : function(event){
			if(event && event.preventDefault) { event.preventDefault(); }
			var self = this;

			this.$("#modal_split_screen").modal("show");
			if(_.isEmpty(this.model.get("remoteToken"))){
				this.model.remoteToken({
					success : function(){
						self.$("#modal_split_screen .modal-body").html(remotePromptTPL(self.model));
						self.$("#qrcode > canvas").remove();
						self.$("#qrcode").qrcode({width : 140, height : 140, text : self.model.get("remoteUrl")});
						self.$("#modal_split_screen").modal("reflow");
					}
				});
			} else{
				this.$("#modal_split_screen .modal-body").html(remotePromptTPL(this.model));
				this.$("#qrcode > canvas").remove();
				this.$("#qrcode").qrcode({width : 140, height : 140, text : this.model.get("remoteUrl")});
				this.$("#modal_split_screen").modal("reflow");
			}
		},

		idleTimedOut : function(event){

			if(event && event.preventDefault){
				event.preventDefault();
				if(event.handled) { return; }
				event.handled = true;
			}
			this.stop();
			if(this.sendGone && !this.ended && !App.remoteControl){
				this.sendGone = false;
				this.model.set("phase", "gone");
				this.model.gone({
					success : this.showIdleTimedOut,
					error   : this.showIdleTimedOut
				});
			} else{
				this.showIdleTimedOut();
			}

			this.model.consoleLatency("idle");
		},

		vmsChanged : function(){
			this.activateAppLab();
			this.model.fetchRelated("vms", {
				success : this.renderConsoles,
				error   : function(){
					console.error("Could not fetch vms");
				}
			});
		},

		statusChanged : function(){
			if(this.model.get("status") === Entitlement.EXPIRED){
				this.model.consoleLatency("expired");
				this.showExpired();
			} else if(
				this.model.get("status") === Entitlement.COMPLETED ||
				this.model.get("status") === Entitlement.CANCELED ||
				this.model.get("status") === Entitlement.AWAITING_GRADE
			){
				this.showComplete();
			}
		},

		progressChanged : function(){
			this.$(".progress-holder .progress .bar").css("width", + this.model.get("progress") + "%");
		},

		timeAllottedChanged : function(model){
			var diff = model.get("timeAllotted") - model.previous("timeAllotted");
			this.model.set("startTimeRemaining", this.model.get("startTimeRemaining") + diff * 60);
		},

		extensionsChange : function(){
			if(this.model.get("canExtend")){
				this.$(".extend-time")
					.attr("data-title", App.i18n("console.entitlement.extendTime.message", this.model.get("extensionsRemaining")))
					.attr("data-original-title", App.i18n("console.entitlement.extendTime.message", this.model.get("extensionsRemaining")))
					.tooltip("setContent");

				this.$(".extension-count-container .text-success").text(this.model.get("extensionsRemaining"));
			} else{
				this.$(".extend-time")
					.attr("data-title", App.i18n("console.entitlement.extendTime.noRemaining"))
					.attr("data-original-title", App.i18n("console.entitlement.extendTime.noRemaining"))
					.tooltip("setContent");
			}

			if(!this.model.get("canExtend") || this.model.get("extensionsRemaining") <= 0 ){
				this.$(".extend-time").prop("disabled", true);
				this.$("[data-extend-time]").prop("disabled", true);
			}
		},

		startTimer : function(){
			this.trigger("started");
			if(this.timerInterval){
				this.stopTimer();
			}
			this.model.set("startTimeRemaining", this.model.get("timeRemaining") * 60);
			this.model.set("labTimeRemaining", this.model.get("startTimeRemaining"));
			this.model.set("startTimeElapsed", this.model.get("timeElapsed"));

			this.userActivity();
			this.timer();
			this.timerInterval = setInterval(this.timer, 500);
			$(document).off("keydown keyup mousemove mousedown", this.userActivity).on("keydown keyup mousemove mousedown", this.userActivity);
		},

		stopTimer : function(){
			if(this.timerInterval){
				clearTimeout(this.timerInterval);
			}
			this.timerInterval = null;
			$(document).off("keydown keyup mousemove mousedown", this.userActivity);
		},

		timer : function(){
			if(!this.model.get("startTime")){
				this.model.set("startTime", Date.now());
			}
			var now = Date.now();
			var timeDiff = (now - this.model.get("startTime")) / 1000; //in seconds

			if(App.config.timeRemainingDiffRefresh > 0){
				if(!this.lastTick){
					this.lastTick = now;
				} else{
					var timeSinceLastTick = (now - this.lastTick);
					if(timeSinceLastTick >= App.config.timeRemainingDiffRefresh || timeSinceLastTick < (App.config.timeRemainingDiffRefresh / -2)){
						this.showTimeDiffRemainingRefresh();
						return;
					}
					this.lastTick = now;

				}
			}
			var timeRemaining = this.model.get("startTimeRemaining") - timeDiff;

			this.model.set("labTimeRemaining", timeRemaining);

			var hideRemainingTime = this.model.get("hideRemainingTime");
			if (!hideRemainingTime) {
				this.$(".time-remaining").text(this.model.get("labTimeRemainingDisplay"));
			}

			if(timeRemaining < 0 && !this.endCheck){
				this.checkExpired();
			}



			timeRemaining *= 1000;
			if (!hideRemainingTime) {
				if(!this.dangerShown && timeRemaining < App.config.timeRemainingDanger){
					this.$(".time-remaining").removeClass("warning").addClass("danger");
					this.dangerShown = true;
				} else if(!this.warningShown && timeRemaining < App.config.timeRemainingWarning){
					this.$(".time-remaining").addClass("warning");
					this.$("#time_warning_popup").modal("show");
					this.warningShown = true;
				} else if (this.warningShown && timeRemaining > App.config.timeRemainingWarning){
					this.dangerShown = false;
					this.warningShown = false;
					this.$(".time-remaining").removeClass("warning danger");
					this.$("#time_warning_popup").modal("hide");
				} else if (this.dangerShown && timeRemaining > App.config.timeRemainingDanger){
					this.dangerShown = false;
					this.$(".time-remaining").removeClass("danger");
				} else if (this.warningShown && (timeRemaining < App.config.timeRemainingWarning) && (timeRemaining > App.config.timeRemainingDanger) && !this.$(".time-remaining").hasClass("warning")){
					this.$(".time-remaining").addClass("warning");
				}
			}

			if(App.config.userIdleDelay){
				var idleTimeDiff = now - this.lastUserActivity;
				if(!this.idleWarningShown && idleTimeDiff > App.config.userIdleDelay){
					if(App.config.userIdleCountDown){
						this.$("#idle_warning_popup").modal("show");
						this.idleWarningShown = true;
						this.idleWarningCountDownStart = now;
					} else{
						this.idleTimedOut();
					}
				}

				if(this.idleWarningShown){
					var idleTimeRemaining = (App.config.userIdleCountDown - (now - this.idleWarningCountDownStart)) / 1000;

					if(idleTimeRemaining < 0){
						this.idleTimedOut();
					} else{
						var idleTimeRemainingMinutes = Math.floor(idleTimeRemaining / 60);
						var idleTimeRemainingSeconds = Math.floor((idleTimeRemaining - (idleTimeRemainingMinutes * 60)));
						var idleTimeRemainingDisplay = _.lpad(idleTimeRemainingMinutes, 2, "0") + ":" + _.lpad(idleTimeRemainingSeconds, 2, "0");

						this.$("#idle_warning_popup .count-down-time").text(idleTimeRemainingDisplay);

					}
				}
			}
			this.onLabTimeElapsedChanged();
		},

		startThumbnails : function(){
			if(!this.stopped && this.consoleListing && this.model.get("fetchThumbnails")){
				this.consoleListing.startThumbnailUpdate();
			} else{
				this.stopThumbnails();
			}
		},

		stopThumbnails : function(){
			if(this.consoleListing){
				this.consoleListing.stopThumbnailUpdate();
			}
		},

		startProgress : function(){
			if(this.progressInterval){
				this.stopProgress();
			}
			var time = App.config.progressFrequency || 5000;
			if(this.endCheck && time > 1000 * 60){
				time = 1000 * 60;
			}
			this.progressInterval = setInterval(this.progress, time);
		},

		stopProgress : function(){
			if(this.progressInterval){
				clearTimeout(this.progressInterval);
			}
			this.progressInterval = null;
		},

		progress : function(){
			this.model.progress();
		},

		stop : function(){
			if(!App.remoteControl){
				this.sync();
			}
			Backbone.history.stop();
			App.windowCommunication.off("called:checkActiveLab", this.activeLabCheck);

			this.stopProgress();
			this.stopTimer();

			if(this.consoleListing){
				this.consoleListing.remove();
			}
			App.push.off("received:labError", this.onError);
			App.push.off("received:vAppReset", this.refreshVApp);
			App.push.off("received:entitlements:" + this.model.id + ":error", this.onError);
			App.push.off("received:entitlements:" + this.model.id + ":ready", this.ready);
			App.push.off("received:entitlements:" + this.model.id + ":exit", this.exited);
			App.push.off("received:entitlements:" + this.model.id + ":saasReset", this.refreshVApp);
			App.push.off("received:entitlements:" + this.model.id + ":end", this.showComplete);
			App.push.off("received:entitlements:" + this.model.id + ":progress", this.updateEntitlementInfo);
			App.push.off("sent:syncData:" + this.model.id + ":activity", this.pushActivity);
			App.push.off("other:classEntitlement:" + this.model.get("id") + ":getThumbnail", this._throttledStudentClassPushThumbnail, this);
			App.push.off("other:classEntitlement:" + this.model.get("id") + ":reset", this.onResetByInstructor, this);

			this.model.off("change:vms", this.vmsChanged);
			this.model.off("change:status", this.statusChanged);
			this.model.off("change:progress", this.progressChanged);
			this.model.off("change:timeAllotted", this.timeAllottedChanged);

			$(document).off("click", "a.home", this.confirmExit);
			$(window).off("beforeunload.entitlement");
			$(window).off("beforeunload", this.cleanupConnections);

			if(this.walkthroughView){
				this.walkthroughView.remove();
				this.walkthroughView = null;
			}
			this.cleanupConnections();

			App.VlpGlobal.setActiveConsoleView(null);
			this.activeConsoleView = null;
			$("body").removeClass("maximize");
			$("#idle_warning_popup").modal("hide").remove();
			$("#time_warning_popup").modal("hide").remove();
			$("#modal_end").modal("hide");
			$("#modal_exit").modal("hide");
			$("#modal_grade").modal("hide");
			this.$("[rel=tooltip]").tooltip("hide");
			this.$("[rel=popover]").popover("hide");

			App.mainView.removeGeneralMessage("support_viewing_message");
			App.mainView.removeGeneralMessage("instructor_viewing_message");
			App.mainView.removeGeneralMessage("instructor_broadcasting_message");
			App.mainView.removeGeneralMessage("instructor_taken_over_message");
			App.mainView.removeGeneralMessage("instructor_taken_over_and_broadcasting_message");

			this.stopped = true;
		},

		startLogoutTimeout : function(){
			if(!App.remoteControl && !App.user.get("hasCredits") && !this.labCompleteTimeout){
				this.labCompleteTimeout = setTimeout(this.forceLogout, App.config.labCompleteTimeout);
			}
		},

		stopLogoutTimeout : function(){
			if(this.labCompleteTimeout){
				this.labCompleteTimeout = clearTimeout(this.labCompleteTimeout);
				this.labCompleteTimeout = null;
			}
		},

		checkExpired : function(){
			var self = this;

			this.endCheck = true;
			this.model.progress({
				success : function(model, response){
					if(!response.data || (response.data.timeAllotted - response.data.timeElapsed <= 1)){
						self.endCheck = true;
						self.stopProgress();
						self.model.consoleLatency("expired");
						self.model.expire({
							success : self.showExpired,
							error   : self.showExpired
						});
					} else{
						self.endCheck = false;
						if(!self.model.get("startTime")){
							self.model.set("startTime", Date.now());
						}
						var now = Date.now();
						var timeDiff = (now - self.model.get("startTime")) / 1000; //in seconds
						var timeRemaining = self.model.get("startTimeRemaining") - timeDiff;
						var timeRemainingServer = response.data.timeAllotted - response.data.timeElapsed;
						self.model.set("startTimeRemaining", self.model.get("startTimeRemaining") + (timeRemainingServer * 60) - timeRemaining);
					}
				}
			});
		},
		onActiveStepNumberChanged : function(){
			this.checkCompleteCriteria();
			$(".lab-completion-criteria-warning .manual-steps-read-value")
				.text(App.i18n("percent", Math.floor(this.model.get("stepVisitedPercent"))));
		},
		onCompletionStatusForLabCriteriaChanged : function(){
			this.showHideCompletionWarnings();
		},
		onLabTimeElapsedChanged : function(){
			if(this.model.get("requiredMinimumTimeSpent")){
				this.checkCompleteCriteria();
				$(".lab-completion-criteria-warning .time-elapsed-value")
					.text(daysHoursMinutesLabel(this.model.get("labTimeElapsed"), {milliseconds: true}));
			}
		},
		showHideCompletionWarnings : function(){
			if(this.model.get("completionStatusForLabCriteria") === "completed"){
				$("#lab_completion_criteria_warning").hide();
				this.$(".entitlement-end-prompt").prop("disabled", false);
				this.$(".entitlement-grade-prompt").prop("disabled", false);
				this.$(".completion-popover-trigger").popover("destroy");
				this.$(".completion-popover-trigger > .btn").tooltip("enable");
			} else {
				var warningText = "";
				var dhms = _.secondsToDHMS(this.model.get("requiredMinimumTimeSpent") * 60);
				var singular = (dhms.minutes === 1) || (dhms.hours === 1 && dhms.minutes === 0) || (dhms.days === 1 && dhms.hours === 0 && dhms.minutes === 0);
				if(!this.percentagePagesVisitedCompleted && !this.minimumTimeCompleted){
					warningText = App.i18n(
						(singular ? "completionCriteria.requiredMinimumTimeSpentAndStepsVisitedPercentageSingular" :"completionCriteria.requiredMinimumTimeSpentAndStepsVisitedPercentage"),
						daysHoursMinutesLabel(this.model.get("requiredMinimumTimeSpent")),
						this.model.get("requiredStepsVisitedPercentage"),
						daysHoursMinutesLabel(this.model.get("labTimeElapsed"), {milliseconds: true}),
						this.model.get("stepVisitedPercent")
					);
				} else if(!this.minimumTimeCompleted && this.percentagePagesVisitedCompleted){
					warningText = App.i18n(
						(singular ? "completionCriteria.requiredMinimumTimeSpentSingular" :"completionCriteria.requiredMinimumTimeSpent"),
						daysHoursMinutesLabel(this.model.get("requiredMinimumTimeSpent")),
						daysHoursMinutesLabel(this.model.get("labTimeElapsed"), {milliseconds: true})
					);
				} else if(!this.percentagePagesVisitedCompleted && this.minimumTimeCompleted){
					warningText = App.i18n(
						"completionCriteria.requiredStepsVisitedPercentage",
						this.model.get("requiredStepsVisitedPercentage"),
						this.model.get("stepVisitedPercent")
					);
				}

				$("#lab_completion_criteria_warning")
					.toggle(!this.minimumTimeCompleted || !this.percentagePagesVisitedCompleted)
					.text(warningText);

				$(".lab-completion-criteria-warning .warning-message").text(warningText);

				$(".lab-completion-criteria-warning .time-elapsed-display")
					.toggle(!this.minimumTimeCompleted);
				$(".lab-completion-criteria-warning .manual-steps-read")
					.toggle(!this.percentagePagesVisitedCompleted);
				$(".lab-completion-criteria-warning .checking-status")
					.toggle(this.percentagePagesVisitedCompleted && this.minimumTimeCompleted);


				if(App.config.disableEndButtonUntilComplete){
					this.$(".entitlement-end-prompt").prop("disabled", true);
					this.$(".entitlement-grade-prompt").prop("disabled", true);
				}
				this.$(".completion-popover-trigger").popover();
				this.$(".completion-popover-trigger > .btn").tooltip("disable");
			}
		},
		checkCompleteCriteria : function(){

			if(this.model.get("completionStatusForLabCriteria") === "completed"){ return; }

			var minimumTimeCompletedChanged = false;
			var percentagePagesVisitedCompletedChanged = false;

			if (this.model.get("requiredMinimumTimeSpentMet")) {
				if(!this.minimumTimeCompleted){
					this.minimumTimeCompleted = true;
					minimumTimeCompletedChanged = true;
					this.showHideCompletionWarnings();
				}
			} else {
				if(this.minimumTimeCompleted){
					this.minimumTimeCompleted = false;
					this.showHideCompletionWarnings();
				}
			}

			if(this.model.get("requiredStepsVisitedPercentageMet")){
				if(!this.percentagePagesVisitedCompleted){
					this.percentagePagesVisitedCompleted = true;
					percentagePagesVisitedCompletedChanged = true;
					this.showHideCompletionWarnings();
				}
			} else {
				if(this.percentagePagesVisitedCompleted){
					this.percentagePagesVisitedCompleted = false;
					this.showHideCompletionWarnings();
				}
			}

			var self = this;

			if(this.minimumTimeCompleted && this.percentagePagesVisitedCompleted){

				if(percentagePagesVisitedCompletedChanged){
					if(this.model.get("activeStepBookmarkComplete") == false){
						this.model.once("change:activeStepBookmarkComplete", function(){
							self._throttledCheckServerCompleteCriteria();
						});
					} else {
						this._throttledCheckServerCompleteCriteria();
					}
				} else {
					if(!this.lastCheckCompleteCriteriaCall || (Date.now() - this.lastCheckCompleteCriteriaCall > this.checkCompleteCriteriaCallFrequency)){
						this.lastCheckCompleteCriteriaCall = Date.now();
						this._throttledCheckServerCompleteCriteria();
					}
				}
			}
		},
		checkServerCompleteCriteria : function(){
			if(this.model.get("completionStatusForLabCriteria") === "completed"){
				return;
			}
			//The return result will be push messages that updates model and changes completionStatusForLabCriteria in updateEntitlementInfo()
			this.model.progress();
		},

		showExpired : function(){
			App.router.navigate((this.model.get("sku") ? "lab/" + this.model.get("sku") + "/" : "") + this.model.id + "/expired", {trigger : false});
			this.stop();
			if(!this.expiredView){
				this.expiredView = new ExpiredView({
					model           : this.model,
					entitlementView : this,
					el              : this.$("#lab")
				});
				this.expiredView.render();
				if(!App.remoteControl){
					this.model.get("survey").fetch();
				}
			}
		},

		showComplete : function(){
			this.ended = true;

			App.router.navigate((this.model.get("sku") ? "lab/" + this.model.get("sku") + "/" : "") + this.model.id + "/completed", {trigger : false});
			this.stop();
			if(App.station.isRegistered() && App.remoteControl){
				App.redirect({page: App.config.pages.EVENT_PROMOS});
			}

			if(this.panelManager){
				this.panelManager.clean();
			}
			if(this.vmConsoleView){
				this.vmConsoleView.clean();
			}
			if(this.saasConsoleView){
				this.saasConsoleView.clean();
			}
			if(App.session.get("returnUrl")){
				this.leave();
			}
			App.reload = App.home;

			if(!this.completedView){
				this.completedView = new CompleteView({
					model          : this.model,
					entitlementView: this,
					videoConference: this.videoConference,
					el             : this.$("#lab")
				});
				this.completedView.render();
			}
		},

		showIdleTimedOut : function(){
			this.stop();
			if(this.videoConference && this.videoConference.connected){
				this.videoConference.leave();
			}

			var self = this;
			setTimeout(function(){
				//wrapped in timeout to allow videoconference leave message to go through.
				if(!self.idleTimedOutView){
					self.idleTimedOutView = new IdleTimedOutView({
						model : self.model,
						el    : self.$("#lab")
					});
				}
				self.idleTimedOutView.render();
			});
		},

		showExitExternal : function(){
			this.stop();
			window.self.close();

			$("#modal_return").modal("show");
			this.$("#lab").remove();

		},

		showTimeDiffRemainingRefresh : function(){
			this.stop();

			var retryTime = Date.now() + App.config.timeRemainingDiffCountdown;
			var countdownInterval;
			var countdownFunction = function(){
				var time = Date.now();
				var seconds = Math.max(0, Math.round((retryTime - time) / 1000));
				$("#modal_time_remaining_diff_refresh .time-remaining-diff-countdown").text(seconds);
				if(retryTime - (time + 1) <= 0){
					clearInterval(countdownInterval);
					App.reload();
				}
			};
			countdownInterval = setInterval(countdownFunction, 1000);
			countdownFunction();

			$("#modal_time_remaining_diff_refresh").modal("show");

		},

		repositionLoadingArea : function(panel){
			var left = 0;
			if(!panel.state.floating && panel.state.expanded){
				var halfWidth = (panel.$el.parent().length ? panel.$el.parent().width() : panel.$el.width()) / 2;
				if(panel.state.position === "left"){
					left = halfWidth;
				} else{
					left = -halfWidth;
				}
			}

			this.$(".building-lab")
				.toggleClass("transition", !panel.state.resizing)
				.toggleClass("manual-expanded", !panel.state.floating && panel.state.expanded)
				.css("left", left + "px");
		},

		closeWindow : function(){
			window.self.close();
			$("#modal_return").modal("show");
		},

		userActivity : function(event, send){
			var moved = true;
			send = (send === undefined ? true : send);
			if(event && event.type === "keydown" && !Browser.fixANSISupported && event.originalEvent && event.originalEvent.key){
				$("#fix_ansi").closest(".setting").removeClass("disabled");
				Browser.fixANSISupported = true;
			}
			if(event && event.type === "mousemove"){
				if(this.lastMouseMoveEvent && this.lastMouseMoveEvent.screenX === event.screenX && this.lastMouseMoveEvent.screenY === event.screenY){
					moved = false;
				}
				this.lastMouseMoveEvent = event;
			}
			if(moved){
				this.lastUserActivity = Date.now();
				if(this._throttledUserActiveSync && send){
					this._throttledUserActiveSync();
				}
				if(this.idleWarningShown){
					this.idleWarningShown = false;
					this.$("#idle_warning_popup").modal("hide");
				}
			}
		},

		pushActivity: function(info){
			if(info.requestParams && info.requestParams[App.config.senderParameter] !== App.id){
				if(info.data){
					if(info.data.startTimeRemaining){
						this.model.set("startTimeRemaining", info.data.startTimeRemaining);
					}
					if(info.data.startTime){
						var startTimeDiff = 0;
						if(info.data.now){
							startTimeDiff = Math.ceil((Date.now() - info.data.now) / 1000) * 1000;
						}
						this.model.set("startTime", info.data.startTime + startTimeDiff);

					}
				}
				this.userActivity(false, false);
			}
		},

		sync: function(){
			var data = {};
			data.service = "syncData";
			data.operation = "sync";
			data.broadcast = true;

			data.requestParams = {
				id : this.model.id
			};
			data.data = {};
			data.data.progress = this.model.get(["progress"]);
			App.push.send(data);
		},

		receivedSync: function(info){
			if(info.requestParams && info.requestParams[App.config.senderParameter] !== App.id){
				this.updateEntitlementInfo(info);
			}
		},

		userActiveSync : function(){
			if(App.config.syncActivityTimeout === 0){ return; }

			var data = {};
			data.service = "syncData";
			data.operation = "activity";
			data.broadcast = true;
			if(!App.remoteControl){
				data.data = {
					startTimeRemaining : this.model.get("startTimeRemaining"),
					startTime          : this.model.get("startTime"),
					now                : Date.now()
				};
			}
			data.requestParams = {
				id : this.model.id
			};
			App.push.send(data);
		},

		ready: function(info){
			var self = this;
			App.push.off("received:entitlements:" + this.model.id + ":ready", this.ready);
			this.model.set("phase", "running");
			var data = (info && info.data) || null;

			if(data){
				this.model.set(this.model.parse(data));
			}
			App.VlpGlobal.applyEntitlementOverrides(this.model);

			if(this.model.get("hasConsoles") ){
				this.renderConsoles();
			} else{
				this.model.progress({
					success : function(model, response){
						if(!response.data || !response.data.vms || response.data.vms.length === 0){
							self.model.set("phase", "error");
							alert(App.i18n("console.entitlement.vmsError"));
							self.leaveOrShowExternal();
						}
						self.vmsChanged();
					}
				});
			}

			this.activateAppLab();

			if(!App.remoteControl){
				this.$("#launch_walkthrough").prop("disabled", false);
				if(!App.session.get("walkthroughShown") && App.user.get("showWalkthrough")){
					this.launchWalkthrough();
				}

				if(this.model.get("integrationCode")){
					try{
						$("body").append(this.model.get("integrationCode"));
					} catch(e){
						console.warn(e);
					}
				}
			} else {
				this.model.remoteRequestDataSync();
			}


			this.model.set("startDate", new Date());
			this.trigger("ready");
		},

		onError: function(response){
			this.model.set("phase", "error");
			alert(_.escapeHTML(response.data.message));
			this.exit();
		},

		leaving : function(){
			this.$("#modal_exiting").modal("show");
			this.$el.hide();
		},

		leaveOrShowExternal : function(){
			if(App.session.get("returnUrl")){
				this.leave();
			} else if(App.session.get("externalSite")){
				this.showExitExternal();
			} else{
				this.leave();
			}
		},

		leave : function(){
			this.leaving();
			if(App.session.get("returnUrl")){
				App.redirect(_.sanitizeURL(App.session.get("returnUrl")));
			} else if(App.station.isRegistered() && App.remoteControl){
				App.redirect({page: App.config.pages.EVENT_PROMOS});
			} else{
				App.redirect({page: App.config.pages.HOME});
			}
		},

		confirmExit : function(event){
			if(!confirm(App.i18n("console.entitlement.exit.title"))){
				event.preventDefault();
				event.stopImmediatePropagation();
			}
		},


		cleanupConnections : function(){
			if(this.vmConsoleView){
				this.vmConsoleView.remove();
			}
			if(this.saasConsoleView){
				this.saasConsoleView.remove();
			}
			this.removeBroadcastVm();
			this.deleteHelpRequest();
		},

		_activeLabData : function(){
			return{
				tenant        : App.getTenant(),
				entitlementId : this.model.id,
				remoteToken   : this.model.get("remoteToken")
			};
		},

		activeLabCheck : function(){
			App.windowCommunication.respond("checkActiveLab", this._activeLabData());
		},

		activateAppLab : function(){
			App.windowCommunication.send("activeLab", this._activeLabData());
		},

		onActiveLabChanged : function(activeLab, event){
			if(activeLab.entitlementId){
				if(this.model.get("id") !== activeLab.entitlementId){
					App.redirect({page: App.config.pages.REMOTE_CONTROL, action: activeLab.entitlementId, tenant: activeLab.tenant,  forceHost : true});
				}
			} else if(activeLab.remoteToken){
				if(this.model.get("remoteToken") !== activeLab.remoteToken && App.remoteToken !== activeLab.remoteToken) {
					App.redirect({page : App.config.pages.REMOTE_CONTROL, action : "remoteToken/" + activeLab.remoteToken, tenant : activeLab.tenant, forceHost : true});
				}
			}

		},

		updateEntitlementInfo : function(info){
			if(info.data){
				this.model.set(this.model.parse(info.data));
			}
		},

		onSendText : function(event){
			var $target = $(event.currentTarget);
			var text = _.unescapeString($target.data("sendText")) || _.unescapeString($target.data("text")) || $target.val() || $target.text();
			this.sendText(text);
		},
		sendText : function(text){
			if(this.vmConsoleView && this.model.get("userActionAllowed")){
				this.vmConsoleView.sendText(text);
			}
		},

		subscribeForNotification : function(e) {
			var $target = $(e.target);
			var selected = $target.is(":checked");
			if (selected) {
				this.model.updateNotification(true);
			} else {
				this.model.updateNotification(false);
			}
		},

		showExit : function(){
			if(this.model.get("exitAllowed")) {
				this.$("#modal_exit").modal("show");
			}
		},

		showEnd : function(){
			if(!this.model.get("requiresGrading") && this.model.get("endAllowed")) {
				this.$("#modal_end").modal("show");
			}
		},

		showReset : function(){
			if(this.model.get("resetAllowed")) {
				this.$("#modal_reset").modal("show");
			}
		},

		showGrade : function(){
			if(this.model.get("requiresGrading")){
				this.$("#modal_grade").modal("show");
			}
		},

		launchWalkthrough : function(){
			var self = this;
			if(!this.walkthroughView){
				this.walkthroughView = new WalkthroughView({
					panelManager : this.panelManager
				});
			}

			this.walkthroughView.start({
				firstTime : !App.session.get("walkthroughShown")
			});
			this.$("#launch_walkthrough").tooltip("hide");

			App.session.set("walkthroughShown", true);
			this.walkthroughView.on("end", function(){
				if(self.walkthroughView){
					self.walkthroughView.remove();
					self.walkthroughView = null;
				}
			});

			if(App.user.get("showWalkthrough")){
				App.user.set("showWalkthrough", false);
				App.user.save({}, {
					properties : ["showWalkthrough"],
					error : function(){}
				});
			}
		},

		updateHelpRequest : function(){
			if(!this.model.has("lab") || !App.user.has("helpRequest")){
				return;
			}

			if(this.model.get("lab").has("groupColor") && _.startsWith(this.model.get("lab").get("groupColor"), "#")){
				App.user.get("helpRequest").set("groupColor", this.model.get("lab").get("groupColor"));
			}
			if(App.user.get("helpRequest").get("labSku") != this.model.get("sku")){
				App.user.get("helpRequest").toggleOn();
			}
		},

		getInstructorHelpRequest : function(){
			if(this.model.get("classId")!="") {
				var instructorHelpRequest = new InstructorHelpRequest.Model(
					{
						classId      : this.model.get("classId"),
						entitlementId: this.model.get("id")
					}
				);
				instructorHelpRequest.getHelpRequest();
			}
		},

		deleteHelpRequest: function () {
			if(this.model.get("classId")!="") {
				var instructorHelpRequest = new InstructorHelpRequest.Model(
					{
						classId      : this.model.get("classId"),
						entitlementId: this.model.get("id")
					}
				);

				instructorHelpRequest.deleteHelpRequest();
			}
		},

		instructorConsoleStatusChanged : function(){
			// If instructor is viewing, show instructor viewing message else hide that message (if any).
			if (this.model.get("instructorViewing")) {

				App.mainView.generalMessage({
					id         : "instructor_viewing_message",
					message    : App.i18n("class.instructorViewing"),
					type       : "info",
					dismissible: false
				});
			} else {
				App.mainView.removeGeneralMessage("instructor_viewing_message");
			}

			if(this.model.get("takenOver")){
				App.mainView.removeGeneralMessage("instructor_broadcasting_message");
				App.mainView.removeGeneralMessage("instructor_viewing_message");
				if(this.model.get("broadcastingDisplay")){
					App.mainView.removeGeneralMessage("instructor_taken_over_message");
					App.mainView.generalMessage({
						id          : "instructor_taken_over_and_broadcasting_message",
						message     : App.i18n("class.takenOverAndBroadcasting"),
						type        : "info",
						dismissible : false
					});
				} else{
					App.mainView.removeGeneralMessage("instructor_taken_over_and_broadcasting_message");
					App.mainView.generalMessage({
						id          : "instructor_taken_over_message",
						message     : App.i18n("class.takenOver"),
						type        : "info",
						dismissible : false
					});
				}
			} else if (this.model.get("broadcastingDisplay")){
				App.mainView.removeGeneralMessage("instructor_taken_over_message");
				App.mainView.removeGeneralMessage("instructor_taken_over_and_broadcasting_message");

				App.mainView.generalMessage({
					id          : "instructor_broadcasting_message",
					message     : App.i18n("class.broadcastingDisplay"),
					type        : "info",
					dismissible : false
				});
			} else{
				App.mainView.removeGeneralMessage("instructor_broadcasting_message");
				App.mainView.removeGeneralMessage("instructor_taken_over_message");
				App.mainView.removeGeneralMessage("instructor_taken_over_and_broadcasting_message");
			}
		},

		onInstructorBroadcastingDisplayChanged : function(){
			if(this.model.get("classBroadcastingDisplay") && this.model.get("classBroadcastingEntitlement") != this.model.get("entitlementKey")){
				this.showBroadcastVm();
			} else{
				this.removeBroadcastVm();
			}
		},

		showBroadcastVm : function(){
			if(!this.broadcastVmView){
				this.broadcastVmView = new BroadcastVmView({
					el : this.$("#broadcast_vm"),
					model : this.model
				});
			}
			this.detachConsoleView(this.activeConsoleView);
			this.broadcastVmView.show();
		},

		removeBroadcastVm : function(){
			if(this.broadcastVmView){
				this.broadcastVmView.remove();
			}
			this.attachConsoleView(this.activeConsoleView);
		},

		detachConsoleView : function(consoleView){
			if(!consoleView) {return; }

			consoleView.pause();
			consoleView.$el.detach();
		},
		attachConsoleView : function(consoleView){
			if(!consoleView) {return; }

			consoleView.$el.appendTo(this.$("#lab"));
			consoleView.resume();
		},

		studentClassPushThumbnail : function(message){
			var vmCanvas = this.$("#vm #vm_console canvas").get(0);
			if(!vmCanvas) { return; }

			var tCanvas = document.createElement("canvas");
			var tCanvasContext = tCanvas.getContext("2d");
			var requestWidth  = message.requestParams.width  || 100;
			var requestHeight = message.requestParams.height || 100;
			if(vmCanvas.width > vmCanvas.height){
				tCanvas.width  = requestWidth;
				tCanvas.height = Math.min((vmCanvas.height / vmCanvas.width) * requestWidth, requestHeight);
			} else{
				tCanvas.height  = requestHeight;
				tCanvas.width = Math.min((vmCanvas.width / vmCanvas.height) * requestHeight, requestWidth);
			}

			tCanvasContext.drawImage(vmCanvas, 0, 0, vmCanvas.width, vmCanvas.height, 0, 0, tCanvas.width, tCanvas.height);

			var pngImage = tCanvas.toDataURL("image/png");
			var jpgImage = tCanvas.toDataURL("image/jpeg", 0.6);

			if(jpgImage.length < pngImage.length){
				this.model.set("activeThumbnail", jpgImage);
			} else{
				this.model.set("activeThumbnail", pngImage);
			}
			this.model.studentClassPushThumbnail();
		},

		onResetByInstructor : function(){
			if(this.vmConsoleView) {
				this.vmConsoleView.displayState(this.vmConsoleView.UNAVAILABLE);
			}
			if(this.consoleListing){
				this.consoleListing.remove();
			}
			this.model.set("phase", "reset");
			this.stopProgress();
			this.stopTimer();

			this.cleanupConnections();


			App.mainView.generalMessage({
				message     : App.i18n("class.reset"),
				type        : "warning",
				dismissible : false
			});

			//30 second refresh just in case vAppReset does not come through.
			_.delay(this.refreshVApp, 30000);
		},

		getPanelId: function(panelName){
			if(panelName === "consoles"){
				panelName = "vm_listing";
			} else if (panelName === "manual"){
				panelName = "instructions";
			} else if (panelName === "zoom"){
				panelName = "video_conference";
			}
			return "#"+ panelName + "_panel";
		},
		closePanelByName : function(panelName){
			if(!this.panelManager) { return; }
			var panel = this.panelManager.getPanel(this.getPanelId(panelName));
			if(panel){
				panel.close();
			}
		},
		openPanelByName : function(panelName){
			if(!this.panelManager) { return; }
			var panel = this.panelManager.getPanel(this.getPanelId(panelName));
			if(panel){
				panel.expand();
				panel.setFocus();
			}
		},
		togglePanelByName : function(panelName){
			if(!this.panelManager) { return; }
			var panel = this.panelManager.getPanel(this.getPanelId(panelName));
			if(panel){
				panel.focusOrToggleExpand();
			}
		},
		hidePanelByName : function(panelName){
			if(!this.panelManager) { return; }
			var panel = this.panelManager.getPanel(this.getPanelId(panelName));
			if(panel){
				panel.hide();
			}
		},
		showPanelByName : function(panelName){
			if(!this.panelManager) { return; }
			var panel = this.panelManager.getPanel(this.getPanelId(panelName));
			if(panel){
				panel.show();
			}
		},
		closeAllPanels : function(position){
			if(!this.panelManager) { return; }
			var panels = this.panelManager.getPanels(position);
			if(panels){
				_.each(panels, function(panel){panel.close();});
			}
		},
		openAllPanels : function(position){
			if(!this.panelManager) { return; }
			var panels = this.panelManager.getPanels(position);
			if(panels){
				_.each(panels, function(panel){panel.expand();});
			}
		},
		togglePanelLeft : function(){
			this.togglePanel("left");
		},
		togglePanelRight : function(){
			this.togglePanel("right");
		},
		togglePanel : function(position){
			this.$(".toggle-panel-" + position).tooltip("hide");
			var $dock = $(".panels .dock-" + position);
			if($dock.find(".panel.expanded.focused").length > 0){
				$dock.find(".panel.expanded.focused .open-close-panel").click();
			} else if($dock.find(".panel.expanded").length > 0){
				$dock.find(".panel.expanded .open-close-panel").click();
			} else if($dock.find(".collapsed.focused").length > 0){
				$dock.find(".panel.collapsed.focused:first .open-close-panel").click();
			} else {
				$dock.find(".panel.collapsed:first .open-close-panel").click();
			}
		},
		getTogglePanelTitle : function(position){
			var showHide = "show";
			if($("body").hasClass(position + "-panel-expanded")){
				showHide = "hide";
			}
			var $dock = $(".dock-" + position);

			var panelType = "";
			if($dock.find("#instructions_panel,#vm_listing_panel,#help_request_instructor_panel").length == 3) {
				panelType = "All";
			} else if($dock.find("#instructions_panel,#vm_listing_panel").length == 2){
				panelType = "ManualAndVmChooser";
			} else if($dock.find("#instructions_panel,#help_request_instructor_panel").length == 2){
				panelType = "ManualAndHelpRequest";
			} else if($dock.find("#vm_listing_panel,#help_request_instructor_panel").length == 2){
				panelType = "VmChooserAndHelpRequest";
			} else if($dock.find("#instructions_panel").length == 1){
				panelType = "Manual";
			} else if($dock.find("#vm_listing_panel").length == 1){
				panelType = "VmChooser";
			} else if($dock.find("#help_request_instructor_panel").length == 1){
				panelType = "HelpRequest";
			}
			var titleKey = "console.vm.togglePanel." + showHide + panelType + "Panel";
			return App.i18n(titleKey);
		}
	});

});

