
define('hbs!tpls/console/partial-support-prompt.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    return "<div class=\"top\">\n	<div class=\"interior\">\n		<h1>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.partiallySupported.title",{"name":"$","hash":{},"data":data}))
    + "</h1>\n\n		<p>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.partiallySupported.message",{"name":"$","hash":{},"data":data}))
    + "</p>\n\n		<ul>\n			<li><a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"action":(depth0 != null ? depth0.entitlementId : depth0),"page":"REMOTE_CONTROL"},"data":data}))
    + "\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.partiallySupported.openRemoteControl",{"name":"$","hash":{},"data":data}))
    + "</a></li>\n			<li>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"console.partiallySupported.fullySupported",{"name":"$","hash":{},"data":data}))
    + "</li>\n		</ul>\n	</div>\n</div>";
},"useData":true});
Handlebars.registerPartial('console_partial_support_prompt', t);
return t;
});

