
define('hbs!tpls/catalogs/enrollment-start-button.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    return "	<a href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"page":"console/{{id}}"},"data":data}))
    + "\" class=\"btn btn-large btn-primary resume-lab\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.resumeLab",{"name":"$","hash":{},"data":data}))
    + "</a>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.and || (depth0 && depth0.and) || helpers.helperMissing).call(depth0,(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"showPrepopWarning",{"name":"config","hash":{},"data":data}),(helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.prepopsAvailable : depth0),true,{"name":"ne","hash":{},"data":data}),{"name":"and","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.and || (depth0 && depth0.and) || helpers.helperMissing).call(depth0,(helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"showPrepopWarning",{"name":"config","hash":{},"data":data}),(helpers.ne || (depth0 && depth0.ne) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.prepopsAvailable : depth0),true,{"name":"ne","hash":{},"data":data}),{"name":"and","hash":{},"fn":this.program(17, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"4":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "		<button type=\"button\" class=\"btn btn-large btn-primary start-lab\" data-toggle=\"modal\" data-target=\"#modal_prepop_warning_"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"\n			 rel=\"tooltip\" data-placement=\"bottom\" data-class=\"tooltip-md\"\n"
    + ((stack1 = helpers['if'].call(depth0,(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.averageDeployTime : depth0),0,{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "		>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.startLab",{"name":"$","hash":{},"data":data}))
    + "\n			<i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n		</button>\n";
},"5":function(depth0,helpers,partials,data) {
    return "				"
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","catalogs.labs.prepops.warningUnknownTime",{"name":"$$","hash":{},"data":data}))
    + "\n";
},"7":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(helpers.lt || (depth0 && depth0.lt) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.averageDeployTime : depth0),60,{"name":"lt","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.program(10, data, 0),"data":data})) != null ? stack1 : "");
},"8":function(depth0,helpers,partials,data) {
    return "				"
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","catalogs.labs.prepops.warningUnderAMinute",{"name":"$$","hash":{},"data":data}))
    + "\n";
},"10":function(depth0,helpers,partials,data) {
    return "				"
    + this.escapeExpression((helpers.$$ || (depth0 && depth0.$$) || helpers.helperMissing).call(depth0,"title","catalogs.labs.prepops.warningDeployTime",(helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.averageDeployTime : depth0),{"name":"daysHoursMinutes","hash":{"seconds":true},"data":data}),{"name":"$$","hash":{},"data":data}))
    + "\n			";
},"12":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<a class=\"btn btn-large btn-primary start-lab\"\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"autoRunCompatibilityChecker",{"name":"config","hash":{},"fn":this.program(13, data, 0),"inverse":this.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "		>\n			"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.startLab",{"name":"$","hash":{},"data":data}))
    + "\n		</a>\n";
},"13":function(depth0,helpers,partials,data) {
    return "				href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"page":"compatibility/{{id}}"},"data":data}))
    + "\"\n";
},"15":function(depth0,helpers,partials,data) {
    return "				href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"page":"console/{{id}}"},"data":data}))
    + "\"\n";
},"17":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "		<div id=\"modal_prepop_warning_"
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"global modal hide fade fluid-height preprop-warning-modal\">\n			<div class=\"modal-dialog\">\n				<div class=\"modal-content\">\n					<div class=\"modal-header\">\n						<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"close",{"name":"$","hash":{},"data":data}))
    + "</button>\n						<h3 class=\"modal-title\"><span class=\"fa fa-fw fa-share-square-o\"></span>"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.prepops.warningTitle",{"name":"$","hash":{},"data":data}))
    + "</h3>\n					</div>\n					<div class=\"modal-body\">\n						<p>\n"
    + ((stack1 = helpers['if'].call(depth0,(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.averageDeployTime : depth0),0,{"name":"eq","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(18, data, 0),"inverse":this.program(20, data, 0),"data":data})) != null ? stack1 : "")
    + "						</p>\n						<button type=\"button\" class=\"btn cancel\" data-dismiss=\"modal\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"cancel",{"name":"$","hash":{},"data":data}))
    + "</button>\n						<a class=\"btn btn-primary\"\n"
    + ((stack1 = (helpers.config || (depth0 && depth0.config) || helpers.helperMissing).call(depth0,"autoRunCompatibilityChecker",{"name":"config","hash":{},"fn":this.program(25, data, 0),"inverse":this.program(27, data, 0),"data":data})) != null ? stack1 : "")
    + "						>\n							"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"continue",{"name":"$","hash":{},"data":data}))
    + "\n						</a>\n					</div>\n				</div>\n			</div>\n		</div>\n";
},"18":function(depth0,helpers,partials,data) {
    return "								"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.prepops.warningUnknownTime",{"name":"$","hash":{},"data":data}))
    + "\n";
},"20":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(helpers.lt || (depth0 && depth0.lt) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.averageDeployTime : depth0),60,{"name":"lt","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(21, data, 0),"inverse":this.program(23, data, 0),"data":data})) != null ? stack1 : "");
},"21":function(depth0,helpers,partials,data) {
    return "								"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.prepops.warningUnderAMinute",{"name":"$","hash":{},"data":data}))
    + "\n";
},"23":function(depth0,helpers,partials,data) {
    return "								"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"catalogs.labs.prepops.warningDeployTime",(helpers.daysHoursMinutes || (depth0 && depth0.daysHoursMinutes) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.averageDeployTime : depth0),{"name":"daysHoursMinutes","hash":{"seconds":true},"data":data}),{"name":"$","hash":{},"data":data}))
    + "\n							";
},"25":function(depth0,helpers,partials,data) {
    return "								href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"page":"compatibility/{{id}}"},"data":data}))
    + "\"\n";
},"27":function(depth0,helpers,partials,data) {
    return "								href=\""
    + this.escapeExpression((helpers.appUrl || (depth0 && depth0.appUrl) || helpers.helperMissing).call(depth0,{"name":"appUrl","hash":{"page":"console/{{id}}"},"data":data}))
    + "\"\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.state : depth0),"started",{"name":"eq","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});
Handlebars.registerPartial('catalogs_enrollment_start_button', t);
return t;
});

