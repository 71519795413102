
define('hbs!tpls/console/waiting-room.handlebars', ["handlebars"], function (Handlebars) { var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "	<div class=\"page-content page-wrapper\" style=\"background-color:"
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.waitingRoomData : depth0)) != null ? stack1.waitingRoom : stack1)) != null ? stack1.background : stack1), depth0))
    + "; background-image: url(/images/events/pending/pending-page-default-transparent.png);\">\n		<div class=\"page-data-wrapper\">\n			<header><h1 class=\"page-header\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"waitingRoomLabState.yourLab",{"name":"$","hash":{},"data":data}))
    + " \""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.waitingRoomData : depth0)) != null ? stack1.labName : stack1), depth0))
    + "\" "
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"waitingRoomLabState.isReady",{"name":"$","hash":{},"data":data}))
    + "</h1></header>\n			<h3 class=\"page-subheader\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"waitingRoomLabState.waitingToStartLab",{"name":"$","hash":{},"data":data}))
    + "</h3>\n			<h3 class=\"page-subheader expiration-time-warning\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"waitingRoomLabState.expirationTimeWarning",{"name":"$","hash":{},"data":data}))
    + "</h3>\n			<div class=\"pending-time-wrapper\">\n				<div class=\"time-subtext\">\n					<span>Hours</span>\n					<span>Minutes</span>\n					<span>Seconds</span>\n				</div>\n				<span class=\"time-remaining-wrapper\">\n					<span class=\"page-number time-remaining time-remaining-override\">00:00:00</span>\n				</span>\n			</div>\n			<div class=\"lab-ready-button-wrapper\">\n				<button class=\"btn btn-outline button-lab button-lab-catalog\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"waitingRoomLabState.goToCatalog",{"name":"$","hash":{},"data":data}))
    + "</button>\n				<button class=\"btn btn-primary button-lab button-lab-ready\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"waitingRoomLabState.goToLab",{"name":"$","hash":{},"data":data}))
    + " <span class=\"arrows\">>></span></button>\n			</div>\n		</div>\n	</div>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.waitingRoomLabExpired : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<div class=\"page-content page-wrapper\" style=\"background-color:"
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.waitingRoomData : depth0)) != null ? stack1.waitingRoom : stack1)) != null ? stack1.background : stack1), depth0))
    + "; background-image: url(/images/events/pending/pending-page-default-transparent.png);\">\n			<div class=\"page-data-wrapper\">\n				<header><h1 class=\"page-header\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"waitingRoomLabState.yourLab",{"name":"$","hash":{},"data":data}))
    + " \""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.waitingRoomData : depth0)) != null ? stack1.labName : stack1), depth0))
    + "\" "
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"waitingRoomLabState.hasExpired",{"name":"$","hash":{},"data":data}))
    + "</h1></header>\n				<h3 class=\"page-subheader\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"waitingRoomLabState.labExpiredSubtext",{"name":"$","hash":{},"data":data}))
    + "</h3>\n				<button class=\"btn btn-outline button-lab button-lab-catalog\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"waitingRoomLabState.goToCatalog",{"name":"$","hash":{},"data":data}))
    + "</button>\n			</div>\n		</div>\n";
},"6":function(depth0,helpers,partials,data) {
    var stack1;

  return "		<div class=\"page-content page-wrapper\" style=\"background-color:"
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.waitingRoomData : depth0)) != null ? stack1.waitingRoom : stack1)) != null ? stack1.background : stack1), depth0))
    + "; background-image: url(/images/events/waiting-room/waiting-room-default-transparent.png);\">\n			<div class=\"page-data-wrapper\">\n				<header><h1 class=\"page-header waiting-room-header\">"
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.waitingRoomData : depth0)) != null ? stack1.waitingRoom : stack1)) != null ? stack1.header : stack1), depth0))
    + "</h1></header>\n				<h3 class=\"page-subheader waiting-room-subtitle\">"
    + ((stack1 = this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.waitingRoomData : depth0)) != null ? stack1.waitingRoom : stack1)) != null ? stack1.subtitle : stack1), depth0)) != null ? stack1 : "")
    + "</h3>\n				<h3 class=\"page-subheader waiting-room-message\">"
    + ((stack1 = this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.waitingRoomData : depth0)) != null ? stack1.waitingRoom : stack1)) != null ? stack1.paragraph : stack1), depth0)) != null ? stack1 : "")
    + "</h3>\n				<div class=\"waiting-room-notification\">\n					<label class=\"notifyMe\" id=\"notify-me\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"waitingRoom.notifyMe",{"name":"$","hash":{},"data":data}))
    + "</label>\n					<label class=\"notifyMe switch\">\n						<input type=\"checkbox\" id=\"subscribe-for-notification\">\n						<span class=\"slider round\"></span>\n					</label>\n				</div>\n				<div class=\"users-ahead-wrapper\">\n					<span id=\"users-ahead-message\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"waitingRoom.usersAhead",{"name":"$","hash":{},"data":data}))
    + "</span>\n					<span class=\"page-number\">"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.waitingRoomData : depth0)) != null ? stack1.usersAhead : stack1), depth0))
    + "</span>\n				</div>\n				<div class=\"leave-queue-wrapper\">\n					<button class=\"btn btn-danger\" id=\"leave-queue\">"
    + this.escapeExpression((helpers.$ || (depth0 && depth0.$) || helpers.helperMissing).call(depth0,"waitingRoom.leaveQueue",{"name":"$","hash":{},"data":data}))
    + "</button>\n				</div>\n			</div>\n		</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers['if'].call(depth0,(depth0 != null ? depth0.waitingRoomLabPending : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});
Handlebars.registerPartial('console_waiting_room', t);
return t;
});

