define('views/compatibility/tests/canvas',['require','../test','utilities/browser','hbs!tpls/compatibility/tests/canvas.handlebars'],function (require) {
	"use strict";

	//class dependencies
	var TestBase = require("../test"),
		Browser  = require("utilities/browser"),
		template = require("hbs!tpls/compatibility/tests/canvas.handlebars");

	return TestBase.extend({
		template : template,
		name : "canvas",
		run : function(){
			var result = Browser.support.canvas;
			this.setStatus(result ? "success" : "failure");
			this.trigger(TestBase.COMPLETE, result);
		}
	});

});

