define('nls/root/verify-account',{
	"verifyAccount" : {
		"checkingToken"       : "Checking Token...",
		"invalidToken"        : "Invalid Token",
		"getNewToken"         : "Send new token",
		"sendingToken"        : "Sending token...",
		"continue"            : "Continue",
		"passwordSaved"       : "Password Saved!",
		"newTokenSent"        : "New token sent",
		"newTokenSentMessage" : "A new token was sent. Please check your email.",
		"answerQuestion"      : "Answer",
		"enterToken" : {
			"title"   : "Enter verification token",
			"label"   : "Token",
			"button"  : "Enter Token"
		}
	},
	"profile" : {
		"password" : {
			"title" : "Set Password",
			"save"  : "Set Password"
		}
	},
	"errors"      : {
	},
	"title"       : "Verify Account"
});
